import {
  FormControl,
  FormLabel,
  Box,
  Button,
  Select,
  Switch,
  SimpleGrid,
  FormHelperText,
  Checkbox,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import MessageBox from '../../components/MessageBox';

import {
  GetPageQueryResult,
  PageType,
  useGetPagesQuery,
  useUpdatePageMutation,
  useUpdatePageDisconnectParentMutation,
  useUpdatePageFlatMutation,
} from '../../generated/graphql';

interface Props {
  data: GetPageQueryResult['data']['page'];
}

const EditForm: React.FC<Props> = ({ data }) => {
  const { push } = useHistory();
  const [parentPageId, setParentPageId] = useState<string | undefined>(
    data.parentId ? data.parentId : undefined
  );
  const [showOnHeader, setShowOnHeader] = useState(data.showOnHeader && true);
  const [showOnFooter, setShowOnFooter] = useState(data.showOnFooter && true);

  const [isLink, setIsLink] = useState(data.isLink && true);
  const [status, setStatus] = useState(data.status && true);
  const [pageType, setPageType] = useState<PageType>(
    data.pageType ? data.pageType : PageType.DEFAULT
  );

  // const [images, setImages] = useState(data.photos ? data.photos : []);

  const [error, setError] = useState('');

  const { data: pagesData, loading: pagesLoading } = useGetPagesQuery({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });
  const [updatePage, { loading }] = useUpdatePageMutation();
  const [
    updatePageFlat,
    { loading: loadingFlat },
  ] = useUpdatePageFlatMutation();
  const [
    updatePageDisconnectParent,
    { loading: loadingDisconnectParent },
  ] = useUpdatePageDisconnectParentMutation();

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      if (!parentPageId && data.parentId) {
        await updatePageDisconnectParent({
          variables: {
            id: data.id,
            showOnHeader,
            showOnFooter,
            isLink,
            status,
            pageType,
            // parentId: data.pageId,
          },
        });
      } else if (!parentPageId && !data.parentId) {
        await updatePageFlat({
          variables: {
            id: data.id,
            showOnHeader,
            showOnFooter,
            isLink,
            status,
            pageType,
          },
        });
      } else {
        await updatePage({
          variables: {
            id: data.id,
            showOnHeader,
            showOnFooter,
            isLink,
            status,
            pageType,
            parentPageId,
          },
        });
      }
      push('/pages');
    } catch (error) {
      console.log('err ⚠️', error);
      setError(error.message);
    }
  };

  return (
    <form onSubmit={onSubmit}>
      <Box width='full' mb='8'>
        {error && <MessageBox status='warning' message={error} />}

        <SimpleGrid columns={[1, 5]} spacing={[2, 8]}>
          <FormControl>
            <FormLabel>Sayfa Türü</FormLabel>
            <Select
              value={pageType}
              onChange={(e) => setPageType(e.target.value as PageType)}
              disabled
            >
              {Object.keys(PageType).map((type) => (
                <option value={type}>{type}</option>
              ))}
            </Select>
          </FormControl>

          <FormControl>
            <FormLabel>Üst Sayfa</FormLabel>
            <Select
              value={parentPageId}
              onChange={(e) => setParentPageId(e.target.value)}
            >
              <option value=''>--</option>
              {!pagesLoading &&
                pagesData.pages.map((page) => (
                  <option
                    value={page.id}
                    key={page.id}
                    disabled={page.id === data.id}
                  >
                    {page.description.length > 1
                      ? page.description.map((desc) => `(${desc.title})`)
                      : page.description.map((desc) => `${desc.title}`)}
                  </option>
                ))}
            </Select>
            <FormHelperText>Üst sayfası varsa seçiniz.</FormHelperText>
          </FormControl>

          <FormControl>
            <FormLabel>Pozisyon</FormLabel>
            <Box display='flex' flexDirection='column'>
              <Checkbox
                colorScheme='red'
                isChecked={showOnHeader}
                onChange={() => setShowOnHeader((prev) => !prev)}
              >
                Header
              </Checkbox>
              <Checkbox
                colorScheme='red'
                isChecked={showOnFooter}
                onChange={() => setShowOnFooter((prev) => !prev)}
              >
                Footer
              </Checkbox>
            </Box>
          </FormControl>
          <FormControl
            alignItems='center'
            display='flex'
            flexDirection='column'
          >
            <FormLabel textAlign='center'>Link?</FormLabel>
            <Switch
              onChange={(e) => setIsLink(e.target.checked)}
              defaultChecked={isLink}
            />
          </FormControl>

          <FormControl
            alignItems='center'
            display='flex'
            flexDirection='column'
          >
            <FormLabel>Aktif?</FormLabel>
            <Switch
              onChange={(e) => setStatus(e.target.checked)}
              defaultChecked={status}
            />
          </FormControl>
        </SimpleGrid>

        <Button
          colorScheme='blue'
          type='submit'
          mt='10px'
          isLoading={loading || loadingDisconnectParent || loadingFlat}
        >
          Kaydet
        </Button>
      </Box>
    </form>
  );
};

export default EditForm;
