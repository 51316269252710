import { Code, Container, Text, VStack } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import Loading from '../../components/Loading';
import MessageBox from '../../components/MessageBox';
import { useGetReaderCommentQuery } from '../../generated/graphql';
import EditForm from './EditForm';

const Edit = () => {
  const { id } = useParams<{ id: string }>();

  const { data, loading, error } = useGetReaderCommentQuery({
    fetchPolicy: 'network-only',
    variables: { where: { id } },
  });

  if (loading || !data) {
    return <Loading />;
  }

  return (
    <Container maxW='4xl' ml='0' pl='0'>
      <VStack align='flex-start' mb='4'>
        <Text fontWeight='bold' fontSize='2xl'>
          Okuyucu Yorumu Düzenle
        </Text>
        <Text>
          Aşağıdaki formdan ürün seçerek o ürüne ait okuyucu yorumları
          oluşturabilirsiniz. İstediğiniz üründe{' '}
          <Code colorScheme='red'>Okuyucu Yorumları</Code> içerik türünde içerik
          oluşturarak yorumları listeleyebilirsiniz.
        </Text>
      </VStack>
      {error ? (
        <MessageBox status='error' message={error.message} />
      ) : (
        <EditForm data={data.readerComment} />
      )}
    </Container>
  );
};

export default Edit;
