import 'emoji-mart/css/emoji-mart.css';
import { Box, Text, VStack, Button, Divider } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { nanoid } from 'nanoid';

import {
  ContentType,
  MediaType,
  ThemeType,
  useCreateContentMutation,
  useUploadFileMutation,
  useGetCategoriesLazyQuery,
  useGetCategoriesQuery,
  useCreateContentWithoutBgImageMutation,
  IdField,
  useGetProductsQuery,
  ContentLineup,
  useGetBlogCategoriesQuery,
} from '../../generated/graphql';
import { CardField, ContentSlide, TimelineCardField } from '../../types';
import ContentDescription from '../../components/ContentDescription';
import categoryIdSetter from '../../services/categoryIdSetter';
import ContentForm from '../../components/ContentInputs/ContentForm';

const Add = () => {
  const { pageId } = useParams<{ pageId: string }>();
  const { push } = useHistory();

  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [videoUrl, setVideoUrl] = useState('');
  const [validVideo, setValidVideo] = useState<any>(null);
  const [photos, setPhotos] = useState([]);
  const [contentType, setContentType] = useState<ContentType>(ContentType.TEXT);
  const [lineUp, setLineUp] = useState<ContentLineup>(ContentLineup.HORIZONTAL);
  const [mediaType, setMediaType] = useState<MediaType>(MediaType.SINGLE);
  const [themeType, setThemeType] = useState<ThemeType>(ThemeType.DARK);

  const [parentCat, setParentCat] = useState('');
  const [childCat, setChildCat] = useState('');
  const [blogCategory, setBlogCategory] = useState('');

  // cards
  const [cardFields, setCardFields] = useState<CardField[]>([
    { id: nanoid(), icon: '', title: '', description: '', url: '', image: '' },
  ]);

  //timeline cards
  const [timelineCardFields, setTimelineCardFields] = useState<
    TimelineCardField[]
  >([
    {
      id: nanoid(),
      icon: '',
      title: '',
      description: '',
      date: '',
      subtitle: '',
      color: '#cccccc',
    },
  ]);

  // slide
  const [slides, setSlides] = useState<ContentSlide[]>([
    {
      id: nanoid(),
      title: '',
      image: '',
      buttonText: '',
      buttonUrl: '',
      description: '',
    },
  ]);
  const { data: productsData, loading: productsLoading } =
    useGetProductsQuery();
  const [products, setProducts] = useState<IdField[]>([]);

  // description inputs
  const [title, setTitle] = useState('');
  const [language] = useState('tr');
  const [description, setDescription] = useState('');
  const [shortDescription, setShortDescription] = useState('');
  const [buttonText, setButtonText] = useState('');
  const [buttonUrl, setButtonUrl] = useState('');

  const [error, setError] = useState('');

  const [createContent, { loading }] = useCreateContentMutation();
  const [createContentWithoutBg, { loading: loadingWithoutBg }] =
    useCreateContentWithoutBgImageMutation();
  const [uploadPhoto, { loading: uploadLoading }] = useUploadFileMutation();

  const [logo, setLogo] = useState<any>('');
  const [logoPreview, setLogoPreview] = useState('');

  const handleImg = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setLogo(e.target.files[0]);
      setLogoPreview(URL.createObjectURL(e.target.files[0]));
    }
  };

  const { data: parentCategoriesData, loading: parentCategoriesLoading } =
    useGetCategoriesQuery({
      variables: {
        where: {
          parentId: { equals: null },
        },
      },
    });

  const [getChildCats, { data: childCats, loading: childCatsLoading }] =
    useGetCategoriesLazyQuery();

  const { data: blogCategoriesData } = useGetBlogCategoriesQuery();

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (contentType === 'IMAGE' && photos.length <= 0) {
      setError('Galeriye hiç resim eklemediniz');
      return;
    }
    if (contentType === 'VIDEO' && !validVideo) {
      setError('Geçerli bir video linki ekleyiniz');
      return;
    }
    try {
      if (logo) {
        const uploadResponse = await uploadPhoto({
          variables: { file: logo },
        });

        await createContent({
          variables: {
            pageId,
            photos,
            videos: videoUrl,
            contentType,
            mediaType,
            longitude,
            latitude,
            cards: cardFields,
            slides,
            timeline: timelineCardFields,
            backgroundImage: (uploadResponse.data.uploadFile as any).file,
            theme: themeType,
            productCategoryId: categoryIdSetter({ parentCat, childCat }),
            highlightedProducts: products,
            lineUp,
            blogCategoryId: blogCategory,

            title,
            description,
            shortDescription,
            language,
            buttonText,
            buttonUrl,
          },
        });
      } else {
        await createContentWithoutBg({
          variables: {
            pageId,
            photos,
            videos: videoUrl,
            contentType,
            mediaType,
            longitude,
            latitude,
            cards: cardFields,
            slides,
            timeline: timelineCardFields,
            theme: themeType,
            productCategoryId: categoryIdSetter({ parentCat, childCat }),
            highlightedProducts: products,
            lineUp,
            blogCategoryId: blogCategory,

            title,
            description,
            shortDescription,
            language,
            buttonText,
            buttonUrl,
          },
        });
      }

      setError('');
      push(`/pages/content/${pageId}`);
    } catch (error) {
      console.log('err ⚠️', error);
      setError(error.message);
    }
  };

  useEffect(() => {
    getChildCats({ variables: { where: { parentId: { equals: parentCat } } } });
  }, [childCats, getChildCats, parentCat]);

  return (
    <>
      <VStack align='flex-start' mb='4'>
        <Text fontWeight='bold' fontSize='2xl'>
          Yeni İçerik
        </Text>
      </VStack>
      <form onSubmit={onSubmit}>
        <Box width='full' mb='8'>
          <ContentForm
            mode='ADD'
            blogCategoriesData={blogCategoriesData}
            blogCategory={blogCategory}
            setBlogCategory={setBlogCategory}
            cardFields={cardFields}
            timelineCardFields={timelineCardFields}
            setTimelineCardFields={setTimelineCardFields}
            childCat={childCat}
            childCats={childCats}
            childCatsLoading={childCatsLoading}
            contentType={contentType}
            error={error}
            handleImg={handleImg}
            latitude={latitude}
            lineUp={lineUp}
            logoPreview={logoPreview}
            longitude={longitude}
            mediaType={mediaType}
            parentCat={parentCat}
            parentCategoriesData={parentCategoriesData}
            parentCategoriesLoading={parentCategoriesLoading}
            photos={photos}
            productsData={productsData}
            productsLoading={productsLoading}
            setCardFields={setCardFields}
            setChildCat={setChildCat}
            setContentType={setContentType}
            setLatitude={setLatitude}
            setLineUp={setLineUp}
            setLogo={setLogo}
            setLogoPreview={setLogoPreview}
            setLongitude={setLongitude}
            setMediaType={setMediaType}
            setParentCat={setParentCat}
            setPhotos={setPhotos}
            setProducts={setProducts}
            setSlides={setSlides}
            setThemeType={setThemeType}
            setValidVideo={setValidVideo}
            setVideoUrl={setVideoUrl}
            slides={slides}
            themeType={themeType}
            validVideo={validVideo}
            videoUrl={videoUrl}
          />

          <Divider my='6' />

          <ContentDescription
            title={title}
            setTitle={setTitle}
            description={description}
            setDescription={setDescription}
            shortDescription={shortDescription}
            setShortDescription={setShortDescription}
            buttonText={buttonText}
            setButtonText={setButtonText}
            buttonUrl={buttonUrl}
            setButtonUrl={setButtonUrl}
          />

          <Button
            colorScheme='blue'
            type='submit'
            mt='10px'
            isLoading={loading || loadingWithoutBg || uploadLoading}
          >
            Kaydet
          </Button>
        </Box>
      </form>
    </>
  );
};

export default Add;
