import {
  Input,
  FormControl,
  FormLabel,
  Box,
  Button,
  FormHelperText,
  Flex,
  Image,
  IconButton,
  Checkbox,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { BsTrash } from 'react-icons/bs';
import { useHistory } from 'react-router-dom';
import CloudinaryImage from '../../components/CloudinaryImage';
import MessageBox from '../../components/MessageBox';

import {
  GetBlogCategoryQueryResult,
  useUploadFileMutation,
  useUpdateBlogCategoryMutation,
  useUpdateBlogCategoryWithoutPhotoMutation,
} from '../../generated/graphql';

interface Props {
  data: GetBlogCategoryQueryResult['data']['findFirstBlogCategory'];
}

const EditForm: React.FC<Props> = ({ data }) => {
  const { push } = useHistory();

  const [logo, setLogo] = useState<any>(data.thumbnail ? data.thumbnail : '');
  const [logoPreview, setLogoPreview] = useState('');
  const [hidden, setHidden] = useState(data.hidden && true);

  const [error, setError] = useState('');

  const [uploadPhoto, { loading: uploadLoading }] = useUploadFileMutation();
  const [updateBlogCategory, { loading }] = useUpdateBlogCategoryMutation();
  const [
    updateBlogCategoryWithoutThumbnail,
    { loading: loadingWithoutThumbnail },
  ] = useUpdateBlogCategoryWithoutPhotoMutation();

  const handleImg = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setLogo(e.target.files[0]);
      setLogoPreview(URL.createObjectURL(e.target.files[0]));
    }
  };
  const [dataLogoPreview, setDataLogoPreview] = useState('');
  useEffect(() => {
    setDataLogoPreview(data.thumbnail);
  }, [data.thumbnail]);

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      if (logo && typeof logo === 'object') {
        const uploadResponse = await uploadPhoto({
          variables: { file: logo },
        });
        await updateBlogCategory({
          variables: {
            id: data.id,
            thumbnail: (uploadResponse.data.uploadFile as any).file,
            hidden,
          },
        });
      } else {
        await updateBlogCategoryWithoutThumbnail({
          variables: {
            id: data.id,
            thumbnail: logo,
            hidden,
          },
        });
      }
      setError('');
      push('/blogcategories');
    } catch (error) {
      console.log('err ⚠️', error);
      setError(error.message);
    }
  };

  return (
    <form onSubmit={onSubmit}>
      <Box>
        {error && <MessageBox status='warning' message={error} />}
        <Flex>
          <Box mr='8'>
            <FormControl>
              <FormLabel>Kapak resmi (opsiyonel)</FormLabel>
              <Input type='file' multiple={false} onChange={handleImg} />
              <FormHelperText>
                Eğer isterseniz blog kategorisine kapak resmi ekleyebilirsiniz.
              </FormHelperText>
            </FormControl>
          </Box>
          <Box boxSize='150px' position='relative'>
            {dataLogoPreview && !logoPreview ? (
              <>
                <IconButton
                  position='absolute'
                  bottom='0'
                  right='0'
                  colorScheme='yellow'
                  aria-label='delete image'
                  icon={<BsTrash size='24' />}
                  onClick={() => {
                    setLogo('');
                    setDataLogoPreview('');
                  }}
                />
                <CloudinaryImage publicId={dataLogoPreview} />
              </>
            ) : (
              <>
                {logoPreview && (
                  <IconButton
                    position='absolute'
                    bottom='0'
                    right='0'
                    colorScheme='yellow'
                    aria-label='delete image'
                    icon={<BsTrash size='24' />}
                    onClick={() => {
                      setLogo('');
                      setLogoPreview('');
                      setDataLogoPreview('');
                    }}
                  />
                )}
                {logoPreview && (
                  <Image
                    src={logoPreview}
                    rounded='sm'
                    objectFit='cover'
                    boxSize='150px'
                  />
                )}
              </>
            )}
          </Box>
        </Flex>

        <Box w='50%'>
          <FormControl ml='auto'>
            <FormLabel>Durum</FormLabel>
            <Checkbox
              colorScheme='red'
              isChecked={hidden}
              onChange={() => setHidden((prev) => !prev)}
            >
              Gizle
            </Checkbox>
            <FormHelperText>
              Gizle işaretlendiğinde bu kategoriye ait makaleler listelenmez.
              Sadece içerik olarak seçildiğinde sayfada gösterilir.
            </FormHelperText>
          </FormControl>
        </Box>

        <Button
          colorScheme='blue'
          type='submit'
          mt='10px'
          isLoading={loading || uploadLoading || loadingWithoutThumbnail}
        >
          Kaydet
        </Button>
      </Box>
    </form>
  );
};

export default EditForm;
