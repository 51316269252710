import {
  chakra,
  IconButton,
  FormControl,
  Flex,
  Box,
  Input,
  Textarea,
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionIcon,
  AccordionPanel,
  Button,
} from '@chakra-ui/react';
import { useCallback } from 'react';
import { AiOutlineDelete } from 'react-icons/ai';
import { BsArrowsMove } from 'react-icons/bs';
import { SortableHandle } from 'react-sortable-hoc';
import { useUploadFileMutation } from '../generated/graphql';
import { ContentSlide } from '../types';
import CloudinaryImage from './CloudinaryImage';
import Loading from './Loading';

interface Props {
  card: ContentSlide;
  cardFields: ContentSlide[];
  setCardFields?: React.Dispatch<React.SetStateAction<ContentSlide[]>>;
  removeCardField: (id: any) => void;
  handleCardInputChange: (id: any, event: any) => void;
  setSlides?: React.Dispatch<React.SetStateAction<ContentSlide[]>>;
}

const DragHandle = SortableHandle(() => <BsArrowsMove size={18} />);

const SlideInput: React.FC<Props> = ({
  card,
  cardFields,
  removeCardField,
  handleCardInputChange,
  // setSlides,
  setCardFields,
}) => {
  const [uploadPhoto, { loading: uploadLoading }] = useUploadFileMutation();

  const handleImg = useCallback(
    async (id, e: React.ChangeEvent<HTMLInputElement>) => {
      new Promise((resolve) => {
        let copied = JSON.parse(JSON.stringify(cardFields));
        const changedInputs = copied.map(async (input) => {
          if (id === input.id) {
            const { data } = await uploadPhoto({
              variables: { file: e.target.files[0] },
            });
            input['image'] = data.uploadFile.file;
          }
          resolve(input);

          return input;
        });
        Promise.all(changedInputs).then((value) => {
          setCardFields(value as any);
        });
      });
    },
    [setCardFields, cardFields, uploadPhoto]
  );

  return (
    <>
      <FormControl display='flex' alignItems='center' w='full'>
        <IconButton
          icon={<AiOutlineDelete size={20} />}
          aria-label='delete card'
          onClick={() => removeCardField(card.id)}
          size='sm'
          variant='ghost'
          colorScheme='red'
          ml='2'
          disabled={cardFields.length <= 1}
        />
        <chakra.div ml='auto'>
          <DragHandle />
        </chakra.div>
      </FormControl>

      <FormControl>
        {uploadLoading ? (
          <Loading />
        ) : (
          <Flex my='2'>
            <Box mr='8' flexBasis='45%' my='auto'>
              <FormControl>
                <Button
                  htmlFor={`slideInput${card.id}`}
                  as='label'
                  colorScheme='pink'
                  _hover={{ cursor: 'pointer' }}
                >
                  Görsel Seç
                </Button>
                <Input
                  type='file'
                  id={`slideInput${card.id}`}
                  hidden
                  multiple={false}
                  onChange={(e) => handleImg(card.id, e)}
                />
              </FormControl>
            </Box>
            {card.image && (
              <Box maxHeight='52px' flexBasis='55%'>
                <CloudinaryImage
                  publicId={card.image}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain',
                    maxWidth: '200px',
                  }}
                />
              </Box>
            )}
          </Flex>
        )}
      </FormControl>
      <Accordion allowToggle w='full'>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box flex='1' textAlign='left'>
                Özellikler
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel>
            <FormControl mb='2'>
              <Input
                type='text'
                name='title'
                value={card.title}
                onChange={(e) => handleCardInputChange(card.id, e)}
                placeholder='başlık'
              />
            </FormControl>
            <FormControl mb='2'>
              <Textarea
                name='description'
                value={card.description}
                onChange={(e) => handleCardInputChange(card.id, e)}
                placeholder='açıklama'
              />
            </FormControl>
            <FormControl mb='2'>
              <Input
                name='buttonText'
                value={card.buttonText}
                onChange={(e) => handleCardInputChange(card.id, e)}
                placeholder='buton üzeri yazı'
              />
            </FormControl>
            <FormControl>
              <Input
                name='buttonUrl'
                value={card.buttonUrl}
                onChange={(e) => handleCardInputChange(card.id, e)}
                placeholder='buton link'
              />
            </FormControl>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </>
  );
};

export default SlideInput;
