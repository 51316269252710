import { Select as CSelect } from '@chakra-ui/select';
import { SkeletonGroupLayout } from './SkeletonGroup';

interface Props {
  state: string;
  setState: Function;
  children: JSX.Element[];
  label?: string;
  loading?: boolean;
}

export const Select = ({
  state,
  setState,
  children,
  label,
  loading = false,
}: Props) => {
  return (
    <SkeletonGroupLayout label={label} loading={loading}>
      <CSelect onChange={(e) => setState(e.target.value)} defaultValue={state}>
        {children}
      </CSelect>
    </SkeletonGroupLayout>
  );
};
