import {
  Button,
  ButtonGroup,
  IconButton,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
  useToast,
  HStack,
  Spacer,
  Select,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  useColorModeValue,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { MdRefresh } from 'react-icons/md';
import { Link, useLocation } from 'react-router-dom';
import DeletePopover from '../../components/DeletePopover';
import DescriptionModal from '../../components/Description';
import Loading from '../../components/Loading';
import MessageBox from '../../components/MessageBox';
import Pagination from '../../components/Pagination';

import {
  GetBlogPostsQueryResult,
  useDeleteBlogPostMutation,
  useGetBlogCategoriesQuery,
  useGetBlogPostsQuery,
} from '../../generated/graphql';
import url from '../../services/url';

interface ItemProps {
  item: GetBlogPostsQueryResult['data']['blogPosts']['posts'][0];
  deleteLoading: boolean;
  deleteHandler: (id: any) => any;
  index: number;
  refetch: any;
  skip: number;
}

const TableItem = (props: ItemProps) => {
  const trBg = useColorModeValue('gray.100', 'gray.700');
  const trBorderColor = useColorModeValue('whiteAlpha.900', 'whiteAlpha.200');
  return (
    <>
      <Tr
        key={props.item.id}
        backgroundColor={trBg}
        border='2px'
        borderColor={trBorderColor}
      >
        <Td>{props.skip + props.index + 1}</Td>
        <Td>
          <DescriptionModal
            descriptionProp={props.item.description}
            field='blogPost'
            fieldId={props.item.id}
            refetch={props.refetch}
          />
        </Td>
        <Td textAlign='center'>{props.item.category.description[0]?.title}</Td>
        <Td isNumeric>
          <ButtonGroup size='sm' variant='solid'>
            <Button
              colorScheme='pink'
              as={Link}
              to={`/blogpost/content/${props.item.id}`}
            >
              [{props.item.content.length}] İçerikler
            </Button>
            <Button
              colorScheme='teal'
              as={Link}
              to={`/blogpost/update/${props.item.id}`}
            >
              Düzenle
            </Button>
            <DeletePopover
              deleteHandler={props.deleteHandler}
              pageId={props.item.id}
              isLoading={props.deleteLoading}
            />
          </ButtonGroup>
        </Td>
      </Tr>
    </>
  );
};

const List = () => {
  const toast = useToast();
  const { pathname } = useLocation();
  const recordsPerPage = 10;
  const [page, setPage] = useState(1);
  const skip = (page - 1) * recordsPerPage;

  const { data: blogCatsData } = useGetBlogCategoriesQuery();
  const [categoryFilter, setCategoryFilter] = useState('');

  const { data, loading, error, refetch } = useGetBlogPostsQuery({
    variables: {
      recordsPerPage,
      skip,
      where: {
        ...(categoryFilter && {
          category: { is: { id: { equals: categoryFilter } } },
        }),
      },
    },
  });

  const totalPages = data
    ? Math.ceil(data?.blogPosts.total / recordsPerPage)
    : 0;

  const [deleteBlogPost, { loading: deleteLoading }] =
    useDeleteBlogPostMutation();

  useEffect(() => {
    refetch();
  }, [pathname, refetch]);

  const deleteHandler = async (id: any) => {
    try {
      const res = await deleteBlogPost({ variables: { id } });
      if (res) refetch();
    } catch (error) {
      console.log('catch err ⚠️', error);
      return toast({
        title: 'Hata',
        description: error.message,
        status: 'error',
        duration: 2500,
        isClosable: true,
      });
    }
  };

  const theadBg = useColorModeValue('blue.200', 'blue.700');

  return (
    <>
      <VStack align='start' mb='4'>
        <Text fontWeight='bold' fontSize='2xl'>
          Blog Yazıları
        </Text>

        <Breadcrumb>
          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to='/'>
              Anasayfa
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink href='#'>Blog Yazıları</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <HStack width='full'>
          <Select
            maxW='md'
            placeholder={!categoryFilter && 'Kategori filtrele'}
            isDisabled={!blogCatsData}
            value={categoryFilter}
            onChange={(e) => setCategoryFilter(e.target.value)}
          >
            {blogCatsData &&
              blogCatsData.blogCategories.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.description[0]?.title}
                </option>
              ))}
          </Select>
          {categoryFilter && (
            <Button colorScheme='red' onClick={() => setCategoryFilter('')}>
              Temizle
            </Button>
          )}
          <Spacer />
          <ButtonGroup isAttached>
            <Button colorScheme='green' as={Link} to={url.BLOGPOSTCREATE}>
              Yeni
            </Button>
            <IconButton
              aria-label='refresh'
              icon={<MdRefresh />}
              onClick={() => refetch()}
            />
          </ButtonGroup>
        </HStack>
      </VStack>
      {error && <MessageBox message={error.message} status='error' />}
      {loading ? (
        <Loading />
      ) : data!.blogPosts.posts.length <= 0 ? (
        <MessageBox
          status='warning'
          message='Henüz hiçbir blog yazısı oluşturmamışsınız. Yeni bir tane oluşturmak için yeni
          butonunu kullanın.'
        />
      ) : (
        <>
          <Table>
            <Thead backgroundColor={theadBg}>
              <Tr>
                <Th>#</Th>
                <Th>Başlık</Th>
                <Th textAlign='center'>Kategori</Th>
                <Th isNumeric>İşlem</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data?.blogPosts.posts.map((blogPost, index) => (
                <TableItem
                  skip={skip}
                  key={index}
                  index={index}
                  item={blogPost}
                  deleteHandler={deleteHandler}
                  deleteLoading={deleteLoading}
                  refetch={refetch}
                />
              ))}
            </Tbody>
          </Table>
        </>
      )}
      {data && (
        <Pagination page={page} setPage={setPage} totalPage={totalPages} />
      )}
    </>
  );
};

export default List;
