import { gql } from '@apollo/client';
export const CHECK_REFRESH_TOKEN = gql`
  mutation checkRefreshToken($refreshToken: String!) {
    checkRefreshToken(refreshToken: $refreshToken) {
      accessToken
      refreshToken
      id
    }
  }
`;
