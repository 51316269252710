import { Box, Container, Heading, Text, VStack } from '@chakra-ui/layout';
import { useEffect, useState } from 'react';
import { Input } from '../../components/Input/Input';
import {
  AmountSeparator,
  EcommerceSettingsUpdateInput,
  GetEcommerceSettingsQueryResult,
  useGetEcommerceSettingsQuery,
  useUpdateEcommerceSettingsMutation,
} from '../../generated/graphql';
import { Select } from '../../components/Input/Select';
import { Button } from '@chakra-ui/button';
import { useToast } from '@chakra-ui/toast';
import { FormControl, FormLabel } from '@chakra-ui/form-control';
import { Switch } from '@chakra-ui/switch';
import { NumberSlider } from '../../components/Input/NumberSlider';
import FormSkeleton from '../../components/FormSkeleton';

interface FormProps {
  data: GetEcommerceSettingsQueryResult['data']['ecommerceSettings'];
  saving: boolean;
  onSave: (input: EcommerceSettingsUpdateInput) => void;
}

const Form = ({ data, onSave, saving }: FormProps) => {
  const [active, setActive] = useState(data.active);
  const [deliveryRadius, setDeliveryRadius] = useState(data.deliveryRadius);
  const [defaultShippingCountry, setDefaultShippingCountry] = useState(
    data.defaultShippingCountry
  );
  const [defaultShippingState, setDefaultShippingState] = useState(
    data.defaultShippingState
  );
  const [defaultShippingCity, setDefaultShippingCity] = useState(
    data.defaultShippingCity
  );
  const [currencyCode, setCurrencyCode] = useState(data.currencyCode);
  const [currencyFormat, setCurrencyFormat] = useState(data.currencyFormat);
  const [currencySymbol, setCurrencySymbol] = useState(data.currencySymbol);
  const [decimalNumber, setDecimalNumber] = useState(data.decimalNumber);
  const [amountSeparator, setAmountSeparator] = useState(data.amountSeparator);
  const [weightUnit, setWeightUnit] = useState(data.weightUnit);
  const [lengthUnit, setLengthUnit] = useState(data.lengthUnit);
  const [orderConfirmationCopyTo, setOrderConfirmationCopyTo] = useState(
    data.orderConfirmationCopyTo
  );

  return (
    <>
      <Box
        bg={active ? 'green.400' : 'tomato'}
        mb='10'
        w='100%'
        p={4}
        color='white'
      >
        <FormControl
          display='flex'
          justifyContent='space-between'
          alignItems='center'
        >
          <FormLabel htmlFor='email-alerts' mb='0'>
            Ecommerce plugin is {active ? 'enabled' : 'disabled'}
          </FormLabel>
          <Switch
            id='email-alerts'
            defaultChecked={active}
            onChange={(e) => setActive(e.target.checked)}
          />
        </FormControl>
      </Box>
      {active && (
        <Box w='100%' p={5}>
          <Input
            state={orderConfirmationCopyTo}
            setState={setOrderConfirmationCopyTo}
            label='Send Order Confirmation Copy'
          />
          <NumberSlider
            label='Delivery Radius'
            state={deliveryRadius}
            setState={setDeliveryRadius}
          />
          <Input
            state={defaultShippingCountry}
            setState={setDefaultShippingCountry}
            label='Shipping Country'
          />
          <Input
            state={defaultShippingState}
            setState={setDefaultShippingState}
            label='Shipping State'
          />
          <Input
            state={defaultShippingCity}
            setState={setDefaultShippingCity}
            label='Shipping City'
          />

          <Input
            state={currencyCode}
            setState={setCurrencyCode}
            label='Currency Code'
          />

          <Input
            state={currencySymbol}
            setState={setCurrencySymbol}
            label='Currency Symbol'
          />
          <Box>
            <Input
              state={currencyFormat}
              setState={setCurrencyFormat}
              label='Currency Format'
            />
            <Box textAlign='right' mt='-7' pb='7'>
              <Text as='sub'>example: 50{currencySymbol}</Text>
            </Box>
          </Box>

          <Select
            label='Amount Separator'
            state={amountSeparator}
            setState={setAmountSeparator}
          >
            <option value={AmountSeparator.COMMADOT}>1,000,000.59</option>
            <option value={AmountSeparator.DOTCOMMA}>1.000.000,59</option>
          </Select>

          <Input
            state={decimalNumber.toString()}
            setState={setDecimalNumber}
            label='Decimal Number'
          />
          <Select
            label='Weight Unit'
            state={weightUnit}
            setState={setWeightUnit}
          >
            <option value='metric'>Metric (KG)</option>
            <option value='imperial'>Imperial (POUND)</option>
          </Select>

          <Select
            label='Length Unit'
            state={lengthUnit}
            setState={setLengthUnit}
          >
            <option value='metric'>Metric (Meter)</option>
            <option value='imperial'>Imperial (Feet)</option>
          </Select>
        </Box>
      )}
      <Button
        colorScheme='blue'
        size='lg'
        isLoading={saving}
        disabled={saving}
        onClick={() =>
          onSave({
            active: { set: active },
            orderConfirmationCopyTo: { set: orderConfirmationCopyTo },
            deliveryRadius: { set: deliveryRadius },
            defaultShippingCountry: { set: defaultShippingCountry },
            defaultShippingState: { set: defaultShippingState },
            defaultShippingCity: { set: defaultShippingCity },
            currencyCode: { set: currencyCode },
            currencySymbol: { set: currencySymbol },
            currencyFormat: { set: currencyFormat },
            amountSeparator: { set: amountSeparator },
            decimalNumber: { set: decimalNumber },
            weightUnit: { set: weightUnit },
            lengthUnit: { set: lengthUnit },
          })
        }
      >
        Save
      </Button>
    </>
  );
};

const EcommerceSettings = () => {
  const { data, loading, error } = useGetEcommerceSettingsQuery();
  const [updateDataMt, { data: response, loading: responseLoading }] =
    useUpdateEcommerceSettingsMutation();
  const toast = useToast();

  useEffect(() => {
    if (response) {
      toast({
        title: 'Saved',
        description: 'Settings has been updated',
        isClosable: true,
        duration: 4000,
        status: 'success',
      });
    }
  }, [response, toast]);

  useEffect(() => {
    if (error) {
      toast({
        title: 'Error',
        description: error.message,
        isClosable: true,
        duration: 4000,
        status: 'error',
      });
    }
  }, [error, toast]);

  const onSave = (input: EcommerceSettingsUpdateInput) => {
    updateDataMt({
      variables: {
        data: input,
      },
    });
  };

  return (
    <VStack align='center' mb='4'>
      <Heading>Ecommerce Settings</Heading>
      <Container width='full' justifyItems='space-evenly' paddingTop='7%'>
        {loading ? (
          <FormSkeleton amount={8} />
        ) : (
          <Form
            data={data.ecommerceSettings}
            onSave={onSave}
            saving={responseLoading}
          />
        )}
      </Container>
    </VStack>
  );
};

export default EcommerceSettings;
