import { Button, SimpleGrid } from '@chakra-ui/react';
import { nanoid } from 'nanoid';

import { ProductFile } from '../types';
import ProductFileInput from './ProductFileInput';

interface Props {
  files: ProductFile[];
  setFiles: React.Dispatch<React.SetStateAction<ProductFile[]>>;
}

const ProductFileCard: React.FC<Props> = ({ files, setFiles }) => {
  const addCardField = () => {
    setFiles((prev) => [
      ...prev,
      {
        id: nanoid(),
        title: '',
        file_name: '',
        language: 'tr',
      },
    ]);
  };

  const removeCardField = (id) => {
    const cards = [...files];
    cards.splice(
      cards.findIndex((value) => value.id === id),
      1
    );
    setFiles(cards);
  };

  const handleCardInputChange = (id, event) => {
    let copied = JSON.parse(JSON.stringify(files));
    const changedInputs = copied.map((input) => {
      if (id === input.id) {
        input[event.target.name] = event.target.value;
      }
      return input;
    });
    setFiles(changedInputs);
  };

  return (
    <>
      <Button onClick={addCardField} mb='4' colorScheme='orange'>
        Dosya ekle
      </Button>
      <SimpleGrid columns={[1, 4]} spacing='4'>
        {files.map((file, i) => (
          <ProductFileInput
            key={file.id}
            index={i}
            removeCardField={removeCardField}
            files={files}
            file={file}
            handleCardInputChange={handleCardInputChange}
            setFiles={setFiles}
          />
        ))}
      </SimpleGrid>
    </>
  );
};

export default ProductFileCard;
