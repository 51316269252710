import ReactDOM from 'react-dom';
import ApolloProvider from './ApolloProvider';
import { ChakraProvider } from '@chakra-ui/react';
import Router from './Router';
import './styles/global.css';
import theme from './styles/theme';
import { BrowserRouter } from 'react-router-dom';

const RenderApp = () => {
  return (
    <ApolloProvider>
      <BrowserRouter>
        <ChakraProvider resetCSS theme={theme}>
          <Router />
        </ChakraProvider>
      </BrowserRouter>
    </ApolloProvider>
  );
};

ReactDOM.render(<RenderApp />, document.getElementById('root'));
