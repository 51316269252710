import { Container, Heading, VStack } from '@chakra-ui/layout';
import { useEffect, useState } from 'react';
import { Input } from '../../components/Input/Input';
import {
  AppSettingsUpdateInput,
  GetAppSettingsQueryResult,
  useGetAppSettingsQuery,
  useUpdateAppSettingsMutation,
} from '../../generated/graphql';
import { timezones } from '../../data/timezones.json';
import { Select } from '../../components/Input/Select';
import { Button } from '@chakra-ui/button';
import { useToast } from '@chakra-ui/toast';
import FormSkeleton from '../../components/FormSkeleton';

interface FormProps {
  data: GetAppSettingsQueryResult['data']['appSettings'];
  saving: boolean;
  onSave: (input: AppSettingsUpdateInput) => void;
}

const Form = ({ data, onSave, saving }: FormProps) => {
  const [appName, setAppName] = useState(data.appName);
  const [domain, setDomain] = useState(data.domain);
  const [language, setLanguage] = useState(data.language);
  const [logo, setLogo] = useState(data.logoFile);
  const [timeZone, setTimeZone] = useState(data.timeZone);
  const [dateFormat, setDateFormat] = useState(data.dateFormat);
  const [timeFormat, setTimeFormat] = useState(data.timeFormat);

  return (
    <>
      <Input state={appName} setState={setAppName} label='App Name' />
      <Input state={domain} setState={setDomain} label='Domain' />
      <Input state={language} setState={setLanguage} label='Language' />
      <Input state={logo} setState={setLogo} label='Logo' />
      <Select label='TimeZone' state={timeZone} setState={setTimeZone}>
        {Object.values(timezones.sort((a, b) => (a > b ? 1 : -1))).map(
          (x, i) => (
            <option key={i} value={x}>
              {x}
            </option>
          )
        )}
      </Select>
      <Input state={dateFormat} setState={setDateFormat} label='DateFormat' />
      <Input state={timeFormat} setState={setTimeFormat} label='Timeformat' />

      <Button
        colorScheme='blue'
        size='lg'
        isLoading={saving}
        disabled={saving}
        onClick={() =>
          onSave({
            appName: { set: appName },
            dateFormat: { set: dateFormat },
            domain: { set: domain },
            language: { set: language },
            logoFile: { set: logo },
            timeFormat: { set: timeFormat },
            timeZone: { set: timeZone },
          })
        }
      >
        Save
      </Button>
    </>
  );
};

const AppSettings = () => {
  const { data, loading, error } = useGetAppSettingsQuery();
  const [updateDataMt, { data: response, loading: responseLoading }] =
    useUpdateAppSettingsMutation();
  const toast = useToast();

  useEffect(() => {
    if (response) {
      toast({
        title: 'Saved',
        description: 'Settings has been updated',
        isClosable: true,
        duration: 4000,
        status: 'success',
      });
    }
  }, [response, toast]);

  useEffect(() => {
    if (error) {
      toast({
        title: 'Error',
        description: error.message,
        isClosable: true,
        duration: 4000,
        status: 'error',
      });
    }
  }, [error, toast]);

  const onSave = (input: AppSettingsUpdateInput) => {
    updateDataMt({
      variables: {
        data: input,
      },
    });
  };

  return (
    <VStack align='center' mb='4'>
      <Heading>App Settings</Heading>
      <Container width='full' justifyItems='space-evenly' paddingTop='7%'>
        {loading ? (
          <FormSkeleton amount={8} />
        ) : (
          <Form
            data={data.appSettings}
            onSave={onSave}
            saving={responseLoading}
          />
        )}
      </Container>
    </VStack>
  );
};

export default AppSettings;
