import { useState } from 'react';
import {
  Input,
  FormControl,
  FormLabel,
  Button,
  SimpleGrid,
  Select,
  GridItem,
  Textarea,
} from '@chakra-ui/react';

import { useHistory, useParams } from 'react-router-dom';
import MessageBox from '../../components/MessageBox';

import {
  GetReaderCommentQueryResult,
  useUpdateReaderCommentMutation,
  useGetProductsQuery,
} from '../../generated/graphql';

interface Props {
  data: GetReaderCommentQueryResult['data']['readerComment'];
}

const EditForm: React.FC<Props> = ({ data }) => {
  const { id } = useParams<{ id: string }>();
  const { push } = useHistory();

  const [name, setName] = useState(data.name || '');
  const [productId, setProductId] = useState(data.product.id || '');
  const [comment, setComment] = useState(data.comment || '');

  const [error, setError] = useState('');

  const [updateComment, { loading }] = useUpdateReaderCommentMutation();

  const { data: productsData } = useGetProductsQuery({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await updateComment({
        variables: {
          id,
          name,
          comment,
        },
      });

      setError('');
      push('/readercomments');
    } catch (error) {
      console.log('err ⚠️', error);
      setError(error.message);
    }
  };

  return (
    <form onSubmit={onSubmit}>
      {error && <MessageBox status='warning' message={error} />}

      <SimpleGrid columns={2} spacing={10}>
        <FormControl isRequired mb='4'>
          <FormLabel>Yorum yapan kişi</FormLabel>
          <Input
            type='text'
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </FormControl>
        <FormControl isRequired isDisabled={!productsData}>
          <FormLabel>Ürün</FormLabel>
          <Select
            value={productId}
            onChange={(e) => setProductId(e.target.value)}
          >
            <option value=''>--</option>
            {productsData &&
              productsData.products.products.map((product) => (
                <option value={product.id} key={product.id}>
                  {product.descriptions[0]?.title || 'Adsız Ürün'}
                </option>
              ))}
          </Select>
        </FormControl>

        <GridItem colSpan={2}>
          <FormControl isRequired>
            <FormLabel>Yorum</FormLabel>
            <Textarea
              name='comment'
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              placeholder='yorum...'
              resize='none'
            ></Textarea>
          </FormControl>
        </GridItem>
      </SimpleGrid>

      <Button colorScheme='blue' type='submit' mt='10px' isLoading={loading}>
        Kaydet
      </Button>
    </form>
  );
};

export default EditForm;
