import {
  Input,
  FormControl,
  FormLabel,
  Button,
  Container,
  Stack,
} from '@chakra-ui/react';
import moment from 'moment';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import MessageBox from '../../components/MessageBox';

import {
  GetEventQueryResult,
  useUpdateEventMutation,
} from '../../generated/graphql';

interface Props {
  data: GetEventQueryResult['data']['findFirstEvent'];
}

const EditForm: React.FC<Props> = ({ data }) => {
  const { id } = useParams<{ id: string }>();
  const { push } = useHistory();
  console.log('data yokmu 👉 ::', data);
  const [startDate, setStartDate] = useState(
    moment(data.startDate).format('YYYY-MM-DD') || ''
  );
  const [endDate, setEndDate] = useState(
    moment(data.endDate).format('YYYY-MM-DD') || ''
  );

  const [error, setError] = useState('');

  const [updateEvent, { loading }] = useUpdateEventMutation();

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await updateEvent({
        variables: {
          id,
          endDate,
          startDate,
        },
      });

      setError('');
      push('/events');
    } catch (error) {
      console.log('err ⚠️', error);
      setError(error.message);
    }
  };

  return (
    <Container width='full' ml='0' pl='0'>
      <form onSubmit={onSubmit}>
        {error && <MessageBox status='warning' message={error} />}
        <Stack direction={{ base: 'column', lg: 'row' }} spacing='6'>
          <FormControl isRequired>
            <FormLabel>Başlangıç Tarihi</FormLabel>
            <Input
              type='date'
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Bitiş Tarihi</FormLabel>
            <Input
              type='date'
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </FormControl>
        </Stack>

        <Button colorScheme='blue' type='submit' mt='10px' isLoading={loading}>
          Kaydet
        </Button>
      </form>
    </Container>
  );
};

export default EditForm;
