import {
  Button,
  ButtonGroup,
  IconButton,
  Text,
  VStack,
  useToast,
  HStack,
  Spacer,
  useColorModeValue,
  Td,
  Tr,
  Table,
  Tbody,
  Th,
  Thead,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from '@chakra-ui/react';
import { CgReorder } from 'react-icons/cg';

import { MdRefresh } from 'react-icons/md';
import { Link, useParams } from 'react-router-dom';
import DeletePopover from '../../components/DeletePopover';
import DescriptionModal from '../../components/Description';

import Loading from '../../components/Loading';
import MessageBox from '../../components/MessageBox';
import {
  useDeleteContentMutation,
  useGetBlogPostContentsQuery,
} from '../../generated/graphql';

const List = () => {
  const { postId } = useParams<{ postId: string }>();
  const toast = useToast();
  const { data, loading, error, refetch } = useGetBlogPostContentsQuery({
    variables: { postId },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const [deleteContent, { loading: deleteLoading }] =
    useDeleteContentMutation();

  const deleteHandler = async (id: any) => {
    try {
      const res = await deleteContent({ variables: { id } });
      if (res) refetch();
    } catch (error) {
      console.log('catch err ⚠️', error);
      return toast({
        title: 'Hata',
        description: error.message,
        status: 'error',
        duration: 2500,
        isClosable: true,
      });
    }
  };

  const theadBg = useColorModeValue('blue.200', 'blue.700');
  const trBg = useColorModeValue('gray.100', 'gray.700');
  const trBorderColor = useColorModeValue('whiteAlpha.900', 'whiteAlpha.200');

  return (
    <>
      <VStack align='start' mb='4'>
        <Text fontWeight='bold' fontSize='2xl'>
          Blog Yazısı içerikleri
        </Text>

        <Breadcrumb>
          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to='/'>
              Anasayfa
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem as={Link} to='/blogpost'>
            <BreadcrumbLink href='#'>Blog Yazıları</BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink href='#'>Blog Yazı İçerikleri</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <HStack width='full'>
          <Button colorScheme='blue' as={Link} to='/blogpost'>
            Geri
          </Button>
          <Spacer />
          <ButtonGroup isAttached>
            <Button
              colorScheme='green'
              as={Link}
              to={`/blogpost/content/${postId}/new`}
            >
              Yeni
            </Button>
            <IconButton
              aria-label='ordering'
              colorScheme='purple'
              icon={<CgReorder size='20' />}
              as={Link}
              to={`/blogpost/content/${postId}/order`}
            />
            <IconButton
              aria-label='refresh'
              icon={<MdRefresh />}
              onClick={() => refetch()}
            />
          </ButtonGroup>
        </HStack>
      </VStack>
      {error && <MessageBox message={error.message} status='error' />}
      {loading ? (
        <Loading />
      ) : data!.contents.length <= 0 ? (
        <MessageBox
          status='warning'
          message='Henüz hiçbir içerik oluşturmamışsınız. Yeni bir tane oluşturmak için yeni
          butonunu kullanın.'
        />
      ) : (
        <Table>
          <Thead backgroundColor={theadBg}>
            <Tr>
              <Th>#</Th>
              <Th>Başlık</Th>
              <Th>İçerik Türü</Th>
              <Th isNumeric>İşlem</Th>
            </Tr>
          </Thead>
          <Tbody>
            {data.contents.map((element, index) => (
              <Tr
                key={element.id}
                backgroundColor={trBg}
                border='2px'
                borderColor={trBorderColor}
              >
                <Td>{index + 1}</Td>
                <Td>
                  <DescriptionModal
                    descriptionProp={element.description}
                    field='content'
                    fieldId={element.id}
                    refetch={refetch}
                  />
                </Td>
                <Td>{element.contentType}</Td>
                <Td isNumeric>
                  <ButtonGroup size='sm' variant='solid'>
                    <Button
                      colorScheme='teal'
                      as={Link}
                      to={`/blogpost/content/${element.id}/update`}
                    >
                      Düzenle
                    </Button>
                    <DeletePopover
                      deleteHandler={deleteHandler}
                      pageId={element.id}
                      isLoading={deleteLoading}
                    />
                  </ButtonGroup>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      )}
    </>
  );
};

export default List;
