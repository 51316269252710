import {
  Button,
  ButtonGroup,
  IconButton,
  Text,
  VStack,
  useToast,
  HStack,
  Spacer,
  Td,
  Tr,
  Table,
  Tbody,
  Th,
  Thead,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  useColorModeValue,
} from '@chakra-ui/react';
import { CgReorder } from 'react-icons/cg';
import { FaCheck } from 'react-icons/fa';

import { MdRefresh } from 'react-icons/md';
import { Link } from 'react-router-dom';
import DeletePopover from '../../components/DeletePopover';
import DescriptionModal from '../../components/Description';
import Loading from '../../components/Loading';
import MessageBox from '../../components/MessageBox';
import {
  useDeleteBlogCategoryMutation,
  useGetBlogCategoriesQuery,
} from '../../generated/graphql';
import url from '../../services/url';

const List = () => {
  const toast = useToast();
  const { data, loading, error, refetch } = useGetBlogCategoriesQuery({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const [deleteBlogCategory, { loading: deleteLoading }] =
    useDeleteBlogCategoryMutation();

  const deleteHandler = async (id: any) => {
    try {
      const res = await deleteBlogCategory({ variables: { id } });
      if (res) refetch();
    } catch (error) {
      return toast({
        title: 'Hata',
        description: error.message,
        status: 'error',
        duration: 2500,
        isClosable: true,
      });
    }
  };

  const theadBg = useColorModeValue('blue.200', 'blue.700');
  const trBg = useColorModeValue('gray.100', 'gray.700');
  const trBorderColor = useColorModeValue('whiteAlpha.900', 'whiteAlpha.200');

  return (
    <>
      <VStack align='start' mb='4'>
        <Text fontWeight='bold' fontSize='2xl'>
          Blog Kategorileri
        </Text>

        <Breadcrumb>
          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to='/'>
              Anasayfa
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink href='#'>Blog Kategorileri</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <HStack width='full'>
          <Spacer />
          <ButtonGroup isAttached>
            <Button colorScheme='green' as={Link} to={url.BLOGCATEGORYCREATE}>
              Yeni
            </Button>
            <IconButton
              aria-label='ordering'
              colorScheme='purple'
              icon={<CgReorder size='20' />}
              as={Link}
              to='/blogcategories/order'
            />
            <IconButton
              aria-label='refresh'
              icon={<MdRefresh />}
              onClick={() => refetch()}
            />
          </ButtonGroup>
        </HStack>
      </VStack>
      {error && <MessageBox message={error.message} status='error' />}
      {loading ? (
        <Loading />
      ) : data!.blogCategories.length <= 0 ? (
        <MessageBox
          status='warning'
          message='Henüz hiçbir blog kategorisi oluşturmamışsınız. Yeni bir tane oluşturmak için yeni
          butonunu kullanın.'
        />
      ) : (
        <Table>
          <Thead backgroundColor={theadBg}>
            <Tr>
              <Th>#</Th>
              <Th>Başlık</Th>
              <Th textAlign='center'>Gizli</Th>
              <Th isNumeric>İşlem</Th>
            </Tr>
          </Thead>
          <Tbody>
            {data.blogCategories.map((element, i) => (
              <Tr
                key={element.id}
                backgroundColor={trBg}
                border='2px'
                borderColor={trBorderColor}
              >
                <Td>{i + 1}</Td>
                <Td>
                  <DescriptionModal
                    descriptionProp={element.description}
                    field='blogCategory'
                    fieldId={element.id}
                    refetch={refetch}
                  />
                </Td>
                <Td>
                  <FaCheck
                    color={element.hidden ? '#27D61C' : 'gray'}
                    size='20'
                    style={{ margin: '0 auto' }}
                  />
                </Td>
                <Td isNumeric>
                  <ButtonGroup size='sm' variant='solid'>
                    <Button
                      colorScheme='teal'
                      as={Link}
                      to={`/blogcategories/update/${element.id}`}
                    >
                      Düzenle
                    </Button>
                    <DeletePopover
                      deleteHandler={deleteHandler}
                      pageId={element.id}
                      isLoading={deleteLoading}
                    />
                  </ButtonGroup>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      )}
    </>
  );
};

export default List;
