import { Box, Text, VStack, Button, Divider } from '@chakra-ui/react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import ContentDescription from '../../components/ContentDescription';
import MessageBox from '../../components/MessageBox';

import { useCreateFaqMutation } from '../../generated/graphql';

const Add = () => {
  const { push } = useHistory();

  // description inputs
  const [title, setTitle] = useState('');
  const [language] = useState('tr');
  const [description, setDescription] = useState('');
  const [shortDescription, setShortDescription] = useState('');
  const [buttonText, setButtonText] = useState('');
  const [buttonUrl, setButtonUrl] = useState('');

  const [error, setError] = useState('');

  const [createFaq, { loading }] = useCreateFaqMutation();

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await createFaq({
        variables: {
          title,
          description,
          shortDescription,
          language,
        },
      });

      setError('');
      push('/faqs');
    } catch (error) {
      console.log('err ::', error);
      setError(error.message);
    }
  };

  return (
    <>
      <VStack align='flex-start' mb='4'>
        <Text fontWeight='bold' fontSize='2xl'>
          Yeni SSS Sorusu
        </Text>
      </VStack>
      <form onSubmit={onSubmit}>
        <Box>
          {error && <MessageBox status='warning' message={error} />}

          <Divider my='6' />
          <ContentDescription
            title={title}
            setTitle={setTitle}
            description={description}
            setDescription={setDescription}
            shortDescription={shortDescription}
            setShortDescription={setShortDescription}
            buttonText={buttonText}
            setButtonText={setButtonText}
            buttonUrl={buttonUrl}
            setButtonUrl={setButtonUrl}
            buttonTextDisabled={true}
            buttonUrlDisabled={true}
            shortDescriptionDisabled={true}
          />

          <Button
            colorScheme='blue'
            type='submit'
            mt='10px'
            isLoading={loading}
          >
            Kaydet
          </Button>
        </Box>
      </form>
    </>
  );
};

export default Add;
