import {
  Input,
  FormControl,
  FormLabel,
  Box,
  Text,
  VStack,
  Button,
  Select,
  SimpleGrid,
  GridItem,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import MessageBox from '../../components/MessageBox';

import {
  useCreateUserMutation,
  UserRole,
  UserStatusType,
} from '../../generated/graphql';

const Add = () => {
  const { push } = useHistory();

  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [password, setPassword] = useState('');
  const [status, setStatus] = useState<UserStatusType>(UserStatusType.ACTIVE);
  const [role, setRole] = useState<UserRole>(UserRole.ADMIN);
  const [identityNumber, setIdentityNumber] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  const [error, setError] = useState('');

  const [createUser, { loading }] = useCreateUserMutation();

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await createUser({
        variables: {
          data: {
            email,
            name,
            surname,
            password,
            status,
            role,
            identityNumber,
            phoneNumber,
          },
        },
      });

      push('/users');
    } catch (error) {
      console.log('err ⚠️', error.graphQLErrors);
      setError(error.message);
    }
  };

  return (
    <>
      <VStack align='flex-start' mb='4'>
        <Text fontWeight='bold' fontSize='2xl'>
          Yeni Yönetici Oluştur
        </Text>
      </VStack>
      <form onSubmit={onSubmit}>
        <Box width='full' mb='8'>
          {error && <MessageBox status='warning' message={error} />}

          <SimpleGrid columns={[1, 3]} spacing={[2, 8]}>
            <GridItem>
              <FormControl isRequired>
                <FormLabel>Ad</FormLabel>
                <Input
                  type='text'
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </FormControl>
            </GridItem>

            <GridItem>
              <FormControl isRequired>
                <FormLabel>Soyad</FormLabel>
                <Input
                  type='text'
                  value={surname}
                  onChange={(e) => setSurname(e.target.value)}
                />
              </FormControl>
            </GridItem>

            <GridItem>
              <FormControl isRequired>
                <FormLabel>Email</FormLabel>
                <Input
                  type='text'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </FormControl>
            </GridItem>

            <GridItem>
              <FormControl isRequired>
                <FormLabel>Şifre</FormLabel>
                <Input
                  type='password'
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </FormControl>
            </GridItem>

            <GridItem>
              <FormControl>
                <FormLabel>Durum</FormLabel>
                <Select
                  value={status}
                  onChange={(e) => setStatus(e.target.value as UserStatusType)}
                >
                  {Object.keys(UserStatusType).map((type) => (
                    <option value={type}>{type}</option>
                  ))}
                </Select>
              </FormControl>
            </GridItem>

            <GridItem>
              <FormControl>
                <FormLabel>Rol</FormLabel>
                <Select
                  value={role}
                  onChange={(e) => setRole(e.target.value as UserRole)}
                >
                  {Object.keys(UserRole).map((type) => (
                    <option value={type}>{type}</option>
                  ))}
                </Select>
              </FormControl>
            </GridItem>

            <GridItem>
              <FormControl>
                <FormLabel>Kimlik Numarası</FormLabel>
                <Input
                  type='text'
                  value={identityNumber}
                  onChange={(e) => setIdentityNumber(e.target.value)}
                />
              </FormControl>
            </GridItem>

            <GridItem>
              <FormControl>
                <FormLabel>Telefon Numarası</FormLabel>
                <Input
                  type='text'
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </FormControl>
            </GridItem>
          </SimpleGrid>

          <Button
            colorScheme='blue'
            type='submit'
            mt='10px'
            isLoading={loading}
          >
            Kaydet
          </Button>
        </Box>
      </form>
    </>
  );
};

export default Add;
