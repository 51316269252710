import { CircularProgress, Flex, FlexProps } from '@chakra-ui/react';

const Loading: React.FC<FlexProps> = (props) => {
  return (
    <Flex alignItems='center' justifyContent='center' {...props}>
      <CircularProgress isIndeterminate color='green.500' />
    </Flex>
  );
};

export default Loading;
