import { extendTheme } from '@chakra-ui/react';
import { createBreakpoints } from '@chakra-ui/theme-tools';

const breakpoints = createBreakpoints({
  sm: '30em',
  md: '48em',
  lg: '62em',
  xl: '80em',
  '2xl': '96em',
});

export default extendTheme({
  breakpoints,
  fontWeights: {
    normal: 300,
    medium: 600,
    bold: 700,
  },
  components: {
    Button: {
      baseStyle: { rounded: 'sm', _focus: { boxShadow: 'none' } },
    },
    Switch: {
      baseStyle: {
        track: {
          _focus: { boxShadow: 'none' },
        },
      },
    },
  },
});
