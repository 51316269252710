const url = {
  HOME: '/',
  LOGIN: '/login',
  DASHBOARD: '/dashboard',
  // PAGES
  PAGESLIST: '/pages',
  PAGESORDER: '/pages/order',
  PAGESCREATE: '/pages/new',
  PAGESUPDATE: '/pages/update/:id',
  PAGESCONTENTLIST: '/pages/content/:pageId',
  PAGESCONTENTORDER: '/pages/content/:pageId/order',
  PAGESCONTENTCREATE: '/pages/content/:pageId/new',
  PAGESCONTENTUPDATE: '/pages/content/:id/update',
  // CONTACTS
  CONTACTSLIST: '/contact',
  CONTACTSCREATE: '/contact/new',
  CONTACTSUPDATE: '/contact/update/:id',
  // FORMIO
  FORMIOLIST: '/formioForms',
  FORMIOCREATE: '/formioForms/new',
  FORMIOORDER: '/formioForms/order',
  FORMIOUPDATE: '/formioForms/update/:id',
  // BLOG
  BLOGCATEGORYLIST: '/blogcategories',
  BLOGCATEGORYCREATE: '/blogcategories/new',
  BLOGCATEGORYORDER: '/blogcategories/order',
  BLOGCATEGORYSUPDATE: '/blogcategories/update/:id',
  BLOGPOSTLIST: '/blogpost',
  BLOGPOSTCREATE: '/blogpost/new',
  BLOGPOSTUPDATE: '/blogpost/update/:id',
  BLOGPOSTCONTENTLIST: '/blogpost/content/:postId',
  BLOGPOSTCONTENTORDER: '/blogpost/content/:postId/order',
  BLOGPOSTCONTENTCREATE: '/blogpost/content/:postId/new',
  BLOGPOSTCONTENTUPDATE: '/blogpost/content/:id/update',
  // ECOMMERCE
  PRODUCTSLIST: '/products',
  PRODUCTSCREATE: '/products/new',
  PRODUCTSUPDATE: '/products/update/:id',
  PRODUCTSORDER: '/products/order/:categoryId?',
  PRODUCTCONTENTLIST: '/products/content/:productId',
  PRODUCTCONTENTORDER: '/products/content/:productId/order',
  PRODUCTCONTENTCREATE: '/products/content/:productId/new',
  PRODUCTCONTENTUPDATE: '/products/content/:id/update',
  CATEGORIESLIST: '/categories',
  CATEGORIESORDER: '/categories/order',
  CATEGORIESCREATE: '/categories/new',
  CATEGORIESUPDATE: '/categories/update/:id',
  // BRANDS
  BRANDSLIST: '/brands',
  BRANDSCREATE: '/brands/new',
  BRANDSORDER: '/brands/order',
  BRANDSUPDATE: '/brands/update/:id',
  // SETTINGS
  APPSETTINGS: '/settings',
  ECOMMERCESETTINGS: '/ecommerceSettings',
  // USERS
  USERSLIST: '/users',
  USERCREATE: '/users/new',
  USERUPDATE: '/users/update/:id',
  // FORM MESSAGES
  FORMMESAGELIST: '/messages',
  FORMMESAGE: '/messages/:id',
  // FAQ
  FAQLIST: '/faqs',
  FAQCREATE: '/faqs/new',
  // EVENT
  EVENTLIST: '/events',
  EVENTCREATE: '/events/new',
  EVENTUPDATE: '/events/update/:id',
  // EVENT
  READERCOMMENTLIST: '/readercomments',
  READERCOMMENTCREATE: '/readercomments/new',
  READERCOMMENTORDER: '/readercomments/order/:productId',
  READERCOMMENTUPDATE: '/readercomments/update/:id',
  // DESCRIPTION
  DESCRIPTIONUPDATE: '/description/update/:id',
  NEWDESCRIPTION: '/description/new/:pageId',
};

export default url;
