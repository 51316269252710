import {
  Input,
  FormControl,
  FormLabel,
  Box,
  Button,
  SimpleGrid,
  GridItem,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import MessageBox from '../../components/MessageBox';
import GoogleMap from '../../components/GoogleMap';
import {
  GetAddressQueryResult,
  useUpdateAddressMutation,
} from '../../generated/graphql';

interface Props {
  data: GetAddressQueryResult['data']['address'];
}

const EditForm: React.FC<Props> = ({ data }) => {
  const { push } = useHistory();
  const [addressTitle, setAddressTitle] = useState(
    data.title ? data.title : ''
  );
  const [postalCode, setPostalCode] = useState(
    data.postalCode ? data.postalCode : ''
  );
  const [addressLineOne, setAddressLineOne] = useState(
    data.addressLineOne ? data.addressLineOne : ''
  );
  const [addressLineTwo, setAddressLineTwo] = useState(
    data.addressLineTwo ? data.addressLineTwo : ''
  );
  const [latitude, setLatitude] = useState(
    data.latitude ? data.latitude : null
  );
  const [longitude, setLongitude] = useState(
    data.longitude ? data.longitude : null
  );

  const [registrationNumber, setRegistrationNumber] = useState(
    data.contact.registrationNumber ? data.contact.registrationNumber : ''
  );
  const [mersisNumber, setMersisNumber] = useState(
    data.contact.mersisNumber ? data.contact.mersisNumber : ''
  );
  const [kepEmail, setKepEmail] = useState(
    data.contact.kepEmail ? data.contact.kepEmail : ''
  );
  const [email, setEmail] = useState(
    data.contact.email ? data.contact.email : ''
  );
  const [phoneNumber, setPhoneNumber] = useState(
    data.contact.phoneNumber ? data.contact.phoneNumber : ''
  );
  const [faxNumber, setFaxNumber] = useState(
    data.contact.faxNumber ? data.contact.faxNumber : ''
  );
  const [taxNumber, setTaxNumber] = useState(
    data.contact.taxNumber ? data.contact.taxNumber : ''
  );
  const [taxOffice, setTaxOffice] = useState(
    data.contact.taxOffice ? data.contact.taxOffice : ''
  );

  const [error, setError] = useState('');

  const [updateAddress, { loading }] = useUpdateAddressMutation();

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await updateAddress({
        variables: {
          id: data.id,
          addressTitle,
          postalCode,
          addressLineOne,
          addressLineTwo,
          longitude,
          latitude,
          registrationNumber,
          email,
          faxNumber,
          phoneNumber,
          mersisNumber,
          kepEmail,
          taxNumber,
          taxOffice,
        },
      });
      push('/contact');
    } catch (error) {
      console.log('err ⚠️', error.graphQLErrors);
      setError(error.message);
    }
  };

  return (
    <form onSubmit={onSubmit}>
      <Box width='full' mb='8'>
        {error && <MessageBox status='warning' message={error} />}
        <SimpleGrid columns={[1, 2]} spacing={[2, 8]}>
          <FormControl isRequired>
            <FormLabel>Adres Başlığı</FormLabel>
            <Input
              type='text'
              value={addressTitle}
              onChange={(e) => setAddressTitle(e.target.value)}
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Posta Kodu</FormLabel>
            <Input
              type='text'
              value={postalCode}
              onChange={(e) => setPostalCode(e.target.value)}
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Adres Satır 1</FormLabel>
            <Input
              type='text'
              value={addressLineOne}
              onChange={(e) => setAddressLineOne(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Adres Satır 2</FormLabel>
            <Input
              type='text'
              value={addressLineTwo}
              onChange={(e) => setAddressLineTwo(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Sicil Numarası</FormLabel>
            <Input
              type='text'
              value={registrationNumber}
              onChange={(e) => setRegistrationNumber(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Telefon Numarası</FormLabel>
            <Input
              type='text'
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Faks Numarası</FormLabel>
            <Input
              type='text'
              value={faxNumber}
              onChange={(e) => setFaxNumber(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Email</FormLabel>
            <Input
              type='text'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Mersis Numarası</FormLabel>
            <Input
              type='text'
              value={mersisNumber}
              onChange={(e) => setMersisNumber(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel>KEP Email</FormLabel>
            <Input
              type='text'
              value={kepEmail}
              onChange={(e) => setKepEmail(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Vergi No</FormLabel>
            <Input
              type='text'
              value={taxNumber}
              onChange={(e) => setTaxNumber(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Vergi Dairesi</FormLabel>
            <Input
              type='text'
              value={taxOffice}
              onChange={(e) => setTaxOffice(e.target.value)}
            />
          </FormControl>
          <GridItem colSpan={2}>
            <FormControl>
              <FormLabel>Harita Pin</FormLabel>
              <GoogleMap
                latitude={latitude}
                setLatitude={setLatitude}
                longitude={longitude}
                setLongitude={setLongitude}
              />
            </FormControl>
          </GridItem>
        </SimpleGrid>
        <Button colorScheme='blue' type='submit' mt='10px' isLoading={loading}>
          Kaydet
        </Button>
      </Box>
    </form>
  );
};

export default EditForm;
