import {
  useColorMode,
  useColorModeValue,
  IconButton,
  IconButtonProps,
} from '@chakra-ui/react';
import { FaMoon, FaSun } from 'react-icons/fa';

type DarkModeSwitchProps = Omit<IconButtonProps, 'aria-label'>;

export const DarkModeSwitch: React.FC<DarkModeSwitchProps> = (props) => {
  const { toggleColorMode } = useColorMode();
  const text = useColorModeValue('dark', 'light');
  const SwitchIcon = useColorModeValue(FaMoon, FaSun);

  return (
    <IconButton
      size='sm'
      fontSize='md'
      color='current'
      variant='unstyled'
      _focus={{ bgColor: 'none' }}
      display='inline-flex'
      onClick={toggleColorMode}
      icon={<SwitchIcon />}
      aria-label={`Switch to ${text} mode`}
      {...props}
    />
  );
};
