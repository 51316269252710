import { useCallback, useState } from 'react';
import { Emoji, EmojiData, Picker } from 'emoji-mart';
import {
  GridItem,
  FormLabel,
  IconButton,
  FormControl,
  Flex,
  Box,
  Input,
  Textarea,
  Switch,
  useBoolean,
  Button,
} from '@chakra-ui/react';
import { AiOutlineDelete } from 'react-icons/ai';
import { CardField } from '../types';
import { useUploadFileMutation } from '../generated/graphql';
import Loading from './Loading';
import CloudinaryImage from './CloudinaryImage';

interface Props {
  removeCardField: (id: any) => void;
  cardFields: CardField[];
  setCardFields?: React.Dispatch<React.SetStateAction<CardField[]>>;
  card: CardField;
  handleEmojiSelect: (id: any, e: EmojiData) => void;
  handleCardInputChange: (id: any, event: any) => void;
}
const EmojiInput: React.FC<Props> = ({
  card,
  cardFields,
  removeCardField,
  handleEmojiSelect,
  handleCardInputChange,
  setCardFields,
}) => {
  const [showEmojiPanel, setShowEmojiPanel] = useState(false);

  const [isImage, { toggle }] = useBoolean(card.image.length > 2 && true);

  const [uploadPhoto, { loading: uploadLoading }] = useUploadFileMutation();

  const handleImg = useCallback(
    async (id, e: React.ChangeEvent<HTMLInputElement>) => {
      new Promise((resolve) => {
        let copied = JSON.parse(JSON.stringify(cardFields));
        const changedInputs = copied.map(async (input) => {
          if (id === input.id) {
            const { data } = await uploadPhoto({
              variables: { file: e.target.files[0] },
            });
            input['image'] = data.uploadFile.file;
          }
          resolve(input);

          return input;
        });
        Promise.all(changedInputs).then((value) => {
          setCardFields(value as any);
        });
      });
    },
    [cardFields, setCardFields, uploadPhoto]
  );

  return (
    <GridItem key={card.id}>
      <FormControl display='flex' alignItems='center'>
        Kart
        <IconButton
          icon={<AiOutlineDelete size={20} />}
          aria-label='delete card'
          onClick={() => removeCardField(card.id)}
          size='sm'
          variant='ghost'
          colorScheme='red'
          ml='2'
          disabled={cardFields.length <= 1}
        />
      </FormControl>
      <FormControl d='flex' alignItems='center' justifyContent='center'>
        <FormLabel margin='0' mr='2'>
          Resimli
        </FormLabel>
        <Switch defaultChecked={isImage} onChange={toggle} />
      </FormControl>
      {isImage ? (
        <FormControl>
          {uploadLoading ? (
            <Loading />
          ) : (
            <Flex my='2'>
              <Box mr='8' flexBasis='45%' my='auto'>
                <FormControl>
                  <Button
                    htmlFor={`imageInput${card.id}`}
                    as='label'
                    colorScheme='pink'
                    _hover={{ cursor: 'pointer' }}
                  >
                    Görsel Seç
                  </Button>
                  <Input
                    type='file'
                    id={`imageInput${card.id}`}
                    hidden
                    multiple={false}
                    onChange={(e) => handleImg(card.id, e)}
                  />
                </FormControl>
              </Box>
              {card.image && (
                <Box maxHeight='52px' flexBasis='55%'>
                  <CloudinaryImage
                    publicId={card.image}
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'contain',
                    }}
                  />
                </Box>
              )}
            </Flex>
          )}
        </FormControl>
      ) : (
        <FormControl>
          <Flex my='2' position='relative'>
            <Emoji
              size={30}
              emoji={card.icon || 'satisfied'}
              onClick={() => setShowEmojiPanel((prev) => !prev)}
            />
            {showEmojiPanel && (
              <Picker
                title='İkon seçin'
                onSelect={(e) => {
                  handleEmojiSelect(card.id, e);
                  setShowEmojiPanel(false);
                }}
                showSkinTones={false}
                showPreview={false}
                style={{ position: 'absolute', zIndex: 50, top: '50px' }}
              />
            )}
          </Flex>
        </FormControl>
      )}

      <FormControl mb='2'>
        <FormLabel>Başlık</FormLabel>
        <Input
          type='text'
          name='title'
          value={card.title}
          onChange={(e) => handleCardInputChange(card.id, e)}
          placeholder='başlık'
        />
      </FormControl>
      <FormControl mb='2'>
        <FormLabel>Açıklama</FormLabel>
        <Textarea
          name='description'
          value={card.description}
          onChange={(e) => handleCardInputChange(card.id, e)}
          placeholder='açıklama'
          resize='none'
        />
      </FormControl>

      <FormControl>
        <FormLabel>URL</FormLabel>
        <Input
          type='text'
          name='url'
          value={card.url}
          onChange={(e) => handleCardInputChange(card.id, e)}
          placeholder='url'
        />
      </FormControl>
    </GridItem>
  );
};

export default EmojiInput;
