import {
  Input,
  FormControl,
  FormLabel,
  Box,
  Text,
  VStack,
  Button,
  Divider,
  SimpleGrid,
  Select,
  FormHelperText,
  Image,
  IconButton,
  Checkbox,
} from '@chakra-ui/react';
import { useState } from 'react';
import { BsTrash } from 'react-icons/bs';
import { useHistory } from 'react-router-dom';
import ContentDescription from '../../components/ContentDescription';
import MessageBox from '../../components/MessageBox';

import {
  useCreateBlogPostMutation,
  useCreateBlogPostWithoutThumbnailMutation,
  useGetBlogCategoriesQuery,
  useUploadFileMutation,
} from '../../generated/graphql';
import { useUserStore } from '../../store';

const Add = () => {
  const { push } = useHistory();
  const { id } = useUserStore((store) => store.user);

  const [categoryId, setCategoryId] = useState('');
  const [logo, setLogo] = useState<any>('');
  const [logoPreview, setLogoPreview] = useState('');
  const [publishedAt, setPublishedAt] = useState('');
  const [isHidden, setIsHidden] = useState(false);

  // description inputs
  const [title, setTitle] = useState('');
  const [language] = useState('tr');
  const [description, setDescription] = useState('');
  const [shortDescription, setShortDescription] = useState('');
  const [buttonText, setButtonText] = useState('');
  const [buttonUrl, setButtonUrl] = useState('');

  const [error, setError] = useState('');

  const [createBlogPost, { loading }] = useCreateBlogPostMutation();
  const [createBlogPostWithoutThumbnail, { loading: loadingWithoutThumbnail }] =
    useCreateBlogPostWithoutThumbnailMutation();
  const { data } = useGetBlogCategoriesQuery();
  const [uploadPhoto, { loading: uploadLoading }] = useUploadFileMutation();

  const handleImg = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setLogo(e.target.files[0]);
      setLogoPreview(URL.createObjectURL(e.target.files[0]));
    }
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      if (logo) {
        const uploadResponse = await uploadPhoto({
          variables: { file: logo },
        });
        await createBlogPost({
          variables: {
            authorId: id,
            categoryId,
            thumbnail: (uploadResponse.data.uploadFile as any).file,
            publishedAt,
            isHidden,

            title,
            description,
            shortDescription,
            language,
            buttonText,
            buttonUrl,
          },
        });
      } else {
        await createBlogPostWithoutThumbnail({
          variables: {
            authorId: id,
            categoryId,
            publishedAt,
            isHidden,

            title,
            description,
            shortDescription,
            language,
            buttonText,
            buttonUrl,
          },
        });
      }

      setError('');
      push('/blogpost');
    } catch (error) {
      console.log('err ⚠️', error);
      setError(error.message);
    }
  };

  return (
    <>
      <VStack align='flex-start' mb='4'>
        <Text fontWeight='bold' fontSize='2xl'>
          Yeni Blog Yazısı
        </Text>
      </VStack>
      <form onSubmit={onSubmit}>
        <Box>
          {error && <MessageBox status='warning' message={error} />}
          <SimpleGrid columns={[1, 3]} spacing={[2, 8]}>
            <FormControl isRequired>
              <FormLabel>Kategori</FormLabel>
              <Select
                value={categoryId}
                onChange={(e) => setCategoryId(e.target.value)}
              >
                <option value=''>--</option>
                {data &&
                  data.blogCategories.map((category) => (
                    <option value={category.id} key={category.id}>
                      {category.description.length > 1
                        ? category.description.map((desc) => `(${desc.title})`)
                        : category.description.map((desc) => `${desc.title}`)}
                    </option>
                  ))}
              </Select>
              <FormHelperText>Yazı kategorisi</FormHelperText>
            </FormControl>
            <FormControl display='flex'>
              <Box mr='8'>
                <FormControl>
                  <FormLabel>Kapak resmi (opsiyonel)</FormLabel>
                  <Input type='file' multiple={false} onChange={handleImg} />
                  <FormHelperText>
                    Eğer isterseniz bu yazıya kapak resmi ekleyebilirsiniz
                  </FormHelperText>
                </FormControl>
              </Box>
              <Box boxSize='150px' position='relative'>
                {logoPreview && (
                  <>
                    <IconButton
                      position='absolute'
                      bottom='0'
                      right='0'
                      colorScheme='yellow'
                      aria-label='delete image'
                      icon={<BsTrash size='24' />}
                      onClick={() => {
                        setLogo('');
                        setLogoPreview('');
                      }}
                    />
                    <Image src={logoPreview} rounded='sm' objectFit='contain' />
                  </>
                )}
              </Box>
            </FormControl>

            <FormControl isRequired>
              <FormLabel>Yayımlanma Tarihi</FormLabel>
              <Input
                type='date'
                value={publishedAt}
                onChange={(e) => setPublishedAt(e.target.value)}
              />
            </FormControl>
          </SimpleGrid>

          <Box w='50%'>
            <FormControl ml='auto'>
              <FormLabel>Durum</FormLabel>
              <Checkbox
                colorScheme='red'
                isChecked={isHidden}
                onChange={() => setIsHidden((prev) => !prev)}
              >
                Gizle
              </Checkbox>
              <FormHelperText>
                Blog yazısı gizli olarak işaretlendiğinde bu yazı
                listelenmeyecektir.
              </FormHelperText>
            </FormControl>
          </Box>

          <Divider my='6' />
          <ContentDescription
            title={title}
            setTitle={setTitle}
            description={description}
            setDescription={setDescription}
            shortDescription={shortDescription}
            setShortDescription={setShortDescription}
            buttonText={buttonText}
            setButtonText={setButtonText}
            buttonUrl={buttonUrl}
            setButtonUrl={setButtonUrl}
          />

          <Button
            colorScheme='blue'
            type='submit'
            mt='10px'
            isLoading={loading || uploadLoading || loadingWithoutThumbnail}
          >
            Kaydet
          </Button>
        </Box>
      </form>
    </>
  );
};

export default Add;
