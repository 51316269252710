import {
  FormControl,
  FormLabel,
  Box,
  Text,
  VStack,
  Button,
  Select,
  FormHelperText,
  Divider,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import ContentDescription from '../../components/ContentDescription';
import MessageBox from '../../components/MessageBox';

import UploadDropzone from '../../components/UploadDropzone';
import {
  useCreateCategoryMutation,
  useCreateCategoryWithoutParentMutation,
  useGetCategoriesQuery,
} from '../../generated/graphql';

const Add = () => {
  const { push } = useHistory();
  const { data: categoriesData } = useGetCategoriesQuery({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });
  const [parentCategoryId, setParentCategoryId] = useState<string | undefined>(
    undefined
  );
  const [photos, setPhotos] = useState([]);

  // description inputs
  const [title, setTitle] = useState('');
  const [language] = useState('tr');
  const [description, setDescription] = useState('');
  const [shortDescription, setShortDescription] = useState('');
  const [buttonText, setButtonText] = useState('');
  const [buttonUrl, setButtonUrl] = useState('');

  const [error, setError] = useState('');

  const [createCategory, { loading }] = useCreateCategoryMutation();
  const [createCategoryWithoutParent, { loading: loadingWithoutParent }] =
    useCreateCategoryWithoutParentMutation();

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      if (!parentCategoryId) {
        await createCategoryWithoutParent({
          variables: {
            photos,

            title,
            description,
            shortDescription,
            language,
            buttonText,
            buttonUrl,
          },
        });
      } else {
        await createCategory({
          variables: {
            parentCategoryId,
            photos,

            title,
            description,
            shortDescription,
            language,
            buttonText,
            buttonUrl,
          },
        });
      }
      setError('');
      push(`/categories`);
    } catch (error) {
      console.log('err ⚠️', error);
      setError(error.message);
    }
  };

  return (
    <>
      <VStack align='flex-start' mb='4'>
        <Text fontWeight='bold' fontSize='2xl'>
          Yeni Kategori
        </Text>
      </VStack>
      <form onSubmit={onSubmit}>
        <Box width='full' mb='8'>
          {error && <MessageBox status='warning' message={error} />}

          <FormControl mb='4'>
            <UploadDropzone images={photos} setImageArray={setPhotos} />
          </FormControl>

          <FormControl>
            <FormLabel>Üst kategori</FormLabel>
            <Select
              value={parentCategoryId}
              onChange={(e) => setParentCategoryId(e.target.value)}
            >
              <option value=''>--</option>
              {categoriesData &&
                categoriesData.categories.map((category) => (
                  <option value={category.id} key={category.id}>
                    {category.descriptions.length > 1
                      ? category.descriptions.map((desc) => `(${desc.title})`)
                      : category.descriptions.map((desc) => `${desc.title}`)}
                  </option>
                ))}
            </Select>
            <FormHelperText>Üst kategorisi varsa seçiniz.</FormHelperText>
          </FormControl>

          <Divider my='6' />
          <ContentDescription
            title={title}
            setTitle={setTitle}
            description={description}
            setDescription={setDescription}
            shortDescription={shortDescription}
            setShortDescription={setShortDescription}
            buttonText={buttonText}
            setButtonText={setButtonText}
            buttonUrl={buttonUrl}
            setButtonUrl={setButtonUrl}
          />

          <Button
            colorScheme='blue'
            type='submit'
            mt='10px'
            isLoading={loading || loadingWithoutParent}
          >
            Kaydet
          </Button>
        </Box>
      </form>
    </>
  );
};

export default Add;
