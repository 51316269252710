import {
  Button,
  chakra,
  Flex,
  FormControl,
  Input,
  Table,
  Text,
  useToast,
  Thead,
  Td,
  Tr,
  Tbody,
  Heading,
  Avatar,
  Th,
  Switch,
  SimpleGrid,
  GridItem,
} from '@chakra-ui/react';
import moment from 'moment';
import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import Loading from '../components/Loading';
import MessageBox from '../components/MessageBox';

import {
  GetFindFirstCustomDataQuery,
  useGetFindFirstCustomDataQuery,
  useGetVideosQuery,
  useSetHomepageVideoMutation,
  useSetLiveVideoUrlMutation,
  useUpdateVideoMutation,
} from '../generated/graphql';

const Dashboard = () => {
  const { data, loading } = useGetFindFirstCustomDataQuery();
  const {
    data: videosData,
    loading: videosLoading,
    refetch,
  } = useGetVideosQuery({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const [updateVideo] = useUpdateVideoMutation();
  const [setHomepageVideo] = useSetHomepageVideoMutation();

  return (
    <>
      <Text fontWeight='bold' fontSize='2xl' mb='4'>
        Kontrol paneli
      </Text>

      <SimpleGrid columns={3} w='full' gap={{ base: 0, md: 6 }}>
        <GridItem colSpan={{ base: 3, md: 1 }}>
          {loading ? <Loading /> : <LiveVideoForm data={data} />}
        </GridItem>
        <GridItem colSpan={{ base: 3, md: 2 }}>
          <Text fontWeight='bold' fontSize='2xl' mb='2'>
            Videolar
          </Text>

          <Flex flexDir='column'>
            {videosLoading ? (
              <Loading />
            ) : videosData.ytVideos.length <= 0 ? (
              <MessageBox status='warning' message='Video yok?' />
            ) : (
              <Flex>
                <Table variant='unstyled' mt={4}>
                  <Thead>
                    <Tr color='gray'>
                      <Th>Video</Th>
                      <Th isNumeric>Göster</Th>
                      <Th isNumeric>Anasayfa?</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {loading ? (
                      <div>yukleniyor</div>
                    ) : (
                      videosData &&
                      videosData.ytVideos.map((video) => (
                        <Tr key={video.id}>
                          <Td>
                            <Flex align='center'>
                              <Avatar
                                size='sm'
                                mr={2}
                                src='youtube.png'
                                as='a'
                                href={`https://www.youtube.com/watch?v=${video.id}`}
                                target='_blank'
                              />
                              <Flex flexDir='column'>
                                <Heading size='sm' letterSpacing='tight'>
                                  {video.data.snippet.title.replace(
                                    /&quot;/g,
                                    '"'
                                  )}
                                </Heading>
                                <Text fontSize='sm' color='gray'>
                                  {moment(
                                    video.data.snippet.publishedAt
                                  ).format('DD/MM/YYYY')}
                                </Text>
                              </Flex>
                            </Flex>
                          </Td>
                          <Td isNumeric>
                            <Switch
                              colorScheme='green'
                              onChange={(e) =>
                                updateVideo({
                                  variables: {
                                    id: video.id,
                                    isActive: e.target.checked,
                                  },
                                })
                              }
                              defaultChecked={video.isActive}
                            />
                          </Td>
                          <Td isNumeric>
                            <Switch
                              defaultChecked={video.showOnHomepage}
                              colorScheme='blue'
                              onChange={async () => {
                                await setHomepageVideo({
                                  variables: {
                                    id: video.id,
                                  },
                                });
                                refetch();
                              }}
                            />
                          </Td>
                        </Tr>
                      ))
                    )}
                  </Tbody>
                </Table>
              </Flex>
            )}
          </Flex>
        </GridItem>
      </SimpleGrid>
    </>
  );
};

type Props = {
  data: GetFindFirstCustomDataQuery;
};

function LiveVideoForm({ data }: Props) {
  const toast = useToast();
  const [setLiveVideoUrlMutation, { loading }] = useSetLiveVideoUrlMutation();

  const [liveVideoUrl, setLiveVideoUrl] = useState(
    data?.findFirstCustomData.liveVideoUrl || ''
  );
  const [validVideo, setValidVideo] = useState<any>(null);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!validVideo) {
      return toast({
        title: 'Hata',
        description: 'Geçerli bir URL giriniz',
        status: 'error',
        duration: 2500,
        isClosable: true,
        position: 'top-left',
      });
    }
    try {
      await setLiveVideoUrlMutation({
        variables: { liveVideoUrl },
      });
      return toast({
        title: 'OK',
        description: 'Video ayarlandı',
        status: 'success',
        duration: 2500,
        isClosable: true,
        position: 'top-left',
      });
    } catch (error) {
      console.log('err :', error.message);
      return toast({
        title: 'Hata',
        description: error.message,
        status: 'error',
        duration: 2500,
        isClosable: true,
        position: 'top-left',
      });
    }
  };

  const handleClear = async () => {
    try {
      await setLiveVideoUrlMutation({
        variables: { liveVideoUrl: '' },
      });
      setLiveVideoUrl('');
      return toast({
        title: 'OK',
        description: 'Canlı yayın URL silindi',
        status: 'success',
        duration: 2500,
        isClosable: true,
        position: 'top-left',
      });
    } catch (error) {
      console.log('err :', error.message);
      return toast({
        title: 'Hata',
        description: error.message,
        status: 'error',
        duration: 2500,
        isClosable: true,
        position: 'top-left',
      });
    }
  };

  return (
    <>
      <Text>
        Anasayfada görünmesi için canlı yayın video linkini girebilirsiniz.
      </Text>
      <chakra.form w='full' onSubmit={handleSubmit}>
        <FormControl
          display='flex'
          flexDir={['column', 'column', 'column', 'row']}
          isDisabled={loading}
          mb='6'
        >
          <Input
            rounded='none'
            placeholder='Canlı Yayın URL'
            value={liveVideoUrl}
            onChange={(e) => setLiveVideoUrl(e.target.value)}
            isInvalid={!validVideo && liveVideoUrl.length > 4}
            _focus={{ outline: 'none' }}
          />
          <Button
            rounded='none'
            colorScheme='blue'
            type='submit'
            isDisabled={loading}
          >
            Kaydet
          </Button>
          <Button
            rounded='none'
            colorScheme='red'
            isDisabled={loading}
            onClick={handleClear}
          >
            Temizle
          </Button>
        </FormControl>
      </chakra.form>

      {liveVideoUrl && (
        <ReactPlayer
          url={liveVideoUrl}
          onError={() => setValidVideo(false)}
          onReady={() => setValidVideo(true)}
          width='100%'
        />
      )}
    </>
  );
}

export default Dashboard;
