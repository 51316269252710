import {
  FormControl,
  FormHelperText,
  FormLabel,
  GridItem,
  Input,
  Select,
  SimpleGrid,
} from '@chakra-ui/react';
import TinyMCE from './TinyMCE';

type Props = {
  title: string;
  setTitle: React.Dispatch<React.SetStateAction<string>>;
  description: string;
  setDescription: React.Dispatch<React.SetStateAction<string>>;

  shortDescription: string;
  setShortDescription: React.Dispatch<React.SetStateAction<string>>;
  buttonText: string;
  setButtonText: React.Dispatch<React.SetStateAction<string>>;

  buttonUrl: string;
  setButtonUrl: React.Dispatch<React.SetStateAction<string>>;

  titleDisabled?: boolean;
  buttonTextDisabled?: boolean;
  buttonUrlDisabled?: boolean;
  descriptionDisabled?: boolean;
  shortDescriptionDisabled?: boolean;
};

const ContentDescription: React.FC<Props> = ({
  title,
  setTitle,
  buttonText,
  buttonUrl,
  description,
  setButtonText,
  setButtonUrl,
  setDescription,
  setShortDescription,
  shortDescription,

  titleDisabled,
  buttonTextDisabled,
  buttonUrlDisabled,
  descriptionDisabled,
  shortDescriptionDisabled,
}) => {
  return (
    <SimpleGrid columns={[1, 2]} spacing={[2, 8]}>
      <FormControl isDisabled={titleDisabled}>
        <FormLabel>Başlık</FormLabel>
        <Input
          type='text'
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
      </FormControl>
      <FormControl>
        <FormLabel>Dil</FormLabel>
        <Select
          /* value={language}
                onChange={(e) => setLanguage(e.target.value)} */
          disabled
        >
          <option value='tr'>Türkçe</option>
          <option value='en'>İngilizce</option>
        </Select>
        <FormHelperText>
          İlk girişte dil seçimi varsayılan olarak Türkçe olarak seçiliyor.
        </FormHelperText>
      </FormControl>

      <FormControl isDisabled={buttonTextDisabled}>
        <FormLabel>Buton Yazı</FormLabel>
        <Input
          type='text'
          value={buttonText}
          onChange={(e) => setButtonText(e.target.value)}
        />
      </FormControl>

      <FormControl isDisabled={buttonUrlDisabled}>
        <FormLabel>Buton URL</FormLabel>
        <Input
          type='text'
          value={buttonUrl}
          onChange={(e) => setButtonUrl(e.target.value)}
        />
      </FormControl>

      <GridItem colSpan={2}>
        <FormControl>
          <FormLabel>Açıklama</FormLabel>
          <TinyMCE
            height={200}
            value={description}
            setValue={setDescription}
            disabled={descriptionDisabled}
          />
        </FormControl>
      </GridItem>

      <GridItem colSpan={2}>
        <FormControl isDisabled={shortDescriptionDisabled}>
          <FormLabel>Kısa Açıklama</FormLabel>
          <TinyMCE
            height={200}
            value={shortDescription}
            setValue={setShortDescription}
            disabled={shortDescriptionDisabled}
          />
        </FormControl>
      </GridItem>
    </SimpleGrid>
  );
};

export default ContentDescription;
