import {
  VStack,
  Text,
  Button,
  useToast,
  SimpleGrid,
  FormControl,
  FormLabel,
  Input,
  Select,
  GridItem,
  Tooltip,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import TinyMCE from '../../components/TinyMCE';
import {
  useDeleteDescMutation,
  useUpdateDescMutation,
} from '../../generated/graphql';
import { DescriptionRouteProps } from '../../types';

function EditDescription() {
  const { goBack } = useHistory();
  const { state } = useLocation<DescriptionRouteProps>();

  const [title, setTitle] = useState(state.description.title || '');
  const [language, setLanguage] = useState('tr');
  const [description, setDescription] = useState(
    state.description.description || ''
  );

  const [shortDescription, setShortDescription] = useState(
    state.description.shortDescription || ''
  );
  const [buttonText, setButtonText] = useState(
    state.description.buttonText || ''
  );
  const [buttonUrl, setButtonUrl] = useState(state.description.buttonUrl || '');

  const [updateDescription, { loading: updateDescLoading }] =
    useUpdateDescMutation();

  const [deleteDescription, { loading: deleteDescLoading }] =
    useDeleteDescMutation();
  const toast = useToast();

  const submitHandler = async (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    try {
      await updateDescription({
        variables: {
          id: state.description.id,
          title,
          description,
          shortDescription,
          language,
          buttonUrl,
          buttonText,
        },
      });
      return toast({
        title: 'OK',
        description: 'Başarılı, yönlendiriliyorsunuz...',
        status: 'success',
        duration: 2000,
        isClosable: true,
        onCloseComplete: () => {
          goBack();
        },
      });
    } catch (error) {
      console.log('catch err ⚠️', error.message);
      return toast({
        title: 'Hata',
        description: error.message,
        status: 'error',
        duration: 2500,
        isClosable: true,
      });
    }
  };

  const handleDeleteDescription = async (id: string) => {
    try {
      const res = await deleteDescription({
        variables: {
          id,
        },
      });
      if (res) {
        goBack();
      }
    } catch (error) {
      console.log('catch err ⚠️', error.message);
    }
  };

  return (
    <>
      <VStack align='start' mb='4'>
        <Text fontWeight='bold' fontSize='2xl'>
          Açıklama Düzenleme
        </Text>
      </VStack>

      <SimpleGrid columns={[1, 2]} spacing={[2, 8]}>
        <FormControl>
          <FormLabel>Başlık</FormLabel>
          <Input
            type='text'
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Dil</FormLabel>
          <Select
            value={state.description.language}
            onChange={(e) => setLanguage(e.target.value)}
          >
            <option value='tr'>Türkçe</option>
            <option value='en'>İngilizce</option>
          </Select>
        </FormControl>

        <FormControl>
          <FormLabel>Buton Yazı</FormLabel>
          <Input
            type='text'
            value={buttonText}
            onChange={(e) => setButtonText(e.target.value)}
          />
        </FormControl>

        <FormControl>
          <FormLabel>Buton URL</FormLabel>
          <Input
            type='text'
            value={buttonUrl}
            onChange={(e) => setButtonUrl(e.target.value)}
          />
        </FormControl>

        <GridItem colSpan={2}>
          <FormControl>
            <FormLabel>Açıklama</FormLabel>
            <TinyMCE
              height={200}
              value={description}
              setValue={setDescription}
            />
          </FormControl>
        </GridItem>

        <GridItem colSpan={2}>
          <FormControl>
            <FormLabel>Kısa Açıklama</FormLabel>
            <TinyMCE
              height={200}
              value={shortDescription}
              setValue={setShortDescription}
            />
          </FormControl>
        </GridItem>

        <FormControl>
          <Button variant='ghost' mr={3} onClick={goBack} border='1px'>
            Geri
          </Button>
          {state.descriptionCount <= 1 ? (
            <Tooltip
              label='Başka dil seçeneği eklenmediği için silinemiyor.'
              hasArrow
              placement='top'
            >
              <Button colorScheme='red' mr={3} opacity='0.5'>
                Sil
              </Button>
            </Tooltip>
          ) : (
            <Button
              colorScheme='red'
              mr={3}
              onClick={() => handleDeleteDescription(state.description.id)}
              isLoading={deleteDescLoading}
            >
              Sil
            </Button>
          )}

          <Button
            colorScheme='blue'
            type='button'
            disabled={updateDescLoading}
            isLoading={updateDescLoading}
            onClick={submitHandler}
          >
            Kaydet
          </Button>
        </FormControl>
      </SimpleGrid>
    </>
  );
}

export default EditDescription;
