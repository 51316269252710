import {
  Button,
  ButtonGroup,
  IconButton,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
  useToast,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import MessageBox from '../../components/MessageBox';
import url from '../../services/url';

import {
  useGetUsersQuery,
  useDeleteUserMutation,
  GetUsersQueryResult,
} from '../../generated/graphql';
import { MdRefresh } from 'react-icons/md';
import Loading from '../../components/Loading';
import moment from 'moment';
import DeletePopover from '../../components/DeletePopover';

interface ItemProps {
  item: GetUsersQueryResult['data']['users'][0];
  deleteLoading: boolean;
  deleteHandler: (id: any) => any;
  index: number;
  refetch: any;
}

const TableItem = (props: ItemProps) => {
  return (
    <>
      <Tr key={props.item.id}>
        <Td>
          {props.item.name} {props.item.surname}
        </Td>
        <Td>{props.item.email}</Td>

        <Td>{moment(props.item.createdAt).format('DD/MM/YYYY')}</Td>
        <Td>{moment(props.item.updatedAt).format('DD/MM/YYYY')}</Td>

        <Td isNumeric>
          <ButtonGroup size='sm' variant='solid'>
            <Button
              colorScheme='teal'
              as={Link}
              to={`/users/update/${props.item.id}`}
            >
              Düzenle
            </Button>
            <DeletePopover
              deleteHandler={props.deleteHandler}
              pageId={props.item.id}
              isLoading={props.deleteLoading}
            />
          </ButtonGroup>
        </Td>
      </Tr>
    </>
  );
};

const List = () => {
  const toast = useToast();
  const { data, loading, error, refetch } = useGetUsersQuery({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const [deleteUser, { loading: deleteLoading }] = useDeleteUserMutation();

  const deleteHandler = async (id: any) => {
    try {
      const res = await deleteUser({ variables: { id } });
      if (res) refetch();
    } catch (error) {
      console.log('catch err ⚠️', error);
      return toast({
        title: 'Hata',
        description: error.message,
        status: 'error',
        duration: 2500,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <VStack align='start' mb='4'>
        <Text fontWeight='bold' fontSize='2xl'>
          Yöneticiler
        </Text>

        <ButtonGroup alignSelf='flex-end' size='sm' isAttached>
          <Button colorScheme='green' as={Link} to={url.USERCREATE}>
            Yeni
          </Button>
          <IconButton
            aria-label='refresh'
            icon={<MdRefresh />}
            onClick={() => refetch()}
          />
        </ButtonGroup>
      </VStack>
      {error && <MessageBox message={error.message} status='error' />}
      {loading ? (
        <Loading />
      ) : data!.users.length <= 0 ? (
        <MessageBox
          status='warning'
          message='Henüz hiçbir kullanıcı yok. Yeni bir tane oluşturmak için yeni
          butonunu kullanın.'
        />
      ) : (
        <Table>
          <Thead>
            <Tr>
              <Th>Ad Soyad</Th>
              <Th>Email</Th>
              <Th>Oluşturulma Tarihi</Th>
              <Th>Son Güncelleme</Th>
              <Th isNumeric></Th>
            </Tr>
          </Thead>
          <Tbody>
            {data?.users.map((element, index: number) => (
              <TableItem
                key={index}
                index={index}
                item={element}
                deleteHandler={deleteHandler}
                deleteLoading={deleteLoading}
                refetch={refetch}
              />
            ))}
          </Tbody>
        </Table>
      )}
    </>
  );
};

export default List;
