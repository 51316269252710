import { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Route, Switch, useLocation } from 'react-router-dom';
import url from './services/url';

import Layout from './components/Layout';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';

import PageList from './pages/Page/List';
import NewPage from './pages/Page/Add';
import EditPage from './pages/Page/Edit';
import OrderPages from './pages/Page/OrderList';

import PageContentList from './pages/Content/List';
import NewPageContent from './pages/Content/Add';
import EditPageContent from './pages/Content/Edit';
import OrderPageContents from './pages/Content/OrderList';

import FormioList from './pages/FormioForm/List';
import NewFormio from './pages/FormioForm/Add';
import EditFormio from './pages/FormioForm/Edit';
import OrderFormio from './pages/FormioForm/OrderList';

import ReaderCommentList from './pages/ReaderComment/List';
import NewReaderComment from './pages/ReaderComment/Add';
import EditReaderComment from './pages/ReaderComment/Edit';
import OrderReaderComments from './pages/ReaderComment/OrderList';

import ContactList from './pages/Contact/List';
import NewContact from './pages/Contact/Add';
import EditContact from './pages/Contact/Edit';

import ProductsList from './pages/Products/List';
import NewProduct from './pages/Products/Add';
import EditProduct from './pages/Products/Edit';
import OrderProduct from './pages/Category/OrderProducts';

import ProductContentsList from './pages/Products/ContentList';
import NewProductContent from './pages/Products/AddContent';
import EditProductContent from './pages/Products/EditContent';
import OrderProductContents from './pages/Products/OrderContentList';

import AppSettings from './pages/Settings/AppSettings';

import CategoryList from './pages/Category/List';
import NewCategory from './pages/Category/Add';
import EditCategory from './pages/Category/Edit';
import OrderCategories from './pages/Category/OrderList';

import BrandList from './pages/Brand/List';
import NewBrand from './pages/Brand/Add';
import EditBrand from './pages/Brand/Edit';
import OrderBrands from './pages/Brand/OrderList';

import EcommerceSettings from './pages/Settings/EcommerceSettings';

import BlogCategoryList from './pages/BlogCategory/List';
import NewBlogCategory from './pages/BlogCategory/Add';
import EditBlogCategory from './pages/BlogCategory/Edit';
import OrderBlogCategories from './pages/BlogCategory/OrderList';

import BlogPostList from './pages/BlogPost/List';
import NewBlogPost from './pages/BlogPost/Add';
import EditBlogPost from './pages/BlogPost/Edit';

import BlogPostContentsList from './pages/BlogPost/ContentList';
import NewBlogPostContent from './pages/BlogPost/AddContent';
import EditBlogPostContent from './pages/BlogPost/EditContent';
import OrderBlogPostContents from './pages/BlogPost/OrderList';

import UserList from './pages/User/List';
import NewUser from './pages/User/Add';
import EditUser from './pages/User/Edit';

import MessageList from './pages/Messages/List';
import Message from './pages/Messages/SingleMessage';

import FaqList from './pages/Faq/List';
import NewFaq from './pages/Faq/Add';

import EventList from './pages/Event/List';
import NewEvent from './pages/Event/Add';
import EditEvent from './pages/Event/Edit';

import {
  chakra,
  ChakraProvider,
  Flex,
  CircularProgress,
} from '@chakra-ui/react';
import { useUserStore } from './store';
import { CHECK_REFRESH_TOKEN } from './gql/user/query';

import EditDescription from './pages/Description/Edit';
import NewDescription from './pages/Description/Add';

const refreshToken = localStorage.getItem('refreshToken');

const Loading = () => (
  <ChakraProvider>
    <chakra.div
      height='100vh'
      display='flex'
      justifyContent='center'
      alignItems='center'
      bgColor='#ccc'
      flexGrow={1}
    >
      <Flex alignItems='center' justifyContent='center'>
        <CircularProgress isIndeterminate color='red.500' size='24' />
      </Flex>
    </chakra.div>
  </ChakraProvider>
);

const CustomRoute = () => {
  const user = useUserStore((store) => store.user);

  if (user) {
    return (
      <Layout>
        <Switch>
          <Route exact path={url.HOME} component={Dashboard} />
          <Route path={url.DASHBOARD} component={Dashboard} />
          {/* PAGES */}
          <Route exact path={url.PAGESLIST} component={PageList} />
          <Route path={url.PAGESCREATE} component={NewPage} />
          <Route path={url.PAGESORDER} component={OrderPages} />
          <Route path={url.PAGESUPDATE} component={EditPage} />
          <Route
            exact
            path={url.PAGESCONTENTLIST}
            component={PageContentList}
          />
          <Route path={url.PAGESCONTENTCREATE} component={NewPageContent} />
          <Route path={url.PAGESCONTENTORDER} component={OrderPageContents} />
          <Route path={url.PAGESCONTENTUPDATE} component={EditPageContent} />
          {/* CONTACTS */}
          <Route exact path={url.CONTACTSLIST} component={ContactList} />
          <Route path={url.CONTACTSCREATE} component={NewContact} />
          <Route path={url.CONTACTSUPDATE} component={EditContact} />
          {/* FORMIO */}
          <Route exact path={url.FORMIOLIST} component={FormioList} />
          <Route path={url.FORMIOCREATE} component={NewFormio} />
          <Route path={url.FORMIOUPDATE} component={EditFormio} />
          <Route path={url.FORMIOORDER} component={OrderFormio} />
          {/* PRODUCTS */}
          <Route exact path={url.PRODUCTSLIST} component={ProductsList} />
          <Route path={url.PRODUCTSCREATE} component={NewProduct} />
          <Route path={url.PRODUCTSUPDATE} component={EditProduct} />
          <Route path={url.PRODUCTSORDER} component={OrderProduct} />

          <Route
            exact
            path={url.PRODUCTCONTENTLIST}
            component={ProductContentsList}
          />
          <Route
            path={url.PRODUCTCONTENTCREATE}
            component={NewProductContent}
          />
          <Route
            path={url.PRODUCTCONTENTUPDATE}
            component={EditProductContent}
          />
          <Route
            path={url.PRODUCTCONTENTORDER}
            component={OrderProductContents}
          />

          {/* SETTINGS */}
          <Route exact path={url.APPSETTINGS} component={AppSettings} />
          <Route
            exact
            path={url.ECOMMERCESETTINGS}
            component={EcommerceSettings}
          />
          {/* CATEGORIES */}
          <Route exact path={url.CATEGORIESLIST} component={CategoryList} />
          <Route path={url.CATEGORIESCREATE} component={NewCategory} />
          <Route path={url.CATEGORIESUPDATE} component={EditCategory} />
          <Route path={url.CATEGORIESORDER} component={OrderCategories} />
          {/* BRANDS */}
          <Route exact path={url.BRANDSLIST} component={BrandList} />
          <Route path={url.BRANDSCREATE} component={NewBrand} />
          <Route path={url.BRANDSUPDATE} component={EditBrand} />
          <Route path={url.BRANDSORDER} component={OrderBrands} />
          {/* BLOG CATEGORIES */}
          <Route
            exact
            path={url.BLOGCATEGORYLIST}
            component={BlogCategoryList}
          />
          <Route path={url.BLOGCATEGORYCREATE} component={NewBlogCategory} />
          <Route path={url.BLOGCATEGORYSUPDATE} component={EditBlogCategory} />
          <Route path={url.BLOGCATEGORYORDER} component={OrderBlogCategories} />
          {/* FAQ */}
          <Route exact path={url.FAQLIST} component={FaqList} />
          <Route path={url.FAQCREATE} component={NewFaq} />

          {/* BLOG POSTS */}
          <Route exact path={url.BLOGPOSTLIST} component={BlogPostList} />
          <Route path={url.BLOGPOSTCREATE} component={NewBlogPost} />
          <Route path={url.BLOGPOSTUPDATE} component={EditBlogPost} />
          <Route
            exact
            path={url.BLOGPOSTCONTENTLIST}
            component={BlogPostContentsList}
          />
          <Route
            exact
            path={url.BLOGPOSTCONTENTCREATE}
            component={NewBlogPostContent}
          />
          <Route
            exact
            path={url.BLOGPOSTCONTENTUPDATE}
            component={EditBlogPostContent}
          />
          <Route
            exact
            path={url.BLOGPOSTCONTENTORDER}
            component={OrderBlogPostContents}
          />

          {/* USERS */}
          <Route exact path={url.USERSLIST} component={UserList} />
          <Route exact path={url.USERCREATE} component={NewUser} />
          <Route exact path={url.USERUPDATE} component={EditUser} />

          {/* FORM */}
          <Route exact path={url.FORMMESAGELIST} component={MessageList} />
          <Route exact path={url.FORMMESAGE} component={Message} />

          {/* EVENTS */}
          <Route exact path={url.EVENTLIST} component={EventList} />
          <Route path={url.EVENTCREATE} component={NewEvent} />
          <Route path={url.EVENTUPDATE} component={EditEvent} />

          {/* EVENTS */}
          <Route
            exact
            path={url.READERCOMMENTLIST}
            component={ReaderCommentList}
          />
          <Route path={url.READERCOMMENTCREATE} component={NewReaderComment} />
          <Route path={url.READERCOMMENTUPDATE} component={EditReaderComment} />
          <Route
            path={url.READERCOMMENTORDER}
            component={OrderReaderComments}
          />

          {/* DESCRIPTIONS */}
          <Route path={url.DESCRIPTIONUPDATE} component={EditDescription} />
          <Route path={url.NEWDESCRIPTION} component={NewDescription} />
        </Switch>
      </Layout>
    );
  } else {
    return <Route path='/' component={Login} />;
  }
};

export default function AppRouter() {
  const setUser = useUserStore((store) => store.setUser);
  const location = useLocation();
  const [loading, setLoading] = useState(true);

  const [checkToken] = useMutation(CHECK_REFRESH_TOKEN);

  useEffect(() => {
    async function tokenChecker() {
      try {
        const result = await checkToken({
          variables: {
            refreshToken: localStorage.getItem('refreshToken'),
          },
        });
        if (result) {
          setUser(result.data.checkRefreshToken);
          setLoading(false);
        }
      } catch (error) {
        console.log('tokenChecker catch:: ', error.message);
        setUser(null);
        setLoading(false);
      }
    }

    if (refreshToken) {
      tokenChecker();
    } else {
      setUser(null);
      setLoading(false);
    }
  }, [checkToken, location, setUser]);

  if (loading) {
    return <Loading />;
  } else {
    return <CustomRoute />;
  }
}
