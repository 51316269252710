import { Box, IconButton, Image } from '@chakra-ui/react';
import { BsTrash } from 'react-icons/bs';
import { IRenderBgImage } from '../../types';
import CloudinaryImage from '../CloudinaryImage';

const renderBgImageSelector = ({
  logoPreview,
  mode,
  setLogo,
  setLogoPreview,
  dataLogoPreview,
  setDataLogoPreview,
}: IRenderBgImage) => {
  if (mode === 'ADD') {
    return (
      <Box boxSize='150px' position='relative'>
        {logoPreview && (
          <>
            <IconButton
              position='absolute'
              bottom='0'
              right='0'
              colorScheme='yellow'
              aria-label='delete image'
              icon={<BsTrash size='24' />}
              onClick={() => {
                setLogo('');
                setLogoPreview('');
              }}
            />
            <Image
              src={logoPreview}
              rounded='sm'
              objectFit='contain'
              boxSize='150px'
            />
          </>
        )}
      </Box>
    );
  }

  if (mode === 'EDIT') {
    return (
      <Box boxSize='150px' position='relative'>
        {dataLogoPreview && !logoPreview ? (
          <>
            <IconButton
              position='absolute'
              bottom='0'
              right='0'
              colorScheme='yellow'
              aria-label='delete image'
              icon={<BsTrash size='24' />}
              onClick={() => {
                setLogo('');
                setDataLogoPreview('');
              }}
            />
            <CloudinaryImage publicId={dataLogoPreview} />
          </>
        ) : (
          <>
            {logoPreview && (
              <IconButton
                position='absolute'
                bottom='0'
                right='0'
                colorScheme='yellow'
                aria-label='delete image'
                icon={<BsTrash size='24' />}
                onClick={() => {
                  setLogo('');
                  setLogoPreview('');
                  setDataLogoPreview('');
                }}
              />
            )}
            {logoPreview && (
              <Image
                src={logoPreview}
                rounded='sm'
                objectFit='contain'
                boxSize='150px'
              />
            )}
          </>
        )}
      </Box>
    );
  }
};

export default renderBgImageSelector;
