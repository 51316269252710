import { Button, Flex } from '@chakra-ui/react';

type Props = {
  totalPage: number;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
};

function Pagination({ page, setPage, totalPage }: Props) {
  return (
    <Flex py='10' w='full' alignItems='center' justifyContent='center'>
      <Flex>
        <Button
          mx={1}
          onClick={() => setPage((prev) => prev - 1)}
          isDisabled={page === 1}
        >
          &larr;
        </Button>
        <Button mx={1} isDisabled>
          {page} / {totalPage}
        </Button>
        <Button
          mx={1}
          onClick={() => setPage((prev) => prev + 1)}
          isDisabled={page === totalPage}
        >
          &rarr;
        </Button>
      </Flex>
    </Flex>
  );
}

export default Pagination;
