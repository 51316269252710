import {
  Center,
  IconButton,
  Text,
  Flex,
  Box,
  useColorModeValue,
} from '@chakra-ui/react';
import { BsCaretRightFill } from 'react-icons/bs';

interface Props {
  onShowSidebar: any;
  showSidebarButton: boolean;
}

const Header = ({ onShowSidebar, showSidebarButton }: Props) => {
  const navbarBg = useColorModeValue('purple.500', 'purple.700');
  return (
    <Flex bg={navbarBg} p={4} color='white' justifyContent='center'>
      {showSidebarButton && (
        <Box flex={1}>
          <IconButton
            icon={<BsCaretRightFill />}
            aria-label='ok'
            variant='unstyled'
            _focus={{ bgColor: 'none' }}
            onClick={onShowSidebar}
          />
        </Box>
      )}
      <Center h='40px' flex={64}>
        <Text fontSize='xl' fontWeight='bold'>
          Günışığı Psikodrama Enstitüsü Yeşim Türköz
        </Text>
      </Center>
    </Flex>
  );
};

export default Header;
