import React from 'react';
import {
  Button,
  ButtonGroup,
  IconButton,
  Text,
  VStack,
  useToast,
  Box,
  Heading,
} from '@chakra-ui/react';
import arrayMove from 'array-move';
import { MdRefresh } from 'react-icons/md';
import { Link } from 'react-router-dom';
import DescriptionModal from '../../components/Description';
import Loading from '../../components/Loading';
import MessageBox from '../../components/MessageBox';
import {
  useDeleteAddressMutation,
  useGetAddressesQuery,
  GetAddressesQueryResult,
  useOrderAddressMutation,
} from '../../generated/graphql';
import url from '../../services/url';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import DeletePopover from '../../components/DeletePopover';

const List = () => {
  const toast = useToast();
  const { data, loading, error, refetch } = useGetAddressesQuery({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const [deleteAddress, { loading: deleteLoading }] =
    useDeleteAddressMutation();

  const deleteHandler = async (id: any) => {
    try {
      const res = await deleteAddress({ variables: { id } });
      if (res) refetch();
    } catch (error) {
      console.log('catch err ⚠️', error);
      return toast({
        title: 'Hata',
        description: error.message,
        status: 'error',
        duration: 2500,
        isClosable: true,
      });
    }
  };

  const [order] = useOrderAddressMutation();
  const setOrder = (data: GetAddressesQueryResult['data']['addresses']) => {
    data.forEach(async (element, i) => {
      try {
        await order({
          variables: {
            id: element.id,
            order: i + 1,
          },
        });
      } catch (error) {
        return toast({
          title: 'Hata',
          description: error.message,
          status: 'error',
          duration: 2500,
          isClosable: true,
        });
      }
    });
  };

  return (
    <>
      <VStack align='start' mb='4'>
        <Text fontWeight='bold' fontSize='2xl'>
          Adresler
        </Text>

        <ButtonGroup alignSelf='flex-end' isAttached>
          <Button colorScheme='green' as={Link} to={url.CONTACTSCREATE}>
            Yeni
          </Button>
          <IconButton
            aria-label='refresh'
            icon={<MdRefresh />}
            onClick={() => refetch()}
          />
        </ButtonGroup>
      </VStack>
      {error && <MessageBox message={error.message} status='error' />}
      {loading ? (
        <Loading />
      ) : data?.addresses.length <= 0 ? (
        <MessageBox
          status='warning'
          message='Henüz hiçbir iletişim bilgisi oluşturmamışsınız. Yeni bir tane oluşturmak için yeni
          butonunu kullanın.'
        />
      ) : (
        <SortableTable
          items={data.addresses}
          setOrder={setOrder}
          deleteHandler={deleteHandler}
          deleteLoading={deleteLoading}
          refetch={refetch}
        />
      )}
    </>
  );
};

const CardItem = SortableElement(
  ({
    item,
    deleteHandler,
    deleteLoading,
    refetch,
  }: {
    item: GetAddressesQueryResult['data']['addresses'][0];
    refetch: any;
    deleteHandler: (id: any) => Promise<string | number>;
    deleteLoading: boolean;
  }) => {
    return (
      <Box
        maxW='sm'
        width='md'
        borderWidth='1px'
        borderRadius='md'
        overflow='hidden'
        display='flex'
        flexDir='column'
        mr='2'
        cursor='grab'
      >
        <Box m='5'>
          <Heading mb='2' as='h4' size='md'>
            {item.title}
          </Heading>
          <DescriptionModal
            descriptionProp={item.contact.description}
            fieldId={item.contact.id}
            field='contact'
            refetch={refetch}
          />
        </Box>
        <Box m='5' mb='2' mt='auto'>
          <ButtonGroup size='sm' variant='solid'>
            <Button
              colorScheme='teal'
              as={Link}
              to={`/contact/update/${item.id}`}
            >
              Düzenle
            </Button>
            <DeletePopover
              deleteHandler={deleteHandler}
              pageId={item.id}
              isLoading={deleteLoading}
            />
          </ButtonGroup>
        </Box>
      </Box>
    );
  }
);

const SortableList = SortableContainer(
  ({
    items,
    deleteHandler,
    deleteLoading,
    refetch,
  }: {
    items: GetAddressesQueryResult['data']['addresses'];
    refetch: any;
    deleteHandler: (id: any) => Promise<string | number>;
    deleteLoading: boolean;
  }) => (
    <Box display='flex' flexDir={['column', 'row']}>
      {items.map((address, index) => (
        <CardItem
          key={address.id}
          item={address}
          index={index}
          deleteHandler={deleteHandler}
          deleteLoading={deleteLoading}
          refetch={refetch}
        />
      ))}
    </Box>
  )
);

class SortableTable extends React.Component<
  {
    items: GetAddressesQueryResult['data']['addresses'];
    setOrder: (data: any) => void;
    refetch: any;
    deleteHandler: (id: any) => Promise<string | number>;
    deleteLoading: boolean;
  },
  { setOrder: any; items: any }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      items: this.props.items,
      setOrder: this.props.setOrder,
    };
  }

  onSortEnd = ({ oldIndex, newIndex }: { oldIndex: any; newIndex: any }) => {
    if (oldIndex === newIndex) return;
    this.setState(({ items }) => {
      const newOrder = arrayMove(items, oldIndex, newIndex);
      this.state.setOrder(newOrder);
      return {
        items: newOrder,
      };
    });
  };

  render() {
    return (
      <SortableList
        axis='xy'
        items={this.state.items}
        onSortEnd={this.onSortEnd}
        refetch={this.props.refetch}
        deleteHandler={this.props.deleteHandler}
        deleteLoading={this.props.deleteLoading}
      />
    );
  }
}

export default List;
