import { useState } from 'react';
import { SortableElement, SortableContainer } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { Button, Wrap, WrapItem } from '@chakra-ui/react';
import { nanoid } from 'nanoid';

import { TimelineCardField } from '../types';
import TimelineInput from './TimelineInput';

const SortableCards = SortableContainer(
  ({ items, removeCardField, handleEmojiSelect, handleCardInputChange }) => {
    return (
      <Wrap spacing='8px' mb='4'>
        {items.map((item, index) => {
          return (
            <SortableCard
              item={item}
              items={items}
              key={index}
              index={index}
              handleEmojiSelect={handleEmojiSelect}
              handleCardInputChange={handleCardInputChange}
              removeCardField={removeCardField}
            />
          );
        })}
      </Wrap>
    );
  }
);

const SortableCard = SortableElement(
  ({
    item,
    removeCardField,
    handleEmojiSelect,
    handleCardInputChange,
    items,
  }) => {
    return (
      <WrapItem
        border='1px'
        p={2}
        borderColor='gray.300'
        rounded='lg'
        flexDir='column'
      >
        <TimelineInput
          card={item}
          cardFields={items}
          removeCardField={removeCardField}
          handleColorSelect={handleEmojiSelect}
          handleCardInputChange={handleCardInputChange}
        />
      </WrapItem>
    );
  }
);

interface Props {
  cardFields: TimelineCardField[];
  setCardFields: React.Dispatch<React.SetStateAction<TimelineCardField[]>>;
}

const TimelineCard: React.FC<Props> = ({ cardFields, setCardFields }) => {
  const [fields, setFields] = useState<TimelineCardField[]>(cardFields || []);

  const addCardField = () => {
    setFields((prev) => [
      ...prev,
      {
        id: nanoid(),
        icon: '',
        title: '',
        description: '',
        url: '',
        image: '',
        date: '',
        subtitle: '',
      },
    ]);
  };

  const removeCardField = (id) => {
    const cards = [...fields];
    cards.splice(
      cards.findIndex((value) => value.id === id),
      1
    );
    setFields(cards);
    setCardFields(cards);
  };

  const handleCardInputChange = (id, event) => {
    let copied = JSON.parse(JSON.stringify(fields));
    const changedInputs = copied.map((input) => {
      if (id === input.id) {
        input[event.target.name] = event.target.value;
      }
      return input;
    });
    setFields(changedInputs);
    setCardFields(changedInputs);
  };

  const handleColorSelect = (id, e) => {
    let copied = JSON.parse(JSON.stringify(fields));
    const changedInputs = copied.map((input) => {
      if (id === input.id) {
        input['color'] = e;
      }
      return input;
    });
    setFields(changedInputs);
    setCardFields(changedInputs);
  };
  
  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex === newIndex) return;

    const newOrder = arrayMove(fields, oldIndex, newIndex);
    setFields(newOrder);
    setCardFields(newOrder);
  };
  return (
    <>
      <Button onClick={addCardField} mb='4'>
        Kart ekle
      </Button>

      <SortableCards
        useDragHandle
        items={fields}
        axis='xy'
        onSortEnd={onSortEnd}
        handleEmojiSelect={handleColorSelect}
        removeCardField={removeCardField}
        handleCardInputChange={handleCardInputChange}
      />
    </>
  );
};

export default TimelineCard;
