import {
  Input,
  FormControl,
  FormLabel,
  Text,
  VStack,
  Button,
  Code,
  SimpleGrid,
  Select,
  Textarea,
  GridItem,
  Container,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import MessageBox from '../../components/MessageBox';

import {
  useCreateReaderCommentMutation,
  useGetProductsQuery,
} from '../../generated/graphql';

const Add = () => {
  const { push } = useHistory();

  const [name, setName] = useState('');
  const [productId, setProductId] = useState('');
  const [comment, setComment] = useState('');

  const [error, setError] = useState('');

  const [createComment, { loading }] = useCreateReaderCommentMutation();

  const { data: productsData } = useGetProductsQuery({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await createComment({
        variables: {
          name,
          comment,
          productId,
        },
      });

      setError('');
      push('/readercomments');
    } catch (error) {
      console.log('err ⚠️', error);
      setError(error.message);
    }
  };

  return (
    <Container maxW='4xl' ml='0' pl='0'>
      <VStack align='flex-start' mb='4'>
        <Text fontWeight='bold' fontSize='2xl'>
          Yeni Okuyucu Yorumu
        </Text>
        <Text>
          Aşağıdaki formdan ürün seçerek o ürüne ait okuyucu yorumları
          oluşturabilirsiniz. İstediğiniz üründe{' '}
          <Code colorScheme='red'>Okuyucu Yorumları</Code> içerik türünde içerik
          oluşturarak yorumları listeleyebilirsiniz.
        </Text>
      </VStack>

      <form onSubmit={onSubmit}>
        {error && <MessageBox status='warning' message={error} />}

        <SimpleGrid columns={2} spacing={10}>
          <FormControl isRequired mb='4'>
            <FormLabel>Yorum yapan kişi</FormLabel>
            <Input
              type='text'
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </FormControl>
          <FormControl isRequired isDisabled={!productsData}>
            <FormLabel>Ürün</FormLabel>
            <Select
              value={productId}
              onChange={(e) => setProductId(e.target.value)}
            >
              <option value=''>--</option>
              {productsData &&
                productsData.products.products.map((product) => (
                  <option value={product.id} key={product.id}>
                    {product.descriptions[0]?.title || 'Adsız Ürün'}
                  </option>
                ))}
            </Select>
          </FormControl>

          <GridItem colSpan={2}>
            <FormControl isRequired>
              <FormLabel>Yorum</FormLabel>
              <Textarea
                name='comment'
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                placeholder='yorum...'
                resize='none'
              ></Textarea>
            </FormControl>
          </GridItem>
        </SimpleGrid>

        <Button colorScheme='blue' type='submit' mt='10px' isLoading={loading}>
          Kaydet
        </Button>
      </form>
    </Container>
  );
};

export default Add;
