import { useState } from 'react';
import { Button, Wrap, WrapItem } from '@chakra-ui/react';
import { nanoid } from 'nanoid';
import arrayMove from 'array-move';
import { SortableElement, SortableContainer } from 'react-sortable-hoc';

import SlideInput from './SlideInput';

import { ContentSlide } from '../types';

const SortableCards = SortableContainer(
  ({ items, removeCardField, handleCardInputChange, setCardFields }) => {
    return (
      <Wrap spacing='8px' mb='4'>
        {items.map((item, index) => {
          return (
            <SortableCard
              item={item}
              items={items}
              key={index}
              index={index}
              handleCardInputChange={handleCardInputChange}
              removeCardField={removeCardField}
              setCardFields={setCardFields}
            />
          );
        })}
      </Wrap>
    );
  }
);

const SortableCard = SortableElement(
  ({ item, removeCardField, handleCardInputChange, items, setCardFields }) => {
    return (
      <WrapItem
        border='1px'
        p={2}
        borderColor='gray.300'
        rounded='lg'
        flexDir='column'
        // maxW='md'
      >
        <SlideInput
          card={item}
          cardFields={items}
          removeCardField={removeCardField}
          handleCardInputChange={handleCardInputChange}
          setCardFields={setCardFields}
        />
      </WrapItem>
    );
  }
);

type Props = {
  cardFields: ContentSlide[];
  setCardFields: React.Dispatch<React.SetStateAction<ContentSlide[]>>;
};
const SlideCard: React.VFC<Props> = ({ cardFields, setCardFields }) => {
  const [fields, setFields] = useState<ContentSlide[]>(cardFields || []);

  const addCardField = () => {
    setFields((prev) => [
      ...prev,
      {
        id: nanoid(),
        buttonText: '',
        buttonUrl: '',
        image: '',
        title: '',
        description: '',
      },
    ]);
  };

  const removeCardField = (id) => {
    const cards = [...fields];
    cards.splice(
      cards.findIndex((value) => value.id === id),
      1
    );
    setFields(cards);
    setCardFields(cards);
  };

  const handleCardInputChange = (id, event) => {
    let copied = JSON.parse(JSON.stringify(fields));
    const changedInputs = copied.map((input) => {
      if (id === input.id) {
        input[event.target.name] = event.target.value;
      }
      return input;
    });
    setFields(changedInputs);
    setCardFields(changedInputs);
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex === newIndex) return;

    const newOrder = arrayMove(fields, oldIndex, newIndex);
    setFields(newOrder);
    setCardFields(newOrder);
  };

  return (
    <>
      <Button onClick={addCardField} mb='4'>
        Slayt ekle
      </Button>

      <SortableCards
        items={fields}
        removeCardField={removeCardField}
        handleCardInputChange={handleCardInputChange}
        setCardFields={setCardFields}
        axis='xy'
        onSortEnd={onSortEnd}
        useDragHandle
      />
    </>
  );
};

export default SlideCard;
