import React from 'react';
import {
  Button,
  ButtonGroup,
  Container,
  IconButton,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from '@chakra-ui/react';
import { useToast } from '@chakra-ui/toast';
import { MdRefresh } from 'react-icons/md';
import { Link } from 'react-router-dom';
import Loading from '../../components/Loading';
import MessageBox from '../../components/MessageBox';
import {
  GetCategoriesQueryResult,
  useOrderCategoryMutation,
  useGetCategoriesQuery,
} from '../../generated/graphql';
import arrayMove from 'array-move';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

const OrderList = () => {
  const toast = useToast();
  const { data, loading, error, refetch } = useGetCategoriesQuery({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const [order] = useOrderCategoryMutation();
  const setOrder = (data: GetCategoriesQueryResult['data']['categories']) => {
    data.forEach(async (element, i) => {
      try {
        await order({
          variables: {
            id: element.id,
            order: i + 1,
          },
        });
      } catch (error) {
        return toast({
          title: 'Hata',
          description: error.message,
          status: 'error',
          duration: 2500,
          isClosable: true,
        });
      }
    });
  };

  return (
    <>
      <VStack align='start' mb='4'>
        <Text fontWeight='bold' fontSize='2xl'>
          E-Commerce kategorilerini sırala
        </Text>

        <ButtonGroup alignSelf='flex-end' isAttached>
          <Button colorScheme='blue' as={Link} to='/categories'>
            Geri
          </Button>
          <IconButton
            aria-label='refresh'
            icon={<MdRefresh />}
            onClick={() => refetch()}
          />
        </ButtonGroup>
      </VStack>
      {error && <MessageBox message={error.message} status='error' />}
      {loading ? (
        <Loading />
      ) : data?.categories.length <= 0 ? (
        <MessageBox
          status='warning'
          message='Henüz hiçbir kategori oluşturmamışsınız. Yeni bir tane oluşturmak için yeni
          butonunu kullanın.'
        />
      ) : (
        <Container maxW='2xl'>
          <SortableTable items={data.categories} setOrder={setOrder} />
        </Container>
      )}
    </>
  );
};

const TableItem = SortableElement(
  ({ item }: { item: GetCategoriesQueryResult['data']['categories'][0] }) => {
    return (
      <Tr _hover={{ cursor: 'grab' }}>
        <Td>
          {item.descriptions[0].title.trim().length <= 0
            ? `No title`
            : `${item.descriptions[0].title}`}
        </Td>
      </Tr>
    );
  }
);

const SortableList = SortableContainer(
  ({ items }: { items: GetCategoriesQueryResult['data']['categories'] }) => {
    return (
      <Table>
        <Thead>
          <Tr>
            <Th>Başlık</Th>
          </Tr>
        </Thead>
        <Tbody>
          {items.map((element, index) => (
            <TableItem key={element.id} item={element} index={index} />
          ))}
        </Tbody>
      </Table>
    );
  }
);

class SortableTable extends React.Component<
  {
    items: GetCategoriesQueryResult['data']['categories'];
    setOrder: (data: any) => void;
  },
  { setOrder: any; items: any }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      items: this.props.items,
      setOrder: this.props.setOrder,
    };
  }

  onSortEnd = ({ oldIndex, newIndex }: { oldIndex: any; newIndex: any }) => {
    if (oldIndex === newIndex) return;
    this.setState(({ items }) => {
      const newOrder = arrayMove(items, oldIndex, newIndex);
      this.state.setOrder(newOrder);
      return {
        items: newOrder,
      };
    });
  };

  render() {
    return <SortableList items={this.state.items} onSortEnd={this.onSortEnd} />;
  }
}

export default OrderList;
