import {
  VStack,
  Text,
  Button,
  useToast,
  SimpleGrid,
  FormControl,
  FormLabel,
  Input,
  Select,
  GridItem,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import TinyMCE from '../../components/TinyMCE';
import { useCreateDescMutation } from '../../generated/graphql';
import { DescriptionRouteProps } from '../../types';

function NewDescription() {
  const { goBack } = useHistory();
  const { state } = useLocation<DescriptionRouteProps>();

  const [title, setTitle] = useState('');
  const [language, setLanguage] = useState('en');
  const [description, setDescription] = useState('');

  const [shortDescription, setShortDescription] = useState('');
  const [buttonText, setButtonText] = useState('');
  const [buttonUrl, setButtonUrl] = useState('');

  const toast = useToast();

  const [createDescription, { loading: createDescLoading }] =
    useCreateDescMutation();

  const submitHandler = async (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    try {
      await createDescription({
        variables: {
          title,
          field: state.field,
          fieldId: state.fieldId,
          description,
          shortDescription,
          language,
          buttonUrl,
          buttonText,
        },
      });
      return toast({
        title: 'OK',
        description: 'Başarılı, yönlendiriliyorsunuz...',
        status: 'success',
        duration: 2000,
        isClosable: true,
        onCloseComplete: () => {
          goBack();
        },
      });
    } catch (error) {
      console.log('catch err ⚠️', error.message);
      return toast({
        title: 'Hata',
        description: error.message,
        status: 'error',
        duration: 2500,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <VStack align='start' mb='4'>
        <Text fontWeight='bold' fontSize='2xl'>
          Yeni açıklama
        </Text>
      </VStack>

      <SimpleGrid columns={[1, 2]} spacing={[2, 8]}>
        <FormControl>
          <FormLabel>Başlık</FormLabel>
          <Input
            type='text'
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Dil</FormLabel>
          <Select
            value={language}
            onChange={(e) => setLanguage(e.target.value)}
          >
            <option value='tr'>Türkçe</option>
            <option value='en'>İngilizce</option>
          </Select>
        </FormControl>

        <FormControl>
          <FormLabel>Buton Yazı</FormLabel>
          <Input
            type='text'
            value={buttonText}
            onChange={(e) => setButtonText(e.target.value)}
          />
        </FormControl>

        <FormControl>
          <FormLabel>Buton URL</FormLabel>
          <Input
            type='text'
            value={buttonUrl}
            onChange={(e) => setButtonUrl(e.target.value)}
          />
        </FormControl>

        <GridItem colSpan={2}>
          <FormControl>
            <FormLabel>Açıklama</FormLabel>
            <TinyMCE
              height={200}
              value={description}
              setValue={setDescription}
            />
          </FormControl>
        </GridItem>

        <GridItem colSpan={2}>
          <FormControl>
            <FormLabel>Kısa Açıklama</FormLabel>
            <TinyMCE
              height={200}
              value={shortDescription}
              setValue={setShortDescription}
            />
          </FormControl>
        </GridItem>

        <FormControl>
          <Button variant='ghost' mr={3} onClick={goBack} border='1px'>
            Geri
          </Button>

          <Button
            colorScheme='blue'
            type='button'
            disabled={createDescLoading}
            isLoading={createDescLoading}
            onClick={submitHandler}
          >
            Kaydet
          </Button>
        </FormControl>
      </SimpleGrid>
    </>
  );
}

export default NewDescription;
