import { Cloudinary } from '@cloudinary/base';
import { fill } from '@cloudinary/base/actions/resize';
import { AdvancedImage, lazyload } from '@cloudinary/react';

const myCld = new Cloudinary({ cloud: { cloudName: 'yesimturkoz' } });

type Props = {
  publicId: string;
  width?: number;
} & React.HTMLAttributes<HTMLImageElement>;

const CloudinaryImage: React.FC<Props> = ({ publicId, width, ...rest }) => {
  const img = myCld.image(publicId);

  if (width) {
    img.resize(fill().width(width).height(width));
  }

  return <AdvancedImage cldImg={img} plugins={[lazyload()]} {...rest} />;
};

export default CloudinaryImage;
