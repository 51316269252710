import {
  Input,
  FormControl,
  FormLabel,
  Box,
  IconButton,
  Button,
  FormHelperText,
  Flex,
  Image,
  Container,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { BsTrash } from 'react-icons/bs';
import { useHistory, useParams } from 'react-router-dom';
import Select from 'react-select';
import CloudinaryImage from '../../components/CloudinaryImage';
import MessageBox from '../../components/MessageBox';

import {
  useUpdateBrandMutation,
  useUpdateBrandWithoutPhotoMutation,
  useUploadFileMutation,
  GetBrandQueryResult,
  useGetCountriesQuery,
} from '../../generated/graphql';

interface Props {
  data: GetBrandQueryResult['data']['brand'];
}

const EditForm: React.FC<Props> = ({ data }) => {
  const { id } = useParams<{ id: string }>();
  const { push } = useHistory();

  const [title, setTitle] = useState(data.title ? data.title : '');
  const [logo, setLogo] = useState<any>(data.photo ? data.photo : '');
  const [logoPreview, setLogoPreview] = useState('');
  const [countries, setCountries] = useState<any[]>([]);

  const { data: countriesData, loading: getCountriesLoading } =
    useGetCountriesQuery();

  const [error, setError] = useState('');

  const [uploadPhoto, { loading: uploadLoading }] = useUploadFileMutation();
  const [updateBrand, { loading }] = useUpdateBrandMutation();
  const [updateBrandWithoutPhoto, { loading: loadingWithoutParent }] =
    useUpdateBrandWithoutPhotoMutation();

  const handleImg = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setLogo(e.target.files[0]);
      setLogoPreview(URL.createObjectURL(e.target.files[0]));
    }
  };

  const [dataLogoPreview, setDataLogoPreview] = useState('');
  useEffect(() => {
    setDataLogoPreview(data.photo);
  }, [data.photo]);

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      if (logo && typeof logo === 'object') {
        const uploadResponse = await uploadPhoto({
          variables: { file: logo },
        });
        await updateBrand({
          variables: {
            id,
            title,
            photo: (uploadResponse.data.uploadFile as any).file,
            countries: countries.map((country) => ({ id: country.value })),
          },
        });
      } else {
        await updateBrandWithoutPhoto({
          variables: {
            id,
            title,
            photo: logo,
            countries: countries.map((country) => ({ id: country.value })),
          },
        });
      }
      setError('');
      push(`/brands`);
    } catch (error) {
      console.log('err ⚠️', error);
      setError(error.message);
    }
  };

  useEffect(() => {
    const appliedCountries = data.countries.map((country) => ({
      value: country.id,
      label: country.title,
    }));
    setCountries(appliedCountries);
  }, [data.countries]);

  return (
    <Container width='full' ml='0' pl='0'>
      <form onSubmit={onSubmit}>
        {error && <MessageBox status='warning' message={error} />}
        <FormControl isRequired mb='4'>
          <FormLabel>Marka Adı</FormLabel>
          <Input
            type='text'
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </FormControl>
        <FormControl mb='4'>
          <FormLabel>Satıldığı Ülkeler</FormLabel>
          <Select
            placeholder='Ülkeler'
            isMulti
            isDisabled={getCountriesLoading}
            isLoading={getCountriesLoading}
            value={countries}
            options={
              countriesData &&
              countriesData.countries.map((country) => ({
                value: country.id,
                label: country.title,
              }))
            }
            onChange={(e) =>
              setCountries(
                e.map((item) => ({
                  id: item.value,
                  label: item.label,
                  value: item.value,
                }))
              )
            }
          />
        </FormControl>
        <Flex>
          <Box mr='8'>
            <FormControl>
              <FormLabel>Logo (opsiyonel)</FormLabel>
              <Input type='file' multiple={false} onChange={handleImg} />
              <FormHelperText>
                İsterseniz markaya bir görsel yükleyebilirsiniz.
              </FormHelperText>
            </FormControl>
          </Box>
          <Box boxSize='150px' position='relative'>
            {dataLogoPreview && !logoPreview ? (
              <>
                <IconButton
                  position='absolute'
                  bottom='0'
                  right='0'
                  colorScheme='yellow'
                  aria-label='delete image'
                  icon={<BsTrash size='24' />}
                  onClick={() => {
                    setLogo('');
                    setDataLogoPreview('');
                  }}
                />
                <CloudinaryImage publicId={dataLogoPreview} width={150} />
              </>
            ) : (
              <>
                {logoPreview && (
                  <IconButton
                    position='absolute'
                    bottom='0'
                    right='0'
                    colorScheme='yellow'
                    aria-label='delete image'
                    icon={<BsTrash size='24' />}
                    onClick={() => {
                      setLogo('');
                      setLogoPreview('');
                      setDataLogoPreview('');
                    }}
                  />
                )}
                {logoPreview && (
                  <Image
                    src={logoPreview}
                    rounded='sm'
                    objectFit='cover'
                    boxSize='150px'
                  />
                )}
              </>
            )}
          </Box>
        </Flex>

        <Button
          colorScheme='blue'
          type='submit'
          mt='10px'
          isLoading={loading || loadingWithoutParent || uploadLoading}
        >
          Kaydet
        </Button>
      </form>
    </Container>
  );
};

export default EditForm;
