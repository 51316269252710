import React from 'react';
import {
  Alert,
  AlertIcon,
  Button,
  ButtonGroup,
  chakra,
  Container,
  IconButton,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from '@chakra-ui/react';
import { useToast } from '@chakra-ui/toast';
import { MdRefresh } from 'react-icons/md';
import { Link, useParams } from 'react-router-dom';
import Loading from '../../components/Loading';
import MessageBox from '../../components/MessageBox';
import {
  GetProductsByCategoryIdQueryResult,
  useGetProductsByCategoryIdQuery,
  useOrderProductMutation,
} from '../../generated/graphql';
import arrayMove from 'array-move';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

const OrderList = () => {
  const toast = useToast();
  const { categoryId } = useParams<{ categoryId?: string }>();
  const { data, loading, error, refetch } = useGetProductsByCategoryIdQuery({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: { categoryId },
  });

  const [order] = useOrderProductMutation();
  const setOrder = (
    data: GetProductsByCategoryIdQueryResult['data']['products']['products']
  ) => {
    data?.forEach(async (element, i) => {
      try {
        await order({
          variables: {
            id: element.id,
            order: i + 1,
          },
        });
      } catch (error) {
        return toast({
          title: 'Hata',
          description: error.message,
          status: 'error',
          duration: 2500,
          isClosable: true,
        });
      }
    });
  };

  return (
    <>
      <VStack align='start' mb='4'>
        <Text fontWeight='bold' fontSize='2xl'>
          Ürün sırala
        </Text>

        <ButtonGroup alignSelf='flex-end' isAttached>
          <Button colorScheme='blue' as={Link} to='/categories'>
            Geri
          </Button>
          <IconButton
            aria-label='refresh'
            icon={<MdRefresh />}
            onClick={() => refetch()}
          />
        </ButtonGroup>
        {data && data.products.products.length > 0 && (
          <Alert status='warning'>
            <AlertIcon />
            <chakra.div mx='auto'>
              <chakra.span fontWeight='bold' mr='1'>
                {data.products.products[0]?.category.descriptions[0]?.title}
              </chakra.span>
              kategorisindeki ürünleri sıralıyorsunuz
            </chakra.div>
          </Alert>
        )}
      </VStack>
      {error && <MessageBox message={error.message} status='error' />}
      {loading ? (
        <Loading />
      ) : data?.products.products.length <= 0 ? (
        <MessageBox
          status='warning'
          message='Henüz hiçbir ürün oluşturmamışsınız. Yeni bir tane oluşturmak için yeni
          butonunu kullanın.'
        />
      ) : (
        <Container maxW='2xl'>
          <SortableTable items={data.products} setOrder={setOrder} />
        </Container>
      )}
    </>
  );
};

const TableItem = SortableElement(
  ({
    item,
  }: {
    item: GetProductsByCategoryIdQueryResult['data']['products']['products'][0];
  }) => {
    return (
      <Tr _hover={{ cursor: 'grab' }}>
        <Td>
          {item.descriptions[0].title.trim().length <= 0
            ? `No title`
            : `${item.descriptions[0].title}`}
        </Td>
      </Tr>
    );
  }
);

const SortableList = SortableContainer(
  ({
    items,
  }: {
    items: GetProductsByCategoryIdQueryResult['data']['products'];
  }) => {
    return (
      <Table>
        <Thead>
          <Tr>
            <Th>Başlık</Th>
          </Tr>
        </Thead>
        <Tbody>
          {items.products.map((element, index) => (
            <TableItem key={element.id} item={element} index={index} />
          ))}
        </Tbody>
      </Table>
    );
  }
);

class SortableTable extends React.Component<
  {
    items: GetProductsByCategoryIdQueryResult['data']['products'];
    setOrder: (data: any) => void;
  },
  {
    setOrder: any;
    items: GetProductsByCategoryIdQueryResult['data']['products'];
  }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      items: this.props.items,
      setOrder: this.props.setOrder,
    };
  }

  onSortEnd = ({ oldIndex, newIndex }: { oldIndex: any; newIndex: any }) => {
    if (oldIndex === newIndex) return;
    this.setState((state) => {
      const { products } = state.items;
      this.state.setOrder(products);
      const newOrder = arrayMove(products, oldIndex, newIndex);
      this.state.setOrder(newOrder);
      return {
        items: {
          total: state.items.total,
          products: newOrder,
        },
      };
    });
  };

  render() {
    return <SortableList items={this.state.items} onSortEnd={this.onSortEnd} />;
  }
}

export default OrderList;
