import {
  Button,
  ButtonGroup,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Box,
  Flex,
  IconButton,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
  useToast,
  Select,
  useColorModeValue,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { useState } from 'react';
import { MdRefresh } from 'react-icons/md';
import { Link } from 'react-router-dom';
import DeletePopover from '../../components/DeletePopover';
import DescriptionModal from '../../components/Description';
import Loading from '../../components/Loading';
import MessageBox from '../../components/MessageBox';
import {
  useDeleteProductMutation,
  useGetProductsQuery,
  GetProductsQueryResult,
  useGetCategoriesQuery,
  useGetCategoriesLazyQuery,
  ProductWhereInput,
} from '../../generated/graphql';

interface ItemProps {
  item: GetProductsQueryResult['data']['products']['products'][0];
  deleteLoading: boolean;
  deleteHandler: (id: any) => any;
  index: number;
  refetch: any;
}

const TableItem = (props: ItemProps) => {
  const trBg = useColorModeValue('gray.100', 'gray.700');
  const trBorderColor = useColorModeValue('whiteAlpha.900', 'whiteAlpha.200');
  
  return (
    <>
      <Tr
        key={props.item.id}
        backgroundColor={trBg}
        border='2px'
        borderColor={trBorderColor}
      >
        <Td>{props.index + 1}</Td>
        <Td>
          <DescriptionModal
            descriptionProp={props.item.descriptions}
            field='product'
            fieldId={props.item.id}
            refetch={props.refetch}
          />
        </Td>
        <Td>
          {
            props.item.category.descriptions.find((d) => d.language === 'tr')
              .title
          }
        </Td>
        <Td>{props.item.brand.title}</Td>
        <Td isNumeric>
          <ButtonGroup size='sm' variant='solid'>
            <Button
              colorScheme='orange'
              as={Link}
              to={`/readercomments/order/${props.item.id}`}
            >
              [{props.item.readerComments.length}] Yorum Sırala
            </Button>
            <Button
              colorScheme='pink'
              as={Link}
              to={`/products/content/${props.item.id}`}
            >
              [{props.item.content.length}] İçerikler
            </Button>
            <Button
              colorScheme='teal'
              as={Link}
              to={`/products/update/${props.item.id}`}
            >
              Düzenle
            </Button>
            <DeletePopover
              deleteHandler={props.deleteHandler}
              pageId={props.item.id}
              isLoading={props.deleteLoading}
            />
          </ButtonGroup>
        </Td>
      </Tr>
    </>
  );
};

type CategoryIdSetter = {
  parentCat: string;
  childCat?: string;
};
function categoryIdSetter({
  parentCat,
  childCat,
}: CategoryIdSetter): ProductWhereInput {
  if (parentCat.length > 0 && childCat.length <= 0) {
    return {
      categoryId: { equals: parentCat },
    };
  } else if (parentCat.length > 0 && childCat.length > 0) {
    return {
      categoryId: { equals: childCat },
    };
  } else return null;
}

const List = () => {
  const toast = useToast();
  const [parentCat, setParentCat] = useState('');
  const [childCat, setChildCat] = useState('');

  /* const take = 10;
  const [page, setPage] = useState(1);
  const skip = (page - 1) * take; */

  const { data, loading, error, refetch } = useGetProductsQuery({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      where: {
        ...(parentCat && { ...categoryIdSetter({ parentCat, childCat }) }),
      },
      // take,
      // skip,
    },
  });

  /* const totalPages = Math.ceil(data && data.products.total / take);
  console.log('data ::', data); */
  const { data: parentCategoriesData, loading: parentCategoriesLoading } =
    useGetCategoriesQuery({
      variables: {
        where: {
          parentId: { equals: null },
        },
      },
    });

  const [getChildCats, { data: childCats, loading: childCatsLoading }] =
    useGetCategoriesLazyQuery();

  const [deleteProduct, { loading: deleteLoading }] =
    useDeleteProductMutation();

  const deleteHandler = async (id: any) => {
    try {
      const res = await deleteProduct({ variables: { id } });
      if (res) refetch();
    } catch (error) {
      console.log('catch err ⚠️', error);
      return toast({
        title: 'Hata',
        description: error.message,
        status: 'error',
        duration: 2500,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    getChildCats({ variables: { where: { parentId: { equals: parentCat } } } });
  }, [childCats, getChildCats, parentCat]);

  const theadBg = useColorModeValue('blue.200', 'blue.700');

  return (
    <>
      <VStack align='start' mb='4'>
        <Text fontWeight='bold' fontSize='2xl'>
          Ürünler
        </Text>

        <Breadcrumb>
          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to='/'>
              Anasayfa
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink href='#'>Ürünler</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Flex
          w='full'
          justifyContent='space-between'
          flexDir={['column', 'row']}
        >
          <Box display='flex'>
            <Select
              value={parentCat}
              onChange={(e) => {
                setParentCat(e.target.value);
                setChildCat('');
              }}
              mb={['4', '0']}
              mr={['0', '4']}
              width='md'
              disabled={parentCategoriesLoading}
            >
              <option value='' disabled>
                --Kategori filtresi--
              </option>
              {parentCategoriesData &&
                parentCategoriesData.categories.map((parentCat) => (
                  <option value={parentCat.id} key={parentCat.id}>
                    {parentCat.descriptions[0].title}
                  </option>
                ))}
            </Select>
            {/* <Button
              onClick={() => {
                setChildCat('');
                setParentCat('');
                refetch();
              }}
              colorScheme='blackAlpha'
              isDisabled={!!!parentCat}
            >
              Temizle
            </Button> */}

            {childCatsLoading ? (
              <Loading />
            ) : (
              childCats &&
              childCats.categories.length > 0 && (
                <>
                  <Select
                    value={childCat}
                    onChange={(e) => {
                      setChildCat(e.target.value);
                    }}
                    mb={['4', '0']}
                    width='md'
                    mr={['0', '4']}
                  >
                    <option value='' disabled>
                      --Seçiniz--
                    </option>
                    {childCats.categories.map((category) => (
                      <option value={category.id} key={category.id}>
                        {category.descriptions[0].title}
                      </option>
                    ))}
                  </Select>
                  <Button
                    onClick={() => {
                      setChildCat('');
                      setParentCat('');
                      refetch();
                    }}
                    colorScheme='blackAlpha'
                  >
                    Temizle
                  </Button>
                </>
              )
            )}
          </Box>
          <ButtonGroup isAttached>
            <Button colorScheme='green' as={Link} to='/products/new'>
              Yeni
            </Button>
            <IconButton
              aria-label='refresh'
              icon={<MdRefresh />}
              onClick={() => refetch()}
            />
          </ButtonGroup>
        </Flex>
      </VStack>
      {error && <MessageBox message={error.message} status='error' />}
      {loading ? (
        <Loading />
      ) : data?.products.products.length <= 0 ? (
        <MessageBox
          status='warning'
          message={
            parentCat || childCat
              ? 'Ürün bulunamadı'
              : 'Henüz hiçbir ürün oluşturmamışsınız. Yeni bir tane oluşturmak için yeni butonunu kullanın.'
          }
        />
      ) : (
        <>
          <Table>
            <Thead backgroundColor={theadBg}>
              <Tr>
                <Th>#</Th>
                <Th>Başlık</Th>
                <Th>Kategori</Th>
                <Th>Marka</Th>
                <Th isNumeric>İşlem</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data &&
                data.products.products.map((product, index: number) => (
                  <TableItem
                    key={index}
                    index={index}
                    item={product}
                    deleteHandler={deleteHandler}
                    deleteLoading={deleteLoading}
                    refetch={refetch}
                  />
                ))}
            </Tbody>
          </Table>
          {/* <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            my='4'
          >
            <IconButton
              aria-label='back'
              icon={<RiArrowLeftSLine />}
              disabled={page === 1}
              onClick={() => setPage((page) => page - 1)}
            />
            <Text mx='6' fontWeight='bold'>
              {page} / {totalPages}
            </Text>
            <IconButton
              aria-label='back'
              icon={<RiArrowRightSLine />}
              disabled={page === totalPages}
              onClick={() => setPage((page) => page + 1)}
            />
          </Box> */}
        </>
      )}
    </>
  );
};

export default List;
