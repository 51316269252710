import {
  Input,
  FormControl,
  FormLabel,
  Text,
  VStack,
  Button,
  Divider,
  Stack,
} from '@chakra-ui/react';
import { useState } from 'react';

import { useHistory } from 'react-router-dom';

import ContentDescription from '../../components/ContentDescription';
import MessageBox from '../../components/MessageBox';

import { useCreateEventMutation } from '../../generated/graphql';

const Add = () => {
  const { push } = useHistory();

  const [title, setTitle] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  // description inputs
  const [language] = useState('tr');
  const [description, setDescription] = useState('');
  const [shortDescription, setShortDescription] = useState('');
  const [buttonText, setButtonText] = useState('');
  const [buttonUrl, setButtonUrl] = useState('');

  const [error, setError] = useState('');

  const [createEvent, { loading }] = useCreateEventMutation();

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await createEvent({
        variables: {
          startDate,
          endDate,
          title,
          description,
          shortDescription,
          language,
          buttonText,
          buttonUrl,
        },
      });

      setError('');
      push('/events');
    } catch (error) {
      console.log('err ⚠️', error);
      setError(error.message);
    }
  };

  return (
    <>
      <VStack align='flex-start' mb='4'>
        <Text fontWeight='bold' fontSize='2xl'>
          Yeni Etkinlik
        </Text>
      </VStack>

      <form onSubmit={onSubmit}>
        {error && <MessageBox status='warning' message={error} />}
        <Stack direction={{ base: 'column', lg: 'row' }} spacing='6'>
          <FormControl isRequired>
            <FormLabel>Etkinlik Adı</FormLabel>
            <Input
              type='text'
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Başlangıç Tarihi</FormLabel>
            <Input
              type='date'
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Bitiş Tarihi</FormLabel>
            <Input
              type='date'
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </FormControl>
        </Stack>

        <Divider my='6' />
        <ContentDescription
          title={title}
          setTitle={setTitle}
          description={description}
          setDescription={setDescription}
          shortDescription={shortDescription}
          setShortDescription={setShortDescription}
          buttonText={buttonText}
          setButtonText={setButtonText}
          buttonUrl={buttonUrl}
          setButtonUrl={setButtonUrl}
          titleDisabled={true}
          buttonTextDisabled={true}
          buttonUrlDisabled={true}
        />

        <Button colorScheme='blue' type='submit' mt='10px' isLoading={loading}>
          Kaydet
        </Button>
      </form>
    </>
  );
};

export default Add;
