import { Editor } from '@tinymce/tinymce-react';
interface Props {
  value?: string | undefined;
  setValue?: any;
  height?: string | number | undefined;
  disabled?: boolean;
}

export default function TinyMCE({
  value,
  setValue,
  height,
  disabled = false,
}: Props) {
  return (
    <Editor
      disabled={disabled}
      initialValue={value}
      init={{
        height: height ? height : 500,
        menubar: false,
        removed_menuitems: 'sourcecode',
        branding: false,
        language_url: '/js/tr_TR.js',
        language: 'tr_TR',
        plugins: [
          'advlist autolink lists link image print preview anchor',
          'searchreplace visualblocks fullscreen',
          'insertdatetime media table paste wordcount',
        ],
        toolbar:
          'formatselect | bold italic forecolor | alignleft aligncenter alignright alignjustify | image media | link table bullist numlist outdent indent | removeformat',
      }}
      onEditorChange={(content) =>
        setValue ? setValue(content) : console.log('tinymce setvalue error')
      }
    />
  );
}
