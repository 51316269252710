import { Skeleton, Stack } from '@chakra-ui/react';

function FormSkeleton({ amount }: { amount: number }) {
  return (
    <Stack>
      {Array(amount)
        .fill(0)
        .map((_, i) => (
          <Skeleton key={i} height='50px' w='full' />
        ))}
    </Stack>
  );
}

export default FormSkeleton;
