type ICategoryIdSetter = {
  parentCat: string;
  childCat?: string;
};

export default function categoryIdSetter({
  parentCat,
  childCat,
}: ICategoryIdSetter): string {
  if (parentCat.length > 0 && childCat.length <= 0) {
    return parentCat;
  } else if (parentCat.length > 0 && childCat.length > 0) {
    return childCat;
  } else return null;
}
