import {
  Button,
  ButtonProps,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
} from '@chakra-ui/react';

type Props = {
  deleteHandler: any;
  pageId: any;
  isLoading: boolean;
  buttonProps?: ButtonProps;
};
const DeletePopover = ({
  deleteHandler,
  pageId,
  isLoading,
  buttonProps,
}: Props) => {
  return (
    <Popover>
      <PopoverTrigger>
        <Button colorScheme='red' {...buttonProps}>
          Sil
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent>
          <PopoverArrow />
          <PopoverHeader fontWeight='bold'>Silme İşlemi</PopoverHeader>
          <PopoverCloseButton />
          <PopoverBody>
            Bu öğe kalıcı olarak silinecektir. Emin misiniz? <br />
            <Button
              colorScheme='red'
              onClick={() => deleteHandler(pageId)}
              isLoading={isLoading}
            >
              Sil
            </Button>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

export default DeletePopover;
