import {
  Box,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Select,
  SimpleGrid,
} from '@chakra-ui/react';
import ReactPlayer from 'react-player';
import {
  ContentLineupSelect,
  ContentTypeSelect,
  MediaTypeSelect,
  ThemeTypeSelect,
} from '.';
import { ContentType } from '../../generated/graphql';
import { ContentFormProps } from '../../types';

import EmojiCard from '../EmojiCard';
import GoogleMap from '../GoogleMap';
import Loading from '../Loading';
import MessageBox from '../MessageBox';
import SlideCard from '../SlideCard';
import TimelineCard from '../TimelineCard';
import UploadDropzone from '../UploadDropzone';
import renderBgImageSelector from './renderBgImageSelector';
import renderHighlightedProductSelector from './renderHighlightedProductSelector';

const ContentForm: React.FC<ContentFormProps> = ({
  mode,
  error,
  contentType,
  setContentType,
  lineUp,
  setLineUp,
  childCat,
  parentCat,
  setChildCat,
  setParentCat,
  parentCategoriesData,
  parentCategoriesLoading,
  childCats,
  childCatsLoading,
  blogCategoriesData,
  blogCategory,
  setBlogCategory,
  handleImg,
  logoPreview,
  setLogo,
  setLogoPreview,
  dataLogoPreview,
  setDataLogoPreview,
  cardFields,
  timelineCardFields,
  latitude,
  longitude,
  mediaType,
  photos,
  products,
  productsData,
  productsLoading,
  setCardFields,
  setTimelineCardFields,
  setLatitude,
  setLongitude,
  setMediaType,
  setPhotos,
  setProducts,
  setSlides,
  setThemeType,
  setVideoUrl,
  slides,
  themeType,
  validVideo,
  setValidVideo,
  videoUrl,
}) => {
  return (
    <>
      {error && <MessageBox status='warning' message={error} />}

      <Box mb='4' display='flex'>
        <FormControl>
          <FormLabel>İçerik Türü</FormLabel>
          <ContentTypeSelect
            contentType={contentType}
            setContentType={setContentType}
          />
          <FormHelperText>İçerik çeşidini seçiniz.</FormHelperText>
        </FormControl>

        <ContentLineupSelect lineUp={lineUp} setLineUp={setLineUp} ml='4' />
      </Box>

      <Flex flexDirection={['column', 'row']}>
        <Flex width={['100%', '50%']}>
          <Box mr='8'>
            <FormControl>
              <FormLabel>Arkaplan Resmi (opsiyonel)</FormLabel>
              <Input type='file' multiple={false} onChange={handleImg} />
              <FormHelperText>
                İsterseniz içeriğe arka plan görseli yükleyebilirsiniz.
              </FormHelperText>
            </FormControl>
          </Box>
          {renderBgImageSelector({
            logoPreview,
            mode,
            setLogo,
            setLogoPreview,
            dataLogoPreview,
            setDataLogoPreview,
          })}
        </Flex>
        <Flex width={['100%', '50%']}>
          <ThemeTypeSelect themeType={themeType} setThemeType={setThemeType} />
        </Flex>
      </Flex>

      {contentType === ContentType.PRODUCT_CATEGORY && (
        <SimpleGrid columns={[1, 2]} spacing={[2, 8]} mb='4'>
          <FormControl isRequired>
            <FormLabel>Kategori</FormLabel>
            <Select
              value={parentCat}
              onChange={(e) => {
                setParentCat(e.target.value);
                setChildCat('');
              }}
              disabled={parentCategoriesLoading}
            >
              <option value='' disabled>
                --Kategori--
              </option>
              {parentCategoriesData &&
                parentCategoriesData.categories.map((parentCat) => (
                  <option value={parentCat.id} key={parentCat.id}>
                    {parentCat.descriptions[0].title}
                  </option>
                ))}
            </Select>
          </FormControl>
          <FormControl>
            {childCatsLoading ? (
              <Loading />
            ) : (
              childCats &&
              childCats.categories.length > 0 && (
                <>
                  <FormLabel>Alt Kategori</FormLabel>
                  <Select
                    value={childCat}
                    onChange={(e) => {
                      setChildCat(e.target.value);
                    }}
                  >
                    <option value=''>--Seçiniz--</option>
                    {childCats.categories.map((category) => (
                      <option value={category.id} key={category.id}>
                        {category.descriptions[0].title}
                      </option>
                    ))}
                  </Select>
                  <FormHelperText>Alt kategori seçebilirsiniz</FormHelperText>
                </>
              )
            )}
          </FormControl>
        </SimpleGrid>
      )}

      {contentType === ContentType.LATEST_NEWS && (
        <FormControl isRequired w='50%'>
          <FormLabel>Blog Kategorisi</FormLabel>
          <Select
            value={blogCategory}
            onChange={(e) => setBlogCategory(e.target.value)}
          >
            <option value='' disabled>
              --
            </option>
            {blogCategoriesData &&
              blogCategoriesData.blogCategories.map((category) => (
                <option value={category.id} key={category.id}>
                  {category.description.length > 1
                    ? category.description.map((desc) => `(${desc.title})`)
                    : category.description.map((desc) => `${desc.title}`)}
                </option>
              ))}
          </Select>
          <FormHelperText>
            Gösterilecek blog kategorisini seçiniz. Bu kategorideki son 3
            makale, kutular şeklinde gösterilecektir.
          </FormHelperText>
        </FormControl>
      )}

      {contentType === ContentType.IMAGE && (
        <>
          <FormControl mb='4'>
            <FormLabel>Resim Yerleşimleri</FormLabel>
            <MediaTypeSelect
              mediaType={mediaType}
              setMediaType={setMediaType}
            />
            <FormHelperText>
              Görsellerin web sayfanızdaki yerleşimleri.
            </FormHelperText>
          </FormControl>
          <FormControl mb='4'>
            <UploadDropzone images={photos} setImageArray={setPhotos} />
          </FormControl>
        </>
      )}

      {contentType === ContentType.MAP && (
        <FormControl>
          <FormLabel>Harita Pin</FormLabel>
          <GoogleMap
            latitude={latitude}
            setLatitude={setLatitude}
            longitude={longitude}
            setLongitude={setLongitude}
          />
        </FormControl>
      )}

      {contentType === ContentType.VIDEO && (
        <FormControl>
          <FormLabel>Video URL</FormLabel>
          <Input
            type='text'
            name='title'
            value={videoUrl}
            onChange={(e) => setVideoUrl(e.target.value)}
            mb='4'
            isInvalid={!validVideo}
          />
          {!validVideo && (
            <FormHelperText mt='0' color='red.400'>
              Geçerli video linki giriniz
            </FormHelperText>
          )}
          {videoUrl && (
            <ReactPlayer
              url={videoUrl}
              onError={() => setValidVideo(false)}
              onReady={() => setValidVideo(true)}
            />
          )}
        </FormControl>
      )}

      {contentType === ContentType.CARD && (
        <EmojiCard cardFields={cardFields} setCardFields={setCardFields} />
      )}

      {contentType === ContentType.SLIDESHOW && (
        <SlideCard cardFields={slides} setCardFields={setSlides} />
      )}

      {contentType === ContentType.HIGHLIGHTED_PRODUCTS &&
        renderHighlightedProductSelector({
          mode,
          products,
          productsData,
          productsLoading,
          setProducts,
        })}

      {contentType === ContentType.TIMELINE && (
        <TimelineCard
          cardFields={timelineCardFields}
          setCardFields={setTimelineCardFields}
        />
      )}
    </>
  );
};

export default ContentForm;
