import {
  Input,
  FormControl,
  FormLabel,
  Box,
  Text,
  VStack,
  Button,
  SimpleGrid,
  GridItem,
  Divider,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useHistory } from 'react-router';
import MessageBox from '../../components/MessageBox';
import GoogleMap from '../../components/GoogleMap';
import { useCreateAddressMutation } from '../../generated/graphql';
import ContentDescription from '../../components/ContentDescription';

const Add = () => {
  const { push } = useHistory();
  const [addressTitle, setAddressTitle] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [addressLineOne, setAddressLineOne] = useState('');
  const [addressLineTwo, setAddressLineTwo] = useState('');
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);

  const [registrationNumber, setRegistrationNumber] = useState('');
  const [mersisNumber, setMersisNumber] = useState('');
  const [kepEmail, setKepEmail] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [faxNumber, setFaxNumber] = useState('');
  const [taxNumber, setTaxNumber] = useState('');
  const [taxOffice, setTaxOffice] = useState('');

  const [title, setTitle] = useState('');
  const [language] = useState('tr');
  const [description, setDescription] = useState('');
  const [shortDescription, setShortDescription] = useState('');
  const [buttonText, setButtonText] = useState('');
  const [buttonUrl, setButtonUrl] = useState('');

  const [error, setError] = useState('');

  const [createAddress, { loading }] = useCreateAddressMutation();

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await createAddress({
        variables: {
          addressTitle,
          postalCode,
          addressLineOne,
          addressLineTwo,
          longitude,
          latitude,
          registrationNumber,
          email,
          faxNumber,
          phoneNumber,
          mersisNumber,
          kepEmail,
          taxNumber,
          taxOffice,
          // DESCRIPTION FIELDS
          title,
          description,
          shortDescription,
          language,
          buttonText,
          buttonUrl,
        },
      });
      push('/contact');
    } catch (error) {
      console.log('err ⚠️', error.graphQLErrors);
      setError(error.message);
    }
  };

  return (
    <>
      <VStack align='flex-start' mb='4'>
        <Text fontWeight='bold' fontSize='2xl'>
          Yeni Adres
        </Text>
      </VStack>
      <form onSubmit={onSubmit}>
        <Box width='full' mb='8'>
          {error && <MessageBox status='warning' message={error} />}
          <SimpleGrid columns={[1, 2]} spacing={[2, 8]}>
            <FormControl isRequired>
              <FormLabel>Adres Başlığı</FormLabel>
              <Input
                type='text'
                value={addressTitle}
                onChange={(e) => setAddressTitle(e.target.value)}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Posta Kodu</FormLabel>
              <Input
                type='text'
                value={postalCode}
                onChange={(e) => setPostalCode(e.target.value)}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Adres Satır 1</FormLabel>
              <Input
                type='text'
                value={addressLineOne}
                onChange={(e) => setAddressLineOne(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Adres Satır 2</FormLabel>
              <Input
                type='text'
                value={addressLineTwo}
                onChange={(e) => setAddressLineTwo(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Sicil Numarası</FormLabel>
              <Input
                type='text'
                value={registrationNumber}
                onChange={(e) => setRegistrationNumber(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Telefon Numarası</FormLabel>
              <Input
                type='text'
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Faks Numarası</FormLabel>
              <Input
                type='text'
                value={faxNumber}
                onChange={(e) => setFaxNumber(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Email</FormLabel>
              <Input
                type='text'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Mersis Numarası</FormLabel>
              <Input
                type='text'
                value={mersisNumber}
                onChange={(e) => setMersisNumber(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <FormLabel>KEP Email</FormLabel>
              <Input
                type='text'
                value={kepEmail}
                onChange={(e) => setKepEmail(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Vergi No</FormLabel>
              <Input
                type='text'
                value={taxNumber}
                onChange={(e) => setTaxNumber(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Vergi Dairesi</FormLabel>
              <Input
                type='text'
                value={taxOffice}
                onChange={(e) => setTaxOffice(e.target.value)}
              />
            </FormControl>
            <GridItem colSpan={2}>
              <FormControl>
                <FormLabel>Harita Pin</FormLabel>
                <GoogleMap
                  latitude={latitude}
                  setLatitude={setLatitude}
                  longitude={longitude}
                  setLongitude={setLongitude}
                />
              </FormControl>
            </GridItem>
          </SimpleGrid>

          <Divider my='6' />
          <ContentDescription
            title={title}
            setTitle={setTitle}
            description={description}
            setDescription={setDescription}
            shortDescription={shortDescription}
            setShortDescription={setShortDescription}
            buttonText={buttonText}
            setButtonText={setButtonText}
            buttonUrl={buttonUrl}
            setButtonUrl={setButtonUrl}
          />
          <Button
            colorScheme='blue'
            type='submit'
            mt='10px'
            isLoading={loading}
          >
            Kaydet
          </Button>
        </Box>
      </form>
    </>
  );
};

export default Add;
