import React from 'react';
import {
  Button,
  ButtonGroup,
  Container,
  IconButton,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from '@chakra-ui/react';
import { useToast } from '@chakra-ui/toast';
import { MdRefresh } from 'react-icons/md';
import { Link } from 'react-router-dom';
import Loading from '../../components/Loading';
import MessageBox from '../../components/MessageBox';
import {
  GetPagesQueryResult,
  useGetPagesQuery,
  useOrderPageMutation,
} from '../../generated/graphql';
import arrayMove from 'array-move';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

const OrderList = () => {
  const toast = useToast();
  const { data, loading, error, refetch } = useGetPagesQuery({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const [order] = useOrderPageMutation();
  const setOrder = (data: GetPagesQueryResult['data']['pages']) => {
    data.forEach(async (element, i) => {
      try {
        await order({
          variables: {
            id: element.id,
            order: i + 1,
          },
        });
      } catch (error) {
        return toast({
          title: 'Hata',
          description: error.message,
          status: 'error',
          duration: 2500,
          isClosable: true,
        });
      }
    });
  };

  return (
    <>
      <VStack align='start' mb='4'>
        <Text fontWeight='bold' fontSize='2xl'>
          Sayfaları Sırala
        </Text>

        <ButtonGroup alignSelf='flex-end' isAttached>
          <Button colorScheme='blue' as={Link} to='/pages'>
            Geri
          </Button>
          <IconButton
            aria-label='refresh'
            icon={<MdRefresh />}
            onClick={() => refetch()}
          />
        </ButtonGroup>
      </VStack>
      {error && <MessageBox message={error.message} status='error' />}
      {loading ? (
        <Loading />
      ) : data?.pages.length <= 0 ? (
        <MessageBox
          status='warning'
          message='Henüz hiçbir sayfa oluşturmamışsınız. Yeni bir tane oluşturmak için yeni
          butonunu kullanın.'
        />
      ) : (
        <Container maxW='2xl'>
          <SortableTable items={data.pages} setOrder={setOrder} />
        </Container>
      )}
    </>
  );
};

const TableItem = SortableElement((props: any) => {
  return (
    <Tr _hover={{ cursor: 'grab' }}>
      <Td>{props.item.description[0].title}</Td>
    </Tr>
  );
});

const SortableList = SortableContainer((props: any) => {
  return (
    <Table>
      <Thead>
        <Tr>
          <Th>Başlık</Th>
        </Tr>
      </Thead>
      <Tbody>
        {props.items.map((element, index) => (
          <TableItem
            key={element.id}
            id={element.id}
            item={element}
            index={index}
          />
        ))}
      </Tbody>
    </Table>
  );
});

class SortableTable extends React.Component<
  {
    items: GetPagesQueryResult['data']['pages'];
    setOrder: (data: any) => void;
  },
  { setOrder: any; items: any }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      items: this.props.items,
      setOrder: this.props.setOrder,
    };
  }

  onSortEnd = ({ oldIndex, newIndex }: { oldIndex: any; newIndex: any }) => {
    if (oldIndex === newIndex) return;
    this.setState(({ items }) => {
      const newOrder = arrayMove(items, oldIndex, newIndex);
      this.state.setOrder(newOrder);
      return {
        items: newOrder,
      };
    });
  };

  render() {
    return <SortableList items={this.state.items} onSortEnd={this.onSortEnd} />;
  }
}

export default OrderList;
