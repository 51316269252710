import {
  Button,
  Text,
  VStack,
  Link as ChakraLink,
  chakra,
  useColorModeValue,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import Loading from '../../components/Loading';
import MessageBox from '../../components/MessageBox';
import {
  useGetFormMessageQuery,
  useMarkAsReadMessageMutation,
} from '../../generated/graphql';

const Edit = () => {
  const { id } = useParams<{ id: string }>();

  const { data, loading, error } = useGetFormMessageQuery({
    fetchPolicy: 'network-only',
    variables: { id },
  });
  const [markAsRead] = useMarkAsReadMessageMutation();

  useEffect(() => {
    markAsRead({ variables: { id } });
  }, [id, markAsRead]);

  const theadBg = useColorModeValue('blue.200', 'blue.700');
  const trBg = useColorModeValue('gray.100', 'gray.700');

  if (loading || !data) {
    return <Loading />;
  }

  return (
    <>
      <VStack align='flex-start' mb='4'>
        <Text fontWeight='bold' fontSize='2xl'>
          Mesaj
        </Text>

        <Button colorScheme='blackAlpha' as={Link} to='/messages'>
          Geri
        </Button>
      </VStack>
      {error ? (
        <MessageBox status='error' message={error.message} />
      ) : (
        <chakra.div maxW='8xl' mx='auto' display='flex' flexDir='column'>
          <chakra.div display='flex' mb='2'>
            <chakra.div
              bgColor={theadBg}
              flex='0 0 120px'
              p='2'
              fontWeight='bold'
            >
              Ad
            </chakra.div>
            <chakra.div bgColor={trBg} flex='1' p='2'>
              {data.findFirstCustomForms.name}
            </chakra.div>
          </chakra.div>

          <chakra.div display='flex' mb='2'>
            <chakra.div
              bgColor={theadBg}
              flex='0 0 120px'
              p='2'
              fontWeight='bold'
            >
              Soyad
            </chakra.div>
            <chakra.div bgColor={trBg} flex='1' p='2'>
              {data.findFirstCustomForms.surname}
            </chakra.div>
          </chakra.div>

          <chakra.div display='flex' mb='2'>
            <chakra.div
              bgColor={theadBg}
              flex='0 0 120px'
              p='2'
              fontWeight='bold'
            >
              Email
            </chakra.div>
            <chakra.div bgColor={trBg} flex='1' p='2'>
              {data.findFirstCustomForms.email}
            </chakra.div>
          </chakra.div>

          <chakra.div display='flex' mb='2'>
            <chakra.div
              bgColor={theadBg}
              flex='0 0 120px'
              p='2'
              fontWeight='bold'
            >
              Şirket adı
            </chakra.div>
            <chakra.div bgColor={trBg} flex='1' p='2'>
              {data.findFirstCustomForms.companyName}
            </chakra.div>
          </chakra.div>

          <chakra.div display='flex' mb='2'>
            <chakra.div
              bgColor={theadBg}
              flex='0 0 120px'
              p='2'
              fontWeight='bold'
            >
              İlişkili ürün
            </chakra.div>
            <chakra.div bgColor={trBg} flex='1' p='2'>
              {data.findFirstCustomForms.productId ? (
                <ChakraLink
                  as={Link}
                  to={`/products/update/${data.findFirstCustomForms.productId}`}
                  color='blue.600'
                >
                  {data.findFirstCustomForms.product.descriptions[0]?.title}
                </ChakraLink>
              ) : (
                '-'
              )}
            </chakra.div>
          </chakra.div>

          <chakra.div display='flex' mb='2'>
            <chakra.div
              bgColor={theadBg}
              flex='0 0 120px'
              p='2'
              fontWeight='bold'
            >
              Mesaj
            </chakra.div>
            <chakra.div bgColor={trBg} flex='1' p='2'>
              {data.findFirstCustomForms.message}
            </chakra.div>
          </chakra.div>
        </chakra.div>
      )}
    </>
  );
};

export default Edit;
