import {
  FormLabel,
  IconButton,
  FormControl,
  Box,
  Input,
  Textarea,
  chakra,
  useBoolean,
} from '@chakra-ui/react';
import { AiOutlineDelete } from 'react-icons/ai';
import { TimelineCardField } from '../types';
import { BsArrowsMove } from 'react-icons/bs';
import { SortableHandle } from 'react-sortable-hoc';
import { HexColorPicker } from 'react-colorful';

interface Props {
  removeCardField: (id: any) => void;
  cardFields: TimelineCardField[];
  setCardFields?: React.Dispatch<React.SetStateAction<TimelineCardField[]>>;
  card: TimelineCardField;
  handleColorSelect: (id: any, e: string) => void;
  handleCardInputChange: (id: any, event: any) => void;
}

const DragHandle = SortableHandle(() => <BsArrowsMove size={18} />);

const TimelineInput: React.FC<Props> = ({
  card,
  cardFields,
  removeCardField,
  handleColorSelect,
  handleCardInputChange,
}) => {
  const [showColorPanel, { toggle }] = useBoolean(false);

  return (
    <>
      <FormControl display='flex' alignItems='center' w='full'>
        <IconButton
          icon={<AiOutlineDelete size={20} />}
          aria-label='delete card'
          onClick={() => removeCardField(card.id)}
          size='sm'
          variant='ghost'
          colorScheme='red'
          disabled={cardFields?.length <= 1}
        />
        <chakra.div ml='auto'>
          <DragHandle />
        </chakra.div>
      </FormControl>

      <FormControl position='relative'>
        <FormLabel>Renk seçin</FormLabel>
        <Box
          h='40px'
          w='40px'
          bgColor={card.color || 'orange'}
          rounded='sm'
          onClick={toggle}
        ></Box>
        {showColorPanel && (
          <HexColorPicker
            style={{ position: 'absolute', zIndex: 50, top: '100px' }}
            onChange={(e) => handleColorSelect(card.id, e)}
          />
        )}
      </FormControl>

      <FormControl mb='2'>
        <FormLabel>Başlık</FormLabel>
        <Input
          type='text'
          name='title'
          value={card.title}
          onChange={(e) => handleCardInputChange(card.id, e)}
          placeholder='başlık'
        />
      </FormControl>
      <FormControl mb='2'>
        <FormLabel>Alt Başlık</FormLabel>
        <Input
          type='text'
          name='subtitle'
          value={card.subtitle}
          onChange={(e) => handleCardInputChange(card.id, e)}
          placeholder='alt başlık'
        />
      </FormControl>

      <FormControl mb='2'>
        <FormLabel>Açıklama</FormLabel>
        <Textarea
          name='description'
          value={card.description}
          onChange={(e) => handleCardInputChange(card.id, e)}
          placeholder='açıklama'
          resize='none'
        />
      </FormControl>
      <FormControl mb='2'>
        <FormLabel>Tarih</FormLabel>
        <Input
          name='date'
          value={card.date}
          onChange={(e) => handleCardInputChange(card.id, e)}
          placeholder='tarih, örneğin 2015-2017'
        />
      </FormControl>
    </>
  );
};

export default TimelineInput;
// export default SortableElement(TimelineInput);
