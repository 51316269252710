import { FormControl, FormHelperText, FormLabel } from '@chakra-ui/react';
import ReactSelect from 'react-select';
import { IRenderHighlightedProductSelector } from '../../types';

const renderHighlightedProductSelector = ({
  mode,
  productsLoading,
  productsData,
  products,
  setProducts,
}: IRenderHighlightedProductSelector) => {
  if (mode === 'ADD') {
    return (
      <FormControl>
        <FormLabel>Ürün seçin</FormLabel>
        <ReactSelect
          placeholder='Ürünler'
          isMulti
          isDisabled={productsLoading}
          isLoading={productsLoading}
          options={
            productsData &&
            productsData.products.products.map((product) => ({
              value: product.id,
              label: product.descriptions[0]?.title || product.sku,
            }))
          }
          onChange={(e) => setProducts(e.map((item) => ({ id: item.value })))}
        />
        <FormHelperText>
          Öne çıkarılacak ürünleri belirleyin. Birden fazla ürün seçebilirsiniz.
        </FormHelperText>
      </FormControl>
    );
  }
  if (mode === 'EDIT') {
    return (
      <FormControl>
        <FormLabel>Ürün seçin</FormLabel>
        <ReactSelect
          placeholder='Ürünler'
          isMulti
          isDisabled={productsLoading}
          isLoading={productsLoading}
          value={products}
          options={
            productsData &&
            productsData.products.products.map((product) => ({
              value: product.id,
              label: product.descriptions[0]?.title || product.sku,
            }))
          }
          onChange={(e) =>
            setProducts(
              e.map((item) => ({
                id: item.value,
                label: item.label,
                value: item.value,
              }))
            )
          }
        />
        <FormHelperText>
          Öne çıkarılacak ürünleri belirleyin. Birden fazla ürün seçebilirsiniz.
        </FormHelperText>
      </FormControl>
    );
  }
};

export default renderHighlightedProductSelector;
