import {
  ApolloClient,
  ApolloProvider as Provider,
  InMemoryCache,
  split,
  ApolloLink,
  Observable,
} from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { WebSocketLink } from '@apollo/client/link/ws';
import { onError } from '@apollo/client/link/error';
import { getMainDefinition } from '@apollo/client/utilities';
import { setContext } from '@apollo/client/link/context';
import { setAccessToken } from './services/tokenChecker';

const promiseToObservable = (promiseParameter: any) =>
  new Observable((subscriber: any) => {
    promiseParameter().then(
      (res) => {
        if (subscriber.closed) return;
        subscriber.next(res);
        subscriber.complete();
      },
      (err) => subscriber.error(err)
    );
    return subscriber;
  });

let httpLink = createUploadLink({
  uri: process.env.REACT_APP_API,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('accessToken');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const wsLink = new WebSocketLink({
  uri: process.env.REACT_APP_WS,
  options: {
    reconnect: true,
    connectionParams: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  },
});

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      for (let err of graphQLErrors) {
        switch (err.extensions.code) {
          // Apollo Server sets code to UNAUTHENTICATED
          // when an AuthenticationError is thrown in a resolver
          case 'UNAUTHENTICATED':
            // Modify the operation context with a new token
            if (localStorage.getItem('refreshToken')) {
              const oldHeaders = operation.getContext().headers;

              return promiseToObservable(setAccessToken).flatMap((res) => {
                operation.setContext({
                  headers: {
                    ...oldHeaders,
                    authorization: `Bearer ${res}`,
                  },
                });
                localStorage.setItem('accessToken', res as string);
                return forward(operation);
              });
            }
            return;
          default:
            console.log('INTERNAL SERVER ERROR THROWN', err.extensions);
        }
      }
    }

    // To retry on network errors, we recommend the RetryLink
    // instead of the onError link. This just logs the error.
    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
    }
  }
);

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink
);

export const client = new ApolloClient({
  link: ApolloLink.from([authLink, errorLink, splitLink]),

  cache: new InMemoryCache(),
});

export default function ApolloProvider(props: any) {
  return <Provider client={client} {...props} />;
}
