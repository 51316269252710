import { Input as CInput } from '@chakra-ui/input';
import { SkeletonGroupLayout } from './SkeletonGroup';

interface Props {
  state: string;
  setState: Function;
  label?: string;
  loading?: boolean;
}

export const Input = ({ state, setState, label, loading = false }: Props) => {
  return (
    <SkeletonGroupLayout label={label} loading={loading}>
      <CInput
        value={state}
        type='text'
        onChange={(e) => setState(e.target.value)}
        placeholder={label || ''}
        size='md'
      />
    </SkeletonGroupLayout>
  );
};
