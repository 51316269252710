import { useState, useCallback, memo } from 'react';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';

const containerStyle = {
  width: 'full',
  height: '500px',
};

interface Props {
  latitude: any;
  longitude: any;
  setLatitude: any;
  setLongitude: any;
}

const GMap: React.FC<Props> = (props) => {
  const position = {
    lat: props.latitude ? props.latitude : 41,
    lng: props.longitude ? props.longitude : 29,
  };
  const [latitude, mapSetLatitude] = useState(
    props.latitude ? props.latitude : 41
  );
  const [longitude, mapSetLongitude] = useState(
    props.longitude ? props.longitude : 29
  );
  const { setLatitude, setLongitude } = props;

  const { isLoaded } = useJsApiLoader({
    id: 'google-map',
    googleMapsApiKey: 'AIzaSyDWmWVzUWsvpIjL0lpCYF0iBi83RkpuhUI',
  });

  const [, setMap] = useState(null);
  const markerOnLoad = (marker) => {
    console.log('marker LOADED: ', marker);
  };

  const onLoad = useCallback(function callback(map) {
    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  return (
    <div>
      {isLoaded && (
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={{ lat: latitude, lng: longitude }}
          zoom={14}
          onLoad={onLoad}
          onUnmount={onUnmount}
        >
          <Marker
            onLoad={markerOnLoad}
            position={position}
            draggable
            onDragEnd={(e) => {
              mapSetLatitude(e.latLng.lat());
              mapSetLongitude(e.latLng.lng());
              setLatitude(e.latLng.lat());
              setLongitude(e.latLng.lng());
            }}
          />
        </GoogleMap>
      )}
    </div>
  );
};

export default memo(GMap);
