import { useEffect, useState } from 'react';
import { Box, Button, ButtonGroup } from '@chakra-ui/react';
import { Link, useHistory, useParams } from 'react-router-dom';
import {
  ContentLineup,
  ContentType,
  GetContentQueryResult,
  MediaType,
  ThemeType,
  useGetBlogCategoriesQuery,
  useGetCategoriesLazyQuery,
  useGetCategoriesQuery,
  useGetProductsQuery,
  useUpdatecontentMutation,
  useUpdatecontentWithoutBgMutation,
  useUploadFileMutation,
} from '../../generated/graphql';
import { ContentSlide, TimelineCardField } from '../../types';
import categoryIdSetter from '../../services/categoryIdSetter';
import ContentForm from '../../components/ContentInputs/ContentForm';

interface Props {
  data: GetContentQueryResult['data']['content'];
}

const EditForm: React.FC<Props> = ({ data }) => {
  const { id } = useParams<{ id: string }>();
  const { push } = useHistory();

  const [latitude, setLatitude] = useState(
    data.latitude ? data.latitude : null
  );
  const [longitude, setLongitude] = useState(
    data.longitude ? data.longitude : null
  );
  const [videoUrl, setVideoUrl] = useState(data.videos ? data.videos : '');
  const [validVideo, setValidVideo] = useState<any>(null);
  const [photos, setPhotos] = useState(data.photos ? data.photos : []);
  const [contentType, setContentType] = useState<ContentType>(
    data.contentType ? data.contentType : ContentType.TEXT
  );
  const [mediaType, setMediaType] = useState(
    data.mediaType ? data.mediaType : MediaType.SINGLE
  );
  const [themeType, setThemeType] = useState<ThemeType>(data.theme);
  const [lineUp, setLineUp] = useState<ContentLineup>(data.lineUp);

  const [parentCat, setParentCat] = useState(
    data.productCategory?.parentId
      ? data.productCategory.parentId
      : data.productCategory
      ? data.productCategory.id
      : ''
  );
  const [childCat, setChildCat] = useState(
    data.productCategory?.parentId ? data.productCategory.id : ''
  );
  const [blogCategory, setBlogCategory] = useState(data.blogCategoryId || '');

  // cards
  const [cardFields, setCardFields] = useState(data.cards);

  // slide
  const [slides, setSlides] = useState<ContentSlide[]>(data.slides);

  //timeline cards
  const [timelineCardFields, setTimelineCardFields] = useState<
    TimelineCardField[]
  >(data.timeline);

  const [error, setError] = useState('');

  const { data: productsData, loading: productsLoading } =
    useGetProductsQuery();
  const [products, setProducts] = useState<any[]>([]);

  const [editContent, { loading }] = useUpdatecontentMutation();
  const [editContentWithoutBg, { loading: loadingWithoutBg }] =
    useUpdatecontentWithoutBgMutation();
  const [uploadPhoto, { loading: uploadLoading }] = useUploadFileMutation();

  const [logo, setLogo] = useState<any>(
    data.backgroundImage ? data.backgroundImage : ''
  );
  const [logoPreview, setLogoPreview] = useState('');

  const handleImg = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setLogo(e.target.files[0]);
      setLogoPreview(URL.createObjectURL(e.target.files[0]));
    }
  };
  const [dataLogoPreview, setDataLogoPreview] = useState('');
  useEffect(() => {
    setDataLogoPreview(data.backgroundImage);
  }, [data.backgroundImage]);

  const { data: parentCategoriesData, loading: parentCategoriesLoading } =
    useGetCategoriesQuery({
      variables: {
        where: {
          parentId: { equals: null },
        },
      },
    });

  const [getChildCats, { data: childCats, loading: childCatsLoading }] =
    useGetCategoriesLazyQuery();

  const { data: blogCategoriesData } = useGetBlogCategoriesQuery();

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (contentType === 'IMAGE' && photos.length <= 0) {
      setError('Galeriye hiç resim eklemediniz');
      return;
    }
    if (contentType === 'VIDEO' && !validVideo) {
      setError('Geçerli bir video linki ekleyiniz');
      return;
    }
    try {
      if (logo && typeof logo === 'object') {
        const uploadResponse = await uploadPhoto({
          variables: { file: logo },
        });
        await editContent({
          variables: {
            id,
            photos,
            videos: videoUrl,
            contentType,
            mediaType,
            longitude,
            latitude,
            cards: cardFields,
            slides,
            backgroundImage: (uploadResponse.data.uploadFile as any).file,
            theme: themeType,
            productCategoryId: categoryIdSetter({ parentCat, childCat }),
            highlightedProducts: products.map((product) => ({
              id: product.value,
            })),
            lineUp,
            timeline: timelineCardFields,
            blogCategoryId: blogCategory,
          },
        });
      } else {
        await editContentWithoutBg({
          variables: {
            id,
            photos,
            videos: videoUrl,
            contentType,
            mediaType,
            longitude,
            latitude,
            cards: cardFields,
            slides,
            theme: themeType,
            productCategoryId: categoryIdSetter({ parentCat, childCat }),
            highlightedProducts: products.map((product) => ({
              id: product.value,
            })),
            lineUp,
            timeline: timelineCardFields,
            blogCategoryId: blogCategory,
          },
        });
      }
      setError('');
      push(`/pages/content/${data.page.id}`);
    } catch (error) {
      console.log('err ⚠️', error);
      setError(error.message);
    }
  };

  useEffect(() => {
    getChildCats({ variables: { where: { parentId: { equals: parentCat } } } });
  }, [childCats, getChildCats, parentCat]);

  useEffect(() => {
    const appliedProducts = data.highlightedProducts.map((product) => ({
      value: product.id,
      label: product.descriptions[0]?.title || product.sku,
    }));
    setProducts(appliedProducts);
  }, [data.highlightedProducts]);

  return (
    <form onSubmit={onSubmit}>
      <Box width='full' mb='8'>
        <ContentForm
          mode='EDIT'
          blogCategoriesData={blogCategoriesData}
          blogCategory={blogCategory}
          setBlogCategory={setBlogCategory}
          cardFields={cardFields}
          timelineCardFields={timelineCardFields}
          setTimelineCardFields={setTimelineCardFields}
          childCat={childCat}
          childCats={childCats}
          childCatsLoading={childCatsLoading}
          contentType={contentType}
          error={error}
          handleImg={handleImg}
          latitude={latitude}
          lineUp={lineUp}
          logoPreview={logoPreview}
          longitude={longitude}
          mediaType={mediaType}
          parentCat={parentCat}
          parentCategoriesData={parentCategoriesData}
          parentCategoriesLoading={parentCategoriesLoading}
          photos={photos}
          productsData={productsData}
          productsLoading={productsLoading}
          setCardFields={setCardFields}
          setChildCat={setChildCat}
          setContentType={setContentType}
          setLatitude={setLatitude}
          setLineUp={setLineUp}
          setLogo={setLogo}
          setLogoPreview={setLogoPreview}
          setLongitude={setLongitude}
          setMediaType={setMediaType}
          setParentCat={setParentCat}
          setPhotos={setPhotos}
          setProducts={setProducts}
          setSlides={setSlides}
          setThemeType={setThemeType}
          setValidVideo={setValidVideo}
          setVideoUrl={setVideoUrl}
          slides={slides}
          themeType={themeType}
          validVideo={validVideo}
          videoUrl={videoUrl}
          dataLogoPreview={dataLogoPreview}
          products={products}
          setDataLogoPreview={setDataLogoPreview}
        />

        <ButtonGroup my='5'>
          <Link to={`/pages/content/${data.page.id}`}>
            <Button colorScheme='gray' type='submit'>
              Vazgeç
            </Button>
          </Link>
          <Button
            colorScheme='blue'
            type='submit'
            isLoading={loading || loadingWithoutBg || uploadLoading}
          >
            Kaydet
          </Button>
        </ButtonGroup>
      </Box>
    </form>
  );
};

export default EditForm;
