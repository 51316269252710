import {
  Box,
  Button,
  Drawer,
  DrawerOverlay,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerContent,
  Flex,
  Spacer,
  useColorModeValue,
  AccordionItem,
  Accordion,
  AccordionPanel,
  AccordionButton,
  AccordionIcon,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { useLogoutMutation } from '../generated/graphql';
import url from '../services/url';
import { useUserStore } from '../store';
import { DarkModeSwitch } from './DarkModeSwitch';

interface Props {
  onClose: any;
  isOpen: boolean;
  variant: string | undefined;
}

const SidebarContent = () => {
  const logoutDispatch = useUserStore((store) => store.logoutUser);
  const [logoutMutation, { loading: logoutLoading }] = useLogoutMutation();

  const logout = async () => {
    try {
      await logoutMutation();
      logoutDispatch();
      window.location.href = '/';
    } catch (error) {
      console.log('logout catch err:: ', error.message);
    }
  };
  return (
    <Flex flexDirection='column' height='full'>
      <Box mb='4'>
        <Button w='100%' as={Link} to={url.HOME} fontWeight='normal'>
          Anasayfa
        </Button>
      </Box>
      <Box mb='4'>
        <Button w='100%' as={Link} to={url.PAGESLIST} fontWeight='normal'>
          Sayfalar
        </Button>
      </Box>
      <Box mb='4'>
        <Button w='100%' as={Link} to={url.CONTACTSLIST} fontWeight='normal'>
          İletişim Bilgileri
        </Button>
      </Box>

      <Box mb='4'>
        <Button w='100%' as={Link} to={url.CATEGORIESLIST} fontWeight='normal'>
          Kategoriler
        </Button>
      </Box>
      <Box mb='4'>
        <Button w='100%' as={Link} to={url.BRANDSLIST} fontWeight='normal'>
          Markalar
        </Button>
      </Box>
      <Box mb='4'>
        <Button w='100%' as={Link} to={url.PRODUCTSLIST} fontWeight='normal'>
          Ürünler
        </Button>
      </Box>
      <Box mb='4'>
        <Button w='100%' as={Link} to={url.READERCOMMENTLIST} fontWeight='normal'>
          Okuyucu Yorumları
        </Button>
      </Box>
      <Box mb='4'>
        <Button w='100%' as={Link} to={url.FORMIOLIST} fontWeight='normal'>
          Formlar
        </Button>
      </Box>
      <Box mb='4'>
        <Button w='100%' as={Link} to={url.FORMMESAGELIST} fontWeight='normal'>
          Mesajlar
        </Button>
      </Box>
      <Box mb='4'>
        <Button w='100%' as={Link} to={url.FAQLIST} fontWeight='normal'>
          S.S.S
        </Button>
      </Box>
      <Box mb='4'>
        <Button w='100%' as={Link} to={url.EVENTLIST} fontWeight='normal'>
          Etkinlikler
        </Button>
      </Box>
      <Box mb='4'>
        <Accordion allowToggle>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex='1' textAlign='left'>
                  Blog
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel>
              <Button
                w='100%'
                as={Link}
                to={url.BLOGCATEGORYLIST}
                fontWeight='normal'
              >
                Kategoriler
              </Button>
              <Button
                w='100%'
                as={Link}
                to={url.BLOGPOSTLIST}
                fontWeight='normal'
              >
                Yazılar
              </Button>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Box>
      <Box mb='4'>
        <Accordion allowToggle>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex='1' textAlign='left'>
                  Ayarlar
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel>
              <Button
                w='100%'
                as={Link}
                to={url.APPSETTINGS}
                fontWeight='normal'
              >
                Genel Ayarlar
              </Button>
              <Button
                w='100%'
                as={Link}
                to={url.ECOMMERCESETTINGS}
                fontWeight='normal'
              >
                Ecommerce
              </Button>
              <Button w='100%' as={Link} to={url.USERSLIST} fontWeight='normal'>
                Kullanıcılar
              </Button>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Box>

      <Spacer />
      <Flex justifyItems='center'>
        <DarkModeSwitch width='100%' border='1px' borderColor='gray' />
      </Flex>
      <Flex mt='2'>
        <Button
          w='100%'
          colorScheme='red'
          rounded='none'
          onClick={logout}
          disabled={logoutLoading}
        >
          Çıkış Yap
        </Button>
      </Flex>
    </Flex>
  );
};

const Sidebar = ({ isOpen, onClose, variant }: Props) => {
  const sidebarBg = useColorModeValue('gray.100', 'gray.700');
  return variant === 'sidebar' ? (
    <Box p={5} flex='0 0 240px' bgColor={sidebarBg} minHeight='100vh'>
      <SidebarContent />
    </Box>
  ) : (
    <Drawer isOpen={isOpen} placement='left' onClose={onClose}>
      <DrawerOverlay>
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Kontrol Paneli</DrawerHeader>

          <DrawerBody>
            <SidebarContent />
          </DrawerBody>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};

export default Sidebar;
