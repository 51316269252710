import {
  Input,
  FormControl,
  FormLabel,
  Text,
  VStack,
  Button,
  Divider,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import MessageBox from '../../components/MessageBox';
import { useCreateFormioFormMutation } from '../../generated/graphql';

const Add = () => {
  const { push } = useHistory();

  const [title, setTitle] = useState('');
  const [slug, setSlug] = useState('');

  const [error, setError] = useState('');

  const [createBrand, { loading }] = useCreateFormioFormMutation();

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await createBrand({
        variables: {
          title,
          slug,
        },
      });

      setError('');
      push(`/formioForms`);
    } catch (error) {
      console.log('err ⚠️', error);
      setError(error.message);
    }
  };

  return (
    <>
      <VStack align='flex-start' mb='4'>
        <Text fontWeight='bold' fontSize='2xl'>
          Yeni Form
        </Text>
        <Text>
          Sistem üzerinden oluşturduğunuz form adını ve başlığını girerek
          formunuzu oluşturabilirsiniz.
        </Text>
      </VStack>

      <form onSubmit={onSubmit}>
        {error && <MessageBox status='warning' message={error} />}
        <FormControl isRequired mb='4'>
          <FormLabel>Başlık</FormLabel>
          <Input
            type='text'
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </FormControl>

        <FormControl isRequired mb='4'>
          <FormLabel>Form Adı</FormLabel>
          <Input
            type='text'
            value={slug}
            onChange={(e) => setSlug(e.target.value)}
          />
        </FormControl>

        <Divider my='6' />

        <Button colorScheme='blue' type='submit' mt='10px' isLoading={loading}>
          Kaydet
        </Button>
      </form>
    </>
  );
};

export default Add;
