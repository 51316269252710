import { useState } from 'react';
import {
  Input,
  FormControl,
  FormLabel,
  Button,
  Container,
} from '@chakra-ui/react';

import { useHistory, useParams } from 'react-router-dom';
import MessageBox from '../../components/MessageBox';

import {
  useUpdateFormioFormMutation,
  GetFormioFormQueryResult,
} from '../../generated/graphql';

interface Props {
  data: GetFormioFormQueryResult['data']['formioForm'];
}

const EditForm: React.FC<Props> = ({ data }) => {
  const { id } = useParams<{ id: string }>();
  const { push } = useHistory();

  const [title, setTitle] = useState(data.title ? data.title : '');
  const [slug, setSlug] = useState(data.slug ? data.slug : '');

  const [error, setError] = useState('');

  const [updateBrand, { loading }] = useUpdateFormioFormMutation();

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await updateBrand({
        variables: {
          id,
          title,
          slug,
        },
      });

      setError('');
      push(`/formioForms`);
    } catch (error) {
      console.log('err ⚠️', error);
      setError(error.message);
    }
  };

  return (
    <Container width='full' ml='0' pl='0'>
      <form onSubmit={onSubmit}>
        {error && <MessageBox status='warning' message={error} />}
        <FormControl isRequired mb='4'>
          <FormLabel>Başlık</FormLabel>
          <Input
            type='text'
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </FormControl>

        <FormControl isRequired mb='4'>
          <FormLabel>Form Adı</FormLabel>
          <Input
            type='text'
            value={slug}
            onChange={(e) => setSlug(e.target.value)}
          />
        </FormControl>

        <Button colorScheme='blue' type='submit' mt='10px' isLoading={loading}>
          Kaydet
        </Button>
      </form>
    </Container>
  );
};

export default EditForm;
