import {
  Box,
  Container,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react';
import Header from './Header';
import Sidebar from './Sidebar';

const smVariant = { navigation: 'drawer', navigationButton: true };
const mdVariant = { navigation: 'sidebar', navigationButton: true };

const Layout: React.FC = ({ children }) => {
  const variants = useBreakpointValue({ base: smVariant, md: mdVariant });
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box display='flex' flex='1 1 auto' height='100%'>
      {/* SIDEBAR */}
      <Sidebar
        isOpen={isOpen}
        onClose={onClose}
        variant={variants?.navigation}
      />
      {/* CONTENT */}
      <Box
        ml={!variants?.navigationButton && 200}
        // flex='1 1 auto'
        flexGrow='inherit'
      >
        <Header
          onShowSidebar={onOpen}
          showSidebarButton={variants?.navigation === 'drawer'}
        />
        <Container maxWidth='full' py='4'>
          {children}
        </Container>
      </Box>
    </Box>
  );
};

export default Layout;
