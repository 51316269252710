import { InputGroup } from '@chakra-ui/input';
import { Text } from '@chakra-ui/layout';
import { Skeleton } from '@chakra-ui/skeleton';

interface Props {
  children: JSX.Element;
  label?: string;
  loading?: boolean;
}

export const SkeletonGroupLayout = ({ label, loading, children }: Props) => {
  return (
    <InputGroup justifyContent='space-between' mb='5'>
      {label && (
        <Text alignSelf='center' mr='5'>
          {label}
        </Text>
      )}
      <Skeleton isLoaded={!loading} width='80%'>
        {children}
      </Skeleton>
    </InputGroup>
  );
};
