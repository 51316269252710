import create from 'zustand';
import { devtools } from 'zustand/middleware';
import { LoginMutationResult } from '../generated/graphql';

interface DecodedUser {
  id: string;
  email?: string;
  iat?: number;
  exp?: number;
  accessToken?: string;
}

interface UserStore {
  user: DecodedUser;
  loginUser: (payload: LoginMutationResult['data']['login']) => void;
  logoutUser: any;
  setUser: (payload: LoginMutationResult['data']['login']) => void;
}

export const useUserStore = create<UserStore>(
  devtools(
    (set) => ({
      user: null,
      loginUser(payload) {
        set((state) => {
          localStorage.setItem('refreshToken', payload.refreshToken);
          return {
            ...state,
            user: payload,
          };
        });
      },
      logoutUser() {
        set((state) => {
          localStorage.removeItem('refreshToken');
          localStorage.removeItem('accessToken');
          return {
            ...state,
            user: null,
          };
        });
      },
      setUser(payload) {
        set((state) => ({ ...state, user: payload }));
      },
    }),
    'users'
  )
);
