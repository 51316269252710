import { useCallback, useState, useEffect } from 'react';
import { Flex, IconButton, Text, Wrap, WrapItem } from '@chakra-ui/react';
import { useDropzone } from 'react-dropzone';
import { BsCardImage, BsTrash } from 'react-icons/bs';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';

import Loading from './Loading';
import { useUploadFileMutation } from '../generated/graphql';
import CloudinaryImage from './CloudinaryImage';

interface SortablePhotoProps {
  deletePhoto: any;
  photo: any;
}
interface SortablePhotosProps {
  items: any[];
  deletePhoto: any;
}

const SortablePhoto = SortableElement(
  ({ photo, deletePhoto }: SortablePhotoProps) => {
    return (
      <WrapItem position='relative' _hover={{ cursor: 'grab' }}>
        <IconButton
          position='absolute'
          bottom='0'
          right='0'
          size='sm'
          colorScheme='red'
          aria-label='delete image'
          icon={<BsTrash />}
          onClick={() => deletePhoto(photo)}
        />
        <CloudinaryImage publicId={photo} width={160} />
      </WrapItem>
    );
  }
);

const SortablePhotos = SortableContainer(
  ({ deletePhoto, items }: SortablePhotosProps) => {
    return (
      items.length > 0 && (
        <Wrap spacing='12px' mb='4'>
          {items.map((photo, index) => {
            return (
              <SortablePhoto
                deletePhoto={deletePhoto}
                photo={photo}
                key={index}
                index={index}
              />
            );
          })}
        </Wrap>
      )
    );
  }
);

const UploadDropzone = ({ images, setImageArray }) => {
  const [uploadPhoto, { loading: uploadLoading }] = useUploadFileMutation();

  const [addedPhotos, setAddedPhotos] = useState(images ? images : []);
  const [change, setChange] = useState(1);

  const deletePhoto = (fileName) => {
    const photos = addedPhotos.filter((photo) => photo !== fileName);
    setAddedPhotos(() => [...photos]);
  };

  useEffect(() => {
    setImageArray(addedPhotos);
  }, [addedPhotos, setAddedPhotos, setImageArray]);

  const onDrop = useCallback(
    async (acceptedFiles) => {
      acceptedFiles.map(async (a) => {
        try {
          const result = await uploadPhoto({
            variables: {
              file: a,
            },
          });

          setAddedPhotos((prevState) => [
            ...prevState,
            (result.data.uploadFile as any).file,
          ]);
          setChange(change + Math.random());
        } catch (error) {
          console.log(error);
        }
      });
    },
    [change, uploadPhoto]
  );

  const { isDragActive, getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex === newIndex) return;
    const newOrder = arrayMove(addedPhotos, oldIndex, newIndex);
    setAddedPhotos(newOrder);
  };

  return (
    <>
      <SortablePhotos
        deletePhoto={deletePhoto}
        items={addedPhotos}
        axis='xy'
        onSortEnd={onSortEnd}
      />
      <Flex
        bg='gray.500'
        w='100%'
        h='150px'
        color='white'
        justifyContent='center'
        alignItems='center'
        rounded='sm'
        _hover={{ cursor: 'pointer' }}
        {...getRootProps()}
      >
        <Flex flexDirection='column' alignItems='center'>
          {uploadLoading ? (
            <Loading />
          ) : isDragActive ? (
            <Text fontWeight='bold'>Bırak</Text>
          ) : (
            <>
              <div>
                <BsCardImage size='36' />
              </div>
              <Text fontWeight='bold' fontSize='xl'>
                Buraya tıklayarak resim ekleyebilir yada sürükleyip
                bırakabilirsiniz
              </Text>
            </>
          )}
        </Flex>

        <input {...getInputProps({ multiple: true })} />
      </Flex>
    </>
  );
};

export default UploadDropzone;
