import {
  FormControl,
  FormLabel,
  Box,
  Text,
  VStack,
  Button,
  Select,
  SimpleGrid,
  Divider,
  Switch,
  FormHelperText,
  Checkbox,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import ContentDescription from '../../components/ContentDescription';
import MessageBox from '../../components/MessageBox';
import {
  PageType,
  useCreatePageMutation,
  useCreatePageWithParentMutation,
  useGetPagesQuery,
} from '../../generated/graphql';

const Add = () => {
  const { push } = useHistory();

  const [isLink, setIsLink] = useState(false);
  const [status, setStatus] = useState(true);
  const [pageType, setPageType] = useState<PageType>(PageType.DEFAULT);
  const [parentPageId, setParentPageId] = useState<string | undefined>(
    undefined
  );
  const [showOnHeader, setShowOnHeader] = useState(true);
  const [showOnFooter, setShowOnFooter] = useState(false);

  const [title, setTitle] = useState('');
  const [language] = useState('tr');
  const [description, setDescription] = useState('');
  const [shortDescription, setShortDescription] = useState('');
  const [buttonText, setButtonText] = useState('');
  const [buttonUrl, setButtonUrl] = useState('');

  // const [images, setImages] = useState([]);

  const [error, setError] = useState('');

  const { data, loading: pagesLoading } = useGetPagesQuery({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });
  const [createPage, { loading }] = useCreatePageMutation();
  const [createPageWithParent, { loading: loadingWithParent }] =
    useCreatePageWithParentMutation();

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      if (!parentPageId) {
        await createPage({
          variables: {
            showOnHeader,
            showOnFooter,
            isLink,
            status,
            pageType,
            title,
            description,
            shortDescription,
            language,
            buttonText,
            buttonUrl,
          },
        });
      } else {
        await createPageWithParent({
          variables: {
            showOnHeader,
            showOnFooter,
            isLink,
            status,
            pageType,
            parentPageId,
            title,
            description,
            shortDescription,
            language,
            buttonText,
            buttonUrl,
          },
        });
      }
      push('/pages');
    } catch (error) {
      console.log('err ⚠️', error.graphQLErrors);
      setError(error.message);
    }
  };

  return (
    <>
      <VStack align='flex-start' mb='4'>
        <Text fontWeight='bold' fontSize='2xl'>
          Yeni Sayfa
        </Text>
      </VStack>
      <form onSubmit={onSubmit}>
        <Box width='full' mb='8'>
          {error && <MessageBox status='warning' message={error} />}
          {/* <FormControl mb='4'>
            <UploadDropzone images={images} setImageArray={setImages} />
          </FormControl> */}
          <SimpleGrid columns={[1, 5]} spacing={[2, 8]}>
            <FormControl>
              <FormLabel>Sayfa Türü</FormLabel>
              <Select
                value={pageType}
                onChange={(e) => setPageType(e.target.value as PageType)}
                disabled
              >
                {Object.keys(PageType).map((type) => (
                  <option value={type}>{type}</option>
                ))}
              </Select>
            </FormControl>

            <FormControl>
              <FormLabel>Üst Sayfa</FormLabel>
              <Select
                value={parentPageId}
                onChange={(e) => setParentPageId(e.target.value)}
              >
                <option value=''>--</option>
                {!pagesLoading &&
                  data.pages.map((page) => (
                    <option value={page.id} key={page.id}>
                      {page.description.length > 1
                        ? page.description.map((desc) => `(${desc.title})`)
                        : page.description.map((desc) => `${desc.title}`)}
                    </option>
                  ))}
              </Select>
              <FormHelperText>Üst sayfası varsa seçiniz.</FormHelperText>
            </FormControl>

            <FormControl>
              <FormLabel>Pozisyon</FormLabel>
              <Box display='flex' flexDirection='column'>
                <Checkbox
                  colorScheme='red'
                  isChecked={showOnHeader}
                  onChange={() => setShowOnHeader((prev) => !prev)}
                >
                  Header
                </Checkbox>
                <Checkbox
                  colorScheme='red'
                  isChecked={showOnFooter}
                  onChange={() => setShowOnFooter((prev) => !prev)}
                >
                  Footer
                </Checkbox>
              </Box>
            </FormControl>
            <FormControl
              alignItems='center'
              display='flex'
              flexDirection='column'
            >
              <FormLabel textAlign='center'>Link?</FormLabel>
              <Switch
                onChange={(e) => setIsLink(e.target.checked)}
                defaultChecked={isLink}
              />
            </FormControl>

            <FormControl
              alignItems='center'
              display='flex'
              flexDirection='column'
            >
              <FormLabel>Aktif?</FormLabel>
              <Switch
                onChange={(e) => setStatus(e.target.checked)}
                defaultChecked={status}
              />
            </FormControl>
          </SimpleGrid>

          <Divider my='6' />
          <ContentDescription
            title={title}
            setTitle={setTitle}
            description={description}
            setDescription={setDescription}
            shortDescription={shortDescription}
            setShortDescription={setShortDescription}
            buttonText={buttonText}
            setButtonText={setButtonText}
            buttonUrl={buttonUrl}
            setButtonUrl={setButtonUrl}
          />

          <Button
            colorScheme='blue'
            type='submit'
            mt='10px'
            isLoading={loading || loadingWithParent}
          >
            Kaydet
          </Button>
        </Box>
      </form>
    </>
  );
};

export default Add;
