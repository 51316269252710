import { useState } from 'react';
import {
  List as ChakraList,
  ListItem,
  Badge,
  IconButton,
  Text,
  chakra,
} from '@chakra-ui/react';
import { MdAdd, MdEdit } from 'react-icons/md';
import { Link } from 'react-router-dom';

import { GetPagesQueryResult } from '../generated/graphql';

interface Props {
  descriptionProp: GetPagesQueryResult['data']['pages'][0]['description'];
  fieldId: string;
  field: string;
  refetch?: any;
}

const DescriptionModal: React.FC<Props> = ({
  descriptionProp,
  field,
  fieldId,
}) => {
  const [title] = useState('');
  const [language] = useState('tr');
  // const [description, setDescription] = useState('');
  const [descriptionId] = useState('');
  const [shortDescription] = useState('');
  const [buttonText] = useState('');
  const [buttonUrl] = useState('');

  return (
    <ChakraList spacing='2'>
      {descriptionProp.map((description) => (
        <ListItem
          key={description.id}
          display='flex'
          justifyContent='space-between'
          borderBottom='1px'
          borderColor='gray.200'
          py={2}
        >
          <chakra.div>
            <Text display='inline' mr={['0', '4']} noOfLines={1}>
              <Badge colorScheme='messenger'>{description.language}</Badge>{' '}
              {description.title}
            </Text>
          </chakra.div>
          <div>
            <IconButton
              size='xs'
              aria-label='edit'
              icon={<MdEdit />}
              border='1px'
              borderColor='gray.400'
              as={Link}
              to={{
                pathname: `/description/update/${description.id}`,
                state: {
                  id: descriptionId,
                  title,
                  description,
                  shortDescription,
                  language,
                  buttonUrl,
                  buttonText,
                  isEdit: true,
                  field,
                  fieldId,
                  descriptionCount: descriptionProp.length,
                },
              }}
            />
          </div>
        </ListItem>
      ))}

      {descriptionProp.length !== 2 && (
        <ListItem display='flex' justifyContent='space-between'>
          <div>Yok</div>
          <IconButton
            size='xs'
            aria-label='add'
            colorScheme='green'
            icon={<MdAdd />}
            as={Link}
            to={{
              pathname: `/description/new/${fieldId}`,
              state: {
                id: descriptionId,
                title,
                // description,
                shortDescription,
                language,
                buttonUrl,
                buttonText,
                isEdit: true,
                field,
                fieldId,
                descriptionCount: descriptionProp.length,
              },
            }}
          />
        </ListItem>
      )}
    </ChakraList>
  );
};

export default DescriptionModal;
