import { useState } from 'react';
import {
  Flex,
  Box,
  FormControl,
  Input,
  Button,
  CircularProgress,
  InputGroup,
  InputRightElement,
  IconButton,
  Text,
  Stack,
  InputLeftElement,
  chakra,
  Image,
} from '@chakra-ui/react';

import { FaEye, FaEyeSlash } from 'react-icons/fa';
import MessageBox from '../components/MessageBox';
import { useLoginMutation } from '../generated/graphql';
import { useUserStore } from '../store';

import { FaUserAlt, FaLock } from 'react-icons/fa';

const CFaUserAlt = chakra(FaUserAlt);
const CFaLock = chakra(FaLock);

export default function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState('');

  const loginUser = useUserStore((store) => store.loginUser);

  const [loginMutation, { loading }] = useLoginMutation();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const res = await loginMutation({ variables: { email, password } });
      loginUser(res.data.login);
      window.location.href = '/dashboard';
    } catch (error) {
      setMessage(error.message);
    }
  };

  return (
    <Flex
      flexDirection='column'
      width='100wh'
      height='100vh'
      backgroundColor='gray.200'
      justifyContent='center'
      alignItems='center'
    >
      <Stack
        flexDir='column'
        mb='2'
        justifyContent='center'
        alignItems='center'
      >
        <Box maxW={{ base: '90%', md: '280px' }}>
          <Image src='logo.png' w='full' />
        </Box>
        <Box minW={{ base: '90%', md: '500px' }}>
          <form onSubmit={handleSubmit}>
            <Stack
              p='1rem'
              backgroundColor='whiteAlpha.900'
              boxShadow='md'
              spacing='4'
            >
              <Text fontSize='2xl' textAlign='center' fontWeight='bold'>
                Kontrol Paneli
              </Text>
              {message && <MessageBox message={message} status='error' />}
              <FormControl isRequired>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents='none'
                    children={<CFaUserAlt color='gray.300' />}
                  />
                  <Input
                    type='text'
                    placeholder='e-posta adresiniz...'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </InputGroup>
              </FormControl>
              <FormControl isRequired mt={6}>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents='none'
                    color='gray.300'
                    children={<CFaLock color='gray.300' />}
                  />
                  <Input
                    type={show ? 'text' : 'password'}
                    placeholder='şifreniz...'
                    value={password}
                    onChange={(e) => setPassword(e.currentTarget.value)}
                  />

                  <InputRightElement>
                    <IconButton
                      size='sm'
                      aria-label='Toggle password'
                      variant='ghost'
                      icon={show ? <FaEyeSlash /> : <FaEye />}
                      onClick={() => setShow(!show)}
                    />
                  </InputRightElement>
                </InputGroup>
              </FormControl>
              <Button
                colorScheme='blue'
                type='submit'
                width='full'
                mt={4}
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress isIndeterminate size='24px' color='teal' />
                ) : (
                  'Giriş yap'
                )}
              </Button>
            </Stack>
          </form>
          <Text textAlign='center' mt='8' mb='2' textColor='gray'>
            © 2021 İnteraktif İş İnternet Hizmetleri
          </Text>
        </Box>
      </Stack>
    </Flex>
  );
}
