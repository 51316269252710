import React from 'react';
import {
  Box,
  Button,
  ButtonGroup,
  Text,
  Heading,
  IconButton,
  useToast,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import arrayMove from 'array-move';
import {
  GetFaqsQueryResult,
  useDeleteFaqMutation,
  useGetFaqsQuery,
  useOrderFaqMutation,
} from '../../generated/graphql';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import DescriptionModal from '../../components/Description';
import MessageBox from '../../components/MessageBox';
import { Link } from 'react-router-dom';
import DeletePopover from '../../components/DeletePopover';
import Loading from '../../components/Loading';
import url from '../../services/url';
import { MdRefresh } from 'react-icons/md';

export default function FaqList() {
  const { data, loading, error, refetch } = useGetFaqsQuery({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });
  const toast = useToast();

  const [deletePage, { loading: deleteLoading }] = useDeleteFaqMutation();

  const deleteHandler = async (id: any) => {
    try {
      const res = await deletePage({ variables: { id } });
      if (res) refetch();
    } catch (error) {
      console.log('catch err ::', error);
      return toast({
        title: 'Hata',
        description: error.message,
        status: 'error',
        duration: 2500,
        isClosable: true,
      });
    }
  };

  const [order] = useOrderFaqMutation();
  const setOrder = (data: GetFaqsQueryResult['data']['faqs']) => {
    data.forEach(async (element, i) => {
      try {
        await order({
          variables: {
            id: element.id,
            order: i + 1,
          },
        });
      } catch (error) {
        return toast({
          title: 'Hata',
          description: error.message,
          status: 'error',
          duration: 2500,
          isClosable: true,
        });
      }
    });
  };

  return (
    <>
      <Heading fontSize='3xl' mb='4'>
        Sıkça Sorulan Sorular
      </Heading>
      <ButtonGroup isAttached width='100%' mb='4'>
        <Button colorScheme='green' as={Link} to={url.FAQCREATE} ml='auto'>
          Yeni
        </Button>
        <IconButton
          aria-label='refresh'
          icon={<MdRefresh />}
          onClick={() => refetch()}
        />
      </ButtonGroup>

      {error && <MessageBox message={error.message} status='error' />}
      {loading ? (
        <Loading />
      ) : data?.faqs.length <= 0 ? (
        <MessageBox
          status='warning'
          message='Henüz hiçbir SSS sorusu oluşturmamışsınız. Yeni bir tane oluşturmak için yeni
          butonunu kullanın.'
        />
      ) : (
        <SortableTable
          items={data.faqs}
          setOrder={setOrder}
          deleteHandler={deleteHandler}
          deleteLoading={deleteLoading}
          refetch={refetch}
        />
      )}
    </>
  );
}

const CardItem = SortableElement(
  ({
    item,
    deleteHandler,
    deleteLoading,
    refetch,
    indexNumber,
  }: {
    item: GetFaqsQueryResult['data']['faqs'][0];
    refetch: any;
    deleteHandler: (id: any) => Promise<string | number>;
    deleteLoading: boolean;
    indexNumber: number;
  }) => {
    return (
      <Box
        maxW='sm'
        width='md'
        borderWidth='1px'
        borderRadius='md'
        overflow='hidden'
        display='flex'
        flexDir='column'
        mr='2'
        cursor='grab'
        h='full'
      >
        <Box m='5'>
          <Text fontWeight='bold'>Soru #{indexNumber + 1}</Text>
          <DescriptionModal
            descriptionProp={item.description}
            fieldId={item.id}
            field='faq'
            refetch={refetch}
          />
        </Box>
        <Box m='5' mb='2' mt='auto' ml='auto'>
          <DeletePopover
            deleteHandler={deleteHandler}
            pageId={item.id}
            isLoading={deleteLoading}
            buttonProps={{ size: 'sm' }}
          />
        </Box>
      </Box>
    );
  }
);

const SortableList = SortableContainer(
  ({
    items,
    deleteHandler,
    deleteLoading,
    refetch,
  }: {
    items: GetFaqsQueryResult['data']['faqs'];
    refetch: any;
    deleteHandler: (id: any) => Promise<string | number>;
    deleteLoading: boolean;
  }) => (
    <Wrap flexDir={['column', 'row']}>
      {items.map((address, index) => (
        <WrapItem key={address.id}>
          <CardItem
            item={address}
            index={index}
            indexNumber={index}
            deleteHandler={deleteHandler}
            deleteLoading={deleteLoading}
            refetch={refetch}
          />
        </WrapItem>
      ))}
    </Wrap>
  )
);

class SortableTable extends React.Component<
  {
    items: GetFaqsQueryResult['data']['faqs'];
    setOrder: (data: any) => void;
    refetch: any;
    deleteHandler: (id: any) => Promise<string | number>;
    deleteLoading: boolean;
  },
  { setOrder: any; items: any }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      items: this.props.items,
      setOrder: this.props.setOrder,
    };
  }

  onSortEnd = ({ oldIndex, newIndex }: { oldIndex: any; newIndex: any }) => {
    if (oldIndex === newIndex) return;
    this.setState(({ items }) => {
      const newOrder = arrayMove(items, oldIndex, newIndex);
      this.state.setOrder(newOrder);
      return {
        items: newOrder,
      };
    });
  };

  render() {
    return (
      <SortableList
        axis='xy'
        items={this.state.items}
        onSortEnd={this.onSortEnd}
        refetch={this.props.refetch}
        deleteHandler={this.props.deleteHandler}
        deleteLoading={this.props.deleteLoading}
      />
    );
  }
}
