import {
  Input,
  FormControl,
  FormLabel,
  Box,
  Button,
  SimpleGrid,
  Select,
  FormHelperText,
  Image,
  IconButton,
  Checkbox,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { BsTrash } from 'react-icons/bs';
import { useHistory } from 'react-router-dom';
import CloudinaryImage from '../../components/CloudinaryImage';
import MessageBox from '../../components/MessageBox';

import {
  useUpdateBlogPostMutation,
  useUpdateBlogPostWithoutThumbnailMutation,
  useGetBlogCategoriesQuery,
  useUploadFileMutation,
  GetBlogPostQueryResult,
} from '../../generated/graphql';
import { useUserStore } from '../../store';

interface Props {
  data: GetBlogPostQueryResult['data']['findFirstBlogPost'];
}

const EditForm: React.FC<Props> = ({ data: blogPost }) => {
  const { push } = useHistory();
  const { id } = useUserStore((store) => store.user);

  const [categoryId, setCategoryId] = useState(
    blogPost.blogCategoryId ? blogPost.blogCategoryId : ''
  );
  const [logo, setLogo] = useState<any>(
    blogPost.thumbnail ? blogPost.thumbnail : ''
  );
  const [logoPreview, setLogoPreview] = useState('');
  const [publishedAt, setPublishedAt] = useState(
    blogPost.publishedAt ? blogPost.publishedAt : ''
  );
  const [isHidden, setIsHidden] = useState(blogPost.isHidden && true);

  const [error, setError] = useState('');

  const [updateBlogPost, { loading }] = useUpdateBlogPostMutation();
  const [updateBlogPostWithoutThumbnail, { loading: loadingWithoutThumbnail }] =
    useUpdateBlogPostWithoutThumbnailMutation();
  const { data } = useGetBlogCategoriesQuery();
  const [uploadPhoto, { loading: uploadLoading }] = useUploadFileMutation();

  const handleImg = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setLogo(e.target.files[0]);
      setLogoPreview(URL.createObjectURL(e.target.files[0]));
    }
  };

  const [dataLogoPreview, setDataLogoPreview] = useState('');
  useEffect(() => {
    setDataLogoPreview(blogPost.thumbnail);
  }, [blogPost.thumbnail]);

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      if (logo && typeof logo === 'object') {
        const uploadResponse = await uploadPhoto({
          variables: { file: logo },
        });
        await updateBlogPost({
          variables: {
            id: blogPost.id,
            authorId: id,
            thumbnail: (uploadResponse.data.uploadFile as any).file,
            categoryId,
            publishedAt,
            isHidden,
          },
        });
      } else {
        await updateBlogPostWithoutThumbnail({
          variables: {
            id: blogPost.id,
            authorId: id,
            categoryId,
            publishedAt,
            isHidden,
          },
        });
      }

      setError('');
      push('/blogpost');
    } catch (error) {
      console.log('err ⚠️', error);
      setError(error.message);
    }
  };

  return (
    <form onSubmit={onSubmit}>
      <Box>
        {error && <MessageBox status='warning' message={error} />}
        <SimpleGrid columns={[1, 3]} spacing={[2, 8]}>
          <FormControl isRequired>
            <FormLabel>Kategori</FormLabel>
            <Select
              value={categoryId}
              onChange={(e) => setCategoryId(e.target.value)}
            >
              <option value=''>--</option>
              {data &&
                data.blogCategories.map((category) => (
                  <option value={category.id} key={category.id}>
                    {category.description.length > 1
                      ? category.description.map((desc) => `(${desc.title})`)
                      : category.description.map((desc) => `${desc.title}`)}
                  </option>
                ))}
            </Select>
            <FormHelperText>Yazı kategorisi</FormHelperText>
          </FormControl>
          <FormControl display='flex'>
            <Box mr='8'>
              <FormControl>
                <FormLabel>Kapak resmi (opsiyonel)</FormLabel>
                <Input type='file' multiple={false} onChange={handleImg} />
                <FormHelperText>
                  Eğer isterseniz bu yazıya kapak resmi ekleyebilirsiniz
                </FormHelperText>
              </FormControl>
            </Box>
            <Box boxSize='150px' position='relative'>
              {dataLogoPreview && !logoPreview ? (
                <>
                  <IconButton
                    position='absolute'
                    bottom='0'
                    right='0'
                    colorScheme='yellow'
                    aria-label='delete image'
                    icon={<BsTrash size='24' />}
                    onClick={() => {
                      setLogo('');
                      setDataLogoPreview('');
                    }}
                  />
                  <CloudinaryImage publicId={dataLogoPreview} />
                </>
              ) : (
                <>
                  {logoPreview && (
                    <IconButton
                      position='absolute'
                      bottom='0'
                      right='0'
                      colorScheme='yellow'
                      aria-label='delete image'
                      icon={<BsTrash size='24' />}
                      onClick={() => {
                        setLogo('');
                        setLogoPreview('');
                        setDataLogoPreview('');
                      }}
                    />
                  )}
                  {logoPreview && (
                    <Image src={logoPreview} rounded='sm' objectFit='cover' />
                  )}
                </>
              )}
            </Box>
          </FormControl>

          <FormControl isRequired>
            <FormLabel>Yayımlanma Tarihi</FormLabel>
            <Input
              type='date'
              value={publishedAt}
              onChange={(e) => setPublishedAt(e.target.value)}
            />
          </FormControl>
        </SimpleGrid>

        <Box w='50%'>
          <FormControl ml='auto'>
            <FormLabel>Durum</FormLabel>
            <Checkbox
              colorScheme='red'
              isChecked={isHidden}
              onChange={() => setIsHidden((prev) => !prev)}
            >
              Gizle
            </Checkbox>
            <FormHelperText>
              Blog yazısı gizli olarak işaretlendiğinde bu yazı
              listelenmeyecektir.
            </FormHelperText>
          </FormControl>
        </Box>

        <Button
          colorScheme='blue'
          type='submit'
          mt='10px'
          isLoading={loading || uploadLoading || loadingWithoutThumbnail}
        >
          Kaydet
        </Button>
      </Box>
    </form>
  );
};

export default EditForm;
