import { Text, VStack } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import Loading from '../../components/Loading';
import MessageBox from '../../components/MessageBox';
import { useGetFormioFormQuery } from '../../generated/graphql';
import EditForm from './EditForm';

const Edit = () => {
  const { id } = useParams<{ id: string }>();

  const { data, loading, error } = useGetFormioFormQuery({
    fetchPolicy: 'network-only',
    variables: { id },
  });

  if (loading || !data) {
    return <Loading />;
  }

  return (
    <>
      <VStack align='flex-start' mb='4'>
        <Text fontWeight='bold' fontSize='2xl'>
          Form Düzenle
        </Text>
      </VStack>
      {error ? (
        <MessageBox status='error' message={error.message} />
      ) : (
        <EditForm data={data.formioForm} />
      )}
    </>
  );
};

export default Edit;
