import {
  Input,
  FormControl,
  FormLabel,
  Box,
  Text,
  VStack,
  Button,
  FormHelperText,
  Flex,
  Image,
  IconButton,
  Divider,
} from '@chakra-ui/react';
import { useState } from 'react';
import { BsTrash } from 'react-icons/bs';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import ContentDescription from '../../components/ContentDescription';
import MessageBox from '../../components/MessageBox';

import {
  useCreateBrandMutation,
  useCreateBrandWithoutPhotoMutation,
  useGetCountriesQuery,
  useUploadFileMutation,
} from '../../generated/graphql';

const Add = () => {
  const { push } = useHistory();

  const [title, setTitle] = useState('');

  const [logo, setLogo] = useState<any>('');
  const [logoPreview, setLogoPreview] = useState('');
  const [countries, setCountries] = useState<any[]>([]);

  // description inputs
  const [language] = useState('tr');
  const [description, setDescription] = useState('');
  const [shortDescription, setShortDescription] = useState('');
  const [buttonText, setButtonText] = useState('');
  const [buttonUrl, setButtonUrl] = useState('');

  const [error, setError] = useState('');

  const [uploadPhoto, { loading: uploadLoading }] = useUploadFileMutation();
  const [createBrand, { loading }] = useCreateBrandMutation();
  const [createBrandWithoutPhoto, { loading: loadingWithoutParent }] =
    useCreateBrandWithoutPhotoMutation();
  const { data: countriesData, loading: getCountriesLoading } =
    useGetCountriesQuery();

  const handleImg = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setLogo(e.target.files[0]);
      setLogoPreview(URL.createObjectURL(e.target.files[0]));
    }
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      if (logo) {
        const uploadResponse = await uploadPhoto({
          variables: { file: logo },
        });
        await createBrand({
          variables: {
            title,
            photo: (uploadResponse.data.uploadFile as any).file,
            countries,

            description,
            shortDescription,
            language,
            buttonText,
            buttonUrl,
          },
        });
      } else {
        await createBrandWithoutPhoto({
          variables: {
            title,
            countries,
            description,
            shortDescription,
            language,
            buttonText,
            buttonUrl,
          },
        });
      }
      setError('');
      push(`/brands`);
    } catch (error) {
      console.log('err ⚠️', error);
      setError(error.message);
    }
  };

  return (
    <>
      <VStack align='flex-start' mb='4'>
        <Text fontWeight='bold' fontSize='2xl'>
          Yeni Marka
        </Text>
      </VStack>

      <form onSubmit={onSubmit}>
        {error && <MessageBox status='warning' message={error} />}
        <FormControl isRequired mb='4'>
          <FormLabel>Marka Adı</FormLabel>
          <Input
            type='text'
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </FormControl>
        <FormControl mb='4'>
          <FormLabel>Satıldığı Ülkeler</FormLabel>
          <Select
            placeholder='Ülkeler'
            isMulti
            isDisabled={getCountriesLoading}
            isLoading={getCountriesLoading}
            options={
              countriesData &&
              countriesData.countries.map((country) => ({
                value: country.id,
                label: country.title,
              }))
            }
            onChange={(e) =>
              setCountries(e.map((item) => ({ id: item.value })))
            }
          />
        </FormControl>
        <Flex>
          <Box mr='8'>
            <FormControl>
              <FormLabel>Logo (opsiyonel)</FormLabel>
              <Input type='file' multiple={false} onChange={handleImg} />
              <FormHelperText>
                İsterseniz markaya bir görsel yükleyebilirsiniz.
              </FormHelperText>
            </FormControl>
          </Box>
          <Box boxSize='150px' position='relative'>
            {logoPreview && (
              <>
                <IconButton
                  position='absolute'
                  bottom='0'
                  right='0'
                  colorScheme='yellow'
                  aria-label='delete image'
                  icon={<BsTrash size='24' />}
                  onClick={() => {
                    setLogo('');
                    setLogoPreview('');
                  }}
                />
                <Image
                  src={logoPreview}
                  rounded='sm'
                  objectFit='cover'
                  boxSize='150px'
                />
              </>
            )}
          </Box>
        </Flex>

        <Divider my='6' />
        <ContentDescription
          title={title}
          setTitle={setTitle}
          description={description}
          setDescription={setDescription}
          shortDescription={shortDescription}
          setShortDescription={setShortDescription}
          buttonText={buttonText}
          setButtonText={setButtonText}
          buttonUrl={buttonUrl}
          setButtonUrl={setButtonUrl}
          titleDisabled={true}
        />

        <Button
          colorScheme='blue'
          type='submit'
          mt='10px'
          isLoading={loading || loadingWithoutParent || uploadLoading}
        >
          Kaydet
        </Button>
      </form>
    </>
  );
};

export default Add;
