import { request } from 'graphql-request';
import { CHECK_REFRESH_TOKEN } from '../gql/user/query';

export async function setAccessToken(): Promise<any> {
  const refreshToken = localStorage.getItem('refreshToken');
  if (!refreshToken) return '';
  try {
    const response = await request(
      process.env.REACT_APP_API,
      CHECK_REFRESH_TOKEN,
      { refreshToken }
    );

    if (response.checkRefreshToken) {
      return response.checkRefreshToken.accessToken;
    }
  } catch (error) {
    // console.log('setAccessToken func err:: ', error.message);
    // return null;
  }
}
