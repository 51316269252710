import {
  Input,
  FormControl,
  FormLabel,
  Box,
  Button,
  Select,
  SimpleGrid,
  GridItem,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import MessageBox from '../../components/MessageBox';

import {
  GetUserQueryResult,
  UserRole,
  UserStatusType,
  useUpdateUserMutation,
} from '../../generated/graphql';

interface Props {
  data: GetUserQueryResult['data']['user'];
}

const EditForm: React.FC<Props> = ({ data }) => {
  const { push } = useHistory();

  const [email, setEmail] = useState(data.email ? data.email : '');
  const [name, setName] = useState(data.name ? data.name : '');
  const [surname, setSurname] = useState(data.surname ? data.surname : '');
  const [password, setPassword] = useState('');
  const [status, setStatus] = useState<UserStatusType>(data.status);
  const [role, setRole] = useState<UserRole>(data.role);
  const [identityNumber, setIdentityNumber] = useState(
    data.identityNumber ? data.identityNumber : ''
  );
  const [phoneNumber, setPhoneNumber] = useState(
    data.phoneNumber ? data.phoneNumber : ''
  );

  const [error, setError] = useState('');

  const [updateUser, { loading }] = useUpdateUserMutation();

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await updateUser({
        variables: {
          where: { id: data.id },
          data: {
            email: { set: email },
            name: { set: name },
            surname: { set: surname },
            password: { set: password },
            status: { set: status },
            role: { set: role },
            identityNumber: { set: identityNumber },
            phoneNumber: { set: phoneNumber },
          },
        },
      });

      push('/users');
    } catch (error) {
      console.log('err ⚠️', error.graphQLErrors);
      setError(error.message);
    }
  };

  return (
    <>
      <form onSubmit={onSubmit}>
        <Box width='full' mb='8'>
          {error && <MessageBox status='warning' message={error} />}

          <SimpleGrid columns={[1, 3]} spacing={[2, 8]}>
            <GridItem>
              <FormControl isRequired>
                <FormLabel>Ad</FormLabel>
                <Input
                  type='text'
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </FormControl>
            </GridItem>

            <GridItem>
              <FormControl isRequired>
                <FormLabel>Soyad</FormLabel>
                <Input
                  type='text'
                  value={surname}
                  onChange={(e) => setSurname(e.target.value)}
                />
              </FormControl>
            </GridItem>

            <GridItem>
              <FormControl isRequired>
                <FormLabel>Email</FormLabel>
                <Input
                  type='text'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </FormControl>
            </GridItem>

            <GridItem>
              <FormControl>
                <FormLabel>Şifre</FormLabel>
                <Input
                  type='password'
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </FormControl>
            </GridItem>

            <GridItem>
              <FormControl>
                <FormLabel>Durum</FormLabel>
                <Select
                  value={status}
                  onChange={(e) => setStatus(e.target.value as UserStatusType)}
                >
                  {Object.keys(UserStatusType).map((type) => (
                    <option value={type}>{type}</option>
                  ))}
                </Select>
              </FormControl>
            </GridItem>

            <GridItem>
              <FormControl>
                <FormLabel>Role</FormLabel>
                <Select
                  value={role}
                  onChange={(e) => setRole(e.target.value as UserRole)}
                >
                  {Object.keys(UserRole).map((type) => (
                    <option value={type}>{type}</option>
                  ))}
                </Select>
              </FormControl>
            </GridItem>

            <GridItem>
              <FormControl>
                <FormLabel>Kimlik Numarası</FormLabel>
                <Input
                  type='text'
                  value={identityNumber}
                  onChange={(e) => setIdentityNumber(e.target.value)}
                />
              </FormControl>
            </GridItem>

            <GridItem>
              <FormControl>
                <FormLabel>Telefon Numarası</FormLabel>
                <Input
                  type='text'
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </FormControl>
            </GridItem>
          </SimpleGrid>

          <Button
            colorScheme='blue'
            type='submit'
            mt='10px'
            isLoading={loading}
          >
            Kaydet
          </Button>
        </Box>
      </form>
    </>
  );
};

export default EditForm;
