import { Button, SimpleGrid } from '@chakra-ui/react';
import { EmojiData } from 'emoji-mart';
import { nanoid } from 'nanoid';
import { CardField } from '../types';

import EmojiInput from './EmojiInput';

interface Props {
  cardFields: CardField[];
  setCardFields: React.Dispatch<React.SetStateAction<CardField[]>>;
}

const EmojiCard: React.FC<Props> = ({ cardFields, setCardFields }) => {
  const addCardField = () => {
    setCardFields((prev) => [
      ...prev,
      {
        id: nanoid(),
        icon: '',
        title: '',
        description: '',
        url: '',
        image: '',
      },
    ]);
  };

  const removeCardField = (id) => {
    const cards = [...cardFields];
    cards.splice(
      cards.findIndex((value) => value.id === id),
      1
    );
    setCardFields(cards);
  };

  const handleCardInputChange = (id, event) => {
    let copied = JSON.parse(JSON.stringify(cardFields));
    const changedInputs = copied.map((input) => {
      if (id === input.id) {
        input[event.target.name] = event.target.value;
      }
      return input;
    });
    setCardFields(changedInputs);
  };

  const handleEmojiSelect = (id, e: EmojiData) => {
    let copied = JSON.parse(JSON.stringify(cardFields));
    const changedInputs = copied.map((input) => {
      if (id === input.id) {
        input['icon'] = e.colons;
      }
      return input;
    });
    setCardFields(changedInputs);
  };

  return (
    <>
      <Button onClick={addCardField} mb='4'>
        Kart ekle
      </Button>
      <SimpleGrid columns={[1, 4]} spacing='4'>
        {cardFields.map((card, i) => (
          <EmojiInput
            key={i}
            card={card}
            removeCardField={removeCardField}
            cardFields={cardFields}
            setCardFields={setCardFields}
            handleEmojiSelect={handleEmojiSelect}
            handleCardInputChange={handleCardInputChange}
          />
        ))}
      </SimpleGrid>
    </>
  );
};

export default EmojiCard;
