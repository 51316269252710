import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type Address = {
  __typename?: 'Address';
  id: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  title: Scalars['String'];
  postalCode: Scalars['String'];
  addressLineOne: Scalars['String'];
  addressLineTwo?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['String']>;
  contactId: Scalars['String'];
  order: Scalars['Int'];
  user?: Maybe<User>;
  contact: Contact;
};

export type AddressCreateManyContactInput = {
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  postalCode: Scalars['String'];
  addressLineOne: Scalars['String'];
  addressLineTwo?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

export type AddressCreateManyContactInputEnvelope = {
  data: Array<AddressCreateManyContactInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AddressCreateManyUserInput = {
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  postalCode: Scalars['String'];
  addressLineOne: Scalars['String'];
  addressLineTwo?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  contactId: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
};

export type AddressCreateManyUserInputEnvelope = {
  data: Array<AddressCreateManyUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AddressCreateNestedManyWithoutContactInput = {
  create?: Maybe<Array<AddressCreateWithoutContactInput>>;
  connectOrCreate?: Maybe<Array<AddressCreateOrConnectWithoutContactInput>>;
  createMany?: Maybe<AddressCreateManyContactInputEnvelope>;
  connect?: Maybe<Array<AddressWhereUniqueInput>>;
};

export type AddressCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<AddressCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<AddressCreateOrConnectWithoutUserInput>>;
  createMany?: Maybe<AddressCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<AddressWhereUniqueInput>>;
};

export type AddressCreateOrConnectWithoutContactInput = {
  where: AddressWhereUniqueInput;
  create: AddressCreateWithoutContactInput;
};

export type AddressCreateOrConnectWithoutUserInput = {
  where: AddressWhereUniqueInput;
  create: AddressCreateWithoutUserInput;
};

export type AddressCreateWithoutContactInput = {
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  postalCode: Scalars['String'];
  addressLineOne: Scalars['String'];
  addressLineTwo?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Int']>;
  user?: Maybe<UserCreateNestedOneWithoutAddressesInput>;
};

export type AddressCreateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  postalCode: Scalars['String'];
  addressLineOne: Scalars['String'];
  addressLineTwo?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Int']>;
  contact: ContactCreateNestedOneWithoutAddressInput;
};

export type AddressListRelationFilter = {
  every?: Maybe<AddressWhereInput>;
  some?: Maybe<AddressWhereInput>;
  none?: Maybe<AddressWhereInput>;
};

export type AddressOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type AddressOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  postalCode?: Maybe<SortOrder>;
  addressLineOne?: Maybe<SortOrder>;
  addressLineTwo?: Maybe<SortOrder>;
  longitude?: Maybe<SortOrder>;
  latitude?: Maybe<SortOrder>;
  user?: Maybe<UserOrderByWithRelationInput>;
  userId?: Maybe<SortOrder>;
  contact?: Maybe<ContactOrderByWithRelationInput>;
  contactId?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
};

export enum AddressScalarFieldEnum {
  id = 'id',
  updatedAt = 'updatedAt',
  createdAt = 'createdAt',
  title = 'title',
  postalCode = 'postalCode',
  addressLineOne = 'addressLineOne',
  addressLineTwo = 'addressLineTwo',
  longitude = 'longitude',
  latitude = 'latitude',
  userId = 'userId',
  contactId = 'contactId',
  order = 'order'
}

export type AddressScalarWhereInput = {
  AND?: Maybe<Array<AddressScalarWhereInput>>;
  OR?: Maybe<Array<AddressScalarWhereInput>>;
  NOT?: Maybe<Array<AddressScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  title?: Maybe<StringFilter>;
  postalCode?: Maybe<StringFilter>;
  addressLineOne?: Maybe<StringFilter>;
  addressLineTwo?: Maybe<StringNullableFilter>;
  longitude?: Maybe<FloatNullableFilter>;
  latitude?: Maybe<FloatNullableFilter>;
  userId?: Maybe<StringNullableFilter>;
  contactId?: Maybe<StringFilter>;
  order?: Maybe<IntFilter>;
};

export type AddressUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  title?: Maybe<StringFieldUpdateOperationsInput>;
  postalCode?: Maybe<StringFieldUpdateOperationsInput>;
  addressLineOne?: Maybe<StringFieldUpdateOperationsInput>;
  addressLineTwo?: Maybe<NullableStringFieldUpdateOperationsInput>;
  longitude?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  latitude?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneWithoutAddressesInput>;
  contact?: Maybe<ContactUpdateOneRequiredWithoutAddressInput>;
};

export type AddressUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  title?: Maybe<StringFieldUpdateOperationsInput>;
  postalCode?: Maybe<StringFieldUpdateOperationsInput>;
  addressLineOne?: Maybe<StringFieldUpdateOperationsInput>;
  addressLineTwo?: Maybe<NullableStringFieldUpdateOperationsInput>;
  longitude?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  latitude?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
};

export type AddressUpdateManyWithWhereWithoutContactInput = {
  where: AddressScalarWhereInput;
  data: AddressUpdateManyMutationInput;
};

export type AddressUpdateManyWithWhereWithoutUserInput = {
  where: AddressScalarWhereInput;
  data: AddressUpdateManyMutationInput;
};

export type AddressUpdateManyWithoutContactInput = {
  create?: Maybe<Array<AddressCreateWithoutContactInput>>;
  connectOrCreate?: Maybe<Array<AddressCreateOrConnectWithoutContactInput>>;
  upsert?: Maybe<Array<AddressUpsertWithWhereUniqueWithoutContactInput>>;
  createMany?: Maybe<AddressCreateManyContactInputEnvelope>;
  connect?: Maybe<Array<AddressWhereUniqueInput>>;
  set?: Maybe<Array<AddressWhereUniqueInput>>;
  disconnect?: Maybe<Array<AddressWhereUniqueInput>>;
  delete?: Maybe<Array<AddressWhereUniqueInput>>;
  update?: Maybe<Array<AddressUpdateWithWhereUniqueWithoutContactInput>>;
  updateMany?: Maybe<Array<AddressUpdateManyWithWhereWithoutContactInput>>;
  deleteMany?: Maybe<Array<AddressScalarWhereInput>>;
};

export type AddressUpdateManyWithoutUserInput = {
  create?: Maybe<Array<AddressCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<AddressCreateOrConnectWithoutUserInput>>;
  upsert?: Maybe<Array<AddressUpsertWithWhereUniqueWithoutUserInput>>;
  createMany?: Maybe<AddressCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<AddressWhereUniqueInput>>;
  set?: Maybe<Array<AddressWhereUniqueInput>>;
  disconnect?: Maybe<Array<AddressWhereUniqueInput>>;
  delete?: Maybe<Array<AddressWhereUniqueInput>>;
  update?: Maybe<Array<AddressUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<AddressUpdateManyWithWhereWithoutUserInput>>;
  deleteMany?: Maybe<Array<AddressScalarWhereInput>>;
};

export type AddressUpdateWithWhereUniqueWithoutContactInput = {
  where: AddressWhereUniqueInput;
  data: AddressUpdateWithoutContactInput;
};

export type AddressUpdateWithWhereUniqueWithoutUserInput = {
  where: AddressWhereUniqueInput;
  data: AddressUpdateWithoutUserInput;
};

export type AddressUpdateWithoutContactInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  title?: Maybe<StringFieldUpdateOperationsInput>;
  postalCode?: Maybe<StringFieldUpdateOperationsInput>;
  addressLineOne?: Maybe<StringFieldUpdateOperationsInput>;
  addressLineTwo?: Maybe<NullableStringFieldUpdateOperationsInput>;
  longitude?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  latitude?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneWithoutAddressesInput>;
};

export type AddressUpdateWithoutUserInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  title?: Maybe<StringFieldUpdateOperationsInput>;
  postalCode?: Maybe<StringFieldUpdateOperationsInput>;
  addressLineOne?: Maybe<StringFieldUpdateOperationsInput>;
  addressLineTwo?: Maybe<NullableStringFieldUpdateOperationsInput>;
  longitude?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  latitude?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  contact?: Maybe<ContactUpdateOneRequiredWithoutAddressInput>;
};

export type AddressUpsertWithWhereUniqueWithoutContactInput = {
  where: AddressWhereUniqueInput;
  update: AddressUpdateWithoutContactInput;
  create: AddressCreateWithoutContactInput;
};

export type AddressUpsertWithWhereUniqueWithoutUserInput = {
  where: AddressWhereUniqueInput;
  update: AddressUpdateWithoutUserInput;
  create: AddressCreateWithoutUserInput;
};

export type AddressWhereInput = {
  AND?: Maybe<Array<AddressWhereInput>>;
  OR?: Maybe<Array<AddressWhereInput>>;
  NOT?: Maybe<Array<AddressWhereInput>>;
  id?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  title?: Maybe<StringFilter>;
  postalCode?: Maybe<StringFilter>;
  addressLineOne?: Maybe<StringFilter>;
  addressLineTwo?: Maybe<StringNullableFilter>;
  longitude?: Maybe<FloatNullableFilter>;
  latitude?: Maybe<FloatNullableFilter>;
  user?: Maybe<UserRelationFilter>;
  userId?: Maybe<StringNullableFilter>;
  contact?: Maybe<ContactRelationFilter>;
  contactId?: Maybe<StringFilter>;
  order?: Maybe<IntFilter>;
};

export type AddressWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type AffectedRowsOutput = {
  __typename?: 'AffectedRowsOutput';
  count: Scalars['Int'];
};

export type AggregateCustomData = {
  __typename?: 'AggregateCustomData';
  _count?: Maybe<CustomDataCountAggregate>;
  _avg?: Maybe<CustomDataAvgAggregate>;
  _sum?: Maybe<CustomDataSumAggregate>;
  _min?: Maybe<CustomDataMinAggregate>;
  _max?: Maybe<CustomDataMaxAggregate>;
};

export type AggregateFormioForm = {
  __typename?: 'AggregateFormioForm';
  _count?: Maybe<FormioFormCountAggregate>;
  _avg?: Maybe<FormioFormAvgAggregate>;
  _sum?: Maybe<FormioFormSumAggregate>;
  _min?: Maybe<FormioFormMinAggregate>;
  _max?: Maybe<FormioFormMaxAggregate>;
};

export type AggregateReaderComment = {
  __typename?: 'AggregateReaderComment';
  _count?: Maybe<ReaderCommentCountAggregate>;
  _avg?: Maybe<ReaderCommentAvgAggregate>;
  _sum?: Maybe<ReaderCommentSumAggregate>;
  _min?: Maybe<ReaderCommentMinAggregate>;
  _max?: Maybe<ReaderCommentMaxAggregate>;
};

export type AggregateYtVideo = {
  __typename?: 'AggregateYtVideo';
  _count?: Maybe<YtVideoCountAggregate>;
  _avg?: Maybe<YtVideoAvgAggregate>;
  _sum?: Maybe<YtVideoSumAggregate>;
  _min?: Maybe<YtVideoMinAggregate>;
  _max?: Maybe<YtVideoMaxAggregate>;
};

export enum AmountSeparator {
  DOTCOMMA = 'DOTCOMMA',
  COMMADOT = 'COMMADOT'
}

export type AppSettings = {
  __typename?: 'AppSettings';
  id: Scalars['Int'];
  appName: Scalars['String'];
  domain: Scalars['String'];
  language: Scalars['String'];
  logoFile: Scalars['String'];
  timeZone: Scalars['String'];
  dateFormat: Scalars['String'];
  timeFormat: Scalars['String'];
};

export type AppSettingsUpdateInput = {
  id?: Maybe<IntFieldUpdateOperationsInput>;
  appName?: Maybe<StringFieldUpdateOperationsInput>;
  domain?: Maybe<StringFieldUpdateOperationsInput>;
  language?: Maybe<StringFieldUpdateOperationsInput>;
  logoFile?: Maybe<StringFieldUpdateOperationsInput>;
  timeZone?: Maybe<StringFieldUpdateOperationsInput>;
  dateFormat?: Maybe<StringFieldUpdateOperationsInput>;
  timeFormat?: Maybe<StringFieldUpdateOperationsInput>;
};

export type AuthLoginInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type AuthPayloadModel = {
  __typename?: 'AuthPayloadModel';
  /** JWT access token */
  accessToken: Scalars['String'];
  /** JWT refresh token */
  refreshToken?: Maybe<Scalars['String']>;
  /** UserId */
  id: Scalars['String'];
};

export type BlogCategory = {
  __typename?: 'BlogCategory';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  thumbnail?: Maybe<Scalars['String']>;
  order: Scalars['Int'];
  hidden?: Maybe<Scalars['Boolean']>;
  description: Array<Description>;
  blogposts: Array<BlogPost>;
  content: Array<Content>;
};


export type BlogCategoryDescriptionArgs = {
  where?: Maybe<DescriptionWhereInput>;
  orderBy?: Maybe<Array<DescriptionOrderByWithRelationInput>>;
  cursor?: Maybe<DescriptionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<DescriptionScalarFieldEnum>>;
};


export type BlogCategoryBlogpostsArgs = {
  where?: Maybe<BlogPostWhereInput>;
  orderBy?: Maybe<Array<BlogPostOrderByWithRelationInput>>;
  cursor?: Maybe<BlogPostWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<BlogPostScalarFieldEnum>>;
};


export type BlogCategoryContentArgs = {
  where?: Maybe<ContentWhereInput>;
  orderBy?: Maybe<Array<ContentOrderByWithRelationInput>>;
  cursor?: Maybe<ContentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<ContentScalarFieldEnum>>;
};

export type BlogCategoryCreateNestedOneWithoutBlogpostsInput = {
  create?: Maybe<BlogCategoryCreateWithoutBlogpostsInput>;
  connectOrCreate?: Maybe<BlogCategoryCreateOrConnectWithoutBlogpostsInput>;
  connect?: Maybe<BlogCategoryWhereUniqueInput>;
};

export type BlogCategoryCreateNestedOneWithoutContentInput = {
  create?: Maybe<BlogCategoryCreateWithoutContentInput>;
  connectOrCreate?: Maybe<BlogCategoryCreateOrConnectWithoutContentInput>;
  connect?: Maybe<BlogCategoryWhereUniqueInput>;
};

export type BlogCategoryCreateNestedOneWithoutDescriptionInput = {
  create?: Maybe<BlogCategoryCreateWithoutDescriptionInput>;
  connectOrCreate?: Maybe<BlogCategoryCreateOrConnectWithoutDescriptionInput>;
  connect?: Maybe<BlogCategoryWhereUniqueInput>;
};

export type BlogCategoryCreateOrConnectWithoutBlogpostsInput = {
  where: BlogCategoryWhereUniqueInput;
  create: BlogCategoryCreateWithoutBlogpostsInput;
};

export type BlogCategoryCreateOrConnectWithoutContentInput = {
  where: BlogCategoryWhereUniqueInput;
  create: BlogCategoryCreateWithoutContentInput;
};

export type BlogCategoryCreateOrConnectWithoutDescriptionInput = {
  where: BlogCategoryWhereUniqueInput;
  create: BlogCategoryCreateWithoutDescriptionInput;
};

export type BlogCategoryCreateWithoutBlogpostsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  thumbnail?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  hidden?: Maybe<Scalars['Boolean']>;
  description?: Maybe<DescriptionCreateNestedManyWithoutBlogCategoryInput>;
  content?: Maybe<ContentCreateNestedManyWithoutBlogCategoryInput>;
};

export type BlogCategoryCreateWithoutContentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  thumbnail?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  hidden?: Maybe<Scalars['Boolean']>;
  description?: Maybe<DescriptionCreateNestedManyWithoutBlogCategoryInput>;
  blogposts?: Maybe<BlogPostCreateNestedManyWithoutCategoryInput>;
};

export type BlogCategoryCreateWithoutDescriptionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  thumbnail?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  hidden?: Maybe<Scalars['Boolean']>;
  blogposts?: Maybe<BlogPostCreateNestedManyWithoutCategoryInput>;
  content?: Maybe<ContentCreateNestedManyWithoutBlogCategoryInput>;
};

export type BlogCategoryOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  thumbnail?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  hidden?: Maybe<SortOrder>;
  description?: Maybe<DescriptionOrderByRelationAggregateInput>;
  blogposts?: Maybe<BlogPostOrderByRelationAggregateInput>;
  content?: Maybe<ContentOrderByRelationAggregateInput>;
};

export type BlogCategoryRelationFilter = {
  is?: Maybe<BlogCategoryWhereInput>;
  isNot?: Maybe<BlogCategoryWhereInput>;
};

export enum BlogCategoryScalarFieldEnum {
  id = 'id',
  createdAt = 'createdAt',
  updatedAt = 'updatedAt',
  thumbnail = 'thumbnail',
  order = 'order',
  hidden = 'hidden'
}

export type BlogCategoryUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  thumbnail?: Maybe<NullableStringFieldUpdateOperationsInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  hidden?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  description?: Maybe<DescriptionUpdateManyWithoutBlogCategoryInput>;
  blogposts?: Maybe<BlogPostUpdateManyWithoutCategoryInput>;
  content?: Maybe<ContentUpdateManyWithoutBlogCategoryInput>;
};

export type BlogCategoryUpdateOneRequiredWithoutBlogpostsInput = {
  create?: Maybe<BlogCategoryCreateWithoutBlogpostsInput>;
  connectOrCreate?: Maybe<BlogCategoryCreateOrConnectWithoutBlogpostsInput>;
  upsert?: Maybe<BlogCategoryUpsertWithoutBlogpostsInput>;
  connect?: Maybe<BlogCategoryWhereUniqueInput>;
  update?: Maybe<BlogCategoryUpdateWithoutBlogpostsInput>;
};

export type BlogCategoryUpdateOneWithoutContentInput = {
  create?: Maybe<BlogCategoryCreateWithoutContentInput>;
  connectOrCreate?: Maybe<BlogCategoryCreateOrConnectWithoutContentInput>;
  upsert?: Maybe<BlogCategoryUpsertWithoutContentInput>;
  connect?: Maybe<BlogCategoryWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  update?: Maybe<BlogCategoryUpdateWithoutContentInput>;
};

export type BlogCategoryUpdateOneWithoutDescriptionInput = {
  create?: Maybe<BlogCategoryCreateWithoutDescriptionInput>;
  connectOrCreate?: Maybe<BlogCategoryCreateOrConnectWithoutDescriptionInput>;
  upsert?: Maybe<BlogCategoryUpsertWithoutDescriptionInput>;
  connect?: Maybe<BlogCategoryWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  update?: Maybe<BlogCategoryUpdateWithoutDescriptionInput>;
};

export type BlogCategoryUpdateWithoutBlogpostsInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  thumbnail?: Maybe<NullableStringFieldUpdateOperationsInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  hidden?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  description?: Maybe<DescriptionUpdateManyWithoutBlogCategoryInput>;
  content?: Maybe<ContentUpdateManyWithoutBlogCategoryInput>;
};

export type BlogCategoryUpdateWithoutContentInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  thumbnail?: Maybe<NullableStringFieldUpdateOperationsInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  hidden?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  description?: Maybe<DescriptionUpdateManyWithoutBlogCategoryInput>;
  blogposts?: Maybe<BlogPostUpdateManyWithoutCategoryInput>;
};

export type BlogCategoryUpdateWithoutDescriptionInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  thumbnail?: Maybe<NullableStringFieldUpdateOperationsInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  hidden?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  blogposts?: Maybe<BlogPostUpdateManyWithoutCategoryInput>;
  content?: Maybe<ContentUpdateManyWithoutBlogCategoryInput>;
};

export type BlogCategoryUpsertWithoutBlogpostsInput = {
  update: BlogCategoryUpdateWithoutBlogpostsInput;
  create: BlogCategoryCreateWithoutBlogpostsInput;
};

export type BlogCategoryUpsertWithoutContentInput = {
  update: BlogCategoryUpdateWithoutContentInput;
  create: BlogCategoryCreateWithoutContentInput;
};

export type BlogCategoryUpsertWithoutDescriptionInput = {
  update: BlogCategoryUpdateWithoutDescriptionInput;
  create: BlogCategoryCreateWithoutDescriptionInput;
};

export type BlogCategoryWhereInput = {
  AND?: Maybe<Array<BlogCategoryWhereInput>>;
  OR?: Maybe<Array<BlogCategoryWhereInput>>;
  NOT?: Maybe<Array<BlogCategoryWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  thumbnail?: Maybe<StringNullableFilter>;
  order?: Maybe<IntFilter>;
  hidden?: Maybe<BoolNullableFilter>;
  description?: Maybe<DescriptionListRelationFilter>;
  blogposts?: Maybe<BlogPostListRelationFilter>;
  content?: Maybe<ContentListRelationFilter>;
};

export type BlogCategoryWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type BlogPost = {
  __typename?: 'BlogPost';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  publishedAt?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  order: Scalars['Int'];
  isHidden: Scalars['Boolean'];
  blogCategoryId: Scalars['String'];
  userId: Scalars['String'];
  content: Array<Content>;
  description: Array<Description>;
  category: BlogCategory;
  author: User;
};


export type BlogPostContentArgs = {
  where?: Maybe<ContentWhereInput>;
  orderBy?: Maybe<Array<ContentOrderByWithRelationInput>>;
  cursor?: Maybe<ContentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<ContentScalarFieldEnum>>;
};


export type BlogPostDescriptionArgs = {
  where?: Maybe<DescriptionWhereInput>;
  orderBy?: Maybe<Array<DescriptionOrderByWithRelationInput>>;
  cursor?: Maybe<DescriptionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<DescriptionScalarFieldEnum>>;
};

export type BlogPostCreateManyAuthorInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  blogCategoryId: Scalars['String'];
};

export type BlogPostCreateManyAuthorInputEnvelope = {
  data: Array<BlogPostCreateManyAuthorInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BlogPostCreateManyCategoryInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  userId: Scalars['String'];
};

export type BlogPostCreateManyCategoryInputEnvelope = {
  data: Array<BlogPostCreateManyCategoryInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BlogPostCreateNestedManyWithoutAuthorInput = {
  create?: Maybe<Array<BlogPostCreateWithoutAuthorInput>>;
  connectOrCreate?: Maybe<Array<BlogPostCreateOrConnectWithoutAuthorInput>>;
  createMany?: Maybe<BlogPostCreateManyAuthorInputEnvelope>;
  connect?: Maybe<Array<BlogPostWhereUniqueInput>>;
};

export type BlogPostCreateNestedManyWithoutCategoryInput = {
  create?: Maybe<Array<BlogPostCreateWithoutCategoryInput>>;
  connectOrCreate?: Maybe<Array<BlogPostCreateOrConnectWithoutCategoryInput>>;
  createMany?: Maybe<BlogPostCreateManyCategoryInputEnvelope>;
  connect?: Maybe<Array<BlogPostWhereUniqueInput>>;
};

export type BlogPostCreateNestedOneWithoutContentInput = {
  create?: Maybe<BlogPostCreateWithoutContentInput>;
  connectOrCreate?: Maybe<BlogPostCreateOrConnectWithoutContentInput>;
  connect?: Maybe<BlogPostWhereUniqueInput>;
};

export type BlogPostCreateNestedOneWithoutDescriptionInput = {
  create?: Maybe<BlogPostCreateWithoutDescriptionInput>;
  connectOrCreate?: Maybe<BlogPostCreateOrConnectWithoutDescriptionInput>;
  connect?: Maybe<BlogPostWhereUniqueInput>;
};

export type BlogPostCreateOrConnectWithoutAuthorInput = {
  where: BlogPostWhereUniqueInput;
  create: BlogPostCreateWithoutAuthorInput;
};

export type BlogPostCreateOrConnectWithoutCategoryInput = {
  where: BlogPostWhereUniqueInput;
  create: BlogPostCreateWithoutCategoryInput;
};

export type BlogPostCreateOrConnectWithoutContentInput = {
  where: BlogPostWhereUniqueInput;
  create: BlogPostCreateWithoutContentInput;
};

export type BlogPostCreateOrConnectWithoutDescriptionInput = {
  where: BlogPostWhereUniqueInput;
  create: BlogPostCreateWithoutDescriptionInput;
};

export type BlogPostCreateWithoutAuthorInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  content?: Maybe<ContentCreateNestedManyWithoutBlogPostInput>;
  description?: Maybe<DescriptionCreateNestedManyWithoutBlogPostInput>;
  category: BlogCategoryCreateNestedOneWithoutBlogpostsInput;
};

export type BlogPostCreateWithoutCategoryInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  content?: Maybe<ContentCreateNestedManyWithoutBlogPostInput>;
  description?: Maybe<DescriptionCreateNestedManyWithoutBlogPostInput>;
  author: UserCreateNestedOneWithoutBlogpostsInput;
};

export type BlogPostCreateWithoutContentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  description?: Maybe<DescriptionCreateNestedManyWithoutBlogPostInput>;
  category: BlogCategoryCreateNestedOneWithoutBlogpostsInput;
  author: UserCreateNestedOneWithoutBlogpostsInput;
};

export type BlogPostCreateWithoutDescriptionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  content?: Maybe<ContentCreateNestedManyWithoutBlogPostInput>;
  category: BlogCategoryCreateNestedOneWithoutBlogpostsInput;
  author: UserCreateNestedOneWithoutBlogpostsInput;
};

export type BlogPostListRelationFilter = {
  every?: Maybe<BlogPostWhereInput>;
  some?: Maybe<BlogPostWhereInput>;
  none?: Maybe<BlogPostWhereInput>;
};

export type BlogPostOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type BlogPostOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  publishedAt?: Maybe<SortOrder>;
  thumbnail?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  isHidden?: Maybe<SortOrder>;
  content?: Maybe<ContentOrderByRelationAggregateInput>;
  description?: Maybe<DescriptionOrderByRelationAggregateInput>;
  category?: Maybe<BlogCategoryOrderByWithRelationInput>;
  blogCategoryId?: Maybe<SortOrder>;
  author?: Maybe<UserOrderByWithRelationInput>;
  userId?: Maybe<SortOrder>;
};

export type BlogPostRelationFilter = {
  is?: Maybe<BlogPostWhereInput>;
  isNot?: Maybe<BlogPostWhereInput>;
};

export enum BlogPostScalarFieldEnum {
  id = 'id',
  createdAt = 'createdAt',
  updatedAt = 'updatedAt',
  publishedAt = 'publishedAt',
  thumbnail = 'thumbnail',
  order = 'order',
  isHidden = 'isHidden',
  blogCategoryId = 'blogCategoryId',
  userId = 'userId'
}

export type BlogPostScalarWhereInput = {
  AND?: Maybe<Array<BlogPostScalarWhereInput>>;
  OR?: Maybe<Array<BlogPostScalarWhereInput>>;
  NOT?: Maybe<Array<BlogPostScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  publishedAt?: Maybe<StringNullableFilter>;
  thumbnail?: Maybe<StringNullableFilter>;
  order?: Maybe<IntFilter>;
  isHidden?: Maybe<BoolFilter>;
  blogCategoryId?: Maybe<StringFilter>;
  userId?: Maybe<StringFilter>;
};

export type BlogPostUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  publishedAt?: Maybe<NullableStringFieldUpdateOperationsInput>;
  thumbnail?: Maybe<NullableStringFieldUpdateOperationsInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  isHidden?: Maybe<BoolFieldUpdateOperationsInput>;
  content?: Maybe<ContentUpdateManyWithoutBlogPostInput>;
  description?: Maybe<DescriptionUpdateManyWithoutBlogPostInput>;
  category?: Maybe<BlogCategoryUpdateOneRequiredWithoutBlogpostsInput>;
  author?: Maybe<UserUpdateOneRequiredWithoutBlogpostsInput>;
};

export type BlogPostUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  publishedAt?: Maybe<NullableStringFieldUpdateOperationsInput>;
  thumbnail?: Maybe<NullableStringFieldUpdateOperationsInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  isHidden?: Maybe<BoolFieldUpdateOperationsInput>;
};

export type BlogPostUpdateManyWithWhereWithoutAuthorInput = {
  where: BlogPostScalarWhereInput;
  data: BlogPostUpdateManyMutationInput;
};

export type BlogPostUpdateManyWithWhereWithoutCategoryInput = {
  where: BlogPostScalarWhereInput;
  data: BlogPostUpdateManyMutationInput;
};

export type BlogPostUpdateManyWithoutAuthorInput = {
  create?: Maybe<Array<BlogPostCreateWithoutAuthorInput>>;
  connectOrCreate?: Maybe<Array<BlogPostCreateOrConnectWithoutAuthorInput>>;
  upsert?: Maybe<Array<BlogPostUpsertWithWhereUniqueWithoutAuthorInput>>;
  createMany?: Maybe<BlogPostCreateManyAuthorInputEnvelope>;
  connect?: Maybe<Array<BlogPostWhereUniqueInput>>;
  set?: Maybe<Array<BlogPostWhereUniqueInput>>;
  disconnect?: Maybe<Array<BlogPostWhereUniqueInput>>;
  delete?: Maybe<Array<BlogPostWhereUniqueInput>>;
  update?: Maybe<Array<BlogPostUpdateWithWhereUniqueWithoutAuthorInput>>;
  updateMany?: Maybe<Array<BlogPostUpdateManyWithWhereWithoutAuthorInput>>;
  deleteMany?: Maybe<Array<BlogPostScalarWhereInput>>;
};

export type BlogPostUpdateManyWithoutCategoryInput = {
  create?: Maybe<Array<BlogPostCreateWithoutCategoryInput>>;
  connectOrCreate?: Maybe<Array<BlogPostCreateOrConnectWithoutCategoryInput>>;
  upsert?: Maybe<Array<BlogPostUpsertWithWhereUniqueWithoutCategoryInput>>;
  createMany?: Maybe<BlogPostCreateManyCategoryInputEnvelope>;
  connect?: Maybe<Array<BlogPostWhereUniqueInput>>;
  set?: Maybe<Array<BlogPostWhereUniqueInput>>;
  disconnect?: Maybe<Array<BlogPostWhereUniqueInput>>;
  delete?: Maybe<Array<BlogPostWhereUniqueInput>>;
  update?: Maybe<Array<BlogPostUpdateWithWhereUniqueWithoutCategoryInput>>;
  updateMany?: Maybe<Array<BlogPostUpdateManyWithWhereWithoutCategoryInput>>;
  deleteMany?: Maybe<Array<BlogPostScalarWhereInput>>;
};

export type BlogPostUpdateOneWithoutContentInput = {
  create?: Maybe<BlogPostCreateWithoutContentInput>;
  connectOrCreate?: Maybe<BlogPostCreateOrConnectWithoutContentInput>;
  upsert?: Maybe<BlogPostUpsertWithoutContentInput>;
  connect?: Maybe<BlogPostWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  update?: Maybe<BlogPostUpdateWithoutContentInput>;
};

export type BlogPostUpdateOneWithoutDescriptionInput = {
  create?: Maybe<BlogPostCreateWithoutDescriptionInput>;
  connectOrCreate?: Maybe<BlogPostCreateOrConnectWithoutDescriptionInput>;
  upsert?: Maybe<BlogPostUpsertWithoutDescriptionInput>;
  connect?: Maybe<BlogPostWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  update?: Maybe<BlogPostUpdateWithoutDescriptionInput>;
};

export type BlogPostUpdateWithWhereUniqueWithoutAuthorInput = {
  where: BlogPostWhereUniqueInput;
  data: BlogPostUpdateWithoutAuthorInput;
};

export type BlogPostUpdateWithWhereUniqueWithoutCategoryInput = {
  where: BlogPostWhereUniqueInput;
  data: BlogPostUpdateWithoutCategoryInput;
};

export type BlogPostUpdateWithoutAuthorInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  publishedAt?: Maybe<NullableStringFieldUpdateOperationsInput>;
  thumbnail?: Maybe<NullableStringFieldUpdateOperationsInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  isHidden?: Maybe<BoolFieldUpdateOperationsInput>;
  content?: Maybe<ContentUpdateManyWithoutBlogPostInput>;
  description?: Maybe<DescriptionUpdateManyWithoutBlogPostInput>;
  category?: Maybe<BlogCategoryUpdateOneRequiredWithoutBlogpostsInput>;
};

export type BlogPostUpdateWithoutCategoryInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  publishedAt?: Maybe<NullableStringFieldUpdateOperationsInput>;
  thumbnail?: Maybe<NullableStringFieldUpdateOperationsInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  isHidden?: Maybe<BoolFieldUpdateOperationsInput>;
  content?: Maybe<ContentUpdateManyWithoutBlogPostInput>;
  description?: Maybe<DescriptionUpdateManyWithoutBlogPostInput>;
  author?: Maybe<UserUpdateOneRequiredWithoutBlogpostsInput>;
};

export type BlogPostUpdateWithoutContentInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  publishedAt?: Maybe<NullableStringFieldUpdateOperationsInput>;
  thumbnail?: Maybe<NullableStringFieldUpdateOperationsInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  isHidden?: Maybe<BoolFieldUpdateOperationsInput>;
  description?: Maybe<DescriptionUpdateManyWithoutBlogPostInput>;
  category?: Maybe<BlogCategoryUpdateOneRequiredWithoutBlogpostsInput>;
  author?: Maybe<UserUpdateOneRequiredWithoutBlogpostsInput>;
};

export type BlogPostUpdateWithoutDescriptionInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  publishedAt?: Maybe<NullableStringFieldUpdateOperationsInput>;
  thumbnail?: Maybe<NullableStringFieldUpdateOperationsInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  isHidden?: Maybe<BoolFieldUpdateOperationsInput>;
  content?: Maybe<ContentUpdateManyWithoutBlogPostInput>;
  category?: Maybe<BlogCategoryUpdateOneRequiredWithoutBlogpostsInput>;
  author?: Maybe<UserUpdateOneRequiredWithoutBlogpostsInput>;
};

export type BlogPostUpsertWithWhereUniqueWithoutAuthorInput = {
  where: BlogPostWhereUniqueInput;
  update: BlogPostUpdateWithoutAuthorInput;
  create: BlogPostCreateWithoutAuthorInput;
};

export type BlogPostUpsertWithWhereUniqueWithoutCategoryInput = {
  where: BlogPostWhereUniqueInput;
  update: BlogPostUpdateWithoutCategoryInput;
  create: BlogPostCreateWithoutCategoryInput;
};

export type BlogPostUpsertWithoutContentInput = {
  update: BlogPostUpdateWithoutContentInput;
  create: BlogPostCreateWithoutContentInput;
};

export type BlogPostUpsertWithoutDescriptionInput = {
  update: BlogPostUpdateWithoutDescriptionInput;
  create: BlogPostCreateWithoutDescriptionInput;
};

export type BlogPostWhereInput = {
  AND?: Maybe<Array<BlogPostWhereInput>>;
  OR?: Maybe<Array<BlogPostWhereInput>>;
  NOT?: Maybe<Array<BlogPostWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  publishedAt?: Maybe<StringNullableFilter>;
  thumbnail?: Maybe<StringNullableFilter>;
  order?: Maybe<IntFilter>;
  isHidden?: Maybe<BoolFilter>;
  content?: Maybe<ContentListRelationFilter>;
  description?: Maybe<DescriptionListRelationFilter>;
  category?: Maybe<BlogCategoryRelationFilter>;
  blogCategoryId?: Maybe<StringFilter>;
  author?: Maybe<UserRelationFilter>;
  userId?: Maybe<StringFilter>;
};

export type BlogPostWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type BoolFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['Boolean']>;
};

export type BoolFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolFilter>;
};

export type BoolNullableFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolNullableFilter>;
};

export type BoolWithAggregatesFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedBoolFilter>;
  _max?: Maybe<NestedBoolFilter>;
};

export type Brand = {
  __typename?: 'Brand';
  id: Scalars['String'];
  title: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  order: Scalars['Int'];
  description: Array<Description>;
  products: Array<Product>;
  countries: Array<Country>;
};


export type BrandDescriptionArgs = {
  where?: Maybe<DescriptionWhereInput>;
  orderBy?: Maybe<Array<DescriptionOrderByWithRelationInput>>;
  cursor?: Maybe<DescriptionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<DescriptionScalarFieldEnum>>;
};


export type BrandProductsArgs = {
  where?: Maybe<ProductWhereInput>;
  orderBy?: Maybe<Array<ProductOrderByWithRelationInput>>;
  cursor?: Maybe<ProductWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<ProductScalarFieldEnum>>;
};


export type BrandCountriesArgs = {
  where?: Maybe<CountryWhereInput>;
  orderBy?: Maybe<Array<CountryOrderByWithRelationInput>>;
  cursor?: Maybe<CountryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<CountryScalarFieldEnum>>;
};

export type BrandCreateInput = {
  id?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  description?: Maybe<DescriptionCreateNestedManyWithoutBrandInput>;
  products?: Maybe<ProductCreateNestedManyWithoutBrandInput>;
  countries?: Maybe<CountryCreateNestedManyWithoutBrandsInput>;
};

export type BrandCreateNestedOneWithoutDescriptionInput = {
  create?: Maybe<BrandCreateWithoutDescriptionInput>;
  connectOrCreate?: Maybe<BrandCreateOrConnectWithoutDescriptionInput>;
  connect?: Maybe<BrandWhereUniqueInput>;
};

export type BrandCreateNestedOneWithoutProductsInput = {
  create?: Maybe<BrandCreateWithoutProductsInput>;
  connectOrCreate?: Maybe<BrandCreateOrConnectWithoutProductsInput>;
  connect?: Maybe<BrandWhereUniqueInput>;
};

export type BrandCreateOrConnectWithoutDescriptionInput = {
  where: BrandWhereUniqueInput;
  create: BrandCreateWithoutDescriptionInput;
};

export type BrandCreateOrConnectWithoutProductsInput = {
  where: BrandWhereUniqueInput;
  create: BrandCreateWithoutProductsInput;
};

export type BrandCreateWithoutDescriptionInput = {
  id?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  products?: Maybe<ProductCreateNestedManyWithoutBrandInput>;
  countries?: Maybe<CountryCreateNestedManyWithoutBrandsInput>;
};

export type BrandCreateWithoutProductsInput = {
  id?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  description?: Maybe<DescriptionCreateNestedManyWithoutBrandInput>;
  countries?: Maybe<CountryCreateNestedManyWithoutBrandsInput>;
};

export type BrandListRelationFilter = {
  every?: Maybe<BrandWhereInput>;
  some?: Maybe<BrandWhereInput>;
  none?: Maybe<BrandWhereInput>;
};

export type BrandOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type BrandOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  slug?: Maybe<SortOrder>;
  photo?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  description?: Maybe<DescriptionOrderByRelationAggregateInput>;
  products?: Maybe<ProductOrderByRelationAggregateInput>;
  countries?: Maybe<CountryOrderByRelationAggregateInput>;
};

export type BrandPayload = {
  __typename?: 'BrandPayload';
  id: Scalars['String'];
  title: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  order: Scalars['Int'];
  description: Array<Description>;
  products: Array<Product>;
  countries: Array<Country>;
};


export type BrandPayloadDescriptionArgs = {
  where?: Maybe<DescriptionWhereInput>;
  orderBy?: Maybe<Array<DescriptionOrderByWithRelationInput>>;
  cursor?: Maybe<DescriptionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<DescriptionScalarFieldEnum>>;
};


export type BrandPayloadProductsArgs = {
  where?: Maybe<ProductWhereInput>;
  orderBy?: Maybe<Array<ProductOrderByWithRelationInput>>;
  cursor?: Maybe<ProductWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<ProductScalarFieldEnum>>;
};


export type BrandPayloadCountriesArgs = {
  where?: Maybe<CountryWhereInput>;
  orderBy?: Maybe<Array<CountryOrderByWithRelationInput>>;
  cursor?: Maybe<CountryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<CountryScalarFieldEnum>>;
};

export type BrandRelationFilter = {
  is?: Maybe<BrandWhereInput>;
  isNot?: Maybe<BrandWhereInput>;
};

export enum BrandScalarFieldEnum {
  id = 'id',
  title = 'title',
  slug = 'slug',
  photo = 'photo',
  order = 'order'
}

export type BrandUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  title?: Maybe<StringFieldUpdateOperationsInput>;
  slug?: Maybe<NullableStringFieldUpdateOperationsInput>;
  photo?: Maybe<NullableStringFieldUpdateOperationsInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  description?: Maybe<DescriptionUpdateManyWithoutBrandInput>;
  products?: Maybe<ProductUpdateManyWithoutBrandInput>;
  countries?: Maybe<CountryUpdateManyWithoutBrandsInput>;
};

export type BrandUpdateOneRequiredWithoutProductsInput = {
  create?: Maybe<BrandCreateWithoutProductsInput>;
  connectOrCreate?: Maybe<BrandCreateOrConnectWithoutProductsInput>;
  upsert?: Maybe<BrandUpsertWithoutProductsInput>;
  connect?: Maybe<BrandWhereUniqueInput>;
  update?: Maybe<BrandUpdateWithoutProductsInput>;
};

export type BrandUpdateOneWithoutDescriptionInput = {
  create?: Maybe<BrandCreateWithoutDescriptionInput>;
  connectOrCreate?: Maybe<BrandCreateOrConnectWithoutDescriptionInput>;
  upsert?: Maybe<BrandUpsertWithoutDescriptionInput>;
  connect?: Maybe<BrandWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  update?: Maybe<BrandUpdateWithoutDescriptionInput>;
};

export type BrandUpdateWithoutDescriptionInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  title?: Maybe<StringFieldUpdateOperationsInput>;
  slug?: Maybe<NullableStringFieldUpdateOperationsInput>;
  photo?: Maybe<NullableStringFieldUpdateOperationsInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  products?: Maybe<ProductUpdateManyWithoutBrandInput>;
  countries?: Maybe<CountryUpdateManyWithoutBrandsInput>;
};

export type BrandUpdateWithoutProductsInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  title?: Maybe<StringFieldUpdateOperationsInput>;
  slug?: Maybe<NullableStringFieldUpdateOperationsInput>;
  photo?: Maybe<NullableStringFieldUpdateOperationsInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  description?: Maybe<DescriptionUpdateManyWithoutBrandInput>;
  countries?: Maybe<CountryUpdateManyWithoutBrandsInput>;
};

export type BrandUpsertWithoutDescriptionInput = {
  update: BrandUpdateWithoutDescriptionInput;
  create: BrandCreateWithoutDescriptionInput;
};

export type BrandUpsertWithoutProductsInput = {
  update: BrandUpdateWithoutProductsInput;
  create: BrandCreateWithoutProductsInput;
};

export type BrandWhereInput = {
  AND?: Maybe<Array<BrandWhereInput>>;
  OR?: Maybe<Array<BrandWhereInput>>;
  NOT?: Maybe<Array<BrandWhereInput>>;
  id?: Maybe<StringFilter>;
  title?: Maybe<StringFilter>;
  slug?: Maybe<StringNullableFilter>;
  photo?: Maybe<StringNullableFilter>;
  order?: Maybe<IntFilter>;
  description?: Maybe<DescriptionListRelationFilter>;
  products?: Maybe<ProductListRelationFilter>;
  countries?: Maybe<CountryListRelationFilter>;
};

export type BrandWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

export type Category = {
  __typename?: 'Category';
  id: Scalars['String'];
  photos: Array<Scalars['String']>;
  parentId?: Maybe<Scalars['String']>;
  order: Scalars['Int'];
  products: Array<Product>;
  descriptions: Array<Description>;
  parent?: Maybe<Category>;
  children: Array<Category>;
  content: Array<Content>;
};


export type CategoryProductsArgs = {
  where?: Maybe<ProductWhereInput>;
  orderBy?: Maybe<Array<ProductOrderByWithRelationInput>>;
  cursor?: Maybe<ProductWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<ProductScalarFieldEnum>>;
};


export type CategoryDescriptionsArgs = {
  where?: Maybe<DescriptionWhereInput>;
  orderBy?: Maybe<Array<DescriptionOrderByWithRelationInput>>;
  cursor?: Maybe<DescriptionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<DescriptionScalarFieldEnum>>;
};


export type CategoryChildrenArgs = {
  where?: Maybe<CategoryWhereInput>;
  orderBy?: Maybe<Array<CategoryOrderByWithRelationInput>>;
  cursor?: Maybe<CategoryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<CategoryScalarFieldEnum>>;
};


export type CategoryContentArgs = {
  where?: Maybe<ContentWhereInput>;
  orderBy?: Maybe<Array<ContentOrderByWithRelationInput>>;
  cursor?: Maybe<ContentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<ContentScalarFieldEnum>>;
};

export type CategoryCreateManyParentInput = {
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  photos?: Maybe<CategoryCreateManyphotosInput>;
};

export type CategoryCreateManyParentInputEnvelope = {
  data: Array<CategoryCreateManyParentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CategoryCreateManyphotosInput = {
  set: Array<Scalars['String']>;
};

export type CategoryCreateNestedManyWithoutParentInput = {
  create?: Maybe<Array<CategoryCreateWithoutParentInput>>;
  connectOrCreate?: Maybe<Array<CategoryCreateOrConnectWithoutParentInput>>;
  createMany?: Maybe<CategoryCreateManyParentInputEnvelope>;
  connect?: Maybe<Array<CategoryWhereUniqueInput>>;
};

export type CategoryCreateNestedOneWithoutChildrenInput = {
  create?: Maybe<CategoryCreateWithoutChildrenInput>;
  connectOrCreate?: Maybe<CategoryCreateOrConnectWithoutChildrenInput>;
  connect?: Maybe<CategoryWhereUniqueInput>;
};

export type CategoryCreateNestedOneWithoutContentInput = {
  create?: Maybe<CategoryCreateWithoutContentInput>;
  connectOrCreate?: Maybe<CategoryCreateOrConnectWithoutContentInput>;
  connect?: Maybe<CategoryWhereUniqueInput>;
};

export type CategoryCreateNestedOneWithoutDescriptionsInput = {
  create?: Maybe<CategoryCreateWithoutDescriptionsInput>;
  connectOrCreate?: Maybe<CategoryCreateOrConnectWithoutDescriptionsInput>;
  connect?: Maybe<CategoryWhereUniqueInput>;
};

export type CategoryCreateNestedOneWithoutProductsInput = {
  create?: Maybe<CategoryCreateWithoutProductsInput>;
  connectOrCreate?: Maybe<CategoryCreateOrConnectWithoutProductsInput>;
  connect?: Maybe<CategoryWhereUniqueInput>;
};

export type CategoryCreateOrConnectWithoutChildrenInput = {
  where: CategoryWhereUniqueInput;
  create: CategoryCreateWithoutChildrenInput;
};

export type CategoryCreateOrConnectWithoutContentInput = {
  where: CategoryWhereUniqueInput;
  create: CategoryCreateWithoutContentInput;
};

export type CategoryCreateOrConnectWithoutDescriptionsInput = {
  where: CategoryWhereUniqueInput;
  create: CategoryCreateWithoutDescriptionsInput;
};

export type CategoryCreateOrConnectWithoutParentInput = {
  where: CategoryWhereUniqueInput;
  create: CategoryCreateWithoutParentInput;
};

export type CategoryCreateOrConnectWithoutProductsInput = {
  where: CategoryWhereUniqueInput;
  create: CategoryCreateWithoutProductsInput;
};

export type CategoryCreateWithoutChildrenInput = {
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  photos?: Maybe<CategoryCreatephotosInput>;
  products?: Maybe<ProductCreateNestedManyWithoutCategoryInput>;
  descriptions?: Maybe<DescriptionCreateNestedManyWithoutCategoryInput>;
  parent?: Maybe<CategoryCreateNestedOneWithoutChildrenInput>;
  content?: Maybe<ContentCreateNestedManyWithoutProductCategoryInput>;
};

export type CategoryCreateWithoutContentInput = {
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  photos?: Maybe<CategoryCreatephotosInput>;
  products?: Maybe<ProductCreateNestedManyWithoutCategoryInput>;
  descriptions?: Maybe<DescriptionCreateNestedManyWithoutCategoryInput>;
  parent?: Maybe<CategoryCreateNestedOneWithoutChildrenInput>;
  children?: Maybe<CategoryCreateNestedManyWithoutParentInput>;
};

export type CategoryCreateWithoutDescriptionsInput = {
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  photos?: Maybe<CategoryCreatephotosInput>;
  products?: Maybe<ProductCreateNestedManyWithoutCategoryInput>;
  parent?: Maybe<CategoryCreateNestedOneWithoutChildrenInput>;
  children?: Maybe<CategoryCreateNestedManyWithoutParentInput>;
  content?: Maybe<ContentCreateNestedManyWithoutProductCategoryInput>;
};

export type CategoryCreateWithoutParentInput = {
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  photos?: Maybe<CategoryCreatephotosInput>;
  products?: Maybe<ProductCreateNestedManyWithoutCategoryInput>;
  descriptions?: Maybe<DescriptionCreateNestedManyWithoutCategoryInput>;
  children?: Maybe<CategoryCreateNestedManyWithoutParentInput>;
  content?: Maybe<ContentCreateNestedManyWithoutProductCategoryInput>;
};

export type CategoryCreateWithoutProductsInput = {
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  photos?: Maybe<CategoryCreatephotosInput>;
  descriptions?: Maybe<DescriptionCreateNestedManyWithoutCategoryInput>;
  parent?: Maybe<CategoryCreateNestedOneWithoutChildrenInput>;
  children?: Maybe<CategoryCreateNestedManyWithoutParentInput>;
  content?: Maybe<ContentCreateNestedManyWithoutProductCategoryInput>;
};

export type CategoryCreatephotosInput = {
  set: Array<Scalars['String']>;
};

export type CategoryListRelationFilter = {
  every?: Maybe<CategoryWhereInput>;
  some?: Maybe<CategoryWhereInput>;
  none?: Maybe<CategoryWhereInput>;
};

export type CategoryOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type CategoryOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  photos?: Maybe<SortOrder>;
  parentId?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  products?: Maybe<ProductOrderByRelationAggregateInput>;
  descriptions?: Maybe<DescriptionOrderByRelationAggregateInput>;
  parent?: Maybe<CategoryOrderByWithRelationInput>;
  children?: Maybe<CategoryOrderByRelationAggregateInput>;
  content?: Maybe<ContentOrderByRelationAggregateInput>;
};

export type CategoryRelationFilter = {
  is?: Maybe<CategoryWhereInput>;
  isNot?: Maybe<CategoryWhereInput>;
};

export enum CategoryScalarFieldEnum {
  id = 'id',
  photos = 'photos',
  parentId = 'parentId',
  order = 'order'
}

export type CategoryScalarWhereInput = {
  AND?: Maybe<Array<CategoryScalarWhereInput>>;
  OR?: Maybe<Array<CategoryScalarWhereInput>>;
  NOT?: Maybe<Array<CategoryScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  photos?: Maybe<StringNullableListFilter>;
  parentId?: Maybe<StringNullableFilter>;
  order?: Maybe<IntFilter>;
};

export type CategoryUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  photos?: Maybe<CategoryUpdatephotosInput>;
  products?: Maybe<ProductUpdateManyWithoutCategoryInput>;
  descriptions?: Maybe<DescriptionUpdateManyWithoutCategoryInput>;
  parent?: Maybe<CategoryUpdateOneWithoutChildrenInput>;
  children?: Maybe<CategoryUpdateManyWithoutParentInput>;
  content?: Maybe<ContentUpdateManyWithoutProductCategoryInput>;
};

export type CategoryUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  photos?: Maybe<CategoryUpdatephotosInput>;
};

export type CategoryUpdateManyWithWhereWithoutParentInput = {
  where: CategoryScalarWhereInput;
  data: CategoryUpdateManyMutationInput;
};

export type CategoryUpdateManyWithoutParentInput = {
  create?: Maybe<Array<CategoryCreateWithoutParentInput>>;
  connectOrCreate?: Maybe<Array<CategoryCreateOrConnectWithoutParentInput>>;
  upsert?: Maybe<Array<CategoryUpsertWithWhereUniqueWithoutParentInput>>;
  createMany?: Maybe<CategoryCreateManyParentInputEnvelope>;
  connect?: Maybe<Array<CategoryWhereUniqueInput>>;
  set?: Maybe<Array<CategoryWhereUniqueInput>>;
  disconnect?: Maybe<Array<CategoryWhereUniqueInput>>;
  delete?: Maybe<Array<CategoryWhereUniqueInput>>;
  update?: Maybe<Array<CategoryUpdateWithWhereUniqueWithoutParentInput>>;
  updateMany?: Maybe<Array<CategoryUpdateManyWithWhereWithoutParentInput>>;
  deleteMany?: Maybe<Array<CategoryScalarWhereInput>>;
};

export type CategoryUpdateOneWithoutChildrenInput = {
  create?: Maybe<CategoryCreateWithoutChildrenInput>;
  connectOrCreate?: Maybe<CategoryCreateOrConnectWithoutChildrenInput>;
  upsert?: Maybe<CategoryUpsertWithoutChildrenInput>;
  connect?: Maybe<CategoryWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  update?: Maybe<CategoryUpdateWithoutChildrenInput>;
};

export type CategoryUpdateOneWithoutContentInput = {
  create?: Maybe<CategoryCreateWithoutContentInput>;
  connectOrCreate?: Maybe<CategoryCreateOrConnectWithoutContentInput>;
  upsert?: Maybe<CategoryUpsertWithoutContentInput>;
  connect?: Maybe<CategoryWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  update?: Maybe<CategoryUpdateWithoutContentInput>;
};

export type CategoryUpdateOneWithoutDescriptionsInput = {
  create?: Maybe<CategoryCreateWithoutDescriptionsInput>;
  connectOrCreate?: Maybe<CategoryCreateOrConnectWithoutDescriptionsInput>;
  upsert?: Maybe<CategoryUpsertWithoutDescriptionsInput>;
  connect?: Maybe<CategoryWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  update?: Maybe<CategoryUpdateWithoutDescriptionsInput>;
};

export type CategoryUpdateOneWithoutProductsInput = {
  create?: Maybe<CategoryCreateWithoutProductsInput>;
  connectOrCreate?: Maybe<CategoryCreateOrConnectWithoutProductsInput>;
  upsert?: Maybe<CategoryUpsertWithoutProductsInput>;
  connect?: Maybe<CategoryWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  update?: Maybe<CategoryUpdateWithoutProductsInput>;
};

export type CategoryUpdateWithWhereUniqueWithoutParentInput = {
  where: CategoryWhereUniqueInput;
  data: CategoryUpdateWithoutParentInput;
};

export type CategoryUpdateWithoutChildrenInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  photos?: Maybe<CategoryUpdatephotosInput>;
  products?: Maybe<ProductUpdateManyWithoutCategoryInput>;
  descriptions?: Maybe<DescriptionUpdateManyWithoutCategoryInput>;
  parent?: Maybe<CategoryUpdateOneWithoutChildrenInput>;
  content?: Maybe<ContentUpdateManyWithoutProductCategoryInput>;
};

export type CategoryUpdateWithoutContentInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  photos?: Maybe<CategoryUpdatephotosInput>;
  products?: Maybe<ProductUpdateManyWithoutCategoryInput>;
  descriptions?: Maybe<DescriptionUpdateManyWithoutCategoryInput>;
  parent?: Maybe<CategoryUpdateOneWithoutChildrenInput>;
  children?: Maybe<CategoryUpdateManyWithoutParentInput>;
};

export type CategoryUpdateWithoutDescriptionsInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  photos?: Maybe<CategoryUpdatephotosInput>;
  products?: Maybe<ProductUpdateManyWithoutCategoryInput>;
  parent?: Maybe<CategoryUpdateOneWithoutChildrenInput>;
  children?: Maybe<CategoryUpdateManyWithoutParentInput>;
  content?: Maybe<ContentUpdateManyWithoutProductCategoryInput>;
};

export type CategoryUpdateWithoutParentInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  photos?: Maybe<CategoryUpdatephotosInput>;
  products?: Maybe<ProductUpdateManyWithoutCategoryInput>;
  descriptions?: Maybe<DescriptionUpdateManyWithoutCategoryInput>;
  children?: Maybe<CategoryUpdateManyWithoutParentInput>;
  content?: Maybe<ContentUpdateManyWithoutProductCategoryInput>;
};

export type CategoryUpdateWithoutProductsInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  photos?: Maybe<CategoryUpdatephotosInput>;
  descriptions?: Maybe<DescriptionUpdateManyWithoutCategoryInput>;
  parent?: Maybe<CategoryUpdateOneWithoutChildrenInput>;
  children?: Maybe<CategoryUpdateManyWithoutParentInput>;
  content?: Maybe<ContentUpdateManyWithoutProductCategoryInput>;
};

export type CategoryUpdatephotosInput = {
  set?: Maybe<Array<Scalars['String']>>;
  push?: Maybe<Array<Scalars['String']>>;
};

export type CategoryUpsertWithWhereUniqueWithoutParentInput = {
  where: CategoryWhereUniqueInput;
  update: CategoryUpdateWithoutParentInput;
  create: CategoryCreateWithoutParentInput;
};

export type CategoryUpsertWithoutChildrenInput = {
  update: CategoryUpdateWithoutChildrenInput;
  create: CategoryCreateWithoutChildrenInput;
};

export type CategoryUpsertWithoutContentInput = {
  update: CategoryUpdateWithoutContentInput;
  create: CategoryCreateWithoutContentInput;
};

export type CategoryUpsertWithoutDescriptionsInput = {
  update: CategoryUpdateWithoutDescriptionsInput;
  create: CategoryCreateWithoutDescriptionsInput;
};

export type CategoryUpsertWithoutProductsInput = {
  update: CategoryUpdateWithoutProductsInput;
  create: CategoryCreateWithoutProductsInput;
};

export type CategoryWhereInput = {
  AND?: Maybe<Array<CategoryWhereInput>>;
  OR?: Maybe<Array<CategoryWhereInput>>;
  NOT?: Maybe<Array<CategoryWhereInput>>;
  id?: Maybe<StringFilter>;
  photos?: Maybe<StringNullableListFilter>;
  parentId?: Maybe<StringNullableFilter>;
  order?: Maybe<IntFilter>;
  products?: Maybe<ProductListRelationFilter>;
  descriptions?: Maybe<DescriptionListRelationFilter>;
  parent?: Maybe<CategoryRelationFilter>;
  children?: Maybe<CategoryListRelationFilter>;
  content?: Maybe<ContentListRelationFilter>;
};

export type CategoryWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type City = {
  __typename?: 'City';
  id: Scalars['String'];
  title: Scalars['String'];
  countryId: Scalars['String'];
};

export type CityCreateManyCountryInput = {
  id?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type CityCreateManyCountryInputEnvelope = {
  data: Array<CityCreateManyCountryInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CityCreateNestedManyWithoutCountryInput = {
  create?: Maybe<Array<CityCreateWithoutCountryInput>>;
  connectOrCreate?: Maybe<Array<CityCreateOrConnectWithoutCountryInput>>;
  createMany?: Maybe<CityCreateManyCountryInputEnvelope>;
  connect?: Maybe<Array<CityWhereUniqueInput>>;
};

export type CityCreateOrConnectWithoutCountryInput = {
  where: CityWhereUniqueInput;
  create: CityCreateWithoutCountryInput;
};

export type CityCreateWithoutCountryInput = {
  id?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  township?: Maybe<TownshipCreateNestedManyWithoutCityInput>;
};

export type CityListRelationFilter = {
  every?: Maybe<CityWhereInput>;
  some?: Maybe<CityWhereInput>;
  none?: Maybe<CityWhereInput>;
};

export type CityOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type CityOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  country?: Maybe<CountryOrderByWithRelationInput>;
  countryId?: Maybe<SortOrder>;
  township?: Maybe<TownshipOrderByRelationAggregateInput>;
};

export type CityRelationFilter = {
  is?: Maybe<CityWhereInput>;
  isNot?: Maybe<CityWhereInput>;
};

export enum CityScalarFieldEnum {
  id = 'id',
  title = 'title',
  countryId = 'countryId'
}

export type CityScalarWhereInput = {
  AND?: Maybe<Array<CityScalarWhereInput>>;
  OR?: Maybe<Array<CityScalarWhereInput>>;
  NOT?: Maybe<Array<CityScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  title?: Maybe<StringFilter>;
  countryId?: Maybe<StringFilter>;
};

export type CityUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  title?: Maybe<StringFieldUpdateOperationsInput>;
};

export type CityUpdateManyWithWhereWithoutCountryInput = {
  where: CityScalarWhereInput;
  data: CityUpdateManyMutationInput;
};

export type CityUpdateManyWithoutCountryInput = {
  create?: Maybe<Array<CityCreateWithoutCountryInput>>;
  connectOrCreate?: Maybe<Array<CityCreateOrConnectWithoutCountryInput>>;
  upsert?: Maybe<Array<CityUpsertWithWhereUniqueWithoutCountryInput>>;
  createMany?: Maybe<CityCreateManyCountryInputEnvelope>;
  connect?: Maybe<Array<CityWhereUniqueInput>>;
  set?: Maybe<Array<CityWhereUniqueInput>>;
  disconnect?: Maybe<Array<CityWhereUniqueInput>>;
  delete?: Maybe<Array<CityWhereUniqueInput>>;
  update?: Maybe<Array<CityUpdateWithWhereUniqueWithoutCountryInput>>;
  updateMany?: Maybe<Array<CityUpdateManyWithWhereWithoutCountryInput>>;
  deleteMany?: Maybe<Array<CityScalarWhereInput>>;
};

export type CityUpdateWithWhereUniqueWithoutCountryInput = {
  where: CityWhereUniqueInput;
  data: CityUpdateWithoutCountryInput;
};

export type CityUpdateWithoutCountryInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  title?: Maybe<StringFieldUpdateOperationsInput>;
  township?: Maybe<TownshipUpdateManyWithoutCityInput>;
};

export type CityUpsertWithWhereUniqueWithoutCountryInput = {
  where: CityWhereUniqueInput;
  update: CityUpdateWithoutCountryInput;
  create: CityCreateWithoutCountryInput;
};

export type CityWhereInput = {
  AND?: Maybe<Array<CityWhereInput>>;
  OR?: Maybe<Array<CityWhereInput>>;
  NOT?: Maybe<Array<CityWhereInput>>;
  id?: Maybe<StringFilter>;
  title?: Maybe<StringFilter>;
  country?: Maybe<CountryRelationFilter>;
  countryId?: Maybe<StringFilter>;
  township?: Maybe<TownshipListRelationFilter>;
};

export type CityWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type Contact = {
  __typename?: 'Contact';
  id: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  registrationNumber?: Maybe<Scalars['String']>;
  mersisNumber?: Maybe<Scalars['String']>;
  kepEmail?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  faxNumber?: Maybe<Scalars['String']>;
  taxNumber?: Maybe<Scalars['String']>;
  taxOffice?: Maybe<Scalars['String']>;
  other?: Maybe<Scalars['JSON']>;
  description: Array<Description>;
  address: Array<Address>;
};


export type ContactDescriptionArgs = {
  where?: Maybe<DescriptionWhereInput>;
  orderBy?: Maybe<Array<DescriptionOrderByWithRelationInput>>;
  cursor?: Maybe<DescriptionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<DescriptionScalarFieldEnum>>;
};


export type ContactAddressArgs = {
  where?: Maybe<AddressWhereInput>;
  orderBy?: Maybe<Array<AddressOrderByWithRelationInput>>;
  cursor?: Maybe<AddressWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<AddressScalarFieldEnum>>;
};

export type ContactCreateNestedOneWithoutAddressInput = {
  create?: Maybe<ContactCreateWithoutAddressInput>;
  connectOrCreate?: Maybe<ContactCreateOrConnectWithoutAddressInput>;
  connect?: Maybe<ContactWhereUniqueInput>;
};

export type ContactCreateNestedOneWithoutDescriptionInput = {
  create?: Maybe<ContactCreateWithoutDescriptionInput>;
  connectOrCreate?: Maybe<ContactCreateOrConnectWithoutDescriptionInput>;
  connect?: Maybe<ContactWhereUniqueInput>;
};

export type ContactCreateOrConnectWithoutAddressInput = {
  where: ContactWhereUniqueInput;
  create: ContactCreateWithoutAddressInput;
};

export type ContactCreateOrConnectWithoutDescriptionInput = {
  where: ContactWhereUniqueInput;
  create: ContactCreateWithoutDescriptionInput;
};

export type ContactCreateWithoutAddressInput = {
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  registrationNumber?: Maybe<Scalars['String']>;
  mersisNumber?: Maybe<Scalars['String']>;
  kepEmail?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  faxNumber?: Maybe<Scalars['String']>;
  taxNumber?: Maybe<Scalars['String']>;
  taxOffice?: Maybe<Scalars['String']>;
  other?: Maybe<Scalars['JSON']>;
  description?: Maybe<DescriptionCreateNestedManyWithoutContactInput>;
};

export type ContactCreateWithoutDescriptionInput = {
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  registrationNumber?: Maybe<Scalars['String']>;
  mersisNumber?: Maybe<Scalars['String']>;
  kepEmail?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  faxNumber?: Maybe<Scalars['String']>;
  taxNumber?: Maybe<Scalars['String']>;
  taxOffice?: Maybe<Scalars['String']>;
  other?: Maybe<Scalars['JSON']>;
  address?: Maybe<AddressCreateNestedManyWithoutContactInput>;
};

export type ContactOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<DescriptionOrderByRelationAggregateInput>;
  registrationNumber?: Maybe<SortOrder>;
  mersisNumber?: Maybe<SortOrder>;
  kepEmail?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  faxNumber?: Maybe<SortOrder>;
  taxNumber?: Maybe<SortOrder>;
  taxOffice?: Maybe<SortOrder>;
  other?: Maybe<SortOrder>;
  address?: Maybe<AddressOrderByRelationAggregateInput>;
};

export type ContactRelationFilter = {
  is?: Maybe<ContactWhereInput>;
  isNot?: Maybe<ContactWhereInput>;
};

export type ContactUpdateOneRequiredWithoutAddressInput = {
  create?: Maybe<ContactCreateWithoutAddressInput>;
  connectOrCreate?: Maybe<ContactCreateOrConnectWithoutAddressInput>;
  upsert?: Maybe<ContactUpsertWithoutAddressInput>;
  connect?: Maybe<ContactWhereUniqueInput>;
  update?: Maybe<ContactUpdateWithoutAddressInput>;
};

export type ContactUpdateOneWithoutDescriptionInput = {
  create?: Maybe<ContactCreateWithoutDescriptionInput>;
  connectOrCreate?: Maybe<ContactCreateOrConnectWithoutDescriptionInput>;
  upsert?: Maybe<ContactUpsertWithoutDescriptionInput>;
  connect?: Maybe<ContactWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ContactUpdateWithoutDescriptionInput>;
};

export type ContactUpdateWithoutAddressInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  registrationNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  mersisNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  kepEmail?: Maybe<NullableStringFieldUpdateOperationsInput>;
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  phoneNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  faxNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  taxNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  taxOffice?: Maybe<NullableStringFieldUpdateOperationsInput>;
  other?: Maybe<Scalars['JSON']>;
  description?: Maybe<DescriptionUpdateManyWithoutContactInput>;
};

export type ContactUpdateWithoutDescriptionInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  registrationNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  mersisNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  kepEmail?: Maybe<NullableStringFieldUpdateOperationsInput>;
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  phoneNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  faxNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  taxNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  taxOffice?: Maybe<NullableStringFieldUpdateOperationsInput>;
  other?: Maybe<Scalars['JSON']>;
  address?: Maybe<AddressUpdateManyWithoutContactInput>;
};

export type ContactUpsertWithoutAddressInput = {
  update: ContactUpdateWithoutAddressInput;
  create: ContactCreateWithoutAddressInput;
};

export type ContactUpsertWithoutDescriptionInput = {
  update: ContactUpdateWithoutDescriptionInput;
  create: ContactCreateWithoutDescriptionInput;
};

export type ContactWhereInput = {
  AND?: Maybe<Array<ContactWhereInput>>;
  OR?: Maybe<Array<ContactWhereInput>>;
  NOT?: Maybe<Array<ContactWhereInput>>;
  id?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  description?: Maybe<DescriptionListRelationFilter>;
  registrationNumber?: Maybe<StringNullableFilter>;
  mersisNumber?: Maybe<StringNullableFilter>;
  kepEmail?: Maybe<StringNullableFilter>;
  email?: Maybe<StringNullableFilter>;
  phoneNumber?: Maybe<StringNullableFilter>;
  faxNumber?: Maybe<StringNullableFilter>;
  taxNumber?: Maybe<StringNullableFilter>;
  taxOffice?: Maybe<StringNullableFilter>;
  other?: Maybe<JsonNullableFilter>;
  address?: Maybe<AddressListRelationFilter>;
};

export type ContactWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type Content = {
  __typename?: 'Content';
  id: Scalars['String'];
  backgroundImage?: Maybe<Scalars['String']>;
  theme: ThemeType;
  photos: Array<Scalars['String']>;
  videos?: Maybe<Scalars['String']>;
  embedCode?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  contentType: ContentType;
  mediaType: MediaType;
  productCategoryType: ProductCategoryListType;
  lineUp: ContentLineup;
  source?: Maybe<Scalars['String']>;
  cards?: Maybe<Scalars['JSON']>;
  slides?: Maybe<Scalars['JSON']>;
  timeline?: Maybe<Scalars['JSON']>;
  order: Scalars['Int'];
  pageId?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  blogPostId?: Maybe<Scalars['String']>;
  productCategoryId?: Maybe<Scalars['String']>;
  blogCategoryId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  description: Array<Description>;
  page?: Maybe<Page>;
  product?: Maybe<Product>;
  blogPost?: Maybe<BlogPost>;
  productCategory?: Maybe<Category>;
  blogCategory?: Maybe<BlogCategory>;
  highlightedProducts: Array<Product>;
};


export type ContentDescriptionArgs = {
  where?: Maybe<DescriptionWhereInput>;
  orderBy?: Maybe<Array<DescriptionOrderByWithRelationInput>>;
  cursor?: Maybe<DescriptionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<DescriptionScalarFieldEnum>>;
};


export type ContentHighlightedProductsArgs = {
  where?: Maybe<ProductWhereInput>;
  orderBy?: Maybe<Array<ProductOrderByWithRelationInput>>;
  cursor?: Maybe<ProductWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<ProductScalarFieldEnum>>;
};

export type ContentCreateManyBlogCategoryInput = {
  id?: Maybe<Scalars['String']>;
  backgroundImage?: Maybe<Scalars['String']>;
  theme?: Maybe<ThemeType>;
  videos?: Maybe<Scalars['String']>;
  embedCode?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  contentType: ContentType;
  mediaType: MediaType;
  productCategoryType?: Maybe<ProductCategoryListType>;
  lineUp?: Maybe<ContentLineup>;
  source?: Maybe<Scalars['String']>;
  cards?: Maybe<Scalars['JSON']>;
  slides?: Maybe<Scalars['JSON']>;
  timeline?: Maybe<Scalars['JSON']>;
  order?: Maybe<Scalars['Int']>;
  pageId?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  blogPostId?: Maybe<Scalars['String']>;
  productCategoryId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  photos?: Maybe<ContentCreateManyphotosInput>;
};

export type ContentCreateManyBlogCategoryInputEnvelope = {
  data: Array<ContentCreateManyBlogCategoryInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ContentCreateManyBlogPostInput = {
  id?: Maybe<Scalars['String']>;
  backgroundImage?: Maybe<Scalars['String']>;
  theme?: Maybe<ThemeType>;
  videos?: Maybe<Scalars['String']>;
  embedCode?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  contentType: ContentType;
  mediaType: MediaType;
  productCategoryType?: Maybe<ProductCategoryListType>;
  lineUp?: Maybe<ContentLineup>;
  source?: Maybe<Scalars['String']>;
  cards?: Maybe<Scalars['JSON']>;
  slides?: Maybe<Scalars['JSON']>;
  timeline?: Maybe<Scalars['JSON']>;
  order?: Maybe<Scalars['Int']>;
  pageId?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  productCategoryId?: Maybe<Scalars['String']>;
  blogCategoryId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  photos?: Maybe<ContentCreateManyphotosInput>;
};

export type ContentCreateManyBlogPostInputEnvelope = {
  data: Array<ContentCreateManyBlogPostInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ContentCreateManyPageInput = {
  id?: Maybe<Scalars['String']>;
  backgroundImage?: Maybe<Scalars['String']>;
  theme?: Maybe<ThemeType>;
  videos?: Maybe<Scalars['String']>;
  embedCode?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  contentType: ContentType;
  mediaType: MediaType;
  productCategoryType?: Maybe<ProductCategoryListType>;
  lineUp?: Maybe<ContentLineup>;
  source?: Maybe<Scalars['String']>;
  cards?: Maybe<Scalars['JSON']>;
  slides?: Maybe<Scalars['JSON']>;
  timeline?: Maybe<Scalars['JSON']>;
  order?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['String']>;
  blogPostId?: Maybe<Scalars['String']>;
  productCategoryId?: Maybe<Scalars['String']>;
  blogCategoryId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  photos?: Maybe<ContentCreateManyphotosInput>;
};

export type ContentCreateManyPageInputEnvelope = {
  data: Array<ContentCreateManyPageInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ContentCreateManyProductCategoryInput = {
  id?: Maybe<Scalars['String']>;
  backgroundImage?: Maybe<Scalars['String']>;
  theme?: Maybe<ThemeType>;
  videos?: Maybe<Scalars['String']>;
  embedCode?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  contentType: ContentType;
  mediaType: MediaType;
  productCategoryType?: Maybe<ProductCategoryListType>;
  lineUp?: Maybe<ContentLineup>;
  source?: Maybe<Scalars['String']>;
  cards?: Maybe<Scalars['JSON']>;
  slides?: Maybe<Scalars['JSON']>;
  timeline?: Maybe<Scalars['JSON']>;
  order?: Maybe<Scalars['Int']>;
  pageId?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  blogPostId?: Maybe<Scalars['String']>;
  blogCategoryId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  photos?: Maybe<ContentCreateManyphotosInput>;
};

export type ContentCreateManyProductCategoryInputEnvelope = {
  data: Array<ContentCreateManyProductCategoryInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ContentCreateManyProductInput = {
  id?: Maybe<Scalars['String']>;
  backgroundImage?: Maybe<Scalars['String']>;
  theme?: Maybe<ThemeType>;
  videos?: Maybe<Scalars['String']>;
  embedCode?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  contentType: ContentType;
  mediaType: MediaType;
  productCategoryType?: Maybe<ProductCategoryListType>;
  lineUp?: Maybe<ContentLineup>;
  source?: Maybe<Scalars['String']>;
  cards?: Maybe<Scalars['JSON']>;
  slides?: Maybe<Scalars['JSON']>;
  timeline?: Maybe<Scalars['JSON']>;
  order?: Maybe<Scalars['Int']>;
  pageId?: Maybe<Scalars['String']>;
  blogPostId?: Maybe<Scalars['String']>;
  productCategoryId?: Maybe<Scalars['String']>;
  blogCategoryId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  photos?: Maybe<ContentCreateManyphotosInput>;
};

export type ContentCreateManyProductInputEnvelope = {
  data: Array<ContentCreateManyProductInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ContentCreateManyphotosInput = {
  set: Array<Scalars['String']>;
};

export type ContentCreateNestedManyWithoutBlogCategoryInput = {
  create?: Maybe<Array<ContentCreateWithoutBlogCategoryInput>>;
  connectOrCreate?: Maybe<Array<ContentCreateOrConnectWithoutBlogCategoryInput>>;
  createMany?: Maybe<ContentCreateManyBlogCategoryInputEnvelope>;
  connect?: Maybe<Array<ContentWhereUniqueInput>>;
};

export type ContentCreateNestedManyWithoutBlogPostInput = {
  create?: Maybe<Array<ContentCreateWithoutBlogPostInput>>;
  connectOrCreate?: Maybe<Array<ContentCreateOrConnectWithoutBlogPostInput>>;
  createMany?: Maybe<ContentCreateManyBlogPostInputEnvelope>;
  connect?: Maybe<Array<ContentWhereUniqueInput>>;
};

export type ContentCreateNestedManyWithoutHighlightedProductsInput = {
  create?: Maybe<Array<ContentCreateWithoutHighlightedProductsInput>>;
  connectOrCreate?: Maybe<Array<ContentCreateOrConnectWithoutHighlightedProductsInput>>;
  connect?: Maybe<Array<ContentWhereUniqueInput>>;
};

export type ContentCreateNestedManyWithoutPageInput = {
  create?: Maybe<Array<ContentCreateWithoutPageInput>>;
  connectOrCreate?: Maybe<Array<ContentCreateOrConnectWithoutPageInput>>;
  createMany?: Maybe<ContentCreateManyPageInputEnvelope>;
  connect?: Maybe<Array<ContentWhereUniqueInput>>;
};

export type ContentCreateNestedManyWithoutProductCategoryInput = {
  create?: Maybe<Array<ContentCreateWithoutProductCategoryInput>>;
  connectOrCreate?: Maybe<Array<ContentCreateOrConnectWithoutProductCategoryInput>>;
  createMany?: Maybe<ContentCreateManyProductCategoryInputEnvelope>;
  connect?: Maybe<Array<ContentWhereUniqueInput>>;
};

export type ContentCreateNestedManyWithoutProductInput = {
  create?: Maybe<Array<ContentCreateWithoutProductInput>>;
  connectOrCreate?: Maybe<Array<ContentCreateOrConnectWithoutProductInput>>;
  createMany?: Maybe<ContentCreateManyProductInputEnvelope>;
  connect?: Maybe<Array<ContentWhereUniqueInput>>;
};

export type ContentCreateNestedOneWithoutDescriptionInput = {
  create?: Maybe<ContentCreateWithoutDescriptionInput>;
  connectOrCreate?: Maybe<ContentCreateOrConnectWithoutDescriptionInput>;
  connect?: Maybe<ContentWhereUniqueInput>;
};

export type ContentCreateOrConnectWithoutBlogCategoryInput = {
  where: ContentWhereUniqueInput;
  create: ContentCreateWithoutBlogCategoryInput;
};

export type ContentCreateOrConnectWithoutBlogPostInput = {
  where: ContentWhereUniqueInput;
  create: ContentCreateWithoutBlogPostInput;
};

export type ContentCreateOrConnectWithoutDescriptionInput = {
  where: ContentWhereUniqueInput;
  create: ContentCreateWithoutDescriptionInput;
};

export type ContentCreateOrConnectWithoutHighlightedProductsInput = {
  where: ContentWhereUniqueInput;
  create: ContentCreateWithoutHighlightedProductsInput;
};

export type ContentCreateOrConnectWithoutPageInput = {
  where: ContentWhereUniqueInput;
  create: ContentCreateWithoutPageInput;
};

export type ContentCreateOrConnectWithoutProductCategoryInput = {
  where: ContentWhereUniqueInput;
  create: ContentCreateWithoutProductCategoryInput;
};

export type ContentCreateOrConnectWithoutProductInput = {
  where: ContentWhereUniqueInput;
  create: ContentCreateWithoutProductInput;
};

export type ContentCreateWithoutBlogCategoryInput = {
  id?: Maybe<Scalars['String']>;
  backgroundImage?: Maybe<Scalars['String']>;
  theme?: Maybe<ThemeType>;
  videos?: Maybe<Scalars['String']>;
  embedCode?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  contentType: ContentType;
  mediaType: MediaType;
  productCategoryType?: Maybe<ProductCategoryListType>;
  lineUp?: Maybe<ContentLineup>;
  source?: Maybe<Scalars['String']>;
  cards?: Maybe<Scalars['JSON']>;
  slides?: Maybe<Scalars['JSON']>;
  timeline?: Maybe<Scalars['JSON']>;
  order?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  photos?: Maybe<ContentCreatephotosInput>;
  description?: Maybe<DescriptionCreateNestedManyWithoutContentInput>;
  page?: Maybe<PageCreateNestedOneWithoutContentInput>;
  product?: Maybe<ProductCreateNestedOneWithoutContentInput>;
  blogPost?: Maybe<BlogPostCreateNestedOneWithoutContentInput>;
  productCategory?: Maybe<CategoryCreateNestedOneWithoutContentInput>;
  highlightedProducts?: Maybe<ProductCreateNestedManyWithoutHighlightedContentsInput>;
};

export type ContentCreateWithoutBlogPostInput = {
  id?: Maybe<Scalars['String']>;
  backgroundImage?: Maybe<Scalars['String']>;
  theme?: Maybe<ThemeType>;
  videos?: Maybe<Scalars['String']>;
  embedCode?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  contentType: ContentType;
  mediaType: MediaType;
  productCategoryType?: Maybe<ProductCategoryListType>;
  lineUp?: Maybe<ContentLineup>;
  source?: Maybe<Scalars['String']>;
  cards?: Maybe<Scalars['JSON']>;
  slides?: Maybe<Scalars['JSON']>;
  timeline?: Maybe<Scalars['JSON']>;
  order?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  photos?: Maybe<ContentCreatephotosInput>;
  description?: Maybe<DescriptionCreateNestedManyWithoutContentInput>;
  page?: Maybe<PageCreateNestedOneWithoutContentInput>;
  product?: Maybe<ProductCreateNestedOneWithoutContentInput>;
  productCategory?: Maybe<CategoryCreateNestedOneWithoutContentInput>;
  blogCategory?: Maybe<BlogCategoryCreateNestedOneWithoutContentInput>;
  highlightedProducts?: Maybe<ProductCreateNestedManyWithoutHighlightedContentsInput>;
};

export type ContentCreateWithoutDescriptionInput = {
  id?: Maybe<Scalars['String']>;
  backgroundImage?: Maybe<Scalars['String']>;
  theme?: Maybe<ThemeType>;
  videos?: Maybe<Scalars['String']>;
  embedCode?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  contentType: ContentType;
  mediaType: MediaType;
  productCategoryType?: Maybe<ProductCategoryListType>;
  lineUp?: Maybe<ContentLineup>;
  source?: Maybe<Scalars['String']>;
  cards?: Maybe<Scalars['JSON']>;
  slides?: Maybe<Scalars['JSON']>;
  timeline?: Maybe<Scalars['JSON']>;
  order?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  photos?: Maybe<ContentCreatephotosInput>;
  page?: Maybe<PageCreateNestedOneWithoutContentInput>;
  product?: Maybe<ProductCreateNestedOneWithoutContentInput>;
  blogPost?: Maybe<BlogPostCreateNestedOneWithoutContentInput>;
  productCategory?: Maybe<CategoryCreateNestedOneWithoutContentInput>;
  blogCategory?: Maybe<BlogCategoryCreateNestedOneWithoutContentInput>;
  highlightedProducts?: Maybe<ProductCreateNestedManyWithoutHighlightedContentsInput>;
};

export type ContentCreateWithoutHighlightedProductsInput = {
  id?: Maybe<Scalars['String']>;
  backgroundImage?: Maybe<Scalars['String']>;
  theme?: Maybe<ThemeType>;
  videos?: Maybe<Scalars['String']>;
  embedCode?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  contentType: ContentType;
  mediaType: MediaType;
  productCategoryType?: Maybe<ProductCategoryListType>;
  lineUp?: Maybe<ContentLineup>;
  source?: Maybe<Scalars['String']>;
  cards?: Maybe<Scalars['JSON']>;
  slides?: Maybe<Scalars['JSON']>;
  timeline?: Maybe<Scalars['JSON']>;
  order?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  photos?: Maybe<ContentCreatephotosInput>;
  description?: Maybe<DescriptionCreateNestedManyWithoutContentInput>;
  page?: Maybe<PageCreateNestedOneWithoutContentInput>;
  product?: Maybe<ProductCreateNestedOneWithoutContentInput>;
  blogPost?: Maybe<BlogPostCreateNestedOneWithoutContentInput>;
  productCategory?: Maybe<CategoryCreateNestedOneWithoutContentInput>;
  blogCategory?: Maybe<BlogCategoryCreateNestedOneWithoutContentInput>;
};

export type ContentCreateWithoutPageInput = {
  id?: Maybe<Scalars['String']>;
  backgroundImage?: Maybe<Scalars['String']>;
  theme?: Maybe<ThemeType>;
  videos?: Maybe<Scalars['String']>;
  embedCode?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  contentType: ContentType;
  mediaType: MediaType;
  productCategoryType?: Maybe<ProductCategoryListType>;
  lineUp?: Maybe<ContentLineup>;
  source?: Maybe<Scalars['String']>;
  cards?: Maybe<Scalars['JSON']>;
  slides?: Maybe<Scalars['JSON']>;
  timeline?: Maybe<Scalars['JSON']>;
  order?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  photos?: Maybe<ContentCreatephotosInput>;
  description?: Maybe<DescriptionCreateNestedManyWithoutContentInput>;
  product?: Maybe<ProductCreateNestedOneWithoutContentInput>;
  blogPost?: Maybe<BlogPostCreateNestedOneWithoutContentInput>;
  productCategory?: Maybe<CategoryCreateNestedOneWithoutContentInput>;
  blogCategory?: Maybe<BlogCategoryCreateNestedOneWithoutContentInput>;
  highlightedProducts?: Maybe<ProductCreateNestedManyWithoutHighlightedContentsInput>;
};

export type ContentCreateWithoutProductCategoryInput = {
  id?: Maybe<Scalars['String']>;
  backgroundImage?: Maybe<Scalars['String']>;
  theme?: Maybe<ThemeType>;
  videos?: Maybe<Scalars['String']>;
  embedCode?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  contentType: ContentType;
  mediaType: MediaType;
  productCategoryType?: Maybe<ProductCategoryListType>;
  lineUp?: Maybe<ContentLineup>;
  source?: Maybe<Scalars['String']>;
  cards?: Maybe<Scalars['JSON']>;
  slides?: Maybe<Scalars['JSON']>;
  timeline?: Maybe<Scalars['JSON']>;
  order?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  photos?: Maybe<ContentCreatephotosInput>;
  description?: Maybe<DescriptionCreateNestedManyWithoutContentInput>;
  page?: Maybe<PageCreateNestedOneWithoutContentInput>;
  product?: Maybe<ProductCreateNestedOneWithoutContentInput>;
  blogPost?: Maybe<BlogPostCreateNestedOneWithoutContentInput>;
  blogCategory?: Maybe<BlogCategoryCreateNestedOneWithoutContentInput>;
  highlightedProducts?: Maybe<ProductCreateNestedManyWithoutHighlightedContentsInput>;
};

export type ContentCreateWithoutProductInput = {
  id?: Maybe<Scalars['String']>;
  backgroundImage?: Maybe<Scalars['String']>;
  theme?: Maybe<ThemeType>;
  videos?: Maybe<Scalars['String']>;
  embedCode?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  contentType: ContentType;
  mediaType: MediaType;
  productCategoryType?: Maybe<ProductCategoryListType>;
  lineUp?: Maybe<ContentLineup>;
  source?: Maybe<Scalars['String']>;
  cards?: Maybe<Scalars['JSON']>;
  slides?: Maybe<Scalars['JSON']>;
  timeline?: Maybe<Scalars['JSON']>;
  order?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  photos?: Maybe<ContentCreatephotosInput>;
  description?: Maybe<DescriptionCreateNestedManyWithoutContentInput>;
  page?: Maybe<PageCreateNestedOneWithoutContentInput>;
  blogPost?: Maybe<BlogPostCreateNestedOneWithoutContentInput>;
  productCategory?: Maybe<CategoryCreateNestedOneWithoutContentInput>;
  blogCategory?: Maybe<BlogCategoryCreateNestedOneWithoutContentInput>;
  highlightedProducts?: Maybe<ProductCreateNestedManyWithoutHighlightedContentsInput>;
};

export type ContentCreatephotosInput = {
  set: Array<Scalars['String']>;
};

export enum ContentLineup {
  HORIZONTAL = 'HORIZONTAL',
  VERTICAL = 'VERTICAL',
  SLIDER = 'SLIDER'
}

export type ContentListRelationFilter = {
  every?: Maybe<ContentWhereInput>;
  some?: Maybe<ContentWhereInput>;
  none?: Maybe<ContentWhereInput>;
};

export type ContentOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type ContentOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  description?: Maybe<DescriptionOrderByRelationAggregateInput>;
  page?: Maybe<PageOrderByWithRelationInput>;
  backgroundImage?: Maybe<SortOrder>;
  theme?: Maybe<SortOrder>;
  photos?: Maybe<SortOrder>;
  videos?: Maybe<SortOrder>;
  embedCode?: Maybe<SortOrder>;
  longitude?: Maybe<SortOrder>;
  latitude?: Maybe<SortOrder>;
  contentType?: Maybe<SortOrder>;
  mediaType?: Maybe<SortOrder>;
  productCategoryType?: Maybe<SortOrder>;
  lineUp?: Maybe<SortOrder>;
  source?: Maybe<SortOrder>;
  cards?: Maybe<SortOrder>;
  slides?: Maybe<SortOrder>;
  timeline?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  pageId?: Maybe<SortOrder>;
  product?: Maybe<ProductOrderByWithRelationInput>;
  productId?: Maybe<SortOrder>;
  blogPost?: Maybe<BlogPostOrderByWithRelationInput>;
  blogPostId?: Maybe<SortOrder>;
  productCategory?: Maybe<CategoryOrderByWithRelationInput>;
  productCategoryId?: Maybe<SortOrder>;
  blogCategory?: Maybe<BlogCategoryOrderByWithRelationInput>;
  blogCategoryId?: Maybe<SortOrder>;
  highlightedProducts?: Maybe<ProductOrderByRelationAggregateInput>;
  updatedAt?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
};

export type ContentRelationFilter = {
  is?: Maybe<ContentWhereInput>;
  isNot?: Maybe<ContentWhereInput>;
};

export enum ContentScalarFieldEnum {
  id = 'id',
  backgroundImage = 'backgroundImage',
  theme = 'theme',
  photos = 'photos',
  videos = 'videos',
  embedCode = 'embedCode',
  longitude = 'longitude',
  latitude = 'latitude',
  contentType = 'contentType',
  mediaType = 'mediaType',
  productCategoryType = 'productCategoryType',
  lineUp = 'lineUp',
  source = 'source',
  cards = 'cards',
  slides = 'slides',
  timeline = 'timeline',
  order = 'order',
  pageId = 'pageId',
  productId = 'productId',
  blogPostId = 'blogPostId',
  productCategoryId = 'productCategoryId',
  blogCategoryId = 'blogCategoryId',
  updatedAt = 'updatedAt',
  createdAt = 'createdAt'
}

export type ContentScalarWhereInput = {
  AND?: Maybe<Array<ContentScalarWhereInput>>;
  OR?: Maybe<Array<ContentScalarWhereInput>>;
  NOT?: Maybe<Array<ContentScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  backgroundImage?: Maybe<StringNullableFilter>;
  theme?: Maybe<EnumThemeTypeFilter>;
  photos?: Maybe<StringNullableListFilter>;
  videos?: Maybe<StringNullableFilter>;
  embedCode?: Maybe<StringNullableFilter>;
  longitude?: Maybe<FloatNullableFilter>;
  latitude?: Maybe<FloatNullableFilter>;
  contentType?: Maybe<EnumContentTypeFilter>;
  mediaType?: Maybe<EnumMediaTypeFilter>;
  productCategoryType?: Maybe<EnumProductCategoryListTypeFilter>;
  lineUp?: Maybe<EnumContentLineupFilter>;
  source?: Maybe<StringNullableFilter>;
  cards?: Maybe<JsonNullableFilter>;
  slides?: Maybe<JsonNullableFilter>;
  timeline?: Maybe<JsonNullableFilter>;
  order?: Maybe<IntFilter>;
  pageId?: Maybe<StringNullableFilter>;
  productId?: Maybe<StringNullableFilter>;
  blogPostId?: Maybe<StringNullableFilter>;
  productCategoryId?: Maybe<StringNullableFilter>;
  blogCategoryId?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  createdAt?: Maybe<DateTimeFilter>;
};

export enum ContentType {
  TEXT = 'TEXT',
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
  MAP = 'MAP',
  CARD = 'CARD',
  FORMIO = 'FORMIO',
  SLIDESHOW = 'SLIDESHOW',
  LATEST_NEWS = 'LATEST_NEWS',
  FORM = 'FORM',
  BRANDS = 'BRANDS',
  PRODUCT_CATEGORY = 'PRODUCT_CATEGORY',
  BRANDS_WORLDMAP = 'BRANDS_WORLDMAP',
  HEROIMAGE = 'HEROIMAGE',
  CALENDAR = 'CALENDAR',
  HIGHLIGHTED_PRODUCTS = 'HIGHLIGHTED_PRODUCTS',
  TIMELINE = 'TIMELINE',
  FAQ = 'FAQ',
  READER_COMMENTS = 'READER_COMMENTS',
  YT_VIDEOS = 'YT_VIDEOS'
}

export type ContentUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  backgroundImage?: Maybe<NullableStringFieldUpdateOperationsInput>;
  theme?: Maybe<EnumThemeTypeFieldUpdateOperationsInput>;
  videos?: Maybe<NullableStringFieldUpdateOperationsInput>;
  embedCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  longitude?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  latitude?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  contentType?: Maybe<EnumContentTypeFieldUpdateOperationsInput>;
  mediaType?: Maybe<EnumMediaTypeFieldUpdateOperationsInput>;
  productCategoryType?: Maybe<EnumProductCategoryListTypeFieldUpdateOperationsInput>;
  lineUp?: Maybe<EnumContentLineupFieldUpdateOperationsInput>;
  source?: Maybe<NullableStringFieldUpdateOperationsInput>;
  cards?: Maybe<Scalars['JSON']>;
  slides?: Maybe<Scalars['JSON']>;
  timeline?: Maybe<Scalars['JSON']>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  photos?: Maybe<ContentUpdatephotosInput>;
};

export type ContentUpdateManyWithWhereWithoutBlogCategoryInput = {
  where: ContentScalarWhereInput;
  data: ContentUpdateManyMutationInput;
};

export type ContentUpdateManyWithWhereWithoutBlogPostInput = {
  where: ContentScalarWhereInput;
  data: ContentUpdateManyMutationInput;
};

export type ContentUpdateManyWithWhereWithoutHighlightedProductsInput = {
  where: ContentScalarWhereInput;
  data: ContentUpdateManyMutationInput;
};

export type ContentUpdateManyWithWhereWithoutPageInput = {
  where: ContentScalarWhereInput;
  data: ContentUpdateManyMutationInput;
};

export type ContentUpdateManyWithWhereWithoutProductCategoryInput = {
  where: ContentScalarWhereInput;
  data: ContentUpdateManyMutationInput;
};

export type ContentUpdateManyWithWhereWithoutProductInput = {
  where: ContentScalarWhereInput;
  data: ContentUpdateManyMutationInput;
};

export type ContentUpdateManyWithoutBlogCategoryInput = {
  create?: Maybe<Array<ContentCreateWithoutBlogCategoryInput>>;
  connectOrCreate?: Maybe<Array<ContentCreateOrConnectWithoutBlogCategoryInput>>;
  upsert?: Maybe<Array<ContentUpsertWithWhereUniqueWithoutBlogCategoryInput>>;
  createMany?: Maybe<ContentCreateManyBlogCategoryInputEnvelope>;
  connect?: Maybe<Array<ContentWhereUniqueInput>>;
  set?: Maybe<Array<ContentWhereUniqueInput>>;
  disconnect?: Maybe<Array<ContentWhereUniqueInput>>;
  delete?: Maybe<Array<ContentWhereUniqueInput>>;
  update?: Maybe<Array<ContentUpdateWithWhereUniqueWithoutBlogCategoryInput>>;
  updateMany?: Maybe<Array<ContentUpdateManyWithWhereWithoutBlogCategoryInput>>;
  deleteMany?: Maybe<Array<ContentScalarWhereInput>>;
};

export type ContentUpdateManyWithoutBlogPostInput = {
  create?: Maybe<Array<ContentCreateWithoutBlogPostInput>>;
  connectOrCreate?: Maybe<Array<ContentCreateOrConnectWithoutBlogPostInput>>;
  upsert?: Maybe<Array<ContentUpsertWithWhereUniqueWithoutBlogPostInput>>;
  createMany?: Maybe<ContentCreateManyBlogPostInputEnvelope>;
  connect?: Maybe<Array<ContentWhereUniqueInput>>;
  set?: Maybe<Array<ContentWhereUniqueInput>>;
  disconnect?: Maybe<Array<ContentWhereUniqueInput>>;
  delete?: Maybe<Array<ContentWhereUniqueInput>>;
  update?: Maybe<Array<ContentUpdateWithWhereUniqueWithoutBlogPostInput>>;
  updateMany?: Maybe<Array<ContentUpdateManyWithWhereWithoutBlogPostInput>>;
  deleteMany?: Maybe<Array<ContentScalarWhereInput>>;
};

export type ContentUpdateManyWithoutHighlightedProductsInput = {
  create?: Maybe<Array<ContentCreateWithoutHighlightedProductsInput>>;
  connectOrCreate?: Maybe<Array<ContentCreateOrConnectWithoutHighlightedProductsInput>>;
  upsert?: Maybe<Array<ContentUpsertWithWhereUniqueWithoutHighlightedProductsInput>>;
  connect?: Maybe<Array<ContentWhereUniqueInput>>;
  set?: Maybe<Array<ContentWhereUniqueInput>>;
  disconnect?: Maybe<Array<ContentWhereUniqueInput>>;
  delete?: Maybe<Array<ContentWhereUniqueInput>>;
  update?: Maybe<Array<ContentUpdateWithWhereUniqueWithoutHighlightedProductsInput>>;
  updateMany?: Maybe<Array<ContentUpdateManyWithWhereWithoutHighlightedProductsInput>>;
  deleteMany?: Maybe<Array<ContentScalarWhereInput>>;
};

export type ContentUpdateManyWithoutPageInput = {
  create?: Maybe<Array<ContentCreateWithoutPageInput>>;
  connectOrCreate?: Maybe<Array<ContentCreateOrConnectWithoutPageInput>>;
  upsert?: Maybe<Array<ContentUpsertWithWhereUniqueWithoutPageInput>>;
  createMany?: Maybe<ContentCreateManyPageInputEnvelope>;
  connect?: Maybe<Array<ContentWhereUniqueInput>>;
  set?: Maybe<Array<ContentWhereUniqueInput>>;
  disconnect?: Maybe<Array<ContentWhereUniqueInput>>;
  delete?: Maybe<Array<ContentWhereUniqueInput>>;
  update?: Maybe<Array<ContentUpdateWithWhereUniqueWithoutPageInput>>;
  updateMany?: Maybe<Array<ContentUpdateManyWithWhereWithoutPageInput>>;
  deleteMany?: Maybe<Array<ContentScalarWhereInput>>;
};

export type ContentUpdateManyWithoutProductCategoryInput = {
  create?: Maybe<Array<ContentCreateWithoutProductCategoryInput>>;
  connectOrCreate?: Maybe<Array<ContentCreateOrConnectWithoutProductCategoryInput>>;
  upsert?: Maybe<Array<ContentUpsertWithWhereUniqueWithoutProductCategoryInput>>;
  createMany?: Maybe<ContentCreateManyProductCategoryInputEnvelope>;
  connect?: Maybe<Array<ContentWhereUniqueInput>>;
  set?: Maybe<Array<ContentWhereUniqueInput>>;
  disconnect?: Maybe<Array<ContentWhereUniqueInput>>;
  delete?: Maybe<Array<ContentWhereUniqueInput>>;
  update?: Maybe<Array<ContentUpdateWithWhereUniqueWithoutProductCategoryInput>>;
  updateMany?: Maybe<Array<ContentUpdateManyWithWhereWithoutProductCategoryInput>>;
  deleteMany?: Maybe<Array<ContentScalarWhereInput>>;
};

export type ContentUpdateManyWithoutProductInput = {
  create?: Maybe<Array<ContentCreateWithoutProductInput>>;
  connectOrCreate?: Maybe<Array<ContentCreateOrConnectWithoutProductInput>>;
  upsert?: Maybe<Array<ContentUpsertWithWhereUniqueWithoutProductInput>>;
  createMany?: Maybe<ContentCreateManyProductInputEnvelope>;
  connect?: Maybe<Array<ContentWhereUniqueInput>>;
  set?: Maybe<Array<ContentWhereUniqueInput>>;
  disconnect?: Maybe<Array<ContentWhereUniqueInput>>;
  delete?: Maybe<Array<ContentWhereUniqueInput>>;
  update?: Maybe<Array<ContentUpdateWithWhereUniqueWithoutProductInput>>;
  updateMany?: Maybe<Array<ContentUpdateManyWithWhereWithoutProductInput>>;
  deleteMany?: Maybe<Array<ContentScalarWhereInput>>;
};

export type ContentUpdateOneWithoutDescriptionInput = {
  create?: Maybe<ContentCreateWithoutDescriptionInput>;
  connectOrCreate?: Maybe<ContentCreateOrConnectWithoutDescriptionInput>;
  upsert?: Maybe<ContentUpsertWithoutDescriptionInput>;
  connect?: Maybe<ContentWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ContentUpdateWithoutDescriptionInput>;
};

export type ContentUpdateWithWhereUniqueWithoutBlogCategoryInput = {
  where: ContentWhereUniqueInput;
  data: ContentUpdateWithoutBlogCategoryInput;
};

export type ContentUpdateWithWhereUniqueWithoutBlogPostInput = {
  where: ContentWhereUniqueInput;
  data: ContentUpdateWithoutBlogPostInput;
};

export type ContentUpdateWithWhereUniqueWithoutHighlightedProductsInput = {
  where: ContentWhereUniqueInput;
  data: ContentUpdateWithoutHighlightedProductsInput;
};

export type ContentUpdateWithWhereUniqueWithoutPageInput = {
  where: ContentWhereUniqueInput;
  data: ContentUpdateWithoutPageInput;
};

export type ContentUpdateWithWhereUniqueWithoutProductCategoryInput = {
  where: ContentWhereUniqueInput;
  data: ContentUpdateWithoutProductCategoryInput;
};

export type ContentUpdateWithWhereUniqueWithoutProductInput = {
  where: ContentWhereUniqueInput;
  data: ContentUpdateWithoutProductInput;
};

export type ContentUpdateWithoutBlogCategoryInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  backgroundImage?: Maybe<NullableStringFieldUpdateOperationsInput>;
  theme?: Maybe<EnumThemeTypeFieldUpdateOperationsInput>;
  videos?: Maybe<NullableStringFieldUpdateOperationsInput>;
  embedCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  longitude?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  latitude?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  contentType?: Maybe<EnumContentTypeFieldUpdateOperationsInput>;
  mediaType?: Maybe<EnumMediaTypeFieldUpdateOperationsInput>;
  productCategoryType?: Maybe<EnumProductCategoryListTypeFieldUpdateOperationsInput>;
  lineUp?: Maybe<EnumContentLineupFieldUpdateOperationsInput>;
  source?: Maybe<NullableStringFieldUpdateOperationsInput>;
  cards?: Maybe<Scalars['JSON']>;
  slides?: Maybe<Scalars['JSON']>;
  timeline?: Maybe<Scalars['JSON']>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  photos?: Maybe<ContentUpdatephotosInput>;
  description?: Maybe<DescriptionUpdateManyWithoutContentInput>;
  page?: Maybe<PageUpdateOneWithoutContentInput>;
  product?: Maybe<ProductUpdateOneWithoutContentInput>;
  blogPost?: Maybe<BlogPostUpdateOneWithoutContentInput>;
  productCategory?: Maybe<CategoryUpdateOneWithoutContentInput>;
  highlightedProducts?: Maybe<ProductUpdateManyWithoutHighlightedContentsInput>;
};

export type ContentUpdateWithoutBlogPostInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  backgroundImage?: Maybe<NullableStringFieldUpdateOperationsInput>;
  theme?: Maybe<EnumThemeTypeFieldUpdateOperationsInput>;
  videos?: Maybe<NullableStringFieldUpdateOperationsInput>;
  embedCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  longitude?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  latitude?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  contentType?: Maybe<EnumContentTypeFieldUpdateOperationsInput>;
  mediaType?: Maybe<EnumMediaTypeFieldUpdateOperationsInput>;
  productCategoryType?: Maybe<EnumProductCategoryListTypeFieldUpdateOperationsInput>;
  lineUp?: Maybe<EnumContentLineupFieldUpdateOperationsInput>;
  source?: Maybe<NullableStringFieldUpdateOperationsInput>;
  cards?: Maybe<Scalars['JSON']>;
  slides?: Maybe<Scalars['JSON']>;
  timeline?: Maybe<Scalars['JSON']>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  photos?: Maybe<ContentUpdatephotosInput>;
  description?: Maybe<DescriptionUpdateManyWithoutContentInput>;
  page?: Maybe<PageUpdateOneWithoutContentInput>;
  product?: Maybe<ProductUpdateOneWithoutContentInput>;
  productCategory?: Maybe<CategoryUpdateOneWithoutContentInput>;
  blogCategory?: Maybe<BlogCategoryUpdateOneWithoutContentInput>;
  highlightedProducts?: Maybe<ProductUpdateManyWithoutHighlightedContentsInput>;
};

export type ContentUpdateWithoutDescriptionInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  backgroundImage?: Maybe<NullableStringFieldUpdateOperationsInput>;
  theme?: Maybe<EnumThemeTypeFieldUpdateOperationsInput>;
  videos?: Maybe<NullableStringFieldUpdateOperationsInput>;
  embedCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  longitude?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  latitude?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  contentType?: Maybe<EnumContentTypeFieldUpdateOperationsInput>;
  mediaType?: Maybe<EnumMediaTypeFieldUpdateOperationsInput>;
  productCategoryType?: Maybe<EnumProductCategoryListTypeFieldUpdateOperationsInput>;
  lineUp?: Maybe<EnumContentLineupFieldUpdateOperationsInput>;
  source?: Maybe<NullableStringFieldUpdateOperationsInput>;
  cards?: Maybe<Scalars['JSON']>;
  slides?: Maybe<Scalars['JSON']>;
  timeline?: Maybe<Scalars['JSON']>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  photos?: Maybe<ContentUpdatephotosInput>;
  page?: Maybe<PageUpdateOneWithoutContentInput>;
  product?: Maybe<ProductUpdateOneWithoutContentInput>;
  blogPost?: Maybe<BlogPostUpdateOneWithoutContentInput>;
  productCategory?: Maybe<CategoryUpdateOneWithoutContentInput>;
  blogCategory?: Maybe<BlogCategoryUpdateOneWithoutContentInput>;
  highlightedProducts?: Maybe<ProductUpdateManyWithoutHighlightedContentsInput>;
};

export type ContentUpdateWithoutHighlightedProductsInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  backgroundImage?: Maybe<NullableStringFieldUpdateOperationsInput>;
  theme?: Maybe<EnumThemeTypeFieldUpdateOperationsInput>;
  videos?: Maybe<NullableStringFieldUpdateOperationsInput>;
  embedCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  longitude?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  latitude?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  contentType?: Maybe<EnumContentTypeFieldUpdateOperationsInput>;
  mediaType?: Maybe<EnumMediaTypeFieldUpdateOperationsInput>;
  productCategoryType?: Maybe<EnumProductCategoryListTypeFieldUpdateOperationsInput>;
  lineUp?: Maybe<EnumContentLineupFieldUpdateOperationsInput>;
  source?: Maybe<NullableStringFieldUpdateOperationsInput>;
  cards?: Maybe<Scalars['JSON']>;
  slides?: Maybe<Scalars['JSON']>;
  timeline?: Maybe<Scalars['JSON']>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  photos?: Maybe<ContentUpdatephotosInput>;
  description?: Maybe<DescriptionUpdateManyWithoutContentInput>;
  page?: Maybe<PageUpdateOneWithoutContentInput>;
  product?: Maybe<ProductUpdateOneWithoutContentInput>;
  blogPost?: Maybe<BlogPostUpdateOneWithoutContentInput>;
  productCategory?: Maybe<CategoryUpdateOneWithoutContentInput>;
  blogCategory?: Maybe<BlogCategoryUpdateOneWithoutContentInput>;
};

export type ContentUpdateWithoutPageInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  backgroundImage?: Maybe<NullableStringFieldUpdateOperationsInput>;
  theme?: Maybe<EnumThemeTypeFieldUpdateOperationsInput>;
  videos?: Maybe<NullableStringFieldUpdateOperationsInput>;
  embedCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  longitude?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  latitude?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  contentType?: Maybe<EnumContentTypeFieldUpdateOperationsInput>;
  mediaType?: Maybe<EnumMediaTypeFieldUpdateOperationsInput>;
  productCategoryType?: Maybe<EnumProductCategoryListTypeFieldUpdateOperationsInput>;
  lineUp?: Maybe<EnumContentLineupFieldUpdateOperationsInput>;
  source?: Maybe<NullableStringFieldUpdateOperationsInput>;
  cards?: Maybe<Scalars['JSON']>;
  slides?: Maybe<Scalars['JSON']>;
  timeline?: Maybe<Scalars['JSON']>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  photos?: Maybe<ContentUpdatephotosInput>;
  description?: Maybe<DescriptionUpdateManyWithoutContentInput>;
  product?: Maybe<ProductUpdateOneWithoutContentInput>;
  blogPost?: Maybe<BlogPostUpdateOneWithoutContentInput>;
  productCategory?: Maybe<CategoryUpdateOneWithoutContentInput>;
  blogCategory?: Maybe<BlogCategoryUpdateOneWithoutContentInput>;
  highlightedProducts?: Maybe<ProductUpdateManyWithoutHighlightedContentsInput>;
};

export type ContentUpdateWithoutProductCategoryInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  backgroundImage?: Maybe<NullableStringFieldUpdateOperationsInput>;
  theme?: Maybe<EnumThemeTypeFieldUpdateOperationsInput>;
  videos?: Maybe<NullableStringFieldUpdateOperationsInput>;
  embedCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  longitude?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  latitude?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  contentType?: Maybe<EnumContentTypeFieldUpdateOperationsInput>;
  mediaType?: Maybe<EnumMediaTypeFieldUpdateOperationsInput>;
  productCategoryType?: Maybe<EnumProductCategoryListTypeFieldUpdateOperationsInput>;
  lineUp?: Maybe<EnumContentLineupFieldUpdateOperationsInput>;
  source?: Maybe<NullableStringFieldUpdateOperationsInput>;
  cards?: Maybe<Scalars['JSON']>;
  slides?: Maybe<Scalars['JSON']>;
  timeline?: Maybe<Scalars['JSON']>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  photos?: Maybe<ContentUpdatephotosInput>;
  description?: Maybe<DescriptionUpdateManyWithoutContentInput>;
  page?: Maybe<PageUpdateOneWithoutContentInput>;
  product?: Maybe<ProductUpdateOneWithoutContentInput>;
  blogPost?: Maybe<BlogPostUpdateOneWithoutContentInput>;
  blogCategory?: Maybe<BlogCategoryUpdateOneWithoutContentInput>;
  highlightedProducts?: Maybe<ProductUpdateManyWithoutHighlightedContentsInput>;
};

export type ContentUpdateWithoutProductInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  backgroundImage?: Maybe<NullableStringFieldUpdateOperationsInput>;
  theme?: Maybe<EnumThemeTypeFieldUpdateOperationsInput>;
  videos?: Maybe<NullableStringFieldUpdateOperationsInput>;
  embedCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  longitude?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  latitude?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  contentType?: Maybe<EnumContentTypeFieldUpdateOperationsInput>;
  mediaType?: Maybe<EnumMediaTypeFieldUpdateOperationsInput>;
  productCategoryType?: Maybe<EnumProductCategoryListTypeFieldUpdateOperationsInput>;
  lineUp?: Maybe<EnumContentLineupFieldUpdateOperationsInput>;
  source?: Maybe<NullableStringFieldUpdateOperationsInput>;
  cards?: Maybe<Scalars['JSON']>;
  slides?: Maybe<Scalars['JSON']>;
  timeline?: Maybe<Scalars['JSON']>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  photos?: Maybe<ContentUpdatephotosInput>;
  description?: Maybe<DescriptionUpdateManyWithoutContentInput>;
  page?: Maybe<PageUpdateOneWithoutContentInput>;
  blogPost?: Maybe<BlogPostUpdateOneWithoutContentInput>;
  productCategory?: Maybe<CategoryUpdateOneWithoutContentInput>;
  blogCategory?: Maybe<BlogCategoryUpdateOneWithoutContentInput>;
  highlightedProducts?: Maybe<ProductUpdateManyWithoutHighlightedContentsInput>;
};

export type ContentUpdatephotosInput = {
  set?: Maybe<Array<Scalars['String']>>;
  push?: Maybe<Array<Scalars['String']>>;
};

export type ContentUpsertWithWhereUniqueWithoutBlogCategoryInput = {
  where: ContentWhereUniqueInput;
  update: ContentUpdateWithoutBlogCategoryInput;
  create: ContentCreateWithoutBlogCategoryInput;
};

export type ContentUpsertWithWhereUniqueWithoutBlogPostInput = {
  where: ContentWhereUniqueInput;
  update: ContentUpdateWithoutBlogPostInput;
  create: ContentCreateWithoutBlogPostInput;
};

export type ContentUpsertWithWhereUniqueWithoutHighlightedProductsInput = {
  where: ContentWhereUniqueInput;
  update: ContentUpdateWithoutHighlightedProductsInput;
  create: ContentCreateWithoutHighlightedProductsInput;
};

export type ContentUpsertWithWhereUniqueWithoutPageInput = {
  where: ContentWhereUniqueInput;
  update: ContentUpdateWithoutPageInput;
  create: ContentCreateWithoutPageInput;
};

export type ContentUpsertWithWhereUniqueWithoutProductCategoryInput = {
  where: ContentWhereUniqueInput;
  update: ContentUpdateWithoutProductCategoryInput;
  create: ContentCreateWithoutProductCategoryInput;
};

export type ContentUpsertWithWhereUniqueWithoutProductInput = {
  where: ContentWhereUniqueInput;
  update: ContentUpdateWithoutProductInput;
  create: ContentCreateWithoutProductInput;
};

export type ContentUpsertWithoutDescriptionInput = {
  update: ContentUpdateWithoutDescriptionInput;
  create: ContentCreateWithoutDescriptionInput;
};

export type ContentWhereInput = {
  AND?: Maybe<Array<ContentWhereInput>>;
  OR?: Maybe<Array<ContentWhereInput>>;
  NOT?: Maybe<Array<ContentWhereInput>>;
  id?: Maybe<StringFilter>;
  description?: Maybe<DescriptionListRelationFilter>;
  page?: Maybe<PageRelationFilter>;
  backgroundImage?: Maybe<StringNullableFilter>;
  theme?: Maybe<EnumThemeTypeFilter>;
  photos?: Maybe<StringNullableListFilter>;
  videos?: Maybe<StringNullableFilter>;
  embedCode?: Maybe<StringNullableFilter>;
  longitude?: Maybe<FloatNullableFilter>;
  latitude?: Maybe<FloatNullableFilter>;
  contentType?: Maybe<EnumContentTypeFilter>;
  mediaType?: Maybe<EnumMediaTypeFilter>;
  productCategoryType?: Maybe<EnumProductCategoryListTypeFilter>;
  lineUp?: Maybe<EnumContentLineupFilter>;
  source?: Maybe<StringNullableFilter>;
  cards?: Maybe<JsonNullableFilter>;
  slides?: Maybe<JsonNullableFilter>;
  timeline?: Maybe<JsonNullableFilter>;
  order?: Maybe<IntFilter>;
  pageId?: Maybe<StringNullableFilter>;
  product?: Maybe<ProductRelationFilter>;
  productId?: Maybe<StringNullableFilter>;
  blogPost?: Maybe<BlogPostRelationFilter>;
  blogPostId?: Maybe<StringNullableFilter>;
  productCategory?: Maybe<CategoryRelationFilter>;
  productCategoryId?: Maybe<StringNullableFilter>;
  blogCategory?: Maybe<BlogCategoryRelationFilter>;
  blogCategoryId?: Maybe<StringNullableFilter>;
  highlightedProducts?: Maybe<ProductListRelationFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  createdAt?: Maybe<DateTimeFilter>;
};

export type ContentWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type Country = {
  __typename?: 'Country';
  id: Scalars['String'];
  title: Scalars['String'];
  alpha2Code: Scalars['String'];
  alpha3Code: Scalars['String'];
  numericCode: Scalars['String'];
  states: Array<State>;
  cities: Array<City>;
  brands: Array<Brand>;
};


export type CountryStatesArgs = {
  where?: Maybe<StateWhereInput>;
  orderBy?: Maybe<Array<StateOrderByWithRelationInput>>;
  cursor?: Maybe<StateWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<StateScalarFieldEnum>>;
};


export type CountryCitiesArgs = {
  where?: Maybe<CityWhereInput>;
  orderBy?: Maybe<Array<CityOrderByWithRelationInput>>;
  cursor?: Maybe<CityWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<CityScalarFieldEnum>>;
};


export type CountryBrandsArgs = {
  where?: Maybe<BrandWhereInput>;
  orderBy?: Maybe<Array<BrandOrderByWithRelationInput>>;
  cursor?: Maybe<BrandWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<BrandScalarFieldEnum>>;
};

export type CountryCreateNestedManyWithoutBrandsInput = {
  create?: Maybe<Array<CountryCreateWithoutBrandsInput>>;
  connectOrCreate?: Maybe<Array<CountryCreateOrConnectWithoutBrandsInput>>;
  connect?: Maybe<Array<CountryWhereUniqueInput>>;
};

export type CountryCreateOrConnectWithoutBrandsInput = {
  where: CountryWhereUniqueInput;
  create: CountryCreateWithoutBrandsInput;
};

export type CountryCreateWithoutBrandsInput = {
  id?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  alpha2Code: Scalars['String'];
  alpha3Code: Scalars['String'];
  numericCode: Scalars['String'];
  states?: Maybe<StateCreateNestedManyWithoutCountryInput>;
  cities?: Maybe<CityCreateNestedManyWithoutCountryInput>;
};

export type CountryListRelationFilter = {
  every?: Maybe<CountryWhereInput>;
  some?: Maybe<CountryWhereInput>;
  none?: Maybe<CountryWhereInput>;
};

export type CountryOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type CountryOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  alpha2Code?: Maybe<SortOrder>;
  alpha3Code?: Maybe<SortOrder>;
  numericCode?: Maybe<SortOrder>;
  states?: Maybe<StateOrderByRelationAggregateInput>;
  cities?: Maybe<CityOrderByRelationAggregateInput>;
  brands?: Maybe<BrandOrderByRelationAggregateInput>;
};

export type CountryRelationFilter = {
  is?: Maybe<CountryWhereInput>;
  isNot?: Maybe<CountryWhereInput>;
};

export enum CountryScalarFieldEnum {
  id = 'id',
  title = 'title',
  alpha2Code = 'alpha2Code',
  alpha3Code = 'alpha3Code',
  numericCode = 'numericCode'
}

export type CountryScalarWhereInput = {
  AND?: Maybe<Array<CountryScalarWhereInput>>;
  OR?: Maybe<Array<CountryScalarWhereInput>>;
  NOT?: Maybe<Array<CountryScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  title?: Maybe<StringFilter>;
  alpha2Code?: Maybe<StringFilter>;
  alpha3Code?: Maybe<StringFilter>;
  numericCode?: Maybe<StringFilter>;
};

export type CountryUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  title?: Maybe<StringFieldUpdateOperationsInput>;
  alpha2Code?: Maybe<StringFieldUpdateOperationsInput>;
  alpha3Code?: Maybe<StringFieldUpdateOperationsInput>;
  numericCode?: Maybe<StringFieldUpdateOperationsInput>;
};

export type CountryUpdateManyWithWhereWithoutBrandsInput = {
  where: CountryScalarWhereInput;
  data: CountryUpdateManyMutationInput;
};

export type CountryUpdateManyWithoutBrandsInput = {
  create?: Maybe<Array<CountryCreateWithoutBrandsInput>>;
  connectOrCreate?: Maybe<Array<CountryCreateOrConnectWithoutBrandsInput>>;
  upsert?: Maybe<Array<CountryUpsertWithWhereUniqueWithoutBrandsInput>>;
  connect?: Maybe<Array<CountryWhereUniqueInput>>;
  set?: Maybe<Array<CountryWhereUniqueInput>>;
  disconnect?: Maybe<Array<CountryWhereUniqueInput>>;
  delete?: Maybe<Array<CountryWhereUniqueInput>>;
  update?: Maybe<Array<CountryUpdateWithWhereUniqueWithoutBrandsInput>>;
  updateMany?: Maybe<Array<CountryUpdateManyWithWhereWithoutBrandsInput>>;
  deleteMany?: Maybe<Array<CountryScalarWhereInput>>;
};

export type CountryUpdateWithWhereUniqueWithoutBrandsInput = {
  where: CountryWhereUniqueInput;
  data: CountryUpdateWithoutBrandsInput;
};

export type CountryUpdateWithoutBrandsInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  title?: Maybe<StringFieldUpdateOperationsInput>;
  alpha2Code?: Maybe<StringFieldUpdateOperationsInput>;
  alpha3Code?: Maybe<StringFieldUpdateOperationsInput>;
  numericCode?: Maybe<StringFieldUpdateOperationsInput>;
  states?: Maybe<StateUpdateManyWithoutCountryInput>;
  cities?: Maybe<CityUpdateManyWithoutCountryInput>;
};

export type CountryUpsertWithWhereUniqueWithoutBrandsInput = {
  where: CountryWhereUniqueInput;
  update: CountryUpdateWithoutBrandsInput;
  create: CountryCreateWithoutBrandsInput;
};

export type CountryWhereInput = {
  AND?: Maybe<Array<CountryWhereInput>>;
  OR?: Maybe<Array<CountryWhereInput>>;
  NOT?: Maybe<Array<CountryWhereInput>>;
  id?: Maybe<StringFilter>;
  title?: Maybe<StringFilter>;
  alpha2Code?: Maybe<StringFilter>;
  alpha3Code?: Maybe<StringFilter>;
  numericCode?: Maybe<StringFilter>;
  states?: Maybe<StateListRelationFilter>;
  cities?: Maybe<CityListRelationFilter>;
  brands?: Maybe<BrandListRelationFilter>;
};

export type CountryWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type CustomBlogPostPayload = {
  __typename?: 'CustomBlogPostPayload';
  total?: Maybe<Scalars['Int']>;
  posts: Array<BlogPost>;
};

export type CustomContentCreateInput = {
  id?: Maybe<Scalars['String']>;
  backgroundImage?: Maybe<Scalars['String']>;
  theme?: Maybe<ThemeType>;
  videos?: Maybe<Scalars['String']>;
  embedCode?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  contentType: ContentType;
  mediaType: MediaType;
  productCategoryType?: Maybe<ProductCategoryListType>;
  lineUp?: Maybe<ContentLineup>;
  source?: Maybe<Scalars['String']>;
  cards?: Maybe<Scalars['JSON']>;
  slides?: Maybe<Scalars['JSON']>;
  timeline?: Maybe<Scalars['JSON']>;
  order?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  photos?: Maybe<ContentCreatephotosInput>;
  description?: Maybe<DescriptionCreateNestedManyWithoutContentInput>;
  page?: Maybe<PageCreateNestedOneWithoutContentInput>;
  product?: Maybe<ProductCreateNestedOneWithoutContentInput>;
  blogPost?: Maybe<BlogPostCreateNestedOneWithoutContentInput>;
  productCategory?: Maybe<CategoryCreateNestedOneWithoutContentInput>;
  blogCategory?: Maybe<BlogCategoryCreateNestedOneWithoutContentInput>;
  highlightedProducts?: Maybe<ProductCreateNestedManyWithoutHighlightedContentsInput>;
  productCategoryId?: Maybe<Scalars['String']>;
  blogCategoryId?: Maybe<Scalars['String']>;
  products?: Maybe<Array<IdField>>;
};

export type CustomContentUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  backgroundImage?: Maybe<NullableStringFieldUpdateOperationsInput>;
  theme?: Maybe<EnumThemeTypeFieldUpdateOperationsInput>;
  videos?: Maybe<NullableStringFieldUpdateOperationsInput>;
  embedCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  longitude?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  latitude?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  contentType?: Maybe<EnumContentTypeFieldUpdateOperationsInput>;
  mediaType?: Maybe<EnumMediaTypeFieldUpdateOperationsInput>;
  productCategoryType?: Maybe<EnumProductCategoryListTypeFieldUpdateOperationsInput>;
  lineUp?: Maybe<EnumContentLineupFieldUpdateOperationsInput>;
  source?: Maybe<NullableStringFieldUpdateOperationsInput>;
  cards?: Maybe<Scalars['JSON']>;
  slides?: Maybe<Scalars['JSON']>;
  timeline?: Maybe<Scalars['JSON']>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  photos?: Maybe<ContentUpdatephotosInput>;
  description?: Maybe<DescriptionUpdateManyWithoutContentInput>;
  page?: Maybe<PageUpdateOneWithoutContentInput>;
  product?: Maybe<ProductUpdateOneWithoutContentInput>;
  blogPost?: Maybe<BlogPostUpdateOneWithoutContentInput>;
  productCategory?: Maybe<CategoryUpdateOneWithoutContentInput>;
  blogCategory?: Maybe<BlogCategoryUpdateOneWithoutContentInput>;
  highlightedProducts?: Maybe<ProductUpdateManyWithoutHighlightedContentsInput>;
  productCategoryId?: Maybe<Scalars['String']>;
  blogCategoryId?: Maybe<Scalars['String']>;
  products?: Maybe<Array<IdField>>;
};

export type CustomData = {
  __typename?: 'CustomData';
  id: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  liveVideoUrl?: Maybe<Scalars['String']>;
};

export type CustomDataAvgAggregate = {
  __typename?: 'CustomDataAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type CustomDataCountAggregate = {
  __typename?: 'CustomDataCountAggregate';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  liveVideoUrl: Scalars['Int'];
  _all: Scalars['Int'];
};

export type CustomDataCreateInput = {
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  liveVideoUrl?: Maybe<Scalars['String']>;
};

export type CustomDataCreateManyInput = {
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  liveVideoUrl?: Maybe<Scalars['String']>;
};

export type CustomDataGroupBy = {
  __typename?: 'CustomDataGroupBy';
  id: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  liveVideoUrl?: Maybe<Scalars['String']>;
  _count?: Maybe<CustomDataCountAggregate>;
  _avg?: Maybe<CustomDataAvgAggregate>;
  _sum?: Maybe<CustomDataSumAggregate>;
  _min?: Maybe<CustomDataMinAggregate>;
  _max?: Maybe<CustomDataMaxAggregate>;
};

export type CustomDataMaxAggregate = {
  __typename?: 'CustomDataMaxAggregate';
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  liveVideoUrl?: Maybe<Scalars['String']>;
};

export type CustomDataMinAggregate = {
  __typename?: 'CustomDataMinAggregate';
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  liveVideoUrl?: Maybe<Scalars['String']>;
};

export type CustomDataOrderByInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  liveVideoUrl?: Maybe<SortOrder>;
};

export type CustomDataOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  liveVideoUrl?: Maybe<SortOrder>;
};

export enum CustomDataScalarFieldEnum {
  id = 'id',
  createdAt = 'createdAt',
  updatedAt = 'updatedAt',
  liveVideoUrl = 'liveVideoUrl'
}

export type CustomDataScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<CustomDataScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<CustomDataScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<CustomDataScalarWhereWithAggregatesInput>>;
  id?: Maybe<IntWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  liveVideoUrl?: Maybe<StringNullableWithAggregatesFilter>;
};

export type CustomDataSumAggregate = {
  __typename?: 'CustomDataSumAggregate';
  id?: Maybe<Scalars['Int']>;
};

export type CustomDataUpdateInput = {
  id?: Maybe<IntFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  liveVideoUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type CustomDataUpdateManyMutationInput = {
  id?: Maybe<IntFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  liveVideoUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type CustomDataWhereInput = {
  AND?: Maybe<Array<CustomDataWhereInput>>;
  OR?: Maybe<Array<CustomDataWhereInput>>;
  NOT?: Maybe<Array<CustomDataWhereInput>>;
  id?: Maybe<IntFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  liveVideoUrl?: Maybe<StringNullableFilter>;
};

export type CustomDataWhereUniqueInput = {
  id?: Maybe<Scalars['Int']>;
};

export type CustomForms = {
  __typename?: 'CustomForms';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  name: Scalars['String'];
  surname: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  fieldOne?: Maybe<Scalars['String']>;
  fieldTwo?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  formType?: Maybe<Scalars['Int']>;
  isRead: Scalars['Boolean'];
  productId?: Maybe<Scalars['String']>;
  product?: Maybe<Product>;
};

export type CustomFormsCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  surname: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  fieldOne?: Maybe<Scalars['String']>;
  fieldTwo?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  formType?: Maybe<Scalars['Int']>;
  isRead?: Maybe<Scalars['Boolean']>;
  product?: Maybe<ProductCreateNestedOneWithoutMessagesInput>;
};

export type CustomFormsCreateManyProductInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  surname: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  fieldOne?: Maybe<Scalars['String']>;
  fieldTwo?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  formType?: Maybe<Scalars['Int']>;
  isRead?: Maybe<Scalars['Boolean']>;
};

export type CustomFormsCreateManyProductInputEnvelope = {
  data: Array<CustomFormsCreateManyProductInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CustomFormsCreateNestedManyWithoutProductInput = {
  create?: Maybe<Array<CustomFormsCreateWithoutProductInput>>;
  connectOrCreate?: Maybe<Array<CustomFormsCreateOrConnectWithoutProductInput>>;
  createMany?: Maybe<CustomFormsCreateManyProductInputEnvelope>;
  connect?: Maybe<Array<CustomFormsWhereUniqueInput>>;
};

export type CustomFormsCreateOrConnectWithoutProductInput = {
  where: CustomFormsWhereUniqueInput;
  create: CustomFormsCreateWithoutProductInput;
};

export type CustomFormsCreateWithoutProductInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  surname: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  fieldOne?: Maybe<Scalars['String']>;
  fieldTwo?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  formType?: Maybe<Scalars['Int']>;
  isRead?: Maybe<Scalars['Boolean']>;
};

export type CustomFormsListRelationFilter = {
  every?: Maybe<CustomFormsWhereInput>;
  some?: Maybe<CustomFormsWhereInput>;
  none?: Maybe<CustomFormsWhereInput>;
};

export type CustomFormsOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type CustomFormsOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  surname?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  companyName?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  message?: Maybe<SortOrder>;
  fieldOne?: Maybe<SortOrder>;
  fieldTwo?: Maybe<SortOrder>;
  department?: Maybe<SortOrder>;
  formType?: Maybe<SortOrder>;
  isRead?: Maybe<SortOrder>;
  product?: Maybe<ProductOrderByWithRelationInput>;
  productId?: Maybe<SortOrder>;
};

export enum CustomFormsScalarFieldEnum {
  id = 'id',
  createdAt = 'createdAt',
  updatedAt = 'updatedAt',
  name = 'name',
  surname = 'surname',
  phoneNumber = 'phoneNumber',
  companyName = 'companyName',
  email = 'email',
  message = 'message',
  fieldOne = 'fieldOne',
  fieldTwo = 'fieldTwo',
  department = 'department',
  formType = 'formType',
  isRead = 'isRead',
  productId = 'productId'
}

export type CustomFormsScalarWhereInput = {
  AND?: Maybe<Array<CustomFormsScalarWhereInput>>;
  OR?: Maybe<Array<CustomFormsScalarWhereInput>>;
  NOT?: Maybe<Array<CustomFormsScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  name?: Maybe<StringFilter>;
  surname?: Maybe<StringFilter>;
  phoneNumber?: Maybe<StringNullableFilter>;
  companyName?: Maybe<StringNullableFilter>;
  email?: Maybe<StringNullableFilter>;
  message?: Maybe<StringFilter>;
  fieldOne?: Maybe<StringNullableFilter>;
  fieldTwo?: Maybe<StringNullableFilter>;
  department?: Maybe<StringNullableFilter>;
  formType?: Maybe<IntNullableFilter>;
  isRead?: Maybe<BoolFilter>;
  productId?: Maybe<StringNullableFilter>;
};

export type CustomFormsUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  surname?: Maybe<StringFieldUpdateOperationsInput>;
  phoneNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  companyName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  message?: Maybe<StringFieldUpdateOperationsInput>;
  fieldOne?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fieldTwo?: Maybe<NullableStringFieldUpdateOperationsInput>;
  department?: Maybe<NullableStringFieldUpdateOperationsInput>;
  formType?: Maybe<NullableIntFieldUpdateOperationsInput>;
  isRead?: Maybe<BoolFieldUpdateOperationsInput>;
  product?: Maybe<ProductUpdateOneWithoutMessagesInput>;
};

export type CustomFormsUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  surname?: Maybe<StringFieldUpdateOperationsInput>;
  phoneNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  companyName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  message?: Maybe<StringFieldUpdateOperationsInput>;
  fieldOne?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fieldTwo?: Maybe<NullableStringFieldUpdateOperationsInput>;
  department?: Maybe<NullableStringFieldUpdateOperationsInput>;
  formType?: Maybe<NullableIntFieldUpdateOperationsInput>;
  isRead?: Maybe<BoolFieldUpdateOperationsInput>;
};

export type CustomFormsUpdateManyWithWhereWithoutProductInput = {
  where: CustomFormsScalarWhereInput;
  data: CustomFormsUpdateManyMutationInput;
};

export type CustomFormsUpdateManyWithoutProductInput = {
  create?: Maybe<Array<CustomFormsCreateWithoutProductInput>>;
  connectOrCreate?: Maybe<Array<CustomFormsCreateOrConnectWithoutProductInput>>;
  upsert?: Maybe<Array<CustomFormsUpsertWithWhereUniqueWithoutProductInput>>;
  createMany?: Maybe<CustomFormsCreateManyProductInputEnvelope>;
  connect?: Maybe<Array<CustomFormsWhereUniqueInput>>;
  set?: Maybe<Array<CustomFormsWhereUniqueInput>>;
  disconnect?: Maybe<Array<CustomFormsWhereUniqueInput>>;
  delete?: Maybe<Array<CustomFormsWhereUniqueInput>>;
  update?: Maybe<Array<CustomFormsUpdateWithWhereUniqueWithoutProductInput>>;
  updateMany?: Maybe<Array<CustomFormsUpdateManyWithWhereWithoutProductInput>>;
  deleteMany?: Maybe<Array<CustomFormsScalarWhereInput>>;
};

export type CustomFormsUpdateWithWhereUniqueWithoutProductInput = {
  where: CustomFormsWhereUniqueInput;
  data: CustomFormsUpdateWithoutProductInput;
};

export type CustomFormsUpdateWithoutProductInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  surname?: Maybe<StringFieldUpdateOperationsInput>;
  phoneNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  companyName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  message?: Maybe<StringFieldUpdateOperationsInput>;
  fieldOne?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fieldTwo?: Maybe<NullableStringFieldUpdateOperationsInput>;
  department?: Maybe<NullableStringFieldUpdateOperationsInput>;
  formType?: Maybe<NullableIntFieldUpdateOperationsInput>;
  isRead?: Maybe<BoolFieldUpdateOperationsInput>;
};

export type CustomFormsUpsertWithWhereUniqueWithoutProductInput = {
  where: CustomFormsWhereUniqueInput;
  update: CustomFormsUpdateWithoutProductInput;
  create: CustomFormsCreateWithoutProductInput;
};

export type CustomFormsWhereInput = {
  AND?: Maybe<Array<CustomFormsWhereInput>>;
  OR?: Maybe<Array<CustomFormsWhereInput>>;
  NOT?: Maybe<Array<CustomFormsWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  name?: Maybe<StringFilter>;
  surname?: Maybe<StringFilter>;
  phoneNumber?: Maybe<StringNullableFilter>;
  companyName?: Maybe<StringNullableFilter>;
  email?: Maybe<StringNullableFilter>;
  message?: Maybe<StringFilter>;
  fieldOne?: Maybe<StringNullableFilter>;
  fieldTwo?: Maybe<StringNullableFilter>;
  department?: Maybe<StringNullableFilter>;
  formType?: Maybe<IntNullableFilter>;
  isRead?: Maybe<BoolFilter>;
  product?: Maybe<ProductRelationFilter>;
  productId?: Maybe<StringNullableFilter>;
};

export type CustomFormsWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};


export type DateTimeFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['DateTime']>;
};

export type DateTimeFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeFilter>;
};

export type DateTimeNullableFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeNullableFilter>;
};

export type DateTimeNullableWithAggregatesFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedDateTimeNullableFilter>;
  _max?: Maybe<NestedDateTimeNullableFilter>;
};

export type DateTimeWithAggregatesFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedDateTimeFilter>;
  _max?: Maybe<NestedDateTimeFilter>;
};

export type Description = {
  __typename?: 'Description';
  id: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  buttonText?: Maybe<Scalars['String']>;
  buttonUrl?: Maybe<Scalars['String']>;
  language: Scalars['String'];
  metaTitle?: Maybe<Scalars['String']>;
  metaKeywords?: Maybe<Scalars['String']>;
  metaDescription?: Maybe<Scalars['String']>;
  seoPoint?: Maybe<Scalars['Int']>;
  contentId?: Maybe<Scalars['String']>;
  pageId?: Maybe<Scalars['String']>;
  contactId?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  blogCategoryId?: Maybe<Scalars['String']>;
  blogPostId?: Maybe<Scalars['String']>;
  brandId?: Maybe<Scalars['String']>;
  faqId?: Maybe<Scalars['String']>;
  eventId?: Maybe<Scalars['String']>;
  content?: Maybe<Content>;
  page?: Maybe<Page>;
  contact?: Maybe<Contact>;
  category?: Maybe<Category>;
  product?: Maybe<Product>;
  blogCategory?: Maybe<BlogCategory>;
  blogPost?: Maybe<BlogPost>;
  brand?: Maybe<Brand>;
  faq?: Maybe<Faq>;
  event?: Maybe<Event>;
};

export type DescriptionCreateInput = {
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  buttonText?: Maybe<Scalars['String']>;
  buttonUrl?: Maybe<Scalars['String']>;
  language: Scalars['String'];
  metaTitle?: Maybe<Scalars['String']>;
  metaKeywords?: Maybe<Scalars['String']>;
  metaDescription?: Maybe<Scalars['String']>;
  seoPoint?: Maybe<Scalars['Int']>;
  content?: Maybe<ContentCreateNestedOneWithoutDescriptionInput>;
  page?: Maybe<PageCreateNestedOneWithoutDescriptionInput>;
  contact?: Maybe<ContactCreateNestedOneWithoutDescriptionInput>;
  category?: Maybe<CategoryCreateNestedOneWithoutDescriptionsInput>;
  product?: Maybe<ProductCreateNestedOneWithoutDescriptionsInput>;
  blogCategory?: Maybe<BlogCategoryCreateNestedOneWithoutDescriptionInput>;
  blogPost?: Maybe<BlogPostCreateNestedOneWithoutDescriptionInput>;
  brand?: Maybe<BrandCreateNestedOneWithoutDescriptionInput>;
  faq?: Maybe<FaqCreateNestedOneWithoutDescriptionInput>;
  event?: Maybe<EventCreateNestedOneWithoutDescriptionInput>;
};

export type DescriptionCreateManyBlogCategoryInput = {
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  buttonText?: Maybe<Scalars['String']>;
  buttonUrl?: Maybe<Scalars['String']>;
  language: Scalars['String'];
  metaTitle?: Maybe<Scalars['String']>;
  metaKeywords?: Maybe<Scalars['String']>;
  metaDescription?: Maybe<Scalars['String']>;
  seoPoint?: Maybe<Scalars['Int']>;
  contentId?: Maybe<Scalars['String']>;
  pageId?: Maybe<Scalars['String']>;
  contactId?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  blogPostId?: Maybe<Scalars['String']>;
  brandId?: Maybe<Scalars['String']>;
  faqId?: Maybe<Scalars['String']>;
  eventId?: Maybe<Scalars['String']>;
};

export type DescriptionCreateManyBlogCategoryInputEnvelope = {
  data: Array<DescriptionCreateManyBlogCategoryInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type DescriptionCreateManyBlogPostInput = {
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  buttonText?: Maybe<Scalars['String']>;
  buttonUrl?: Maybe<Scalars['String']>;
  language: Scalars['String'];
  metaTitle?: Maybe<Scalars['String']>;
  metaKeywords?: Maybe<Scalars['String']>;
  metaDescription?: Maybe<Scalars['String']>;
  seoPoint?: Maybe<Scalars['Int']>;
  contentId?: Maybe<Scalars['String']>;
  pageId?: Maybe<Scalars['String']>;
  contactId?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  blogCategoryId?: Maybe<Scalars['String']>;
  brandId?: Maybe<Scalars['String']>;
  faqId?: Maybe<Scalars['String']>;
  eventId?: Maybe<Scalars['String']>;
};

export type DescriptionCreateManyBlogPostInputEnvelope = {
  data: Array<DescriptionCreateManyBlogPostInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type DescriptionCreateManyBrandInput = {
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  buttonText?: Maybe<Scalars['String']>;
  buttonUrl?: Maybe<Scalars['String']>;
  language: Scalars['String'];
  metaTitle?: Maybe<Scalars['String']>;
  metaKeywords?: Maybe<Scalars['String']>;
  metaDescription?: Maybe<Scalars['String']>;
  seoPoint?: Maybe<Scalars['Int']>;
  contentId?: Maybe<Scalars['String']>;
  pageId?: Maybe<Scalars['String']>;
  contactId?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  blogCategoryId?: Maybe<Scalars['String']>;
  blogPostId?: Maybe<Scalars['String']>;
  faqId?: Maybe<Scalars['String']>;
  eventId?: Maybe<Scalars['String']>;
};

export type DescriptionCreateManyBrandInputEnvelope = {
  data: Array<DescriptionCreateManyBrandInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type DescriptionCreateManyCategoryInput = {
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  buttonText?: Maybe<Scalars['String']>;
  buttonUrl?: Maybe<Scalars['String']>;
  language: Scalars['String'];
  metaTitle?: Maybe<Scalars['String']>;
  metaKeywords?: Maybe<Scalars['String']>;
  metaDescription?: Maybe<Scalars['String']>;
  seoPoint?: Maybe<Scalars['Int']>;
  contentId?: Maybe<Scalars['String']>;
  pageId?: Maybe<Scalars['String']>;
  contactId?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  blogCategoryId?: Maybe<Scalars['String']>;
  blogPostId?: Maybe<Scalars['String']>;
  brandId?: Maybe<Scalars['String']>;
  faqId?: Maybe<Scalars['String']>;
  eventId?: Maybe<Scalars['String']>;
};

export type DescriptionCreateManyCategoryInputEnvelope = {
  data: Array<DescriptionCreateManyCategoryInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type DescriptionCreateManyContactInput = {
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  buttonText?: Maybe<Scalars['String']>;
  buttonUrl?: Maybe<Scalars['String']>;
  language: Scalars['String'];
  metaTitle?: Maybe<Scalars['String']>;
  metaKeywords?: Maybe<Scalars['String']>;
  metaDescription?: Maybe<Scalars['String']>;
  seoPoint?: Maybe<Scalars['Int']>;
  contentId?: Maybe<Scalars['String']>;
  pageId?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  blogCategoryId?: Maybe<Scalars['String']>;
  blogPostId?: Maybe<Scalars['String']>;
  brandId?: Maybe<Scalars['String']>;
  faqId?: Maybe<Scalars['String']>;
  eventId?: Maybe<Scalars['String']>;
};

export type DescriptionCreateManyContactInputEnvelope = {
  data: Array<DescriptionCreateManyContactInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type DescriptionCreateManyContentInput = {
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  buttonText?: Maybe<Scalars['String']>;
  buttonUrl?: Maybe<Scalars['String']>;
  language: Scalars['String'];
  metaTitle?: Maybe<Scalars['String']>;
  metaKeywords?: Maybe<Scalars['String']>;
  metaDescription?: Maybe<Scalars['String']>;
  seoPoint?: Maybe<Scalars['Int']>;
  pageId?: Maybe<Scalars['String']>;
  contactId?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  blogCategoryId?: Maybe<Scalars['String']>;
  blogPostId?: Maybe<Scalars['String']>;
  brandId?: Maybe<Scalars['String']>;
  faqId?: Maybe<Scalars['String']>;
  eventId?: Maybe<Scalars['String']>;
};

export type DescriptionCreateManyContentInputEnvelope = {
  data: Array<DescriptionCreateManyContentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type DescriptionCreateManyEventInput = {
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  buttonText?: Maybe<Scalars['String']>;
  buttonUrl?: Maybe<Scalars['String']>;
  language: Scalars['String'];
  metaTitle?: Maybe<Scalars['String']>;
  metaKeywords?: Maybe<Scalars['String']>;
  metaDescription?: Maybe<Scalars['String']>;
  seoPoint?: Maybe<Scalars['Int']>;
  contentId?: Maybe<Scalars['String']>;
  pageId?: Maybe<Scalars['String']>;
  contactId?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  blogCategoryId?: Maybe<Scalars['String']>;
  blogPostId?: Maybe<Scalars['String']>;
  brandId?: Maybe<Scalars['String']>;
  faqId?: Maybe<Scalars['String']>;
};

export type DescriptionCreateManyEventInputEnvelope = {
  data: Array<DescriptionCreateManyEventInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type DescriptionCreateManyFaqInput = {
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  buttonText?: Maybe<Scalars['String']>;
  buttonUrl?: Maybe<Scalars['String']>;
  language: Scalars['String'];
  metaTitle?: Maybe<Scalars['String']>;
  metaKeywords?: Maybe<Scalars['String']>;
  metaDescription?: Maybe<Scalars['String']>;
  seoPoint?: Maybe<Scalars['Int']>;
  contentId?: Maybe<Scalars['String']>;
  pageId?: Maybe<Scalars['String']>;
  contactId?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  blogCategoryId?: Maybe<Scalars['String']>;
  blogPostId?: Maybe<Scalars['String']>;
  brandId?: Maybe<Scalars['String']>;
  eventId?: Maybe<Scalars['String']>;
};

export type DescriptionCreateManyFaqInputEnvelope = {
  data: Array<DescriptionCreateManyFaqInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type DescriptionCreateManyPageInput = {
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  buttonText?: Maybe<Scalars['String']>;
  buttonUrl?: Maybe<Scalars['String']>;
  language: Scalars['String'];
  metaTitle?: Maybe<Scalars['String']>;
  metaKeywords?: Maybe<Scalars['String']>;
  metaDescription?: Maybe<Scalars['String']>;
  seoPoint?: Maybe<Scalars['Int']>;
  contentId?: Maybe<Scalars['String']>;
  contactId?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  blogCategoryId?: Maybe<Scalars['String']>;
  blogPostId?: Maybe<Scalars['String']>;
  brandId?: Maybe<Scalars['String']>;
  faqId?: Maybe<Scalars['String']>;
  eventId?: Maybe<Scalars['String']>;
};

export type DescriptionCreateManyPageInputEnvelope = {
  data: Array<DescriptionCreateManyPageInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type DescriptionCreateManyProductInput = {
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  buttonText?: Maybe<Scalars['String']>;
  buttonUrl?: Maybe<Scalars['String']>;
  language: Scalars['String'];
  metaTitle?: Maybe<Scalars['String']>;
  metaKeywords?: Maybe<Scalars['String']>;
  metaDescription?: Maybe<Scalars['String']>;
  seoPoint?: Maybe<Scalars['Int']>;
  contentId?: Maybe<Scalars['String']>;
  pageId?: Maybe<Scalars['String']>;
  contactId?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['String']>;
  blogCategoryId?: Maybe<Scalars['String']>;
  blogPostId?: Maybe<Scalars['String']>;
  brandId?: Maybe<Scalars['String']>;
  faqId?: Maybe<Scalars['String']>;
  eventId?: Maybe<Scalars['String']>;
};

export type DescriptionCreateManyProductInputEnvelope = {
  data: Array<DescriptionCreateManyProductInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type DescriptionCreateNestedManyWithoutBlogCategoryInput = {
  create?: Maybe<Array<DescriptionCreateWithoutBlogCategoryInput>>;
  connectOrCreate?: Maybe<Array<DescriptionCreateOrConnectWithoutBlogCategoryInput>>;
  createMany?: Maybe<DescriptionCreateManyBlogCategoryInputEnvelope>;
  connect?: Maybe<Array<DescriptionWhereUniqueInput>>;
};

export type DescriptionCreateNestedManyWithoutBlogPostInput = {
  create?: Maybe<Array<DescriptionCreateWithoutBlogPostInput>>;
  connectOrCreate?: Maybe<Array<DescriptionCreateOrConnectWithoutBlogPostInput>>;
  createMany?: Maybe<DescriptionCreateManyBlogPostInputEnvelope>;
  connect?: Maybe<Array<DescriptionWhereUniqueInput>>;
};

export type DescriptionCreateNestedManyWithoutBrandInput = {
  create?: Maybe<Array<DescriptionCreateWithoutBrandInput>>;
  connectOrCreate?: Maybe<Array<DescriptionCreateOrConnectWithoutBrandInput>>;
  createMany?: Maybe<DescriptionCreateManyBrandInputEnvelope>;
  connect?: Maybe<Array<DescriptionWhereUniqueInput>>;
};

export type DescriptionCreateNestedManyWithoutCategoryInput = {
  create?: Maybe<Array<DescriptionCreateWithoutCategoryInput>>;
  connectOrCreate?: Maybe<Array<DescriptionCreateOrConnectWithoutCategoryInput>>;
  createMany?: Maybe<DescriptionCreateManyCategoryInputEnvelope>;
  connect?: Maybe<Array<DescriptionWhereUniqueInput>>;
};

export type DescriptionCreateNestedManyWithoutContactInput = {
  create?: Maybe<Array<DescriptionCreateWithoutContactInput>>;
  connectOrCreate?: Maybe<Array<DescriptionCreateOrConnectWithoutContactInput>>;
  createMany?: Maybe<DescriptionCreateManyContactInputEnvelope>;
  connect?: Maybe<Array<DescriptionWhereUniqueInput>>;
};

export type DescriptionCreateNestedManyWithoutContentInput = {
  create?: Maybe<Array<DescriptionCreateWithoutContentInput>>;
  connectOrCreate?: Maybe<Array<DescriptionCreateOrConnectWithoutContentInput>>;
  createMany?: Maybe<DescriptionCreateManyContentInputEnvelope>;
  connect?: Maybe<Array<DescriptionWhereUniqueInput>>;
};

export type DescriptionCreateNestedManyWithoutEventInput = {
  create?: Maybe<Array<DescriptionCreateWithoutEventInput>>;
  connectOrCreate?: Maybe<Array<DescriptionCreateOrConnectWithoutEventInput>>;
  createMany?: Maybe<DescriptionCreateManyEventInputEnvelope>;
  connect?: Maybe<Array<DescriptionWhereUniqueInput>>;
};

export type DescriptionCreateNestedManyWithoutFaqInput = {
  create?: Maybe<Array<DescriptionCreateWithoutFaqInput>>;
  connectOrCreate?: Maybe<Array<DescriptionCreateOrConnectWithoutFaqInput>>;
  createMany?: Maybe<DescriptionCreateManyFaqInputEnvelope>;
  connect?: Maybe<Array<DescriptionWhereUniqueInput>>;
};

export type DescriptionCreateNestedManyWithoutPageInput = {
  create?: Maybe<Array<DescriptionCreateWithoutPageInput>>;
  connectOrCreate?: Maybe<Array<DescriptionCreateOrConnectWithoutPageInput>>;
  createMany?: Maybe<DescriptionCreateManyPageInputEnvelope>;
  connect?: Maybe<Array<DescriptionWhereUniqueInput>>;
};

export type DescriptionCreateNestedManyWithoutProductInput = {
  create?: Maybe<Array<DescriptionCreateWithoutProductInput>>;
  connectOrCreate?: Maybe<Array<DescriptionCreateOrConnectWithoutProductInput>>;
  createMany?: Maybe<DescriptionCreateManyProductInputEnvelope>;
  connect?: Maybe<Array<DescriptionWhereUniqueInput>>;
};

export type DescriptionCreateOrConnectWithoutBlogCategoryInput = {
  where: DescriptionWhereUniqueInput;
  create: DescriptionCreateWithoutBlogCategoryInput;
};

export type DescriptionCreateOrConnectWithoutBlogPostInput = {
  where: DescriptionWhereUniqueInput;
  create: DescriptionCreateWithoutBlogPostInput;
};

export type DescriptionCreateOrConnectWithoutBrandInput = {
  where: DescriptionWhereUniqueInput;
  create: DescriptionCreateWithoutBrandInput;
};

export type DescriptionCreateOrConnectWithoutCategoryInput = {
  where: DescriptionWhereUniqueInput;
  create: DescriptionCreateWithoutCategoryInput;
};

export type DescriptionCreateOrConnectWithoutContactInput = {
  where: DescriptionWhereUniqueInput;
  create: DescriptionCreateWithoutContactInput;
};

export type DescriptionCreateOrConnectWithoutContentInput = {
  where: DescriptionWhereUniqueInput;
  create: DescriptionCreateWithoutContentInput;
};

export type DescriptionCreateOrConnectWithoutEventInput = {
  where: DescriptionWhereUniqueInput;
  create: DescriptionCreateWithoutEventInput;
};

export type DescriptionCreateOrConnectWithoutFaqInput = {
  where: DescriptionWhereUniqueInput;
  create: DescriptionCreateWithoutFaqInput;
};

export type DescriptionCreateOrConnectWithoutPageInput = {
  where: DescriptionWhereUniqueInput;
  create: DescriptionCreateWithoutPageInput;
};

export type DescriptionCreateOrConnectWithoutProductInput = {
  where: DescriptionWhereUniqueInput;
  create: DescriptionCreateWithoutProductInput;
};

export type DescriptionCreateWithoutBlogCategoryInput = {
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  buttonText?: Maybe<Scalars['String']>;
  buttonUrl?: Maybe<Scalars['String']>;
  language: Scalars['String'];
  metaTitle?: Maybe<Scalars['String']>;
  metaKeywords?: Maybe<Scalars['String']>;
  metaDescription?: Maybe<Scalars['String']>;
  seoPoint?: Maybe<Scalars['Int']>;
  content?: Maybe<ContentCreateNestedOneWithoutDescriptionInput>;
  page?: Maybe<PageCreateNestedOneWithoutDescriptionInput>;
  contact?: Maybe<ContactCreateNestedOneWithoutDescriptionInput>;
  category?: Maybe<CategoryCreateNestedOneWithoutDescriptionsInput>;
  product?: Maybe<ProductCreateNestedOneWithoutDescriptionsInput>;
  blogPost?: Maybe<BlogPostCreateNestedOneWithoutDescriptionInput>;
  brand?: Maybe<BrandCreateNestedOneWithoutDescriptionInput>;
  faq?: Maybe<FaqCreateNestedOneWithoutDescriptionInput>;
  event?: Maybe<EventCreateNestedOneWithoutDescriptionInput>;
};

export type DescriptionCreateWithoutBlogPostInput = {
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  buttonText?: Maybe<Scalars['String']>;
  buttonUrl?: Maybe<Scalars['String']>;
  language: Scalars['String'];
  metaTitle?: Maybe<Scalars['String']>;
  metaKeywords?: Maybe<Scalars['String']>;
  metaDescription?: Maybe<Scalars['String']>;
  seoPoint?: Maybe<Scalars['Int']>;
  content?: Maybe<ContentCreateNestedOneWithoutDescriptionInput>;
  page?: Maybe<PageCreateNestedOneWithoutDescriptionInput>;
  contact?: Maybe<ContactCreateNestedOneWithoutDescriptionInput>;
  category?: Maybe<CategoryCreateNestedOneWithoutDescriptionsInput>;
  product?: Maybe<ProductCreateNestedOneWithoutDescriptionsInput>;
  blogCategory?: Maybe<BlogCategoryCreateNestedOneWithoutDescriptionInput>;
  brand?: Maybe<BrandCreateNestedOneWithoutDescriptionInput>;
  faq?: Maybe<FaqCreateNestedOneWithoutDescriptionInput>;
  event?: Maybe<EventCreateNestedOneWithoutDescriptionInput>;
};

export type DescriptionCreateWithoutBrandInput = {
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  buttonText?: Maybe<Scalars['String']>;
  buttonUrl?: Maybe<Scalars['String']>;
  language: Scalars['String'];
  metaTitle?: Maybe<Scalars['String']>;
  metaKeywords?: Maybe<Scalars['String']>;
  metaDescription?: Maybe<Scalars['String']>;
  seoPoint?: Maybe<Scalars['Int']>;
  content?: Maybe<ContentCreateNestedOneWithoutDescriptionInput>;
  page?: Maybe<PageCreateNestedOneWithoutDescriptionInput>;
  contact?: Maybe<ContactCreateNestedOneWithoutDescriptionInput>;
  category?: Maybe<CategoryCreateNestedOneWithoutDescriptionsInput>;
  product?: Maybe<ProductCreateNestedOneWithoutDescriptionsInput>;
  blogCategory?: Maybe<BlogCategoryCreateNestedOneWithoutDescriptionInput>;
  blogPost?: Maybe<BlogPostCreateNestedOneWithoutDescriptionInput>;
  faq?: Maybe<FaqCreateNestedOneWithoutDescriptionInput>;
  event?: Maybe<EventCreateNestedOneWithoutDescriptionInput>;
};

export type DescriptionCreateWithoutCategoryInput = {
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  buttonText?: Maybe<Scalars['String']>;
  buttonUrl?: Maybe<Scalars['String']>;
  language: Scalars['String'];
  metaTitle?: Maybe<Scalars['String']>;
  metaKeywords?: Maybe<Scalars['String']>;
  metaDescription?: Maybe<Scalars['String']>;
  seoPoint?: Maybe<Scalars['Int']>;
  content?: Maybe<ContentCreateNestedOneWithoutDescriptionInput>;
  page?: Maybe<PageCreateNestedOneWithoutDescriptionInput>;
  contact?: Maybe<ContactCreateNestedOneWithoutDescriptionInput>;
  product?: Maybe<ProductCreateNestedOneWithoutDescriptionsInput>;
  blogCategory?: Maybe<BlogCategoryCreateNestedOneWithoutDescriptionInput>;
  blogPost?: Maybe<BlogPostCreateNestedOneWithoutDescriptionInput>;
  brand?: Maybe<BrandCreateNestedOneWithoutDescriptionInput>;
  faq?: Maybe<FaqCreateNestedOneWithoutDescriptionInput>;
  event?: Maybe<EventCreateNestedOneWithoutDescriptionInput>;
};

export type DescriptionCreateWithoutContactInput = {
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  buttonText?: Maybe<Scalars['String']>;
  buttonUrl?: Maybe<Scalars['String']>;
  language: Scalars['String'];
  metaTitle?: Maybe<Scalars['String']>;
  metaKeywords?: Maybe<Scalars['String']>;
  metaDescription?: Maybe<Scalars['String']>;
  seoPoint?: Maybe<Scalars['Int']>;
  content?: Maybe<ContentCreateNestedOneWithoutDescriptionInput>;
  page?: Maybe<PageCreateNestedOneWithoutDescriptionInput>;
  category?: Maybe<CategoryCreateNestedOneWithoutDescriptionsInput>;
  product?: Maybe<ProductCreateNestedOneWithoutDescriptionsInput>;
  blogCategory?: Maybe<BlogCategoryCreateNestedOneWithoutDescriptionInput>;
  blogPost?: Maybe<BlogPostCreateNestedOneWithoutDescriptionInput>;
  brand?: Maybe<BrandCreateNestedOneWithoutDescriptionInput>;
  faq?: Maybe<FaqCreateNestedOneWithoutDescriptionInput>;
  event?: Maybe<EventCreateNestedOneWithoutDescriptionInput>;
};

export type DescriptionCreateWithoutContentInput = {
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  buttonText?: Maybe<Scalars['String']>;
  buttonUrl?: Maybe<Scalars['String']>;
  language: Scalars['String'];
  metaTitle?: Maybe<Scalars['String']>;
  metaKeywords?: Maybe<Scalars['String']>;
  metaDescription?: Maybe<Scalars['String']>;
  seoPoint?: Maybe<Scalars['Int']>;
  page?: Maybe<PageCreateNestedOneWithoutDescriptionInput>;
  contact?: Maybe<ContactCreateNestedOneWithoutDescriptionInput>;
  category?: Maybe<CategoryCreateNestedOneWithoutDescriptionsInput>;
  product?: Maybe<ProductCreateNestedOneWithoutDescriptionsInput>;
  blogCategory?: Maybe<BlogCategoryCreateNestedOneWithoutDescriptionInput>;
  blogPost?: Maybe<BlogPostCreateNestedOneWithoutDescriptionInput>;
  brand?: Maybe<BrandCreateNestedOneWithoutDescriptionInput>;
  faq?: Maybe<FaqCreateNestedOneWithoutDescriptionInput>;
  event?: Maybe<EventCreateNestedOneWithoutDescriptionInput>;
};

export type DescriptionCreateWithoutEventInput = {
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  buttonText?: Maybe<Scalars['String']>;
  buttonUrl?: Maybe<Scalars['String']>;
  language: Scalars['String'];
  metaTitle?: Maybe<Scalars['String']>;
  metaKeywords?: Maybe<Scalars['String']>;
  metaDescription?: Maybe<Scalars['String']>;
  seoPoint?: Maybe<Scalars['Int']>;
  content?: Maybe<ContentCreateNestedOneWithoutDescriptionInput>;
  page?: Maybe<PageCreateNestedOneWithoutDescriptionInput>;
  contact?: Maybe<ContactCreateNestedOneWithoutDescriptionInput>;
  category?: Maybe<CategoryCreateNestedOneWithoutDescriptionsInput>;
  product?: Maybe<ProductCreateNestedOneWithoutDescriptionsInput>;
  blogCategory?: Maybe<BlogCategoryCreateNestedOneWithoutDescriptionInput>;
  blogPost?: Maybe<BlogPostCreateNestedOneWithoutDescriptionInput>;
  brand?: Maybe<BrandCreateNestedOneWithoutDescriptionInput>;
  faq?: Maybe<FaqCreateNestedOneWithoutDescriptionInput>;
};

export type DescriptionCreateWithoutFaqInput = {
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  buttonText?: Maybe<Scalars['String']>;
  buttonUrl?: Maybe<Scalars['String']>;
  language: Scalars['String'];
  metaTitle?: Maybe<Scalars['String']>;
  metaKeywords?: Maybe<Scalars['String']>;
  metaDescription?: Maybe<Scalars['String']>;
  seoPoint?: Maybe<Scalars['Int']>;
  content?: Maybe<ContentCreateNestedOneWithoutDescriptionInput>;
  page?: Maybe<PageCreateNestedOneWithoutDescriptionInput>;
  contact?: Maybe<ContactCreateNestedOneWithoutDescriptionInput>;
  category?: Maybe<CategoryCreateNestedOneWithoutDescriptionsInput>;
  product?: Maybe<ProductCreateNestedOneWithoutDescriptionsInput>;
  blogCategory?: Maybe<BlogCategoryCreateNestedOneWithoutDescriptionInput>;
  blogPost?: Maybe<BlogPostCreateNestedOneWithoutDescriptionInput>;
  brand?: Maybe<BrandCreateNestedOneWithoutDescriptionInput>;
  event?: Maybe<EventCreateNestedOneWithoutDescriptionInput>;
};

export type DescriptionCreateWithoutPageInput = {
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  buttonText?: Maybe<Scalars['String']>;
  buttonUrl?: Maybe<Scalars['String']>;
  language: Scalars['String'];
  metaTitle?: Maybe<Scalars['String']>;
  metaKeywords?: Maybe<Scalars['String']>;
  metaDescription?: Maybe<Scalars['String']>;
  seoPoint?: Maybe<Scalars['Int']>;
  content?: Maybe<ContentCreateNestedOneWithoutDescriptionInput>;
  contact?: Maybe<ContactCreateNestedOneWithoutDescriptionInput>;
  category?: Maybe<CategoryCreateNestedOneWithoutDescriptionsInput>;
  product?: Maybe<ProductCreateNestedOneWithoutDescriptionsInput>;
  blogCategory?: Maybe<BlogCategoryCreateNestedOneWithoutDescriptionInput>;
  blogPost?: Maybe<BlogPostCreateNestedOneWithoutDescriptionInput>;
  brand?: Maybe<BrandCreateNestedOneWithoutDescriptionInput>;
  faq?: Maybe<FaqCreateNestedOneWithoutDescriptionInput>;
  event?: Maybe<EventCreateNestedOneWithoutDescriptionInput>;
};

export type DescriptionCreateWithoutProductInput = {
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  buttonText?: Maybe<Scalars['String']>;
  buttonUrl?: Maybe<Scalars['String']>;
  language: Scalars['String'];
  metaTitle?: Maybe<Scalars['String']>;
  metaKeywords?: Maybe<Scalars['String']>;
  metaDescription?: Maybe<Scalars['String']>;
  seoPoint?: Maybe<Scalars['Int']>;
  content?: Maybe<ContentCreateNestedOneWithoutDescriptionInput>;
  page?: Maybe<PageCreateNestedOneWithoutDescriptionInput>;
  contact?: Maybe<ContactCreateNestedOneWithoutDescriptionInput>;
  category?: Maybe<CategoryCreateNestedOneWithoutDescriptionsInput>;
  blogCategory?: Maybe<BlogCategoryCreateNestedOneWithoutDescriptionInput>;
  blogPost?: Maybe<BlogPostCreateNestedOneWithoutDescriptionInput>;
  brand?: Maybe<BrandCreateNestedOneWithoutDescriptionInput>;
  faq?: Maybe<FaqCreateNestedOneWithoutDescriptionInput>;
  event?: Maybe<EventCreateNestedOneWithoutDescriptionInput>;
};

export type DescriptionListRelationFilter = {
  every?: Maybe<DescriptionWhereInput>;
  some?: Maybe<DescriptionWhereInput>;
  none?: Maybe<DescriptionWhereInput>;
};

export type DescriptionOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type DescriptionOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  shortDescription?: Maybe<SortOrder>;
  slug?: Maybe<SortOrder>;
  buttonText?: Maybe<SortOrder>;
  buttonUrl?: Maybe<SortOrder>;
  language?: Maybe<SortOrder>;
  metaTitle?: Maybe<SortOrder>;
  metaKeywords?: Maybe<SortOrder>;
  metaDescription?: Maybe<SortOrder>;
  seoPoint?: Maybe<SortOrder>;
  content?: Maybe<ContentOrderByWithRelationInput>;
  contentId?: Maybe<SortOrder>;
  page?: Maybe<PageOrderByWithRelationInput>;
  pageId?: Maybe<SortOrder>;
  contact?: Maybe<ContactOrderByWithRelationInput>;
  contactId?: Maybe<SortOrder>;
  category?: Maybe<CategoryOrderByWithRelationInput>;
  categoryId?: Maybe<SortOrder>;
  product?: Maybe<ProductOrderByWithRelationInput>;
  productId?: Maybe<SortOrder>;
  blogCategory?: Maybe<BlogCategoryOrderByWithRelationInput>;
  blogCategoryId?: Maybe<SortOrder>;
  blogPost?: Maybe<BlogPostOrderByWithRelationInput>;
  blogPostId?: Maybe<SortOrder>;
  brand?: Maybe<BrandOrderByWithRelationInput>;
  brandId?: Maybe<SortOrder>;
  faq?: Maybe<FaqOrderByWithRelationInput>;
  faqId?: Maybe<SortOrder>;
  event?: Maybe<EventOrderByWithRelationInput>;
  eventId?: Maybe<SortOrder>;
};

export enum DescriptionScalarFieldEnum {
  id = 'id',
  updatedAt = 'updatedAt',
  createdAt = 'createdAt',
  title = 'title',
  description = 'description',
  shortDescription = 'shortDescription',
  slug = 'slug',
  buttonText = 'buttonText',
  buttonUrl = 'buttonUrl',
  language = 'language',
  metaTitle = 'metaTitle',
  metaKeywords = 'metaKeywords',
  metaDescription = 'metaDescription',
  seoPoint = 'seoPoint',
  contentId = 'contentId',
  pageId = 'pageId',
  contactId = 'contactId',
  categoryId = 'categoryId',
  productId = 'productId',
  blogCategoryId = 'blogCategoryId',
  blogPostId = 'blogPostId',
  brandId = 'brandId',
  faqId = 'faqId',
  eventId = 'eventId'
}

export type DescriptionScalarWhereInput = {
  AND?: Maybe<Array<DescriptionScalarWhereInput>>;
  OR?: Maybe<Array<DescriptionScalarWhereInput>>;
  NOT?: Maybe<Array<DescriptionScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  title?: Maybe<StringNullableFilter>;
  description?: Maybe<StringNullableFilter>;
  shortDescription?: Maybe<StringNullableFilter>;
  slug?: Maybe<StringNullableFilter>;
  buttonText?: Maybe<StringNullableFilter>;
  buttonUrl?: Maybe<StringNullableFilter>;
  language?: Maybe<StringFilter>;
  metaTitle?: Maybe<StringNullableFilter>;
  metaKeywords?: Maybe<StringNullableFilter>;
  metaDescription?: Maybe<StringNullableFilter>;
  seoPoint?: Maybe<IntNullableFilter>;
  contentId?: Maybe<StringNullableFilter>;
  pageId?: Maybe<StringNullableFilter>;
  contactId?: Maybe<StringNullableFilter>;
  categoryId?: Maybe<StringNullableFilter>;
  productId?: Maybe<StringNullableFilter>;
  blogCategoryId?: Maybe<StringNullableFilter>;
  blogPostId?: Maybe<StringNullableFilter>;
  brandId?: Maybe<StringNullableFilter>;
  faqId?: Maybe<StringNullableFilter>;
  eventId?: Maybe<StringNullableFilter>;
};

export type DescriptionUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  title?: Maybe<NullableStringFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  shortDescription?: Maybe<NullableStringFieldUpdateOperationsInput>;
  slug?: Maybe<NullableStringFieldUpdateOperationsInput>;
  buttonText?: Maybe<NullableStringFieldUpdateOperationsInput>;
  buttonUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  language?: Maybe<StringFieldUpdateOperationsInput>;
  metaTitle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  metaKeywords?: Maybe<NullableStringFieldUpdateOperationsInput>;
  metaDescription?: Maybe<NullableStringFieldUpdateOperationsInput>;
  seoPoint?: Maybe<NullableIntFieldUpdateOperationsInput>;
  content?: Maybe<ContentUpdateOneWithoutDescriptionInput>;
  page?: Maybe<PageUpdateOneWithoutDescriptionInput>;
  contact?: Maybe<ContactUpdateOneWithoutDescriptionInput>;
  category?: Maybe<CategoryUpdateOneWithoutDescriptionsInput>;
  product?: Maybe<ProductUpdateOneWithoutDescriptionsInput>;
  blogCategory?: Maybe<BlogCategoryUpdateOneWithoutDescriptionInput>;
  blogPost?: Maybe<BlogPostUpdateOneWithoutDescriptionInput>;
  brand?: Maybe<BrandUpdateOneWithoutDescriptionInput>;
  faq?: Maybe<FaqUpdateOneWithoutDescriptionInput>;
  event?: Maybe<EventUpdateOneWithoutDescriptionInput>;
};

export type DescriptionUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  title?: Maybe<NullableStringFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  shortDescription?: Maybe<NullableStringFieldUpdateOperationsInput>;
  slug?: Maybe<NullableStringFieldUpdateOperationsInput>;
  buttonText?: Maybe<NullableStringFieldUpdateOperationsInput>;
  buttonUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  language?: Maybe<StringFieldUpdateOperationsInput>;
  metaTitle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  metaKeywords?: Maybe<NullableStringFieldUpdateOperationsInput>;
  metaDescription?: Maybe<NullableStringFieldUpdateOperationsInput>;
  seoPoint?: Maybe<NullableIntFieldUpdateOperationsInput>;
};

export type DescriptionUpdateManyWithWhereWithoutBlogCategoryInput = {
  where: DescriptionScalarWhereInput;
  data: DescriptionUpdateManyMutationInput;
};

export type DescriptionUpdateManyWithWhereWithoutBlogPostInput = {
  where: DescriptionScalarWhereInput;
  data: DescriptionUpdateManyMutationInput;
};

export type DescriptionUpdateManyWithWhereWithoutBrandInput = {
  where: DescriptionScalarWhereInput;
  data: DescriptionUpdateManyMutationInput;
};

export type DescriptionUpdateManyWithWhereWithoutCategoryInput = {
  where: DescriptionScalarWhereInput;
  data: DescriptionUpdateManyMutationInput;
};

export type DescriptionUpdateManyWithWhereWithoutContactInput = {
  where: DescriptionScalarWhereInput;
  data: DescriptionUpdateManyMutationInput;
};

export type DescriptionUpdateManyWithWhereWithoutContentInput = {
  where: DescriptionScalarWhereInput;
  data: DescriptionUpdateManyMutationInput;
};

export type DescriptionUpdateManyWithWhereWithoutEventInput = {
  where: DescriptionScalarWhereInput;
  data: DescriptionUpdateManyMutationInput;
};

export type DescriptionUpdateManyWithWhereWithoutFaqInput = {
  where: DescriptionScalarWhereInput;
  data: DescriptionUpdateManyMutationInput;
};

export type DescriptionUpdateManyWithWhereWithoutPageInput = {
  where: DescriptionScalarWhereInput;
  data: DescriptionUpdateManyMutationInput;
};

export type DescriptionUpdateManyWithWhereWithoutProductInput = {
  where: DescriptionScalarWhereInput;
  data: DescriptionUpdateManyMutationInput;
};

export type DescriptionUpdateManyWithoutBlogCategoryInput = {
  create?: Maybe<Array<DescriptionCreateWithoutBlogCategoryInput>>;
  connectOrCreate?: Maybe<Array<DescriptionCreateOrConnectWithoutBlogCategoryInput>>;
  upsert?: Maybe<Array<DescriptionUpsertWithWhereUniqueWithoutBlogCategoryInput>>;
  createMany?: Maybe<DescriptionCreateManyBlogCategoryInputEnvelope>;
  connect?: Maybe<Array<DescriptionWhereUniqueInput>>;
  set?: Maybe<Array<DescriptionWhereUniqueInput>>;
  disconnect?: Maybe<Array<DescriptionWhereUniqueInput>>;
  delete?: Maybe<Array<DescriptionWhereUniqueInput>>;
  update?: Maybe<Array<DescriptionUpdateWithWhereUniqueWithoutBlogCategoryInput>>;
  updateMany?: Maybe<Array<DescriptionUpdateManyWithWhereWithoutBlogCategoryInput>>;
  deleteMany?: Maybe<Array<DescriptionScalarWhereInput>>;
};

export type DescriptionUpdateManyWithoutBlogPostInput = {
  create?: Maybe<Array<DescriptionCreateWithoutBlogPostInput>>;
  connectOrCreate?: Maybe<Array<DescriptionCreateOrConnectWithoutBlogPostInput>>;
  upsert?: Maybe<Array<DescriptionUpsertWithWhereUniqueWithoutBlogPostInput>>;
  createMany?: Maybe<DescriptionCreateManyBlogPostInputEnvelope>;
  connect?: Maybe<Array<DescriptionWhereUniqueInput>>;
  set?: Maybe<Array<DescriptionWhereUniqueInput>>;
  disconnect?: Maybe<Array<DescriptionWhereUniqueInput>>;
  delete?: Maybe<Array<DescriptionWhereUniqueInput>>;
  update?: Maybe<Array<DescriptionUpdateWithWhereUniqueWithoutBlogPostInput>>;
  updateMany?: Maybe<Array<DescriptionUpdateManyWithWhereWithoutBlogPostInput>>;
  deleteMany?: Maybe<Array<DescriptionScalarWhereInput>>;
};

export type DescriptionUpdateManyWithoutBrandInput = {
  create?: Maybe<Array<DescriptionCreateWithoutBrandInput>>;
  connectOrCreate?: Maybe<Array<DescriptionCreateOrConnectWithoutBrandInput>>;
  upsert?: Maybe<Array<DescriptionUpsertWithWhereUniqueWithoutBrandInput>>;
  createMany?: Maybe<DescriptionCreateManyBrandInputEnvelope>;
  connect?: Maybe<Array<DescriptionWhereUniqueInput>>;
  set?: Maybe<Array<DescriptionWhereUniqueInput>>;
  disconnect?: Maybe<Array<DescriptionWhereUniqueInput>>;
  delete?: Maybe<Array<DescriptionWhereUniqueInput>>;
  update?: Maybe<Array<DescriptionUpdateWithWhereUniqueWithoutBrandInput>>;
  updateMany?: Maybe<Array<DescriptionUpdateManyWithWhereWithoutBrandInput>>;
  deleteMany?: Maybe<Array<DescriptionScalarWhereInput>>;
};

export type DescriptionUpdateManyWithoutCategoryInput = {
  create?: Maybe<Array<DescriptionCreateWithoutCategoryInput>>;
  connectOrCreate?: Maybe<Array<DescriptionCreateOrConnectWithoutCategoryInput>>;
  upsert?: Maybe<Array<DescriptionUpsertWithWhereUniqueWithoutCategoryInput>>;
  createMany?: Maybe<DescriptionCreateManyCategoryInputEnvelope>;
  connect?: Maybe<Array<DescriptionWhereUniqueInput>>;
  set?: Maybe<Array<DescriptionWhereUniqueInput>>;
  disconnect?: Maybe<Array<DescriptionWhereUniqueInput>>;
  delete?: Maybe<Array<DescriptionWhereUniqueInput>>;
  update?: Maybe<Array<DescriptionUpdateWithWhereUniqueWithoutCategoryInput>>;
  updateMany?: Maybe<Array<DescriptionUpdateManyWithWhereWithoutCategoryInput>>;
  deleteMany?: Maybe<Array<DescriptionScalarWhereInput>>;
};

export type DescriptionUpdateManyWithoutContactInput = {
  create?: Maybe<Array<DescriptionCreateWithoutContactInput>>;
  connectOrCreate?: Maybe<Array<DescriptionCreateOrConnectWithoutContactInput>>;
  upsert?: Maybe<Array<DescriptionUpsertWithWhereUniqueWithoutContactInput>>;
  createMany?: Maybe<DescriptionCreateManyContactInputEnvelope>;
  connect?: Maybe<Array<DescriptionWhereUniqueInput>>;
  set?: Maybe<Array<DescriptionWhereUniqueInput>>;
  disconnect?: Maybe<Array<DescriptionWhereUniqueInput>>;
  delete?: Maybe<Array<DescriptionWhereUniqueInput>>;
  update?: Maybe<Array<DescriptionUpdateWithWhereUniqueWithoutContactInput>>;
  updateMany?: Maybe<Array<DescriptionUpdateManyWithWhereWithoutContactInput>>;
  deleteMany?: Maybe<Array<DescriptionScalarWhereInput>>;
};

export type DescriptionUpdateManyWithoutContentInput = {
  create?: Maybe<Array<DescriptionCreateWithoutContentInput>>;
  connectOrCreate?: Maybe<Array<DescriptionCreateOrConnectWithoutContentInput>>;
  upsert?: Maybe<Array<DescriptionUpsertWithWhereUniqueWithoutContentInput>>;
  createMany?: Maybe<DescriptionCreateManyContentInputEnvelope>;
  connect?: Maybe<Array<DescriptionWhereUniqueInput>>;
  set?: Maybe<Array<DescriptionWhereUniqueInput>>;
  disconnect?: Maybe<Array<DescriptionWhereUniqueInput>>;
  delete?: Maybe<Array<DescriptionWhereUniqueInput>>;
  update?: Maybe<Array<DescriptionUpdateWithWhereUniqueWithoutContentInput>>;
  updateMany?: Maybe<Array<DescriptionUpdateManyWithWhereWithoutContentInput>>;
  deleteMany?: Maybe<Array<DescriptionScalarWhereInput>>;
};

export type DescriptionUpdateManyWithoutEventInput = {
  create?: Maybe<Array<DescriptionCreateWithoutEventInput>>;
  connectOrCreate?: Maybe<Array<DescriptionCreateOrConnectWithoutEventInput>>;
  upsert?: Maybe<Array<DescriptionUpsertWithWhereUniqueWithoutEventInput>>;
  createMany?: Maybe<DescriptionCreateManyEventInputEnvelope>;
  connect?: Maybe<Array<DescriptionWhereUniqueInput>>;
  set?: Maybe<Array<DescriptionWhereUniqueInput>>;
  disconnect?: Maybe<Array<DescriptionWhereUniqueInput>>;
  delete?: Maybe<Array<DescriptionWhereUniqueInput>>;
  update?: Maybe<Array<DescriptionUpdateWithWhereUniqueWithoutEventInput>>;
  updateMany?: Maybe<Array<DescriptionUpdateManyWithWhereWithoutEventInput>>;
  deleteMany?: Maybe<Array<DescriptionScalarWhereInput>>;
};

export type DescriptionUpdateManyWithoutFaqInput = {
  create?: Maybe<Array<DescriptionCreateWithoutFaqInput>>;
  connectOrCreate?: Maybe<Array<DescriptionCreateOrConnectWithoutFaqInput>>;
  upsert?: Maybe<Array<DescriptionUpsertWithWhereUniqueWithoutFaqInput>>;
  createMany?: Maybe<DescriptionCreateManyFaqInputEnvelope>;
  connect?: Maybe<Array<DescriptionWhereUniqueInput>>;
  set?: Maybe<Array<DescriptionWhereUniqueInput>>;
  disconnect?: Maybe<Array<DescriptionWhereUniqueInput>>;
  delete?: Maybe<Array<DescriptionWhereUniqueInput>>;
  update?: Maybe<Array<DescriptionUpdateWithWhereUniqueWithoutFaqInput>>;
  updateMany?: Maybe<Array<DescriptionUpdateManyWithWhereWithoutFaqInput>>;
  deleteMany?: Maybe<Array<DescriptionScalarWhereInput>>;
};

export type DescriptionUpdateManyWithoutPageInput = {
  create?: Maybe<Array<DescriptionCreateWithoutPageInput>>;
  connectOrCreate?: Maybe<Array<DescriptionCreateOrConnectWithoutPageInput>>;
  upsert?: Maybe<Array<DescriptionUpsertWithWhereUniqueWithoutPageInput>>;
  createMany?: Maybe<DescriptionCreateManyPageInputEnvelope>;
  connect?: Maybe<Array<DescriptionWhereUniqueInput>>;
  set?: Maybe<Array<DescriptionWhereUniqueInput>>;
  disconnect?: Maybe<Array<DescriptionWhereUniqueInput>>;
  delete?: Maybe<Array<DescriptionWhereUniqueInput>>;
  update?: Maybe<Array<DescriptionUpdateWithWhereUniqueWithoutPageInput>>;
  updateMany?: Maybe<Array<DescriptionUpdateManyWithWhereWithoutPageInput>>;
  deleteMany?: Maybe<Array<DescriptionScalarWhereInput>>;
};

export type DescriptionUpdateManyWithoutProductInput = {
  create?: Maybe<Array<DescriptionCreateWithoutProductInput>>;
  connectOrCreate?: Maybe<Array<DescriptionCreateOrConnectWithoutProductInput>>;
  upsert?: Maybe<Array<DescriptionUpsertWithWhereUniqueWithoutProductInput>>;
  createMany?: Maybe<DescriptionCreateManyProductInputEnvelope>;
  connect?: Maybe<Array<DescriptionWhereUniqueInput>>;
  set?: Maybe<Array<DescriptionWhereUniqueInput>>;
  disconnect?: Maybe<Array<DescriptionWhereUniqueInput>>;
  delete?: Maybe<Array<DescriptionWhereUniqueInput>>;
  update?: Maybe<Array<DescriptionUpdateWithWhereUniqueWithoutProductInput>>;
  updateMany?: Maybe<Array<DescriptionUpdateManyWithWhereWithoutProductInput>>;
  deleteMany?: Maybe<Array<DescriptionScalarWhereInput>>;
};

export type DescriptionUpdateWithWhereUniqueWithoutBlogCategoryInput = {
  where: DescriptionWhereUniqueInput;
  data: DescriptionUpdateWithoutBlogCategoryInput;
};

export type DescriptionUpdateWithWhereUniqueWithoutBlogPostInput = {
  where: DescriptionWhereUniqueInput;
  data: DescriptionUpdateWithoutBlogPostInput;
};

export type DescriptionUpdateWithWhereUniqueWithoutBrandInput = {
  where: DescriptionWhereUniqueInput;
  data: DescriptionUpdateWithoutBrandInput;
};

export type DescriptionUpdateWithWhereUniqueWithoutCategoryInput = {
  where: DescriptionWhereUniqueInput;
  data: DescriptionUpdateWithoutCategoryInput;
};

export type DescriptionUpdateWithWhereUniqueWithoutContactInput = {
  where: DescriptionWhereUniqueInput;
  data: DescriptionUpdateWithoutContactInput;
};

export type DescriptionUpdateWithWhereUniqueWithoutContentInput = {
  where: DescriptionWhereUniqueInput;
  data: DescriptionUpdateWithoutContentInput;
};

export type DescriptionUpdateWithWhereUniqueWithoutEventInput = {
  where: DescriptionWhereUniqueInput;
  data: DescriptionUpdateWithoutEventInput;
};

export type DescriptionUpdateWithWhereUniqueWithoutFaqInput = {
  where: DescriptionWhereUniqueInput;
  data: DescriptionUpdateWithoutFaqInput;
};

export type DescriptionUpdateWithWhereUniqueWithoutPageInput = {
  where: DescriptionWhereUniqueInput;
  data: DescriptionUpdateWithoutPageInput;
};

export type DescriptionUpdateWithWhereUniqueWithoutProductInput = {
  where: DescriptionWhereUniqueInput;
  data: DescriptionUpdateWithoutProductInput;
};

export type DescriptionUpdateWithoutBlogCategoryInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  title?: Maybe<NullableStringFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  shortDescription?: Maybe<NullableStringFieldUpdateOperationsInput>;
  slug?: Maybe<NullableStringFieldUpdateOperationsInput>;
  buttonText?: Maybe<NullableStringFieldUpdateOperationsInput>;
  buttonUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  language?: Maybe<StringFieldUpdateOperationsInput>;
  metaTitle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  metaKeywords?: Maybe<NullableStringFieldUpdateOperationsInput>;
  metaDescription?: Maybe<NullableStringFieldUpdateOperationsInput>;
  seoPoint?: Maybe<NullableIntFieldUpdateOperationsInput>;
  content?: Maybe<ContentUpdateOneWithoutDescriptionInput>;
  page?: Maybe<PageUpdateOneWithoutDescriptionInput>;
  contact?: Maybe<ContactUpdateOneWithoutDescriptionInput>;
  category?: Maybe<CategoryUpdateOneWithoutDescriptionsInput>;
  product?: Maybe<ProductUpdateOneWithoutDescriptionsInput>;
  blogPost?: Maybe<BlogPostUpdateOneWithoutDescriptionInput>;
  brand?: Maybe<BrandUpdateOneWithoutDescriptionInput>;
  faq?: Maybe<FaqUpdateOneWithoutDescriptionInput>;
  event?: Maybe<EventUpdateOneWithoutDescriptionInput>;
};

export type DescriptionUpdateWithoutBlogPostInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  title?: Maybe<NullableStringFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  shortDescription?: Maybe<NullableStringFieldUpdateOperationsInput>;
  slug?: Maybe<NullableStringFieldUpdateOperationsInput>;
  buttonText?: Maybe<NullableStringFieldUpdateOperationsInput>;
  buttonUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  language?: Maybe<StringFieldUpdateOperationsInput>;
  metaTitle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  metaKeywords?: Maybe<NullableStringFieldUpdateOperationsInput>;
  metaDescription?: Maybe<NullableStringFieldUpdateOperationsInput>;
  seoPoint?: Maybe<NullableIntFieldUpdateOperationsInput>;
  content?: Maybe<ContentUpdateOneWithoutDescriptionInput>;
  page?: Maybe<PageUpdateOneWithoutDescriptionInput>;
  contact?: Maybe<ContactUpdateOneWithoutDescriptionInput>;
  category?: Maybe<CategoryUpdateOneWithoutDescriptionsInput>;
  product?: Maybe<ProductUpdateOneWithoutDescriptionsInput>;
  blogCategory?: Maybe<BlogCategoryUpdateOneWithoutDescriptionInput>;
  brand?: Maybe<BrandUpdateOneWithoutDescriptionInput>;
  faq?: Maybe<FaqUpdateOneWithoutDescriptionInput>;
  event?: Maybe<EventUpdateOneWithoutDescriptionInput>;
};

export type DescriptionUpdateWithoutBrandInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  title?: Maybe<NullableStringFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  shortDescription?: Maybe<NullableStringFieldUpdateOperationsInput>;
  slug?: Maybe<NullableStringFieldUpdateOperationsInput>;
  buttonText?: Maybe<NullableStringFieldUpdateOperationsInput>;
  buttonUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  language?: Maybe<StringFieldUpdateOperationsInput>;
  metaTitle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  metaKeywords?: Maybe<NullableStringFieldUpdateOperationsInput>;
  metaDescription?: Maybe<NullableStringFieldUpdateOperationsInput>;
  seoPoint?: Maybe<NullableIntFieldUpdateOperationsInput>;
  content?: Maybe<ContentUpdateOneWithoutDescriptionInput>;
  page?: Maybe<PageUpdateOneWithoutDescriptionInput>;
  contact?: Maybe<ContactUpdateOneWithoutDescriptionInput>;
  category?: Maybe<CategoryUpdateOneWithoutDescriptionsInput>;
  product?: Maybe<ProductUpdateOneWithoutDescriptionsInput>;
  blogCategory?: Maybe<BlogCategoryUpdateOneWithoutDescriptionInput>;
  blogPost?: Maybe<BlogPostUpdateOneWithoutDescriptionInput>;
  faq?: Maybe<FaqUpdateOneWithoutDescriptionInput>;
  event?: Maybe<EventUpdateOneWithoutDescriptionInput>;
};

export type DescriptionUpdateWithoutCategoryInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  title?: Maybe<NullableStringFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  shortDescription?: Maybe<NullableStringFieldUpdateOperationsInput>;
  slug?: Maybe<NullableStringFieldUpdateOperationsInput>;
  buttonText?: Maybe<NullableStringFieldUpdateOperationsInput>;
  buttonUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  language?: Maybe<StringFieldUpdateOperationsInput>;
  metaTitle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  metaKeywords?: Maybe<NullableStringFieldUpdateOperationsInput>;
  metaDescription?: Maybe<NullableStringFieldUpdateOperationsInput>;
  seoPoint?: Maybe<NullableIntFieldUpdateOperationsInput>;
  content?: Maybe<ContentUpdateOneWithoutDescriptionInput>;
  page?: Maybe<PageUpdateOneWithoutDescriptionInput>;
  contact?: Maybe<ContactUpdateOneWithoutDescriptionInput>;
  product?: Maybe<ProductUpdateOneWithoutDescriptionsInput>;
  blogCategory?: Maybe<BlogCategoryUpdateOneWithoutDescriptionInput>;
  blogPost?: Maybe<BlogPostUpdateOneWithoutDescriptionInput>;
  brand?: Maybe<BrandUpdateOneWithoutDescriptionInput>;
  faq?: Maybe<FaqUpdateOneWithoutDescriptionInput>;
  event?: Maybe<EventUpdateOneWithoutDescriptionInput>;
};

export type DescriptionUpdateWithoutContactInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  title?: Maybe<NullableStringFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  shortDescription?: Maybe<NullableStringFieldUpdateOperationsInput>;
  slug?: Maybe<NullableStringFieldUpdateOperationsInput>;
  buttonText?: Maybe<NullableStringFieldUpdateOperationsInput>;
  buttonUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  language?: Maybe<StringFieldUpdateOperationsInput>;
  metaTitle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  metaKeywords?: Maybe<NullableStringFieldUpdateOperationsInput>;
  metaDescription?: Maybe<NullableStringFieldUpdateOperationsInput>;
  seoPoint?: Maybe<NullableIntFieldUpdateOperationsInput>;
  content?: Maybe<ContentUpdateOneWithoutDescriptionInput>;
  page?: Maybe<PageUpdateOneWithoutDescriptionInput>;
  category?: Maybe<CategoryUpdateOneWithoutDescriptionsInput>;
  product?: Maybe<ProductUpdateOneWithoutDescriptionsInput>;
  blogCategory?: Maybe<BlogCategoryUpdateOneWithoutDescriptionInput>;
  blogPost?: Maybe<BlogPostUpdateOneWithoutDescriptionInput>;
  brand?: Maybe<BrandUpdateOneWithoutDescriptionInput>;
  faq?: Maybe<FaqUpdateOneWithoutDescriptionInput>;
  event?: Maybe<EventUpdateOneWithoutDescriptionInput>;
};

export type DescriptionUpdateWithoutContentInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  title?: Maybe<NullableStringFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  shortDescription?: Maybe<NullableStringFieldUpdateOperationsInput>;
  slug?: Maybe<NullableStringFieldUpdateOperationsInput>;
  buttonText?: Maybe<NullableStringFieldUpdateOperationsInput>;
  buttonUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  language?: Maybe<StringFieldUpdateOperationsInput>;
  metaTitle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  metaKeywords?: Maybe<NullableStringFieldUpdateOperationsInput>;
  metaDescription?: Maybe<NullableStringFieldUpdateOperationsInput>;
  seoPoint?: Maybe<NullableIntFieldUpdateOperationsInput>;
  page?: Maybe<PageUpdateOneWithoutDescriptionInput>;
  contact?: Maybe<ContactUpdateOneWithoutDescriptionInput>;
  category?: Maybe<CategoryUpdateOneWithoutDescriptionsInput>;
  product?: Maybe<ProductUpdateOneWithoutDescriptionsInput>;
  blogCategory?: Maybe<BlogCategoryUpdateOneWithoutDescriptionInput>;
  blogPost?: Maybe<BlogPostUpdateOneWithoutDescriptionInput>;
  brand?: Maybe<BrandUpdateOneWithoutDescriptionInput>;
  faq?: Maybe<FaqUpdateOneWithoutDescriptionInput>;
  event?: Maybe<EventUpdateOneWithoutDescriptionInput>;
};

export type DescriptionUpdateWithoutEventInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  title?: Maybe<NullableStringFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  shortDescription?: Maybe<NullableStringFieldUpdateOperationsInput>;
  slug?: Maybe<NullableStringFieldUpdateOperationsInput>;
  buttonText?: Maybe<NullableStringFieldUpdateOperationsInput>;
  buttonUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  language?: Maybe<StringFieldUpdateOperationsInput>;
  metaTitle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  metaKeywords?: Maybe<NullableStringFieldUpdateOperationsInput>;
  metaDescription?: Maybe<NullableStringFieldUpdateOperationsInput>;
  seoPoint?: Maybe<NullableIntFieldUpdateOperationsInput>;
  content?: Maybe<ContentUpdateOneWithoutDescriptionInput>;
  page?: Maybe<PageUpdateOneWithoutDescriptionInput>;
  contact?: Maybe<ContactUpdateOneWithoutDescriptionInput>;
  category?: Maybe<CategoryUpdateOneWithoutDescriptionsInput>;
  product?: Maybe<ProductUpdateOneWithoutDescriptionsInput>;
  blogCategory?: Maybe<BlogCategoryUpdateOneWithoutDescriptionInput>;
  blogPost?: Maybe<BlogPostUpdateOneWithoutDescriptionInput>;
  brand?: Maybe<BrandUpdateOneWithoutDescriptionInput>;
  faq?: Maybe<FaqUpdateOneWithoutDescriptionInput>;
};

export type DescriptionUpdateWithoutFaqInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  title?: Maybe<NullableStringFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  shortDescription?: Maybe<NullableStringFieldUpdateOperationsInput>;
  slug?: Maybe<NullableStringFieldUpdateOperationsInput>;
  buttonText?: Maybe<NullableStringFieldUpdateOperationsInput>;
  buttonUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  language?: Maybe<StringFieldUpdateOperationsInput>;
  metaTitle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  metaKeywords?: Maybe<NullableStringFieldUpdateOperationsInput>;
  metaDescription?: Maybe<NullableStringFieldUpdateOperationsInput>;
  seoPoint?: Maybe<NullableIntFieldUpdateOperationsInput>;
  content?: Maybe<ContentUpdateOneWithoutDescriptionInput>;
  page?: Maybe<PageUpdateOneWithoutDescriptionInput>;
  contact?: Maybe<ContactUpdateOneWithoutDescriptionInput>;
  category?: Maybe<CategoryUpdateOneWithoutDescriptionsInput>;
  product?: Maybe<ProductUpdateOneWithoutDescriptionsInput>;
  blogCategory?: Maybe<BlogCategoryUpdateOneWithoutDescriptionInput>;
  blogPost?: Maybe<BlogPostUpdateOneWithoutDescriptionInput>;
  brand?: Maybe<BrandUpdateOneWithoutDescriptionInput>;
  event?: Maybe<EventUpdateOneWithoutDescriptionInput>;
};

export type DescriptionUpdateWithoutPageInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  title?: Maybe<NullableStringFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  shortDescription?: Maybe<NullableStringFieldUpdateOperationsInput>;
  slug?: Maybe<NullableStringFieldUpdateOperationsInput>;
  buttonText?: Maybe<NullableStringFieldUpdateOperationsInput>;
  buttonUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  language?: Maybe<StringFieldUpdateOperationsInput>;
  metaTitle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  metaKeywords?: Maybe<NullableStringFieldUpdateOperationsInput>;
  metaDescription?: Maybe<NullableStringFieldUpdateOperationsInput>;
  seoPoint?: Maybe<NullableIntFieldUpdateOperationsInput>;
  content?: Maybe<ContentUpdateOneWithoutDescriptionInput>;
  contact?: Maybe<ContactUpdateOneWithoutDescriptionInput>;
  category?: Maybe<CategoryUpdateOneWithoutDescriptionsInput>;
  product?: Maybe<ProductUpdateOneWithoutDescriptionsInput>;
  blogCategory?: Maybe<BlogCategoryUpdateOneWithoutDescriptionInput>;
  blogPost?: Maybe<BlogPostUpdateOneWithoutDescriptionInput>;
  brand?: Maybe<BrandUpdateOneWithoutDescriptionInput>;
  faq?: Maybe<FaqUpdateOneWithoutDescriptionInput>;
  event?: Maybe<EventUpdateOneWithoutDescriptionInput>;
};

export type DescriptionUpdateWithoutProductInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  title?: Maybe<NullableStringFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  shortDescription?: Maybe<NullableStringFieldUpdateOperationsInput>;
  slug?: Maybe<NullableStringFieldUpdateOperationsInput>;
  buttonText?: Maybe<NullableStringFieldUpdateOperationsInput>;
  buttonUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  language?: Maybe<StringFieldUpdateOperationsInput>;
  metaTitle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  metaKeywords?: Maybe<NullableStringFieldUpdateOperationsInput>;
  metaDescription?: Maybe<NullableStringFieldUpdateOperationsInput>;
  seoPoint?: Maybe<NullableIntFieldUpdateOperationsInput>;
  content?: Maybe<ContentUpdateOneWithoutDescriptionInput>;
  page?: Maybe<PageUpdateOneWithoutDescriptionInput>;
  contact?: Maybe<ContactUpdateOneWithoutDescriptionInput>;
  category?: Maybe<CategoryUpdateOneWithoutDescriptionsInput>;
  blogCategory?: Maybe<BlogCategoryUpdateOneWithoutDescriptionInput>;
  blogPost?: Maybe<BlogPostUpdateOneWithoutDescriptionInput>;
  brand?: Maybe<BrandUpdateOneWithoutDescriptionInput>;
  faq?: Maybe<FaqUpdateOneWithoutDescriptionInput>;
  event?: Maybe<EventUpdateOneWithoutDescriptionInput>;
};

export type DescriptionUpsertWithWhereUniqueWithoutBlogCategoryInput = {
  where: DescriptionWhereUniqueInput;
  update: DescriptionUpdateWithoutBlogCategoryInput;
  create: DescriptionCreateWithoutBlogCategoryInput;
};

export type DescriptionUpsertWithWhereUniqueWithoutBlogPostInput = {
  where: DescriptionWhereUniqueInput;
  update: DescriptionUpdateWithoutBlogPostInput;
  create: DescriptionCreateWithoutBlogPostInput;
};

export type DescriptionUpsertWithWhereUniqueWithoutBrandInput = {
  where: DescriptionWhereUniqueInput;
  update: DescriptionUpdateWithoutBrandInput;
  create: DescriptionCreateWithoutBrandInput;
};

export type DescriptionUpsertWithWhereUniqueWithoutCategoryInput = {
  where: DescriptionWhereUniqueInput;
  update: DescriptionUpdateWithoutCategoryInput;
  create: DescriptionCreateWithoutCategoryInput;
};

export type DescriptionUpsertWithWhereUniqueWithoutContactInput = {
  where: DescriptionWhereUniqueInput;
  update: DescriptionUpdateWithoutContactInput;
  create: DescriptionCreateWithoutContactInput;
};

export type DescriptionUpsertWithWhereUniqueWithoutContentInput = {
  where: DescriptionWhereUniqueInput;
  update: DescriptionUpdateWithoutContentInput;
  create: DescriptionCreateWithoutContentInput;
};

export type DescriptionUpsertWithWhereUniqueWithoutEventInput = {
  where: DescriptionWhereUniqueInput;
  update: DescriptionUpdateWithoutEventInput;
  create: DescriptionCreateWithoutEventInput;
};

export type DescriptionUpsertWithWhereUniqueWithoutFaqInput = {
  where: DescriptionWhereUniqueInput;
  update: DescriptionUpdateWithoutFaqInput;
  create: DescriptionCreateWithoutFaqInput;
};

export type DescriptionUpsertWithWhereUniqueWithoutPageInput = {
  where: DescriptionWhereUniqueInput;
  update: DescriptionUpdateWithoutPageInput;
  create: DescriptionCreateWithoutPageInput;
};

export type DescriptionUpsertWithWhereUniqueWithoutProductInput = {
  where: DescriptionWhereUniqueInput;
  update: DescriptionUpdateWithoutProductInput;
  create: DescriptionCreateWithoutProductInput;
};

export type DescriptionWhereInput = {
  AND?: Maybe<Array<DescriptionWhereInput>>;
  OR?: Maybe<Array<DescriptionWhereInput>>;
  NOT?: Maybe<Array<DescriptionWhereInput>>;
  id?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  title?: Maybe<StringNullableFilter>;
  description?: Maybe<StringNullableFilter>;
  shortDescription?: Maybe<StringNullableFilter>;
  slug?: Maybe<StringNullableFilter>;
  buttonText?: Maybe<StringNullableFilter>;
  buttonUrl?: Maybe<StringNullableFilter>;
  language?: Maybe<StringFilter>;
  metaTitle?: Maybe<StringNullableFilter>;
  metaKeywords?: Maybe<StringNullableFilter>;
  metaDescription?: Maybe<StringNullableFilter>;
  seoPoint?: Maybe<IntNullableFilter>;
  content?: Maybe<ContentRelationFilter>;
  contentId?: Maybe<StringNullableFilter>;
  page?: Maybe<PageRelationFilter>;
  pageId?: Maybe<StringNullableFilter>;
  contact?: Maybe<ContactRelationFilter>;
  contactId?: Maybe<StringNullableFilter>;
  category?: Maybe<CategoryRelationFilter>;
  categoryId?: Maybe<StringNullableFilter>;
  product?: Maybe<ProductRelationFilter>;
  productId?: Maybe<StringNullableFilter>;
  blogCategory?: Maybe<BlogCategoryRelationFilter>;
  blogCategoryId?: Maybe<StringNullableFilter>;
  blogPost?: Maybe<BlogPostRelationFilter>;
  blogPostId?: Maybe<StringNullableFilter>;
  brand?: Maybe<BrandRelationFilter>;
  brandId?: Maybe<StringNullableFilter>;
  faq?: Maybe<FaqRelationFilter>;
  faqId?: Maybe<StringNullableFilter>;
  event?: Maybe<EventRelationFilter>;
  eventId?: Maybe<StringNullableFilter>;
};

export type DescriptionWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type DistrictCreateManyTownshipInput = {
  id?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type DistrictCreateManyTownshipInputEnvelope = {
  data: Array<DistrictCreateManyTownshipInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type DistrictCreateNestedManyWithoutTownshipInput = {
  create?: Maybe<Array<DistrictCreateWithoutTownshipInput>>;
  connectOrCreate?: Maybe<Array<DistrictCreateOrConnectWithoutTownshipInput>>;
  createMany?: Maybe<DistrictCreateManyTownshipInputEnvelope>;
  connect?: Maybe<Array<DistrictWhereUniqueInput>>;
};

export type DistrictCreateOrConnectWithoutTownshipInput = {
  where: DistrictWhereUniqueInput;
  create: DistrictCreateWithoutTownshipInput;
};

export type DistrictCreateWithoutTownshipInput = {
  id?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type DistrictListRelationFilter = {
  every?: Maybe<DistrictWhereInput>;
  some?: Maybe<DistrictWhereInput>;
  none?: Maybe<DistrictWhereInput>;
};

export type DistrictScalarWhereInput = {
  AND?: Maybe<Array<DistrictScalarWhereInput>>;
  OR?: Maybe<Array<DistrictScalarWhereInput>>;
  NOT?: Maybe<Array<DistrictScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  title?: Maybe<StringFilter>;
  townshipId?: Maybe<StringFilter>;
};

export type DistrictUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  title?: Maybe<StringFieldUpdateOperationsInput>;
};

export type DistrictUpdateManyWithWhereWithoutTownshipInput = {
  where: DistrictScalarWhereInput;
  data: DistrictUpdateManyMutationInput;
};

export type DistrictUpdateManyWithoutTownshipInput = {
  create?: Maybe<Array<DistrictCreateWithoutTownshipInput>>;
  connectOrCreate?: Maybe<Array<DistrictCreateOrConnectWithoutTownshipInput>>;
  upsert?: Maybe<Array<DistrictUpsertWithWhereUniqueWithoutTownshipInput>>;
  createMany?: Maybe<DistrictCreateManyTownshipInputEnvelope>;
  connect?: Maybe<Array<DistrictWhereUniqueInput>>;
  set?: Maybe<Array<DistrictWhereUniqueInput>>;
  disconnect?: Maybe<Array<DistrictWhereUniqueInput>>;
  delete?: Maybe<Array<DistrictWhereUniqueInput>>;
  update?: Maybe<Array<DistrictUpdateWithWhereUniqueWithoutTownshipInput>>;
  updateMany?: Maybe<Array<DistrictUpdateManyWithWhereWithoutTownshipInput>>;
  deleteMany?: Maybe<Array<DistrictScalarWhereInput>>;
};

export type DistrictUpdateWithWhereUniqueWithoutTownshipInput = {
  where: DistrictWhereUniqueInput;
  data: DistrictUpdateWithoutTownshipInput;
};

export type DistrictUpdateWithoutTownshipInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  title?: Maybe<StringFieldUpdateOperationsInput>;
};

export type DistrictUpsertWithWhereUniqueWithoutTownshipInput = {
  where: DistrictWhereUniqueInput;
  update: DistrictUpdateWithoutTownshipInput;
  create: DistrictCreateWithoutTownshipInput;
};

export type DistrictWhereInput = {
  AND?: Maybe<Array<DistrictWhereInput>>;
  OR?: Maybe<Array<DistrictWhereInput>>;
  NOT?: Maybe<Array<DistrictWhereInput>>;
  id?: Maybe<StringFilter>;
  title?: Maybe<StringFilter>;
  township?: Maybe<TownshipRelationFilter>;
  townshipId?: Maybe<StringFilter>;
};

export type DistrictWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type EcommerceSettings = {
  __typename?: 'EcommerceSettings';
  id: Scalars['Int'];
  active: Scalars['Boolean'];
  deliveryRadius: Scalars['Int'];
  currencyCode: Scalars['String'];
  currencySymbol?: Maybe<Scalars['String']>;
  currencyFormat: Scalars['String'];
  amountSeparator: AmountSeparator;
  decimalNumber: Scalars['Int'];
  defaultShippingCountry?: Maybe<Scalars['String']>;
  defaultShippingState?: Maybe<Scalars['String']>;
  defaultShippingCity?: Maybe<Scalars['String']>;
  defaultProductSorting: Scalars['String'];
  productsLimit: Scalars['Int'];
  weightUnit: Scalars['String'];
  lengthUnit: Scalars['String'];
  hideBillingAddress: Scalars['Boolean'];
  orderConfirmationCopyTo: Scalars['String'];
};

export type EcommerceSettingsUpdateInput = {
  id?: Maybe<IntFieldUpdateOperationsInput>;
  active?: Maybe<BoolFieldUpdateOperationsInput>;
  deliveryRadius?: Maybe<IntFieldUpdateOperationsInput>;
  currencyCode?: Maybe<StringFieldUpdateOperationsInput>;
  currencySymbol?: Maybe<NullableStringFieldUpdateOperationsInput>;
  currencyFormat?: Maybe<StringFieldUpdateOperationsInput>;
  amountSeparator?: Maybe<EnumAmountSeparatorFieldUpdateOperationsInput>;
  decimalNumber?: Maybe<IntFieldUpdateOperationsInput>;
  defaultShippingCountry?: Maybe<NullableStringFieldUpdateOperationsInput>;
  defaultShippingState?: Maybe<NullableStringFieldUpdateOperationsInput>;
  defaultShippingCity?: Maybe<NullableStringFieldUpdateOperationsInput>;
  defaultProductSorting?: Maybe<StringFieldUpdateOperationsInput>;
  productsLimit?: Maybe<IntFieldUpdateOperationsInput>;
  weightUnit?: Maybe<StringFieldUpdateOperationsInput>;
  lengthUnit?: Maybe<StringFieldUpdateOperationsInput>;
  hideBillingAddress?: Maybe<BoolFieldUpdateOperationsInput>;
  orderConfirmationCopyTo?: Maybe<StringFieldUpdateOperationsInput>;
};

export type EnumAmountSeparatorFieldUpdateOperationsInput = {
  set?: Maybe<AmountSeparator>;
};

export type EnumContentLineupFieldUpdateOperationsInput = {
  set?: Maybe<ContentLineup>;
};

export type EnumContentLineupFilter = {
  equals?: Maybe<ContentLineup>;
  in?: Maybe<Array<ContentLineup>>;
  notIn?: Maybe<Array<ContentLineup>>;
  not?: Maybe<NestedEnumContentLineupFilter>;
};

export type EnumContentTypeFieldUpdateOperationsInput = {
  set?: Maybe<ContentType>;
};

export type EnumContentTypeFilter = {
  equals?: Maybe<ContentType>;
  in?: Maybe<Array<ContentType>>;
  notIn?: Maybe<Array<ContentType>>;
  not?: Maybe<NestedEnumContentTypeFilter>;
};

export type EnumGenderTypeNullableFilter = {
  equals?: Maybe<GenderType>;
  in?: Maybe<Array<GenderType>>;
  notIn?: Maybe<Array<GenderType>>;
  not?: Maybe<NestedEnumGenderTypeNullableFilter>;
};

export type EnumMediaTypeFieldUpdateOperationsInput = {
  set?: Maybe<MediaType>;
};

export type EnumMediaTypeFilter = {
  equals?: Maybe<MediaType>;
  in?: Maybe<Array<MediaType>>;
  notIn?: Maybe<Array<MediaType>>;
  not?: Maybe<NestedEnumMediaTypeFilter>;
};

export type EnumPageTypeFieldUpdateOperationsInput = {
  set?: Maybe<PageType>;
};

export type EnumPageTypeFilter = {
  equals?: Maybe<PageType>;
  in?: Maybe<Array<PageType>>;
  notIn?: Maybe<Array<PageType>>;
  not?: Maybe<NestedEnumPageTypeFilter>;
};

export type EnumProductCategoryListTypeFieldUpdateOperationsInput = {
  set?: Maybe<ProductCategoryListType>;
};

export type EnumProductCategoryListTypeFilter = {
  equals?: Maybe<ProductCategoryListType>;
  in?: Maybe<Array<ProductCategoryListType>>;
  notIn?: Maybe<Array<ProductCategoryListType>>;
  not?: Maybe<NestedEnumProductCategoryListTypeFilter>;
};

export type EnumThemeTypeFieldUpdateOperationsInput = {
  set?: Maybe<ThemeType>;
};

export type EnumThemeTypeFilter = {
  equals?: Maybe<ThemeType>;
  in?: Maybe<Array<ThemeType>>;
  notIn?: Maybe<Array<ThemeType>>;
  not?: Maybe<NestedEnumThemeTypeFilter>;
};

export type EnumUserRoleFieldUpdateOperationsInput = {
  set?: Maybe<UserRole>;
};

export type EnumUserRoleFilter = {
  equals?: Maybe<UserRole>;
  in?: Maybe<Array<UserRole>>;
  notIn?: Maybe<Array<UserRole>>;
  not?: Maybe<NestedEnumUserRoleFilter>;
};

export type EnumUserStatusTypeFieldUpdateOperationsInput = {
  set?: Maybe<UserStatusType>;
};

export type EnumUserStatusTypeFilter = {
  equals?: Maybe<UserStatusType>;
  in?: Maybe<Array<UserStatusType>>;
  notIn?: Maybe<Array<UserStatusType>>;
  not?: Maybe<NestedEnumUserStatusTypeFilter>;
};

export type Event = {
  __typename?: 'Event';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  description: Array<Description>;
};


export type EventDescriptionArgs = {
  where?: Maybe<DescriptionWhereInput>;
  orderBy?: Maybe<Array<DescriptionOrderByWithRelationInput>>;
  cursor?: Maybe<DescriptionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<DescriptionScalarFieldEnum>>;
};

export type EventCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  description?: Maybe<DescriptionCreateNestedManyWithoutEventInput>;
};

export type EventCreateNestedOneWithoutDescriptionInput = {
  create?: Maybe<EventCreateWithoutDescriptionInput>;
  connectOrCreate?: Maybe<EventCreateOrConnectWithoutDescriptionInput>;
  connect?: Maybe<EventWhereUniqueInput>;
};

export type EventCreateOrConnectWithoutDescriptionInput = {
  where: EventWhereUniqueInput;
  create: EventCreateWithoutDescriptionInput;
};

export type EventCreateWithoutDescriptionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
};

export type EventOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  startDate?: Maybe<SortOrder>;
  endDate?: Maybe<SortOrder>;
  description?: Maybe<DescriptionOrderByRelationAggregateInput>;
};

export type EventRelationFilter = {
  is?: Maybe<EventWhereInput>;
  isNot?: Maybe<EventWhereInput>;
};

export enum EventScalarFieldEnum {
  id = 'id',
  createdAt = 'createdAt',
  updatedAt = 'updatedAt',
  startDate = 'startDate',
  endDate = 'endDate'
}

export type EventUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  startDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  endDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<DescriptionUpdateManyWithoutEventInput>;
};

export type EventUpdateOneWithoutDescriptionInput = {
  create?: Maybe<EventCreateWithoutDescriptionInput>;
  connectOrCreate?: Maybe<EventCreateOrConnectWithoutDescriptionInput>;
  upsert?: Maybe<EventUpsertWithoutDescriptionInput>;
  connect?: Maybe<EventWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  update?: Maybe<EventUpdateWithoutDescriptionInput>;
};

export type EventUpdateWithoutDescriptionInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  startDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  endDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type EventUpsertWithoutDescriptionInput = {
  update: EventUpdateWithoutDescriptionInput;
  create: EventCreateWithoutDescriptionInput;
};

export type EventWhereInput = {
  AND?: Maybe<Array<EventWhereInput>>;
  OR?: Maybe<Array<EventWhereInput>>;
  NOT?: Maybe<Array<EventWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  startDate?: Maybe<DateTimeFilter>;
  endDate?: Maybe<DateTimeFilter>;
  description?: Maybe<DescriptionListRelationFilter>;
};

export type EventWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type Faq = {
  __typename?: 'Faq';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  order: Scalars['Int'];
  description: Array<Description>;
};


export type FaqDescriptionArgs = {
  where?: Maybe<DescriptionWhereInput>;
  orderBy?: Maybe<Array<DescriptionOrderByWithRelationInput>>;
  cursor?: Maybe<DescriptionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<DescriptionScalarFieldEnum>>;
};

export type FaqCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  order?: Maybe<Scalars['Int']>;
  description?: Maybe<DescriptionCreateNestedManyWithoutFaqInput>;
};

export type FaqCreateNestedOneWithoutDescriptionInput = {
  create?: Maybe<FaqCreateWithoutDescriptionInput>;
  connectOrCreate?: Maybe<FaqCreateOrConnectWithoutDescriptionInput>;
  connect?: Maybe<FaqWhereUniqueInput>;
};

export type FaqCreateOrConnectWithoutDescriptionInput = {
  where: FaqWhereUniqueInput;
  create: FaqCreateWithoutDescriptionInput;
};

export type FaqCreateWithoutDescriptionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  order?: Maybe<Scalars['Int']>;
};

export type FaqOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  description?: Maybe<DescriptionOrderByRelationAggregateInput>;
};

export type FaqRelationFilter = {
  is?: Maybe<FaqWhereInput>;
  isNot?: Maybe<FaqWhereInput>;
};

export enum FaqScalarFieldEnum {
  id = 'id',
  createdAt = 'createdAt',
  updatedAt = 'updatedAt',
  order = 'order'
}

export type FaqUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  description?: Maybe<DescriptionUpdateManyWithoutFaqInput>;
};

export type FaqUpdateOneWithoutDescriptionInput = {
  create?: Maybe<FaqCreateWithoutDescriptionInput>;
  connectOrCreate?: Maybe<FaqCreateOrConnectWithoutDescriptionInput>;
  upsert?: Maybe<FaqUpsertWithoutDescriptionInput>;
  connect?: Maybe<FaqWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  update?: Maybe<FaqUpdateWithoutDescriptionInput>;
};

export type FaqUpdateWithoutDescriptionInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
};

export type FaqUpsertWithoutDescriptionInput = {
  update: FaqUpdateWithoutDescriptionInput;
  create: FaqCreateWithoutDescriptionInput;
};

export type FaqWhereInput = {
  AND?: Maybe<Array<FaqWhereInput>>;
  OR?: Maybe<Array<FaqWhereInput>>;
  NOT?: Maybe<Array<FaqWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  order?: Maybe<IntFilter>;
  description?: Maybe<DescriptionListRelationFilter>;
};

export type FaqWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type FloatNullableFilter = {
  equals?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  notIn?: Maybe<Array<Scalars['Float']>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatNullableFilter>;
};

export type FormioForm = {
  __typename?: 'FormioForm';
  id: Scalars['String'];
  slug: Scalars['String'];
  title: Scalars['String'];
  order: Scalars['Int'];
  status: Scalars['Boolean'];
};

export type FormioFormAvgAggregate = {
  __typename?: 'FormioFormAvgAggregate';
  order?: Maybe<Scalars['Float']>;
};

export type FormioFormCountAggregate = {
  __typename?: 'FormioFormCountAggregate';
  id: Scalars['Int'];
  slug: Scalars['Int'];
  title: Scalars['Int'];
  order: Scalars['Int'];
  status: Scalars['Int'];
  _all: Scalars['Int'];
};

export type FormioFormCreateInput = {
  id?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  title: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Boolean']>;
};

export type FormioFormCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  title: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Boolean']>;
};

export type FormioFormGroupBy = {
  __typename?: 'FormioFormGroupBy';
  id: Scalars['String'];
  slug: Scalars['String'];
  title: Scalars['String'];
  order: Scalars['Int'];
  status: Scalars['Boolean'];
  _count?: Maybe<FormioFormCountAggregate>;
  _avg?: Maybe<FormioFormAvgAggregate>;
  _sum?: Maybe<FormioFormSumAggregate>;
  _min?: Maybe<FormioFormMinAggregate>;
  _max?: Maybe<FormioFormMaxAggregate>;
};

export type FormioFormMaxAggregate = {
  __typename?: 'FormioFormMaxAggregate';
  id?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Boolean']>;
};

export type FormioFormMinAggregate = {
  __typename?: 'FormioFormMinAggregate';
  id?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Boolean']>;
};

export type FormioFormOrderByInput = {
  id?: Maybe<SortOrder>;
  slug?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
};

export type FormioFormOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  slug?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
};

export enum FormioFormScalarFieldEnum {
  id = 'id',
  slug = 'slug',
  title = 'title',
  order = 'order',
  status = 'status'
}

export type FormioFormScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<FormioFormScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<FormioFormScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<FormioFormScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  slug?: Maybe<StringWithAggregatesFilter>;
  title?: Maybe<StringWithAggregatesFilter>;
  order?: Maybe<IntWithAggregatesFilter>;
  status?: Maybe<BoolWithAggregatesFilter>;
};

export type FormioFormSumAggregate = {
  __typename?: 'FormioFormSumAggregate';
  order?: Maybe<Scalars['Int']>;
};

export type FormioFormUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  slug?: Maybe<StringFieldUpdateOperationsInput>;
  title?: Maybe<StringFieldUpdateOperationsInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  status?: Maybe<BoolFieldUpdateOperationsInput>;
};

export type FormioFormUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  slug?: Maybe<StringFieldUpdateOperationsInput>;
  title?: Maybe<StringFieldUpdateOperationsInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  status?: Maybe<BoolFieldUpdateOperationsInput>;
};

export type FormioFormWhereInput = {
  AND?: Maybe<Array<FormioFormWhereInput>>;
  OR?: Maybe<Array<FormioFormWhereInput>>;
  NOT?: Maybe<Array<FormioFormWhereInput>>;
  id?: Maybe<StringFilter>;
  slug?: Maybe<StringFilter>;
  title?: Maybe<StringFilter>;
  order?: Maybe<IntFilter>;
  status?: Maybe<BoolFilter>;
};

export type FormioFormWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export enum GenderType {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  OTHER = 'OTHER'
}

export type IdField = {
  id: Scalars['String'];
};

export type IntFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['Int']>;
  increment?: Maybe<Scalars['Int']>;
  decrement?: Maybe<Scalars['Int']>;
  multiply?: Maybe<Scalars['Int']>;
  divide?: Maybe<Scalars['Int']>;
};

export type IntFilter = {
  equals?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  notIn?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntFilter>;
};

export type IntNullableFilter = {
  equals?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  notIn?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntNullableFilter>;
};

export type IntWithAggregatesFilter = {
  equals?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  notIn?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _avg?: Maybe<NestedFloatFilter>;
  _sum?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedIntFilter>;
};



export type JsonNullableFilter = {
  equals?: Maybe<Scalars['JSON']>;
  not?: Maybe<Scalars['JSON']>;
};

export type JsonNullableWithAggregatesFilter = {
  equals?: Maybe<Scalars['JSON']>;
  not?: Maybe<Scalars['JSON']>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedJsonNullableFilter>;
  _max?: Maybe<NestedJsonNullableFilter>;
};

export enum MediaType {
  SINGLE = 'SINGLE',
  GALLERY = 'GALLERY',
  THUMBNAIL = 'THUMBNAIL',
  SIDEBYSIDE = 'SIDEBYSIDE',
  NOIMAGE = 'NOIMAGE'
}

export type Mutation = {
  __typename?: 'Mutation';
  createCustomData: CustomData;
  createManyCustomData: AffectedRowsOutput;
  deleteCustomData?: Maybe<CustomData>;
  updateCustomData?: Maybe<CustomData>;
  deleteManyCustomData: AffectedRowsOutput;
  updateManyCustomData: AffectedRowsOutput;
  upsertCustomData: CustomData;
  createFormioForm: FormioForm;
  createManyFormioForm: AffectedRowsOutput;
  deleteFormioForm?: Maybe<FormioForm>;
  updateFormioForm?: Maybe<FormioForm>;
  deleteManyFormioForm: AffectedRowsOutput;
  updateManyFormioForm: AffectedRowsOutput;
  upsertFormioForm: FormioForm;
  createReaderComment: ReaderComment;
  createManyReaderComment: AffectedRowsOutput;
  deleteReaderComment?: Maybe<ReaderComment>;
  updateReaderComment?: Maybe<ReaderComment>;
  deleteManyReaderComment: AffectedRowsOutput;
  updateManyReaderComment: AffectedRowsOutput;
  upsertReaderComment: ReaderComment;
  createYtVideo: YtVideo;
  createManyYtVideo: AffectedRowsOutput;
  deleteYtVideo?: Maybe<YtVideo>;
  updateYtVideo?: Maybe<YtVideo>;
  deleteManyYtVideo: AffectedRowsOutput;
  updateManyYtVideo: AffectedRowsOutput;
  upsertYtVideo: YtVideo;
  createAddress: Address;
  deleteAddress: Scalars['Boolean'];
  updateAddress?: Maybe<Address>;
  updateBlogCategory?: Maybe<BlogCategory>;
  updateBlogPost?: Maybe<BlogPost>;
  updateCustomForms?: Maybe<CustomForms>;
  deleteDescription?: Maybe<Description>;
  updateDescription: Description;
  createEvent: Event;
  deleteEvent?: Maybe<Event>;
  updateEvent?: Maybe<Event>;
  createFaq: Faq;
  deleteFaq?: Maybe<Faq>;
  updateFaq?: Maybe<Faq>;
  updatePage?: Maybe<Page>;
  updateAppSettings: AppSettings;
  createBlogCategory: BlogCategory;
  deleteBlogCategory: Scalars['Boolean'];
  createBlogPost: BlogPost;
  deleteBlogPost: Scalars['Boolean'];
  createBrand: Brand;
  updateBrand: Brand;
  deleteBrand: Scalars['Boolean'];
  createCategory: Category;
  updateCategory: Category;
  deleteCategory: Scalars['Boolean'];
  createContent: Content;
  updateContent: Content;
  deleteContent: Scalars['Boolean'];
  createDescription: Description;
  updateEcommerceSettings: EcommerceSettings;
  createCustomForms: CustomForms;
  deleteMessage: Scalars['Boolean'];
  createPage: Page;
  deletePage: Scalars['Boolean'];
  setHomepage: Scalars['Boolean'];
  createProduct: Product;
  updateProduct: Product;
  deleteProduct: Scalars['Boolean'];
  pubSubMutation: Scalars['Boolean'];
  publisherMutation: Scalars['Boolean'];
  uploadFile: Scalars['JSONObject'];
  uploadProductFile: Scalars['JSONObject'];
  createUser: User;
  updateUser: User;
  deleteUser: Scalars['Boolean'];
  login: AuthPayloadModel;
  register: AuthPayloadModel;
  checkRefreshToken: AuthPayloadModel;
  logout: Scalars['Boolean'];
  setHomepageVideo: Scalars['Boolean'];
};


export type MutationCreateCustomDataArgs = {
  data: CustomDataCreateInput;
};


export type MutationCreateManyCustomDataArgs = {
  data: Array<CustomDataCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};


export type MutationDeleteCustomDataArgs = {
  where: CustomDataWhereUniqueInput;
};


export type MutationUpdateCustomDataArgs = {
  data: CustomDataUpdateInput;
  where: CustomDataWhereUniqueInput;
};


export type MutationDeleteManyCustomDataArgs = {
  where?: Maybe<CustomDataWhereInput>;
};


export type MutationUpdateManyCustomDataArgs = {
  data: CustomDataUpdateManyMutationInput;
  where?: Maybe<CustomDataWhereInput>;
};


export type MutationUpsertCustomDataArgs = {
  where: CustomDataWhereUniqueInput;
  create: CustomDataCreateInput;
  update: CustomDataUpdateInput;
};


export type MutationCreateFormioFormArgs = {
  data: FormioFormCreateInput;
};


export type MutationCreateManyFormioFormArgs = {
  data: Array<FormioFormCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};


export type MutationDeleteFormioFormArgs = {
  where: FormioFormWhereUniqueInput;
};


export type MutationUpdateFormioFormArgs = {
  data: FormioFormUpdateInput;
  where: FormioFormWhereUniqueInput;
};


export type MutationDeleteManyFormioFormArgs = {
  where?: Maybe<FormioFormWhereInput>;
};


export type MutationUpdateManyFormioFormArgs = {
  data: FormioFormUpdateManyMutationInput;
  where?: Maybe<FormioFormWhereInput>;
};


export type MutationUpsertFormioFormArgs = {
  where: FormioFormWhereUniqueInput;
  create: FormioFormCreateInput;
  update: FormioFormUpdateInput;
};


export type MutationCreateReaderCommentArgs = {
  data: ReaderCommentCreateInput;
};


export type MutationCreateManyReaderCommentArgs = {
  data: Array<ReaderCommentCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};


export type MutationDeleteReaderCommentArgs = {
  where: ReaderCommentWhereUniqueInput;
};


export type MutationUpdateReaderCommentArgs = {
  data: ReaderCommentUpdateInput;
  where: ReaderCommentWhereUniqueInput;
};


export type MutationDeleteManyReaderCommentArgs = {
  where?: Maybe<ReaderCommentWhereInput>;
};


export type MutationUpdateManyReaderCommentArgs = {
  data: ReaderCommentUpdateManyMutationInput;
  where?: Maybe<ReaderCommentWhereInput>;
};


export type MutationUpsertReaderCommentArgs = {
  where: ReaderCommentWhereUniqueInput;
  create: ReaderCommentCreateInput;
  update: ReaderCommentUpdateInput;
};


export type MutationCreateYtVideoArgs = {
  data: YtVideoCreateInput;
};


export type MutationCreateManyYtVideoArgs = {
  data: Array<YtVideoCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};


export type MutationDeleteYtVideoArgs = {
  where: YtVideoWhereUniqueInput;
};


export type MutationUpdateYtVideoArgs = {
  data: YtVideoUpdateInput;
  where: YtVideoWhereUniqueInput;
};


export type MutationDeleteManyYtVideoArgs = {
  where?: Maybe<YtVideoWhereInput>;
};


export type MutationUpdateManyYtVideoArgs = {
  data: YtVideoUpdateManyMutationInput;
  where?: Maybe<YtVideoWhereInput>;
};


export type MutationUpsertYtVideoArgs = {
  where: YtVideoWhereUniqueInput;
  create: YtVideoCreateInput;
  update: YtVideoUpdateInput;
};


export type MutationCreateAddressArgs = {
  description: DescriptionCreateInput;
  contact: ContactCreateWithoutDescriptionInput;
  data: AddressCreateWithoutContactInput;
};


export type MutationDeleteAddressArgs = {
  id: Scalars['String'];
};


export type MutationUpdateAddressArgs = {
  data: AddressUpdateInput;
  where: AddressWhereUniqueInput;
};


export type MutationUpdateBlogCategoryArgs = {
  data: BlogCategoryUpdateInput;
  where: BlogCategoryWhereUniqueInput;
};


export type MutationUpdateBlogPostArgs = {
  data: BlogPostUpdateInput;
  where: BlogPostWhereUniqueInput;
};


export type MutationUpdateCustomFormsArgs = {
  data: CustomFormsUpdateInput;
  where: CustomFormsWhereUniqueInput;
};


export type MutationDeleteDescriptionArgs = {
  where: DescriptionWhereUniqueInput;
};


export type MutationUpdateDescriptionArgs = {
  where: DescriptionWhereUniqueInput;
  data: DescriptionUpdateInput;
};


export type MutationCreateEventArgs = {
  data: EventCreateInput;
};


export type MutationDeleteEventArgs = {
  where: EventWhereUniqueInput;
};


export type MutationUpdateEventArgs = {
  data: EventUpdateInput;
  where: EventWhereUniqueInput;
};


export type MutationCreateFaqArgs = {
  data: FaqCreateInput;
};


export type MutationDeleteFaqArgs = {
  where: FaqWhereUniqueInput;
};


export type MutationUpdateFaqArgs = {
  data: FaqUpdateInput;
  where: FaqWhereUniqueInput;
};


export type MutationUpdatePageArgs = {
  data: PageUpdateInput;
  where: PageWhereUniqueInput;
};


export type MutationUpdateAppSettingsArgs = {
  data: AppSettingsUpdateInput;
};


export type MutationCreateBlogCategoryArgs = {
  description: DescriptionCreateInput;
  data: BlogCategoryCreateWithoutDescriptionInput;
};


export type MutationDeleteBlogCategoryArgs = {
  id: Scalars['ID'];
};


export type MutationCreateBlogPostArgs = {
  description: DescriptionCreateInput;
  data: BlogPostCreateWithoutDescriptionInput;
};


export type MutationDeleteBlogPostArgs = {
  id: Scalars['ID'];
};


export type MutationCreateBrandArgs = {
  data: BrandCreateInput;
  countries?: Maybe<Array<IdField>>;
};


export type MutationUpdateBrandArgs = {
  data: BrandUpdateInput;
  where: BrandWhereUniqueInput;
  countries?: Maybe<Array<IdField>>;
};


export type MutationDeleteBrandArgs = {
  id: Scalars['ID'];
};


export type MutationCreateCategoryArgs = {
  description: DescriptionCreateInput;
  data: CategoryCreateWithoutDescriptionsInput;
};


export type MutationUpdateCategoryArgs = {
  data: CategoryUpdateInput;
  where: CategoryWhereUniqueInput;
};


export type MutationDeleteCategoryArgs = {
  id: Scalars['ID'];
};


export type MutationCreateContentArgs = {
  data: CustomContentCreateInput;
};


export type MutationUpdateContentArgs = {
  where: ContentWhereUniqueInput;
  data: CustomContentUpdateInput;
};


export type MutationDeleteContentArgs = {
  id: Scalars['String'];
};


export type MutationCreateDescriptionArgs = {
  fieldId: Scalars['String'];
  field: Scalars['String'];
  data: DescriptionCreateInput;
};


export type MutationUpdateEcommerceSettingsArgs = {
  data: EcommerceSettingsUpdateInput;
};


export type MutationCreateCustomFormsArgs = {
  data: CustomFormsCreateInput;
};


export type MutationDeleteMessageArgs = {
  id: Scalars['ID'];
};


export type MutationCreatePageArgs = {
  description: DescriptionCreateInput;
  data: PageCreateWithoutDescriptionInput;
};


export type MutationDeletePageArgs = {
  id: Scalars['String'];
};


export type MutationSetHomepageArgs = {
  isHomepage: Scalars['Boolean'];
  id: Scalars['ID'];
};


export type MutationCreateProductArgs = {
  description: DescriptionCreateInput;
  data: ProductCreateWithoutDescriptionsInput;
};


export type MutationUpdateProductArgs = {
  data: ProductUpdateInput;
  where: ProductWhereUniqueInput;
};


export type MutationDeleteProductArgs = {
  id: Scalars['ID'];
};


export type MutationPubSubMutationArgs = {
  message?: Maybe<Scalars['String']>;
};


export type MutationPublisherMutationArgs = {
  message?: Maybe<Scalars['String']>;
};


export type MutationUploadFileArgs = {
  file: Scalars['Upload'];
};


export type MutationUploadProductFileArgs = {
  file: Scalars['Upload'];
};


export type MutationCreateUserArgs = {
  data: UserCreateInput;
};


export type MutationUpdateUserArgs = {
  where: UserWhereUniqueInput;
  data: UserUpdateInput;
};


export type MutationDeleteUserArgs = {
  id: Scalars['ID'];
};


export type MutationLoginArgs = {
  data: AuthLoginInput;
};


export type MutationRegisterArgs = {
  data: UserCreateInput;
};


export type MutationCheckRefreshTokenArgs = {
  refreshToken: Scalars['String'];
};


export type MutationSetHomepageVideoArgs = {
  id: Scalars['ID'];
};

export type NestedBoolFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolFilter>;
};

export type NestedBoolNullableFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolNullableFilter>;
};

export type NestedBoolWithAggregatesFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedBoolFilter>;
  _max?: Maybe<NestedBoolFilter>;
};

export type NestedDateTimeFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeFilter>;
};

export type NestedDateTimeNullableFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeNullableFilter>;
};

export type NestedDateTimeNullableWithAggregatesFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedDateTimeNullableFilter>;
  _max?: Maybe<NestedDateTimeNullableFilter>;
};

export type NestedDateTimeWithAggregatesFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedDateTimeFilter>;
  _max?: Maybe<NestedDateTimeFilter>;
};

export type NestedEnumContentLineupFilter = {
  equals?: Maybe<ContentLineup>;
  in?: Maybe<Array<ContentLineup>>;
  notIn?: Maybe<Array<ContentLineup>>;
  not?: Maybe<NestedEnumContentLineupFilter>;
};

export type NestedEnumContentTypeFilter = {
  equals?: Maybe<ContentType>;
  in?: Maybe<Array<ContentType>>;
  notIn?: Maybe<Array<ContentType>>;
  not?: Maybe<NestedEnumContentTypeFilter>;
};

export type NestedEnumGenderTypeNullableFilter = {
  equals?: Maybe<GenderType>;
  in?: Maybe<Array<GenderType>>;
  notIn?: Maybe<Array<GenderType>>;
  not?: Maybe<NestedEnumGenderTypeNullableFilter>;
};

export type NestedEnumMediaTypeFilter = {
  equals?: Maybe<MediaType>;
  in?: Maybe<Array<MediaType>>;
  notIn?: Maybe<Array<MediaType>>;
  not?: Maybe<NestedEnumMediaTypeFilter>;
};

export type NestedEnumPageTypeFilter = {
  equals?: Maybe<PageType>;
  in?: Maybe<Array<PageType>>;
  notIn?: Maybe<Array<PageType>>;
  not?: Maybe<NestedEnumPageTypeFilter>;
};

export type NestedEnumProductCategoryListTypeFilter = {
  equals?: Maybe<ProductCategoryListType>;
  in?: Maybe<Array<ProductCategoryListType>>;
  notIn?: Maybe<Array<ProductCategoryListType>>;
  not?: Maybe<NestedEnumProductCategoryListTypeFilter>;
};

export type NestedEnumThemeTypeFilter = {
  equals?: Maybe<ThemeType>;
  in?: Maybe<Array<ThemeType>>;
  notIn?: Maybe<Array<ThemeType>>;
  not?: Maybe<NestedEnumThemeTypeFilter>;
};

export type NestedEnumUserRoleFilter = {
  equals?: Maybe<UserRole>;
  in?: Maybe<Array<UserRole>>;
  notIn?: Maybe<Array<UserRole>>;
  not?: Maybe<NestedEnumUserRoleFilter>;
};

export type NestedEnumUserStatusTypeFilter = {
  equals?: Maybe<UserStatusType>;
  in?: Maybe<Array<UserStatusType>>;
  notIn?: Maybe<Array<UserStatusType>>;
  not?: Maybe<NestedEnumUserStatusTypeFilter>;
};

export type NestedFloatFilter = {
  equals?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  notIn?: Maybe<Array<Scalars['Float']>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatFilter>;
};

export type NestedFloatNullableFilter = {
  equals?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  notIn?: Maybe<Array<Scalars['Float']>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatNullableFilter>;
};

export type NestedIntFilter = {
  equals?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  notIn?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntFilter>;
};

export type NestedIntNullableFilter = {
  equals?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  notIn?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntNullableFilter>;
};

export type NestedIntWithAggregatesFilter = {
  equals?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  notIn?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _avg?: Maybe<NestedFloatFilter>;
  _sum?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedIntFilter>;
};

export type NestedJsonNullableFilter = {
  equals?: Maybe<Scalars['JSON']>;
  not?: Maybe<Scalars['JSON']>;
};

export type NestedStringFilter = {
  equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  notIn?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  not?: Maybe<NestedStringFilter>;
};

export type NestedStringNullableFilter = {
  equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  notIn?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  not?: Maybe<NestedStringNullableFilter>;
};

export type NestedStringNullableWithAggregatesFilter = {
  equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  notIn?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  not?: Maybe<NestedStringNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedStringNullableFilter>;
  _max?: Maybe<NestedStringNullableFilter>;
};

export type NestedStringWithAggregatesFilter = {
  equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  notIn?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  not?: Maybe<NestedStringWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedStringFilter>;
  _max?: Maybe<NestedStringFilter>;
};

export type Notification = {
  __typename?: 'Notification';
  id: Scalars['ID'];
  message?: Maybe<Scalars['String']>;
  date: Scalars['DateTime'];
};

export type NullableBoolFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['Boolean']>;
};

export type NullableDateTimeFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['DateTime']>;
};

export type NullableEnumGenderTypeFieldUpdateOperationsInput = {
  set?: Maybe<GenderType>;
};

export type NullableFloatFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['Float']>;
  increment?: Maybe<Scalars['Float']>;
  decrement?: Maybe<Scalars['Float']>;
  multiply?: Maybe<Scalars['Float']>;
  divide?: Maybe<Scalars['Float']>;
};

export type NullableIntFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['Int']>;
  increment?: Maybe<Scalars['Int']>;
  decrement?: Maybe<Scalars['Int']>;
  multiply?: Maybe<Scalars['Int']>;
  divide?: Maybe<Scalars['Int']>;
};

export type NullableStringFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['String']>;
};

export type Page = {
  __typename?: 'Page';
  id: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  photos: Array<Scalars['String']>;
  pageType: PageType;
  showOnHeader: Scalars['Boolean'];
  showOnFooter: Scalars['Boolean'];
  publishedAt?: Maybe<Scalars['String']>;
  isLink: Scalars['Boolean'];
  isHomepage: Scalars['Boolean'];
  status: Scalars['Boolean'];
  order: Scalars['Int'];
  pageId?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['String']>;
  description: Array<Description>;
  content: Array<Content>;
  parent?: Maybe<Page>;
  children: Array<Page>;
};


export type PageDescriptionArgs = {
  where?: Maybe<DescriptionWhereInput>;
  orderBy?: Maybe<Array<DescriptionOrderByWithRelationInput>>;
  cursor?: Maybe<DescriptionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<DescriptionScalarFieldEnum>>;
};


export type PageContentArgs = {
  where?: Maybe<ContentWhereInput>;
  orderBy?: Maybe<Array<ContentOrderByWithRelationInput>>;
  cursor?: Maybe<ContentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<ContentScalarFieldEnum>>;
};


export type PageChildrenArgs = {
  where?: Maybe<PageWhereInput>;
  orderBy?: Maybe<Array<PageOrderByWithRelationInput>>;
  cursor?: Maybe<PageWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<PageScalarFieldEnum>>;
};

export type PageCreateManyParentInput = {
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  pageType?: Maybe<PageType>;
  showOnHeader: Scalars['Boolean'];
  showOnFooter: Scalars['Boolean'];
  publishedAt?: Maybe<Scalars['String']>;
  isLink?: Maybe<Scalars['Boolean']>;
  isHomepage?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  pageId?: Maybe<Scalars['String']>;
  photos?: Maybe<PageCreateManyphotosInput>;
};

export type PageCreateManyParentInputEnvelope = {
  data: Array<PageCreateManyParentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PageCreateManyphotosInput = {
  set: Array<Scalars['String']>;
};

export type PageCreateNestedManyWithoutParentInput = {
  create?: Maybe<Array<PageCreateWithoutParentInput>>;
  connectOrCreate?: Maybe<Array<PageCreateOrConnectWithoutParentInput>>;
  createMany?: Maybe<PageCreateManyParentInputEnvelope>;
  connect?: Maybe<Array<PageWhereUniqueInput>>;
};

export type PageCreateNestedOneWithoutChildrenInput = {
  create?: Maybe<PageCreateWithoutChildrenInput>;
  connectOrCreate?: Maybe<PageCreateOrConnectWithoutChildrenInput>;
  connect?: Maybe<PageWhereUniqueInput>;
};

export type PageCreateNestedOneWithoutContentInput = {
  create?: Maybe<PageCreateWithoutContentInput>;
  connectOrCreate?: Maybe<PageCreateOrConnectWithoutContentInput>;
  connect?: Maybe<PageWhereUniqueInput>;
};

export type PageCreateNestedOneWithoutDescriptionInput = {
  create?: Maybe<PageCreateWithoutDescriptionInput>;
  connectOrCreate?: Maybe<PageCreateOrConnectWithoutDescriptionInput>;
  connect?: Maybe<PageWhereUniqueInput>;
};

export type PageCreateOrConnectWithoutChildrenInput = {
  where: PageWhereUniqueInput;
  create: PageCreateWithoutChildrenInput;
};

export type PageCreateOrConnectWithoutContentInput = {
  where: PageWhereUniqueInput;
  create: PageCreateWithoutContentInput;
};

export type PageCreateOrConnectWithoutDescriptionInput = {
  where: PageWhereUniqueInput;
  create: PageCreateWithoutDescriptionInput;
};

export type PageCreateOrConnectWithoutParentInput = {
  where: PageWhereUniqueInput;
  create: PageCreateWithoutParentInput;
};

export type PageCreateWithoutChildrenInput = {
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  pageType?: Maybe<PageType>;
  showOnHeader: Scalars['Boolean'];
  showOnFooter: Scalars['Boolean'];
  publishedAt?: Maybe<Scalars['String']>;
  isLink?: Maybe<Scalars['Boolean']>;
  isHomepage?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  pageId?: Maybe<Scalars['String']>;
  photos?: Maybe<PageCreatephotosInput>;
  description?: Maybe<DescriptionCreateNestedManyWithoutPageInput>;
  content?: Maybe<ContentCreateNestedManyWithoutPageInput>;
  parent?: Maybe<PageCreateNestedOneWithoutChildrenInput>;
};

export type PageCreateWithoutContentInput = {
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  pageType?: Maybe<PageType>;
  showOnHeader: Scalars['Boolean'];
  showOnFooter: Scalars['Boolean'];
  publishedAt?: Maybe<Scalars['String']>;
  isLink?: Maybe<Scalars['Boolean']>;
  isHomepage?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  pageId?: Maybe<Scalars['String']>;
  photos?: Maybe<PageCreatephotosInput>;
  description?: Maybe<DescriptionCreateNestedManyWithoutPageInput>;
  parent?: Maybe<PageCreateNestedOneWithoutChildrenInput>;
  children?: Maybe<PageCreateNestedManyWithoutParentInput>;
};

export type PageCreateWithoutDescriptionInput = {
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  pageType?: Maybe<PageType>;
  showOnHeader: Scalars['Boolean'];
  showOnFooter: Scalars['Boolean'];
  publishedAt?: Maybe<Scalars['String']>;
  isLink?: Maybe<Scalars['Boolean']>;
  isHomepage?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  pageId?: Maybe<Scalars['String']>;
  photos?: Maybe<PageCreatephotosInput>;
  content?: Maybe<ContentCreateNestedManyWithoutPageInput>;
  parent?: Maybe<PageCreateNestedOneWithoutChildrenInput>;
  children?: Maybe<PageCreateNestedManyWithoutParentInput>;
};

export type PageCreateWithoutParentInput = {
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  pageType?: Maybe<PageType>;
  showOnHeader: Scalars['Boolean'];
  showOnFooter: Scalars['Boolean'];
  publishedAt?: Maybe<Scalars['String']>;
  isLink?: Maybe<Scalars['Boolean']>;
  isHomepage?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  pageId?: Maybe<Scalars['String']>;
  photos?: Maybe<PageCreatephotosInput>;
  description?: Maybe<DescriptionCreateNestedManyWithoutPageInput>;
  content?: Maybe<ContentCreateNestedManyWithoutPageInput>;
  children?: Maybe<PageCreateNestedManyWithoutParentInput>;
};

export type PageCreatephotosInput = {
  set: Array<Scalars['String']>;
};

export type PageListRelationFilter = {
  every?: Maybe<PageWhereInput>;
  some?: Maybe<PageWhereInput>;
  none?: Maybe<PageWhereInput>;
};

export type PageOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type PageOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<DescriptionOrderByRelationAggregateInput>;
  photos?: Maybe<SortOrder>;
  pageType?: Maybe<SortOrder>;
  showOnHeader?: Maybe<SortOrder>;
  showOnFooter?: Maybe<SortOrder>;
  publishedAt?: Maybe<SortOrder>;
  isLink?: Maybe<SortOrder>;
  isHomepage?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  pageId?: Maybe<SortOrder>;
  content?: Maybe<ContentOrderByRelationAggregateInput>;
  parentId?: Maybe<SortOrder>;
  parent?: Maybe<PageOrderByWithRelationInput>;
  children?: Maybe<PageOrderByRelationAggregateInput>;
};

export type PageRelationFilter = {
  is?: Maybe<PageWhereInput>;
  isNot?: Maybe<PageWhereInput>;
};

export enum PageScalarFieldEnum {
  id = 'id',
  updatedAt = 'updatedAt',
  createdAt = 'createdAt',
  photos = 'photos',
  pageType = 'pageType',
  showOnHeader = 'showOnHeader',
  showOnFooter = 'showOnFooter',
  publishedAt = 'publishedAt',
  isLink = 'isLink',
  isHomepage = 'isHomepage',
  status = 'status',
  order = 'order',
  pageId = 'pageId',
  parentId = 'parentId'
}

export type PageScalarWhereInput = {
  AND?: Maybe<Array<PageScalarWhereInput>>;
  OR?: Maybe<Array<PageScalarWhereInput>>;
  NOT?: Maybe<Array<PageScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  photos?: Maybe<StringNullableListFilter>;
  pageType?: Maybe<EnumPageTypeFilter>;
  showOnHeader?: Maybe<BoolFilter>;
  showOnFooter?: Maybe<BoolFilter>;
  publishedAt?: Maybe<StringNullableFilter>;
  isLink?: Maybe<BoolFilter>;
  isHomepage?: Maybe<BoolFilter>;
  status?: Maybe<BoolFilter>;
  order?: Maybe<IntFilter>;
  pageId?: Maybe<StringNullableFilter>;
  parentId?: Maybe<StringNullableFilter>;
};

export enum PageType {
  DEFAULT = 'DEFAULT',
  BLOG = 'BLOG'
}

export type PageUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  pageType?: Maybe<EnumPageTypeFieldUpdateOperationsInput>;
  showOnHeader?: Maybe<BoolFieldUpdateOperationsInput>;
  showOnFooter?: Maybe<BoolFieldUpdateOperationsInput>;
  publishedAt?: Maybe<NullableStringFieldUpdateOperationsInput>;
  isLink?: Maybe<BoolFieldUpdateOperationsInput>;
  isHomepage?: Maybe<BoolFieldUpdateOperationsInput>;
  status?: Maybe<BoolFieldUpdateOperationsInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  pageId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  photos?: Maybe<PageUpdatephotosInput>;
  description?: Maybe<DescriptionUpdateManyWithoutPageInput>;
  content?: Maybe<ContentUpdateManyWithoutPageInput>;
  parent?: Maybe<PageUpdateOneWithoutChildrenInput>;
  children?: Maybe<PageUpdateManyWithoutParentInput>;
};

export type PageUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  pageType?: Maybe<EnumPageTypeFieldUpdateOperationsInput>;
  showOnHeader?: Maybe<BoolFieldUpdateOperationsInput>;
  showOnFooter?: Maybe<BoolFieldUpdateOperationsInput>;
  publishedAt?: Maybe<NullableStringFieldUpdateOperationsInput>;
  isLink?: Maybe<BoolFieldUpdateOperationsInput>;
  isHomepage?: Maybe<BoolFieldUpdateOperationsInput>;
  status?: Maybe<BoolFieldUpdateOperationsInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  pageId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  photos?: Maybe<PageUpdatephotosInput>;
};

export type PageUpdateManyWithWhereWithoutParentInput = {
  where: PageScalarWhereInput;
  data: PageUpdateManyMutationInput;
};

export type PageUpdateManyWithoutParentInput = {
  create?: Maybe<Array<PageCreateWithoutParentInput>>;
  connectOrCreate?: Maybe<Array<PageCreateOrConnectWithoutParentInput>>;
  upsert?: Maybe<Array<PageUpsertWithWhereUniqueWithoutParentInput>>;
  createMany?: Maybe<PageCreateManyParentInputEnvelope>;
  connect?: Maybe<Array<PageWhereUniqueInput>>;
  set?: Maybe<Array<PageWhereUniqueInput>>;
  disconnect?: Maybe<Array<PageWhereUniqueInput>>;
  delete?: Maybe<Array<PageWhereUniqueInput>>;
  update?: Maybe<Array<PageUpdateWithWhereUniqueWithoutParentInput>>;
  updateMany?: Maybe<Array<PageUpdateManyWithWhereWithoutParentInput>>;
  deleteMany?: Maybe<Array<PageScalarWhereInput>>;
};

export type PageUpdateOneWithoutChildrenInput = {
  create?: Maybe<PageCreateWithoutChildrenInput>;
  connectOrCreate?: Maybe<PageCreateOrConnectWithoutChildrenInput>;
  upsert?: Maybe<PageUpsertWithoutChildrenInput>;
  connect?: Maybe<PageWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  update?: Maybe<PageUpdateWithoutChildrenInput>;
};

export type PageUpdateOneWithoutContentInput = {
  create?: Maybe<PageCreateWithoutContentInput>;
  connectOrCreate?: Maybe<PageCreateOrConnectWithoutContentInput>;
  upsert?: Maybe<PageUpsertWithoutContentInput>;
  connect?: Maybe<PageWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  update?: Maybe<PageUpdateWithoutContentInput>;
};

export type PageUpdateOneWithoutDescriptionInput = {
  create?: Maybe<PageCreateWithoutDescriptionInput>;
  connectOrCreate?: Maybe<PageCreateOrConnectWithoutDescriptionInput>;
  upsert?: Maybe<PageUpsertWithoutDescriptionInput>;
  connect?: Maybe<PageWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  update?: Maybe<PageUpdateWithoutDescriptionInput>;
};

export type PageUpdateWithWhereUniqueWithoutParentInput = {
  where: PageWhereUniqueInput;
  data: PageUpdateWithoutParentInput;
};

export type PageUpdateWithoutChildrenInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  pageType?: Maybe<EnumPageTypeFieldUpdateOperationsInput>;
  showOnHeader?: Maybe<BoolFieldUpdateOperationsInput>;
  showOnFooter?: Maybe<BoolFieldUpdateOperationsInput>;
  publishedAt?: Maybe<NullableStringFieldUpdateOperationsInput>;
  isLink?: Maybe<BoolFieldUpdateOperationsInput>;
  isHomepage?: Maybe<BoolFieldUpdateOperationsInput>;
  status?: Maybe<BoolFieldUpdateOperationsInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  pageId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  photos?: Maybe<PageUpdatephotosInput>;
  description?: Maybe<DescriptionUpdateManyWithoutPageInput>;
  content?: Maybe<ContentUpdateManyWithoutPageInput>;
  parent?: Maybe<PageUpdateOneWithoutChildrenInput>;
};

export type PageUpdateWithoutContentInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  pageType?: Maybe<EnumPageTypeFieldUpdateOperationsInput>;
  showOnHeader?: Maybe<BoolFieldUpdateOperationsInput>;
  showOnFooter?: Maybe<BoolFieldUpdateOperationsInput>;
  publishedAt?: Maybe<NullableStringFieldUpdateOperationsInput>;
  isLink?: Maybe<BoolFieldUpdateOperationsInput>;
  isHomepage?: Maybe<BoolFieldUpdateOperationsInput>;
  status?: Maybe<BoolFieldUpdateOperationsInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  pageId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  photos?: Maybe<PageUpdatephotosInput>;
  description?: Maybe<DescriptionUpdateManyWithoutPageInput>;
  parent?: Maybe<PageUpdateOneWithoutChildrenInput>;
  children?: Maybe<PageUpdateManyWithoutParentInput>;
};

export type PageUpdateWithoutDescriptionInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  pageType?: Maybe<EnumPageTypeFieldUpdateOperationsInput>;
  showOnHeader?: Maybe<BoolFieldUpdateOperationsInput>;
  showOnFooter?: Maybe<BoolFieldUpdateOperationsInput>;
  publishedAt?: Maybe<NullableStringFieldUpdateOperationsInput>;
  isLink?: Maybe<BoolFieldUpdateOperationsInput>;
  isHomepage?: Maybe<BoolFieldUpdateOperationsInput>;
  status?: Maybe<BoolFieldUpdateOperationsInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  pageId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  photos?: Maybe<PageUpdatephotosInput>;
  content?: Maybe<ContentUpdateManyWithoutPageInput>;
  parent?: Maybe<PageUpdateOneWithoutChildrenInput>;
  children?: Maybe<PageUpdateManyWithoutParentInput>;
};

export type PageUpdateWithoutParentInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  pageType?: Maybe<EnumPageTypeFieldUpdateOperationsInput>;
  showOnHeader?: Maybe<BoolFieldUpdateOperationsInput>;
  showOnFooter?: Maybe<BoolFieldUpdateOperationsInput>;
  publishedAt?: Maybe<NullableStringFieldUpdateOperationsInput>;
  isLink?: Maybe<BoolFieldUpdateOperationsInput>;
  isHomepage?: Maybe<BoolFieldUpdateOperationsInput>;
  status?: Maybe<BoolFieldUpdateOperationsInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  pageId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  photos?: Maybe<PageUpdatephotosInput>;
  description?: Maybe<DescriptionUpdateManyWithoutPageInput>;
  content?: Maybe<ContentUpdateManyWithoutPageInput>;
  children?: Maybe<PageUpdateManyWithoutParentInput>;
};

export type PageUpdatephotosInput = {
  set?: Maybe<Array<Scalars['String']>>;
  push?: Maybe<Array<Scalars['String']>>;
};

export type PageUpsertWithWhereUniqueWithoutParentInput = {
  where: PageWhereUniqueInput;
  update: PageUpdateWithoutParentInput;
  create: PageCreateWithoutParentInput;
};

export type PageUpsertWithoutChildrenInput = {
  update: PageUpdateWithoutChildrenInput;
  create: PageCreateWithoutChildrenInput;
};

export type PageUpsertWithoutContentInput = {
  update: PageUpdateWithoutContentInput;
  create: PageCreateWithoutContentInput;
};

export type PageUpsertWithoutDescriptionInput = {
  update: PageUpdateWithoutDescriptionInput;
  create: PageCreateWithoutDescriptionInput;
};

export type PageWhereInput = {
  AND?: Maybe<Array<PageWhereInput>>;
  OR?: Maybe<Array<PageWhereInput>>;
  NOT?: Maybe<Array<PageWhereInput>>;
  id?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  description?: Maybe<DescriptionListRelationFilter>;
  photos?: Maybe<StringNullableListFilter>;
  pageType?: Maybe<EnumPageTypeFilter>;
  showOnHeader?: Maybe<BoolFilter>;
  showOnFooter?: Maybe<BoolFilter>;
  publishedAt?: Maybe<StringNullableFilter>;
  isLink?: Maybe<BoolFilter>;
  isHomepage?: Maybe<BoolFilter>;
  status?: Maybe<BoolFilter>;
  order?: Maybe<IntFilter>;
  pageId?: Maybe<StringNullableFilter>;
  content?: Maybe<ContentListRelationFilter>;
  parentId?: Maybe<StringNullableFilter>;
  parent?: Maybe<PageRelationFilter>;
  children?: Maybe<PageListRelationFilter>;
};

export type PageWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type Price = {
  __typename?: 'Price';
  id: Scalars['String'];
  price: Scalars['Int'];
  salesPrice: Scalars['Int'];
  minimumQuantity?: Maybe<Scalars['Int']>;
  expiryDate?: Maybe<Scalars['DateTime']>;
  productId?: Maybe<Scalars['String']>;
};

export type PriceCreateManyProductInput = {
  id?: Maybe<Scalars['String']>;
  price: Scalars['Int'];
  salesPrice: Scalars['Int'];
  minimumQuantity?: Maybe<Scalars['Int']>;
  expiryDate?: Maybe<Scalars['DateTime']>;
};

export type PriceCreateManyProductInputEnvelope = {
  data: Array<PriceCreateManyProductInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PriceCreateNestedManyWithoutProductInput = {
  create?: Maybe<Array<PriceCreateWithoutProductInput>>;
  connectOrCreate?: Maybe<Array<PriceCreateOrConnectWithoutProductInput>>;
  createMany?: Maybe<PriceCreateManyProductInputEnvelope>;
  connect?: Maybe<Array<PriceWhereUniqueInput>>;
};

export type PriceCreateOrConnectWithoutProductInput = {
  where: PriceWhereUniqueInput;
  create: PriceCreateWithoutProductInput;
};

export type PriceCreateWithoutProductInput = {
  id?: Maybe<Scalars['String']>;
  price: Scalars['Int'];
  salesPrice: Scalars['Int'];
  minimumQuantity?: Maybe<Scalars['Int']>;
  expiryDate?: Maybe<Scalars['DateTime']>;
};

export type PriceListRelationFilter = {
  every?: Maybe<PriceWhereInput>;
  some?: Maybe<PriceWhereInput>;
  none?: Maybe<PriceWhereInput>;
};

export type PriceOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type PriceOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  price?: Maybe<SortOrder>;
  salesPrice?: Maybe<SortOrder>;
  minimumQuantity?: Maybe<SortOrder>;
  expiryDate?: Maybe<SortOrder>;
  product?: Maybe<ProductOrderByWithRelationInput>;
  productId?: Maybe<SortOrder>;
};

export enum PriceScalarFieldEnum {
  id = 'id',
  price = 'price',
  salesPrice = 'salesPrice',
  minimumQuantity = 'minimumQuantity',
  expiryDate = 'expiryDate',
  productId = 'productId'
}

export type PriceScalarWhereInput = {
  AND?: Maybe<Array<PriceScalarWhereInput>>;
  OR?: Maybe<Array<PriceScalarWhereInput>>;
  NOT?: Maybe<Array<PriceScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  price?: Maybe<IntFilter>;
  salesPrice?: Maybe<IntFilter>;
  minimumQuantity?: Maybe<IntNullableFilter>;
  expiryDate?: Maybe<DateTimeNullableFilter>;
  productId?: Maybe<StringNullableFilter>;
};

export type PriceUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  price?: Maybe<IntFieldUpdateOperationsInput>;
  salesPrice?: Maybe<IntFieldUpdateOperationsInput>;
  minimumQuantity?: Maybe<NullableIntFieldUpdateOperationsInput>;
  expiryDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type PriceUpdateManyWithWhereWithoutProductInput = {
  where: PriceScalarWhereInput;
  data: PriceUpdateManyMutationInput;
};

export type PriceUpdateManyWithoutProductInput = {
  create?: Maybe<Array<PriceCreateWithoutProductInput>>;
  connectOrCreate?: Maybe<Array<PriceCreateOrConnectWithoutProductInput>>;
  upsert?: Maybe<Array<PriceUpsertWithWhereUniqueWithoutProductInput>>;
  createMany?: Maybe<PriceCreateManyProductInputEnvelope>;
  connect?: Maybe<Array<PriceWhereUniqueInput>>;
  set?: Maybe<Array<PriceWhereUniqueInput>>;
  disconnect?: Maybe<Array<PriceWhereUniqueInput>>;
  delete?: Maybe<Array<PriceWhereUniqueInput>>;
  update?: Maybe<Array<PriceUpdateWithWhereUniqueWithoutProductInput>>;
  updateMany?: Maybe<Array<PriceUpdateManyWithWhereWithoutProductInput>>;
  deleteMany?: Maybe<Array<PriceScalarWhereInput>>;
};

export type PriceUpdateWithWhereUniqueWithoutProductInput = {
  where: PriceWhereUniqueInput;
  data: PriceUpdateWithoutProductInput;
};

export type PriceUpdateWithoutProductInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  price?: Maybe<IntFieldUpdateOperationsInput>;
  salesPrice?: Maybe<IntFieldUpdateOperationsInput>;
  minimumQuantity?: Maybe<NullableIntFieldUpdateOperationsInput>;
  expiryDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type PriceUpsertWithWhereUniqueWithoutProductInput = {
  where: PriceWhereUniqueInput;
  update: PriceUpdateWithoutProductInput;
  create: PriceCreateWithoutProductInput;
};

export type PriceWhereInput = {
  AND?: Maybe<Array<PriceWhereInput>>;
  OR?: Maybe<Array<PriceWhereInput>>;
  NOT?: Maybe<Array<PriceWhereInput>>;
  id?: Maybe<StringFilter>;
  price?: Maybe<IntFilter>;
  salesPrice?: Maybe<IntFilter>;
  minimumQuantity?: Maybe<IntNullableFilter>;
  expiryDate?: Maybe<DateTimeNullableFilter>;
  product?: Maybe<ProductRelationFilter>;
  productId?: Maybe<StringNullableFilter>;
};

export type PriceWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type Product = {
  __typename?: 'Product';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  sku: Scalars['String'];
  order: Scalars['Int'];
  buyLink?: Maybe<Scalars['String']>;
  disabled: Scalars['Boolean'];
  photos: Array<Scalars['String']>;
  files?: Maybe<Scalars['JSON']>;
  categoryId?: Maybe<Scalars['String']>;
  brandId: Scalars['String'];
  content: Array<Content>;
  category?: Maybe<Category>;
  prices: Array<Price>;
  brand: Brand;
  descriptions: Array<Description>;
  messages: Array<CustomForms>;
  highlightedContents: Array<Content>;
  readerComments: Array<ReaderComment>;
};


export type ProductContentArgs = {
  where?: Maybe<ContentWhereInput>;
  orderBy?: Maybe<Array<ContentOrderByWithRelationInput>>;
  cursor?: Maybe<ContentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<ContentScalarFieldEnum>>;
};


export type ProductPricesArgs = {
  where?: Maybe<PriceWhereInput>;
  orderBy?: Maybe<Array<PriceOrderByWithRelationInput>>;
  cursor?: Maybe<PriceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<PriceScalarFieldEnum>>;
};


export type ProductDescriptionsArgs = {
  where?: Maybe<DescriptionWhereInput>;
  orderBy?: Maybe<Array<DescriptionOrderByWithRelationInput>>;
  cursor?: Maybe<DescriptionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<DescriptionScalarFieldEnum>>;
};


export type ProductMessagesArgs = {
  where?: Maybe<CustomFormsWhereInput>;
  orderBy?: Maybe<Array<CustomFormsOrderByWithRelationInput>>;
  cursor?: Maybe<CustomFormsWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<CustomFormsScalarFieldEnum>>;
};


export type ProductHighlightedContentsArgs = {
  where?: Maybe<ContentWhereInput>;
  orderBy?: Maybe<Array<ContentOrderByWithRelationInput>>;
  cursor?: Maybe<ContentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<ContentScalarFieldEnum>>;
};


export type ProductReaderCommentsArgs = {
  where?: Maybe<ReaderCommentWhereInput>;
  orderBy?: Maybe<Array<ReaderCommentOrderByWithRelationInput>>;
  cursor?: Maybe<ReaderCommentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<ReaderCommentScalarFieldEnum>>;
};

export enum ProductCategoryListType {
  SLIDE = 'SLIDE',
  CARD = 'CARD'
}

export type ProductCreateManyBrandInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  sku: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  buyLink?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  files?: Maybe<Scalars['JSON']>;
  categoryId?: Maybe<Scalars['String']>;
  photos?: Maybe<ProductCreateManyphotosInput>;
};

export type ProductCreateManyBrandInputEnvelope = {
  data: Array<ProductCreateManyBrandInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ProductCreateManyCategoryInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  sku: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  buyLink?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  files?: Maybe<Scalars['JSON']>;
  brandId: Scalars['String'];
  photos?: Maybe<ProductCreateManyphotosInput>;
};

export type ProductCreateManyCategoryInputEnvelope = {
  data: Array<ProductCreateManyCategoryInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ProductCreateManyphotosInput = {
  set: Array<Scalars['String']>;
};

export type ProductCreateNestedManyWithoutBrandInput = {
  create?: Maybe<Array<ProductCreateWithoutBrandInput>>;
  connectOrCreate?: Maybe<Array<ProductCreateOrConnectWithoutBrandInput>>;
  createMany?: Maybe<ProductCreateManyBrandInputEnvelope>;
  connect?: Maybe<Array<ProductWhereUniqueInput>>;
};

export type ProductCreateNestedManyWithoutCategoryInput = {
  create?: Maybe<Array<ProductCreateWithoutCategoryInput>>;
  connectOrCreate?: Maybe<Array<ProductCreateOrConnectWithoutCategoryInput>>;
  createMany?: Maybe<ProductCreateManyCategoryInputEnvelope>;
  connect?: Maybe<Array<ProductWhereUniqueInput>>;
};

export type ProductCreateNestedManyWithoutHighlightedContentsInput = {
  create?: Maybe<Array<ProductCreateWithoutHighlightedContentsInput>>;
  connectOrCreate?: Maybe<Array<ProductCreateOrConnectWithoutHighlightedContentsInput>>;
  connect?: Maybe<Array<ProductWhereUniqueInput>>;
};

export type ProductCreateNestedOneWithoutContentInput = {
  create?: Maybe<ProductCreateWithoutContentInput>;
  connectOrCreate?: Maybe<ProductCreateOrConnectWithoutContentInput>;
  connect?: Maybe<ProductWhereUniqueInput>;
};

export type ProductCreateNestedOneWithoutDescriptionsInput = {
  create?: Maybe<ProductCreateWithoutDescriptionsInput>;
  connectOrCreate?: Maybe<ProductCreateOrConnectWithoutDescriptionsInput>;
  connect?: Maybe<ProductWhereUniqueInput>;
};

export type ProductCreateNestedOneWithoutMessagesInput = {
  create?: Maybe<ProductCreateWithoutMessagesInput>;
  connectOrCreate?: Maybe<ProductCreateOrConnectWithoutMessagesInput>;
  connect?: Maybe<ProductWhereUniqueInput>;
};

export type ProductCreateNestedOneWithoutReaderCommentsInput = {
  create?: Maybe<ProductCreateWithoutReaderCommentsInput>;
  connectOrCreate?: Maybe<ProductCreateOrConnectWithoutReaderCommentsInput>;
  connect?: Maybe<ProductWhereUniqueInput>;
};

export type ProductCreateOrConnectWithoutBrandInput = {
  where: ProductWhereUniqueInput;
  create: ProductCreateWithoutBrandInput;
};

export type ProductCreateOrConnectWithoutCategoryInput = {
  where: ProductWhereUniqueInput;
  create: ProductCreateWithoutCategoryInput;
};

export type ProductCreateOrConnectWithoutContentInput = {
  where: ProductWhereUniqueInput;
  create: ProductCreateWithoutContentInput;
};

export type ProductCreateOrConnectWithoutDescriptionsInput = {
  where: ProductWhereUniqueInput;
  create: ProductCreateWithoutDescriptionsInput;
};

export type ProductCreateOrConnectWithoutHighlightedContentsInput = {
  where: ProductWhereUniqueInput;
  create: ProductCreateWithoutHighlightedContentsInput;
};

export type ProductCreateOrConnectWithoutMessagesInput = {
  where: ProductWhereUniqueInput;
  create: ProductCreateWithoutMessagesInput;
};

export type ProductCreateOrConnectWithoutReaderCommentsInput = {
  where: ProductWhereUniqueInput;
  create: ProductCreateWithoutReaderCommentsInput;
};

export type ProductCreateWithoutBrandInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  sku: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  buyLink?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  files?: Maybe<Scalars['JSON']>;
  photos?: Maybe<ProductCreatephotosInput>;
  content?: Maybe<ContentCreateNestedManyWithoutProductInput>;
  category?: Maybe<CategoryCreateNestedOneWithoutProductsInput>;
  prices?: Maybe<PriceCreateNestedManyWithoutProductInput>;
  descriptions?: Maybe<DescriptionCreateNestedManyWithoutProductInput>;
  messages?: Maybe<CustomFormsCreateNestedManyWithoutProductInput>;
  highlightedContents?: Maybe<ContentCreateNestedManyWithoutHighlightedProductsInput>;
  readerComments?: Maybe<ReaderCommentCreateNestedManyWithoutProductInput>;
};

export type ProductCreateWithoutCategoryInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  sku: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  buyLink?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  files?: Maybe<Scalars['JSON']>;
  photos?: Maybe<ProductCreatephotosInput>;
  content?: Maybe<ContentCreateNestedManyWithoutProductInput>;
  prices?: Maybe<PriceCreateNestedManyWithoutProductInput>;
  brand: BrandCreateNestedOneWithoutProductsInput;
  descriptions?: Maybe<DescriptionCreateNestedManyWithoutProductInput>;
  messages?: Maybe<CustomFormsCreateNestedManyWithoutProductInput>;
  highlightedContents?: Maybe<ContentCreateNestedManyWithoutHighlightedProductsInput>;
  readerComments?: Maybe<ReaderCommentCreateNestedManyWithoutProductInput>;
};

export type ProductCreateWithoutContentInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  sku: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  buyLink?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  files?: Maybe<Scalars['JSON']>;
  photos?: Maybe<ProductCreatephotosInput>;
  category?: Maybe<CategoryCreateNestedOneWithoutProductsInput>;
  prices?: Maybe<PriceCreateNestedManyWithoutProductInput>;
  brand: BrandCreateNestedOneWithoutProductsInput;
  descriptions?: Maybe<DescriptionCreateNestedManyWithoutProductInput>;
  messages?: Maybe<CustomFormsCreateNestedManyWithoutProductInput>;
  highlightedContents?: Maybe<ContentCreateNestedManyWithoutHighlightedProductsInput>;
  readerComments?: Maybe<ReaderCommentCreateNestedManyWithoutProductInput>;
};

export type ProductCreateWithoutDescriptionsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  sku: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  buyLink?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  files?: Maybe<Scalars['JSON']>;
  photos?: Maybe<ProductCreatephotosInput>;
  content?: Maybe<ContentCreateNestedManyWithoutProductInput>;
  category?: Maybe<CategoryCreateNestedOneWithoutProductsInput>;
  prices?: Maybe<PriceCreateNestedManyWithoutProductInput>;
  brand: BrandCreateNestedOneWithoutProductsInput;
  messages?: Maybe<CustomFormsCreateNestedManyWithoutProductInput>;
  highlightedContents?: Maybe<ContentCreateNestedManyWithoutHighlightedProductsInput>;
  readerComments?: Maybe<ReaderCommentCreateNestedManyWithoutProductInput>;
};

export type ProductCreateWithoutHighlightedContentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  sku: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  buyLink?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  files?: Maybe<Scalars['JSON']>;
  photos?: Maybe<ProductCreatephotosInput>;
  content?: Maybe<ContentCreateNestedManyWithoutProductInput>;
  category?: Maybe<CategoryCreateNestedOneWithoutProductsInput>;
  prices?: Maybe<PriceCreateNestedManyWithoutProductInput>;
  brand: BrandCreateNestedOneWithoutProductsInput;
  descriptions?: Maybe<DescriptionCreateNestedManyWithoutProductInput>;
  messages?: Maybe<CustomFormsCreateNestedManyWithoutProductInput>;
  readerComments?: Maybe<ReaderCommentCreateNestedManyWithoutProductInput>;
};

export type ProductCreateWithoutMessagesInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  sku: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  buyLink?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  files?: Maybe<Scalars['JSON']>;
  photos?: Maybe<ProductCreatephotosInput>;
  content?: Maybe<ContentCreateNestedManyWithoutProductInput>;
  category?: Maybe<CategoryCreateNestedOneWithoutProductsInput>;
  prices?: Maybe<PriceCreateNestedManyWithoutProductInput>;
  brand: BrandCreateNestedOneWithoutProductsInput;
  descriptions?: Maybe<DescriptionCreateNestedManyWithoutProductInput>;
  highlightedContents?: Maybe<ContentCreateNestedManyWithoutHighlightedProductsInput>;
  readerComments?: Maybe<ReaderCommentCreateNestedManyWithoutProductInput>;
};

export type ProductCreateWithoutReaderCommentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  sku: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  buyLink?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  files?: Maybe<Scalars['JSON']>;
  photos?: Maybe<ProductCreatephotosInput>;
  content?: Maybe<ContentCreateNestedManyWithoutProductInput>;
  category?: Maybe<CategoryCreateNestedOneWithoutProductsInput>;
  prices?: Maybe<PriceCreateNestedManyWithoutProductInput>;
  brand: BrandCreateNestedOneWithoutProductsInput;
  descriptions?: Maybe<DescriptionCreateNestedManyWithoutProductInput>;
  messages?: Maybe<CustomFormsCreateNestedManyWithoutProductInput>;
  highlightedContents?: Maybe<ContentCreateNestedManyWithoutHighlightedProductsInput>;
};

export type ProductCreatephotosInput = {
  set: Array<Scalars['String']>;
};

export type ProductListRelationFilter = {
  every?: Maybe<ProductWhereInput>;
  some?: Maybe<ProductWhereInput>;
  none?: Maybe<ProductWhereInput>;
};

export type ProductOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type ProductOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  sku?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  buyLink?: Maybe<SortOrder>;
  disabled?: Maybe<SortOrder>;
  photos?: Maybe<SortOrder>;
  files?: Maybe<SortOrder>;
  content?: Maybe<ContentOrderByRelationAggregateInput>;
  category?: Maybe<CategoryOrderByWithRelationInput>;
  categoryId?: Maybe<SortOrder>;
  prices?: Maybe<PriceOrderByRelationAggregateInput>;
  brand?: Maybe<BrandOrderByWithRelationInput>;
  brandId?: Maybe<SortOrder>;
  descriptions?: Maybe<DescriptionOrderByRelationAggregateInput>;
  messages?: Maybe<CustomFormsOrderByRelationAggregateInput>;
  highlightedContents?: Maybe<ContentOrderByRelationAggregateInput>;
  readerComments?: Maybe<ReaderCommentOrderByRelationAggregateInput>;
};

export type ProductRelationFilter = {
  is?: Maybe<ProductWhereInput>;
  isNot?: Maybe<ProductWhereInput>;
};

export enum ProductScalarFieldEnum {
  id = 'id',
  createdAt = 'createdAt',
  updatedAt = 'updatedAt',
  sku = 'sku',
  order = 'order',
  buyLink = 'buyLink',
  disabled = 'disabled',
  photos = 'photos',
  files = 'files',
  categoryId = 'categoryId',
  brandId = 'brandId'
}

export type ProductScalarWhereInput = {
  AND?: Maybe<Array<ProductScalarWhereInput>>;
  OR?: Maybe<Array<ProductScalarWhereInput>>;
  NOT?: Maybe<Array<ProductScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  sku?: Maybe<StringFilter>;
  order?: Maybe<IntFilter>;
  buyLink?: Maybe<StringNullableFilter>;
  disabled?: Maybe<BoolFilter>;
  photos?: Maybe<StringNullableListFilter>;
  files?: Maybe<JsonNullableFilter>;
  categoryId?: Maybe<StringNullableFilter>;
  brandId?: Maybe<StringFilter>;
};

export type ProductUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  sku?: Maybe<StringFieldUpdateOperationsInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  buyLink?: Maybe<NullableStringFieldUpdateOperationsInput>;
  disabled?: Maybe<BoolFieldUpdateOperationsInput>;
  files?: Maybe<Scalars['JSON']>;
  photos?: Maybe<ProductUpdatephotosInput>;
  content?: Maybe<ContentUpdateManyWithoutProductInput>;
  category?: Maybe<CategoryUpdateOneWithoutProductsInput>;
  prices?: Maybe<PriceUpdateManyWithoutProductInput>;
  brand?: Maybe<BrandUpdateOneRequiredWithoutProductsInput>;
  descriptions?: Maybe<DescriptionUpdateManyWithoutProductInput>;
  messages?: Maybe<CustomFormsUpdateManyWithoutProductInput>;
  highlightedContents?: Maybe<ContentUpdateManyWithoutHighlightedProductsInput>;
  readerComments?: Maybe<ReaderCommentUpdateManyWithoutProductInput>;
};

export type ProductUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  sku?: Maybe<StringFieldUpdateOperationsInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  buyLink?: Maybe<NullableStringFieldUpdateOperationsInput>;
  disabled?: Maybe<BoolFieldUpdateOperationsInput>;
  files?: Maybe<Scalars['JSON']>;
  photos?: Maybe<ProductUpdatephotosInput>;
};

export type ProductUpdateManyWithWhereWithoutBrandInput = {
  where: ProductScalarWhereInput;
  data: ProductUpdateManyMutationInput;
};

export type ProductUpdateManyWithWhereWithoutCategoryInput = {
  where: ProductScalarWhereInput;
  data: ProductUpdateManyMutationInput;
};

export type ProductUpdateManyWithWhereWithoutHighlightedContentsInput = {
  where: ProductScalarWhereInput;
  data: ProductUpdateManyMutationInput;
};

export type ProductUpdateManyWithoutBrandInput = {
  create?: Maybe<Array<ProductCreateWithoutBrandInput>>;
  connectOrCreate?: Maybe<Array<ProductCreateOrConnectWithoutBrandInput>>;
  upsert?: Maybe<Array<ProductUpsertWithWhereUniqueWithoutBrandInput>>;
  createMany?: Maybe<ProductCreateManyBrandInputEnvelope>;
  connect?: Maybe<Array<ProductWhereUniqueInput>>;
  set?: Maybe<Array<ProductWhereUniqueInput>>;
  disconnect?: Maybe<Array<ProductWhereUniqueInput>>;
  delete?: Maybe<Array<ProductWhereUniqueInput>>;
  update?: Maybe<Array<ProductUpdateWithWhereUniqueWithoutBrandInput>>;
  updateMany?: Maybe<Array<ProductUpdateManyWithWhereWithoutBrandInput>>;
  deleteMany?: Maybe<Array<ProductScalarWhereInput>>;
};

export type ProductUpdateManyWithoutCategoryInput = {
  create?: Maybe<Array<ProductCreateWithoutCategoryInput>>;
  connectOrCreate?: Maybe<Array<ProductCreateOrConnectWithoutCategoryInput>>;
  upsert?: Maybe<Array<ProductUpsertWithWhereUniqueWithoutCategoryInput>>;
  createMany?: Maybe<ProductCreateManyCategoryInputEnvelope>;
  connect?: Maybe<Array<ProductWhereUniqueInput>>;
  set?: Maybe<Array<ProductWhereUniqueInput>>;
  disconnect?: Maybe<Array<ProductWhereUniqueInput>>;
  delete?: Maybe<Array<ProductWhereUniqueInput>>;
  update?: Maybe<Array<ProductUpdateWithWhereUniqueWithoutCategoryInput>>;
  updateMany?: Maybe<Array<ProductUpdateManyWithWhereWithoutCategoryInput>>;
  deleteMany?: Maybe<Array<ProductScalarWhereInput>>;
};

export type ProductUpdateManyWithoutHighlightedContentsInput = {
  create?: Maybe<Array<ProductCreateWithoutHighlightedContentsInput>>;
  connectOrCreate?: Maybe<Array<ProductCreateOrConnectWithoutHighlightedContentsInput>>;
  upsert?: Maybe<Array<ProductUpsertWithWhereUniqueWithoutHighlightedContentsInput>>;
  connect?: Maybe<Array<ProductWhereUniqueInput>>;
  set?: Maybe<Array<ProductWhereUniqueInput>>;
  disconnect?: Maybe<Array<ProductWhereUniqueInput>>;
  delete?: Maybe<Array<ProductWhereUniqueInput>>;
  update?: Maybe<Array<ProductUpdateWithWhereUniqueWithoutHighlightedContentsInput>>;
  updateMany?: Maybe<Array<ProductUpdateManyWithWhereWithoutHighlightedContentsInput>>;
  deleteMany?: Maybe<Array<ProductScalarWhereInput>>;
};

export type ProductUpdateOneWithoutContentInput = {
  create?: Maybe<ProductCreateWithoutContentInput>;
  connectOrCreate?: Maybe<ProductCreateOrConnectWithoutContentInput>;
  upsert?: Maybe<ProductUpsertWithoutContentInput>;
  connect?: Maybe<ProductWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ProductUpdateWithoutContentInput>;
};

export type ProductUpdateOneWithoutDescriptionsInput = {
  create?: Maybe<ProductCreateWithoutDescriptionsInput>;
  connectOrCreate?: Maybe<ProductCreateOrConnectWithoutDescriptionsInput>;
  upsert?: Maybe<ProductUpsertWithoutDescriptionsInput>;
  connect?: Maybe<ProductWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ProductUpdateWithoutDescriptionsInput>;
};

export type ProductUpdateOneWithoutMessagesInput = {
  create?: Maybe<ProductCreateWithoutMessagesInput>;
  connectOrCreate?: Maybe<ProductCreateOrConnectWithoutMessagesInput>;
  upsert?: Maybe<ProductUpsertWithoutMessagesInput>;
  connect?: Maybe<ProductWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ProductUpdateWithoutMessagesInput>;
};

export type ProductUpdateOneWithoutReaderCommentsInput = {
  create?: Maybe<ProductCreateWithoutReaderCommentsInput>;
  connectOrCreate?: Maybe<ProductCreateOrConnectWithoutReaderCommentsInput>;
  upsert?: Maybe<ProductUpsertWithoutReaderCommentsInput>;
  connect?: Maybe<ProductWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ProductUpdateWithoutReaderCommentsInput>;
};

export type ProductUpdateWithWhereUniqueWithoutBrandInput = {
  where: ProductWhereUniqueInput;
  data: ProductUpdateWithoutBrandInput;
};

export type ProductUpdateWithWhereUniqueWithoutCategoryInput = {
  where: ProductWhereUniqueInput;
  data: ProductUpdateWithoutCategoryInput;
};

export type ProductUpdateWithWhereUniqueWithoutHighlightedContentsInput = {
  where: ProductWhereUniqueInput;
  data: ProductUpdateWithoutHighlightedContentsInput;
};

export type ProductUpdateWithoutBrandInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  sku?: Maybe<StringFieldUpdateOperationsInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  buyLink?: Maybe<NullableStringFieldUpdateOperationsInput>;
  disabled?: Maybe<BoolFieldUpdateOperationsInput>;
  files?: Maybe<Scalars['JSON']>;
  photos?: Maybe<ProductUpdatephotosInput>;
  content?: Maybe<ContentUpdateManyWithoutProductInput>;
  category?: Maybe<CategoryUpdateOneWithoutProductsInput>;
  prices?: Maybe<PriceUpdateManyWithoutProductInput>;
  descriptions?: Maybe<DescriptionUpdateManyWithoutProductInput>;
  messages?: Maybe<CustomFormsUpdateManyWithoutProductInput>;
  highlightedContents?: Maybe<ContentUpdateManyWithoutHighlightedProductsInput>;
  readerComments?: Maybe<ReaderCommentUpdateManyWithoutProductInput>;
};

export type ProductUpdateWithoutCategoryInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  sku?: Maybe<StringFieldUpdateOperationsInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  buyLink?: Maybe<NullableStringFieldUpdateOperationsInput>;
  disabled?: Maybe<BoolFieldUpdateOperationsInput>;
  files?: Maybe<Scalars['JSON']>;
  photos?: Maybe<ProductUpdatephotosInput>;
  content?: Maybe<ContentUpdateManyWithoutProductInput>;
  prices?: Maybe<PriceUpdateManyWithoutProductInput>;
  brand?: Maybe<BrandUpdateOneRequiredWithoutProductsInput>;
  descriptions?: Maybe<DescriptionUpdateManyWithoutProductInput>;
  messages?: Maybe<CustomFormsUpdateManyWithoutProductInput>;
  highlightedContents?: Maybe<ContentUpdateManyWithoutHighlightedProductsInput>;
  readerComments?: Maybe<ReaderCommentUpdateManyWithoutProductInput>;
};

export type ProductUpdateWithoutContentInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  sku?: Maybe<StringFieldUpdateOperationsInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  buyLink?: Maybe<NullableStringFieldUpdateOperationsInput>;
  disabled?: Maybe<BoolFieldUpdateOperationsInput>;
  files?: Maybe<Scalars['JSON']>;
  photos?: Maybe<ProductUpdatephotosInput>;
  category?: Maybe<CategoryUpdateOneWithoutProductsInput>;
  prices?: Maybe<PriceUpdateManyWithoutProductInput>;
  brand?: Maybe<BrandUpdateOneRequiredWithoutProductsInput>;
  descriptions?: Maybe<DescriptionUpdateManyWithoutProductInput>;
  messages?: Maybe<CustomFormsUpdateManyWithoutProductInput>;
  highlightedContents?: Maybe<ContentUpdateManyWithoutHighlightedProductsInput>;
  readerComments?: Maybe<ReaderCommentUpdateManyWithoutProductInput>;
};

export type ProductUpdateWithoutDescriptionsInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  sku?: Maybe<StringFieldUpdateOperationsInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  buyLink?: Maybe<NullableStringFieldUpdateOperationsInput>;
  disabled?: Maybe<BoolFieldUpdateOperationsInput>;
  files?: Maybe<Scalars['JSON']>;
  photos?: Maybe<ProductUpdatephotosInput>;
  content?: Maybe<ContentUpdateManyWithoutProductInput>;
  category?: Maybe<CategoryUpdateOneWithoutProductsInput>;
  prices?: Maybe<PriceUpdateManyWithoutProductInput>;
  brand?: Maybe<BrandUpdateOneRequiredWithoutProductsInput>;
  messages?: Maybe<CustomFormsUpdateManyWithoutProductInput>;
  highlightedContents?: Maybe<ContentUpdateManyWithoutHighlightedProductsInput>;
  readerComments?: Maybe<ReaderCommentUpdateManyWithoutProductInput>;
};

export type ProductUpdateWithoutHighlightedContentsInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  sku?: Maybe<StringFieldUpdateOperationsInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  buyLink?: Maybe<NullableStringFieldUpdateOperationsInput>;
  disabled?: Maybe<BoolFieldUpdateOperationsInput>;
  files?: Maybe<Scalars['JSON']>;
  photos?: Maybe<ProductUpdatephotosInput>;
  content?: Maybe<ContentUpdateManyWithoutProductInput>;
  category?: Maybe<CategoryUpdateOneWithoutProductsInput>;
  prices?: Maybe<PriceUpdateManyWithoutProductInput>;
  brand?: Maybe<BrandUpdateOneRequiredWithoutProductsInput>;
  descriptions?: Maybe<DescriptionUpdateManyWithoutProductInput>;
  messages?: Maybe<CustomFormsUpdateManyWithoutProductInput>;
  readerComments?: Maybe<ReaderCommentUpdateManyWithoutProductInput>;
};

export type ProductUpdateWithoutMessagesInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  sku?: Maybe<StringFieldUpdateOperationsInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  buyLink?: Maybe<NullableStringFieldUpdateOperationsInput>;
  disabled?: Maybe<BoolFieldUpdateOperationsInput>;
  files?: Maybe<Scalars['JSON']>;
  photos?: Maybe<ProductUpdatephotosInput>;
  content?: Maybe<ContentUpdateManyWithoutProductInput>;
  category?: Maybe<CategoryUpdateOneWithoutProductsInput>;
  prices?: Maybe<PriceUpdateManyWithoutProductInput>;
  brand?: Maybe<BrandUpdateOneRequiredWithoutProductsInput>;
  descriptions?: Maybe<DescriptionUpdateManyWithoutProductInput>;
  highlightedContents?: Maybe<ContentUpdateManyWithoutHighlightedProductsInput>;
  readerComments?: Maybe<ReaderCommentUpdateManyWithoutProductInput>;
};

export type ProductUpdateWithoutReaderCommentsInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  sku?: Maybe<StringFieldUpdateOperationsInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  buyLink?: Maybe<NullableStringFieldUpdateOperationsInput>;
  disabled?: Maybe<BoolFieldUpdateOperationsInput>;
  files?: Maybe<Scalars['JSON']>;
  photos?: Maybe<ProductUpdatephotosInput>;
  content?: Maybe<ContentUpdateManyWithoutProductInput>;
  category?: Maybe<CategoryUpdateOneWithoutProductsInput>;
  prices?: Maybe<PriceUpdateManyWithoutProductInput>;
  brand?: Maybe<BrandUpdateOneRequiredWithoutProductsInput>;
  descriptions?: Maybe<DescriptionUpdateManyWithoutProductInput>;
  messages?: Maybe<CustomFormsUpdateManyWithoutProductInput>;
  highlightedContents?: Maybe<ContentUpdateManyWithoutHighlightedProductsInput>;
};

export type ProductUpdatephotosInput = {
  set?: Maybe<Array<Scalars['String']>>;
  push?: Maybe<Array<Scalars['String']>>;
};

export type ProductUpsertWithWhereUniqueWithoutBrandInput = {
  where: ProductWhereUniqueInput;
  update: ProductUpdateWithoutBrandInput;
  create: ProductCreateWithoutBrandInput;
};

export type ProductUpsertWithWhereUniqueWithoutCategoryInput = {
  where: ProductWhereUniqueInput;
  update: ProductUpdateWithoutCategoryInput;
  create: ProductCreateWithoutCategoryInput;
};

export type ProductUpsertWithWhereUniqueWithoutHighlightedContentsInput = {
  where: ProductWhereUniqueInput;
  update: ProductUpdateWithoutHighlightedContentsInput;
  create: ProductCreateWithoutHighlightedContentsInput;
};

export type ProductUpsertWithoutContentInput = {
  update: ProductUpdateWithoutContentInput;
  create: ProductCreateWithoutContentInput;
};

export type ProductUpsertWithoutDescriptionsInput = {
  update: ProductUpdateWithoutDescriptionsInput;
  create: ProductCreateWithoutDescriptionsInput;
};

export type ProductUpsertWithoutMessagesInput = {
  update: ProductUpdateWithoutMessagesInput;
  create: ProductCreateWithoutMessagesInput;
};

export type ProductUpsertWithoutReaderCommentsInput = {
  update: ProductUpdateWithoutReaderCommentsInput;
  create: ProductCreateWithoutReaderCommentsInput;
};

export type ProductWhereInput = {
  AND?: Maybe<Array<ProductWhereInput>>;
  OR?: Maybe<Array<ProductWhereInput>>;
  NOT?: Maybe<Array<ProductWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  sku?: Maybe<StringFilter>;
  order?: Maybe<IntFilter>;
  buyLink?: Maybe<StringNullableFilter>;
  disabled?: Maybe<BoolFilter>;
  photos?: Maybe<StringNullableListFilter>;
  files?: Maybe<JsonNullableFilter>;
  content?: Maybe<ContentListRelationFilter>;
  category?: Maybe<CategoryRelationFilter>;
  categoryId?: Maybe<StringNullableFilter>;
  prices?: Maybe<PriceListRelationFilter>;
  brand?: Maybe<BrandRelationFilter>;
  brandId?: Maybe<StringFilter>;
  descriptions?: Maybe<DescriptionListRelationFilter>;
  messages?: Maybe<CustomFormsListRelationFilter>;
  highlightedContents?: Maybe<ContentListRelationFilter>;
  readerComments?: Maybe<ReaderCommentListRelationFilter>;
};

export type ProductWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type ProductsPayload = {
  __typename?: 'ProductsPayload';
  products: Array<Product>;
  total?: Maybe<Scalars['Int']>;
};

export type Query = {
  __typename?: 'Query';
  findUniqueCustomData?: Maybe<CustomData>;
  findFirstCustomData?: Maybe<CustomData>;
  findManyCustomData: Array<CustomData>;
  aggregateCustomData: AggregateCustomData;
  groupByCustomData: Array<CustomDataGroupBy>;
  formioForm?: Maybe<FormioForm>;
  findFirstFormioForm?: Maybe<FormioForm>;
  formioForms: Array<FormioForm>;
  aggregateFormioForm: AggregateFormioForm;
  groupByFormioForm: Array<FormioFormGroupBy>;
  readerComment?: Maybe<ReaderComment>;
  findFirstReaderComment?: Maybe<ReaderComment>;
  readerComments: Array<ReaderComment>;
  aggregateReaderComment: AggregateReaderComment;
  groupByReaderComment: Array<ReaderCommentGroupBy>;
  ytVideo?: Maybe<YtVideo>;
  findFirstYtVideo?: Maybe<YtVideo>;
  ytVideos: Array<YtVideo>;
  aggregateYtVideo: AggregateYtVideo;
  groupByYtVideo: Array<YtVideoGroupBy>;
  address?: Maybe<Address>;
  addresses: Array<Address>;
  findFirstBlogCategory?: Maybe<BlogCategory>;
  blogCategories: Array<BlogCategory>;
  findFirstBlogPost?: Maybe<BlogPost>;
  brands: Array<Brand>;
  category?: Maybe<Category>;
  categories: Array<Category>;
  content?: Maybe<Content>;
  contents: Array<Content>;
  countries: Array<Country>;
  findFirstCustomForms?: Maybe<CustomForms>;
  findManyCustomForms: Array<CustomForms>;
  description?: Maybe<Description>;
  findFirstEvent?: Maybe<Event>;
  events: Array<Event>;
  findFirstFaq?: Maybe<Faq>;
  faqs: Array<Faq>;
  pages: Array<Page>;
  user?: Maybe<User>;
  users: Array<User>;
  appSettings: AppSettings;
  blogPosts: CustomBlogPostPayload;
  brand: BrandPayload;
  ecommerceSettings: EcommerceSettings;
  page?: Maybe<Page>;
  homepage?: Maybe<Page>;
  products: ProductsPayload;
  product?: Maybe<Product>;
  me: User;
};


export type QueryFindUniqueCustomDataArgs = {
  where: CustomDataWhereUniqueInput;
};


export type QueryFindFirstCustomDataArgs = {
  where?: Maybe<CustomDataWhereInput>;
  orderBy?: Maybe<Array<CustomDataOrderByWithRelationInput>>;
  cursor?: Maybe<CustomDataWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<CustomDataScalarFieldEnum>>;
};


export type QueryFindManyCustomDataArgs = {
  where?: Maybe<CustomDataWhereInput>;
  orderBy?: Maybe<Array<CustomDataOrderByWithRelationInput>>;
  cursor?: Maybe<CustomDataWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<CustomDataScalarFieldEnum>>;
};


export type QueryAggregateCustomDataArgs = {
  where?: Maybe<CustomDataWhereInput>;
  orderBy?: Maybe<Array<CustomDataOrderByWithRelationInput>>;
  cursor?: Maybe<CustomDataWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryGroupByCustomDataArgs = {
  where?: Maybe<CustomDataWhereInput>;
  orderBy?: Maybe<Array<CustomDataOrderByInput>>;
  by: Array<CustomDataScalarFieldEnum>;
  having?: Maybe<CustomDataScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFormioFormArgs = {
  where: FormioFormWhereUniqueInput;
};


export type QueryFindFirstFormioFormArgs = {
  where?: Maybe<FormioFormWhereInput>;
  orderBy?: Maybe<Array<FormioFormOrderByWithRelationInput>>;
  cursor?: Maybe<FormioFormWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<FormioFormScalarFieldEnum>>;
};


export type QueryFormioFormsArgs = {
  where?: Maybe<FormioFormWhereInput>;
  orderBy?: Maybe<Array<FormioFormOrderByWithRelationInput>>;
  cursor?: Maybe<FormioFormWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<FormioFormScalarFieldEnum>>;
};


export type QueryAggregateFormioFormArgs = {
  where?: Maybe<FormioFormWhereInput>;
  orderBy?: Maybe<Array<FormioFormOrderByWithRelationInput>>;
  cursor?: Maybe<FormioFormWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryGroupByFormioFormArgs = {
  where?: Maybe<FormioFormWhereInput>;
  orderBy?: Maybe<Array<FormioFormOrderByInput>>;
  by: Array<FormioFormScalarFieldEnum>;
  having?: Maybe<FormioFormScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryReaderCommentArgs = {
  where: ReaderCommentWhereUniqueInput;
};


export type QueryFindFirstReaderCommentArgs = {
  where?: Maybe<ReaderCommentWhereInput>;
  orderBy?: Maybe<Array<ReaderCommentOrderByWithRelationInput>>;
  cursor?: Maybe<ReaderCommentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<ReaderCommentScalarFieldEnum>>;
};


export type QueryReaderCommentsArgs = {
  where?: Maybe<ReaderCommentWhereInput>;
  orderBy?: Maybe<Array<ReaderCommentOrderByWithRelationInput>>;
  cursor?: Maybe<ReaderCommentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<ReaderCommentScalarFieldEnum>>;
};


export type QueryAggregateReaderCommentArgs = {
  where?: Maybe<ReaderCommentWhereInput>;
  orderBy?: Maybe<Array<ReaderCommentOrderByWithRelationInput>>;
  cursor?: Maybe<ReaderCommentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryGroupByReaderCommentArgs = {
  where?: Maybe<ReaderCommentWhereInput>;
  orderBy?: Maybe<Array<ReaderCommentOrderByInput>>;
  by: Array<ReaderCommentScalarFieldEnum>;
  having?: Maybe<ReaderCommentScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryYtVideoArgs = {
  where: YtVideoWhereUniqueInput;
};


export type QueryFindFirstYtVideoArgs = {
  where?: Maybe<YtVideoWhereInput>;
  orderBy?: Maybe<Array<YtVideoOrderByWithRelationInput>>;
  cursor?: Maybe<YtVideoWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<YtVideoScalarFieldEnum>>;
};


export type QueryYtVideosArgs = {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<YtVideoOrderByWithRelationInput>>;
  where?: Maybe<YtVideoWhereInput>;
};


export type QueryAggregateYtVideoArgs = {
  where?: Maybe<YtVideoWhereInput>;
  orderBy?: Maybe<Array<YtVideoOrderByWithRelationInput>>;
  cursor?: Maybe<YtVideoWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryGroupByYtVideoArgs = {
  where?: Maybe<YtVideoWhereInput>;
  orderBy?: Maybe<Array<YtVideoOrderByInput>>;
  by: Array<YtVideoScalarFieldEnum>;
  having?: Maybe<YtVideoScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAddressArgs = {
  where: AddressWhereUniqueInput;
};


export type QueryAddressesArgs = {
  where?: Maybe<AddressWhereInput>;
  orderBy?: Maybe<Array<AddressOrderByWithRelationInput>>;
  cursor?: Maybe<AddressWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<AddressScalarFieldEnum>>;
};


export type QueryFindFirstBlogCategoryArgs = {
  where?: Maybe<BlogCategoryWhereInput>;
  orderBy?: Maybe<Array<BlogCategoryOrderByWithRelationInput>>;
  cursor?: Maybe<BlogCategoryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<BlogCategoryScalarFieldEnum>>;
};


export type QueryBlogCategoriesArgs = {
  where?: Maybe<BlogCategoryWhereInput>;
  orderBy?: Maybe<Array<BlogCategoryOrderByWithRelationInput>>;
  cursor?: Maybe<BlogCategoryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<BlogCategoryScalarFieldEnum>>;
};


export type QueryFindFirstBlogPostArgs = {
  where?: Maybe<BlogPostWhereInput>;
  orderBy?: Maybe<Array<BlogPostOrderByWithRelationInput>>;
  cursor?: Maybe<BlogPostWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<BlogPostScalarFieldEnum>>;
};


export type QueryBrandsArgs = {
  where?: Maybe<BrandWhereInput>;
  orderBy?: Maybe<Array<BrandOrderByWithRelationInput>>;
  cursor?: Maybe<BrandWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<BrandScalarFieldEnum>>;
};


export type QueryCategoryArgs = {
  where: CategoryWhereUniqueInput;
};


export type QueryCategoriesArgs = {
  where?: Maybe<CategoryWhereInput>;
  orderBy?: Maybe<Array<CategoryOrderByWithRelationInput>>;
  cursor?: Maybe<CategoryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<CategoryScalarFieldEnum>>;
};


export type QueryContentArgs = {
  where: ContentWhereUniqueInput;
};


export type QueryContentsArgs = {
  where?: Maybe<ContentWhereInput>;
  orderBy?: Maybe<Array<ContentOrderByWithRelationInput>>;
  cursor?: Maybe<ContentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<ContentScalarFieldEnum>>;
};


export type QueryCountriesArgs = {
  where?: Maybe<CountryWhereInput>;
  orderBy?: Maybe<Array<CountryOrderByWithRelationInput>>;
  cursor?: Maybe<CountryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<CountryScalarFieldEnum>>;
};


export type QueryFindFirstCustomFormsArgs = {
  where?: Maybe<CustomFormsWhereInput>;
  orderBy?: Maybe<Array<CustomFormsOrderByWithRelationInput>>;
  cursor?: Maybe<CustomFormsWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<CustomFormsScalarFieldEnum>>;
};


export type QueryFindManyCustomFormsArgs = {
  where?: Maybe<CustomFormsWhereInput>;
  orderBy?: Maybe<Array<CustomFormsOrderByWithRelationInput>>;
  cursor?: Maybe<CustomFormsWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<CustomFormsScalarFieldEnum>>;
};


export type QueryDescriptionArgs = {
  where: DescriptionWhereUniqueInput;
};


export type QueryFindFirstEventArgs = {
  where?: Maybe<EventWhereInput>;
  orderBy?: Maybe<Array<EventOrderByWithRelationInput>>;
  cursor?: Maybe<EventWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<EventScalarFieldEnum>>;
};


export type QueryEventsArgs = {
  where?: Maybe<EventWhereInput>;
  orderBy?: Maybe<Array<EventOrderByWithRelationInput>>;
  cursor?: Maybe<EventWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<EventScalarFieldEnum>>;
};


export type QueryFindFirstFaqArgs = {
  where?: Maybe<FaqWhereInput>;
  orderBy?: Maybe<Array<FaqOrderByWithRelationInput>>;
  cursor?: Maybe<FaqWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<FaqScalarFieldEnum>>;
};


export type QueryFaqsArgs = {
  where?: Maybe<FaqWhereInput>;
  orderBy?: Maybe<Array<FaqOrderByWithRelationInput>>;
  cursor?: Maybe<FaqWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<FaqScalarFieldEnum>>;
};


export type QueryPagesArgs = {
  where?: Maybe<PageWhereInput>;
  orderBy?: Maybe<Array<PageOrderByWithRelationInput>>;
  cursor?: Maybe<PageWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<PageScalarFieldEnum>>;
};


export type QueryUserArgs = {
  where: UserWhereUniqueInput;
};


export type QueryUsersArgs = {
  where?: Maybe<UserWhereInput>;
  orderBy?: Maybe<Array<UserOrderByWithRelationInput>>;
  cursor?: Maybe<UserWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<UserScalarFieldEnum>>;
};


export type QueryBlogPostsArgs = {
  distinct?: Maybe<Array<BlogPostScalarFieldEnum>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  cursor?: Maybe<BlogPostWhereUniqueInput>;
  orderBy?: Maybe<Array<BlogPostOrderByWithRelationInput>>;
  where?: Maybe<BlogPostWhereInput>;
};


export type QueryBrandArgs = {
  where: BrandWhereUniqueInput;
};


export type QueryPageArgs = {
  where?: Maybe<PageWhereInput>;
};


export type QueryProductsArgs = {
  distinct?: Maybe<Array<ProductScalarFieldEnum>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  cursor?: Maybe<ProductWhereUniqueInput>;
  orderBy?: Maybe<Array<ProductOrderByWithRelationInput>>;
  where?: Maybe<ProductWhereInput>;
};


export type QueryProductArgs = {
  where: ProductWhereInput;
};

export enum QueryMode {
  default = 'default',
  insensitive = 'insensitive'
}

export type ReaderComment = {
  __typename?: 'ReaderComment';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  name?: Maybe<Scalars['String']>;
  comment: Scalars['String'];
  order: Scalars['Int'];
  productId?: Maybe<Scalars['String']>;
  product?: Maybe<Product>;
};

export type ReaderCommentAvgAggregate = {
  __typename?: 'ReaderCommentAvgAggregate';
  order?: Maybe<Scalars['Float']>;
};

export type ReaderCommentCountAggregate = {
  __typename?: 'ReaderCommentCountAggregate';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  name: Scalars['Int'];
  comment: Scalars['Int'];
  order: Scalars['Int'];
  productId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ReaderCommentCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  comment: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  product?: Maybe<ProductCreateNestedOneWithoutReaderCommentsInput>;
};

export type ReaderCommentCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  comment: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['String']>;
};

export type ReaderCommentCreateManyProductInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  comment: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
};

export type ReaderCommentCreateManyProductInputEnvelope = {
  data: Array<ReaderCommentCreateManyProductInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ReaderCommentCreateNestedManyWithoutProductInput = {
  create?: Maybe<Array<ReaderCommentCreateWithoutProductInput>>;
  connectOrCreate?: Maybe<Array<ReaderCommentCreateOrConnectWithoutProductInput>>;
  createMany?: Maybe<ReaderCommentCreateManyProductInputEnvelope>;
  connect?: Maybe<Array<ReaderCommentWhereUniqueInput>>;
};

export type ReaderCommentCreateOrConnectWithoutProductInput = {
  where: ReaderCommentWhereUniqueInput;
  create: ReaderCommentCreateWithoutProductInput;
};

export type ReaderCommentCreateWithoutProductInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  comment: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
};

export type ReaderCommentGroupBy = {
  __typename?: 'ReaderCommentGroupBy';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  name?: Maybe<Scalars['String']>;
  comment: Scalars['String'];
  order: Scalars['Int'];
  productId?: Maybe<Scalars['String']>;
  _count?: Maybe<ReaderCommentCountAggregate>;
  _avg?: Maybe<ReaderCommentAvgAggregate>;
  _sum?: Maybe<ReaderCommentSumAggregate>;
  _min?: Maybe<ReaderCommentMinAggregate>;
  _max?: Maybe<ReaderCommentMaxAggregate>;
};

export type ReaderCommentListRelationFilter = {
  every?: Maybe<ReaderCommentWhereInput>;
  some?: Maybe<ReaderCommentWhereInput>;
  none?: Maybe<ReaderCommentWhereInput>;
};

export type ReaderCommentMaxAggregate = {
  __typename?: 'ReaderCommentMaxAggregate';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['String']>;
};

export type ReaderCommentMinAggregate = {
  __typename?: 'ReaderCommentMinAggregate';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['String']>;
};

export type ReaderCommentOrderByInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  comment?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  productId?: Maybe<SortOrder>;
};

export type ReaderCommentOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type ReaderCommentOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  comment?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  product?: Maybe<ProductOrderByWithRelationInput>;
  productId?: Maybe<SortOrder>;
};

export enum ReaderCommentScalarFieldEnum {
  id = 'id',
  createdAt = 'createdAt',
  updatedAt = 'updatedAt',
  name = 'name',
  comment = 'comment',
  order = 'order',
  productId = 'productId'
}

export type ReaderCommentScalarWhereInput = {
  AND?: Maybe<Array<ReaderCommentScalarWhereInput>>;
  OR?: Maybe<Array<ReaderCommentScalarWhereInput>>;
  NOT?: Maybe<Array<ReaderCommentScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  name?: Maybe<StringNullableFilter>;
  comment?: Maybe<StringFilter>;
  order?: Maybe<IntFilter>;
  productId?: Maybe<StringNullableFilter>;
};

export type ReaderCommentScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ReaderCommentScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ReaderCommentScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ReaderCommentScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  name?: Maybe<StringNullableWithAggregatesFilter>;
  comment?: Maybe<StringWithAggregatesFilter>;
  order?: Maybe<IntWithAggregatesFilter>;
  productId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type ReaderCommentSumAggregate = {
  __typename?: 'ReaderCommentSumAggregate';
  order?: Maybe<Scalars['Int']>;
};

export type ReaderCommentUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  comment?: Maybe<StringFieldUpdateOperationsInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  product?: Maybe<ProductUpdateOneWithoutReaderCommentsInput>;
};

export type ReaderCommentUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  comment?: Maybe<StringFieldUpdateOperationsInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
};

export type ReaderCommentUpdateManyWithWhereWithoutProductInput = {
  where: ReaderCommentScalarWhereInput;
  data: ReaderCommentUpdateManyMutationInput;
};

export type ReaderCommentUpdateManyWithoutProductInput = {
  create?: Maybe<Array<ReaderCommentCreateWithoutProductInput>>;
  connectOrCreate?: Maybe<Array<ReaderCommentCreateOrConnectWithoutProductInput>>;
  upsert?: Maybe<Array<ReaderCommentUpsertWithWhereUniqueWithoutProductInput>>;
  createMany?: Maybe<ReaderCommentCreateManyProductInputEnvelope>;
  connect?: Maybe<Array<ReaderCommentWhereUniqueInput>>;
  set?: Maybe<Array<ReaderCommentWhereUniqueInput>>;
  disconnect?: Maybe<Array<ReaderCommentWhereUniqueInput>>;
  delete?: Maybe<Array<ReaderCommentWhereUniqueInput>>;
  update?: Maybe<Array<ReaderCommentUpdateWithWhereUniqueWithoutProductInput>>;
  updateMany?: Maybe<Array<ReaderCommentUpdateManyWithWhereWithoutProductInput>>;
  deleteMany?: Maybe<Array<ReaderCommentScalarWhereInput>>;
};

export type ReaderCommentUpdateWithWhereUniqueWithoutProductInput = {
  where: ReaderCommentWhereUniqueInput;
  data: ReaderCommentUpdateWithoutProductInput;
};

export type ReaderCommentUpdateWithoutProductInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  comment?: Maybe<StringFieldUpdateOperationsInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
};

export type ReaderCommentUpsertWithWhereUniqueWithoutProductInput = {
  where: ReaderCommentWhereUniqueInput;
  update: ReaderCommentUpdateWithoutProductInput;
  create: ReaderCommentCreateWithoutProductInput;
};

export type ReaderCommentWhereInput = {
  AND?: Maybe<Array<ReaderCommentWhereInput>>;
  OR?: Maybe<Array<ReaderCommentWhereInput>>;
  NOT?: Maybe<Array<ReaderCommentWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  name?: Maybe<StringNullableFilter>;
  comment?: Maybe<StringFilter>;
  order?: Maybe<IntFilter>;
  product?: Maybe<ProductRelationFilter>;
  productId?: Maybe<StringNullableFilter>;
};

export type ReaderCommentWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export enum SortOrder {
  asc = 'asc',
  desc = 'desc'
}

export type State = {
  __typename?: 'State';
  id: Scalars['String'];
  title: Scalars['String'];
  countryId?: Maybe<Scalars['String']>;
};

export type StateCreateManyCountryInput = {
  id?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type StateCreateManyCountryInputEnvelope = {
  data: Array<StateCreateManyCountryInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type StateCreateNestedManyWithoutCountryInput = {
  create?: Maybe<Array<StateCreateWithoutCountryInput>>;
  connectOrCreate?: Maybe<Array<StateCreateOrConnectWithoutCountryInput>>;
  createMany?: Maybe<StateCreateManyCountryInputEnvelope>;
  connect?: Maybe<Array<StateWhereUniqueInput>>;
};

export type StateCreateOrConnectWithoutCountryInput = {
  where: StateWhereUniqueInput;
  create: StateCreateWithoutCountryInput;
};

export type StateCreateWithoutCountryInput = {
  id?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type StateListRelationFilter = {
  every?: Maybe<StateWhereInput>;
  some?: Maybe<StateWhereInput>;
  none?: Maybe<StateWhereInput>;
};

export type StateOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type StateOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  country?: Maybe<CountryOrderByWithRelationInput>;
  countryId?: Maybe<SortOrder>;
};

export enum StateScalarFieldEnum {
  id = 'id',
  title = 'title',
  countryId = 'countryId'
}

export type StateScalarWhereInput = {
  AND?: Maybe<Array<StateScalarWhereInput>>;
  OR?: Maybe<Array<StateScalarWhereInput>>;
  NOT?: Maybe<Array<StateScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  title?: Maybe<StringFilter>;
  countryId?: Maybe<StringNullableFilter>;
};

export type StateUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  title?: Maybe<StringFieldUpdateOperationsInput>;
};

export type StateUpdateManyWithWhereWithoutCountryInput = {
  where: StateScalarWhereInput;
  data: StateUpdateManyMutationInput;
};

export type StateUpdateManyWithoutCountryInput = {
  create?: Maybe<Array<StateCreateWithoutCountryInput>>;
  connectOrCreate?: Maybe<Array<StateCreateOrConnectWithoutCountryInput>>;
  upsert?: Maybe<Array<StateUpsertWithWhereUniqueWithoutCountryInput>>;
  createMany?: Maybe<StateCreateManyCountryInputEnvelope>;
  connect?: Maybe<Array<StateWhereUniqueInput>>;
  set?: Maybe<Array<StateWhereUniqueInput>>;
  disconnect?: Maybe<Array<StateWhereUniqueInput>>;
  delete?: Maybe<Array<StateWhereUniqueInput>>;
  update?: Maybe<Array<StateUpdateWithWhereUniqueWithoutCountryInput>>;
  updateMany?: Maybe<Array<StateUpdateManyWithWhereWithoutCountryInput>>;
  deleteMany?: Maybe<Array<StateScalarWhereInput>>;
};

export type StateUpdateWithWhereUniqueWithoutCountryInput = {
  where: StateWhereUniqueInput;
  data: StateUpdateWithoutCountryInput;
};

export type StateUpdateWithoutCountryInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  title?: Maybe<StringFieldUpdateOperationsInput>;
};

export type StateUpsertWithWhereUniqueWithoutCountryInput = {
  where: StateWhereUniqueInput;
  update: StateUpdateWithoutCountryInput;
  create: StateCreateWithoutCountryInput;
};

export type StateWhereInput = {
  AND?: Maybe<Array<StateWhereInput>>;
  OR?: Maybe<Array<StateWhereInput>>;
  NOT?: Maybe<Array<StateWhereInput>>;
  id?: Maybe<StringFilter>;
  title?: Maybe<StringFilter>;
  country?: Maybe<CountryRelationFilter>;
  countryId?: Maybe<StringNullableFilter>;
};

export type StateWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type StringFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['String']>;
};

export type StringFilter = {
  equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  notIn?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  mode?: Maybe<QueryMode>;
  not?: Maybe<NestedStringFilter>;
};

export type StringNullableFilter = {
  equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  notIn?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  mode?: Maybe<QueryMode>;
  not?: Maybe<NestedStringNullableFilter>;
};

export type StringNullableListFilter = {
  equals?: Maybe<Array<Scalars['String']>>;
  has?: Maybe<Scalars['String']>;
  hasEvery?: Maybe<Array<Scalars['String']>>;
  hasSome?: Maybe<Array<Scalars['String']>>;
  isEmpty?: Maybe<Scalars['Boolean']>;
};

export type StringNullableWithAggregatesFilter = {
  equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  notIn?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  mode?: Maybe<QueryMode>;
  not?: Maybe<NestedStringNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedStringNullableFilter>;
  _max?: Maybe<NestedStringNullableFilter>;
};

export type StringWithAggregatesFilter = {
  equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  notIn?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  mode?: Maybe<QueryMode>;
  not?: Maybe<NestedStringWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedStringFilter>;
  _max?: Maybe<NestedStringFilter>;
};

export type Subscription = {
  __typename?: 'Subscription';
  normalSubscription: Notification;
  subscriptionWithFilter: Notification;
};

export enum ThemeType {
  LIGHT = 'LIGHT',
  DARK = 'DARK'
}

export type TownshipCreateManyCityInput = {
  id?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type TownshipCreateManyCityInputEnvelope = {
  data: Array<TownshipCreateManyCityInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type TownshipCreateNestedManyWithoutCityInput = {
  create?: Maybe<Array<TownshipCreateWithoutCityInput>>;
  connectOrCreate?: Maybe<Array<TownshipCreateOrConnectWithoutCityInput>>;
  createMany?: Maybe<TownshipCreateManyCityInputEnvelope>;
  connect?: Maybe<Array<TownshipWhereUniqueInput>>;
};

export type TownshipCreateOrConnectWithoutCityInput = {
  where: TownshipWhereUniqueInput;
  create: TownshipCreateWithoutCityInput;
};

export type TownshipCreateWithoutCityInput = {
  id?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  district?: Maybe<DistrictCreateNestedManyWithoutTownshipInput>;
};

export type TownshipListRelationFilter = {
  every?: Maybe<TownshipWhereInput>;
  some?: Maybe<TownshipWhereInput>;
  none?: Maybe<TownshipWhereInput>;
};

export type TownshipOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type TownshipRelationFilter = {
  is?: Maybe<TownshipWhereInput>;
  isNot?: Maybe<TownshipWhereInput>;
};

export type TownshipScalarWhereInput = {
  AND?: Maybe<Array<TownshipScalarWhereInput>>;
  OR?: Maybe<Array<TownshipScalarWhereInput>>;
  NOT?: Maybe<Array<TownshipScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  title?: Maybe<StringFilter>;
  cityId?: Maybe<StringFilter>;
};

export type TownshipUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  title?: Maybe<StringFieldUpdateOperationsInput>;
};

export type TownshipUpdateManyWithWhereWithoutCityInput = {
  where: TownshipScalarWhereInput;
  data: TownshipUpdateManyMutationInput;
};

export type TownshipUpdateManyWithoutCityInput = {
  create?: Maybe<Array<TownshipCreateWithoutCityInput>>;
  connectOrCreate?: Maybe<Array<TownshipCreateOrConnectWithoutCityInput>>;
  upsert?: Maybe<Array<TownshipUpsertWithWhereUniqueWithoutCityInput>>;
  createMany?: Maybe<TownshipCreateManyCityInputEnvelope>;
  connect?: Maybe<Array<TownshipWhereUniqueInput>>;
  set?: Maybe<Array<TownshipWhereUniqueInput>>;
  disconnect?: Maybe<Array<TownshipWhereUniqueInput>>;
  delete?: Maybe<Array<TownshipWhereUniqueInput>>;
  update?: Maybe<Array<TownshipUpdateWithWhereUniqueWithoutCityInput>>;
  updateMany?: Maybe<Array<TownshipUpdateManyWithWhereWithoutCityInput>>;
  deleteMany?: Maybe<Array<TownshipScalarWhereInput>>;
};

export type TownshipUpdateWithWhereUniqueWithoutCityInput = {
  where: TownshipWhereUniqueInput;
  data: TownshipUpdateWithoutCityInput;
};

export type TownshipUpdateWithoutCityInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  title?: Maybe<StringFieldUpdateOperationsInput>;
  district?: Maybe<DistrictUpdateManyWithoutTownshipInput>;
};

export type TownshipUpsertWithWhereUniqueWithoutCityInput = {
  where: TownshipWhereUniqueInput;
  update: TownshipUpdateWithoutCityInput;
  create: TownshipCreateWithoutCityInput;
};

export type TownshipWhereInput = {
  AND?: Maybe<Array<TownshipWhereInput>>;
  OR?: Maybe<Array<TownshipWhereInput>>;
  NOT?: Maybe<Array<TownshipWhereInput>>;
  id?: Maybe<StringFilter>;
  title?: Maybe<StringFilter>;
  city?: Maybe<CityRelationFilter>;
  cityId?: Maybe<StringFilter>;
  district?: Maybe<DistrictListRelationFilter>;
};

export type TownshipWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};


export type User = {
  __typename?: 'User';
  id: Scalars['String'];
  identityNumber?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  surname: Scalars['String'];
  email: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
  gender?: Maybe<GenderType>;
  emailActivated: Scalars['Boolean'];
  phoneActivated: Scalars['Boolean'];
  status: UserStatusType;
  role: UserRole;
  updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  addresses: Array<Address>;
  blogposts: Array<BlogPost>;
};


export type UserAddressesArgs = {
  where?: Maybe<AddressWhereInput>;
  orderBy?: Maybe<Array<AddressOrderByWithRelationInput>>;
  cursor?: Maybe<AddressWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<AddressScalarFieldEnum>>;
};


export type UserBlogpostsArgs = {
  where?: Maybe<BlogPostWhereInput>;
  orderBy?: Maybe<Array<BlogPostOrderByWithRelationInput>>;
  cursor?: Maybe<BlogPostWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<BlogPostScalarFieldEnum>>;
};

export type UserCreateInput = {
  id?: Maybe<Scalars['String']>;
  identityNumber?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  surname: Scalars['String'];
  email: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  gender?: Maybe<GenderType>;
  emailActivated?: Maybe<Scalars['Boolean']>;
  phoneActivated?: Maybe<Scalars['Boolean']>;
  status: UserStatusType;
  role?: Maybe<UserRole>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  addresses?: Maybe<AddressCreateNestedManyWithoutUserInput>;
  blogposts?: Maybe<BlogPostCreateNestedManyWithoutAuthorInput>;
};

export type UserCreateNestedOneWithoutAddressesInput = {
  create?: Maybe<UserCreateWithoutAddressesInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutAddressesInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutBlogpostsInput = {
  create?: Maybe<UserCreateWithoutBlogpostsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutBlogpostsInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateOrConnectWithoutAddressesInput = {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutAddressesInput;
};

export type UserCreateOrConnectWithoutBlogpostsInput = {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutBlogpostsInput;
};

export type UserCreateWithoutAddressesInput = {
  id?: Maybe<Scalars['String']>;
  identityNumber?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  surname: Scalars['String'];
  email: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  gender?: Maybe<GenderType>;
  emailActivated?: Maybe<Scalars['Boolean']>;
  phoneActivated?: Maybe<Scalars['Boolean']>;
  status: UserStatusType;
  role?: Maybe<UserRole>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  blogposts?: Maybe<BlogPostCreateNestedManyWithoutAuthorInput>;
};

export type UserCreateWithoutBlogpostsInput = {
  id?: Maybe<Scalars['String']>;
  identityNumber?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  surname: Scalars['String'];
  email: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  gender?: Maybe<GenderType>;
  emailActivated?: Maybe<Scalars['Boolean']>;
  phoneActivated?: Maybe<Scalars['Boolean']>;
  status: UserStatusType;
  role?: Maybe<UserRole>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  addresses?: Maybe<AddressCreateNestedManyWithoutUserInput>;
};

export type UserOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  identityNumber?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  surname?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  refreshToken?: Maybe<SortOrder>;
  password?: Maybe<SortOrder>;
  gender?: Maybe<SortOrder>;
  emailActivated?: Maybe<SortOrder>;
  phoneActivated?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  role?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  addresses?: Maybe<AddressOrderByRelationAggregateInput>;
  blogposts?: Maybe<BlogPostOrderByRelationAggregateInput>;
};

export type UserRelationFilter = {
  is?: Maybe<UserWhereInput>;
  isNot?: Maybe<UserWhereInput>;
};

export enum UserRole {
  ADMIN = 'ADMIN',
  USER = 'USER'
}

export enum UserScalarFieldEnum {
  id = 'id',
  identityNumber = 'identityNumber',
  name = 'name',
  surname = 'surname',
  email = 'email',
  phoneNumber = 'phoneNumber',
  refreshToken = 'refreshToken',
  password = 'password',
  gender = 'gender',
  emailActivated = 'emailActivated',
  phoneActivated = 'phoneActivated',
  status = 'status',
  role = 'role',
  updatedAt = 'updatedAt',
  createdAt = 'createdAt'
}

export enum UserStatusType {
  ACTIVE = 'ACTIVE',
  PASSIVE = 'PASSIVE',
  BLOCKED = 'BLOCKED'
}

export type UserUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  identityNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  surname?: Maybe<StringFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  phoneNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  refreshToken?: Maybe<NullableStringFieldUpdateOperationsInput>;
  password?: Maybe<StringFieldUpdateOperationsInput>;
  gender?: Maybe<NullableEnumGenderTypeFieldUpdateOperationsInput>;
  emailActivated?: Maybe<BoolFieldUpdateOperationsInput>;
  phoneActivated?: Maybe<BoolFieldUpdateOperationsInput>;
  status?: Maybe<EnumUserStatusTypeFieldUpdateOperationsInput>;
  role?: Maybe<EnumUserRoleFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  addresses?: Maybe<AddressUpdateManyWithoutUserInput>;
  blogposts?: Maybe<BlogPostUpdateManyWithoutAuthorInput>;
};

export type UserUpdateOneRequiredWithoutBlogpostsInput = {
  create?: Maybe<UserCreateWithoutBlogpostsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutBlogpostsInput>;
  upsert?: Maybe<UserUpsertWithoutBlogpostsInput>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUpdateWithoutBlogpostsInput>;
};

export type UserUpdateOneWithoutAddressesInput = {
  create?: Maybe<UserCreateWithoutAddressesInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutAddressesInput>;
  upsert?: Maybe<UserUpsertWithoutAddressesInput>;
  connect?: Maybe<UserWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  update?: Maybe<UserUpdateWithoutAddressesInput>;
};

export type UserUpdateWithoutAddressesInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  identityNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  surname?: Maybe<StringFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  phoneNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  refreshToken?: Maybe<NullableStringFieldUpdateOperationsInput>;
  password?: Maybe<StringFieldUpdateOperationsInput>;
  gender?: Maybe<NullableEnumGenderTypeFieldUpdateOperationsInput>;
  emailActivated?: Maybe<BoolFieldUpdateOperationsInput>;
  phoneActivated?: Maybe<BoolFieldUpdateOperationsInput>;
  status?: Maybe<EnumUserStatusTypeFieldUpdateOperationsInput>;
  role?: Maybe<EnumUserRoleFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  blogposts?: Maybe<BlogPostUpdateManyWithoutAuthorInput>;
};

export type UserUpdateWithoutBlogpostsInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  identityNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  surname?: Maybe<StringFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  phoneNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  refreshToken?: Maybe<NullableStringFieldUpdateOperationsInput>;
  password?: Maybe<StringFieldUpdateOperationsInput>;
  gender?: Maybe<NullableEnumGenderTypeFieldUpdateOperationsInput>;
  emailActivated?: Maybe<BoolFieldUpdateOperationsInput>;
  phoneActivated?: Maybe<BoolFieldUpdateOperationsInput>;
  status?: Maybe<EnumUserStatusTypeFieldUpdateOperationsInput>;
  role?: Maybe<EnumUserRoleFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  addresses?: Maybe<AddressUpdateManyWithoutUserInput>;
};

export type UserUpsertWithoutAddressesInput = {
  update: UserUpdateWithoutAddressesInput;
  create: UserCreateWithoutAddressesInput;
};

export type UserUpsertWithoutBlogpostsInput = {
  update: UserUpdateWithoutBlogpostsInput;
  create: UserCreateWithoutBlogpostsInput;
};

export type UserWhereInput = {
  AND?: Maybe<Array<UserWhereInput>>;
  OR?: Maybe<Array<UserWhereInput>>;
  NOT?: Maybe<Array<UserWhereInput>>;
  id?: Maybe<StringFilter>;
  identityNumber?: Maybe<StringNullableFilter>;
  name?: Maybe<StringFilter>;
  surname?: Maybe<StringFilter>;
  email?: Maybe<StringFilter>;
  phoneNumber?: Maybe<StringNullableFilter>;
  refreshToken?: Maybe<StringNullableFilter>;
  password?: Maybe<StringFilter>;
  gender?: Maybe<EnumGenderTypeNullableFilter>;
  emailActivated?: Maybe<BoolFilter>;
  phoneActivated?: Maybe<BoolFilter>;
  status?: Maybe<EnumUserStatusTypeFilter>;
  role?: Maybe<EnumUserRoleFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  addresses?: Maybe<AddressListRelationFilter>;
  blogposts?: Maybe<BlogPostListRelationFilter>;
};

export type UserWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
};

export type YtVideo = {
  __typename?: 'YtVideo';
  id: Scalars['String'];
  lastSynced: Scalars['DateTime'];
  uploadDate?: Maybe<Scalars['DateTime']>;
  isActive: Scalars['Boolean'];
  order: Scalars['Int'];
  data?: Maybe<Scalars['JSON']>;
  showOnHomepage: Scalars['Boolean'];
};

export type YtVideoAvgAggregate = {
  __typename?: 'YtVideoAvgAggregate';
  order?: Maybe<Scalars['Float']>;
};

export type YtVideoCountAggregate = {
  __typename?: 'YtVideoCountAggregate';
  id: Scalars['Int'];
  lastSynced: Scalars['Int'];
  uploadDate: Scalars['Int'];
  isActive: Scalars['Int'];
  order: Scalars['Int'];
  data: Scalars['Int'];
  showOnHomepage: Scalars['Int'];
  _all: Scalars['Int'];
};

export type YtVideoCreateInput = {
  id?: Maybe<Scalars['String']>;
  lastSynced?: Maybe<Scalars['DateTime']>;
  uploadDate?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  data?: Maybe<Scalars['JSON']>;
  showOnHomepage?: Maybe<Scalars['Boolean']>;
};

export type YtVideoCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  lastSynced?: Maybe<Scalars['DateTime']>;
  uploadDate?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  data?: Maybe<Scalars['JSON']>;
  showOnHomepage?: Maybe<Scalars['Boolean']>;
};

export type YtVideoGroupBy = {
  __typename?: 'YtVideoGroupBy';
  id: Scalars['String'];
  lastSynced: Scalars['DateTime'];
  uploadDate?: Maybe<Scalars['DateTime']>;
  isActive: Scalars['Boolean'];
  order: Scalars['Int'];
  data?: Maybe<Scalars['JSON']>;
  showOnHomepage: Scalars['Boolean'];
  _count?: Maybe<YtVideoCountAggregate>;
  _avg?: Maybe<YtVideoAvgAggregate>;
  _sum?: Maybe<YtVideoSumAggregate>;
  _min?: Maybe<YtVideoMinAggregate>;
  _max?: Maybe<YtVideoMaxAggregate>;
};

export type YtVideoMaxAggregate = {
  __typename?: 'YtVideoMaxAggregate';
  id?: Maybe<Scalars['String']>;
  lastSynced?: Maybe<Scalars['DateTime']>;
  uploadDate?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  showOnHomepage?: Maybe<Scalars['Boolean']>;
};

export type YtVideoMinAggregate = {
  __typename?: 'YtVideoMinAggregate';
  id?: Maybe<Scalars['String']>;
  lastSynced?: Maybe<Scalars['DateTime']>;
  uploadDate?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  showOnHomepage?: Maybe<Scalars['Boolean']>;
};

export type YtVideoOrderByInput = {
  id?: Maybe<SortOrder>;
  lastSynced?: Maybe<SortOrder>;
  uploadDate?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  data?: Maybe<SortOrder>;
  showOnHomepage?: Maybe<SortOrder>;
};

export type YtVideoOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  lastSynced?: Maybe<SortOrder>;
  uploadDate?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  data?: Maybe<SortOrder>;
  showOnHomepage?: Maybe<SortOrder>;
};

export enum YtVideoScalarFieldEnum {
  id = 'id',
  lastSynced = 'lastSynced',
  uploadDate = 'uploadDate',
  isActive = 'isActive',
  order = 'order',
  data = 'data',
  showOnHomepage = 'showOnHomepage'
}

export type YtVideoScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<YtVideoScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<YtVideoScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<YtVideoScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  lastSynced?: Maybe<DateTimeWithAggregatesFilter>;
  uploadDate?: Maybe<DateTimeNullableWithAggregatesFilter>;
  isActive?: Maybe<BoolWithAggregatesFilter>;
  order?: Maybe<IntWithAggregatesFilter>;
  data?: Maybe<JsonNullableWithAggregatesFilter>;
  showOnHomepage?: Maybe<BoolWithAggregatesFilter>;
};

export type YtVideoSumAggregate = {
  __typename?: 'YtVideoSumAggregate';
  order?: Maybe<Scalars['Int']>;
};

export type YtVideoUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  lastSynced?: Maybe<DateTimeFieldUpdateOperationsInput>;
  uploadDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  isActive?: Maybe<BoolFieldUpdateOperationsInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  data?: Maybe<Scalars['JSON']>;
  showOnHomepage?: Maybe<BoolFieldUpdateOperationsInput>;
};

export type YtVideoUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  lastSynced?: Maybe<DateTimeFieldUpdateOperationsInput>;
  uploadDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  isActive?: Maybe<BoolFieldUpdateOperationsInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  data?: Maybe<Scalars['JSON']>;
  showOnHomepage?: Maybe<BoolFieldUpdateOperationsInput>;
};

export type YtVideoWhereInput = {
  AND?: Maybe<Array<YtVideoWhereInput>>;
  OR?: Maybe<Array<YtVideoWhereInput>>;
  NOT?: Maybe<Array<YtVideoWhereInput>>;
  id?: Maybe<StringFilter>;
  lastSynced?: Maybe<DateTimeFilter>;
  uploadDate?: Maybe<DateTimeNullableFilter>;
  isActive?: Maybe<BoolFilter>;
  order?: Maybe<IntFilter>;
  data?: Maybe<JsonNullableFilter>;
  showOnHomepage?: Maybe<BoolFilter>;
};

export type YtVideoWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type GetAddressQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetAddressQuery = (
  { __typename?: 'Query' }
  & { address?: Maybe<(
    { __typename?: 'Address' }
    & Pick<Address, 'id' | 'createdAt' | 'updatedAt' | 'title' | 'postalCode' | 'addressLineOne' | 'addressLineTwo' | 'longitude' | 'latitude'>
    & { contact: (
      { __typename?: 'Contact' }
      & Pick<Contact, 'id' | 'updatedAt' | 'createdAt' | 'registrationNumber' | 'mersisNumber' | 'kepEmail' | 'email' | 'phoneNumber' | 'faxNumber' | 'taxNumber' | 'taxOffice' | 'other'>
      & { description: Array<(
        { __typename?: 'Description' }
        & Pick<Description, 'id' | 'updatedAt' | 'createdAt' | 'title' | 'description' | 'shortDescription' | 'slug' | 'buttonText' | 'buttonUrl' | 'language' | 'metaTitle' | 'metaDescription' | 'metaKeywords' | 'seoPoint'>
      )> }
    ) }
  )> }
);

export type GetAddressesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAddressesQuery = (
  { __typename?: 'Query' }
  & { addresses: Array<(
    { __typename?: 'Address' }
    & Pick<Address, 'id' | 'updatedAt' | 'createdAt' | 'title' | 'postalCode' | 'addressLineOne' | 'addressLineTwo' | 'longitude' | 'latitude'>
    & { contact: (
      { __typename?: 'Contact' }
      & Pick<Contact, 'id' | 'updatedAt' | 'createdAt' | 'registrationNumber' | 'mersisNumber' | 'kepEmail' | 'taxNumber' | 'taxOffice' | 'other'>
      & { description: Array<(
        { __typename?: 'Description' }
        & Pick<Description, 'id' | 'updatedAt' | 'createdAt' | 'title' | 'description' | 'shortDescription' | 'slug' | 'buttonText' | 'buttonUrl' | 'language' | 'metaTitle' | 'metaDescription' | 'metaKeywords' | 'seoPoint'>
      )>, address: Array<(
        { __typename?: 'Address' }
        & Pick<Address, 'id'>
      )> }
    ) }
  )> }
);

export type CreateAddressMutationVariables = Exact<{
  addressTitle: Scalars['String'];
  postalCode: Scalars['String'];
  addressLineOne: Scalars['String'];
  addressLineTwo?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  faxNumber?: Maybe<Scalars['String']>;
  registrationNumber?: Maybe<Scalars['String']>;
  mersisNumber?: Maybe<Scalars['String']>;
  kepEmail?: Maybe<Scalars['String']>;
  taxNumber?: Maybe<Scalars['String']>;
  taxOffice?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  language: Scalars['String'];
  buttonText?: Maybe<Scalars['String']>;
  buttonUrl?: Maybe<Scalars['String']>;
}>;


export type CreateAddressMutation = (
  { __typename?: 'Mutation' }
  & { createAddress: (
    { __typename?: 'Address' }
    & Pick<Address, 'id' | 'updatedAt' | 'createdAt'>
  ) }
);

export type DeleteAddressMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteAddressMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteAddress'>
);

export type OrderAddressMutationVariables = Exact<{
  id: Scalars['String'];
  order: Scalars['Int'];
}>;


export type OrderAddressMutation = (
  { __typename?: 'Mutation' }
  & { updateAddress?: Maybe<(
    { __typename?: 'Address' }
    & Pick<Address, 'id' | 'updatedAt'>
  )> }
);

export type UpdateAddressMutationVariables = Exact<{
  id: Scalars['String'];
  addressTitle: Scalars['String'];
  postalCode: Scalars['String'];
  addressLineOne: Scalars['String'];
  addressLineTwo?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  registrationNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  faxNumber?: Maybe<Scalars['String']>;
  mersisNumber?: Maybe<Scalars['String']>;
  kepEmail?: Maybe<Scalars['String']>;
  taxNumber?: Maybe<Scalars['String']>;
  taxOffice?: Maybe<Scalars['String']>;
}>;


export type UpdateAddressMutation = (
  { __typename?: 'Mutation' }
  & { updateAddress?: Maybe<(
    { __typename?: 'Address' }
    & Pick<Address, 'id' | 'updatedAt' | 'createdAt' | 'postalCode'>
  )> }
);

export type GetBlogCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBlogCategoriesQuery = (
  { __typename?: 'Query' }
  & { blogCategories: Array<(
    { __typename?: 'BlogCategory' }
    & Pick<BlogCategory, 'id' | 'createdAt' | 'updatedAt' | 'order' | 'hidden'>
    & { description: Array<(
      { __typename?: 'Description' }
      & Pick<Description, 'id' | 'updatedAt' | 'createdAt' | 'title' | 'description' | 'shortDescription' | 'slug' | 'buttonText' | 'buttonUrl' | 'language' | 'metaTitle' | 'metaDescription' | 'metaKeywords' | 'seoPoint'>
    )> }
  )> }
);

export type GetBlogCategoryQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetBlogCategoryQuery = (
  { __typename?: 'Query' }
  & { findFirstBlogCategory?: Maybe<(
    { __typename?: 'BlogCategory' }
    & Pick<BlogCategory, 'id' | 'createdAt' | 'updatedAt' | 'order' | 'hidden' | 'thumbnail'>
    & { description: Array<(
      { __typename?: 'Description' }
      & Pick<Description, 'id' | 'updatedAt' | 'createdAt' | 'title' | 'description' | 'shortDescription' | 'slug' | 'buttonText' | 'buttonUrl' | 'language' | 'metaTitle' | 'metaDescription' | 'metaKeywords' | 'seoPoint'>
    )> }
  )> }
);

export type CreateBlogCategoryMutationVariables = Exact<{
  thumbnail?: Maybe<Scalars['String']>;
  hidden?: Maybe<Scalars['Boolean']>;
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  language: Scalars['String'];
  buttonText?: Maybe<Scalars['String']>;
  buttonUrl?: Maybe<Scalars['String']>;
}>;


export type CreateBlogCategoryMutation = (
  { __typename?: 'Mutation' }
  & { createBlogCategory: (
    { __typename?: 'BlogCategory' }
    & Pick<BlogCategory, 'id' | 'createdAt' | 'updatedAt'>
  ) }
);

export type CreateBlogCategoryWithoutThumbnailMutationVariables = Exact<{
  hidden?: Maybe<Scalars['Boolean']>;
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  language: Scalars['String'];
  buttonText?: Maybe<Scalars['String']>;
  buttonUrl?: Maybe<Scalars['String']>;
}>;


export type CreateBlogCategoryWithoutThumbnailMutation = (
  { __typename?: 'Mutation' }
  & { createBlogCategory: (
    { __typename?: 'BlogCategory' }
    & Pick<BlogCategory, 'id' | 'createdAt' | 'updatedAt'>
  ) }
);

export type DeleteBlogCategoryMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteBlogCategoryMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteBlogCategory'>
);

export type OrderBlogCategoryMutationVariables = Exact<{
  id: Scalars['String'];
  order: Scalars['Int'];
}>;


export type OrderBlogCategoryMutation = (
  { __typename?: 'Mutation' }
  & { updateBlogCategory?: Maybe<(
    { __typename?: 'BlogCategory' }
    & Pick<BlogCategory, 'id' | 'updatedAt'>
  )> }
);

export type UpdateBlogCategoryMutationVariables = Exact<{
  thumbnail?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  hidden?: Maybe<Scalars['Boolean']>;
}>;


export type UpdateBlogCategoryMutation = (
  { __typename?: 'Mutation' }
  & { updateBlogCategory?: Maybe<(
    { __typename?: 'BlogCategory' }
    & Pick<BlogCategory, 'id' | 'updatedAt'>
  )> }
);

export type UpdateBlogCategoryWithoutPhotoMutationVariables = Exact<{
  id: Scalars['String'];
  thumbnail?: Maybe<Scalars['String']>;
  hidden?: Maybe<Scalars['Boolean']>;
}>;


export type UpdateBlogCategoryWithoutPhotoMutation = (
  { __typename?: 'Mutation' }
  & { updateBlogCategory?: Maybe<(
    { __typename?: 'BlogCategory' }
    & Pick<BlogCategory, 'id' | 'updatedAt'>
  )> }
);

export type GetBlogPostContentsQueryVariables = Exact<{
  postId?: Maybe<Scalars['String']>;
}>;


export type GetBlogPostContentsQuery = (
  { __typename?: 'Query' }
  & { contents: Array<(
    { __typename?: 'Content' }
    & Pick<Content, 'id' | 'updatedAt' | 'createdAt' | 'photos' | 'videos' | 'embedCode' | 'longitude' | 'latitude' | 'contentType' | 'mediaType' | 'source' | 'order'>
    & { description: Array<(
      { __typename?: 'Description' }
      & Pick<Description, 'id' | 'updatedAt' | 'createdAt' | 'title' | 'description' | 'shortDescription' | 'slug' | 'buttonText' | 'buttonUrl' | 'language' | 'metaTitle' | 'metaDescription' | 'metaKeywords' | 'seoPoint'>
    )>, page?: Maybe<(
      { __typename?: 'Page' }
      & Pick<Page, 'id' | 'updatedAt' | 'createdAt'>
    )> }
  )> }
);

export type CreateBlogPostMutationVariables = Exact<{
  thumbnail?: Maybe<Scalars['String']>;
  categoryId: Scalars['String'];
  authorId: Scalars['String'];
  publishedAt?: Maybe<Scalars['String']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  language: Scalars['String'];
  buttonText?: Maybe<Scalars['String']>;
  buttonUrl?: Maybe<Scalars['String']>;
}>;


export type CreateBlogPostMutation = (
  { __typename?: 'Mutation' }
  & { createBlogPost: (
    { __typename?: 'BlogPost' }
    & Pick<BlogPost, 'id'>
  ) }
);

export type CreateBlogPostWithoutThumbnailMutationVariables = Exact<{
  categoryId: Scalars['String'];
  authorId: Scalars['String'];
  publishedAt?: Maybe<Scalars['String']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  language: Scalars['String'];
  buttonText?: Maybe<Scalars['String']>;
  buttonUrl?: Maybe<Scalars['String']>;
}>;


export type CreateBlogPostWithoutThumbnailMutation = (
  { __typename?: 'Mutation' }
  & { createBlogPost: (
    { __typename?: 'BlogPost' }
    & Pick<BlogPost, 'id'>
  ) }
);

export type CreateBlogPostContentMutationVariables = Exact<{
  postId: Scalars['String'];
  photos: Array<Scalars['String']> | Scalars['String'];
  videos?: Maybe<Scalars['String']>;
  contentType: ContentType;
  mediaType: MediaType;
  embedCode?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  source?: Maybe<Scalars['String']>;
  cards: Scalars['JSON'];
  slides?: Maybe<Scalars['JSON']>;
  timeline?: Maybe<Scalars['JSON']>;
  backgroundImage?: Maybe<Scalars['String']>;
  theme?: Maybe<ThemeType>;
  productCategoryId?: Maybe<Scalars['String']>;
  blogCategoryId?: Maybe<Scalars['String']>;
  highlightedProducts?: Maybe<Array<IdField> | IdField>;
  lineUp: ContentLineup;
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  language: Scalars['String'];
  buttonText?: Maybe<Scalars['String']>;
  buttonUrl?: Maybe<Scalars['String']>;
}>;


export type CreateBlogPostContentMutation = (
  { __typename?: 'Mutation' }
  & { createContent: (
    { __typename?: 'Content' }
    & Pick<Content, 'id' | 'updatedAt' | 'createdAt'>
  ) }
);

export type CreateBlogPostContentWithoutBgMutationVariables = Exact<{
  postId: Scalars['String'];
  photos: Array<Scalars['String']> | Scalars['String'];
  videos?: Maybe<Scalars['String']>;
  contentType: ContentType;
  mediaType: MediaType;
  embedCode?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  source?: Maybe<Scalars['String']>;
  cards: Scalars['JSON'];
  slides?: Maybe<Scalars['JSON']>;
  timeline?: Maybe<Scalars['JSON']>;
  theme?: Maybe<ThemeType>;
  productCategoryId?: Maybe<Scalars['String']>;
  blogCategoryId?: Maybe<Scalars['String']>;
  highlightedProducts?: Maybe<Array<IdField> | IdField>;
  lineUp: ContentLineup;
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  language: Scalars['String'];
  buttonText?: Maybe<Scalars['String']>;
  buttonUrl?: Maybe<Scalars['String']>;
}>;


export type CreateBlogPostContentWithoutBgMutation = (
  { __typename?: 'Mutation' }
  & { createContent: (
    { __typename?: 'Content' }
    & Pick<Content, 'id' | 'updatedAt' | 'createdAt'>
  ) }
);

export type DeleteBlogPostMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteBlogPostMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteBlogPost'>
);

export type GetBlogPostQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetBlogPostQuery = (
  { __typename?: 'Query' }
  & { findFirstBlogPost?: Maybe<(
    { __typename?: 'BlogPost' }
    & Pick<BlogPost, 'id' | 'createdAt' | 'updatedAt' | 'publishedAt' | 'thumbnail' | 'isHidden' | 'blogCategoryId'>
    & { description: Array<(
      { __typename?: 'Description' }
      & Pick<Description, 'id' | 'updatedAt' | 'createdAt' | 'title' | 'description' | 'shortDescription' | 'slug' | 'buttonText' | 'buttonUrl' | 'language' | 'metaTitle' | 'metaDescription' | 'metaKeywords' | 'seoPoint'>
    )> }
  )> }
);

export type GetBlogPostsQueryVariables = Exact<{
  recordsPerPage?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<BlogPostWhereInput>;
}>;


export type GetBlogPostsQuery = (
  { __typename?: 'Query' }
  & { blogPosts: (
    { __typename?: 'CustomBlogPostPayload' }
    & Pick<CustomBlogPostPayload, 'total'>
    & { posts: Array<(
      { __typename?: 'BlogPost' }
      & Pick<BlogPost, 'id' | 'createdAt' | 'updatedAt' | 'publishedAt' | 'thumbnail' | 'isHidden'>
      & { content: Array<(
        { __typename?: 'Content' }
        & Pick<Content, 'id'>
      )>, category: (
        { __typename?: 'BlogCategory' }
        & { description: Array<(
          { __typename?: 'Description' }
          & Pick<Description, 'title'>
        )> }
      ), description: Array<(
        { __typename?: 'Description' }
        & Pick<Description, 'id' | 'updatedAt' | 'createdAt' | 'title' | 'description' | 'shortDescription' | 'slug' | 'buttonText' | 'buttonUrl' | 'language' | 'metaTitle' | 'metaDescription' | 'metaKeywords' | 'seoPoint'>
      )> }
    )> }
  ) }
);

export type UpdateBlogPostMutationVariables = Exact<{
  id: Scalars['String'];
  categoryId: Scalars['String'];
  thumbnail?: Maybe<Scalars['String']>;
  authorId: Scalars['String'];
  publishedAt?: Maybe<Scalars['String']>;
  isHidden?: Maybe<Scalars['Boolean']>;
}>;


export type UpdateBlogPostMutation = (
  { __typename?: 'Mutation' }
  & { updateBlogPost?: Maybe<(
    { __typename?: 'BlogPost' }
    & Pick<BlogPost, 'id' | 'createdAt' | 'updatedAt'>
  )> }
);

export type UpdateBlogPostWithoutThumbnailMutationVariables = Exact<{
  id: Scalars['String'];
  authorId?: Maybe<Scalars['String']>;
  categoryId: Scalars['String'];
  publishedAt?: Maybe<Scalars['String']>;
  isHidden?: Maybe<Scalars['Boolean']>;
}>;


export type UpdateBlogPostWithoutThumbnailMutation = (
  { __typename?: 'Mutation' }
  & { updateBlogPost?: Maybe<(
    { __typename?: 'BlogPost' }
    & Pick<BlogPost, 'id' | 'createdAt' | 'updatedAt'>
  )> }
);

export type GetBrandQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetBrandQuery = (
  { __typename?: 'Query' }
  & { brand: (
    { __typename?: 'BrandPayload' }
    & Pick<BrandPayload, 'id' | 'title' | 'slug' | 'photo'>
    & { countries: Array<(
      { __typename?: 'Country' }
      & Pick<Country, 'id' | 'title' | 'alpha2Code' | 'alpha3Code' | 'numericCode'>
    )> }
  ) }
);

export type GetBrandsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBrandsQuery = (
  { __typename?: 'Query' }
  & { brands: Array<(
    { __typename?: 'Brand' }
    & Pick<Brand, 'id' | 'title' | 'slug' | 'photo' | 'order'>
    & { description: Array<(
      { __typename?: 'Description' }
      & Pick<Description, 'id' | 'updatedAt' | 'createdAt' | 'title' | 'description' | 'shortDescription' | 'slug' | 'buttonText' | 'buttonUrl' | 'language' | 'metaTitle' | 'metaDescription' | 'metaKeywords' | 'seoPoint'>
    )> }
  )> }
);

export type CreateBrandMutationVariables = Exact<{
  title: Scalars['String'];
  photo?: Maybe<Scalars['String']>;
  countries?: Maybe<Array<IdField> | IdField>;
  description?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  language: Scalars['String'];
  buttonText?: Maybe<Scalars['String']>;
  buttonUrl?: Maybe<Scalars['String']>;
}>;


export type CreateBrandMutation = (
  { __typename?: 'Mutation' }
  & { createBrand: (
    { __typename?: 'Brand' }
    & Pick<Brand, 'id' | 'title' | 'slug'>
  ) }
);

export type CreateBrandWithoutPhotoMutationVariables = Exact<{
  title: Scalars['String'];
  countries?: Maybe<Array<IdField> | IdField>;
  description?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  language: Scalars['String'];
  buttonText?: Maybe<Scalars['String']>;
  buttonUrl?: Maybe<Scalars['String']>;
}>;


export type CreateBrandWithoutPhotoMutation = (
  { __typename?: 'Mutation' }
  & { createBrand: (
    { __typename?: 'Brand' }
    & Pick<Brand, 'id' | 'title' | 'slug'>
  ) }
);

export type DeleteBrandMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteBrandMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteBrand'>
);

export type OrderBrandMutationVariables = Exact<{
  id: Scalars['String'];
  order: Scalars['Int'];
}>;


export type OrderBrandMutation = (
  { __typename?: 'Mutation' }
  & { updateBrand: (
    { __typename?: 'Brand' }
    & Pick<Brand, 'id'>
  ) }
);

export type UpdateBrandMutationVariables = Exact<{
  id: Scalars['String'];
  title: Scalars['String'];
  photo?: Maybe<Scalars['String']>;
  countries?: Maybe<Array<IdField> | IdField>;
}>;


export type UpdateBrandMutation = (
  { __typename?: 'Mutation' }
  & { updateBrand: (
    { __typename?: 'Brand' }
    & Pick<Brand, 'id' | 'title' | 'slug' | 'photo'>
  ) }
);

export type UpdateBrandWithoutPhotoMutationVariables = Exact<{
  id: Scalars['String'];
  title: Scalars['String'];
  countries?: Maybe<Array<IdField> | IdField>;
  photo?: Maybe<Scalars['String']>;
}>;


export type UpdateBrandWithoutPhotoMutation = (
  { __typename?: 'Mutation' }
  & { updateBrand: (
    { __typename?: 'Brand' }
    & Pick<Brand, 'id' | 'title' | 'slug'>
  ) }
);

export type GetCategoriesQueryVariables = Exact<{
  where?: Maybe<CategoryWhereInput>;
}>;


export type GetCategoriesQuery = (
  { __typename?: 'Query' }
  & { categories: Array<(
    { __typename?: 'Category' }
    & Pick<Category, 'id' | 'parentId' | 'order'>
    & { parent?: Maybe<(
      { __typename?: 'Category' }
      & Pick<Category, 'id'>
      & { descriptions: Array<(
        { __typename?: 'Description' }
        & Pick<Description, 'id' | 'title'>
      )> }
    )>, products: Array<(
      { __typename?: 'Product' }
      & Pick<Product, 'id' | 'createdAt' | 'updatedAt' | 'sku' | 'disabled'>
    )>, descriptions: Array<(
      { __typename?: 'Description' }
      & Pick<Description, 'id' | 'updatedAt' | 'createdAt' | 'title' | 'description' | 'shortDescription' | 'slug' | 'buttonText' | 'buttonUrl' | 'language' | 'metaTitle' | 'metaDescription' | 'metaKeywords' | 'seoPoint'>
    )> }
  )> }
);

export type GetCategoryQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetCategoryQuery = (
  { __typename?: 'Query' }
  & { category?: Maybe<(
    { __typename?: 'Category' }
    & Pick<Category, 'id' | 'parentId' | 'photos' | 'order'>
    & { parent?: Maybe<(
      { __typename?: 'Category' }
      & Pick<Category, 'id'>
    )>, products: Array<(
      { __typename?: 'Product' }
      & Pick<Product, 'id' | 'createdAt' | 'updatedAt' | 'sku' | 'disabled'>
    )>, descriptions: Array<(
      { __typename?: 'Description' }
      & Pick<Description, 'id' | 'updatedAt' | 'createdAt' | 'title' | 'description' | 'shortDescription' | 'slug' | 'buttonText' | 'buttonUrl' | 'language' | 'metaTitle' | 'metaDescription' | 'metaKeywords' | 'seoPoint'>
    )> }
  )> }
);

export type CreateCategoryMutationVariables = Exact<{
  parentCategoryId?: Maybe<Scalars['String']>;
  photos: Array<Scalars['String']> | Scalars['String'];
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  language: Scalars['String'];
  buttonText?: Maybe<Scalars['String']>;
  buttonUrl?: Maybe<Scalars['String']>;
}>;


export type CreateCategoryMutation = (
  { __typename?: 'Mutation' }
  & { createCategory: (
    { __typename?: 'Category' }
    & Pick<Category, 'id' | 'parentId'>
  ) }
);

export type CreateCategoryWithoutParentMutationVariables = Exact<{
  photos: Array<Scalars['String']> | Scalars['String'];
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  language: Scalars['String'];
  buttonText?: Maybe<Scalars['String']>;
  buttonUrl?: Maybe<Scalars['String']>;
}>;


export type CreateCategoryWithoutParentMutation = (
  { __typename?: 'Mutation' }
  & { createCategory: (
    { __typename?: 'Category' }
    & Pick<Category, 'id' | 'parentId'>
  ) }
);

export type DeleteCategoryMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteCategoryMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteCategory'>
);

export type OrderCategoryMutationVariables = Exact<{
  id: Scalars['String'];
  order: Scalars['Int'];
}>;


export type OrderCategoryMutation = (
  { __typename?: 'Mutation' }
  & { updateCategory: (
    { __typename?: 'Category' }
    & Pick<Category, 'id'>
  ) }
);

export type UpdateCategoryMutationVariables = Exact<{
  id: Scalars['String'];
  parentCategoryId?: Maybe<Scalars['String']>;
  photos: Array<Scalars['String']> | Scalars['String'];
}>;


export type UpdateCategoryMutation = (
  { __typename?: 'Mutation' }
  & { updateCategory: (
    { __typename?: 'Category' }
    & Pick<Category, 'id'>
  ) }
);

export type UpdateCategoryWithoutParentMutationVariables = Exact<{
  id: Scalars['String'];
  photos: Array<Scalars['String']> | Scalars['String'];
}>;


export type UpdateCategoryWithoutParentMutation = (
  { __typename?: 'Mutation' }
  & { updateCategory: (
    { __typename?: 'Category' }
    & Pick<Category, 'id'>
  ) }
);

export type GetReaderCommentQueryVariables = Exact<{
  where: ReaderCommentWhereUniqueInput;
}>;


export type GetReaderCommentQuery = (
  { __typename?: 'Query' }
  & { readerComment?: Maybe<(
    { __typename?: 'ReaderComment' }
    & Pick<ReaderComment, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'comment' | 'order'>
    & { product?: Maybe<(
      { __typename?: 'Product' }
      & Pick<Product, 'id' | 'sku'>
      & { descriptions: Array<(
        { __typename?: 'Description' }
        & Pick<Description, 'id' | 'title' | 'slug'>
      )> }
    )> }
  )> }
);

export type GetReaderCommentsQueryVariables = Exact<{
  where?: Maybe<ReaderCommentWhereInput>;
}>;


export type GetReaderCommentsQuery = (
  { __typename?: 'Query' }
  & { readerComments: Array<(
    { __typename?: 'ReaderComment' }
    & Pick<ReaderComment, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'comment' | 'order'>
    & { product?: Maybe<(
      { __typename?: 'Product' }
      & Pick<Product, 'id' | 'sku'>
      & { descriptions: Array<(
        { __typename?: 'Description' }
        & Pick<Description, 'id' | 'title' | 'slug'>
      )> }
    )> }
  )> }
);

export type CreateReaderCommentMutationVariables = Exact<{
  name?: Maybe<Scalars['String']>;
  comment: Scalars['String'];
  productId?: Maybe<Scalars['String']>;
}>;


export type CreateReaderCommentMutation = (
  { __typename?: 'Mutation' }
  & { createReaderComment: (
    { __typename?: 'ReaderComment' }
    & Pick<ReaderComment, 'id'>
  ) }
);

export type DeleteReaderCommentMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteReaderCommentMutation = (
  { __typename?: 'Mutation' }
  & { deleteReaderComment?: Maybe<(
    { __typename?: 'ReaderComment' }
    & Pick<ReaderComment, 'id'>
  )> }
);

export type OrderReaderCommentMutationVariables = Exact<{
  id: Scalars['String'];
  order: Scalars['Int'];
}>;


export type OrderReaderCommentMutation = (
  { __typename?: 'Mutation' }
  & { updateReaderComment?: Maybe<(
    { __typename?: 'ReaderComment' }
    & Pick<ReaderComment, 'id'>
  )> }
);

export type UpdateReaderCommentMutationVariables = Exact<{
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  comment: Scalars['String'];
}>;


export type UpdateReaderCommentMutation = (
  { __typename?: 'Mutation' }
  & { updateReaderComment?: Maybe<(
    { __typename?: 'ReaderComment' }
    & Pick<ReaderComment, 'id'>
  )> }
);

export type GetContentQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetContentQuery = (
  { __typename?: 'Query' }
  & { content?: Maybe<(
    { __typename?: 'Content' }
    & Pick<Content, 'id' | 'updatedAt' | 'createdAt' | 'theme' | 'backgroundImage' | 'productCategoryId' | 'blogCategoryId' | 'lineUp' | 'photos' | 'cards' | 'slides' | 'timeline' | 'videos' | 'embedCode' | 'longitude' | 'latitude' | 'contentType' | 'mediaType' | 'source' | 'order'>
    & { productCategory?: Maybe<(
      { __typename?: 'Category' }
      & Pick<Category, 'id' | 'parentId'>
    )>, highlightedProducts: Array<(
      { __typename?: 'Product' }
      & Pick<Product, 'id' | 'sku'>
      & { descriptions: Array<(
        { __typename?: 'Description' }
        & Pick<Description, 'id' | 'title' | 'slug'>
      )> }
    )>, description: Array<(
      { __typename?: 'Description' }
      & Pick<Description, 'id' | 'updatedAt' | 'createdAt' | 'title' | 'description' | 'shortDescription' | 'slug' | 'buttonText' | 'buttonUrl' | 'language' | 'metaTitle' | 'metaDescription' | 'metaKeywords' | 'seoPoint'>
    )>, page?: Maybe<(
      { __typename?: 'Page' }
      & Pick<Page, 'id' | 'updatedAt' | 'createdAt'>
    )>, product?: Maybe<(
      { __typename?: 'Product' }
      & Pick<Product, 'id' | 'updatedAt' | 'createdAt'>
    )>, blogPost?: Maybe<(
      { __typename?: 'BlogPost' }
      & Pick<BlogPost, 'id' | 'updatedAt' | 'createdAt'>
    )> }
  )> }
);

export type GetContentsQueryVariables = Exact<{
  pageId?: Maybe<Scalars['String']>;
}>;


export type GetContentsQuery = (
  { __typename?: 'Query' }
  & { contents: Array<(
    { __typename?: 'Content' }
    & Pick<Content, 'id' | 'updatedAt' | 'createdAt' | 'photos' | 'videos' | 'embedCode' | 'longitude' | 'latitude' | 'contentType' | 'mediaType' | 'source' | 'order'>
    & { description: Array<(
      { __typename?: 'Description' }
      & Pick<Description, 'id' | 'updatedAt' | 'createdAt' | 'title' | 'description' | 'shortDescription' | 'slug' | 'buttonText' | 'buttonUrl' | 'language' | 'metaTitle' | 'metaDescription' | 'metaKeywords' | 'seoPoint'>
    )>, page?: Maybe<(
      { __typename?: 'Page' }
      & Pick<Page, 'id' | 'updatedAt' | 'createdAt'>
    )> }
  )> }
);

export type CreateContentMutationVariables = Exact<{
  pageId: Scalars['String'];
  photos: Array<Scalars['String']> | Scalars['String'];
  videos?: Maybe<Scalars['String']>;
  contentType: ContentType;
  mediaType: MediaType;
  embedCode?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  source?: Maybe<Scalars['String']>;
  cards: Scalars['JSON'];
  slides?: Maybe<Scalars['JSON']>;
  timeline?: Maybe<Scalars['JSON']>;
  backgroundImage?: Maybe<Scalars['String']>;
  theme?: Maybe<ThemeType>;
  productCategoryId?: Maybe<Scalars['String']>;
  blogCategoryId?: Maybe<Scalars['String']>;
  highlightedProducts?: Maybe<Array<IdField> | IdField>;
  lineUp: ContentLineup;
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  language: Scalars['String'];
  buttonText?: Maybe<Scalars['String']>;
  buttonUrl?: Maybe<Scalars['String']>;
}>;


export type CreateContentMutation = (
  { __typename?: 'Mutation' }
  & { createContent: (
    { __typename?: 'Content' }
    & Pick<Content, 'id' | 'updatedAt' | 'createdAt'>
  ) }
);

export type CreateContentWithoutBgImageMutationVariables = Exact<{
  pageId: Scalars['String'];
  photos: Array<Scalars['String']> | Scalars['String'];
  videos?: Maybe<Scalars['String']>;
  contentType: ContentType;
  mediaType: MediaType;
  embedCode?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  source?: Maybe<Scalars['String']>;
  cards: Scalars['JSON'];
  slides?: Maybe<Scalars['JSON']>;
  timeline?: Maybe<Scalars['JSON']>;
  theme?: Maybe<ThemeType>;
  productCategoryId?: Maybe<Scalars['String']>;
  blogCategoryId?: Maybe<Scalars['String']>;
  highlightedProducts?: Maybe<Array<IdField> | IdField>;
  lineUp: ContentLineup;
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  language: Scalars['String'];
  buttonText?: Maybe<Scalars['String']>;
  buttonUrl?: Maybe<Scalars['String']>;
}>;


export type CreateContentWithoutBgImageMutation = (
  { __typename?: 'Mutation' }
  & { createContent: (
    { __typename?: 'Content' }
    & Pick<Content, 'id' | 'updatedAt' | 'createdAt'>
  ) }
);

export type DeleteContentMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteContentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteContent'>
);

export type OrderContentMutationVariables = Exact<{
  id: Scalars['String'];
  order: Scalars['Int'];
}>;


export type OrderContentMutation = (
  { __typename?: 'Mutation' }
  & { updateContent: (
    { __typename?: 'Content' }
    & Pick<Content, 'id' | 'updatedAt'>
  ) }
);

export type UpdatecontentMutationVariables = Exact<{
  id: Scalars['String'];
  photos: Array<Scalars['String']> | Scalars['String'];
  videos?: Maybe<Scalars['String']>;
  contentType?: Maybe<ContentType>;
  mediaType?: Maybe<MediaType>;
  backgroundImage?: Maybe<Scalars['String']>;
  theme?: Maybe<ThemeType>;
  productCategoryId?: Maybe<Scalars['String']>;
  blogCategoryId?: Maybe<Scalars['String']>;
  highlightedProducts?: Maybe<Array<IdField> | IdField>;
  lineUp: ContentLineup;
  longitude?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  cards?: Maybe<Scalars['JSON']>;
  slides?: Maybe<Scalars['JSON']>;
  timeline?: Maybe<Scalars['JSON']>;
}>;


export type UpdatecontentMutation = (
  { __typename?: 'Mutation' }
  & { updateContent: (
    { __typename?: 'Content' }
    & Pick<Content, 'id' | 'updatedAt' | 'createdAt'>
  ) }
);

export type UpdatecontentWithoutBgMutationVariables = Exact<{
  id: Scalars['String'];
  photos: Array<Scalars['String']> | Scalars['String'];
  videos?: Maybe<Scalars['String']>;
  contentType?: Maybe<ContentType>;
  mediaType?: Maybe<MediaType>;
  theme?: Maybe<ThemeType>;
  productCategoryId?: Maybe<Scalars['String']>;
  blogCategoryId?: Maybe<Scalars['String']>;
  highlightedProducts?: Maybe<Array<IdField> | IdField>;
  lineUp: ContentLineup;
  longitude?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  cards?: Maybe<Scalars['JSON']>;
  slides?: Maybe<Scalars['JSON']>;
  timeline?: Maybe<Scalars['JSON']>;
}>;


export type UpdatecontentWithoutBgMutation = (
  { __typename?: 'Mutation' }
  & { updateContent: (
    { __typename?: 'Content' }
    & Pick<Content, 'id' | 'updatedAt' | 'createdAt'>
  ) }
);

export type GetCountriesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCountriesQuery = (
  { __typename?: 'Query' }
  & { countries: Array<(
    { __typename?: 'Country' }
    & Pick<Country, 'id' | 'title' | 'alpha2Code' | 'alpha3Code' | 'numericCode'>
  )> }
);

export type GetFindFirstCustomDataQueryVariables = Exact<{ [key: string]: never; }>;


export type GetFindFirstCustomDataQuery = (
  { __typename?: 'Query' }
  & { findFirstCustomData?: Maybe<(
    { __typename?: 'CustomData' }
    & Pick<CustomData, 'id' | 'createdAt' | 'updatedAt' | 'liveVideoUrl'>
  )> }
);

export type SetLiveVideoUrlMutationVariables = Exact<{
  liveVideoUrl?: Maybe<Scalars['String']>;
}>;


export type SetLiveVideoUrlMutation = (
  { __typename?: 'Mutation' }
  & { updateCustomData?: Maybe<(
    { __typename?: 'CustomData' }
    & Pick<CustomData, 'id'>
  )> }
);

export type GetVideosQueryVariables = Exact<{
  where?: Maybe<YtVideoWhereInput>;
  orderBy?: Maybe<Array<YtVideoOrderByWithRelationInput> | YtVideoOrderByWithRelationInput>;
}>;


export type GetVideosQuery = (
  { __typename?: 'Query' }
  & { ytVideos: Array<(
    { __typename?: 'YtVideo' }
    & Pick<YtVideo, 'id' | 'lastSynced' | 'isActive' | 'showOnHomepage' | 'order' | 'data'>
  )> }
);

export type UpdateVideoMutationVariables = Exact<{
  id: Scalars['String'];
  isActive: Scalars['Boolean'];
}>;


export type UpdateVideoMutation = (
  { __typename?: 'Mutation' }
  & { updateYtVideo?: Maybe<(
    { __typename?: 'YtVideo' }
    & Pick<YtVideo, 'id'>
  )> }
);

export type SetHomepageVideoMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type SetHomepageVideoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'setHomepageVideo'>
);

export type CreateDescMutationVariables = Exact<{
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  language: Scalars['String'];
  shortDescription?: Maybe<Scalars['String']>;
  buttonUrl?: Maybe<Scalars['String']>;
  buttonText?: Maybe<Scalars['String']>;
  field: Scalars['String'];
  fieldId: Scalars['String'];
}>;


export type CreateDescMutation = (
  { __typename?: 'Mutation' }
  & { createDescription: (
    { __typename?: 'Description' }
    & Pick<Description, 'id' | 'updatedAt' | 'createdAt' | 'title' | 'description' | 'slug'>
  ) }
);

export type DeleteDescMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteDescMutation = (
  { __typename?: 'Mutation' }
  & { deleteDescription?: Maybe<(
    { __typename?: 'Description' }
    & Pick<Description, 'id'>
  )> }
);

export type GetDescriptionQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetDescriptionQuery = (
  { __typename?: 'Query' }
  & { description?: Maybe<(
    { __typename?: 'Description' }
    & Pick<Description, 'id' | 'updatedAt' | 'createdAt' | 'title' | 'description' | 'shortDescription' | 'slug' | 'buttonText' | 'buttonUrl' | 'language' | 'metaTitle' | 'metaKeywords' | 'metaDescription' | 'seoPoint'>
  )> }
);

export type UpdateDescMutationVariables = Exact<{
  id: Scalars['String'];
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  language: Scalars['String'];
  shortDescription?: Maybe<Scalars['String']>;
  buttonUrl?: Maybe<Scalars['String']>;
  buttonText?: Maybe<Scalars['String']>;
}>;


export type UpdateDescMutation = (
  { __typename?: 'Mutation' }
  & { updateDescription: (
    { __typename?: 'Description' }
    & Pick<Description, 'id' | 'updatedAt' | 'createdAt' | 'title' | 'description' | 'buttonText' | 'buttonUrl'>
  ) }
);

export type CreateEventMutationVariables = Exact<{
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  language: Scalars['String'];
  buttonText?: Maybe<Scalars['String']>;
  buttonUrl?: Maybe<Scalars['String']>;
}>;


export type CreateEventMutation = (
  { __typename?: 'Mutation' }
  & { createEvent: (
    { __typename?: 'Event' }
    & Pick<Event, 'id' | 'updatedAt' | 'createdAt'>
  ) }
);

export type DeleteEventMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteEventMutation = (
  { __typename?: 'Mutation' }
  & { deleteEvent?: Maybe<(
    { __typename?: 'Event' }
    & Pick<Event, 'id'>
  )> }
);

export type GetEventQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetEventQuery = (
  { __typename?: 'Query' }
  & { findFirstEvent?: Maybe<(
    { __typename?: 'Event' }
    & Pick<Event, 'id' | 'createdAt' | 'updatedAt' | 'startDate' | 'endDate'>
    & { description: Array<(
      { __typename?: 'Description' }
      & Pick<Description, 'id' | 'updatedAt' | 'createdAt' | 'title' | 'description' | 'shortDescription' | 'slug' | 'buttonText' | 'buttonUrl' | 'language' | 'metaTitle' | 'metaDescription' | 'metaKeywords' | 'seoPoint'>
    )> }
  )> }
);

export type GetEventsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetEventsQuery = (
  { __typename?: 'Query' }
  & { events: Array<(
    { __typename?: 'Event' }
    & Pick<Event, 'id' | 'createdAt' | 'updatedAt' | 'startDate' | 'endDate'>
    & { description: Array<(
      { __typename?: 'Description' }
      & Pick<Description, 'id' | 'updatedAt' | 'createdAt' | 'title' | 'description' | 'shortDescription' | 'slug' | 'buttonText' | 'buttonUrl' | 'language' | 'metaTitle' | 'metaDescription' | 'metaKeywords' | 'seoPoint'>
    )> }
  )> }
);

export type UpdateEventMutationVariables = Exact<{
  id: Scalars['String'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
}>;


export type UpdateEventMutation = (
  { __typename?: 'Mutation' }
  & { updateEvent?: Maybe<(
    { __typename?: 'Event' }
    & Pick<Event, 'id' | 'updatedAt'>
  )> }
);

export type CreateFaqMutationVariables = Exact<{
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  language: Scalars['String'];
  buttonText?: Maybe<Scalars['String']>;
  buttonUrl?: Maybe<Scalars['String']>;
}>;


export type CreateFaqMutation = (
  { __typename?: 'Mutation' }
  & { createFaq: (
    { __typename?: 'Faq' }
    & Pick<Faq, 'id' | 'updatedAt' | 'createdAt'>
  ) }
);

export type DeleteFaqMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteFaqMutation = (
  { __typename?: 'Mutation' }
  & { deleteFaq?: Maybe<(
    { __typename?: 'Faq' }
    & Pick<Faq, 'id'>
  )> }
);

export type GetFaqQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetFaqQuery = (
  { __typename?: 'Query' }
  & { findFirstFaq?: Maybe<(
    { __typename?: 'Faq' }
    & Pick<Faq, 'id' | 'createdAt' | 'updatedAt' | 'order'>
    & { description: Array<(
      { __typename?: 'Description' }
      & Pick<Description, 'id' | 'updatedAt' | 'createdAt' | 'title' | 'description' | 'shortDescription' | 'slug' | 'buttonText' | 'buttonUrl' | 'language' | 'metaTitle' | 'metaDescription' | 'metaKeywords' | 'seoPoint'>
    )> }
  )> }
);

export type GetFaqsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetFaqsQuery = (
  { __typename?: 'Query' }
  & { faqs: Array<(
    { __typename?: 'Faq' }
    & Pick<Faq, 'id' | 'createdAt' | 'updatedAt' | 'order'>
    & { description: Array<(
      { __typename?: 'Description' }
      & Pick<Description, 'id' | 'updatedAt' | 'createdAt' | 'title' | 'description' | 'shortDescription' | 'slug' | 'buttonText' | 'buttonUrl' | 'language' | 'metaTitle' | 'metaDescription' | 'metaKeywords' | 'seoPoint'>
    )> }
  )> }
);

export type OrderFaqMutationVariables = Exact<{
  id: Scalars['String'];
  order: Scalars['Int'];
}>;


export type OrderFaqMutation = (
  { __typename?: 'Mutation' }
  & { updateFaq?: Maybe<(
    { __typename?: 'Faq' }
    & Pick<Faq, 'id' | 'updatedAt'>
  )> }
);

export type UploadFileMutationVariables = Exact<{
  file: Scalars['Upload'];
}>;


export type UploadFileMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'uploadFile'>
);

export type UploadProductFileMutationVariables = Exact<{
  file: Scalars['Upload'];
}>;


export type UploadProductFileMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'uploadProductFile'>
);

export type DeleteMessageMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteMessageMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteMessage'>
);

export type GetFormMessageQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetFormMessageQuery = (
  { __typename?: 'Query' }
  & { findFirstCustomForms?: Maybe<(
    { __typename?: 'CustomForms' }
    & Pick<CustomForms, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'surname' | 'email' | 'companyName' | 'message' | 'fieldOne' | 'fieldTwo' | 'department' | 'formType' | 'isRead' | 'productId'>
    & { product?: Maybe<(
      { __typename?: 'Product' }
      & Pick<Product, 'id'>
      & { descriptions: Array<(
        { __typename?: 'Description' }
        & Pick<Description, 'id' | 'title' | 'slug' | 'language'>
      )> }
    )> }
  )> }
);

export type GetFormMessagesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetFormMessagesQuery = (
  { __typename?: 'Query' }
  & { findManyCustomForms: Array<(
    { __typename?: 'CustomForms' }
    & Pick<CustomForms, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'surname' | 'email' | 'companyName' | 'message' | 'fieldOne' | 'fieldTwo' | 'department' | 'formType' | 'isRead' | 'productId'>
    & { product?: Maybe<(
      { __typename?: 'Product' }
      & Pick<Product, 'id'>
      & { descriptions: Array<(
        { __typename?: 'Description' }
        & Pick<Description, 'id' | 'title' | 'slug' | 'language'>
      )> }
    )> }
  )> }
);

export type MarkAsReadMessageMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type MarkAsReadMessageMutation = (
  { __typename?: 'Mutation' }
  & { updateCustomForms?: Maybe<(
    { __typename?: 'CustomForms' }
    & Pick<CustomForms, 'id'>
  )> }
);

export type CreateFormioFormMutationVariables = Exact<{
  title: Scalars['String'];
  slug: Scalars['String'];
}>;


export type CreateFormioFormMutation = (
  { __typename?: 'Mutation' }
  & { createFormioForm: (
    { __typename?: 'FormioForm' }
    & Pick<FormioForm, 'id'>
  ) }
);

export type DeleteFormioFormMutationVariables = Exact<{
  deleteFormioFormWhere: FormioFormWhereUniqueInput;
}>;


export type DeleteFormioFormMutation = (
  { __typename?: 'Mutation' }
  & { deleteFormioForm?: Maybe<(
    { __typename?: 'FormioForm' }
    & Pick<FormioForm, 'id'>
  )> }
);

export type GetFormioFormQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetFormioFormQuery = (
  { __typename?: 'Query' }
  & { formioForm?: Maybe<(
    { __typename?: 'FormioForm' }
    & Pick<FormioForm, 'id' | 'title' | 'slug' | 'order' | 'status'>
  )> }
);

export type GetFormioFormsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetFormioFormsQuery = (
  { __typename?: 'Query' }
  & { formioForms: Array<(
    { __typename?: 'FormioForm' }
    & Pick<FormioForm, 'id' | 'title' | 'slug' | 'order' | 'status'>
  )> }
);

export type OrderFormioFormMutationVariables = Exact<{
  id: Scalars['String'];
  order: Scalars['Int'];
}>;


export type OrderFormioFormMutation = (
  { __typename?: 'Mutation' }
  & { updateFormioForm?: Maybe<(
    { __typename?: 'FormioForm' }
    & Pick<FormioForm, 'id'>
  )> }
);

export type UpdateFormioFormMutationVariables = Exact<{
  id: Scalars['String'];
  title: Scalars['String'];
  slug: Scalars['String'];
}>;


export type UpdateFormioFormMutation = (
  { __typename?: 'Mutation' }
  & { updateFormioForm?: Maybe<(
    { __typename?: 'FormioForm' }
    & Pick<FormioForm, 'id' | 'title'>
  )> }
);

export type CreatePageMutationVariables = Exact<{
  showOnHeader: Scalars['Boolean'];
  showOnFooter: Scalars['Boolean'];
  isLink?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['Boolean']>;
  pageType?: Maybe<PageType>;
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  language: Scalars['String'];
  buttonText?: Maybe<Scalars['String']>;
  buttonUrl?: Maybe<Scalars['String']>;
}>;


export type CreatePageMutation = (
  { __typename?: 'Mutation' }
  & { createPage: (
    { __typename?: 'Page' }
    & Pick<Page, 'id' | 'updatedAt' | 'createdAt' | 'photos' | 'showOnHeader' | 'showOnFooter' | 'publishedAt' | 'isLink' | 'status' | 'order'>
  ) }
);

export type CreatePageWithParentMutationVariables = Exact<{
  showOnHeader: Scalars['Boolean'];
  showOnFooter: Scalars['Boolean'];
  isLink?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['Boolean']>;
  pageType?: Maybe<PageType>;
  parentPageId?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  language: Scalars['String'];
  buttonText?: Maybe<Scalars['String']>;
  buttonUrl?: Maybe<Scalars['String']>;
}>;


export type CreatePageWithParentMutation = (
  { __typename?: 'Mutation' }
  & { createPage: (
    { __typename?: 'Page' }
    & Pick<Page, 'id' | 'updatedAt' | 'createdAt' | 'photos' | 'showOnHeader' | 'showOnFooter' | 'publishedAt' | 'isLink' | 'status' | 'order'>
  ) }
);

export type DeletePageMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeletePageMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deletePage'>
);

export type OrderPageMutationVariables = Exact<{
  id: Scalars['String'];
  order: Scalars['Int'];
}>;


export type OrderPageMutation = (
  { __typename?: 'Mutation' }
  & { updatePage?: Maybe<(
    { __typename?: 'Page' }
    & Pick<Page, 'id' | 'updatedAt'>
  )> }
);

export type GetPageQueryVariables = Exact<{
  id?: Maybe<Scalars['String']>;
}>;


export type GetPageQuery = (
  { __typename?: 'Query' }
  & { page?: Maybe<(
    { __typename?: 'Page' }
    & Pick<Page, 'id' | 'updatedAt' | 'createdAt' | 'photos' | 'pageType' | 'parentId' | 'showOnHeader' | 'showOnFooter' | 'publishedAt' | 'isLink' | 'status' | 'order'>
    & { description: Array<(
      { __typename?: 'Description' }
      & Pick<Description, 'id' | 'updatedAt' | 'createdAt' | 'title' | 'description' | 'shortDescription' | 'slug' | 'buttonText' | 'buttonUrl' | 'language' | 'metaTitle' | 'metaKeywords' | 'metaDescription' | 'seoPoint'>
    )>, content: Array<(
      { __typename?: 'Content' }
      & Pick<Content, 'id' | 'photos' | 'videos'>
    )> }
  )> }
);

export type GetPagesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPagesQuery = (
  { __typename?: 'Query' }
  & { pages: Array<(
    { __typename?: 'Page' }
    & Pick<Page, 'id' | 'updatedAt' | 'createdAt' | 'photos' | 'pageType' | 'isHomepage' | 'showOnHeader' | 'showOnFooter' | 'parentId' | 'publishedAt' | 'isLink' | 'status' | 'order'>
    & { description: Array<(
      { __typename?: 'Description' }
      & Pick<Description, 'id' | 'updatedAt' | 'createdAt' | 'title' | 'description' | 'shortDescription' | 'slug' | 'buttonText' | 'buttonUrl' | 'language' | 'metaTitle' | 'metaDescription' | 'metaKeywords' | 'seoPoint'>
    )>, content: Array<(
      { __typename?: 'Content' }
      & Pick<Content, 'id' | 'photos' | 'videos'>
    )>, parent?: Maybe<(
      { __typename?: 'Page' }
      & Pick<Page, 'id'>
      & { description: Array<(
        { __typename?: 'Description' }
        & Pick<Description, 'title'>
      )> }
    )> }
  )> }
);

export type UpdatePageMutationVariables = Exact<{
  id?: Maybe<Scalars['String']>;
  showOnHeader?: Maybe<Scalars['Boolean']>;
  showOnFooter?: Maybe<Scalars['Boolean']>;
  isLink?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['Boolean']>;
  pageType?: Maybe<PageType>;
  parentPageId?: Maybe<Scalars['String']>;
}>;


export type UpdatePageMutation = (
  { __typename?: 'Mutation' }
  & { updatePage?: Maybe<(
    { __typename?: 'Page' }
    & Pick<Page, 'id' | 'updatedAt' | 'createdAt' | 'photos' | 'showOnHeader' | 'showOnFooter' | 'publishedAt' | 'isLink' | 'status' | 'order'>
  )> }
);

export type UpdatePageFlatMutationVariables = Exact<{
  id?: Maybe<Scalars['String']>;
  showOnHeader?: Maybe<Scalars['Boolean']>;
  showOnFooter?: Maybe<Scalars['Boolean']>;
  isLink?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['Boolean']>;
  pageType?: Maybe<PageType>;
}>;


export type UpdatePageFlatMutation = (
  { __typename?: 'Mutation' }
  & { updatePage?: Maybe<(
    { __typename?: 'Page' }
    & Pick<Page, 'id' | 'updatedAt' | 'createdAt' | 'photos' | 'showOnHeader' | 'showOnFooter' | 'publishedAt' | 'isLink' | 'status' | 'order'>
  )> }
);

export type UpdatePageDisconnectParentMutationVariables = Exact<{
  id?: Maybe<Scalars['String']>;
  showOnHeader?: Maybe<Scalars['Boolean']>;
  showOnFooter?: Maybe<Scalars['Boolean']>;
  isLink?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['Boolean']>;
  pageType?: Maybe<PageType>;
}>;


export type UpdatePageDisconnectParentMutation = (
  { __typename?: 'Mutation' }
  & { updatePage?: Maybe<(
    { __typename?: 'Page' }
    & Pick<Page, 'id' | 'updatedAt' | 'createdAt' | 'photos' | 'showOnHeader' | 'showOnFooter' | 'publishedAt' | 'isLink' | 'status' | 'order'>
  )> }
);

export type SetHomepageMutationVariables = Exact<{
  id: Scalars['ID'];
  isHomepage: Scalars['Boolean'];
}>;


export type SetHomepageMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'setHomepage'>
);

export type GetProductContentsQueryVariables = Exact<{
  productId?: Maybe<Scalars['String']>;
}>;


export type GetProductContentsQuery = (
  { __typename?: 'Query' }
  & { contents: Array<(
    { __typename?: 'Content' }
    & Pick<Content, 'id' | 'updatedAt' | 'createdAt' | 'photos' | 'videos' | 'embedCode' | 'longitude' | 'latitude' | 'contentType' | 'mediaType' | 'source' | 'order'>
    & { description: Array<(
      { __typename?: 'Description' }
      & Pick<Description, 'id' | 'updatedAt' | 'createdAt' | 'title' | 'description' | 'shortDescription' | 'slug' | 'buttonText' | 'buttonUrl' | 'language' | 'metaTitle' | 'metaDescription' | 'metaKeywords' | 'seoPoint'>
    )>, page?: Maybe<(
      { __typename?: 'Page' }
      & Pick<Page, 'id' | 'updatedAt' | 'createdAt'>
    )> }
  )> }
);

export type CreateProductMutationVariables = Exact<{
  sku: Scalars['String'];
  price: Scalars['Int'];
  salesPrice: Scalars['Int'];
  minimumQuantity?: Maybe<Scalars['Int']>;
  buyLink?: Maybe<Scalars['String']>;
  expiryDate?: Maybe<Scalars['DateTime']>;
  brandId: Scalars['String'];
  photos: Array<Scalars['String']> | Scalars['String'];
  files?: Maybe<Scalars['JSON']>;
  disabled?: Maybe<Scalars['Boolean']>;
  categoryId?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  language: Scalars['String'];
  buttonText?: Maybe<Scalars['String']>;
  buttonUrl?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
}>;


export type CreateProductMutation = (
  { __typename?: 'Mutation' }
  & { createProduct: (
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'createdAt' | 'updatedAt' | 'sku'>
  ) }
);

export type CreateProductWithoutCategoryMutationVariables = Exact<{
  sku: Scalars['String'];
  price: Scalars['Int'];
  salesPrice: Scalars['Int'];
  minimumQuantity?: Maybe<Scalars['Int']>;
  expiryDate?: Maybe<Scalars['DateTime']>;
  brandId: Scalars['String'];
  photos: Array<Scalars['String']> | Scalars['String'];
  buyLink?: Maybe<Scalars['String']>;
  files?: Maybe<Scalars['JSON']>;
  disabled?: Maybe<Scalars['Boolean']>;
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  language: Scalars['String'];
  buttonText?: Maybe<Scalars['String']>;
  buttonUrl?: Maybe<Scalars['String']>;
}>;


export type CreateProductWithoutCategoryMutation = (
  { __typename?: 'Mutation' }
  & { createProduct: (
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'createdAt' | 'updatedAt' | 'sku'>
  ) }
);

export type CreateProductContentMutationVariables = Exact<{
  productId: Scalars['String'];
  photos: Array<Scalars['String']> | Scalars['String'];
  videos?: Maybe<Scalars['String']>;
  contentType: ContentType;
  mediaType: MediaType;
  embedCode?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  source?: Maybe<Scalars['String']>;
  cards: Scalars['JSON'];
  slides?: Maybe<Scalars['JSON']>;
  timeline?: Maybe<Scalars['JSON']>;
  backgroundImage?: Maybe<Scalars['String']>;
  theme?: Maybe<ThemeType>;
  productCategoryId?: Maybe<Scalars['String']>;
  blogCategoryId?: Maybe<Scalars['String']>;
  highlightedProducts?: Maybe<Array<IdField> | IdField>;
  lineUp: ContentLineup;
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  language: Scalars['String'];
  buttonText?: Maybe<Scalars['String']>;
  buttonUrl?: Maybe<Scalars['String']>;
}>;


export type CreateProductContentMutation = (
  { __typename?: 'Mutation' }
  & { createContent: (
    { __typename?: 'Content' }
    & Pick<Content, 'id' | 'updatedAt' | 'createdAt'>
  ) }
);

export type CreateProductContentWithoutBgMutationVariables = Exact<{
  productId: Scalars['String'];
  photos: Array<Scalars['String']> | Scalars['String'];
  videos?: Maybe<Scalars['String']>;
  contentType: ContentType;
  mediaType: MediaType;
  embedCode?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  source?: Maybe<Scalars['String']>;
  cards: Scalars['JSON'];
  slides?: Maybe<Scalars['JSON']>;
  timeline?: Maybe<Scalars['JSON']>;
  theme?: Maybe<ThemeType>;
  productCategoryId?: Maybe<Scalars['String']>;
  blogCategoryId?: Maybe<Scalars['String']>;
  highlightedProducts?: Maybe<Array<IdField> | IdField>;
  lineUp: ContentLineup;
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  language: Scalars['String'];
  buttonText?: Maybe<Scalars['String']>;
  buttonUrl?: Maybe<Scalars['String']>;
}>;


export type CreateProductContentWithoutBgMutation = (
  { __typename?: 'Mutation' }
  & { createContent: (
    { __typename?: 'Content' }
    & Pick<Content, 'id' | 'updatedAt' | 'createdAt'>
  ) }
);

export type DeleteProductMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteProductMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteProduct'>
);

export type OrderProductMutationVariables = Exact<{
  id: Scalars['String'];
  order: Scalars['Int'];
}>;


export type OrderProductMutation = (
  { __typename?: 'Mutation' }
  & { updateProduct: (
    { __typename?: 'Product' }
    & Pick<Product, 'id'>
  ) }
);

export type GetProductsByCategoryIdQueryVariables = Exact<{
  categoryId: Scalars['String'];
}>;


export type GetProductsByCategoryIdQuery = (
  { __typename?: 'Query' }
  & { products: (
    { __typename?: 'ProductsPayload' }
    & Pick<ProductsPayload, 'total'>
    & { products: Array<(
      { __typename?: 'Product' }
      & Pick<Product, 'id' | 'createdAt' | 'updatedAt' | 'sku'>
      & { prices: Array<(
        { __typename?: 'Price' }
        & Pick<Price, 'id' | 'price' | 'salesPrice'>
      )>, brand: (
        { __typename?: 'Brand' }
        & Pick<Brand, 'id' | 'title'>
      ), category?: Maybe<(
        { __typename?: 'Category' }
        & Pick<Category, 'id'>
        & { descriptions: Array<(
          { __typename?: 'Description' }
          & Pick<Description, 'id' | 'title'>
        )> }
      )>, descriptions: Array<(
        { __typename?: 'Description' }
        & Pick<Description, 'id' | 'updatedAt' | 'createdAt' | 'title' | 'description' | 'shortDescription' | 'slug' | 'buttonText' | 'buttonUrl' | 'language' | 'metaTitle' | 'metaDescription' | 'metaKeywords' | 'seoPoint'>
      )> }
    )> }
  ) }
);

export type GetProductQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetProductQuery = (
  { __typename?: 'Query' }
  & { product?: Maybe<(
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'createdAt' | 'updatedAt' | 'sku' | 'buyLink' | 'disabled' | 'photos' | 'files' | 'categoryId' | 'order' | 'brandId'>
    & { category?: Maybe<(
      { __typename?: 'Category' }
      & Pick<Category, 'id' | 'parentId'>
    )>, prices: Array<(
      { __typename?: 'Price' }
      & Pick<Price, 'id' | 'price' | 'salesPrice' | 'minimumQuantity' | 'expiryDate'>
    )>, descriptions: Array<(
      { __typename?: 'Description' }
      & Pick<Description, 'id' | 'updatedAt' | 'createdAt' | 'title' | 'description' | 'shortDescription' | 'slug' | 'buttonText' | 'buttonUrl' | 'language' | 'metaTitle' | 'metaDescription' | 'metaKeywords' | 'seoPoint'>
    )> }
  )> }
);

export type GetProductsQueryVariables = Exact<{
  where?: Maybe<ProductWhereInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
}>;


export type GetProductsQuery = (
  { __typename?: 'Query' }
  & { products: (
    { __typename?: 'ProductsPayload' }
    & Pick<ProductsPayload, 'total'>
    & { products: Array<(
      { __typename?: 'Product' }
      & Pick<Product, 'id' | 'createdAt' | 'updatedAt' | 'sku' | 'buyLink' | 'order'>
      & { category?: Maybe<(
        { __typename?: 'Category' }
        & Pick<Category, 'id'>
        & { descriptions: Array<(
          { __typename?: 'Description' }
          & Pick<Description, 'id' | 'title' | 'language'>
        )> }
      )>, prices: Array<(
        { __typename?: 'Price' }
        & Pick<Price, 'id' | 'price' | 'salesPrice'>
      )>, brand: (
        { __typename?: 'Brand' }
        & Pick<Brand, 'title'>
      ), content: Array<(
        { __typename?: 'Content' }
        & Pick<Content, 'id'>
      )>, readerComments: Array<(
        { __typename?: 'ReaderComment' }
        & Pick<ReaderComment, 'id' | 'createdAt' | 'updatedAt'>
      )>, descriptions: Array<(
        { __typename?: 'Description' }
        & Pick<Description, 'id' | 'updatedAt' | 'createdAt' | 'title' | 'description' | 'shortDescription' | 'slug' | 'buttonText' | 'buttonUrl' | 'language' | 'metaTitle' | 'metaDescription' | 'metaKeywords' | 'seoPoint'>
      )> }
    )> }
  ) }
);

export type UpdateProductMutationVariables = Exact<{
  id: Scalars['String'];
  priceId: Scalars['String'];
  sku: Scalars['String'];
  price: Scalars['Int'];
  buyLink?: Maybe<Scalars['String']>;
  salesPrice: Scalars['Int'];
  minimumQuantity?: Maybe<Scalars['Int']>;
  expiryDate?: Maybe<Scalars['DateTime']>;
  brandId: Scalars['String'];
  photos: Array<Scalars['String']> | Scalars['String'];
  files?: Maybe<Scalars['JSON']>;
  disabled?: Maybe<Scalars['Boolean']>;
  categoryId?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
}>;


export type UpdateProductMutation = (
  { __typename?: 'Mutation' }
  & { updateProduct: (
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'createdAt' | 'updatedAt'>
  ) }
);

export type UpdateProductWithoutCategoryMutationVariables = Exact<{
  id: Scalars['String'];
  priceId: Scalars['String'];
  sku: Scalars['String'];
  price: Scalars['Int'];
  salesPrice: Scalars['Int'];
  minimumQuantity?: Maybe<Scalars['Int']>;
  buyLink?: Maybe<Scalars['String']>;
  expiryDate?: Maybe<Scalars['DateTime']>;
  brandId: Scalars['String'];
  photos: Array<Scalars['String']> | Scalars['String'];
  files?: Maybe<Scalars['JSON']>;
  disabled?: Maybe<Scalars['Boolean']>;
}>;


export type UpdateProductWithoutCategoryMutation = (
  { __typename?: 'Mutation' }
  & { updateProduct: (
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'createdAt' | 'updatedAt'>
  ) }
);

export type UpdateProductDisconnectCategoryMutationVariables = Exact<{
  id: Scalars['String'];
  priceId: Scalars['String'];
  sku: Scalars['String'];
  price: Scalars['Int'];
  salesPrice: Scalars['Int'];
  buyLink?: Maybe<Scalars['String']>;
  minimumQuantity?: Maybe<Scalars['Int']>;
  expiryDate?: Maybe<Scalars['DateTime']>;
  brandId: Scalars['String'];
  photos: Array<Scalars['String']> | Scalars['String'];
  files?: Maybe<Scalars['JSON']>;
  disabled?: Maybe<Scalars['Boolean']>;
}>;


export type UpdateProductDisconnectCategoryMutation = (
  { __typename?: 'Mutation' }
  & { updateProduct: (
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'createdAt' | 'updatedAt'>
  ) }
);

export type GetAppSettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAppSettingsQuery = (
  { __typename?: 'Query' }
  & { appSettings: (
    { __typename?: 'AppSettings' }
    & Pick<AppSettings, 'id' | 'appName' | 'domain' | 'language' | 'logoFile' | 'timeZone' | 'dateFormat' | 'timeFormat'>
  ) }
);

export type UpdateAppSettingsMutationVariables = Exact<{
  data: AppSettingsUpdateInput;
}>;


export type UpdateAppSettingsMutation = (
  { __typename?: 'Mutation' }
  & { updateAppSettings: (
    { __typename?: 'AppSettings' }
    & Pick<AppSettings, 'id' | 'appName'>
  ) }
);

export type GetEcommerceSettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetEcommerceSettingsQuery = (
  { __typename?: 'Query' }
  & { ecommerceSettings: (
    { __typename?: 'EcommerceSettings' }
    & Pick<EcommerceSettings, 'id' | 'active' | 'deliveryRadius' | 'currencyCode' | 'currencySymbol' | 'currencyFormat' | 'amountSeparator' | 'decimalNumber' | 'defaultShippingCountry' | 'defaultShippingState' | 'defaultShippingCity' | 'defaultProductSorting' | 'productsLimit' | 'weightUnit' | 'lengthUnit' | 'hideBillingAddress' | 'orderConfirmationCopyTo'>
  ) }
);

export type UpdateEcommerceSettingsMutationVariables = Exact<{
  data: EcommerceSettingsUpdateInput;
}>;


export type UpdateEcommerceSettingsMutation = (
  { __typename?: 'Mutation' }
  & { updateEcommerceSettings: (
    { __typename?: 'EcommerceSettings' }
    & Pick<EcommerceSettings, 'active' | 'deliveryRadius' | 'currencyCode' | 'currencySymbol' | 'currencyFormat' | 'amountSeparator' | 'decimalNumber' | 'defaultShippingCountry' | 'defaultShippingState' | 'defaultShippingCity' | 'defaultProductSorting' | 'productsLimit' | 'weightUnit' | 'lengthUnit' | 'hideBillingAddress' | 'orderConfirmationCopyTo'>
  ) }
);

export type CreateUserMutationVariables = Exact<{
  data: UserCreateInput;
}>;


export type CreateUserMutation = (
  { __typename?: 'Mutation' }
  & { createUser: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'identityNumber' | 'name'>
  ) }
);

export type DeleteUserMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteUser'>
);

export type LoginMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { login: (
    { __typename?: 'AuthPayloadModel' }
    & Pick<AuthPayloadModel, 'accessToken' | 'refreshToken' | 'id'>
  ) }
);

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'logout'>
);

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'identityNumber' | 'name' | 'surname' | 'email' | 'phoneNumber' | 'gender' | 'emailActivated' | 'phoneActivated' | 'status' | 'role' | 'updatedAt' | 'createdAt'>
    & { addresses: Array<(
      { __typename?: 'Address' }
      & Pick<Address, 'id' | 'updatedAt' | 'title' | 'postalCode'>
    )> }
  ) }
);

export type CheckRefreshTokenMutationVariables = Exact<{
  refreshToken: Scalars['String'];
}>;


export type CheckRefreshTokenMutation = (
  { __typename?: 'Mutation' }
  & { checkRefreshToken: (
    { __typename?: 'AuthPayloadModel' }
    & Pick<AuthPayloadModel, 'accessToken' | 'refreshToken' | 'id'>
  ) }
);

export type UpdateUserMutationVariables = Exact<{
  where: UserWhereUniqueInput;
  data: UserUpdateInput;
}>;


export type UpdateUserMutation = (
  { __typename?: 'Mutation' }
  & { updateUser: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'email'>
  ) }
);

export type GetUserQueryVariables = Exact<{
  where: UserWhereUniqueInput;
}>;


export type GetUserQuery = (
  { __typename?: 'Query' }
  & { user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'identityNumber' | 'name' | 'surname' | 'email' | 'phoneNumber' | 'gender' | 'emailActivated' | 'phoneActivated' | 'status' | 'role' | 'updatedAt' | 'createdAt'>
  )> }
);

export type GetUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUsersQuery = (
  { __typename?: 'Query' }
  & { users: Array<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'identityNumber' | 'name' | 'surname' | 'email' | 'phoneNumber' | 'gender' | 'emailActivated' | 'phoneActivated' | 'status' | 'role' | 'updatedAt' | 'createdAt'>
  )> }
);


export const GetAddressDocument = gql`
    query getAddress($id: String!) {
  address(where: {id: $id}) {
    id
    createdAt
    updatedAt
    title
    postalCode
    addressLineOne
    addressLineTwo
    longitude
    latitude
    contact {
      id
      updatedAt
      createdAt
      description {
        id
        updatedAt
        createdAt
        title
        description
        shortDescription
        slug
        buttonText
        buttonUrl
        language
        metaTitle
        metaDescription
        metaKeywords
        seoPoint
      }
      registrationNumber
      mersisNumber
      kepEmail
      email
      phoneNumber
      faxNumber
      taxNumber
      taxOffice
      other
    }
  }
}
    `;

/**
 * __useGetAddressQuery__
 *
 * To run a query within a React component, call `useGetAddressQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAddressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAddressQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAddressQuery(baseOptions: Apollo.QueryHookOptions<GetAddressQuery, GetAddressQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAddressQuery, GetAddressQueryVariables>(GetAddressDocument, options);
      }
export function useGetAddressLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAddressQuery, GetAddressQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAddressQuery, GetAddressQueryVariables>(GetAddressDocument, options);
        }
export type GetAddressQueryHookResult = ReturnType<typeof useGetAddressQuery>;
export type GetAddressLazyQueryHookResult = ReturnType<typeof useGetAddressLazyQuery>;
export type GetAddressQueryResult = Apollo.QueryResult<GetAddressQuery, GetAddressQueryVariables>;
export const GetAddressesDocument = gql`
    query getAddresses {
  addresses(orderBy: {order: asc}) {
    id
    updatedAt
    createdAt
    title
    postalCode
    addressLineOne
    addressLineTwo
    longitude
    latitude
    contact {
      id
      updatedAt
      createdAt
      description {
        id
        updatedAt
        createdAt
        title
        description
        shortDescription
        slug
        buttonText
        buttonUrl
        language
        metaTitle
        metaDescription
        metaKeywords
        seoPoint
      }
      address {
        id
      }
      registrationNumber
      mersisNumber
      kepEmail
      taxNumber
      taxOffice
      other
    }
  }
}
    `;

/**
 * __useGetAddressesQuery__
 *
 * To run a query within a React component, call `useGetAddressesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAddressesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAddressesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAddressesQuery(baseOptions?: Apollo.QueryHookOptions<GetAddressesQuery, GetAddressesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAddressesQuery, GetAddressesQueryVariables>(GetAddressesDocument, options);
      }
export function useGetAddressesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAddressesQuery, GetAddressesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAddressesQuery, GetAddressesQueryVariables>(GetAddressesDocument, options);
        }
export type GetAddressesQueryHookResult = ReturnType<typeof useGetAddressesQuery>;
export type GetAddressesLazyQueryHookResult = ReturnType<typeof useGetAddressesLazyQuery>;
export type GetAddressesQueryResult = Apollo.QueryResult<GetAddressesQuery, GetAddressesQueryVariables>;
export const CreateAddressDocument = gql`
    mutation createAddress($addressTitle: String!, $postalCode: String!, $addressLineOne: String!, $addressLineTwo: String, $longitude: Float, $latitude: Float, $email: String, $phoneNumber: String, $faxNumber: String, $registrationNumber: String, $mersisNumber: String, $kepEmail: String, $taxNumber: String, $taxOffice: String, $title: String!, $description: String, $shortDescription: String, $language: String!, $buttonText: String, $buttonUrl: String) {
  createAddress(
    data: {title: $addressTitle, postalCode: $postalCode, addressLineOne: $addressLineOne, addressLineTwo: $addressLineTwo, longitude: $longitude, latitude: $latitude}
    contact: {registrationNumber: $registrationNumber, mersisNumber: $mersisNumber, kepEmail: $kepEmail, email: $email, phoneNumber: $phoneNumber, faxNumber: $faxNumber, taxNumber: $taxNumber, taxOffice: $taxOffice}
    description: {title: $title, description: $description, shortDescription: $shortDescription, language: $language, buttonText: $buttonText, buttonUrl: $buttonUrl, seoPoint: 0}
  ) {
    id
    updatedAt
    createdAt
  }
}
    `;
export type CreateAddressMutationFn = Apollo.MutationFunction<CreateAddressMutation, CreateAddressMutationVariables>;

/**
 * __useCreateAddressMutation__
 *
 * To run a mutation, you first call `useCreateAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAddressMutation, { data, loading, error }] = useCreateAddressMutation({
 *   variables: {
 *      addressTitle: // value for 'addressTitle'
 *      postalCode: // value for 'postalCode'
 *      addressLineOne: // value for 'addressLineOne'
 *      addressLineTwo: // value for 'addressLineTwo'
 *      longitude: // value for 'longitude'
 *      latitude: // value for 'latitude'
 *      email: // value for 'email'
 *      phoneNumber: // value for 'phoneNumber'
 *      faxNumber: // value for 'faxNumber'
 *      registrationNumber: // value for 'registrationNumber'
 *      mersisNumber: // value for 'mersisNumber'
 *      kepEmail: // value for 'kepEmail'
 *      taxNumber: // value for 'taxNumber'
 *      taxOffice: // value for 'taxOffice'
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      shortDescription: // value for 'shortDescription'
 *      language: // value for 'language'
 *      buttonText: // value for 'buttonText'
 *      buttonUrl: // value for 'buttonUrl'
 *   },
 * });
 */
export function useCreateAddressMutation(baseOptions?: Apollo.MutationHookOptions<CreateAddressMutation, CreateAddressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAddressMutation, CreateAddressMutationVariables>(CreateAddressDocument, options);
      }
export type CreateAddressMutationHookResult = ReturnType<typeof useCreateAddressMutation>;
export type CreateAddressMutationResult = Apollo.MutationResult<CreateAddressMutation>;
export type CreateAddressMutationOptions = Apollo.BaseMutationOptions<CreateAddressMutation, CreateAddressMutationVariables>;
export const DeleteAddressDocument = gql`
    mutation deleteAddress($id: String!) {
  deleteAddress(id: $id)
}
    `;
export type DeleteAddressMutationFn = Apollo.MutationFunction<DeleteAddressMutation, DeleteAddressMutationVariables>;

/**
 * __useDeleteAddressMutation__
 *
 * To run a mutation, you first call `useDeleteAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAddressMutation, { data, loading, error }] = useDeleteAddressMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAddressMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAddressMutation, DeleteAddressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAddressMutation, DeleteAddressMutationVariables>(DeleteAddressDocument, options);
      }
export type DeleteAddressMutationHookResult = ReturnType<typeof useDeleteAddressMutation>;
export type DeleteAddressMutationResult = Apollo.MutationResult<DeleteAddressMutation>;
export type DeleteAddressMutationOptions = Apollo.BaseMutationOptions<DeleteAddressMutation, DeleteAddressMutationVariables>;
export const OrderAddressDocument = gql`
    mutation orderAddress($id: String!, $order: Int!) {
  updateAddress(where: {id: $id}, data: {order: {set: $order}}) {
    id
    updatedAt
  }
}
    `;
export type OrderAddressMutationFn = Apollo.MutationFunction<OrderAddressMutation, OrderAddressMutationVariables>;

/**
 * __useOrderAddressMutation__
 *
 * To run a mutation, you first call `useOrderAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderAddressMutation, { data, loading, error }] = useOrderAddressMutation({
 *   variables: {
 *      id: // value for 'id'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useOrderAddressMutation(baseOptions?: Apollo.MutationHookOptions<OrderAddressMutation, OrderAddressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OrderAddressMutation, OrderAddressMutationVariables>(OrderAddressDocument, options);
      }
export type OrderAddressMutationHookResult = ReturnType<typeof useOrderAddressMutation>;
export type OrderAddressMutationResult = Apollo.MutationResult<OrderAddressMutation>;
export type OrderAddressMutationOptions = Apollo.BaseMutationOptions<OrderAddressMutation, OrderAddressMutationVariables>;
export const UpdateAddressDocument = gql`
    mutation updateAddress($id: String!, $addressTitle: String!, $postalCode: String!, $addressLineOne: String!, $addressLineTwo: String, $longitude: Float, $latitude: Float, $registrationNumber: String, $email: String, $phoneNumber: String, $faxNumber: String, $mersisNumber: String, $kepEmail: String, $taxNumber: String, $taxOffice: String) {
  updateAddress(
    where: {id: $id}
    data: {title: {set: $addressTitle}, postalCode: {set: $postalCode}, addressLineOne: {set: $addressLineOne}, addressLineTwo: {set: $addressLineTwo}, longitude: {set: $longitude}, latitude: {set: $latitude}, contact: {update: {email: {set: $email}, phoneNumber: {set: $phoneNumber}, faxNumber: {set: $faxNumber}, registrationNumber: {set: $registrationNumber}, mersisNumber: {set: $mersisNumber}, kepEmail: {set: $kepEmail}, taxNumber: {set: $taxNumber}, taxOffice: {set: $taxOffice}}}}
  ) {
    id
    updatedAt
    createdAt
    postalCode
  }
}
    `;
export type UpdateAddressMutationFn = Apollo.MutationFunction<UpdateAddressMutation, UpdateAddressMutationVariables>;

/**
 * __useUpdateAddressMutation__
 *
 * To run a mutation, you first call `useUpdateAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAddressMutation, { data, loading, error }] = useUpdateAddressMutation({
 *   variables: {
 *      id: // value for 'id'
 *      addressTitle: // value for 'addressTitle'
 *      postalCode: // value for 'postalCode'
 *      addressLineOne: // value for 'addressLineOne'
 *      addressLineTwo: // value for 'addressLineTwo'
 *      longitude: // value for 'longitude'
 *      latitude: // value for 'latitude'
 *      registrationNumber: // value for 'registrationNumber'
 *      email: // value for 'email'
 *      phoneNumber: // value for 'phoneNumber'
 *      faxNumber: // value for 'faxNumber'
 *      mersisNumber: // value for 'mersisNumber'
 *      kepEmail: // value for 'kepEmail'
 *      taxNumber: // value for 'taxNumber'
 *      taxOffice: // value for 'taxOffice'
 *   },
 * });
 */
export function useUpdateAddressMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAddressMutation, UpdateAddressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAddressMutation, UpdateAddressMutationVariables>(UpdateAddressDocument, options);
      }
export type UpdateAddressMutationHookResult = ReturnType<typeof useUpdateAddressMutation>;
export type UpdateAddressMutationResult = Apollo.MutationResult<UpdateAddressMutation>;
export type UpdateAddressMutationOptions = Apollo.BaseMutationOptions<UpdateAddressMutation, UpdateAddressMutationVariables>;
export const GetBlogCategoriesDocument = gql`
    query getBlogCategories {
  blogCategories(orderBy: {order: asc}) {
    id
    createdAt
    updatedAt
    order
    hidden
    description {
      id
      updatedAt
      createdAt
      title
      description
      shortDescription
      slug
      buttonText
      buttonUrl
      language
      metaTitle
      metaDescription
      metaKeywords
      seoPoint
    }
  }
}
    `;

/**
 * __useGetBlogCategoriesQuery__
 *
 * To run a query within a React component, call `useGetBlogCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBlogCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBlogCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBlogCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetBlogCategoriesQuery, GetBlogCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBlogCategoriesQuery, GetBlogCategoriesQueryVariables>(GetBlogCategoriesDocument, options);
      }
export function useGetBlogCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBlogCategoriesQuery, GetBlogCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBlogCategoriesQuery, GetBlogCategoriesQueryVariables>(GetBlogCategoriesDocument, options);
        }
export type GetBlogCategoriesQueryHookResult = ReturnType<typeof useGetBlogCategoriesQuery>;
export type GetBlogCategoriesLazyQueryHookResult = ReturnType<typeof useGetBlogCategoriesLazyQuery>;
export type GetBlogCategoriesQueryResult = Apollo.QueryResult<GetBlogCategoriesQuery, GetBlogCategoriesQueryVariables>;
export const GetBlogCategoryDocument = gql`
    query getBlogCategory($id: String!) {
  findFirstBlogCategory(where: {id: {equals: $id}}) {
    id
    createdAt
    updatedAt
    order
    hidden
    thumbnail
    description {
      id
      updatedAt
      createdAt
      title
      description
      shortDescription
      slug
      buttonText
      buttonUrl
      language
      metaTitle
      metaDescription
      metaKeywords
      seoPoint
    }
  }
}
    `;

/**
 * __useGetBlogCategoryQuery__
 *
 * To run a query within a React component, call `useGetBlogCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBlogCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBlogCategoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBlogCategoryQuery(baseOptions: Apollo.QueryHookOptions<GetBlogCategoryQuery, GetBlogCategoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBlogCategoryQuery, GetBlogCategoryQueryVariables>(GetBlogCategoryDocument, options);
      }
export function useGetBlogCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBlogCategoryQuery, GetBlogCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBlogCategoryQuery, GetBlogCategoryQueryVariables>(GetBlogCategoryDocument, options);
        }
export type GetBlogCategoryQueryHookResult = ReturnType<typeof useGetBlogCategoryQuery>;
export type GetBlogCategoryLazyQueryHookResult = ReturnType<typeof useGetBlogCategoryLazyQuery>;
export type GetBlogCategoryQueryResult = Apollo.QueryResult<GetBlogCategoryQuery, GetBlogCategoryQueryVariables>;
export const CreateBlogCategoryDocument = gql`
    mutation createBlogCategory($thumbnail: String, $hidden: Boolean, $title: String!, $description: String, $shortDescription: String, $language: String!, $buttonText: String, $buttonUrl: String) {
  createBlogCategory(
    data: {thumbnail: $thumbnail, hidden: $hidden}
    description: {title: $title, description: $description, shortDescription: $shortDescription, language: $language, buttonText: $buttonText, buttonUrl: $buttonUrl, seoPoint: 0}
  ) {
    id
    createdAt
    updatedAt
  }
}
    `;
export type CreateBlogCategoryMutationFn = Apollo.MutationFunction<CreateBlogCategoryMutation, CreateBlogCategoryMutationVariables>;

/**
 * __useCreateBlogCategoryMutation__
 *
 * To run a mutation, you first call `useCreateBlogCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBlogCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBlogCategoryMutation, { data, loading, error }] = useCreateBlogCategoryMutation({
 *   variables: {
 *      thumbnail: // value for 'thumbnail'
 *      hidden: // value for 'hidden'
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      shortDescription: // value for 'shortDescription'
 *      language: // value for 'language'
 *      buttonText: // value for 'buttonText'
 *      buttonUrl: // value for 'buttonUrl'
 *   },
 * });
 */
export function useCreateBlogCategoryMutation(baseOptions?: Apollo.MutationHookOptions<CreateBlogCategoryMutation, CreateBlogCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBlogCategoryMutation, CreateBlogCategoryMutationVariables>(CreateBlogCategoryDocument, options);
      }
export type CreateBlogCategoryMutationHookResult = ReturnType<typeof useCreateBlogCategoryMutation>;
export type CreateBlogCategoryMutationResult = Apollo.MutationResult<CreateBlogCategoryMutation>;
export type CreateBlogCategoryMutationOptions = Apollo.BaseMutationOptions<CreateBlogCategoryMutation, CreateBlogCategoryMutationVariables>;
export const CreateBlogCategoryWithoutThumbnailDocument = gql`
    mutation createBlogCategoryWithoutThumbnail($hidden: Boolean, $title: String!, $description: String, $shortDescription: String, $language: String!, $buttonText: String, $buttonUrl: String) {
  createBlogCategory(
    data: {hidden: $hidden}
    description: {title: $title, description: $description, shortDescription: $shortDescription, language: $language, buttonText: $buttonText, buttonUrl: $buttonUrl, seoPoint: 0}
  ) {
    id
    createdAt
    updatedAt
  }
}
    `;
export type CreateBlogCategoryWithoutThumbnailMutationFn = Apollo.MutationFunction<CreateBlogCategoryWithoutThumbnailMutation, CreateBlogCategoryWithoutThumbnailMutationVariables>;

/**
 * __useCreateBlogCategoryWithoutThumbnailMutation__
 *
 * To run a mutation, you first call `useCreateBlogCategoryWithoutThumbnailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBlogCategoryWithoutThumbnailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBlogCategoryWithoutThumbnailMutation, { data, loading, error }] = useCreateBlogCategoryWithoutThumbnailMutation({
 *   variables: {
 *      hidden: // value for 'hidden'
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      shortDescription: // value for 'shortDescription'
 *      language: // value for 'language'
 *      buttonText: // value for 'buttonText'
 *      buttonUrl: // value for 'buttonUrl'
 *   },
 * });
 */
export function useCreateBlogCategoryWithoutThumbnailMutation(baseOptions?: Apollo.MutationHookOptions<CreateBlogCategoryWithoutThumbnailMutation, CreateBlogCategoryWithoutThumbnailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBlogCategoryWithoutThumbnailMutation, CreateBlogCategoryWithoutThumbnailMutationVariables>(CreateBlogCategoryWithoutThumbnailDocument, options);
      }
export type CreateBlogCategoryWithoutThumbnailMutationHookResult = ReturnType<typeof useCreateBlogCategoryWithoutThumbnailMutation>;
export type CreateBlogCategoryWithoutThumbnailMutationResult = Apollo.MutationResult<CreateBlogCategoryWithoutThumbnailMutation>;
export type CreateBlogCategoryWithoutThumbnailMutationOptions = Apollo.BaseMutationOptions<CreateBlogCategoryWithoutThumbnailMutation, CreateBlogCategoryWithoutThumbnailMutationVariables>;
export const DeleteBlogCategoryDocument = gql`
    mutation deleteBlogCategory($id: ID!) {
  deleteBlogCategory(id: $id)
}
    `;
export type DeleteBlogCategoryMutationFn = Apollo.MutationFunction<DeleteBlogCategoryMutation, DeleteBlogCategoryMutationVariables>;

/**
 * __useDeleteBlogCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteBlogCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBlogCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBlogCategoryMutation, { data, loading, error }] = useDeleteBlogCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteBlogCategoryMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBlogCategoryMutation, DeleteBlogCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteBlogCategoryMutation, DeleteBlogCategoryMutationVariables>(DeleteBlogCategoryDocument, options);
      }
export type DeleteBlogCategoryMutationHookResult = ReturnType<typeof useDeleteBlogCategoryMutation>;
export type DeleteBlogCategoryMutationResult = Apollo.MutationResult<DeleteBlogCategoryMutation>;
export type DeleteBlogCategoryMutationOptions = Apollo.BaseMutationOptions<DeleteBlogCategoryMutation, DeleteBlogCategoryMutationVariables>;
export const OrderBlogCategoryDocument = gql`
    mutation orderBlogCategory($id: String!, $order: Int!) {
  updateBlogCategory(where: {id: $id}, data: {order: {set: $order}}) {
    id
    updatedAt
  }
}
    `;
export type OrderBlogCategoryMutationFn = Apollo.MutationFunction<OrderBlogCategoryMutation, OrderBlogCategoryMutationVariables>;

/**
 * __useOrderBlogCategoryMutation__
 *
 * To run a mutation, you first call `useOrderBlogCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderBlogCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderBlogCategoryMutation, { data, loading, error }] = useOrderBlogCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useOrderBlogCategoryMutation(baseOptions?: Apollo.MutationHookOptions<OrderBlogCategoryMutation, OrderBlogCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OrderBlogCategoryMutation, OrderBlogCategoryMutationVariables>(OrderBlogCategoryDocument, options);
      }
export type OrderBlogCategoryMutationHookResult = ReturnType<typeof useOrderBlogCategoryMutation>;
export type OrderBlogCategoryMutationResult = Apollo.MutationResult<OrderBlogCategoryMutation>;
export type OrderBlogCategoryMutationOptions = Apollo.BaseMutationOptions<OrderBlogCategoryMutation, OrderBlogCategoryMutationVariables>;
export const UpdateBlogCategoryDocument = gql`
    mutation updateBlogCategory($thumbnail: String, $id: String!, $hidden: Boolean) {
  updateBlogCategory(
    data: {thumbnail: {set: $thumbnail}, hidden: {set: $hidden}}
    where: {id: $id}
  ) {
    id
    updatedAt
  }
}
    `;
export type UpdateBlogCategoryMutationFn = Apollo.MutationFunction<UpdateBlogCategoryMutation, UpdateBlogCategoryMutationVariables>;

/**
 * __useUpdateBlogCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateBlogCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBlogCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBlogCategoryMutation, { data, loading, error }] = useUpdateBlogCategoryMutation({
 *   variables: {
 *      thumbnail: // value for 'thumbnail'
 *      id: // value for 'id'
 *      hidden: // value for 'hidden'
 *   },
 * });
 */
export function useUpdateBlogCategoryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBlogCategoryMutation, UpdateBlogCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBlogCategoryMutation, UpdateBlogCategoryMutationVariables>(UpdateBlogCategoryDocument, options);
      }
export type UpdateBlogCategoryMutationHookResult = ReturnType<typeof useUpdateBlogCategoryMutation>;
export type UpdateBlogCategoryMutationResult = Apollo.MutationResult<UpdateBlogCategoryMutation>;
export type UpdateBlogCategoryMutationOptions = Apollo.BaseMutationOptions<UpdateBlogCategoryMutation, UpdateBlogCategoryMutationVariables>;
export const UpdateBlogCategoryWithoutPhotoDocument = gql`
    mutation updateBlogCategoryWithoutPhoto($id: String!, $thumbnail: String, $hidden: Boolean) {
  updateBlogCategory(
    where: {id: $id}
    data: {thumbnail: {set: $thumbnail}, hidden: {set: $hidden}}
  ) {
    id
    updatedAt
  }
}
    `;
export type UpdateBlogCategoryWithoutPhotoMutationFn = Apollo.MutationFunction<UpdateBlogCategoryWithoutPhotoMutation, UpdateBlogCategoryWithoutPhotoMutationVariables>;

/**
 * __useUpdateBlogCategoryWithoutPhotoMutation__
 *
 * To run a mutation, you first call `useUpdateBlogCategoryWithoutPhotoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBlogCategoryWithoutPhotoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBlogCategoryWithoutPhotoMutation, { data, loading, error }] = useUpdateBlogCategoryWithoutPhotoMutation({
 *   variables: {
 *      id: // value for 'id'
 *      thumbnail: // value for 'thumbnail'
 *      hidden: // value for 'hidden'
 *   },
 * });
 */
export function useUpdateBlogCategoryWithoutPhotoMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBlogCategoryWithoutPhotoMutation, UpdateBlogCategoryWithoutPhotoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBlogCategoryWithoutPhotoMutation, UpdateBlogCategoryWithoutPhotoMutationVariables>(UpdateBlogCategoryWithoutPhotoDocument, options);
      }
export type UpdateBlogCategoryWithoutPhotoMutationHookResult = ReturnType<typeof useUpdateBlogCategoryWithoutPhotoMutation>;
export type UpdateBlogCategoryWithoutPhotoMutationResult = Apollo.MutationResult<UpdateBlogCategoryWithoutPhotoMutation>;
export type UpdateBlogCategoryWithoutPhotoMutationOptions = Apollo.BaseMutationOptions<UpdateBlogCategoryWithoutPhotoMutation, UpdateBlogCategoryWithoutPhotoMutationVariables>;
export const GetBlogPostContentsDocument = gql`
    query getBlogPostContents($postId: String) {
  contents(where: {blogPostId: {equals: $postId}}, orderBy: {order: asc}) {
    id
    updatedAt
    createdAt
    description {
      id
      updatedAt
      createdAt
      title
      description
      shortDescription
      slug
      buttonText
      buttonUrl
      language
      metaTitle
      metaDescription
      metaKeywords
      seoPoint
    }
    photos
    page {
      id
      updatedAt
      createdAt
    }
    videos
    embedCode
    longitude
    latitude
    contentType
    mediaType
    source
    order
  }
}
    `;

/**
 * __useGetBlogPostContentsQuery__
 *
 * To run a query within a React component, call `useGetBlogPostContentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBlogPostContentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBlogPostContentsQuery({
 *   variables: {
 *      postId: // value for 'postId'
 *   },
 * });
 */
export function useGetBlogPostContentsQuery(baseOptions?: Apollo.QueryHookOptions<GetBlogPostContentsQuery, GetBlogPostContentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBlogPostContentsQuery, GetBlogPostContentsQueryVariables>(GetBlogPostContentsDocument, options);
      }
export function useGetBlogPostContentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBlogPostContentsQuery, GetBlogPostContentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBlogPostContentsQuery, GetBlogPostContentsQueryVariables>(GetBlogPostContentsDocument, options);
        }
export type GetBlogPostContentsQueryHookResult = ReturnType<typeof useGetBlogPostContentsQuery>;
export type GetBlogPostContentsLazyQueryHookResult = ReturnType<typeof useGetBlogPostContentsLazyQuery>;
export type GetBlogPostContentsQueryResult = Apollo.QueryResult<GetBlogPostContentsQuery, GetBlogPostContentsQueryVariables>;
export const CreateBlogPostDocument = gql`
    mutation createBlogPost($thumbnail: String, $categoryId: String!, $authorId: String!, $publishedAt: String, $isHidden: Boolean, $title: String!, $description: String, $shortDescription: String, $language: String!, $buttonText: String, $buttonUrl: String) {
  createBlogPost(
    data: {thumbnail: $thumbnail, publishedAt: $publishedAt, category: {connect: {id: $categoryId}}, author: {connect: {id: $authorId}}, isHidden: $isHidden}
    description: {title: $title, description: $description, shortDescription: $shortDescription, language: $language, buttonText: $buttonText, buttonUrl: $buttonUrl, seoPoint: 0}
  ) {
    id
  }
}
    `;
export type CreateBlogPostMutationFn = Apollo.MutationFunction<CreateBlogPostMutation, CreateBlogPostMutationVariables>;

/**
 * __useCreateBlogPostMutation__
 *
 * To run a mutation, you first call `useCreateBlogPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBlogPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBlogPostMutation, { data, loading, error }] = useCreateBlogPostMutation({
 *   variables: {
 *      thumbnail: // value for 'thumbnail'
 *      categoryId: // value for 'categoryId'
 *      authorId: // value for 'authorId'
 *      publishedAt: // value for 'publishedAt'
 *      isHidden: // value for 'isHidden'
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      shortDescription: // value for 'shortDescription'
 *      language: // value for 'language'
 *      buttonText: // value for 'buttonText'
 *      buttonUrl: // value for 'buttonUrl'
 *   },
 * });
 */
export function useCreateBlogPostMutation(baseOptions?: Apollo.MutationHookOptions<CreateBlogPostMutation, CreateBlogPostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBlogPostMutation, CreateBlogPostMutationVariables>(CreateBlogPostDocument, options);
      }
export type CreateBlogPostMutationHookResult = ReturnType<typeof useCreateBlogPostMutation>;
export type CreateBlogPostMutationResult = Apollo.MutationResult<CreateBlogPostMutation>;
export type CreateBlogPostMutationOptions = Apollo.BaseMutationOptions<CreateBlogPostMutation, CreateBlogPostMutationVariables>;
export const CreateBlogPostWithoutThumbnailDocument = gql`
    mutation createBlogPostWithoutThumbnail($categoryId: String!, $authorId: String!, $publishedAt: String, $isHidden: Boolean, $title: String!, $description: String, $shortDescription: String, $language: String!, $buttonText: String, $buttonUrl: String) {
  createBlogPost(
    data: {publishedAt: $publishedAt, category: {connect: {id: $categoryId}}, author: {connect: {id: $authorId}}, isHidden: $isHidden}
    description: {title: $title, description: $description, shortDescription: $shortDescription, language: $language, buttonText: $buttonText, buttonUrl: $buttonUrl, seoPoint: 0}
  ) {
    id
  }
}
    `;
export type CreateBlogPostWithoutThumbnailMutationFn = Apollo.MutationFunction<CreateBlogPostWithoutThumbnailMutation, CreateBlogPostWithoutThumbnailMutationVariables>;

/**
 * __useCreateBlogPostWithoutThumbnailMutation__
 *
 * To run a mutation, you first call `useCreateBlogPostWithoutThumbnailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBlogPostWithoutThumbnailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBlogPostWithoutThumbnailMutation, { data, loading, error }] = useCreateBlogPostWithoutThumbnailMutation({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *      authorId: // value for 'authorId'
 *      publishedAt: // value for 'publishedAt'
 *      isHidden: // value for 'isHidden'
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      shortDescription: // value for 'shortDescription'
 *      language: // value for 'language'
 *      buttonText: // value for 'buttonText'
 *      buttonUrl: // value for 'buttonUrl'
 *   },
 * });
 */
export function useCreateBlogPostWithoutThumbnailMutation(baseOptions?: Apollo.MutationHookOptions<CreateBlogPostWithoutThumbnailMutation, CreateBlogPostWithoutThumbnailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBlogPostWithoutThumbnailMutation, CreateBlogPostWithoutThumbnailMutationVariables>(CreateBlogPostWithoutThumbnailDocument, options);
      }
export type CreateBlogPostWithoutThumbnailMutationHookResult = ReturnType<typeof useCreateBlogPostWithoutThumbnailMutation>;
export type CreateBlogPostWithoutThumbnailMutationResult = Apollo.MutationResult<CreateBlogPostWithoutThumbnailMutation>;
export type CreateBlogPostWithoutThumbnailMutationOptions = Apollo.BaseMutationOptions<CreateBlogPostWithoutThumbnailMutation, CreateBlogPostWithoutThumbnailMutationVariables>;
export const CreateBlogPostContentDocument = gql`
    mutation createBlogPostContent($postId: String!, $photos: [String!]!, $videos: String, $contentType: ContentType!, $mediaType: MediaType!, $embedCode: String, $longitude: Float, $latitude: Float, $source: String, $cards: JSON!, $slides: JSON, $timeline: JSON, $backgroundImage: String, $theme: ThemeType, $productCategoryId: String, $blogCategoryId: String, $highlightedProducts: [IDField!], $lineUp: ContentLineup!, $title: String!, $description: String, $shortDescription: String, $language: String!, $buttonText: String, $buttonUrl: String) {
  createContent(
    data: {blogPost: {connect: {id: $postId}}, photos: {set: $photos}, videos: $videos, contentType: $contentType, mediaType: $mediaType, embedCode: $embedCode, longitude: $longitude, latitude: $latitude, source: $source, cards: $cards, slides: $slides, timeline: $timeline, backgroundImage: $backgroundImage, theme: $theme, productCategoryId: $productCategoryId, blogCategoryId: $blogCategoryId, products: $highlightedProducts, lineUp: $lineUp, description: {create: {title: $title, description: $description, shortDescription: $shortDescription, language: $language, buttonText: $buttonText, buttonUrl: $buttonUrl, seoPoint: 0}}}
  ) {
    id
    updatedAt
    createdAt
  }
}
    `;
export type CreateBlogPostContentMutationFn = Apollo.MutationFunction<CreateBlogPostContentMutation, CreateBlogPostContentMutationVariables>;

/**
 * __useCreateBlogPostContentMutation__
 *
 * To run a mutation, you first call `useCreateBlogPostContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBlogPostContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBlogPostContentMutation, { data, loading, error }] = useCreateBlogPostContentMutation({
 *   variables: {
 *      postId: // value for 'postId'
 *      photos: // value for 'photos'
 *      videos: // value for 'videos'
 *      contentType: // value for 'contentType'
 *      mediaType: // value for 'mediaType'
 *      embedCode: // value for 'embedCode'
 *      longitude: // value for 'longitude'
 *      latitude: // value for 'latitude'
 *      source: // value for 'source'
 *      cards: // value for 'cards'
 *      slides: // value for 'slides'
 *      timeline: // value for 'timeline'
 *      backgroundImage: // value for 'backgroundImage'
 *      theme: // value for 'theme'
 *      productCategoryId: // value for 'productCategoryId'
 *      blogCategoryId: // value for 'blogCategoryId'
 *      highlightedProducts: // value for 'highlightedProducts'
 *      lineUp: // value for 'lineUp'
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      shortDescription: // value for 'shortDescription'
 *      language: // value for 'language'
 *      buttonText: // value for 'buttonText'
 *      buttonUrl: // value for 'buttonUrl'
 *   },
 * });
 */
export function useCreateBlogPostContentMutation(baseOptions?: Apollo.MutationHookOptions<CreateBlogPostContentMutation, CreateBlogPostContentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBlogPostContentMutation, CreateBlogPostContentMutationVariables>(CreateBlogPostContentDocument, options);
      }
export type CreateBlogPostContentMutationHookResult = ReturnType<typeof useCreateBlogPostContentMutation>;
export type CreateBlogPostContentMutationResult = Apollo.MutationResult<CreateBlogPostContentMutation>;
export type CreateBlogPostContentMutationOptions = Apollo.BaseMutationOptions<CreateBlogPostContentMutation, CreateBlogPostContentMutationVariables>;
export const CreateBlogPostContentWithoutBgDocument = gql`
    mutation createBlogPostContentWithoutBg($postId: String!, $photos: [String!]!, $videos: String, $contentType: ContentType!, $mediaType: MediaType!, $embedCode: String, $longitude: Float, $latitude: Float, $source: String, $cards: JSON!, $slides: JSON, $timeline: JSON, $theme: ThemeType, $productCategoryId: String, $blogCategoryId: String, $highlightedProducts: [IDField!], $lineUp: ContentLineup!, $title: String!, $description: String, $shortDescription: String, $language: String!, $buttonText: String, $buttonUrl: String) {
  createContent(
    data: {blogPost: {connect: {id: $postId}}, photos: {set: $photos}, videos: $videos, contentType: $contentType, mediaType: $mediaType, embedCode: $embedCode, longitude: $longitude, latitude: $latitude, source: $source, cards: $cards, slides: $slides, timeline: $timeline, theme: $theme, productCategoryId: $productCategoryId, blogCategoryId: $blogCategoryId, products: $highlightedProducts, lineUp: $lineUp, description: {create: {title: $title, description: $description, shortDescription: $shortDescription, language: $language, buttonText: $buttonText, buttonUrl: $buttonUrl, seoPoint: 0}}}
  ) {
    id
    updatedAt
    createdAt
  }
}
    `;
export type CreateBlogPostContentWithoutBgMutationFn = Apollo.MutationFunction<CreateBlogPostContentWithoutBgMutation, CreateBlogPostContentWithoutBgMutationVariables>;

/**
 * __useCreateBlogPostContentWithoutBgMutation__
 *
 * To run a mutation, you first call `useCreateBlogPostContentWithoutBgMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBlogPostContentWithoutBgMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBlogPostContentWithoutBgMutation, { data, loading, error }] = useCreateBlogPostContentWithoutBgMutation({
 *   variables: {
 *      postId: // value for 'postId'
 *      photos: // value for 'photos'
 *      videos: // value for 'videos'
 *      contentType: // value for 'contentType'
 *      mediaType: // value for 'mediaType'
 *      embedCode: // value for 'embedCode'
 *      longitude: // value for 'longitude'
 *      latitude: // value for 'latitude'
 *      source: // value for 'source'
 *      cards: // value for 'cards'
 *      slides: // value for 'slides'
 *      timeline: // value for 'timeline'
 *      theme: // value for 'theme'
 *      productCategoryId: // value for 'productCategoryId'
 *      blogCategoryId: // value for 'blogCategoryId'
 *      highlightedProducts: // value for 'highlightedProducts'
 *      lineUp: // value for 'lineUp'
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      shortDescription: // value for 'shortDescription'
 *      language: // value for 'language'
 *      buttonText: // value for 'buttonText'
 *      buttonUrl: // value for 'buttonUrl'
 *   },
 * });
 */
export function useCreateBlogPostContentWithoutBgMutation(baseOptions?: Apollo.MutationHookOptions<CreateBlogPostContentWithoutBgMutation, CreateBlogPostContentWithoutBgMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBlogPostContentWithoutBgMutation, CreateBlogPostContentWithoutBgMutationVariables>(CreateBlogPostContentWithoutBgDocument, options);
      }
export type CreateBlogPostContentWithoutBgMutationHookResult = ReturnType<typeof useCreateBlogPostContentWithoutBgMutation>;
export type CreateBlogPostContentWithoutBgMutationResult = Apollo.MutationResult<CreateBlogPostContentWithoutBgMutation>;
export type CreateBlogPostContentWithoutBgMutationOptions = Apollo.BaseMutationOptions<CreateBlogPostContentWithoutBgMutation, CreateBlogPostContentWithoutBgMutationVariables>;
export const DeleteBlogPostDocument = gql`
    mutation deleteBlogPost($id: ID!) {
  deleteBlogPost(id: $id)
}
    `;
export type DeleteBlogPostMutationFn = Apollo.MutationFunction<DeleteBlogPostMutation, DeleteBlogPostMutationVariables>;

/**
 * __useDeleteBlogPostMutation__
 *
 * To run a mutation, you first call `useDeleteBlogPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBlogPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBlogPostMutation, { data, loading, error }] = useDeleteBlogPostMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteBlogPostMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBlogPostMutation, DeleteBlogPostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteBlogPostMutation, DeleteBlogPostMutationVariables>(DeleteBlogPostDocument, options);
      }
export type DeleteBlogPostMutationHookResult = ReturnType<typeof useDeleteBlogPostMutation>;
export type DeleteBlogPostMutationResult = Apollo.MutationResult<DeleteBlogPostMutation>;
export type DeleteBlogPostMutationOptions = Apollo.BaseMutationOptions<DeleteBlogPostMutation, DeleteBlogPostMutationVariables>;
export const GetBlogPostDocument = gql`
    query getBlogPost($id: String!) {
  findFirstBlogPost(where: {id: {equals: $id}}) {
    id
    createdAt
    updatedAt
    publishedAt
    thumbnail
    isHidden
    blogCategoryId
    description {
      id
      updatedAt
      createdAt
      title
      description
      shortDescription
      slug
      buttonText
      buttonUrl
      language
      metaTitle
      metaDescription
      metaKeywords
      seoPoint
    }
  }
}
    `;

/**
 * __useGetBlogPostQuery__
 *
 * To run a query within a React component, call `useGetBlogPostQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBlogPostQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBlogPostQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBlogPostQuery(baseOptions: Apollo.QueryHookOptions<GetBlogPostQuery, GetBlogPostQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBlogPostQuery, GetBlogPostQueryVariables>(GetBlogPostDocument, options);
      }
export function useGetBlogPostLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBlogPostQuery, GetBlogPostQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBlogPostQuery, GetBlogPostQueryVariables>(GetBlogPostDocument, options);
        }
export type GetBlogPostQueryHookResult = ReturnType<typeof useGetBlogPostQuery>;
export type GetBlogPostLazyQueryHookResult = ReturnType<typeof useGetBlogPostLazyQuery>;
export type GetBlogPostQueryResult = Apollo.QueryResult<GetBlogPostQuery, GetBlogPostQueryVariables>;
export const GetBlogPostsDocument = gql`
    query getBlogPosts($recordsPerPage: Int, $skip: Int, $where: BlogPostWhereInput) {
  blogPosts(
    orderBy: {createdAt: desc}
    take: $recordsPerPage
    skip: $skip
    where: $where
  ) {
    total
    posts {
      id
      createdAt
      updatedAt
      publishedAt
      thumbnail
      isHidden
      content {
        id
      }
      category {
        description {
          title
        }
      }
      description {
        id
        updatedAt
        createdAt
        title
        description
        shortDescription
        slug
        buttonText
        buttonUrl
        language
        metaTitle
        metaDescription
        metaKeywords
        seoPoint
      }
    }
  }
}
    `;

/**
 * __useGetBlogPostsQuery__
 *
 * To run a query within a React component, call `useGetBlogPostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBlogPostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBlogPostsQuery({
 *   variables: {
 *      recordsPerPage: // value for 'recordsPerPage'
 *      skip: // value for 'skip'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetBlogPostsQuery(baseOptions?: Apollo.QueryHookOptions<GetBlogPostsQuery, GetBlogPostsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBlogPostsQuery, GetBlogPostsQueryVariables>(GetBlogPostsDocument, options);
      }
export function useGetBlogPostsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBlogPostsQuery, GetBlogPostsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBlogPostsQuery, GetBlogPostsQueryVariables>(GetBlogPostsDocument, options);
        }
export type GetBlogPostsQueryHookResult = ReturnType<typeof useGetBlogPostsQuery>;
export type GetBlogPostsLazyQueryHookResult = ReturnType<typeof useGetBlogPostsLazyQuery>;
export type GetBlogPostsQueryResult = Apollo.QueryResult<GetBlogPostsQuery, GetBlogPostsQueryVariables>;
export const UpdateBlogPostDocument = gql`
    mutation updateBlogPost($id: String!, $categoryId: String!, $thumbnail: String, $authorId: String!, $publishedAt: String, $isHidden: Boolean) {
  updateBlogPost(
    where: {id: $id}
    data: {publishedAt: {set: $publishedAt}, thumbnail: {set: $thumbnail}, category: {connect: {id: $categoryId}}, author: {connect: {id: $authorId}}, isHidden: {set: $isHidden}}
  ) {
    id
    createdAt
    updatedAt
  }
}
    `;
export type UpdateBlogPostMutationFn = Apollo.MutationFunction<UpdateBlogPostMutation, UpdateBlogPostMutationVariables>;

/**
 * __useUpdateBlogPostMutation__
 *
 * To run a mutation, you first call `useUpdateBlogPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBlogPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBlogPostMutation, { data, loading, error }] = useUpdateBlogPostMutation({
 *   variables: {
 *      id: // value for 'id'
 *      categoryId: // value for 'categoryId'
 *      thumbnail: // value for 'thumbnail'
 *      authorId: // value for 'authorId'
 *      publishedAt: // value for 'publishedAt'
 *      isHidden: // value for 'isHidden'
 *   },
 * });
 */
export function useUpdateBlogPostMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBlogPostMutation, UpdateBlogPostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBlogPostMutation, UpdateBlogPostMutationVariables>(UpdateBlogPostDocument, options);
      }
export type UpdateBlogPostMutationHookResult = ReturnType<typeof useUpdateBlogPostMutation>;
export type UpdateBlogPostMutationResult = Apollo.MutationResult<UpdateBlogPostMutation>;
export type UpdateBlogPostMutationOptions = Apollo.BaseMutationOptions<UpdateBlogPostMutation, UpdateBlogPostMutationVariables>;
export const UpdateBlogPostWithoutThumbnailDocument = gql`
    mutation updateBlogPostWithoutThumbnail($id: String!, $authorId: String, $categoryId: String!, $publishedAt: String, $isHidden: Boolean) {
  updateBlogPost(
    where: {id: $id}
    data: {publishedAt: {set: $publishedAt}, category: {connect: {id: $categoryId}}, author: {connect: {id: $authorId}}, isHidden: {set: $isHidden}}
  ) {
    id
    createdAt
    updatedAt
  }
}
    `;
export type UpdateBlogPostWithoutThumbnailMutationFn = Apollo.MutationFunction<UpdateBlogPostWithoutThumbnailMutation, UpdateBlogPostWithoutThumbnailMutationVariables>;

/**
 * __useUpdateBlogPostWithoutThumbnailMutation__
 *
 * To run a mutation, you first call `useUpdateBlogPostWithoutThumbnailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBlogPostWithoutThumbnailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBlogPostWithoutThumbnailMutation, { data, loading, error }] = useUpdateBlogPostWithoutThumbnailMutation({
 *   variables: {
 *      id: // value for 'id'
 *      authorId: // value for 'authorId'
 *      categoryId: // value for 'categoryId'
 *      publishedAt: // value for 'publishedAt'
 *      isHidden: // value for 'isHidden'
 *   },
 * });
 */
export function useUpdateBlogPostWithoutThumbnailMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBlogPostWithoutThumbnailMutation, UpdateBlogPostWithoutThumbnailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBlogPostWithoutThumbnailMutation, UpdateBlogPostWithoutThumbnailMutationVariables>(UpdateBlogPostWithoutThumbnailDocument, options);
      }
export type UpdateBlogPostWithoutThumbnailMutationHookResult = ReturnType<typeof useUpdateBlogPostWithoutThumbnailMutation>;
export type UpdateBlogPostWithoutThumbnailMutationResult = Apollo.MutationResult<UpdateBlogPostWithoutThumbnailMutation>;
export type UpdateBlogPostWithoutThumbnailMutationOptions = Apollo.BaseMutationOptions<UpdateBlogPostWithoutThumbnailMutation, UpdateBlogPostWithoutThumbnailMutationVariables>;
export const GetBrandDocument = gql`
    query getBrand($id: String!) {
  brand(where: {id: $id}) {
    id
    title
    slug
    photo
    countries {
      id
      title
      alpha2Code
      alpha3Code
      numericCode
    }
  }
}
    `;

/**
 * __useGetBrandQuery__
 *
 * To run a query within a React component, call `useGetBrandQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBrandQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBrandQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBrandQuery(baseOptions: Apollo.QueryHookOptions<GetBrandQuery, GetBrandQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBrandQuery, GetBrandQueryVariables>(GetBrandDocument, options);
      }
export function useGetBrandLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBrandQuery, GetBrandQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBrandQuery, GetBrandQueryVariables>(GetBrandDocument, options);
        }
export type GetBrandQueryHookResult = ReturnType<typeof useGetBrandQuery>;
export type GetBrandLazyQueryHookResult = ReturnType<typeof useGetBrandLazyQuery>;
export type GetBrandQueryResult = Apollo.QueryResult<GetBrandQuery, GetBrandQueryVariables>;
export const GetBrandsDocument = gql`
    query getBrands {
  brands(orderBy: {order: asc}) {
    id
    title
    slug
    photo
    order
    description {
      id
      updatedAt
      createdAt
      title
      description
      shortDescription
      slug
      buttonText
      buttonUrl
      language
      metaTitle
      metaDescription
      metaKeywords
      seoPoint
    }
  }
}
    `;

/**
 * __useGetBrandsQuery__
 *
 * To run a query within a React component, call `useGetBrandsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBrandsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBrandsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBrandsQuery(baseOptions?: Apollo.QueryHookOptions<GetBrandsQuery, GetBrandsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBrandsQuery, GetBrandsQueryVariables>(GetBrandsDocument, options);
      }
export function useGetBrandsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBrandsQuery, GetBrandsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBrandsQuery, GetBrandsQueryVariables>(GetBrandsDocument, options);
        }
export type GetBrandsQueryHookResult = ReturnType<typeof useGetBrandsQuery>;
export type GetBrandsLazyQueryHookResult = ReturnType<typeof useGetBrandsLazyQuery>;
export type GetBrandsQueryResult = Apollo.QueryResult<GetBrandsQuery, GetBrandsQueryVariables>;
export const CreateBrandDocument = gql`
    mutation createBrand($title: String!, $photo: String, $countries: [IDField!], $description: String, $shortDescription: String, $language: String!, $buttonText: String, $buttonUrl: String) {
  createBrand(
    data: {title: $title, photo: $photo, description: {create: {title: $title, description: $description, shortDescription: $shortDescription, language: $language, buttonText: $buttonText, buttonUrl: $buttonUrl, seoPoint: 0}}}
    countries: $countries
  ) {
    id
    title
    slug
  }
}
    `;
export type CreateBrandMutationFn = Apollo.MutationFunction<CreateBrandMutation, CreateBrandMutationVariables>;

/**
 * __useCreateBrandMutation__
 *
 * To run a mutation, you first call `useCreateBrandMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBrandMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBrandMutation, { data, loading, error }] = useCreateBrandMutation({
 *   variables: {
 *      title: // value for 'title'
 *      photo: // value for 'photo'
 *      countries: // value for 'countries'
 *      description: // value for 'description'
 *      shortDescription: // value for 'shortDescription'
 *      language: // value for 'language'
 *      buttonText: // value for 'buttonText'
 *      buttonUrl: // value for 'buttonUrl'
 *   },
 * });
 */
export function useCreateBrandMutation(baseOptions?: Apollo.MutationHookOptions<CreateBrandMutation, CreateBrandMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBrandMutation, CreateBrandMutationVariables>(CreateBrandDocument, options);
      }
export type CreateBrandMutationHookResult = ReturnType<typeof useCreateBrandMutation>;
export type CreateBrandMutationResult = Apollo.MutationResult<CreateBrandMutation>;
export type CreateBrandMutationOptions = Apollo.BaseMutationOptions<CreateBrandMutation, CreateBrandMutationVariables>;
export const CreateBrandWithoutPhotoDocument = gql`
    mutation createBrandWithoutPhoto($title: String!, $countries: [IDField!], $description: String, $shortDescription: String, $language: String!, $buttonText: String, $buttonUrl: String) {
  createBrand(
    data: {title: $title, description: {create: {title: $title, description: $description, shortDescription: $shortDescription, language: $language, buttonText: $buttonText, buttonUrl: $buttonUrl, seoPoint: 0}}}
    countries: $countries
  ) {
    id
    title
    slug
  }
}
    `;
export type CreateBrandWithoutPhotoMutationFn = Apollo.MutationFunction<CreateBrandWithoutPhotoMutation, CreateBrandWithoutPhotoMutationVariables>;

/**
 * __useCreateBrandWithoutPhotoMutation__
 *
 * To run a mutation, you first call `useCreateBrandWithoutPhotoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBrandWithoutPhotoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBrandWithoutPhotoMutation, { data, loading, error }] = useCreateBrandWithoutPhotoMutation({
 *   variables: {
 *      title: // value for 'title'
 *      countries: // value for 'countries'
 *      description: // value for 'description'
 *      shortDescription: // value for 'shortDescription'
 *      language: // value for 'language'
 *      buttonText: // value for 'buttonText'
 *      buttonUrl: // value for 'buttonUrl'
 *   },
 * });
 */
export function useCreateBrandWithoutPhotoMutation(baseOptions?: Apollo.MutationHookOptions<CreateBrandWithoutPhotoMutation, CreateBrandWithoutPhotoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBrandWithoutPhotoMutation, CreateBrandWithoutPhotoMutationVariables>(CreateBrandWithoutPhotoDocument, options);
      }
export type CreateBrandWithoutPhotoMutationHookResult = ReturnType<typeof useCreateBrandWithoutPhotoMutation>;
export type CreateBrandWithoutPhotoMutationResult = Apollo.MutationResult<CreateBrandWithoutPhotoMutation>;
export type CreateBrandWithoutPhotoMutationOptions = Apollo.BaseMutationOptions<CreateBrandWithoutPhotoMutation, CreateBrandWithoutPhotoMutationVariables>;
export const DeleteBrandDocument = gql`
    mutation deleteBrand($id: ID!) {
  deleteBrand(id: $id)
}
    `;
export type DeleteBrandMutationFn = Apollo.MutationFunction<DeleteBrandMutation, DeleteBrandMutationVariables>;

/**
 * __useDeleteBrandMutation__
 *
 * To run a mutation, you first call `useDeleteBrandMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBrandMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBrandMutation, { data, loading, error }] = useDeleteBrandMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteBrandMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBrandMutation, DeleteBrandMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteBrandMutation, DeleteBrandMutationVariables>(DeleteBrandDocument, options);
      }
export type DeleteBrandMutationHookResult = ReturnType<typeof useDeleteBrandMutation>;
export type DeleteBrandMutationResult = Apollo.MutationResult<DeleteBrandMutation>;
export type DeleteBrandMutationOptions = Apollo.BaseMutationOptions<DeleteBrandMutation, DeleteBrandMutationVariables>;
export const OrderBrandDocument = gql`
    mutation orderBrand($id: String!, $order: Int!) {
  updateBrand(where: {id: $id}, data: {order: {set: $order}}) {
    id
  }
}
    `;
export type OrderBrandMutationFn = Apollo.MutationFunction<OrderBrandMutation, OrderBrandMutationVariables>;

/**
 * __useOrderBrandMutation__
 *
 * To run a mutation, you first call `useOrderBrandMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderBrandMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderBrandMutation, { data, loading, error }] = useOrderBrandMutation({
 *   variables: {
 *      id: // value for 'id'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useOrderBrandMutation(baseOptions?: Apollo.MutationHookOptions<OrderBrandMutation, OrderBrandMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OrderBrandMutation, OrderBrandMutationVariables>(OrderBrandDocument, options);
      }
export type OrderBrandMutationHookResult = ReturnType<typeof useOrderBrandMutation>;
export type OrderBrandMutationResult = Apollo.MutationResult<OrderBrandMutation>;
export type OrderBrandMutationOptions = Apollo.BaseMutationOptions<OrderBrandMutation, OrderBrandMutationVariables>;
export const UpdateBrandDocument = gql`
    mutation updateBrand($id: String!, $title: String!, $photo: String, $countries: [IDField!]) {
  updateBrand(
    where: {id: $id}
    data: {title: {set: $title}, photo: {set: $photo}}
    countries: $countries
  ) {
    id
    title
    slug
    photo
  }
}
    `;
export type UpdateBrandMutationFn = Apollo.MutationFunction<UpdateBrandMutation, UpdateBrandMutationVariables>;

/**
 * __useUpdateBrandMutation__
 *
 * To run a mutation, you first call `useUpdateBrandMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBrandMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBrandMutation, { data, loading, error }] = useUpdateBrandMutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *      photo: // value for 'photo'
 *      countries: // value for 'countries'
 *   },
 * });
 */
export function useUpdateBrandMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBrandMutation, UpdateBrandMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBrandMutation, UpdateBrandMutationVariables>(UpdateBrandDocument, options);
      }
export type UpdateBrandMutationHookResult = ReturnType<typeof useUpdateBrandMutation>;
export type UpdateBrandMutationResult = Apollo.MutationResult<UpdateBrandMutation>;
export type UpdateBrandMutationOptions = Apollo.BaseMutationOptions<UpdateBrandMutation, UpdateBrandMutationVariables>;
export const UpdateBrandWithoutPhotoDocument = gql`
    mutation updateBrandWithoutPhoto($id: String!, $title: String!, $countries: [IDField!], $photo: String) {
  updateBrand(
    where: {id: $id}
    data: {title: {set: $title}, photo: {set: $photo}}
    countries: $countries
  ) {
    id
    title
    slug
  }
}
    `;
export type UpdateBrandWithoutPhotoMutationFn = Apollo.MutationFunction<UpdateBrandWithoutPhotoMutation, UpdateBrandWithoutPhotoMutationVariables>;

/**
 * __useUpdateBrandWithoutPhotoMutation__
 *
 * To run a mutation, you first call `useUpdateBrandWithoutPhotoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBrandWithoutPhotoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBrandWithoutPhotoMutation, { data, loading, error }] = useUpdateBrandWithoutPhotoMutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *      countries: // value for 'countries'
 *      photo: // value for 'photo'
 *   },
 * });
 */
export function useUpdateBrandWithoutPhotoMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBrandWithoutPhotoMutation, UpdateBrandWithoutPhotoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBrandWithoutPhotoMutation, UpdateBrandWithoutPhotoMutationVariables>(UpdateBrandWithoutPhotoDocument, options);
      }
export type UpdateBrandWithoutPhotoMutationHookResult = ReturnType<typeof useUpdateBrandWithoutPhotoMutation>;
export type UpdateBrandWithoutPhotoMutationResult = Apollo.MutationResult<UpdateBrandWithoutPhotoMutation>;
export type UpdateBrandWithoutPhotoMutationOptions = Apollo.BaseMutationOptions<UpdateBrandWithoutPhotoMutation, UpdateBrandWithoutPhotoMutationVariables>;
export const GetCategoriesDocument = gql`
    query getCategories($where: CategoryWhereInput) {
  categories(orderBy: {order: asc}, where: $where) {
    id
    parentId
    order
    parent {
      id
      descriptions {
        id
        title
      }
    }
    products {
      id
      createdAt
      updatedAt
      sku
      disabled
    }
    descriptions {
      id
      updatedAt
      createdAt
      title
      description
      shortDescription
      slug
      buttonText
      buttonUrl
      language
      metaTitle
      metaDescription
      metaKeywords
      seoPoint
    }
  }
}
    `;

/**
 * __useGetCategoriesQuery__
 *
 * To run a query within a React component, call `useGetCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCategoriesQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetCategoriesQuery, GetCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCategoriesQuery, GetCategoriesQueryVariables>(GetCategoriesDocument, options);
      }
export function useGetCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCategoriesQuery, GetCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCategoriesQuery, GetCategoriesQueryVariables>(GetCategoriesDocument, options);
        }
export type GetCategoriesQueryHookResult = ReturnType<typeof useGetCategoriesQuery>;
export type GetCategoriesLazyQueryHookResult = ReturnType<typeof useGetCategoriesLazyQuery>;
export type GetCategoriesQueryResult = Apollo.QueryResult<GetCategoriesQuery, GetCategoriesQueryVariables>;
export const GetCategoryDocument = gql`
    query getCategory($id: String!) {
  category(where: {id: $id}) {
    id
    parentId
    photos
    order
    parent {
      id
    }
    products {
      id
      createdAt
      updatedAt
      sku
      disabled
    }
    descriptions {
      id
      updatedAt
      createdAt
      title
      description
      shortDescription
      slug
      buttonText
      buttonUrl
      language
      metaTitle
      metaDescription
      metaKeywords
      seoPoint
    }
  }
}
    `;

/**
 * __useGetCategoryQuery__
 *
 * To run a query within a React component, call `useGetCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCategoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCategoryQuery(baseOptions: Apollo.QueryHookOptions<GetCategoryQuery, GetCategoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCategoryQuery, GetCategoryQueryVariables>(GetCategoryDocument, options);
      }
export function useGetCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCategoryQuery, GetCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCategoryQuery, GetCategoryQueryVariables>(GetCategoryDocument, options);
        }
export type GetCategoryQueryHookResult = ReturnType<typeof useGetCategoryQuery>;
export type GetCategoryLazyQueryHookResult = ReturnType<typeof useGetCategoryLazyQuery>;
export type GetCategoryQueryResult = Apollo.QueryResult<GetCategoryQuery, GetCategoryQueryVariables>;
export const CreateCategoryDocument = gql`
    mutation createCategory($parentCategoryId: String, $photos: [String!]!, $title: String!, $description: String, $shortDescription: String, $language: String!, $buttonText: String, $buttonUrl: String) {
  createCategory(
    data: {parent: {connect: {id: $parentCategoryId}}, photos: {set: $photos}}
    description: {title: $title, description: $description, shortDescription: $shortDescription, language: $language, buttonText: $buttonText, buttonUrl: $buttonUrl, seoPoint: 0}
  ) {
    id
    parentId
  }
}
    `;
export type CreateCategoryMutationFn = Apollo.MutationFunction<CreateCategoryMutation, CreateCategoryMutationVariables>;

/**
 * __useCreateCategoryMutation__
 *
 * To run a mutation, you first call `useCreateCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCategoryMutation, { data, loading, error }] = useCreateCategoryMutation({
 *   variables: {
 *      parentCategoryId: // value for 'parentCategoryId'
 *      photos: // value for 'photos'
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      shortDescription: // value for 'shortDescription'
 *      language: // value for 'language'
 *      buttonText: // value for 'buttonText'
 *      buttonUrl: // value for 'buttonUrl'
 *   },
 * });
 */
export function useCreateCategoryMutation(baseOptions?: Apollo.MutationHookOptions<CreateCategoryMutation, CreateCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCategoryMutation, CreateCategoryMutationVariables>(CreateCategoryDocument, options);
      }
export type CreateCategoryMutationHookResult = ReturnType<typeof useCreateCategoryMutation>;
export type CreateCategoryMutationResult = Apollo.MutationResult<CreateCategoryMutation>;
export type CreateCategoryMutationOptions = Apollo.BaseMutationOptions<CreateCategoryMutation, CreateCategoryMutationVariables>;
export const CreateCategoryWithoutParentDocument = gql`
    mutation createCategoryWithoutParent($photos: [String!]!, $title: String!, $description: String, $shortDescription: String, $language: String!, $buttonText: String, $buttonUrl: String) {
  createCategory(
    data: {photos: {set: $photos}}
    description: {title: $title, description: $description, shortDescription: $shortDescription, language: $language, buttonText: $buttonText, buttonUrl: $buttonUrl, seoPoint: 0}
  ) {
    id
    parentId
  }
}
    `;
export type CreateCategoryWithoutParentMutationFn = Apollo.MutationFunction<CreateCategoryWithoutParentMutation, CreateCategoryWithoutParentMutationVariables>;

/**
 * __useCreateCategoryWithoutParentMutation__
 *
 * To run a mutation, you first call `useCreateCategoryWithoutParentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCategoryWithoutParentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCategoryWithoutParentMutation, { data, loading, error }] = useCreateCategoryWithoutParentMutation({
 *   variables: {
 *      photos: // value for 'photos'
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      shortDescription: // value for 'shortDescription'
 *      language: // value for 'language'
 *      buttonText: // value for 'buttonText'
 *      buttonUrl: // value for 'buttonUrl'
 *   },
 * });
 */
export function useCreateCategoryWithoutParentMutation(baseOptions?: Apollo.MutationHookOptions<CreateCategoryWithoutParentMutation, CreateCategoryWithoutParentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCategoryWithoutParentMutation, CreateCategoryWithoutParentMutationVariables>(CreateCategoryWithoutParentDocument, options);
      }
export type CreateCategoryWithoutParentMutationHookResult = ReturnType<typeof useCreateCategoryWithoutParentMutation>;
export type CreateCategoryWithoutParentMutationResult = Apollo.MutationResult<CreateCategoryWithoutParentMutation>;
export type CreateCategoryWithoutParentMutationOptions = Apollo.BaseMutationOptions<CreateCategoryWithoutParentMutation, CreateCategoryWithoutParentMutationVariables>;
export const DeleteCategoryDocument = gql`
    mutation deleteCategory($id: ID!) {
  deleteCategory(id: $id)
}
    `;
export type DeleteCategoryMutationFn = Apollo.MutationFunction<DeleteCategoryMutation, DeleteCategoryMutationVariables>;

/**
 * __useDeleteCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCategoryMutation, { data, loading, error }] = useDeleteCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCategoryMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCategoryMutation, DeleteCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCategoryMutation, DeleteCategoryMutationVariables>(DeleteCategoryDocument, options);
      }
export type DeleteCategoryMutationHookResult = ReturnType<typeof useDeleteCategoryMutation>;
export type DeleteCategoryMutationResult = Apollo.MutationResult<DeleteCategoryMutation>;
export type DeleteCategoryMutationOptions = Apollo.BaseMutationOptions<DeleteCategoryMutation, DeleteCategoryMutationVariables>;
export const OrderCategoryDocument = gql`
    mutation orderCategory($id: String!, $order: Int!) {
  updateCategory(where: {id: $id}, data: {order: {set: $order}}) {
    id
  }
}
    `;
export type OrderCategoryMutationFn = Apollo.MutationFunction<OrderCategoryMutation, OrderCategoryMutationVariables>;

/**
 * __useOrderCategoryMutation__
 *
 * To run a mutation, you first call `useOrderCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderCategoryMutation, { data, loading, error }] = useOrderCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useOrderCategoryMutation(baseOptions?: Apollo.MutationHookOptions<OrderCategoryMutation, OrderCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OrderCategoryMutation, OrderCategoryMutationVariables>(OrderCategoryDocument, options);
      }
export type OrderCategoryMutationHookResult = ReturnType<typeof useOrderCategoryMutation>;
export type OrderCategoryMutationResult = Apollo.MutationResult<OrderCategoryMutation>;
export type OrderCategoryMutationOptions = Apollo.BaseMutationOptions<OrderCategoryMutation, OrderCategoryMutationVariables>;
export const UpdateCategoryDocument = gql`
    mutation updateCategory($id: String!, $parentCategoryId: String, $photos: [String!]!) {
  updateCategory(
    where: {id: $id}
    data: {parent: {connect: {id: $parentCategoryId}}, photos: {set: $photos}}
  ) {
    id
  }
}
    `;
export type UpdateCategoryMutationFn = Apollo.MutationFunction<UpdateCategoryMutation, UpdateCategoryMutationVariables>;

/**
 * __useUpdateCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCategoryMutation, { data, loading, error }] = useUpdateCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      parentCategoryId: // value for 'parentCategoryId'
 *      photos: // value for 'photos'
 *   },
 * });
 */
export function useUpdateCategoryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCategoryMutation, UpdateCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCategoryMutation, UpdateCategoryMutationVariables>(UpdateCategoryDocument, options);
      }
export type UpdateCategoryMutationHookResult = ReturnType<typeof useUpdateCategoryMutation>;
export type UpdateCategoryMutationResult = Apollo.MutationResult<UpdateCategoryMutation>;
export type UpdateCategoryMutationOptions = Apollo.BaseMutationOptions<UpdateCategoryMutation, UpdateCategoryMutationVariables>;
export const UpdateCategoryWithoutParentDocument = gql`
    mutation updateCategoryWithoutParent($id: String!, $photos: [String!]!) {
  updateCategory(
    where: {id: $id}
    data: {photos: {set: $photos}, parent: {disconnect: true}}
  ) {
    id
  }
}
    `;
export type UpdateCategoryWithoutParentMutationFn = Apollo.MutationFunction<UpdateCategoryWithoutParentMutation, UpdateCategoryWithoutParentMutationVariables>;

/**
 * __useUpdateCategoryWithoutParentMutation__
 *
 * To run a mutation, you first call `useUpdateCategoryWithoutParentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCategoryWithoutParentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCategoryWithoutParentMutation, { data, loading, error }] = useUpdateCategoryWithoutParentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      photos: // value for 'photos'
 *   },
 * });
 */
export function useUpdateCategoryWithoutParentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCategoryWithoutParentMutation, UpdateCategoryWithoutParentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCategoryWithoutParentMutation, UpdateCategoryWithoutParentMutationVariables>(UpdateCategoryWithoutParentDocument, options);
      }
export type UpdateCategoryWithoutParentMutationHookResult = ReturnType<typeof useUpdateCategoryWithoutParentMutation>;
export type UpdateCategoryWithoutParentMutationResult = Apollo.MutationResult<UpdateCategoryWithoutParentMutation>;
export type UpdateCategoryWithoutParentMutationOptions = Apollo.BaseMutationOptions<UpdateCategoryWithoutParentMutation, UpdateCategoryWithoutParentMutationVariables>;
export const GetReaderCommentDocument = gql`
    query getReaderComment($where: ReaderCommentWhereUniqueInput!) {
  readerComment(where: $where) {
    id
    createdAt
    updatedAt
    name
    comment
    order
    product {
      id
      sku
      descriptions {
        id
        title
        slug
      }
    }
  }
}
    `;

/**
 * __useGetReaderCommentQuery__
 *
 * To run a query within a React component, call `useGetReaderCommentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReaderCommentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReaderCommentQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetReaderCommentQuery(baseOptions: Apollo.QueryHookOptions<GetReaderCommentQuery, GetReaderCommentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReaderCommentQuery, GetReaderCommentQueryVariables>(GetReaderCommentDocument, options);
      }
export function useGetReaderCommentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReaderCommentQuery, GetReaderCommentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReaderCommentQuery, GetReaderCommentQueryVariables>(GetReaderCommentDocument, options);
        }
export type GetReaderCommentQueryHookResult = ReturnType<typeof useGetReaderCommentQuery>;
export type GetReaderCommentLazyQueryHookResult = ReturnType<typeof useGetReaderCommentLazyQuery>;
export type GetReaderCommentQueryResult = Apollo.QueryResult<GetReaderCommentQuery, GetReaderCommentQueryVariables>;
export const GetReaderCommentsDocument = gql`
    query getReaderComments($where: ReaderCommentWhereInput) {
  readerComments(where: $where, orderBy: {order: asc}) {
    id
    createdAt
    updatedAt
    name
    comment
    order
    product {
      id
      sku
      descriptions {
        id
        title
        slug
      }
    }
  }
}
    `;

/**
 * __useGetReaderCommentsQuery__
 *
 * To run a query within a React component, call `useGetReaderCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReaderCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReaderCommentsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetReaderCommentsQuery(baseOptions?: Apollo.QueryHookOptions<GetReaderCommentsQuery, GetReaderCommentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReaderCommentsQuery, GetReaderCommentsQueryVariables>(GetReaderCommentsDocument, options);
      }
export function useGetReaderCommentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReaderCommentsQuery, GetReaderCommentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReaderCommentsQuery, GetReaderCommentsQueryVariables>(GetReaderCommentsDocument, options);
        }
export type GetReaderCommentsQueryHookResult = ReturnType<typeof useGetReaderCommentsQuery>;
export type GetReaderCommentsLazyQueryHookResult = ReturnType<typeof useGetReaderCommentsLazyQuery>;
export type GetReaderCommentsQueryResult = Apollo.QueryResult<GetReaderCommentsQuery, GetReaderCommentsQueryVariables>;
export const CreateReaderCommentDocument = gql`
    mutation createReaderComment($name: String, $comment: String!, $productId: String) {
  createReaderComment(
    data: {name: $name, comment: $comment, product: {connect: {id: $productId}}}
  ) {
    id
  }
}
    `;
export type CreateReaderCommentMutationFn = Apollo.MutationFunction<CreateReaderCommentMutation, CreateReaderCommentMutationVariables>;

/**
 * __useCreateReaderCommentMutation__
 *
 * To run a mutation, you first call `useCreateReaderCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReaderCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReaderCommentMutation, { data, loading, error }] = useCreateReaderCommentMutation({
 *   variables: {
 *      name: // value for 'name'
 *      comment: // value for 'comment'
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useCreateReaderCommentMutation(baseOptions?: Apollo.MutationHookOptions<CreateReaderCommentMutation, CreateReaderCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateReaderCommentMutation, CreateReaderCommentMutationVariables>(CreateReaderCommentDocument, options);
      }
export type CreateReaderCommentMutationHookResult = ReturnType<typeof useCreateReaderCommentMutation>;
export type CreateReaderCommentMutationResult = Apollo.MutationResult<CreateReaderCommentMutation>;
export type CreateReaderCommentMutationOptions = Apollo.BaseMutationOptions<CreateReaderCommentMutation, CreateReaderCommentMutationVariables>;
export const DeleteReaderCommentDocument = gql`
    mutation deleteReaderComment($id: String!) {
  deleteReaderComment(where: {id: $id}) {
    id
  }
}
    `;
export type DeleteReaderCommentMutationFn = Apollo.MutationFunction<DeleteReaderCommentMutation, DeleteReaderCommentMutationVariables>;

/**
 * __useDeleteReaderCommentMutation__
 *
 * To run a mutation, you first call `useDeleteReaderCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteReaderCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteReaderCommentMutation, { data, loading, error }] = useDeleteReaderCommentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteReaderCommentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteReaderCommentMutation, DeleteReaderCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteReaderCommentMutation, DeleteReaderCommentMutationVariables>(DeleteReaderCommentDocument, options);
      }
export type DeleteReaderCommentMutationHookResult = ReturnType<typeof useDeleteReaderCommentMutation>;
export type DeleteReaderCommentMutationResult = Apollo.MutationResult<DeleteReaderCommentMutation>;
export type DeleteReaderCommentMutationOptions = Apollo.BaseMutationOptions<DeleteReaderCommentMutation, DeleteReaderCommentMutationVariables>;
export const OrderReaderCommentDocument = gql`
    mutation orderReaderComment($id: String!, $order: Int!) {
  updateReaderComment(where: {id: $id}, data: {order: {set: $order}}) {
    id
  }
}
    `;
export type OrderReaderCommentMutationFn = Apollo.MutationFunction<OrderReaderCommentMutation, OrderReaderCommentMutationVariables>;

/**
 * __useOrderReaderCommentMutation__
 *
 * To run a mutation, you first call `useOrderReaderCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderReaderCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderReaderCommentMutation, { data, loading, error }] = useOrderReaderCommentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useOrderReaderCommentMutation(baseOptions?: Apollo.MutationHookOptions<OrderReaderCommentMutation, OrderReaderCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OrderReaderCommentMutation, OrderReaderCommentMutationVariables>(OrderReaderCommentDocument, options);
      }
export type OrderReaderCommentMutationHookResult = ReturnType<typeof useOrderReaderCommentMutation>;
export type OrderReaderCommentMutationResult = Apollo.MutationResult<OrderReaderCommentMutation>;
export type OrderReaderCommentMutationOptions = Apollo.BaseMutationOptions<OrderReaderCommentMutation, OrderReaderCommentMutationVariables>;
export const UpdateReaderCommentDocument = gql`
    mutation updateReaderComment($id: String, $name: String, $comment: String!) {
  updateReaderComment(
    where: {id: $id}
    data: {name: {set: $name}, comment: {set: $comment}}
  ) {
    id
  }
}
    `;
export type UpdateReaderCommentMutationFn = Apollo.MutationFunction<UpdateReaderCommentMutation, UpdateReaderCommentMutationVariables>;

/**
 * __useUpdateReaderCommentMutation__
 *
 * To run a mutation, you first call `useUpdateReaderCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReaderCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReaderCommentMutation, { data, loading, error }] = useUpdateReaderCommentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useUpdateReaderCommentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateReaderCommentMutation, UpdateReaderCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateReaderCommentMutation, UpdateReaderCommentMutationVariables>(UpdateReaderCommentDocument, options);
      }
export type UpdateReaderCommentMutationHookResult = ReturnType<typeof useUpdateReaderCommentMutation>;
export type UpdateReaderCommentMutationResult = Apollo.MutationResult<UpdateReaderCommentMutation>;
export type UpdateReaderCommentMutationOptions = Apollo.BaseMutationOptions<UpdateReaderCommentMutation, UpdateReaderCommentMutationVariables>;
export const GetContentDocument = gql`
    query getContent($id: String!) {
  content(where: {id: $id}) {
    id
    updatedAt
    createdAt
    theme
    backgroundImage
    productCategoryId
    blogCategoryId
    lineUp
    productCategory {
      id
      parentId
    }
    highlightedProducts {
      id
      sku
      descriptions {
        id
        title
        slug
      }
    }
    description {
      id
      updatedAt
      createdAt
      title
      description
      shortDescription
      slug
      buttonText
      buttonUrl
      language
      metaTitle
      metaDescription
      metaKeywords
      seoPoint
    }
    page {
      id
      updatedAt
      createdAt
    }
    product {
      id
      updatedAt
      createdAt
    }
    blogPost {
      id
      updatedAt
      createdAt
    }
    photos
    cards
    slides
    timeline
    videos
    embedCode
    longitude
    latitude
    contentType
    mediaType
    source
    order
  }
}
    `;

/**
 * __useGetContentQuery__
 *
 * To run a query within a React component, call `useGetContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetContentQuery(baseOptions: Apollo.QueryHookOptions<GetContentQuery, GetContentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContentQuery, GetContentQueryVariables>(GetContentDocument, options);
      }
export function useGetContentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContentQuery, GetContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContentQuery, GetContentQueryVariables>(GetContentDocument, options);
        }
export type GetContentQueryHookResult = ReturnType<typeof useGetContentQuery>;
export type GetContentLazyQueryHookResult = ReturnType<typeof useGetContentLazyQuery>;
export type GetContentQueryResult = Apollo.QueryResult<GetContentQuery, GetContentQueryVariables>;
export const GetContentsDocument = gql`
    query getContents($pageId: String) {
  contents(where: {pageId: {equals: $pageId}}, orderBy: {order: asc}) {
    id
    updatedAt
    createdAt
    description {
      id
      updatedAt
      createdAt
      title
      description
      shortDescription
      slug
      buttonText
      buttonUrl
      language
      metaTitle
      metaDescription
      metaKeywords
      seoPoint
    }
    photos
    page {
      id
      updatedAt
      createdAt
    }
    videos
    embedCode
    longitude
    latitude
    contentType
    mediaType
    source
    order
  }
}
    `;

/**
 * __useGetContentsQuery__
 *
 * To run a query within a React component, call `useGetContentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContentsQuery({
 *   variables: {
 *      pageId: // value for 'pageId'
 *   },
 * });
 */
export function useGetContentsQuery(baseOptions?: Apollo.QueryHookOptions<GetContentsQuery, GetContentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContentsQuery, GetContentsQueryVariables>(GetContentsDocument, options);
      }
export function useGetContentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContentsQuery, GetContentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContentsQuery, GetContentsQueryVariables>(GetContentsDocument, options);
        }
export type GetContentsQueryHookResult = ReturnType<typeof useGetContentsQuery>;
export type GetContentsLazyQueryHookResult = ReturnType<typeof useGetContentsLazyQuery>;
export type GetContentsQueryResult = Apollo.QueryResult<GetContentsQuery, GetContentsQueryVariables>;
export const CreateContentDocument = gql`
    mutation createContent($pageId: String!, $photos: [String!]!, $videos: String, $contentType: ContentType!, $mediaType: MediaType!, $embedCode: String, $longitude: Float, $latitude: Float, $source: String, $cards: JSON!, $slides: JSON, $timeline: JSON, $backgroundImage: String, $theme: ThemeType, $productCategoryId: String, $blogCategoryId: String, $highlightedProducts: [IDField!], $lineUp: ContentLineup!, $title: String!, $description: String, $shortDescription: String, $language: String!, $buttonText: String, $buttonUrl: String) {
  createContent(
    data: {page: {connect: {id: $pageId}}, photos: {set: $photos}, videos: $videos, contentType: $contentType, mediaType: $mediaType, embedCode: $embedCode, longitude: $longitude, latitude: $latitude, source: $source, cards: $cards, slides: $slides, timeline: $timeline, backgroundImage: $backgroundImage, theme: $theme, productCategoryId: $productCategoryId, blogCategoryId: $blogCategoryId, products: $highlightedProducts, lineUp: $lineUp, description: {create: {title: $title, description: $description, shortDescription: $shortDescription, language: $language, buttonText: $buttonText, buttonUrl: $buttonUrl, seoPoint: 0}}}
  ) {
    id
    updatedAt
    createdAt
  }
}
    `;
export type CreateContentMutationFn = Apollo.MutationFunction<CreateContentMutation, CreateContentMutationVariables>;

/**
 * __useCreateContentMutation__
 *
 * To run a mutation, you first call `useCreateContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContentMutation, { data, loading, error }] = useCreateContentMutation({
 *   variables: {
 *      pageId: // value for 'pageId'
 *      photos: // value for 'photos'
 *      videos: // value for 'videos'
 *      contentType: // value for 'contentType'
 *      mediaType: // value for 'mediaType'
 *      embedCode: // value for 'embedCode'
 *      longitude: // value for 'longitude'
 *      latitude: // value for 'latitude'
 *      source: // value for 'source'
 *      cards: // value for 'cards'
 *      slides: // value for 'slides'
 *      timeline: // value for 'timeline'
 *      backgroundImage: // value for 'backgroundImage'
 *      theme: // value for 'theme'
 *      productCategoryId: // value for 'productCategoryId'
 *      blogCategoryId: // value for 'blogCategoryId'
 *      highlightedProducts: // value for 'highlightedProducts'
 *      lineUp: // value for 'lineUp'
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      shortDescription: // value for 'shortDescription'
 *      language: // value for 'language'
 *      buttonText: // value for 'buttonText'
 *      buttonUrl: // value for 'buttonUrl'
 *   },
 * });
 */
export function useCreateContentMutation(baseOptions?: Apollo.MutationHookOptions<CreateContentMutation, CreateContentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateContentMutation, CreateContentMutationVariables>(CreateContentDocument, options);
      }
export type CreateContentMutationHookResult = ReturnType<typeof useCreateContentMutation>;
export type CreateContentMutationResult = Apollo.MutationResult<CreateContentMutation>;
export type CreateContentMutationOptions = Apollo.BaseMutationOptions<CreateContentMutation, CreateContentMutationVariables>;
export const CreateContentWithoutBgImageDocument = gql`
    mutation createContentWithoutBgImage($pageId: String!, $photos: [String!]!, $videos: String, $contentType: ContentType!, $mediaType: MediaType!, $embedCode: String, $longitude: Float, $latitude: Float, $source: String, $cards: JSON!, $slides: JSON, $timeline: JSON, $theme: ThemeType, $productCategoryId: String, $blogCategoryId: String, $highlightedProducts: [IDField!], $lineUp: ContentLineup!, $title: String!, $description: String, $shortDescription: String, $language: String!, $buttonText: String, $buttonUrl: String) {
  createContent(
    data: {page: {connect: {id: $pageId}}, photos: {set: $photos}, videos: $videos, contentType: $contentType, mediaType: $mediaType, embedCode: $embedCode, longitude: $longitude, latitude: $latitude, source: $source, cards: $cards, slides: $slides, timeline: $timeline, theme: $theme, productCategoryId: $productCategoryId, blogCategoryId: $blogCategoryId, products: $highlightedProducts, lineUp: $lineUp, description: {create: {title: $title, description: $description, shortDescription: $shortDescription, language: $language, buttonText: $buttonText, buttonUrl: $buttonUrl, seoPoint: 0}}}
  ) {
    id
    updatedAt
    createdAt
  }
}
    `;
export type CreateContentWithoutBgImageMutationFn = Apollo.MutationFunction<CreateContentWithoutBgImageMutation, CreateContentWithoutBgImageMutationVariables>;

/**
 * __useCreateContentWithoutBgImageMutation__
 *
 * To run a mutation, you first call `useCreateContentWithoutBgImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContentWithoutBgImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContentWithoutBgImageMutation, { data, loading, error }] = useCreateContentWithoutBgImageMutation({
 *   variables: {
 *      pageId: // value for 'pageId'
 *      photos: // value for 'photos'
 *      videos: // value for 'videos'
 *      contentType: // value for 'contentType'
 *      mediaType: // value for 'mediaType'
 *      embedCode: // value for 'embedCode'
 *      longitude: // value for 'longitude'
 *      latitude: // value for 'latitude'
 *      source: // value for 'source'
 *      cards: // value for 'cards'
 *      slides: // value for 'slides'
 *      timeline: // value for 'timeline'
 *      theme: // value for 'theme'
 *      productCategoryId: // value for 'productCategoryId'
 *      blogCategoryId: // value for 'blogCategoryId'
 *      highlightedProducts: // value for 'highlightedProducts'
 *      lineUp: // value for 'lineUp'
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      shortDescription: // value for 'shortDescription'
 *      language: // value for 'language'
 *      buttonText: // value for 'buttonText'
 *      buttonUrl: // value for 'buttonUrl'
 *   },
 * });
 */
export function useCreateContentWithoutBgImageMutation(baseOptions?: Apollo.MutationHookOptions<CreateContentWithoutBgImageMutation, CreateContentWithoutBgImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateContentWithoutBgImageMutation, CreateContentWithoutBgImageMutationVariables>(CreateContentWithoutBgImageDocument, options);
      }
export type CreateContentWithoutBgImageMutationHookResult = ReturnType<typeof useCreateContentWithoutBgImageMutation>;
export type CreateContentWithoutBgImageMutationResult = Apollo.MutationResult<CreateContentWithoutBgImageMutation>;
export type CreateContentWithoutBgImageMutationOptions = Apollo.BaseMutationOptions<CreateContentWithoutBgImageMutation, CreateContentWithoutBgImageMutationVariables>;
export const DeleteContentDocument = gql`
    mutation deleteContent($id: String!) {
  deleteContent(id: $id)
}
    `;
export type DeleteContentMutationFn = Apollo.MutationFunction<DeleteContentMutation, DeleteContentMutationVariables>;

/**
 * __useDeleteContentMutation__
 *
 * To run a mutation, you first call `useDeleteContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContentMutation, { data, loading, error }] = useDeleteContentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteContentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteContentMutation, DeleteContentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteContentMutation, DeleteContentMutationVariables>(DeleteContentDocument, options);
      }
export type DeleteContentMutationHookResult = ReturnType<typeof useDeleteContentMutation>;
export type DeleteContentMutationResult = Apollo.MutationResult<DeleteContentMutation>;
export type DeleteContentMutationOptions = Apollo.BaseMutationOptions<DeleteContentMutation, DeleteContentMutationVariables>;
export const OrderContentDocument = gql`
    mutation orderContent($id: String!, $order: Int!) {
  updateContent(where: {id: $id}, data: {order: {set: $order}}) {
    id
    updatedAt
  }
}
    `;
export type OrderContentMutationFn = Apollo.MutationFunction<OrderContentMutation, OrderContentMutationVariables>;

/**
 * __useOrderContentMutation__
 *
 * To run a mutation, you first call `useOrderContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderContentMutation, { data, loading, error }] = useOrderContentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useOrderContentMutation(baseOptions?: Apollo.MutationHookOptions<OrderContentMutation, OrderContentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OrderContentMutation, OrderContentMutationVariables>(OrderContentDocument, options);
      }
export type OrderContentMutationHookResult = ReturnType<typeof useOrderContentMutation>;
export type OrderContentMutationResult = Apollo.MutationResult<OrderContentMutation>;
export type OrderContentMutationOptions = Apollo.BaseMutationOptions<OrderContentMutation, OrderContentMutationVariables>;
export const UpdatecontentDocument = gql`
    mutation updatecontent($id: String!, $photos: [String!]!, $videos: String, $contentType: ContentType, $mediaType: MediaType, $backgroundImage: String, $theme: ThemeType, $productCategoryId: String, $blogCategoryId: String, $highlightedProducts: [IDField!], $lineUp: ContentLineup!, $longitude: Float, $latitude: Float, $cards: JSON, $slides: JSON, $timeline: JSON) {
  updateContent(
    where: {id: $id}
    data: {photos: {set: $photos}, videos: {set: $videos}, contentType: {set: $contentType}, mediaType: {set: $mediaType}, backgroundImage: {set: $backgroundImage}, theme: {set: $theme}, productCategoryId: $productCategoryId, blogCategoryId: $blogCategoryId, products: $highlightedProducts, lineUp: {set: $lineUp}, cards: $cards, slides: $slides, timeline: $timeline, longitude: {set: $longitude}, latitude: {set: $latitude}}
  ) {
    id
    updatedAt
    createdAt
  }
}
    `;
export type UpdatecontentMutationFn = Apollo.MutationFunction<UpdatecontentMutation, UpdatecontentMutationVariables>;

/**
 * __useUpdatecontentMutation__
 *
 * To run a mutation, you first call `useUpdatecontentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatecontentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatecontentMutation, { data, loading, error }] = useUpdatecontentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      photos: // value for 'photos'
 *      videos: // value for 'videos'
 *      contentType: // value for 'contentType'
 *      mediaType: // value for 'mediaType'
 *      backgroundImage: // value for 'backgroundImage'
 *      theme: // value for 'theme'
 *      productCategoryId: // value for 'productCategoryId'
 *      blogCategoryId: // value for 'blogCategoryId'
 *      highlightedProducts: // value for 'highlightedProducts'
 *      lineUp: // value for 'lineUp'
 *      longitude: // value for 'longitude'
 *      latitude: // value for 'latitude'
 *      cards: // value for 'cards'
 *      slides: // value for 'slides'
 *      timeline: // value for 'timeline'
 *   },
 * });
 */
export function useUpdatecontentMutation(baseOptions?: Apollo.MutationHookOptions<UpdatecontentMutation, UpdatecontentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatecontentMutation, UpdatecontentMutationVariables>(UpdatecontentDocument, options);
      }
export type UpdatecontentMutationHookResult = ReturnType<typeof useUpdatecontentMutation>;
export type UpdatecontentMutationResult = Apollo.MutationResult<UpdatecontentMutation>;
export type UpdatecontentMutationOptions = Apollo.BaseMutationOptions<UpdatecontentMutation, UpdatecontentMutationVariables>;
export const UpdatecontentWithoutBgDocument = gql`
    mutation updatecontentWithoutBg($id: String!, $photos: [String!]!, $videos: String, $contentType: ContentType, $mediaType: MediaType, $theme: ThemeType, $productCategoryId: String, $blogCategoryId: String, $highlightedProducts: [IDField!], $lineUp: ContentLineup!, $longitude: Float, $latitude: Float, $cards: JSON, $slides: JSON, $timeline: JSON) {
  updateContent(
    where: {id: $id}
    data: {photos: {set: $photos}, videos: {set: $videos}, contentType: {set: $contentType}, mediaType: {set: $mediaType}, backgroundImage: {set: ""}, theme: {set: $theme}, productCategoryId: $productCategoryId, blogCategoryId: $blogCategoryId, products: $highlightedProducts, lineUp: {set: $lineUp}, cards: $cards, slides: $slides, timeline: $timeline, longitude: {set: $longitude}, latitude: {set: $latitude}}
  ) {
    id
    updatedAt
    createdAt
  }
}
    `;
export type UpdatecontentWithoutBgMutationFn = Apollo.MutationFunction<UpdatecontentWithoutBgMutation, UpdatecontentWithoutBgMutationVariables>;

/**
 * __useUpdatecontentWithoutBgMutation__
 *
 * To run a mutation, you first call `useUpdatecontentWithoutBgMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatecontentWithoutBgMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatecontentWithoutBgMutation, { data, loading, error }] = useUpdatecontentWithoutBgMutation({
 *   variables: {
 *      id: // value for 'id'
 *      photos: // value for 'photos'
 *      videos: // value for 'videos'
 *      contentType: // value for 'contentType'
 *      mediaType: // value for 'mediaType'
 *      theme: // value for 'theme'
 *      productCategoryId: // value for 'productCategoryId'
 *      blogCategoryId: // value for 'blogCategoryId'
 *      highlightedProducts: // value for 'highlightedProducts'
 *      lineUp: // value for 'lineUp'
 *      longitude: // value for 'longitude'
 *      latitude: // value for 'latitude'
 *      cards: // value for 'cards'
 *      slides: // value for 'slides'
 *      timeline: // value for 'timeline'
 *   },
 * });
 */
export function useUpdatecontentWithoutBgMutation(baseOptions?: Apollo.MutationHookOptions<UpdatecontentWithoutBgMutation, UpdatecontentWithoutBgMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatecontentWithoutBgMutation, UpdatecontentWithoutBgMutationVariables>(UpdatecontentWithoutBgDocument, options);
      }
export type UpdatecontentWithoutBgMutationHookResult = ReturnType<typeof useUpdatecontentWithoutBgMutation>;
export type UpdatecontentWithoutBgMutationResult = Apollo.MutationResult<UpdatecontentWithoutBgMutation>;
export type UpdatecontentWithoutBgMutationOptions = Apollo.BaseMutationOptions<UpdatecontentWithoutBgMutation, UpdatecontentWithoutBgMutationVariables>;
export const GetCountriesDocument = gql`
    query getCountries {
  countries {
    id
    title
    alpha2Code
    alpha3Code
    numericCode
  }
}
    `;

/**
 * __useGetCountriesQuery__
 *
 * To run a query within a React component, call `useGetCountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCountriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCountriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCountriesQuery(baseOptions?: Apollo.QueryHookOptions<GetCountriesQuery, GetCountriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCountriesQuery, GetCountriesQueryVariables>(GetCountriesDocument, options);
      }
export function useGetCountriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCountriesQuery, GetCountriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCountriesQuery, GetCountriesQueryVariables>(GetCountriesDocument, options);
        }
export type GetCountriesQueryHookResult = ReturnType<typeof useGetCountriesQuery>;
export type GetCountriesLazyQueryHookResult = ReturnType<typeof useGetCountriesLazyQuery>;
export type GetCountriesQueryResult = Apollo.QueryResult<GetCountriesQuery, GetCountriesQueryVariables>;
export const GetFindFirstCustomDataDocument = gql`
    query getFindFirstCustomData {
  findFirstCustomData(where: {id: {equals: 1}}) {
    id
    createdAt
    updatedAt
    liveVideoUrl
  }
}
    `;

/**
 * __useGetFindFirstCustomDataQuery__
 *
 * To run a query within a React component, call `useGetFindFirstCustomDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFindFirstCustomDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFindFirstCustomDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFindFirstCustomDataQuery(baseOptions?: Apollo.QueryHookOptions<GetFindFirstCustomDataQuery, GetFindFirstCustomDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFindFirstCustomDataQuery, GetFindFirstCustomDataQueryVariables>(GetFindFirstCustomDataDocument, options);
      }
export function useGetFindFirstCustomDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFindFirstCustomDataQuery, GetFindFirstCustomDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFindFirstCustomDataQuery, GetFindFirstCustomDataQueryVariables>(GetFindFirstCustomDataDocument, options);
        }
export type GetFindFirstCustomDataQueryHookResult = ReturnType<typeof useGetFindFirstCustomDataQuery>;
export type GetFindFirstCustomDataLazyQueryHookResult = ReturnType<typeof useGetFindFirstCustomDataLazyQuery>;
export type GetFindFirstCustomDataQueryResult = Apollo.QueryResult<GetFindFirstCustomDataQuery, GetFindFirstCustomDataQueryVariables>;
export const SetLiveVideoUrlDocument = gql`
    mutation setLiveVideoUrl($liveVideoUrl: String) {
  updateCustomData(where: {id: 1}, data: {liveVideoUrl: {set: $liveVideoUrl}}) {
    id
  }
}
    `;
export type SetLiveVideoUrlMutationFn = Apollo.MutationFunction<SetLiveVideoUrlMutation, SetLiveVideoUrlMutationVariables>;

/**
 * __useSetLiveVideoUrlMutation__
 *
 * To run a mutation, you first call `useSetLiveVideoUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetLiveVideoUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setLiveVideoUrlMutation, { data, loading, error }] = useSetLiveVideoUrlMutation({
 *   variables: {
 *      liveVideoUrl: // value for 'liveVideoUrl'
 *   },
 * });
 */
export function useSetLiveVideoUrlMutation(baseOptions?: Apollo.MutationHookOptions<SetLiveVideoUrlMutation, SetLiveVideoUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetLiveVideoUrlMutation, SetLiveVideoUrlMutationVariables>(SetLiveVideoUrlDocument, options);
      }
export type SetLiveVideoUrlMutationHookResult = ReturnType<typeof useSetLiveVideoUrlMutation>;
export type SetLiveVideoUrlMutationResult = Apollo.MutationResult<SetLiveVideoUrlMutation>;
export type SetLiveVideoUrlMutationOptions = Apollo.BaseMutationOptions<SetLiveVideoUrlMutation, SetLiveVideoUrlMutationVariables>;
export const GetVideosDocument = gql`
    query getVideos($where: YtVideoWhereInput, $orderBy: [YtVideoOrderByWithRelationInput!]) {
  ytVideos(where: $where, orderBy: $orderBy) {
    id
    lastSynced
    isActive
    showOnHomepage
    order
    data
  }
}
    `;

/**
 * __useGetVideosQuery__
 *
 * To run a query within a React component, call `useGetVideosQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVideosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVideosQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetVideosQuery(baseOptions?: Apollo.QueryHookOptions<GetVideosQuery, GetVideosQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVideosQuery, GetVideosQueryVariables>(GetVideosDocument, options);
      }
export function useGetVideosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVideosQuery, GetVideosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVideosQuery, GetVideosQueryVariables>(GetVideosDocument, options);
        }
export type GetVideosQueryHookResult = ReturnType<typeof useGetVideosQuery>;
export type GetVideosLazyQueryHookResult = ReturnType<typeof useGetVideosLazyQuery>;
export type GetVideosQueryResult = Apollo.QueryResult<GetVideosQuery, GetVideosQueryVariables>;
export const UpdateVideoDocument = gql`
    mutation updateVideo($id: String!, $isActive: Boolean!) {
  updateYtVideo(where: {id: $id}, data: {isActive: {set: $isActive}}) {
    id
  }
}
    `;
export type UpdateVideoMutationFn = Apollo.MutationFunction<UpdateVideoMutation, UpdateVideoMutationVariables>;

/**
 * __useUpdateVideoMutation__
 *
 * To run a mutation, you first call `useUpdateVideoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVideoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVideoMutation, { data, loading, error }] = useUpdateVideoMutation({
 *   variables: {
 *      id: // value for 'id'
 *      isActive: // value for 'isActive'
 *   },
 * });
 */
export function useUpdateVideoMutation(baseOptions?: Apollo.MutationHookOptions<UpdateVideoMutation, UpdateVideoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateVideoMutation, UpdateVideoMutationVariables>(UpdateVideoDocument, options);
      }
export type UpdateVideoMutationHookResult = ReturnType<typeof useUpdateVideoMutation>;
export type UpdateVideoMutationResult = Apollo.MutationResult<UpdateVideoMutation>;
export type UpdateVideoMutationOptions = Apollo.BaseMutationOptions<UpdateVideoMutation, UpdateVideoMutationVariables>;
export const SetHomepageVideoDocument = gql`
    mutation setHomepageVideo($id: ID!) {
  setHomepageVideo(id: $id)
}
    `;
export type SetHomepageVideoMutationFn = Apollo.MutationFunction<SetHomepageVideoMutation, SetHomepageVideoMutationVariables>;

/**
 * __useSetHomepageVideoMutation__
 *
 * To run a mutation, you first call `useSetHomepageVideoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetHomepageVideoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setHomepageVideoMutation, { data, loading, error }] = useSetHomepageVideoMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSetHomepageVideoMutation(baseOptions?: Apollo.MutationHookOptions<SetHomepageVideoMutation, SetHomepageVideoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetHomepageVideoMutation, SetHomepageVideoMutationVariables>(SetHomepageVideoDocument, options);
      }
export type SetHomepageVideoMutationHookResult = ReturnType<typeof useSetHomepageVideoMutation>;
export type SetHomepageVideoMutationResult = Apollo.MutationResult<SetHomepageVideoMutation>;
export type SetHomepageVideoMutationOptions = Apollo.BaseMutationOptions<SetHomepageVideoMutation, SetHomepageVideoMutationVariables>;
export const CreateDescDocument = gql`
    mutation createDesc($title: String!, $description: String, $language: String!, $shortDescription: String, $buttonUrl: String, $buttonText: String, $field: String!, $fieldId: String!) {
  createDescription(
    data: {title: $title, description: $description, language: $language, shortDescription: $shortDescription, buttonUrl: $buttonUrl, buttonText: $buttonText}
    field: $field
    fieldId: $fieldId
  ) {
    id
    updatedAt
    createdAt
    title
    description
    slug
  }
}
    `;
export type CreateDescMutationFn = Apollo.MutationFunction<CreateDescMutation, CreateDescMutationVariables>;

/**
 * __useCreateDescMutation__
 *
 * To run a mutation, you first call `useCreateDescMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDescMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDescMutation, { data, loading, error }] = useCreateDescMutation({
 *   variables: {
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      language: // value for 'language'
 *      shortDescription: // value for 'shortDescription'
 *      buttonUrl: // value for 'buttonUrl'
 *      buttonText: // value for 'buttonText'
 *      field: // value for 'field'
 *      fieldId: // value for 'fieldId'
 *   },
 * });
 */
export function useCreateDescMutation(baseOptions?: Apollo.MutationHookOptions<CreateDescMutation, CreateDescMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDescMutation, CreateDescMutationVariables>(CreateDescDocument, options);
      }
export type CreateDescMutationHookResult = ReturnType<typeof useCreateDescMutation>;
export type CreateDescMutationResult = Apollo.MutationResult<CreateDescMutation>;
export type CreateDescMutationOptions = Apollo.BaseMutationOptions<CreateDescMutation, CreateDescMutationVariables>;
export const DeleteDescDocument = gql`
    mutation deleteDesc($id: String!) {
  deleteDescription(where: {id: $id}) {
    id
  }
}
    `;
export type DeleteDescMutationFn = Apollo.MutationFunction<DeleteDescMutation, DeleteDescMutationVariables>;

/**
 * __useDeleteDescMutation__
 *
 * To run a mutation, you first call `useDeleteDescMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDescMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDescMutation, { data, loading, error }] = useDeleteDescMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDescMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDescMutation, DeleteDescMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDescMutation, DeleteDescMutationVariables>(DeleteDescDocument, options);
      }
export type DeleteDescMutationHookResult = ReturnType<typeof useDeleteDescMutation>;
export type DeleteDescMutationResult = Apollo.MutationResult<DeleteDescMutation>;
export type DeleteDescMutationOptions = Apollo.BaseMutationOptions<DeleteDescMutation, DeleteDescMutationVariables>;
export const GetDescriptionDocument = gql`
    query getDescription($id: String!) {
  description(where: {id: $id}) {
    id
    updatedAt
    createdAt
    title
    description
    shortDescription
    slug
    buttonText
    buttonUrl
    language
    metaTitle
    metaKeywords
    metaDescription
    seoPoint
  }
}
    `;

/**
 * __useGetDescriptionQuery__
 *
 * To run a query within a React component, call `useGetDescriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDescriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDescriptionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDescriptionQuery(baseOptions: Apollo.QueryHookOptions<GetDescriptionQuery, GetDescriptionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDescriptionQuery, GetDescriptionQueryVariables>(GetDescriptionDocument, options);
      }
export function useGetDescriptionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDescriptionQuery, GetDescriptionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDescriptionQuery, GetDescriptionQueryVariables>(GetDescriptionDocument, options);
        }
export type GetDescriptionQueryHookResult = ReturnType<typeof useGetDescriptionQuery>;
export type GetDescriptionLazyQueryHookResult = ReturnType<typeof useGetDescriptionLazyQuery>;
export type GetDescriptionQueryResult = Apollo.QueryResult<GetDescriptionQuery, GetDescriptionQueryVariables>;
export const UpdateDescDocument = gql`
    mutation updateDesc($id: String!, $title: String!, $description: String, $language: String!, $shortDescription: String, $buttonUrl: String, $buttonText: String) {
  updateDescription(
    where: {id: $id}
    data: {title: {set: $title}, description: {set: $description}, language: {set: $language}, shortDescription: {set: $shortDescription}, buttonUrl: {set: $buttonUrl}, buttonText: {set: $buttonText}}
  ) {
    id
    updatedAt
    createdAt
    title
    description
    buttonText
    buttonUrl
  }
}
    `;
export type UpdateDescMutationFn = Apollo.MutationFunction<UpdateDescMutation, UpdateDescMutationVariables>;

/**
 * __useUpdateDescMutation__
 *
 * To run a mutation, you first call `useUpdateDescMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDescMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDescMutation, { data, loading, error }] = useUpdateDescMutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      language: // value for 'language'
 *      shortDescription: // value for 'shortDescription'
 *      buttonUrl: // value for 'buttonUrl'
 *      buttonText: // value for 'buttonText'
 *   },
 * });
 */
export function useUpdateDescMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDescMutation, UpdateDescMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDescMutation, UpdateDescMutationVariables>(UpdateDescDocument, options);
      }
export type UpdateDescMutationHookResult = ReturnType<typeof useUpdateDescMutation>;
export type UpdateDescMutationResult = Apollo.MutationResult<UpdateDescMutation>;
export type UpdateDescMutationOptions = Apollo.BaseMutationOptions<UpdateDescMutation, UpdateDescMutationVariables>;
export const CreateEventDocument = gql`
    mutation createEvent($startDate: DateTime!, $endDate: DateTime!, $title: String!, $description: String, $shortDescription: String, $language: String!, $buttonText: String, $buttonUrl: String) {
  createEvent(
    data: {startDate: $startDate, endDate: $endDate, description: {create: {title: $title, description: $description, shortDescription: $shortDescription, language: $language, buttonText: $buttonText, buttonUrl: $buttonUrl, seoPoint: 0}}}
  ) {
    id
    updatedAt
    createdAt
  }
}
    `;
export type CreateEventMutationFn = Apollo.MutationFunction<CreateEventMutation, CreateEventMutationVariables>;

/**
 * __useCreateEventMutation__
 *
 * To run a mutation, you first call `useCreateEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEventMutation, { data, loading, error }] = useCreateEventMutation({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      shortDescription: // value for 'shortDescription'
 *      language: // value for 'language'
 *      buttonText: // value for 'buttonText'
 *      buttonUrl: // value for 'buttonUrl'
 *   },
 * });
 */
export function useCreateEventMutation(baseOptions?: Apollo.MutationHookOptions<CreateEventMutation, CreateEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEventMutation, CreateEventMutationVariables>(CreateEventDocument, options);
      }
export type CreateEventMutationHookResult = ReturnType<typeof useCreateEventMutation>;
export type CreateEventMutationResult = Apollo.MutationResult<CreateEventMutation>;
export type CreateEventMutationOptions = Apollo.BaseMutationOptions<CreateEventMutation, CreateEventMutationVariables>;
export const DeleteEventDocument = gql`
    mutation deleteEvent($id: String!) {
  deleteEvent(where: {id: $id}) {
    id
  }
}
    `;
export type DeleteEventMutationFn = Apollo.MutationFunction<DeleteEventMutation, DeleteEventMutationVariables>;

/**
 * __useDeleteEventMutation__
 *
 * To run a mutation, you first call `useDeleteEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEventMutation, { data, loading, error }] = useDeleteEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteEventMutation(baseOptions?: Apollo.MutationHookOptions<DeleteEventMutation, DeleteEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteEventMutation, DeleteEventMutationVariables>(DeleteEventDocument, options);
      }
export type DeleteEventMutationHookResult = ReturnType<typeof useDeleteEventMutation>;
export type DeleteEventMutationResult = Apollo.MutationResult<DeleteEventMutation>;
export type DeleteEventMutationOptions = Apollo.BaseMutationOptions<DeleteEventMutation, DeleteEventMutationVariables>;
export const GetEventDocument = gql`
    query getEvent($id: String!) {
  findFirstEvent(where: {id: {equals: $id}}) {
    id
    createdAt
    updatedAt
    startDate
    endDate
    description {
      id
      updatedAt
      createdAt
      title
      description
      shortDescription
      slug
      buttonText
      buttonUrl
      language
      metaTitle
      metaDescription
      metaKeywords
      seoPoint
    }
  }
}
    `;

/**
 * __useGetEventQuery__
 *
 * To run a query within a React component, call `useGetEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEventQuery(baseOptions: Apollo.QueryHookOptions<GetEventQuery, GetEventQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEventQuery, GetEventQueryVariables>(GetEventDocument, options);
      }
export function useGetEventLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEventQuery, GetEventQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEventQuery, GetEventQueryVariables>(GetEventDocument, options);
        }
export type GetEventQueryHookResult = ReturnType<typeof useGetEventQuery>;
export type GetEventLazyQueryHookResult = ReturnType<typeof useGetEventLazyQuery>;
export type GetEventQueryResult = Apollo.QueryResult<GetEventQuery, GetEventQueryVariables>;
export const GetEventsDocument = gql`
    query getEvents {
  events(orderBy: {startDate: asc}) {
    id
    createdAt
    updatedAt
    startDate
    endDate
    description {
      id
      updatedAt
      createdAt
      title
      description
      shortDescription
      slug
      buttonText
      buttonUrl
      language
      metaTitle
      metaDescription
      metaKeywords
      seoPoint
    }
  }
}
    `;

/**
 * __useGetEventsQuery__
 *
 * To run a query within a React component, call `useGetEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEventsQuery(baseOptions?: Apollo.QueryHookOptions<GetEventsQuery, GetEventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEventsQuery, GetEventsQueryVariables>(GetEventsDocument, options);
      }
export function useGetEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEventsQuery, GetEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEventsQuery, GetEventsQueryVariables>(GetEventsDocument, options);
        }
export type GetEventsQueryHookResult = ReturnType<typeof useGetEventsQuery>;
export type GetEventsLazyQueryHookResult = ReturnType<typeof useGetEventsLazyQuery>;
export type GetEventsQueryResult = Apollo.QueryResult<GetEventsQuery, GetEventsQueryVariables>;
export const UpdateEventDocument = gql`
    mutation updateEvent($id: String!, $startDate: DateTime!, $endDate: DateTime!) {
  updateEvent(
    data: {startDate: {set: $startDate}, endDate: {set: $endDate}}
    where: {id: $id}
  ) {
    id
    updatedAt
  }
}
    `;
export type UpdateEventMutationFn = Apollo.MutationFunction<UpdateEventMutation, UpdateEventMutationVariables>;

/**
 * __useUpdateEventMutation__
 *
 * To run a mutation, you first call `useUpdateEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEventMutation, { data, loading, error }] = useUpdateEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useUpdateEventMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEventMutation, UpdateEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEventMutation, UpdateEventMutationVariables>(UpdateEventDocument, options);
      }
export type UpdateEventMutationHookResult = ReturnType<typeof useUpdateEventMutation>;
export type UpdateEventMutationResult = Apollo.MutationResult<UpdateEventMutation>;
export type UpdateEventMutationOptions = Apollo.BaseMutationOptions<UpdateEventMutation, UpdateEventMutationVariables>;
export const CreateFaqDocument = gql`
    mutation createFaq($title: String!, $description: String, $shortDescription: String, $language: String!, $buttonText: String, $buttonUrl: String) {
  createFaq(
    data: {description: {create: {title: $title, description: $description, shortDescription: $shortDescription, language: $language, buttonText: $buttonText, buttonUrl: $buttonUrl, seoPoint: 0}}}
  ) {
    id
    updatedAt
    createdAt
  }
}
    `;
export type CreateFaqMutationFn = Apollo.MutationFunction<CreateFaqMutation, CreateFaqMutationVariables>;

/**
 * __useCreateFaqMutation__
 *
 * To run a mutation, you first call `useCreateFaqMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFaqMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFaqMutation, { data, loading, error }] = useCreateFaqMutation({
 *   variables: {
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      shortDescription: // value for 'shortDescription'
 *      language: // value for 'language'
 *      buttonText: // value for 'buttonText'
 *      buttonUrl: // value for 'buttonUrl'
 *   },
 * });
 */
export function useCreateFaqMutation(baseOptions?: Apollo.MutationHookOptions<CreateFaqMutation, CreateFaqMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFaqMutation, CreateFaqMutationVariables>(CreateFaqDocument, options);
      }
export type CreateFaqMutationHookResult = ReturnType<typeof useCreateFaqMutation>;
export type CreateFaqMutationResult = Apollo.MutationResult<CreateFaqMutation>;
export type CreateFaqMutationOptions = Apollo.BaseMutationOptions<CreateFaqMutation, CreateFaqMutationVariables>;
export const DeleteFaqDocument = gql`
    mutation deleteFaq($id: String!) {
  deleteFaq(where: {id: $id}) {
    id
  }
}
    `;
export type DeleteFaqMutationFn = Apollo.MutationFunction<DeleteFaqMutation, DeleteFaqMutationVariables>;

/**
 * __useDeleteFaqMutation__
 *
 * To run a mutation, you first call `useDeleteFaqMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFaqMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFaqMutation, { data, loading, error }] = useDeleteFaqMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteFaqMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFaqMutation, DeleteFaqMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFaqMutation, DeleteFaqMutationVariables>(DeleteFaqDocument, options);
      }
export type DeleteFaqMutationHookResult = ReturnType<typeof useDeleteFaqMutation>;
export type DeleteFaqMutationResult = Apollo.MutationResult<DeleteFaqMutation>;
export type DeleteFaqMutationOptions = Apollo.BaseMutationOptions<DeleteFaqMutation, DeleteFaqMutationVariables>;
export const GetFaqDocument = gql`
    query getFaq($id: String!) {
  findFirstFaq(where: {id: {equals: $id}}) {
    id
    createdAt
    updatedAt
    order
    description {
      id
      updatedAt
      createdAt
      title
      description
      shortDescription
      slug
      buttonText
      buttonUrl
      language
      metaTitle
      metaDescription
      metaKeywords
      seoPoint
    }
  }
}
    `;

/**
 * __useGetFaqQuery__
 *
 * To run a query within a React component, call `useGetFaqQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFaqQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFaqQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetFaqQuery(baseOptions: Apollo.QueryHookOptions<GetFaqQuery, GetFaqQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFaqQuery, GetFaqQueryVariables>(GetFaqDocument, options);
      }
export function useGetFaqLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFaqQuery, GetFaqQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFaqQuery, GetFaqQueryVariables>(GetFaqDocument, options);
        }
export type GetFaqQueryHookResult = ReturnType<typeof useGetFaqQuery>;
export type GetFaqLazyQueryHookResult = ReturnType<typeof useGetFaqLazyQuery>;
export type GetFaqQueryResult = Apollo.QueryResult<GetFaqQuery, GetFaqQueryVariables>;
export const GetFaqsDocument = gql`
    query getFaqs {
  faqs(orderBy: {order: asc}) {
    id
    createdAt
    updatedAt
    order
    description {
      id
      updatedAt
      createdAt
      title
      description
      shortDescription
      slug
      buttonText
      buttonUrl
      language
      metaTitle
      metaDescription
      metaKeywords
      seoPoint
    }
  }
}
    `;

/**
 * __useGetFaqsQuery__
 *
 * To run a query within a React component, call `useGetFaqsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFaqsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFaqsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFaqsQuery(baseOptions?: Apollo.QueryHookOptions<GetFaqsQuery, GetFaqsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFaqsQuery, GetFaqsQueryVariables>(GetFaqsDocument, options);
      }
export function useGetFaqsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFaqsQuery, GetFaqsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFaqsQuery, GetFaqsQueryVariables>(GetFaqsDocument, options);
        }
export type GetFaqsQueryHookResult = ReturnType<typeof useGetFaqsQuery>;
export type GetFaqsLazyQueryHookResult = ReturnType<typeof useGetFaqsLazyQuery>;
export type GetFaqsQueryResult = Apollo.QueryResult<GetFaqsQuery, GetFaqsQueryVariables>;
export const OrderFaqDocument = gql`
    mutation orderFaq($id: String!, $order: Int!) {
  updateFaq(where: {id: $id}, data: {order: {set: $order}}) {
    id
    updatedAt
  }
}
    `;
export type OrderFaqMutationFn = Apollo.MutationFunction<OrderFaqMutation, OrderFaqMutationVariables>;

/**
 * __useOrderFaqMutation__
 *
 * To run a mutation, you first call `useOrderFaqMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderFaqMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderFaqMutation, { data, loading, error }] = useOrderFaqMutation({
 *   variables: {
 *      id: // value for 'id'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useOrderFaqMutation(baseOptions?: Apollo.MutationHookOptions<OrderFaqMutation, OrderFaqMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OrderFaqMutation, OrderFaqMutationVariables>(OrderFaqDocument, options);
      }
export type OrderFaqMutationHookResult = ReturnType<typeof useOrderFaqMutation>;
export type OrderFaqMutationResult = Apollo.MutationResult<OrderFaqMutation>;
export type OrderFaqMutationOptions = Apollo.BaseMutationOptions<OrderFaqMutation, OrderFaqMutationVariables>;
export const UploadFileDocument = gql`
    mutation uploadFile($file: Upload!) {
  uploadFile(file: $file)
}
    `;
export type UploadFileMutationFn = Apollo.MutationFunction<UploadFileMutation, UploadFileMutationVariables>;

/**
 * __useUploadFileMutation__
 *
 * To run a mutation, you first call `useUploadFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadFileMutation, { data, loading, error }] = useUploadFileMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadFileMutation(baseOptions?: Apollo.MutationHookOptions<UploadFileMutation, UploadFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadFileMutation, UploadFileMutationVariables>(UploadFileDocument, options);
      }
export type UploadFileMutationHookResult = ReturnType<typeof useUploadFileMutation>;
export type UploadFileMutationResult = Apollo.MutationResult<UploadFileMutation>;
export type UploadFileMutationOptions = Apollo.BaseMutationOptions<UploadFileMutation, UploadFileMutationVariables>;
export const UploadProductFileDocument = gql`
    mutation uploadProductFile($file: Upload!) {
  uploadProductFile(file: $file)
}
    `;
export type UploadProductFileMutationFn = Apollo.MutationFunction<UploadProductFileMutation, UploadProductFileMutationVariables>;

/**
 * __useUploadProductFileMutation__
 *
 * To run a mutation, you first call `useUploadProductFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadProductFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadProductFileMutation, { data, loading, error }] = useUploadProductFileMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadProductFileMutation(baseOptions?: Apollo.MutationHookOptions<UploadProductFileMutation, UploadProductFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadProductFileMutation, UploadProductFileMutationVariables>(UploadProductFileDocument, options);
      }
export type UploadProductFileMutationHookResult = ReturnType<typeof useUploadProductFileMutation>;
export type UploadProductFileMutationResult = Apollo.MutationResult<UploadProductFileMutation>;
export type UploadProductFileMutationOptions = Apollo.BaseMutationOptions<UploadProductFileMutation, UploadProductFileMutationVariables>;
export const DeleteMessageDocument = gql`
    mutation deleteMessage($id: ID!) {
  deleteMessage(id: $id)
}
    `;
export type DeleteMessageMutationFn = Apollo.MutationFunction<DeleteMessageMutation, DeleteMessageMutationVariables>;

/**
 * __useDeleteMessageMutation__
 *
 * To run a mutation, you first call `useDeleteMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMessageMutation, { data, loading, error }] = useDeleteMessageMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteMessageMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMessageMutation, DeleteMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteMessageMutation, DeleteMessageMutationVariables>(DeleteMessageDocument, options);
      }
export type DeleteMessageMutationHookResult = ReturnType<typeof useDeleteMessageMutation>;
export type DeleteMessageMutationResult = Apollo.MutationResult<DeleteMessageMutation>;
export type DeleteMessageMutationOptions = Apollo.BaseMutationOptions<DeleteMessageMutation, DeleteMessageMutationVariables>;
export const GetFormMessageDocument = gql`
    query getFormMessage($id: String!) {
  findFirstCustomForms(where: {id: {equals: $id}}) {
    id
    createdAt
    updatedAt
    name
    surname
    email
    companyName
    message
    fieldOne
    fieldTwo
    department
    formType
    isRead
    productId
    product {
      id
      descriptions {
        id
        title
        slug
        language
      }
    }
  }
}
    `;

/**
 * __useGetFormMessageQuery__
 *
 * To run a query within a React component, call `useGetFormMessageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFormMessageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFormMessageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetFormMessageQuery(baseOptions: Apollo.QueryHookOptions<GetFormMessageQuery, GetFormMessageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFormMessageQuery, GetFormMessageQueryVariables>(GetFormMessageDocument, options);
      }
export function useGetFormMessageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFormMessageQuery, GetFormMessageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFormMessageQuery, GetFormMessageQueryVariables>(GetFormMessageDocument, options);
        }
export type GetFormMessageQueryHookResult = ReturnType<typeof useGetFormMessageQuery>;
export type GetFormMessageLazyQueryHookResult = ReturnType<typeof useGetFormMessageLazyQuery>;
export type GetFormMessageQueryResult = Apollo.QueryResult<GetFormMessageQuery, GetFormMessageQueryVariables>;
export const GetFormMessagesDocument = gql`
    query getFormMessages {
  findManyCustomForms(orderBy: {createdAt: desc}) {
    id
    createdAt
    updatedAt
    name
    surname
    email
    companyName
    message
    fieldOne
    fieldTwo
    department
    formType
    isRead
    productId
    product {
      id
      descriptions {
        id
        title
        slug
        language
      }
    }
  }
}
    `;

/**
 * __useGetFormMessagesQuery__
 *
 * To run a query within a React component, call `useGetFormMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFormMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFormMessagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFormMessagesQuery(baseOptions?: Apollo.QueryHookOptions<GetFormMessagesQuery, GetFormMessagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFormMessagesQuery, GetFormMessagesQueryVariables>(GetFormMessagesDocument, options);
      }
export function useGetFormMessagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFormMessagesQuery, GetFormMessagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFormMessagesQuery, GetFormMessagesQueryVariables>(GetFormMessagesDocument, options);
        }
export type GetFormMessagesQueryHookResult = ReturnType<typeof useGetFormMessagesQuery>;
export type GetFormMessagesLazyQueryHookResult = ReturnType<typeof useGetFormMessagesLazyQuery>;
export type GetFormMessagesQueryResult = Apollo.QueryResult<GetFormMessagesQuery, GetFormMessagesQueryVariables>;
export const MarkAsReadMessageDocument = gql`
    mutation markAsReadMessage($id: String!) {
  updateCustomForms(where: {id: $id}, data: {isRead: {set: true}}) {
    id
  }
}
    `;
export type MarkAsReadMessageMutationFn = Apollo.MutationFunction<MarkAsReadMessageMutation, MarkAsReadMessageMutationVariables>;

/**
 * __useMarkAsReadMessageMutation__
 *
 * To run a mutation, you first call `useMarkAsReadMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkAsReadMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markAsReadMessageMutation, { data, loading, error }] = useMarkAsReadMessageMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMarkAsReadMessageMutation(baseOptions?: Apollo.MutationHookOptions<MarkAsReadMessageMutation, MarkAsReadMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkAsReadMessageMutation, MarkAsReadMessageMutationVariables>(MarkAsReadMessageDocument, options);
      }
export type MarkAsReadMessageMutationHookResult = ReturnType<typeof useMarkAsReadMessageMutation>;
export type MarkAsReadMessageMutationResult = Apollo.MutationResult<MarkAsReadMessageMutation>;
export type MarkAsReadMessageMutationOptions = Apollo.BaseMutationOptions<MarkAsReadMessageMutation, MarkAsReadMessageMutationVariables>;
export const CreateFormioFormDocument = gql`
    mutation createFormioForm($title: String!, $slug: String!) {
  createFormioForm(data: {title: $title, slug: $slug}) {
    id
  }
}
    `;
export type CreateFormioFormMutationFn = Apollo.MutationFunction<CreateFormioFormMutation, CreateFormioFormMutationVariables>;

/**
 * __useCreateFormioFormMutation__
 *
 * To run a mutation, you first call `useCreateFormioFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFormioFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFormioFormMutation, { data, loading, error }] = useCreateFormioFormMutation({
 *   variables: {
 *      title: // value for 'title'
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useCreateFormioFormMutation(baseOptions?: Apollo.MutationHookOptions<CreateFormioFormMutation, CreateFormioFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFormioFormMutation, CreateFormioFormMutationVariables>(CreateFormioFormDocument, options);
      }
export type CreateFormioFormMutationHookResult = ReturnType<typeof useCreateFormioFormMutation>;
export type CreateFormioFormMutationResult = Apollo.MutationResult<CreateFormioFormMutation>;
export type CreateFormioFormMutationOptions = Apollo.BaseMutationOptions<CreateFormioFormMutation, CreateFormioFormMutationVariables>;
export const DeleteFormioFormDocument = gql`
    mutation DeleteFormioForm($deleteFormioFormWhere: FormioFormWhereUniqueInput!) {
  deleteFormioForm(where: $deleteFormioFormWhere) {
    id
  }
}
    `;
export type DeleteFormioFormMutationFn = Apollo.MutationFunction<DeleteFormioFormMutation, DeleteFormioFormMutationVariables>;

/**
 * __useDeleteFormioFormMutation__
 *
 * To run a mutation, you first call `useDeleteFormioFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFormioFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFormioFormMutation, { data, loading, error }] = useDeleteFormioFormMutation({
 *   variables: {
 *      deleteFormioFormWhere: // value for 'deleteFormioFormWhere'
 *   },
 * });
 */
export function useDeleteFormioFormMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFormioFormMutation, DeleteFormioFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFormioFormMutation, DeleteFormioFormMutationVariables>(DeleteFormioFormDocument, options);
      }
export type DeleteFormioFormMutationHookResult = ReturnType<typeof useDeleteFormioFormMutation>;
export type DeleteFormioFormMutationResult = Apollo.MutationResult<DeleteFormioFormMutation>;
export type DeleteFormioFormMutationOptions = Apollo.BaseMutationOptions<DeleteFormioFormMutation, DeleteFormioFormMutationVariables>;
export const GetFormioFormDocument = gql`
    query getFormioForm($id: String!) {
  formioForm(where: {id: $id}) {
    id
    title
    slug
    order
    status
  }
}
    `;

/**
 * __useGetFormioFormQuery__
 *
 * To run a query within a React component, call `useGetFormioFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFormioFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFormioFormQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetFormioFormQuery(baseOptions: Apollo.QueryHookOptions<GetFormioFormQuery, GetFormioFormQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFormioFormQuery, GetFormioFormQueryVariables>(GetFormioFormDocument, options);
      }
export function useGetFormioFormLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFormioFormQuery, GetFormioFormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFormioFormQuery, GetFormioFormQueryVariables>(GetFormioFormDocument, options);
        }
export type GetFormioFormQueryHookResult = ReturnType<typeof useGetFormioFormQuery>;
export type GetFormioFormLazyQueryHookResult = ReturnType<typeof useGetFormioFormLazyQuery>;
export type GetFormioFormQueryResult = Apollo.QueryResult<GetFormioFormQuery, GetFormioFormQueryVariables>;
export const GetFormioFormsDocument = gql`
    query getFormioForms {
  formioForms(orderBy: {order: asc}) {
    id
    title
    slug
    order
    status
  }
}
    `;

/**
 * __useGetFormioFormsQuery__
 *
 * To run a query within a React component, call `useGetFormioFormsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFormioFormsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFormioFormsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFormioFormsQuery(baseOptions?: Apollo.QueryHookOptions<GetFormioFormsQuery, GetFormioFormsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFormioFormsQuery, GetFormioFormsQueryVariables>(GetFormioFormsDocument, options);
      }
export function useGetFormioFormsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFormioFormsQuery, GetFormioFormsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFormioFormsQuery, GetFormioFormsQueryVariables>(GetFormioFormsDocument, options);
        }
export type GetFormioFormsQueryHookResult = ReturnType<typeof useGetFormioFormsQuery>;
export type GetFormioFormsLazyQueryHookResult = ReturnType<typeof useGetFormioFormsLazyQuery>;
export type GetFormioFormsQueryResult = Apollo.QueryResult<GetFormioFormsQuery, GetFormioFormsQueryVariables>;
export const OrderFormioFormDocument = gql`
    mutation orderFormioForm($id: String!, $order: Int!) {
  updateFormioForm(where: {id: $id}, data: {order: {set: $order}}) {
    id
  }
}
    `;
export type OrderFormioFormMutationFn = Apollo.MutationFunction<OrderFormioFormMutation, OrderFormioFormMutationVariables>;

/**
 * __useOrderFormioFormMutation__
 *
 * To run a mutation, you first call `useOrderFormioFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderFormioFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderFormioFormMutation, { data, loading, error }] = useOrderFormioFormMutation({
 *   variables: {
 *      id: // value for 'id'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useOrderFormioFormMutation(baseOptions?: Apollo.MutationHookOptions<OrderFormioFormMutation, OrderFormioFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OrderFormioFormMutation, OrderFormioFormMutationVariables>(OrderFormioFormDocument, options);
      }
export type OrderFormioFormMutationHookResult = ReturnType<typeof useOrderFormioFormMutation>;
export type OrderFormioFormMutationResult = Apollo.MutationResult<OrderFormioFormMutation>;
export type OrderFormioFormMutationOptions = Apollo.BaseMutationOptions<OrderFormioFormMutation, OrderFormioFormMutationVariables>;
export const UpdateFormioFormDocument = gql`
    mutation updateFormioForm($id: String!, $title: String!, $slug: String!) {
  updateFormioForm(
    where: {id: $id}
    data: {title: {set: $title}, slug: {set: $slug}}
  ) {
    id
    title
  }
}
    `;
export type UpdateFormioFormMutationFn = Apollo.MutationFunction<UpdateFormioFormMutation, UpdateFormioFormMutationVariables>;

/**
 * __useUpdateFormioFormMutation__
 *
 * To run a mutation, you first call `useUpdateFormioFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFormioFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFormioFormMutation, { data, loading, error }] = useUpdateFormioFormMutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useUpdateFormioFormMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFormioFormMutation, UpdateFormioFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFormioFormMutation, UpdateFormioFormMutationVariables>(UpdateFormioFormDocument, options);
      }
export type UpdateFormioFormMutationHookResult = ReturnType<typeof useUpdateFormioFormMutation>;
export type UpdateFormioFormMutationResult = Apollo.MutationResult<UpdateFormioFormMutation>;
export type UpdateFormioFormMutationOptions = Apollo.BaseMutationOptions<UpdateFormioFormMutation, UpdateFormioFormMutationVariables>;
export const CreatePageDocument = gql`
    mutation createPage($showOnHeader: Boolean!, $showOnFooter: Boolean!, $isLink: Boolean, $status: Boolean, $pageType: PageType, $title: String!, $description: String, $shortDescription: String, $language: String!, $buttonText: String, $buttonUrl: String) {
  createPage(
    data: {showOnHeader: $showOnHeader, showOnFooter: $showOnFooter, isLink: $isLink, status: $status, pageType: $pageType}
    description: {title: $title, description: $description, shortDescription: $shortDescription, language: $language, buttonText: $buttonText, buttonUrl: $buttonUrl, seoPoint: 0}
  ) {
    id
    updatedAt
    createdAt
    photos
    showOnHeader
    showOnFooter
    publishedAt
    isLink
    status
    order
  }
}
    `;
export type CreatePageMutationFn = Apollo.MutationFunction<CreatePageMutation, CreatePageMutationVariables>;

/**
 * __useCreatePageMutation__
 *
 * To run a mutation, you first call `useCreatePageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPageMutation, { data, loading, error }] = useCreatePageMutation({
 *   variables: {
 *      showOnHeader: // value for 'showOnHeader'
 *      showOnFooter: // value for 'showOnFooter'
 *      isLink: // value for 'isLink'
 *      status: // value for 'status'
 *      pageType: // value for 'pageType'
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      shortDescription: // value for 'shortDescription'
 *      language: // value for 'language'
 *      buttonText: // value for 'buttonText'
 *      buttonUrl: // value for 'buttonUrl'
 *   },
 * });
 */
export function useCreatePageMutation(baseOptions?: Apollo.MutationHookOptions<CreatePageMutation, CreatePageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePageMutation, CreatePageMutationVariables>(CreatePageDocument, options);
      }
export type CreatePageMutationHookResult = ReturnType<typeof useCreatePageMutation>;
export type CreatePageMutationResult = Apollo.MutationResult<CreatePageMutation>;
export type CreatePageMutationOptions = Apollo.BaseMutationOptions<CreatePageMutation, CreatePageMutationVariables>;
export const CreatePageWithParentDocument = gql`
    mutation createPageWithParent($showOnHeader: Boolean!, $showOnFooter: Boolean!, $isLink: Boolean, $status: Boolean, $pageType: PageType, $parentPageId: String, $title: String!, $description: String, $shortDescription: String, $language: String!, $buttonText: String, $buttonUrl: String) {
  createPage(
    data: {showOnHeader: $showOnHeader, showOnFooter: $showOnFooter, isLink: $isLink, status: $status, pageType: $pageType, parent: {connect: {id: $parentPageId}}}
    description: {title: $title, description: $description, shortDescription: $shortDescription, language: $language, buttonText: $buttonText, buttonUrl: $buttonUrl, seoPoint: 0}
  ) {
    id
    updatedAt
    createdAt
    photos
    showOnHeader
    showOnFooter
    publishedAt
    isLink
    status
    order
  }
}
    `;
export type CreatePageWithParentMutationFn = Apollo.MutationFunction<CreatePageWithParentMutation, CreatePageWithParentMutationVariables>;

/**
 * __useCreatePageWithParentMutation__
 *
 * To run a mutation, you first call `useCreatePageWithParentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePageWithParentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPageWithParentMutation, { data, loading, error }] = useCreatePageWithParentMutation({
 *   variables: {
 *      showOnHeader: // value for 'showOnHeader'
 *      showOnFooter: // value for 'showOnFooter'
 *      isLink: // value for 'isLink'
 *      status: // value for 'status'
 *      pageType: // value for 'pageType'
 *      parentPageId: // value for 'parentPageId'
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      shortDescription: // value for 'shortDescription'
 *      language: // value for 'language'
 *      buttonText: // value for 'buttonText'
 *      buttonUrl: // value for 'buttonUrl'
 *   },
 * });
 */
export function useCreatePageWithParentMutation(baseOptions?: Apollo.MutationHookOptions<CreatePageWithParentMutation, CreatePageWithParentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePageWithParentMutation, CreatePageWithParentMutationVariables>(CreatePageWithParentDocument, options);
      }
export type CreatePageWithParentMutationHookResult = ReturnType<typeof useCreatePageWithParentMutation>;
export type CreatePageWithParentMutationResult = Apollo.MutationResult<CreatePageWithParentMutation>;
export type CreatePageWithParentMutationOptions = Apollo.BaseMutationOptions<CreatePageWithParentMutation, CreatePageWithParentMutationVariables>;
export const DeletePageDocument = gql`
    mutation deletePage($id: String!) {
  deletePage(id: $id)
}
    `;
export type DeletePageMutationFn = Apollo.MutationFunction<DeletePageMutation, DeletePageMutationVariables>;

/**
 * __useDeletePageMutation__
 *
 * To run a mutation, you first call `useDeletePageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePageMutation, { data, loading, error }] = useDeletePageMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePageMutation(baseOptions?: Apollo.MutationHookOptions<DeletePageMutation, DeletePageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePageMutation, DeletePageMutationVariables>(DeletePageDocument, options);
      }
export type DeletePageMutationHookResult = ReturnType<typeof useDeletePageMutation>;
export type DeletePageMutationResult = Apollo.MutationResult<DeletePageMutation>;
export type DeletePageMutationOptions = Apollo.BaseMutationOptions<DeletePageMutation, DeletePageMutationVariables>;
export const OrderPageDocument = gql`
    mutation orderPage($id: String!, $order: Int!) {
  updatePage(where: {id: $id}, data: {order: {set: $order}}) {
    id
    updatedAt
  }
}
    `;
export type OrderPageMutationFn = Apollo.MutationFunction<OrderPageMutation, OrderPageMutationVariables>;

/**
 * __useOrderPageMutation__
 *
 * To run a mutation, you first call `useOrderPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderPageMutation, { data, loading, error }] = useOrderPageMutation({
 *   variables: {
 *      id: // value for 'id'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useOrderPageMutation(baseOptions?: Apollo.MutationHookOptions<OrderPageMutation, OrderPageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OrderPageMutation, OrderPageMutationVariables>(OrderPageDocument, options);
      }
export type OrderPageMutationHookResult = ReturnType<typeof useOrderPageMutation>;
export type OrderPageMutationResult = Apollo.MutationResult<OrderPageMutation>;
export type OrderPageMutationOptions = Apollo.BaseMutationOptions<OrderPageMutation, OrderPageMutationVariables>;
export const GetPageDocument = gql`
    query getPage($id: String) {
  page(where: {id: {equals: $id}}) {
    id
    updatedAt
    createdAt
    description {
      id
      updatedAt
      createdAt
      title
      description
      shortDescription
      slug
      buttonText
      buttonUrl
      language
      metaTitle
      metaKeywords
      metaDescription
      seoPoint
    }
    photos
    content {
      id
      photos
      videos
    }
    pageType
    parentId
    showOnHeader
    showOnFooter
    publishedAt
    isLink
    status
    order
  }
}
    `;

/**
 * __useGetPageQuery__
 *
 * To run a query within a React component, call `useGetPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPageQuery(baseOptions?: Apollo.QueryHookOptions<GetPageQuery, GetPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPageQuery, GetPageQueryVariables>(GetPageDocument, options);
      }
export function useGetPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPageQuery, GetPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPageQuery, GetPageQueryVariables>(GetPageDocument, options);
        }
export type GetPageQueryHookResult = ReturnType<typeof useGetPageQuery>;
export type GetPageLazyQueryHookResult = ReturnType<typeof useGetPageLazyQuery>;
export type GetPageQueryResult = Apollo.QueryResult<GetPageQuery, GetPageQueryVariables>;
export const GetPagesDocument = gql`
    query getPages {
  pages(orderBy: {order: asc}) {
    id
    updatedAt
    createdAt
    description {
      id
      updatedAt
      createdAt
      title
      description
      shortDescription
      slug
      buttonText
      buttonUrl
      language
      metaTitle
      metaDescription
      metaKeywords
      seoPoint
    }
    photos
    content {
      id
      photos
      videos
    }
    pageType
    isHomepage
    showOnHeader
    showOnFooter
    parentId
    parent {
      id
      description {
        title
      }
    }
    publishedAt
    isLink
    status
    order
  }
}
    `;

/**
 * __useGetPagesQuery__
 *
 * To run a query within a React component, call `useGetPagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPagesQuery(baseOptions?: Apollo.QueryHookOptions<GetPagesQuery, GetPagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPagesQuery, GetPagesQueryVariables>(GetPagesDocument, options);
      }
export function useGetPagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPagesQuery, GetPagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPagesQuery, GetPagesQueryVariables>(GetPagesDocument, options);
        }
export type GetPagesQueryHookResult = ReturnType<typeof useGetPagesQuery>;
export type GetPagesLazyQueryHookResult = ReturnType<typeof useGetPagesLazyQuery>;
export type GetPagesQueryResult = Apollo.QueryResult<GetPagesQuery, GetPagesQueryVariables>;
export const UpdatePageDocument = gql`
    mutation updatePage($id: String, $showOnHeader: Boolean, $showOnFooter: Boolean, $isLink: Boolean, $status: Boolean, $pageType: PageType, $parentPageId: String) {
  updatePage(
    where: {id: $id}
    data: {showOnHeader: {set: $showOnHeader}, showOnFooter: {set: $showOnFooter}, isLink: {set: $isLink}, status: {set: $status}, pageType: {set: $pageType}, parent: {connect: {id: $parentPageId}}}
  ) {
    id
    updatedAt
    createdAt
    photos
    showOnHeader
    showOnFooter
    publishedAt
    isLink
    status
    order
  }
}
    `;
export type UpdatePageMutationFn = Apollo.MutationFunction<UpdatePageMutation, UpdatePageMutationVariables>;

/**
 * __useUpdatePageMutation__
 *
 * To run a mutation, you first call `useUpdatePageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePageMutation, { data, loading, error }] = useUpdatePageMutation({
 *   variables: {
 *      id: // value for 'id'
 *      showOnHeader: // value for 'showOnHeader'
 *      showOnFooter: // value for 'showOnFooter'
 *      isLink: // value for 'isLink'
 *      status: // value for 'status'
 *      pageType: // value for 'pageType'
 *      parentPageId: // value for 'parentPageId'
 *   },
 * });
 */
export function useUpdatePageMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePageMutation, UpdatePageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePageMutation, UpdatePageMutationVariables>(UpdatePageDocument, options);
      }
export type UpdatePageMutationHookResult = ReturnType<typeof useUpdatePageMutation>;
export type UpdatePageMutationResult = Apollo.MutationResult<UpdatePageMutation>;
export type UpdatePageMutationOptions = Apollo.BaseMutationOptions<UpdatePageMutation, UpdatePageMutationVariables>;
export const UpdatePageFlatDocument = gql`
    mutation updatePageFlat($id: String, $showOnHeader: Boolean, $showOnFooter: Boolean, $isLink: Boolean, $status: Boolean, $pageType: PageType) {
  updatePage(
    where: {id: $id}
    data: {showOnHeader: {set: $showOnHeader}, showOnFooter: {set: $showOnFooter}, isLink: {set: $isLink}, status: {set: $status}, pageType: {set: $pageType}}
  ) {
    id
    updatedAt
    createdAt
    photos
    showOnHeader
    showOnFooter
    publishedAt
    isLink
    status
    order
  }
}
    `;
export type UpdatePageFlatMutationFn = Apollo.MutationFunction<UpdatePageFlatMutation, UpdatePageFlatMutationVariables>;

/**
 * __useUpdatePageFlatMutation__
 *
 * To run a mutation, you first call `useUpdatePageFlatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePageFlatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePageFlatMutation, { data, loading, error }] = useUpdatePageFlatMutation({
 *   variables: {
 *      id: // value for 'id'
 *      showOnHeader: // value for 'showOnHeader'
 *      showOnFooter: // value for 'showOnFooter'
 *      isLink: // value for 'isLink'
 *      status: // value for 'status'
 *      pageType: // value for 'pageType'
 *   },
 * });
 */
export function useUpdatePageFlatMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePageFlatMutation, UpdatePageFlatMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePageFlatMutation, UpdatePageFlatMutationVariables>(UpdatePageFlatDocument, options);
      }
export type UpdatePageFlatMutationHookResult = ReturnType<typeof useUpdatePageFlatMutation>;
export type UpdatePageFlatMutationResult = Apollo.MutationResult<UpdatePageFlatMutation>;
export type UpdatePageFlatMutationOptions = Apollo.BaseMutationOptions<UpdatePageFlatMutation, UpdatePageFlatMutationVariables>;
export const UpdatePageDisconnectParentDocument = gql`
    mutation updatePageDisconnectParent($id: String, $showOnHeader: Boolean, $showOnFooter: Boolean, $isLink: Boolean, $status: Boolean, $pageType: PageType) {
  updatePage(
    where: {id: $id}
    data: {showOnHeader: {set: $showOnHeader}, showOnFooter: {set: $showOnFooter}, isLink: {set: $isLink}, status: {set: $status}, pageType: {set: $pageType}, parent: {disconnect: true}}
  ) {
    id
    updatedAt
    createdAt
    photos
    showOnHeader
    showOnFooter
    publishedAt
    isLink
    status
    order
  }
}
    `;
export type UpdatePageDisconnectParentMutationFn = Apollo.MutationFunction<UpdatePageDisconnectParentMutation, UpdatePageDisconnectParentMutationVariables>;

/**
 * __useUpdatePageDisconnectParentMutation__
 *
 * To run a mutation, you first call `useUpdatePageDisconnectParentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePageDisconnectParentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePageDisconnectParentMutation, { data, loading, error }] = useUpdatePageDisconnectParentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      showOnHeader: // value for 'showOnHeader'
 *      showOnFooter: // value for 'showOnFooter'
 *      isLink: // value for 'isLink'
 *      status: // value for 'status'
 *      pageType: // value for 'pageType'
 *   },
 * });
 */
export function useUpdatePageDisconnectParentMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePageDisconnectParentMutation, UpdatePageDisconnectParentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePageDisconnectParentMutation, UpdatePageDisconnectParentMutationVariables>(UpdatePageDisconnectParentDocument, options);
      }
export type UpdatePageDisconnectParentMutationHookResult = ReturnType<typeof useUpdatePageDisconnectParentMutation>;
export type UpdatePageDisconnectParentMutationResult = Apollo.MutationResult<UpdatePageDisconnectParentMutation>;
export type UpdatePageDisconnectParentMutationOptions = Apollo.BaseMutationOptions<UpdatePageDisconnectParentMutation, UpdatePageDisconnectParentMutationVariables>;
export const SetHomepageDocument = gql`
    mutation setHomepage($id: ID!, $isHomepage: Boolean!) {
  setHomepage(id: $id, isHomepage: $isHomepage)
}
    `;
export type SetHomepageMutationFn = Apollo.MutationFunction<SetHomepageMutation, SetHomepageMutationVariables>;

/**
 * __useSetHomepageMutation__
 *
 * To run a mutation, you first call `useSetHomepageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetHomepageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setHomepageMutation, { data, loading, error }] = useSetHomepageMutation({
 *   variables: {
 *      id: // value for 'id'
 *      isHomepage: // value for 'isHomepage'
 *   },
 * });
 */
export function useSetHomepageMutation(baseOptions?: Apollo.MutationHookOptions<SetHomepageMutation, SetHomepageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetHomepageMutation, SetHomepageMutationVariables>(SetHomepageDocument, options);
      }
export type SetHomepageMutationHookResult = ReturnType<typeof useSetHomepageMutation>;
export type SetHomepageMutationResult = Apollo.MutationResult<SetHomepageMutation>;
export type SetHomepageMutationOptions = Apollo.BaseMutationOptions<SetHomepageMutation, SetHomepageMutationVariables>;
export const GetProductContentsDocument = gql`
    query getProductContents($productId: String) {
  contents(where: {productId: {equals: $productId}}, orderBy: {order: asc}) {
    id
    updatedAt
    createdAt
    description {
      id
      updatedAt
      createdAt
      title
      description
      shortDescription
      slug
      buttonText
      buttonUrl
      language
      metaTitle
      metaDescription
      metaKeywords
      seoPoint
    }
    photos
    page {
      id
      updatedAt
      createdAt
    }
    videos
    embedCode
    longitude
    latitude
    contentType
    mediaType
    source
    order
  }
}
    `;

/**
 * __useGetProductContentsQuery__
 *
 * To run a query within a React component, call `useGetProductContentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductContentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductContentsQuery({
 *   variables: {
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useGetProductContentsQuery(baseOptions?: Apollo.QueryHookOptions<GetProductContentsQuery, GetProductContentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductContentsQuery, GetProductContentsQueryVariables>(GetProductContentsDocument, options);
      }
export function useGetProductContentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductContentsQuery, GetProductContentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductContentsQuery, GetProductContentsQueryVariables>(GetProductContentsDocument, options);
        }
export type GetProductContentsQueryHookResult = ReturnType<typeof useGetProductContentsQuery>;
export type GetProductContentsLazyQueryHookResult = ReturnType<typeof useGetProductContentsLazyQuery>;
export type GetProductContentsQueryResult = Apollo.QueryResult<GetProductContentsQuery, GetProductContentsQueryVariables>;
export const CreateProductDocument = gql`
    mutation createProduct($sku: String!, $price: Int!, $salesPrice: Int!, $minimumQuantity: Int, $buyLink: String, $expiryDate: DateTime, $brandId: String!, $photos: [String!]!, $files: JSON, $disabled: Boolean, $categoryId: String, $title: String!, $description: String, $shortDescription: String, $language: String!, $buttonText: String, $buttonUrl: String, $order: Int) {
  createProduct(
    data: {sku: $sku, order: $order, prices: {create: {price: $price, salesPrice: $salesPrice, minimumQuantity: $minimumQuantity, expiryDate: $expiryDate}}, brand: {connect: {id: $brandId}}, photos: {set: $photos}, files: $files, buyLink: $buyLink, disabled: $disabled, category: {connect: {id: $categoryId}}}
    description: {title: $title, description: $description, shortDescription: $shortDescription, language: $language, buttonText: $buttonText, buttonUrl: $buttonUrl, seoPoint: 0}
  ) {
    id
    createdAt
    updatedAt
    sku
  }
}
    `;
export type CreateProductMutationFn = Apollo.MutationFunction<CreateProductMutation, CreateProductMutationVariables>;

/**
 * __useCreateProductMutation__
 *
 * To run a mutation, you first call `useCreateProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductMutation, { data, loading, error }] = useCreateProductMutation({
 *   variables: {
 *      sku: // value for 'sku'
 *      price: // value for 'price'
 *      salesPrice: // value for 'salesPrice'
 *      minimumQuantity: // value for 'minimumQuantity'
 *      buyLink: // value for 'buyLink'
 *      expiryDate: // value for 'expiryDate'
 *      brandId: // value for 'brandId'
 *      photos: // value for 'photos'
 *      files: // value for 'files'
 *      disabled: // value for 'disabled'
 *      categoryId: // value for 'categoryId'
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      shortDescription: // value for 'shortDescription'
 *      language: // value for 'language'
 *      buttonText: // value for 'buttonText'
 *      buttonUrl: // value for 'buttonUrl'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useCreateProductMutation(baseOptions?: Apollo.MutationHookOptions<CreateProductMutation, CreateProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProductMutation, CreateProductMutationVariables>(CreateProductDocument, options);
      }
export type CreateProductMutationHookResult = ReturnType<typeof useCreateProductMutation>;
export type CreateProductMutationResult = Apollo.MutationResult<CreateProductMutation>;
export type CreateProductMutationOptions = Apollo.BaseMutationOptions<CreateProductMutation, CreateProductMutationVariables>;
export const CreateProductWithoutCategoryDocument = gql`
    mutation createProductWithoutCategory($sku: String!, $price: Int!, $salesPrice: Int!, $minimumQuantity: Int, $expiryDate: DateTime, $brandId: String!, $photos: [String!]!, $buyLink: String, $files: JSON, $disabled: Boolean, $title: String!, $description: String, $shortDescription: String, $language: String!, $buttonText: String, $buttonUrl: String) {
  createProduct(
    data: {sku: $sku, prices: {create: {price: $price, salesPrice: $salesPrice, minimumQuantity: $minimumQuantity, expiryDate: $expiryDate}}, buyLink: $buyLink, brand: {connect: {id: $brandId}}, photos: {set: $photos}, files: $files, disabled: $disabled}
    description: {title: $title, description: $description, shortDescription: $shortDescription, language: $language, buttonText: $buttonText, buttonUrl: $buttonUrl, seoPoint: 0}
  ) {
    id
    createdAt
    updatedAt
    sku
  }
}
    `;
export type CreateProductWithoutCategoryMutationFn = Apollo.MutationFunction<CreateProductWithoutCategoryMutation, CreateProductWithoutCategoryMutationVariables>;

/**
 * __useCreateProductWithoutCategoryMutation__
 *
 * To run a mutation, you first call `useCreateProductWithoutCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductWithoutCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductWithoutCategoryMutation, { data, loading, error }] = useCreateProductWithoutCategoryMutation({
 *   variables: {
 *      sku: // value for 'sku'
 *      price: // value for 'price'
 *      salesPrice: // value for 'salesPrice'
 *      minimumQuantity: // value for 'minimumQuantity'
 *      expiryDate: // value for 'expiryDate'
 *      brandId: // value for 'brandId'
 *      photos: // value for 'photos'
 *      buyLink: // value for 'buyLink'
 *      files: // value for 'files'
 *      disabled: // value for 'disabled'
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      shortDescription: // value for 'shortDescription'
 *      language: // value for 'language'
 *      buttonText: // value for 'buttonText'
 *      buttonUrl: // value for 'buttonUrl'
 *   },
 * });
 */
export function useCreateProductWithoutCategoryMutation(baseOptions?: Apollo.MutationHookOptions<CreateProductWithoutCategoryMutation, CreateProductWithoutCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProductWithoutCategoryMutation, CreateProductWithoutCategoryMutationVariables>(CreateProductWithoutCategoryDocument, options);
      }
export type CreateProductWithoutCategoryMutationHookResult = ReturnType<typeof useCreateProductWithoutCategoryMutation>;
export type CreateProductWithoutCategoryMutationResult = Apollo.MutationResult<CreateProductWithoutCategoryMutation>;
export type CreateProductWithoutCategoryMutationOptions = Apollo.BaseMutationOptions<CreateProductWithoutCategoryMutation, CreateProductWithoutCategoryMutationVariables>;
export const CreateProductContentDocument = gql`
    mutation createProductContent($productId: String!, $photos: [String!]!, $videos: String, $contentType: ContentType!, $mediaType: MediaType!, $embedCode: String, $longitude: Float, $latitude: Float, $source: String, $cards: JSON!, $slides: JSON, $timeline: JSON, $backgroundImage: String, $theme: ThemeType, $productCategoryId: String, $blogCategoryId: String, $highlightedProducts: [IDField!], $lineUp: ContentLineup!, $title: String!, $description: String, $shortDescription: String, $language: String!, $buttonText: String, $buttonUrl: String) {
  createContent(
    data: {product: {connect: {id: $productId}}, photos: {set: $photos}, videos: $videos, contentType: $contentType, mediaType: $mediaType, embedCode: $embedCode, longitude: $longitude, latitude: $latitude, source: $source, cards: $cards, slides: $slides, timeline: $timeline, backgroundImage: $backgroundImage, theme: $theme, productCategoryId: $productCategoryId, blogCategoryId: $blogCategoryId, products: $highlightedProducts, lineUp: $lineUp, description: {create: {title: $title, description: $description, shortDescription: $shortDescription, language: $language, buttonText: $buttonText, buttonUrl: $buttonUrl, seoPoint: 0}}}
  ) {
    id
    updatedAt
    createdAt
  }
}
    `;
export type CreateProductContentMutationFn = Apollo.MutationFunction<CreateProductContentMutation, CreateProductContentMutationVariables>;

/**
 * __useCreateProductContentMutation__
 *
 * To run a mutation, you first call `useCreateProductContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductContentMutation, { data, loading, error }] = useCreateProductContentMutation({
 *   variables: {
 *      productId: // value for 'productId'
 *      photos: // value for 'photos'
 *      videos: // value for 'videos'
 *      contentType: // value for 'contentType'
 *      mediaType: // value for 'mediaType'
 *      embedCode: // value for 'embedCode'
 *      longitude: // value for 'longitude'
 *      latitude: // value for 'latitude'
 *      source: // value for 'source'
 *      cards: // value for 'cards'
 *      slides: // value for 'slides'
 *      timeline: // value for 'timeline'
 *      backgroundImage: // value for 'backgroundImage'
 *      theme: // value for 'theme'
 *      productCategoryId: // value for 'productCategoryId'
 *      blogCategoryId: // value for 'blogCategoryId'
 *      highlightedProducts: // value for 'highlightedProducts'
 *      lineUp: // value for 'lineUp'
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      shortDescription: // value for 'shortDescription'
 *      language: // value for 'language'
 *      buttonText: // value for 'buttonText'
 *      buttonUrl: // value for 'buttonUrl'
 *   },
 * });
 */
export function useCreateProductContentMutation(baseOptions?: Apollo.MutationHookOptions<CreateProductContentMutation, CreateProductContentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProductContentMutation, CreateProductContentMutationVariables>(CreateProductContentDocument, options);
      }
export type CreateProductContentMutationHookResult = ReturnType<typeof useCreateProductContentMutation>;
export type CreateProductContentMutationResult = Apollo.MutationResult<CreateProductContentMutation>;
export type CreateProductContentMutationOptions = Apollo.BaseMutationOptions<CreateProductContentMutation, CreateProductContentMutationVariables>;
export const CreateProductContentWithoutBgDocument = gql`
    mutation createProductContentWithoutBg($productId: String!, $photos: [String!]!, $videos: String, $contentType: ContentType!, $mediaType: MediaType!, $embedCode: String, $longitude: Float, $latitude: Float, $source: String, $cards: JSON!, $slides: JSON, $timeline: JSON, $theme: ThemeType, $productCategoryId: String, $blogCategoryId: String, $highlightedProducts: [IDField!], $lineUp: ContentLineup!, $title: String!, $description: String, $shortDescription: String, $language: String!, $buttonText: String, $buttonUrl: String) {
  createContent(
    data: {product: {connect: {id: $productId}}, photos: {set: $photos}, videos: $videos, contentType: $contentType, mediaType: $mediaType, embedCode: $embedCode, longitude: $longitude, latitude: $latitude, source: $source, cards: $cards, slides: $slides, timeline: $timeline, theme: $theme, productCategoryId: $productCategoryId, blogCategoryId: $blogCategoryId, products: $highlightedProducts, lineUp: $lineUp, description: {create: {title: $title, description: $description, shortDescription: $shortDescription, language: $language, buttonText: $buttonText, buttonUrl: $buttonUrl, seoPoint: 0}}}
  ) {
    id
    updatedAt
    createdAt
  }
}
    `;
export type CreateProductContentWithoutBgMutationFn = Apollo.MutationFunction<CreateProductContentWithoutBgMutation, CreateProductContentWithoutBgMutationVariables>;

/**
 * __useCreateProductContentWithoutBgMutation__
 *
 * To run a mutation, you first call `useCreateProductContentWithoutBgMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductContentWithoutBgMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductContentWithoutBgMutation, { data, loading, error }] = useCreateProductContentWithoutBgMutation({
 *   variables: {
 *      productId: // value for 'productId'
 *      photos: // value for 'photos'
 *      videos: // value for 'videos'
 *      contentType: // value for 'contentType'
 *      mediaType: // value for 'mediaType'
 *      embedCode: // value for 'embedCode'
 *      longitude: // value for 'longitude'
 *      latitude: // value for 'latitude'
 *      source: // value for 'source'
 *      cards: // value for 'cards'
 *      slides: // value for 'slides'
 *      timeline: // value for 'timeline'
 *      theme: // value for 'theme'
 *      productCategoryId: // value for 'productCategoryId'
 *      blogCategoryId: // value for 'blogCategoryId'
 *      highlightedProducts: // value for 'highlightedProducts'
 *      lineUp: // value for 'lineUp'
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      shortDescription: // value for 'shortDescription'
 *      language: // value for 'language'
 *      buttonText: // value for 'buttonText'
 *      buttonUrl: // value for 'buttonUrl'
 *   },
 * });
 */
export function useCreateProductContentWithoutBgMutation(baseOptions?: Apollo.MutationHookOptions<CreateProductContentWithoutBgMutation, CreateProductContentWithoutBgMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProductContentWithoutBgMutation, CreateProductContentWithoutBgMutationVariables>(CreateProductContentWithoutBgDocument, options);
      }
export type CreateProductContentWithoutBgMutationHookResult = ReturnType<typeof useCreateProductContentWithoutBgMutation>;
export type CreateProductContentWithoutBgMutationResult = Apollo.MutationResult<CreateProductContentWithoutBgMutation>;
export type CreateProductContentWithoutBgMutationOptions = Apollo.BaseMutationOptions<CreateProductContentWithoutBgMutation, CreateProductContentWithoutBgMutationVariables>;
export const DeleteProductDocument = gql`
    mutation deleteProduct($id: ID!) {
  deleteProduct(id: $id)
}
    `;
export type DeleteProductMutationFn = Apollo.MutationFunction<DeleteProductMutation, DeleteProductMutationVariables>;

/**
 * __useDeleteProductMutation__
 *
 * To run a mutation, you first call `useDeleteProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProductMutation, { data, loading, error }] = useDeleteProductMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteProductMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProductMutation, DeleteProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteProductMutation, DeleteProductMutationVariables>(DeleteProductDocument, options);
      }
export type DeleteProductMutationHookResult = ReturnType<typeof useDeleteProductMutation>;
export type DeleteProductMutationResult = Apollo.MutationResult<DeleteProductMutation>;
export type DeleteProductMutationOptions = Apollo.BaseMutationOptions<DeleteProductMutation, DeleteProductMutationVariables>;
export const OrderProductDocument = gql`
    mutation orderProduct($id: String!, $order: Int!) {
  updateProduct(where: {id: $id}, data: {order: {set: $order}}) {
    id
  }
}
    `;
export type OrderProductMutationFn = Apollo.MutationFunction<OrderProductMutation, OrderProductMutationVariables>;

/**
 * __useOrderProductMutation__
 *
 * To run a mutation, you first call `useOrderProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderProductMutation, { data, loading, error }] = useOrderProductMutation({
 *   variables: {
 *      id: // value for 'id'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useOrderProductMutation(baseOptions?: Apollo.MutationHookOptions<OrderProductMutation, OrderProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OrderProductMutation, OrderProductMutationVariables>(OrderProductDocument, options);
      }
export type OrderProductMutationHookResult = ReturnType<typeof useOrderProductMutation>;
export type OrderProductMutationResult = Apollo.MutationResult<OrderProductMutation>;
export type OrderProductMutationOptions = Apollo.BaseMutationOptions<OrderProductMutation, OrderProductMutationVariables>;
export const GetProductsByCategoryIdDocument = gql`
    query getProductsByCategoryId($categoryId: String!) {
  products(where: {categoryId: {equals: $categoryId}}, orderBy: {order: asc}) {
    total
    products {
      id
      createdAt
      updatedAt
      sku
      prices {
        id
        price
        salesPrice
      }
      brand {
        id
        title
      }
      category {
        id
        descriptions {
          id
          title
        }
      }
      descriptions {
        id
        updatedAt
        createdAt
        title
        description
        shortDescription
        slug
        buttonText
        buttonUrl
        language
        metaTitle
        metaDescription
        metaKeywords
        seoPoint
      }
    }
  }
}
    `;

/**
 * __useGetProductsByCategoryIdQuery__
 *
 * To run a query within a React component, call `useGetProductsByCategoryIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductsByCategoryIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductsByCategoryIdQuery({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *   },
 * });
 */
export function useGetProductsByCategoryIdQuery(baseOptions: Apollo.QueryHookOptions<GetProductsByCategoryIdQuery, GetProductsByCategoryIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductsByCategoryIdQuery, GetProductsByCategoryIdQueryVariables>(GetProductsByCategoryIdDocument, options);
      }
export function useGetProductsByCategoryIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductsByCategoryIdQuery, GetProductsByCategoryIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductsByCategoryIdQuery, GetProductsByCategoryIdQueryVariables>(GetProductsByCategoryIdDocument, options);
        }
export type GetProductsByCategoryIdQueryHookResult = ReturnType<typeof useGetProductsByCategoryIdQuery>;
export type GetProductsByCategoryIdLazyQueryHookResult = ReturnType<typeof useGetProductsByCategoryIdLazyQuery>;
export type GetProductsByCategoryIdQueryResult = Apollo.QueryResult<GetProductsByCategoryIdQuery, GetProductsByCategoryIdQueryVariables>;
export const GetProductDocument = gql`
    query getProduct($id: String!) {
  product(where: {id: {equals: $id}}) {
    id
    createdAt
    updatedAt
    sku
    buyLink
    disabled
    photos
    files
    categoryId
    order
    category {
      id
      parentId
    }
    brandId
    prices {
      id
      price
      salesPrice
      minimumQuantity
      expiryDate
    }
    descriptions {
      id
      updatedAt
      createdAt
      title
      description
      shortDescription
      slug
      buttonText
      buttonUrl
      language
      metaTitle
      metaDescription
      metaKeywords
      seoPoint
    }
  }
}
    `;

/**
 * __useGetProductQuery__
 *
 * To run a query within a React component, call `useGetProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProductQuery(baseOptions: Apollo.QueryHookOptions<GetProductQuery, GetProductQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductQuery, GetProductQueryVariables>(GetProductDocument, options);
      }
export function useGetProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductQuery, GetProductQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductQuery, GetProductQueryVariables>(GetProductDocument, options);
        }
export type GetProductQueryHookResult = ReturnType<typeof useGetProductQuery>;
export type GetProductLazyQueryHookResult = ReturnType<typeof useGetProductLazyQuery>;
export type GetProductQueryResult = Apollo.QueryResult<GetProductQuery, GetProductQueryVariables>;
export const GetProductsDocument = gql`
    query getProducts($where: ProductWhereInput, $take: Int, $skip: Int) {
  products(
    orderBy: [{categoryId: asc}, {order: asc}]
    where: $where
    take: $take
    skip: $skip
  ) {
    total
    products {
      id
      createdAt
      updatedAt
      sku
      buyLink
      order
      category {
        id
        descriptions {
          id
          title
          language
        }
      }
      prices {
        id
        price
        salesPrice
      }
      brand {
        title
      }
      content {
        id
      }
      readerComments {
        id
        createdAt
        updatedAt
      }
      descriptions {
        id
        updatedAt
        createdAt
        title
        description
        shortDescription
        slug
        buttonText
        buttonUrl
        language
        metaTitle
        metaDescription
        metaKeywords
        seoPoint
      }
    }
  }
}
    `;

/**
 * __useGetProductsQuery__
 *
 * To run a query within a React component, call `useGetProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetProductsQuery(baseOptions?: Apollo.QueryHookOptions<GetProductsQuery, GetProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductsQuery, GetProductsQueryVariables>(GetProductsDocument, options);
      }
export function useGetProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductsQuery, GetProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductsQuery, GetProductsQueryVariables>(GetProductsDocument, options);
        }
export type GetProductsQueryHookResult = ReturnType<typeof useGetProductsQuery>;
export type GetProductsLazyQueryHookResult = ReturnType<typeof useGetProductsLazyQuery>;
export type GetProductsQueryResult = Apollo.QueryResult<GetProductsQuery, GetProductsQueryVariables>;
export const UpdateProductDocument = gql`
    mutation updateProduct($id: String!, $priceId: String!, $sku: String!, $price: Int!, $buyLink: String, $salesPrice: Int!, $minimumQuantity: Int, $expiryDate: DateTime, $brandId: String!, $photos: [String!]!, $files: JSON, $disabled: Boolean, $categoryId: String, $order: Int) {
  updateProduct(
    where: {id: $id}
    data: {sku: {set: $sku}, buyLink: {set: $buyLink}, order: {set: $order}, prices: {update: {where: {id: $priceId}, data: {price: {set: $price}, salesPrice: {set: $salesPrice}, minimumQuantity: {set: $minimumQuantity}, expiryDate: {set: $expiryDate}}}}, brand: {connect: {id: $brandId}}, photos: {set: $photos}, files: $files, disabled: {set: $disabled}, category: {connect: {id: $categoryId}}}
  ) {
    id
    createdAt
    updatedAt
  }
}
    `;
export type UpdateProductMutationFn = Apollo.MutationFunction<UpdateProductMutation, UpdateProductMutationVariables>;

/**
 * __useUpdateProductMutation__
 *
 * To run a mutation, you first call `useUpdateProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductMutation, { data, loading, error }] = useUpdateProductMutation({
 *   variables: {
 *      id: // value for 'id'
 *      priceId: // value for 'priceId'
 *      sku: // value for 'sku'
 *      price: // value for 'price'
 *      buyLink: // value for 'buyLink'
 *      salesPrice: // value for 'salesPrice'
 *      minimumQuantity: // value for 'minimumQuantity'
 *      expiryDate: // value for 'expiryDate'
 *      brandId: // value for 'brandId'
 *      photos: // value for 'photos'
 *      files: // value for 'files'
 *      disabled: // value for 'disabled'
 *      categoryId: // value for 'categoryId'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useUpdateProductMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProductMutation, UpdateProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProductMutation, UpdateProductMutationVariables>(UpdateProductDocument, options);
      }
export type UpdateProductMutationHookResult = ReturnType<typeof useUpdateProductMutation>;
export type UpdateProductMutationResult = Apollo.MutationResult<UpdateProductMutation>;
export type UpdateProductMutationOptions = Apollo.BaseMutationOptions<UpdateProductMutation, UpdateProductMutationVariables>;
export const UpdateProductWithoutCategoryDocument = gql`
    mutation updateProductWithoutCategory($id: String!, $priceId: String!, $sku: String!, $price: Int!, $salesPrice: Int!, $minimumQuantity: Int, $buyLink: String, $expiryDate: DateTime, $brandId: String!, $photos: [String!]!, $files: JSON, $disabled: Boolean) {
  updateProduct(
    where: {id: $id}
    data: {sku: {set: $sku}, buyLink: {set: $buyLink}, prices: {update: {where: {id: $priceId}, data: {price: {set: $price}, salesPrice: {set: $salesPrice}, minimumQuantity: {set: $minimumQuantity}, expiryDate: {set: $expiryDate}}}}, brand: {connect: {id: $brandId}}, photos: {set: $photos}, files: $files, disabled: {set: $disabled}}
  ) {
    id
    createdAt
    updatedAt
  }
}
    `;
export type UpdateProductWithoutCategoryMutationFn = Apollo.MutationFunction<UpdateProductWithoutCategoryMutation, UpdateProductWithoutCategoryMutationVariables>;

/**
 * __useUpdateProductWithoutCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateProductWithoutCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductWithoutCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductWithoutCategoryMutation, { data, loading, error }] = useUpdateProductWithoutCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      priceId: // value for 'priceId'
 *      sku: // value for 'sku'
 *      price: // value for 'price'
 *      salesPrice: // value for 'salesPrice'
 *      minimumQuantity: // value for 'minimumQuantity'
 *      buyLink: // value for 'buyLink'
 *      expiryDate: // value for 'expiryDate'
 *      brandId: // value for 'brandId'
 *      photos: // value for 'photos'
 *      files: // value for 'files'
 *      disabled: // value for 'disabled'
 *   },
 * });
 */
export function useUpdateProductWithoutCategoryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProductWithoutCategoryMutation, UpdateProductWithoutCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProductWithoutCategoryMutation, UpdateProductWithoutCategoryMutationVariables>(UpdateProductWithoutCategoryDocument, options);
      }
export type UpdateProductWithoutCategoryMutationHookResult = ReturnType<typeof useUpdateProductWithoutCategoryMutation>;
export type UpdateProductWithoutCategoryMutationResult = Apollo.MutationResult<UpdateProductWithoutCategoryMutation>;
export type UpdateProductWithoutCategoryMutationOptions = Apollo.BaseMutationOptions<UpdateProductWithoutCategoryMutation, UpdateProductWithoutCategoryMutationVariables>;
export const UpdateProductDisconnectCategoryDocument = gql`
    mutation updateProductDisconnectCategory($id: String!, $priceId: String!, $sku: String!, $price: Int!, $salesPrice: Int!, $buyLink: String, $minimumQuantity: Int, $expiryDate: DateTime, $brandId: String!, $photos: [String!]!, $files: JSON, $disabled: Boolean) {
  updateProduct(
    where: {id: $id}
    data: {sku: {set: $sku}, buyLink: {set: $buyLink}, prices: {update: {where: {id: $priceId}, data: {price: {set: $price}, salesPrice: {set: $salesPrice}, minimumQuantity: {set: $minimumQuantity}, expiryDate: {set: $expiryDate}}}}, brand: {connect: {id: $brandId}}, photos: {set: $photos}, files: $files, disabled: {set: $disabled}, category: {disconnect: true}}
  ) {
    id
    createdAt
    updatedAt
  }
}
    `;
export type UpdateProductDisconnectCategoryMutationFn = Apollo.MutationFunction<UpdateProductDisconnectCategoryMutation, UpdateProductDisconnectCategoryMutationVariables>;

/**
 * __useUpdateProductDisconnectCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateProductDisconnectCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductDisconnectCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductDisconnectCategoryMutation, { data, loading, error }] = useUpdateProductDisconnectCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      priceId: // value for 'priceId'
 *      sku: // value for 'sku'
 *      price: // value for 'price'
 *      salesPrice: // value for 'salesPrice'
 *      buyLink: // value for 'buyLink'
 *      minimumQuantity: // value for 'minimumQuantity'
 *      expiryDate: // value for 'expiryDate'
 *      brandId: // value for 'brandId'
 *      photos: // value for 'photos'
 *      files: // value for 'files'
 *      disabled: // value for 'disabled'
 *   },
 * });
 */
export function useUpdateProductDisconnectCategoryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProductDisconnectCategoryMutation, UpdateProductDisconnectCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProductDisconnectCategoryMutation, UpdateProductDisconnectCategoryMutationVariables>(UpdateProductDisconnectCategoryDocument, options);
      }
export type UpdateProductDisconnectCategoryMutationHookResult = ReturnType<typeof useUpdateProductDisconnectCategoryMutation>;
export type UpdateProductDisconnectCategoryMutationResult = Apollo.MutationResult<UpdateProductDisconnectCategoryMutation>;
export type UpdateProductDisconnectCategoryMutationOptions = Apollo.BaseMutationOptions<UpdateProductDisconnectCategoryMutation, UpdateProductDisconnectCategoryMutationVariables>;
export const GetAppSettingsDocument = gql`
    query getAppSettings {
  appSettings {
    id
    appName
    domain
    language
    logoFile
    timeZone
    dateFormat
    timeFormat
  }
}
    `;

/**
 * __useGetAppSettingsQuery__
 *
 * To run a query within a React component, call `useGetAppSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAppSettingsQuery(baseOptions?: Apollo.QueryHookOptions<GetAppSettingsQuery, GetAppSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAppSettingsQuery, GetAppSettingsQueryVariables>(GetAppSettingsDocument, options);
      }
export function useGetAppSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAppSettingsQuery, GetAppSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAppSettingsQuery, GetAppSettingsQueryVariables>(GetAppSettingsDocument, options);
        }
export type GetAppSettingsQueryHookResult = ReturnType<typeof useGetAppSettingsQuery>;
export type GetAppSettingsLazyQueryHookResult = ReturnType<typeof useGetAppSettingsLazyQuery>;
export type GetAppSettingsQueryResult = Apollo.QueryResult<GetAppSettingsQuery, GetAppSettingsQueryVariables>;
export const UpdateAppSettingsDocument = gql`
    mutation updateAppSettings($data: AppSettingsUpdateInput!) {
  updateAppSettings(data: $data) {
    id
    appName
  }
}
    `;
export type UpdateAppSettingsMutationFn = Apollo.MutationFunction<UpdateAppSettingsMutation, UpdateAppSettingsMutationVariables>;

/**
 * __useUpdateAppSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateAppSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAppSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAppSettingsMutation, { data, loading, error }] = useUpdateAppSettingsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateAppSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAppSettingsMutation, UpdateAppSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAppSettingsMutation, UpdateAppSettingsMutationVariables>(UpdateAppSettingsDocument, options);
      }
export type UpdateAppSettingsMutationHookResult = ReturnType<typeof useUpdateAppSettingsMutation>;
export type UpdateAppSettingsMutationResult = Apollo.MutationResult<UpdateAppSettingsMutation>;
export type UpdateAppSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateAppSettingsMutation, UpdateAppSettingsMutationVariables>;
export const GetEcommerceSettingsDocument = gql`
    query getEcommerceSettings {
  ecommerceSettings {
    id
    active
    deliveryRadius
    currencyCode
    currencySymbol
    currencyFormat
    amountSeparator
    decimalNumber
    defaultShippingCountry
    defaultShippingState
    defaultShippingCity
    defaultProductSorting
    productsLimit
    weightUnit
    lengthUnit
    hideBillingAddress
    orderConfirmationCopyTo
  }
}
    `;

/**
 * __useGetEcommerceSettingsQuery__
 *
 * To run a query within a React component, call `useGetEcommerceSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEcommerceSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEcommerceSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEcommerceSettingsQuery(baseOptions?: Apollo.QueryHookOptions<GetEcommerceSettingsQuery, GetEcommerceSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEcommerceSettingsQuery, GetEcommerceSettingsQueryVariables>(GetEcommerceSettingsDocument, options);
      }
export function useGetEcommerceSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEcommerceSettingsQuery, GetEcommerceSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEcommerceSettingsQuery, GetEcommerceSettingsQueryVariables>(GetEcommerceSettingsDocument, options);
        }
export type GetEcommerceSettingsQueryHookResult = ReturnType<typeof useGetEcommerceSettingsQuery>;
export type GetEcommerceSettingsLazyQueryHookResult = ReturnType<typeof useGetEcommerceSettingsLazyQuery>;
export type GetEcommerceSettingsQueryResult = Apollo.QueryResult<GetEcommerceSettingsQuery, GetEcommerceSettingsQueryVariables>;
export const UpdateEcommerceSettingsDocument = gql`
    mutation updateEcommerceSettings($data: EcommerceSettingsUpdateInput!) {
  updateEcommerceSettings(data: $data) {
    active
    deliveryRadius
    currencyCode
    currencySymbol
    currencyFormat
    amountSeparator
    decimalNumber
    defaultShippingCountry
    defaultShippingState
    defaultShippingCity
    defaultProductSorting
    productsLimit
    weightUnit
    lengthUnit
    hideBillingAddress
    orderConfirmationCopyTo
  }
}
    `;
export type UpdateEcommerceSettingsMutationFn = Apollo.MutationFunction<UpdateEcommerceSettingsMutation, UpdateEcommerceSettingsMutationVariables>;

/**
 * __useUpdateEcommerceSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateEcommerceSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEcommerceSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEcommerceSettingsMutation, { data, loading, error }] = useUpdateEcommerceSettingsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateEcommerceSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEcommerceSettingsMutation, UpdateEcommerceSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEcommerceSettingsMutation, UpdateEcommerceSettingsMutationVariables>(UpdateEcommerceSettingsDocument, options);
      }
export type UpdateEcommerceSettingsMutationHookResult = ReturnType<typeof useUpdateEcommerceSettingsMutation>;
export type UpdateEcommerceSettingsMutationResult = Apollo.MutationResult<UpdateEcommerceSettingsMutation>;
export type UpdateEcommerceSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateEcommerceSettingsMutation, UpdateEcommerceSettingsMutationVariables>;
export const CreateUserDocument = gql`
    mutation createUser($data: UserCreateInput!) {
  createUser(data: $data) {
    id
    identityNumber
    name
  }
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const DeleteUserDocument = gql`
    mutation deleteUser($id: ID!) {
  deleteUser(id: $id)
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const LoginDocument = gql`
    mutation login($email: String!, $password: String!) {
  login(data: {email: $email, password: $password}) {
    accessToken
    refreshToken
    id
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const LogoutDocument = gql`
    mutation logout {
  logout
}
    `;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const MeDocument = gql`
    query me {
  me {
    id
    identityNumber
    name
    surname
    email
    phoneNumber
    gender
    emailActivated
    phoneActivated
    status
    role
    updatedAt
    createdAt
    addresses {
      id
      updatedAt
      title
      postalCode
    }
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const CheckRefreshTokenDocument = gql`
    mutation checkRefreshToken($refreshToken: String!) {
  checkRefreshToken(refreshToken: $refreshToken) {
    accessToken
    refreshToken
    id
  }
}
    `;
export type CheckRefreshTokenMutationFn = Apollo.MutationFunction<CheckRefreshTokenMutation, CheckRefreshTokenMutationVariables>;

/**
 * __useCheckRefreshTokenMutation__
 *
 * To run a mutation, you first call `useCheckRefreshTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckRefreshTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkRefreshTokenMutation, { data, loading, error }] = useCheckRefreshTokenMutation({
 *   variables: {
 *      refreshToken: // value for 'refreshToken'
 *   },
 * });
 */
export function useCheckRefreshTokenMutation(baseOptions?: Apollo.MutationHookOptions<CheckRefreshTokenMutation, CheckRefreshTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CheckRefreshTokenMutation, CheckRefreshTokenMutationVariables>(CheckRefreshTokenDocument, options);
      }
export type CheckRefreshTokenMutationHookResult = ReturnType<typeof useCheckRefreshTokenMutation>;
export type CheckRefreshTokenMutationResult = Apollo.MutationResult<CheckRefreshTokenMutation>;
export type CheckRefreshTokenMutationOptions = Apollo.BaseMutationOptions<CheckRefreshTokenMutation, CheckRefreshTokenMutationVariables>;
export const UpdateUserDocument = gql`
    mutation updateUser($where: UserWhereUniqueInput!, $data: UserUpdateInput!) {
  updateUser(where: $where, data: $data) {
    id
    email
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const GetUserDocument = gql`
    query getUser($where: UserWhereUniqueInput!) {
  user(where: $where) {
    id
    identityNumber
    name
    surname
    email
    phoneNumber
    gender
    emailActivated
    phoneActivated
    status
    role
    updatedAt
    createdAt
  }
}
    `;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetUserQuery(baseOptions: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const GetUsersDocument = gql`
    query getUsers {
  users(orderBy: {createdAt: desc}) {
    id
    identityNumber
    name
    surname
    email
    phoneNumber
    gender
    emailActivated
    phoneActivated
    status
    role
    updatedAt
    createdAt
  }
}
    `;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
      }
export function useGetUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
        }
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersQueryResult = Apollo.QueryResult<GetUsersQuery, GetUsersQueryVariables>;