import {
  Input,
  FormControl,
  FormLabel,
  Box,
  Text,
  VStack,
  Button,
  FormHelperText,
  Flex,
  Image,
  Divider,
  IconButton,
  Checkbox,
} from '@chakra-ui/react';
import { useState } from 'react';
import { BsTrash } from 'react-icons/bs';
import { useHistory } from 'react-router-dom';
import ContentDescription from '../../components/ContentDescription';
import MessageBox from '../../components/MessageBox';

import {
  useCreateBlogCategoryMutation,
  useCreateBlogCategoryWithoutThumbnailMutation,
  useUploadFileMutation,
} from '../../generated/graphql';

const Add = () => {
  const { push } = useHistory();

  const [logo, setLogo] = useState<any>('');
  const [logoPreview, setLogoPreview] = useState('');
  const [hidden, setHidden] = useState(false);

  // description inputs
  const [title, setTitle] = useState('');
  const [language] = useState('tr');
  const [description, setDescription] = useState('');
  const [shortDescription, setShortDescription] = useState('');
  const [buttonText, setButtonText] = useState('');
  const [buttonUrl, setButtonUrl] = useState('');

  const [error, setError] = useState('');

  const [uploadPhoto, { loading: uploadLoading }] = useUploadFileMutation();
  const [createBlogCategory, { loading }] = useCreateBlogCategoryMutation();
  const [
    createBlogCategoryWithoutThumbnail,
    { loading: loadingWithoutThumbnail },
  ] = useCreateBlogCategoryWithoutThumbnailMutation();

  const handleImg = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setLogo(e.target.files[0]);
      setLogoPreview(URL.createObjectURL(e.target.files[0]));
    }
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      if (logo) {
        const uploadResponse = await uploadPhoto({
          variables: { file: logo },
        });
        await createBlogCategory({
          variables: {
            thumbnail: (uploadResponse.data.uploadFile as any).file,
            hidden,

            title,
            description,
            shortDescription,
            language,
            buttonText,
            buttonUrl,
          },
        });
      } else {
        await createBlogCategoryWithoutThumbnail({
          variables: {
            hidden,
            title,
            description,
            shortDescription,
            language,
            buttonText,
            buttonUrl,
          },
        });
      }
      setError('');
      push('/blogcategories');
    } catch (error) {
      console.log('err ::', error);
      setError(error.message);
    }
  };

  return (
    <>
      <VStack align='flex-start' mb='4'>
        <Text fontWeight='bold' fontSize='2xl'>
          Yeni Blog Kategorisi
        </Text>
      </VStack>
      <form onSubmit={onSubmit}>
        <Box>
          {error && <MessageBox status='warning' message={error} />}
          <Flex>
            <Box mr='8'>
              <FormControl>
                <FormLabel>Kapak resmi (opsiyonel)</FormLabel>
                <Input type='file' multiple={false} onChange={handleImg} />
                <FormHelperText>
                  Eğer isterseniz blog kategorisine kapak resmi
                  ekleyebilirsiniz.
                </FormHelperText>
              </FormControl>
            </Box>
            <Box boxSize='150px' position='relative'>
              {logoPreview && (
                <>
                  <IconButton
                    position='absolute'
                    bottom='0'
                    right='0'
                    colorScheme='yellow'
                    aria-label='delete image'
                    icon={<BsTrash size='24' />}
                    onClick={() => {
                      setLogo('');
                      setLogoPreview('');
                    }}
                  />
                  <Image
                    src={logoPreview}
                    rounded='sm'
                    objectFit='cover'
                    boxSize='150px'
                  />
                </>
              )}
            </Box>
          </Flex>
          <Box w='50%'>
            <FormControl ml='auto'>
              <FormLabel>Durum</FormLabel>
              <Checkbox
                colorScheme='red'
                isChecked={hidden}
                onChange={() => setHidden((prev) => !prev)}
              >
                Gizle
              </Checkbox>
              <FormHelperText>
                Gizle işaretlendiğinde bu kategoriye ait makaleler listelenmez.
                Sadece içerik olarak seçildiğinde sayfada gösterilir.
              </FormHelperText>
            </FormControl>
          </Box>

          <Divider my='6' />
          <ContentDescription
            title={title}
            setTitle={setTitle}
            description={description}
            setDescription={setDescription}
            shortDescription={shortDescription}
            setShortDescription={setShortDescription}
            buttonText={buttonText}
            setButtonText={setButtonText}
            buttonUrl={buttonUrl}
            setButtonUrl={setButtonUrl}
          />

          <Button
            colorScheme='blue'
            type='submit'
            mt='10px'
            isLoading={loading || uploadLoading || loadingWithoutThumbnail}
          >
            Kaydet
          </Button>
        </Box>
      </form>
    </>
  );
};

export default Add;
