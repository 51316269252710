import {
  FormControl,
  FormLabel,
  Box,
  Button,
  Select,
  FormHelperText,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import MessageBox from '../../components/MessageBox';
import UploadDropzone from '../../components/UploadDropzone';
import {
  useUpdateCategoryMutation,
  useUpdateCategoryWithoutParentMutation,
  GetCategoryQueryResult,
  useGetCategoriesQuery,
} from '../../generated/graphql';

interface Props {
  data: GetCategoryQueryResult['data']['category'];
}

const EditForm: React.FC<Props> = ({ data }) => {
  const { id } = useParams<{ id: string }>();
  const { push } = useHistory();
  const { data: categoriesData } = useGetCategoriesQuery({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const [parentCategoryId, setParentCategoryId] = useState<string | undefined>(
    data.parentId ? data.parentId : undefined
  );
  const [photos, setPhotos] = useState(data.photos ? data.photos : []);

  const [error, setError] = useState('');

  const [updateCategory, { loading }] = useUpdateCategoryMutation();
  const [
    updateCategoryWithoutParent,
    { loading: loadingWithoutParent },
  ] = useUpdateCategoryWithoutParentMutation();

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      if (!parentCategoryId) {
        await updateCategoryWithoutParent({
          variables: {
            id,
            photos,
          },
        });
      } else {
        await updateCategory({
          variables: {
            id,
            parentCategoryId,
            photos,
          },
        });
      }
      setError('');
      push(`/categories`);
    } catch (error) {
      console.log('err ⚠️', error);
      setError(error.message);
    }
  };

  return (
    <form onSubmit={onSubmit}>
      <Box width='full' mb='8'>
        {error && <MessageBox status='warning' message={error} />}

        <FormControl mb='4'>
          <UploadDropzone images={photos} setImageArray={setPhotos} />
        </FormControl>

        <FormControl>
          <FormLabel>Üst kategori</FormLabel>
          <Select
            value={parentCategoryId}
            onChange={(e) => setParentCategoryId(e.target.value)}
          >
            <option value=''>--</option>
            {categoriesData &&
              categoriesData.categories.map((category) => (
                <option
                  value={category.id}
                  key={category.id}
                  disabled={category.id === id}
                >
                  {category.descriptions.length > 1
                    ? category.descriptions.map((desc) => `(${desc.title})`)
                    : category.descriptions.map((desc) => `${desc.title}`)}
                </option>
              ))}
          </Select>
          <FormHelperText>Üst kategorisi varsa seçiniz.</FormHelperText>
        </FormControl>

        <Button
          colorScheme='blue'
          type='submit'
          mt='10px'
          isLoading={loading || loadingWithoutParent}
        >
          Kaydet
        </Button>
      </Box>
    </form>
  );
};

export default EditForm;
