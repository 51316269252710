import {
  GridItem,
  FormLabel,
  IconButton,
  FormControl,
  Flex,
  Box,
  Input,
  Select,
  Link,
} from '@chakra-ui/react';
import { useCallback } from 'react';
import { AiOutlineDelete, AiOutlineLink } from 'react-icons/ai';
import { useUploadProductFileMutation } from '../generated/graphql';
import { ProductFile } from '../types';
import Loading from './Loading';

interface Props {
  file: ProductFile;
  files: ProductFile[];
  index: number;
  removeCardField: (id: any) => void;
  handleCardInputChange: (id: any, event: any) => void;
  setFiles: React.Dispatch<React.SetStateAction<ProductFile[]>>;
}

const ProductFileInput: React.FC<Props> = ({
  removeCardField,
  file,
  files,
  setFiles,
  handleCardInputChange,
  index,
}) => {
  const [uploadProductFile, { loading: uploadLoading }] =
    useUploadProductFileMutation();

  const handleFileChange = useCallback(
    async (id, e: React.ChangeEvent<HTMLInputElement>) => {
      new Promise((resolve) => {
        let copied = JSON.parse(JSON.stringify(files));
        const changedInputs = copied.map(async (input) => {
          if (id === input.id) {
            const { data } = await uploadProductFile({
              variables: { file: e.target.files[0] },
            });
            input['file_name'] = data.uploadProductFile.file;
          }
          resolve(input);
          return input;
        });
        Promise.all(changedInputs).then((value) => {
          setFiles(value as any);
        });
      });
    },
    [files, setFiles, uploadProductFile]
  );

  return (
    <GridItem
      key={file.id}
      display='flex'
      flexDirection='column'
      justifyContent='space-between'
    >
      <FormControl>
        <FormLabel display='flex' alignItems='center'>
          Dosya #{index + 1}
          <IconButton
            icon={<AiOutlineDelete size={20} />}
            aria-label='delete card'
            onClick={() => removeCardField(file.id)}
            size='sm'
            variant='ghost'
            colorScheme='red'
            ml='2'
          />
        </FormLabel>
        {uploadLoading ? (
          <Loading />
        ) : (
          <Flex my='2'>
            <Box mr='8'>
              {file.file_name ? (
                <Link
                  display='flex'
                  alignItems='center'
                  href={`${process.env.REACT_APP_BASE}/productfiles/${file.file_name}`}
                  isExternal
                  bgColor='green.500'
                  textColor='white'
                  rounded='sm'
                  px='2'
                >
                  Dosya yüklendi
                  <AiOutlineLink size='20' style={{ marginLeft: 4 }} />
                </Link>
              ) : (
                <FormControl>
                  <FormLabel>Dosya</FormLabel>
                  <Input
                    type='file'
                    multiple={false}
                    onChange={(e) => handleFileChange(file.id, e)}
                  />
                </FormControl>
              )}
            </Box>
          </Flex>
        )}
      </FormControl>
      <Box>
        <FormControl mb='2'>
          <Input
            type='text'
            name='title'
            value={file.title}
            onChange={(e) => handleCardInputChange(file.id, e)}
            placeholder='başlık'
          />
        </FormControl>

        <FormControl mb='2'>
          <Select
            name='language'
            onChange={(e) => handleCardInputChange(file.id, e)}
          >
            <option value='tr'>Türkçe</option>
            <option value='en'>İngilizce</option>
          </Select>
        </FormControl>
      </Box>
    </GridItem>
  );
};

export default ProductFileInput;
