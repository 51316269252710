import React from 'react';

import { Flex } from '@chakra-ui/layout';
import {
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
} from '@chakra-ui/number-input';
import {
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
} from '@chakra-ui/slider';
import { SkeletonGroupLayout } from './SkeletonGroup';

interface Props {
  state: number;
  setState: Function;
  label?: string;
  loading?: boolean;
}

const NumberSlider = ({ state, setState, label, loading = false }: Props) => {
  const handleChange = (value) => setState(value);

  return (
    <SkeletonGroupLayout loading={loading} label={label}>
      <Flex>
        <NumberInput
          maxW='100px'
          mr='2rem'
          value={state}
          onChange={handleChange}
        >
          <NumberInputField />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
        <Slider
          flex='1'
          focusThumbOnChange={false}
          value={state}
          onChange={handleChange}
        >
          <SliderTrack>
            <SliderFilledTrack />
          </SliderTrack>
          <SliderThumb fontSize='sm' boxSize='32px' children={state} />
        </Slider>
      </Flex>
    </SkeletonGroupLayout>
  );
};

export { NumberSlider };
