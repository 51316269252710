import {
  FormControl,
  FormControlProps,
  FormLabel,
  Select,
} from '@chakra-ui/react';
import {
  ContentLineup,
  ContentType,
  MediaType,
  ThemeType,
} from '../../generated/graphql';

type IContentLineupSelect = {
  lineUp: ContentLineup;
  setLineUp: (type: ContentLineup) => void;
} & FormControlProps;

export const ContentTypeSelect = ({
  contentType,
  setContentType,
}: {
  contentType: ContentType;
  setContentType: (type: ContentType) => void;
}) => (
  <Select
    value={contentType}
    onChange={(e) => setContentType(e.target.value as ContentType)}
  >
    {Object.keys(ContentType).map((type, i) => {
      let text: string;

      switch (type) {
        case ContentType.TEXT:
          text = 'Metin';
          break;
        case ContentType.IMAGE:
          text = 'Görsel';
          break;
        case ContentType.VIDEO:
          text = 'Video';
          break;
        case ContentType.MAP:
          text = 'Harita';
          break;
        case ContentType.CARD:
          text = 'Kart';
          break;
        case ContentType.SLIDESHOW:
          text = 'Slayt';
          break;
        case ContentType.LATEST_NEWS:
          text = 'Blog Yazıları';
          break;
        case ContentType.FORM:
          text = 'FORM';
          break;
        case ContentType.PRODUCT_CATEGORY:
          text = 'Ürün Kategorisi';
          break;
        case ContentType.HIGHLIGHTED_PRODUCTS:
          text = 'Öne Çıkarılan Ürünler';
          break;
        case ContentType.BRANDS:
          text = 'Markalar';
          break;
        case ContentType.BRANDS_WORLDMAP:
          text = 'Markalar Dünya Haritası';
          break;
        case ContentType.CALENDAR:
          text = 'Takvim';
          break;
        case ContentType.HEROIMAGE:
          text = 'Dev Görsel';
          break;
        case ContentType.TIMELINE:
          text = 'TIMELINE';
          break;
        case ContentType.FAQ:
          text = 'Sıkça Sorulan Sorular';
          break;
        case ContentType.READER_COMMENTS:
          text = 'Okuyucu Yorumları';
          break;
        case ContentType.YT_VIDEOS:
          text = 'Youtube Videoları';
          break;
        case ContentType.FORMIO:
          text = 'FORMIO';
          break;
        default:
          text = 'Metin';
          break;
      }

      return (
        <option value={type} key={i}>
          {text}
        </option>
      );
    })}
  </Select>
);

export const MediaTypeSelect = ({
  mediaType,
  setMediaType,
}: {
  mediaType: MediaType;
  setMediaType: (type: MediaType) => void;
}) => (
  <Select
    value={mediaType}
    onChange={(e) => setMediaType(e.target.value as MediaType)}
  >
    {Object.keys(MediaType).map((type, i) => {
      let text: string;

      switch (type) {
        case MediaType.SINGLE:
          text = 'Tekli Görsel';
          break;
        case MediaType.GALLERY:
          text = 'Galeri';
          break;
        case MediaType.THUMBNAIL:
          text = 'Küçük Resim';
          break;
        case MediaType.SIDEBYSIDE:
          text = 'Yazılı Görsel';
          break;
        case MediaType.NOIMAGE:
          text = 'NOIMAGE';
          break;
        default:
          text = 'Tekli Görsel';
          break;
      }

      return (
        <option value={type} key={i}>
          {text}
        </option>
      );
    })}
  </Select>
);

export const ThemeTypeSelect = ({
  themeType,
  setThemeType,
  ...rest
}: {
  themeType: ThemeType;
  setThemeType: (type: ThemeType) => void;
} & FormControlProps) => (
  <FormControl {...rest}>
    <FormLabel>Tema</FormLabel>
    <Select
      value={themeType}
      onChange={(e) => setThemeType(e.target.value as ThemeType)}
      width='full'
    >
      {Object.keys(ThemeType).map((type, i) => {
        let text: string;

        switch (type) {
          case ThemeType.DARK:
            text = 'DARK';
            break;
          case ThemeType.LIGHT:
            text = 'LIGHT';
            break;
          default:
            text = 'DARK';
            break;
        }

        return (
          <option value={type} key={i}>
            {text}
          </option>
        );
      })}
    </Select>
  </FormControl>
);

export const ContentLineupSelect = ({
  lineUp,
  setLineUp,
  ...rest
}: IContentLineupSelect) => (
  <FormControl {...rest}>
    <FormLabel>Dizilim</FormLabel>
    <Select
      value={lineUp}
      onChange={(e) => setLineUp(e.target.value as ContentLineup)}
      width='full'
    >
      {Object.keys(ContentLineup).map((type, i) => {
        let text: string;

        switch (type) {
          case ContentLineup.HORIZONTAL:
            text = 'Yatay';
            break;
          case ContentLineup.VERTICAL:
            text = 'Dikey';
            break;
          case ContentLineup.SLIDER:
            text = 'Slider';
            break;
          default:
            text = 'Yatay';
            break;
        }

        return (
          <option value={type} key={i}>
            {text}
          </option>
        );
      })}
    </Select>
  </FormControl>
);
