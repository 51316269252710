import {
  Button,
  ButtonGroup,
  IconButton,
  Text,
  VStack,
  useToast,
  Accordion,
  AccordionItem,
  AccordionButton,
  Box,
  AccordionPanel,
  AccordionIcon,
  HStack,
  Container,
} from '@chakra-ui/react';
import { CgReorder } from 'react-icons/cg';
import { MdRefresh } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { groupBy } from 'lodash';
import DescriptionModal from '../../components/Description';

import Loading from '../../components/Loading';
import MessageBox from '../../components/MessageBox';
import {
  useDeleteCategoryMutation,
  useGetCategoriesQuery,
} from '../../generated/graphql';
import DeletePopover from '../../components/DeletePopover';

const List = () => {
  const toast = useToast();
  const { data, loading, error, refetch } = useGetCategoriesQuery({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const [deleteCategory, { loading: deleteLoading }] =
    useDeleteCategoryMutation();

  const deleteHandler = async (id: any) => {
    try {
      const res = await deleteCategory({ variables: { id } });
      if (res) refetch();
    } catch (error) {
      console.log('catch err ⚠️', error);
      return toast({
        title: 'Hata',
        description: error.message,
        status: 'error',
        duration: 2500,
        isClosable: true,
      });
    }
  };

  const grouped = groupBy(data && data.categories, 'parentId');

  return (
    <>
      <VStack align='start' mb='4'>
        <Text fontWeight='bold' fontSize='2xl'>
          E-Commerce Kategoriler
        </Text>

        <ButtonGroup alignSelf='flex-end' isAttached>
          <Button colorScheme='green' as={Link} to='/categories/new'>
            Yeni
          </Button>
          <IconButton
            aria-label='ordering'
            colorScheme='purple'
            icon={<CgReorder size='20' />}
            as={Link}
            to='/categories/order'
          />
          <IconButton
            aria-label='refresh'
            icon={<MdRefresh />}
            onClick={() => refetch()}
          />
        </ButtonGroup>
      </VStack>
      <Container maxWidth='6xl'>
        {error && <MessageBox message={error.message} status='error' />}
        {loading ? (
          <Loading />
        ) : data?.categories.length <= 0 ? (
          <MessageBox
            status='warning'
            message='Henüz hiçbir kategori oluşturmamışsınız. Yeni bir tane oluşturmak için yeni
          butonunu kullanın.'
          />
        ) : (
          Object.values(grouped).map((group, i) => {
            return (
              <Accordion allowToggle key={i}>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box flex='1' textAlign='left'>
                        Kategori:{' '}
                        {group[0].parent?.descriptions[0].title ?? (
                          <Text display='inline' fontWeight='bold'>
                            Ana Kategoriler
                          </Text>
                        )}
                      </Box>
                      {group[0]?.parentId && (
                        <Button
                          colorScheme='gray'
                          as={Link}
                          to={`/products/order/${group[0]?.parentId}`}
                          mr='2'
                          size='sm'
                        >
                          Ürün Sırala
                        </Button>
                      )}
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    {group.map((element, i) => {
                      return (
                        <HStack
                          display='flex'
                          justifyContent='space-between'
                          mb='4'
                          key={element.id}
                        >
                          <Box>{i + 1}</Box>
                          <Box width='350px'>
                            <DescriptionModal
                              descriptionProp={element.descriptions}
                              field='category'
                              fieldId={element.id}
                              refetch={refetch}
                            />
                          </Box>
                          <Box>
                            <ButtonGroup size='sm' variant='solid'>
                              <Button
                                colorScheme='gray'
                                as={Link}
                                to={`/products/order/${element.id}`}
                              >
                                Ürün Sırala
                              </Button>
                              <Button
                                colorScheme='teal'
                                as={Link}
                                to={`/categories/update/${element.id}`}
                              >
                                Düzenle
                              </Button>
                              <DeletePopover
                                deleteHandler={deleteHandler}
                                pageId={element.id}
                                isLoading={deleteLoading}
                              />
                            </ButtonGroup>
                          </Box>
                        </HStack>
                      );
                    })}
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            );
          })
        )}
      </Container>
    </>
  );
};

export default List;
