import {
  Box,
  Alert,
  AlertIcon,
  AlertDescription,
  AlertStatus,
} from '@chakra-ui/react';

type Props = {
  message: string | undefined;
  status: AlertStatus;
  [x: string]: any;
};

export default function MessageBox({ status, message, ...rest }: Props) {
  return (
    <Box my={4} {...rest}>
      <Alert status={status} borderRadius={4}>
        <AlertIcon />
        <AlertDescription>{message}</AlertDescription>
      </Alert>
    </Box>
  );
}
