import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Select,
  SimpleGrid,
  Stack,
  Switch,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ContentDescription from '../../components/ContentDescription';
import Loading from '../../components/Loading';
import MessageBox from '../../components/MessageBox';
import ProductFileCard from '../../components/ProductFileCard';
import UploadDropzone from '../../components/UploadDropzone';
import {
  useCreateProductMutation,
  useCreateProductWithoutCategoryMutation,
  useGetBrandsQuery,
  useGetCategoriesLazyQuery,
  useGetCategoriesQuery,
} from '../../generated/graphql';
import categoryIdSetter from '../../services/categoryIdSetter';
import { ProductFile } from '../../types';

const Add = () => {
  const { push } = useHistory();

  const [brandId, setBrandId] = useState('');
  const [parentCat, setParentCat] = useState('');
  const [childCat, setChildCat] = useState('');
  const [photos, setPhotos] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [sku, setSku] = useState('');
  const [buyLink, setBuyLink] = useState('');
  const [price, setPrice] = useState(0);
  const [salesPrice, setSalesPrice] = useState(0);
  const [expiryDate, setExpiryDate] = useState('');
  const [files, setFiles] = useState<ProductFile[]>([]);
  const [order, setOrder] = useState(0);
  // description inputs
  const [title, setTitle] = useState('');
  const [language] = useState('tr');
  const [description, setDescription] = useState('');
  const [shortDescription, setShortDescription] = useState('');
  const [buttonText, setButtonText] = useState('');
  const [buttonUrl, setButtonUrl] = useState('');

  const [error, setError] = useState('');

  const [createProduct, { loading }] = useCreateProductMutation();
  const [createProductWithoutCategory, { loading: loadingWithoutCategory }] =
    useCreateProductWithoutCategoryMutation();

  const { data: brandsData } = useGetBrandsQuery({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const { data: parentCategoriesData, loading: parentCategoriesLoading } =
    useGetCategoriesQuery({
      variables: {
        where: {
          parentId: { equals: null },
        },
      },
    });

  const [getChildCats, { data: childCats, loading: childCatsLoading }] =
    useGetCategoriesLazyQuery();

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      if (!parentCat) {
        await createProductWithoutCategory({
          variables: {
            sku,
            price,
            salesPrice,
            brandId,
            photos,
            buyLink,
            files,
            expiryDate,
            minimumQuantity: 0,
            disabled: isDisabled,

            title,
            description,
            shortDescription,
            language,
            buttonText,
            buttonUrl,
          },
        });
      } else {
        await createProduct({
          variables: {
            sku,
            price,
            salesPrice,
            brandId,
            order,
            photos,
            buyLink,
            files,
            expiryDate,
            categoryId: categoryIdSetter({ parentCat, childCat }),
            minimumQuantity: 0,
            disabled: isDisabled,

            title,
            description,
            shortDescription,
            language,
            buttonText,
            buttonUrl,
          },
        });
      }
      setError('');
      push(`/products`);
    } catch (error) {
      console.log('err ⚠️', error);
      setError(error.message);
    }
  };

  useEffect(() => {
    getChildCats({ variables: { where: { parentId: { equals: parentCat } } } });
  }, [childCats, getChildCats, parentCat]);

  return (
    <>
      <VStack align='flex-start' mb='4'>
        <Text fontWeight='bold' fontSize='2xl'>
          Yeni Ürün
        </Text>
      </VStack>
      <form onSubmit={onSubmit}>
        <Box width='full' mb='8'>
          {error && <MessageBox status='warning' message={error} />}
          <FormControl mb='4'>
            <UploadDropzone images={photos} setImageArray={setPhotos} />
          </FormControl>
          <SimpleGrid columns={[1, 2]} spacing={[2, 8]}>
            <FormControl isRequired>
              <FormLabel>Kategori</FormLabel>
              <Select
                value={parentCat}
                onChange={(e) => {
                  setParentCat(e.target.value);
                  setChildCat('');
                }}
                disabled={parentCategoriesLoading}
              >
                <option value='' disabled>
                  --Kategori--
                </option>
                {parentCategoriesData &&
                  parentCategoriesData.categories.map((parentCat) => (
                    <option value={parentCat.id} key={parentCat.id}>
                      {parentCat.descriptions[0].title}
                    </option>
                  ))}
              </Select>
            </FormControl>
            <FormControl>
              {childCatsLoading ? (
                <Loading />
              ) : (
                childCats &&
                childCats.categories.length > 0 && (
                  <>
                    <FormLabel>Alt Kategori</FormLabel>
                    <Select
                      value={childCat}
                      onChange={(e) => {
                        setChildCat(e.target.value);
                      }}
                    >
                      <option value=''>--Seçiniz--</option>
                      {childCats.categories.map((category) => (
                        <option value={category.id} key={category.id}>
                          {category.descriptions[0].title}
                        </option>
                      ))}
                    </Select>
                    <FormHelperText>
                      Alt kategorisi varsa seçiniz.
                    </FormHelperText>
                  </>
                )
              )}
            </FormControl>

            <FormControl isRequired>
              <FormLabel>Marka</FormLabel>
              <Select
                value={brandId}
                onChange={(e) => setBrandId(e.target.value)}
              >
                <option value=''>--</option>
                {brandsData &&
                  brandsData.brands.map((brand) => (
                    <option value={brand.id} key={brand.id}>
                      {brand.title}
                    </option>
                  ))}
              </Select>
              <FormHelperText>Ürün markası</FormHelperText>
            </FormControl>
            <FormControl>
              <FormLabel>Sıra</FormLabel>
              <Input
                type='number'
                value={order}
                onChange={(e) => setOrder(parseInt(e.target.value))}
              />
              <FormHelperText>Ürünün sırası</FormHelperText>
            </FormControl>
          </SimpleGrid>
          <Box my={4}>
            <FormControl isRequired>
              <FormLabel>Satın Alma Linki</FormLabel>
              <Input
                type='text'
                value={buyLink}
                onChange={(e) => setBuyLink(e.target.value)}
              />
              <FormHelperText>SKU(Stock Keeping Unit)</FormHelperText>
            </FormControl>
          </Box>
          <Stack direction={['column', 'row']} alignItems='center' mt='4'>
            <FormControl isRequired>
              <FormLabel>SKU</FormLabel>
              <Input
                type='text'
                value={sku}
                onChange={(e) => setSku(e.target.value)}
              />
              <FormHelperText>SKU(Stock Keeping Unit)</FormHelperText>
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Fiyat</FormLabel>
              <Input
                type='number'
                value={price}
                onChange={(e) => setPrice(Number(e.target.value))}
              />
              <FormHelperText>Taban fiyat</FormHelperText>
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Satış Fiyat</FormLabel>
              <Input
                type='number'
                value={salesPrice}
                onChange={(e) => setSalesPrice(Number(e.target.value))}
              />
              <FormHelperText>İndirimli veya satış fiyatı</FormHelperText>
            </FormControl>
            <FormControl>
              <FormLabel>Bitiş Tarihi</FormLabel>
              <Input
                type='date'
                value={expiryDate}
                onChange={(e) => setExpiryDate(e.target.value)}
              />
              <FormHelperText>Şu tarihe kadar indirim</FormHelperText>
            </FormControl>
            <FormControl
              alignItems='center'
              display='flex'
              flexDirection='column'
            >
              <FormLabel textAlign='center'>Devre dışı bırak?</FormLabel>
              <Switch
                onChange={(e) => setIsDisabled(e.target.checked)}
                defaultChecked={isDisabled}
              />
            </FormControl>
          </Stack>

          <Box p='6px' my='4'>
            <ProductFileCard files={files} setFiles={setFiles} />
          </Box>

          <Divider my='6' />
          <ContentDescription
            title={title}
            setTitle={setTitle}
            description={description}
            setDescription={setDescription}
            shortDescription={shortDescription}
            setShortDescription={setShortDescription}
            buttonText={buttonText}
            setButtonText={setButtonText}
            buttonUrl={buttonUrl}
            setButtonUrl={setButtonUrl}
          />

          <Button
            colorScheme='blue'
            type='submit'
            mt='10px'
            isLoading={loading || loadingWithoutCategory}
          >
            Kaydet
          </Button>
        </Box>
      </form>
    </>
  );
};

export default Add;
